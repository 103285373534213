import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveFFTxn,
  approveVendorTxn,
} from "../../../../redux/features/approveTxn/approveTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import alert from "../../../../../src/assets/Approve transactions/Alert.png";
import verified from "../../../../../src/assets/Approve transactions/Verified.png";
import "./index.css";
import LineWithImage from "../LineWithImage";
import formatDate from "../../../../utils/formatdate";
import trimSentence from "../../../../utils/trimSentence";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import { getFieldForceAccounts } from "../../../../redux/features/accounts/accounts";
import { verifyThisPAN } from "../../../../redux/features/verifyPAN/verifyPAN";
import { getFFLimits } from "../../../../redux/features/ffLimits/ffLimits";

const ApprovalDetailsCard = (props) => {
  const dispatch = useDispatch();
  let FFAccount = useSelector((state) => state.accounts.fieldForce);
  FFAccount = FFAccount?.accounts?.[0];
  let PANVerified = useSelector((state) => state.verify.verifyPAN);
  const limitApiRes = useSelector((state) => state.ffLimits.limit);

  useEffect(() => {
    dispatch(
      getFieldForceAccounts({ account_id: props.data?.destination_account_id })
    );
    dispatch(verifyThisPAN({ pan_number: props.data?.destination_pan_number }));
    dispatch(getFFLimits({ user_id: props?.data?.user_id }));
  }, [props.data?.destination_account_id]);

  return (
    <div className="w-full overflow-hidden smallHeight:h-[95vh] xl:text-sm 2xl:text-lg 4xl:text-3xl  flex rounded-4xl bg-[#3e5555]">
      <div className="w-1/2  rounded-4xl text-white">
        <div className="xl:p-6 2xl:p-12 4xl:p-24">
          <div
            style={{
              borderColor:
                FFAccount?.approval_status === "Y" ? "#ffffff" : "#ff9130",
            }}
            className="border-dashed border-2 pb-4"
          >
            <div
              className="text-center font-normal xl:text-base 2xl:text-xl 4xl:text-4xl leading-normal tracking-normal bg-[#3e5555] w-4/6 mx-auto py-0 xl:px-1 px-2 -mt-4 mb-5"
              style={{
                color:
                  FFAccount?.approval_status === "Y" ? "#ffffff" : "#ff9130",
              }}
            >
              {FFAccount?.approval_status === "Y"
                ? "Approved account details"
                : "Pending account details"}
            </div>
            <div className="xl:px-4 2xl:px-8 4xl:px-16">
              <div className="simpleBorderBox truncate ...">
                <b className="text-[white] truncate ...">
                  {" "}
                  ₹ {formatCurrency(limitApiRes?.limits.remaining)}
                </b>{" "}
                <br />
                Daily transaction limit
              </div>
              <div className="simpleBorderBox truncate ...">
                <b className="text-[white] truncate ...">
                  {FFAccount?.contact_no}
                </b>{" "}
                <br />
                Contact number
              </div>
              <div className="simpleBorderBox truncate ...">
                <b className="text-[white] truncate ...">
                  {FFAccount?.email_id}
                </b>{" "}
                <br />
                Email
              </div>
              <div className="dashedBorderBox truncate ...">
                <b className="text-[white] truncate ...">
                  {FFAccount?.gstin ? FFAccount.gstin : "Not given"}
                </b>{" "}
                <br />
                GSTIN
              </div>
            </div>
            <LineWithImage imageUrl={verified} />
            <div className="xl:px-4 2xl:px-8 4xl:px-16 text-[#c9c9c9]">
              <div className="simpleBorderBox mb-3 pb-3 truncate ...">
                <b className="text-[white] truncate ...">
                  {props.data?.destination_pan_number
                    ? props.data?.destination_pan_number
                    : "Not available"}
                </b>{" "}
                <br />
                PAN
              </div>
              <div className="simpleBorderBox mb-3 pb-3 truncate ...">
                <b className="text-[white] truncate ...">
                  {PANVerified?.pan_details.full_name
                    ? PANVerified?.pan_details.full_name
                    : "Not available"}
                </b>{" "}
                <br />
                Legal name
              </div>
              <div className="flex w-full justify-between items-center ">
                {!props.data?.account?.legal_name.length >= 1 ? (
                  <>
                    <b>
                      We were unable to verify bank <br /> credentials, as{" "}
                      <span className="capitalize">
                        {props.data?.transaction?.initiator_name}
                      </span>{" "}
                      <br />
                      has chosen to skip verification.
                    </b>
                    <img
                      width={"101px"}
                      style={{
                        width: "8%",
                        height: "8%",
                        background: "inherit",
                      }}
                      src={alert} // conditional icon rendering
                      alt=""
                      srcSet=""
                    />
                  </>
                ) : (
                  <div style={{ paddingTop: "10%" }}>
                    <b>{props.data?.account?.legal_name}</b> <br />
                    <span style={{ color: "#C9C9C9" }}>
                      Account holder name
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-[#C9C9C9] xl:text-lg 2xl:text-xl 4xl:text-3xl xl:py-2 2xl:py-4 4xl:py-8">
            Requested by {props.data?.txn_initiator_name}
          </div>
        </div>
      </div>
      <div className="w-1/2  rounded-4xl bg-[#4A6565]">
        <div
          className="xl:text-xl 2xl:text-3xl 4xl:text-4xl  5xl:text-6xl capitalize text-center text-white  w-4/5 m-auto my-6
          truncate ..."
        >
          {props?.data?.destination_owner_name}
        </div>
        <div className="bg-[#def5ed] text-center xl:py-1.5 2xl:py-3 4xl:py-6">
          <div className="xl:text-2xl 2xl:text-4xl 4xl:text-5xl  5xl:text-7xl font-semibold xl:p-2 2xl:p-4 4xl:p-8 text-[#69B76F]">
            {" "}
            ₹ {formatCurrency(props.data?.amount)}
          </div>
          <p className="text-[#707070]">
            {formatDate(props.data?.initiate_date)}
          </p>
        </div>
        <div className="xl:p-4 2xl:p-8">
          <div className=" simpleBorderBox truncate ...">
            <b>{props.data?.destination_bank_acc_no}</b> <br />
            Account number
          </div>
          <div className="dashedBorderBox">
            <div className="paraNImg">
              <p className="w-1/2 truncate ...">
                <b className="text-white">{props.data?.destination_ifsc} </b>
                <br />
                <span style={{ color: "#c9c9c9" }}> IFSC code </span>
              </p>
              <img
                width={"20%"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.data?.destination_bank_name) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </div>
          </div>
          <div
            style={{
              height: "3px",
              padding: 0,
              margin: "10px 0",
              opacity: "0.2",
              marginBottom: "27.21px",
              background:
                "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
            }}
            className="dashedLine"
          ></div>
          <div className="simpleBorderBox truncate ...">
            <b>
              {props.data?.transaction?.invoice_number
                ? props.data?.transaction.invoice_number
                : "Not given"}
            </b>{" "}
            <br />
            Invoice number
          </div>
          <div className="noBorderBox truncate ...">
            <b>
              {props.data?.transaction?.distribution_remark
                ? props.data?.transaction.invoice_number
                : "Not given"}
            </b>{" "}
            <br />
            Distribution remark
          </div>
        </div>
        <div className="flex text-white xl:text-base 2xl:text-xl 4xl:text-4xl justify-center gap-6 m-auto xl:my-3 2xl:my-6 4xl:my-12 ">
          <button
            className="bg-[#ea545b] w-2/6 font-normal xl:px-0.5 2xl:px-1 4xl:px-3 xl:py-2 2xl:py-3 4xl:py-6 rounded-4xl flex items-center justify-center"
            onClick={() => {
              props.setDeniedReasonData({
                txnId: [props?.data.txn_id],
                dispatchFn: approveFFTxn,
                setShowStatusCard: () =>
                  props.setShowStatusCard([
                    ...props.showStatusCard,
                    props.index,
                  ]),
              });
              props.setDeniedReason(true);
            }}
          >
            <img
              width={"19px"}
              src={require("../../../../assets/Approve transactions/Decline white.png")}
              alt=""
            />{" "}
            &nbsp;&nbsp; 
          </button>
          <button
            onClick={() => {
              dispatch(
                approveFFTxn({ txn_id: [props?.data.txn_id], status: "Y" })
              );
              props.setShowStatusCard([...props.showStatusCard, props.index]);
            }}
            className="bg-[#69b76f] w-2/6 font-normal xl:px-0.5 2xl:px-1 4xl:px-3  xl:py-2 2xl:py-3 4xl:py-6 rounded-4xl flex items-center justify-center "
          >
            <img
              width={"19px"}
              src={require("../../../../assets/Approve transactions/Approve white.png")}
              alt=""
            />
            &nbsp;&nbsp; Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalDetailsCard;
