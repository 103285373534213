const TransactionType = ({ AuthMatrixTransactions, handleSelected, selected }) => {
  return (
    <div className='flex flex-col'>
      <div className='text-white text-left p-10 font-semibold text-2xl 3xl:text-3xl 5xl:text-5xl 6xl:text-6xl ' > Authorization <br /> Matrix </div>
      <div className='flex flex-col gap-10' >
        {
          AuthMatrixTransactions?.map((item, index) => (
            <div onClick={() => handleSelected(item.type_of_transaction)}
              className={`flex gap-5 px-10 py-2 items-center cursor-pointer ${selected === item.type_of_transaction ? "bg-[#1D3A6D] shadow-2xl border-l-8 border-red-500" : ""} `}>
              <div className='flex items-center justify-center w-8 h-8 2xl:w-10 2xl:h-10 3xl:w-12 3xl:h-12 5xl:w-14 5xl:h-14' >
                <img width={"100%"} height={"100"} src={require(`../../../../../assets/Settings/Configuration/AuthMatrix/${item?.icon}.png`)} alt="" />
              </div>
              <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate' >
                {item.title}
              </div>
            </div>
          )
          )
        }
      </div>
    </div>
  )
}

export default TransactionType