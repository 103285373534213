import React, { useEffect, useState } from "react";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import { getClientViewDetail } from "../../../../redux/features/txnDetails/txnDetails";

const CurrentStatusCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(15);
  const [loop, setLoop] = useState(1);
  const [isCopied, setIsCopied] = useState(false);
  const txn_id = props?.createdClientTxn.txn_details.txn_id;

  setTimeout(() => {
    setIsCopied(false);
  }, 3000);

  useEffect(() => {
    if ((count > -1 && loop < 10) || count > 0) {
      setTimeout(() => {
        setCount(count - 1);
      }, 1000);
    }
  }, [count]);

  useEffect(() => {
    if (count === 0) {
      dispatch(getClientViewDetail({ txn_id: txn_id }));
    }
    if (count === -1 && loop < 10) {
      setLoop(loop + 1);
      setCount(15);
    }
  }, [count]);

  useEffect(() => {
    setCount(15);
  }, [loop]);

  return (
    <>
      <div className="flex w-5/6 h-full flex-col bg-[#466088] rounded-4xl items-center justify-between">
        <div className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6 items-center justify-center">
          <div className="flex text-4xl pt-12 font-semibold text-white gap-6 p-4 mt-4 pb-4">
            Current status:
          </div>
          <div className="flex rounded-[50%] shadow-customCard p-12 2xl:p-20 3xl:p-24 4xl:p-28 5xl:p-32 6xl:p-36 text-4xl font-semibold text-white gap-6">
            {count}
          </div>
          <div className="flex text-3xl pt-6 text-white gap-6 p-4 mt-4 pb-4">
            Attempt total - {loop}
          </div>
          <button
            style={{
              opacity: loop < 10 || count > 0 ? 0.5 : 1,
            }}
            disabled={loop < 10 || count > 0}
            onClick={() => setLoop(loop + 1)}
            className="text-2xl antialiased text-[#466088] font-bold bg-white rounded-3xl shadow-customCard p-2 2xl:p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12"
          >
            Check status
          </button>
        </div>
        <div
          className={`w-fu ll cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl bg-[#466088] shadow-customCard`}
        >
          <div className="flex text-lg w-4/5 m-auto justify-between h-[25%]">
            <div className="">Share link</div>
            {/* <div className="text-[#F18841] underline" >Resend link</div> */}
          </div>
          <div className="flex justify-evenly items-end gap-2">
            <div
              className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
              onClick={() => {
                navigator.clipboard.writeText(
                  props.createdClientTxn?.redirect_url
                );
                setIsCopied(true);
              }}
            >
              <div className="w-2/6 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  viewBox="0 0 492 492.002"
                >
                  <g
                    id="Group_2463"
                    data-name="Group 2463"
                    transform="translate(-311 -403)"
                  >
                    <g
                      id="_3669369_content_copy_ic_icon"
                      data-name="3669369_content_copy_ic_icon"
                      transform="translate(311 403)"
                    >
                      <path
                        id="Path_1154"
                        data-name="Path 1154"
                        d="M0,0H492V492H0Z"
                        transform="translate(0 0)"
                        fill="none"
                      />
                      <path
                        id="Path_1155"
                        data-name="Path 1155"
                        d="M363.263,2H55.323C26.967,2,4,21.943,4,46.566V358.532H55.323V46.566h307.94Zm76.985,89.133H157.97c-28.356,0-51.323,19.943-51.323,44.566V447.664c0,24.623,22.967,44.566,51.323,44.566H440.249c28.356,0,51.323-19.943,51.323-44.566V135.7C491.572,111.076,468.6,91.133,440.249,91.133Zm0,356.532H157.97V135.7H440.249Z"
                        transform="translate(-2.229 -1.114)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p>{isCopied ? "Link copied" : "Copy link"}</p>
            </div>
            <div className="h-4/5 w-0.5 bg-white" />
            <div
              onClick={() =>
                window.open(
                  `https://wa.me/+91${props.selectedAcc?.contact_no}?text=${props.createdClientTxn?.redirect_url}`,
                  "_blank"
                )
              }
              style={{
                pointerEvents: props.createdClientTxn?.sms_sent_details
                  ? "all"
                  : "none",
                opacity: props.createdClientTxn?.sms_sent_details ? 1 : 0.5,
              }}
              className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
            >
              <div className="w-2/6 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  viewBox="0 0 475 475"
                >
                  <g id="WA" transform="translate(15 15)">
                    <g
                      id="Group_1361"
                      data-name="Group 1361"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_1150"
                        data-name="Path 1150"
                        d="M11.946,441.054,107.3,414.83A224.69,224.69,0,0,0,226.5,449,222.5,222.5,0,1,0,4,226.5,224.691,224.691,0,0,0,38.17,345.7Z"
                        transform="translate(-4 -4)"
                        fill="#50bf7a"
                        stroke="#fff"
                        strokeMiterlimit="10"
                        strokeWidth="30"
                      />
                      <path
                        id="Path_1151"
                        data-name="Path 1151"
                        d="M239.806,176.445C224.708,208.23,196.9,212.2,196.9,212.2c-23.839,4.768-54.036-5.563-77.875-16.687-34.17-15.893-63.571-41.321-83.437-73.9C26.842,106.516,18.9,89.034,18.1,72.346c0,0-3.179-27.812,23.839-50.062A13.425,13.425,0,0,1,49.887,19.9h11.92a11.951,11.951,0,0,1,11.125,7.946l18.277,44.5a13.883,13.883,0,0,1-2.384,13.509L76.9,98.57c-3.973,3.973-3.973,9.536-1.589,14.3a26.049,26.049,0,0,0,4.768,6.357c14.3,19.071,33.375,33.375,54.83,42.911a54.783,54.783,0,0,0,7.946,3.179c5.562,1.589,10.33-.795,13.509-4.768l9.536-14.3a11.822,11.822,0,0,1,11.92-5.563l47.679,7.152c4.768.795,8.741,4.768,10.33,9.536l3.179,11.92C240.6,170.882,240.6,174.061,239.806,176.445Z"
                        transform="translate(93.149 106.448)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p>Whatsapp Web</p>
            </div>
            <div className="h-4/5 w-0.5 bg-white" />
            <div
              className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
              onClick={() =>
                window.open(
                  `mailto:"${props?.selectedAcc.email_id}"?subject="${props.createdClientTxn?.redirect_url}"`,
                  "_blank"
                )
              }
              style={{
                pointerEvents: props.createdClientTxn?.email_sent_details
                  ? "all"
                  : "none",
                opacity: props.createdClientTxn?.email_sent_details ? 1 : 0.5,
              }}
            >
              <div className="w-3/6 flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  viewBox="0 0 730.971 298.208"
                >
                  <g
                    id="Group_3547"
                    data-name="Group 3547"
                    transform="translate(-16683 -21211.5)"
                  >
                    <g
                      id="_1737385_e-mail_email_mail_message_messagesent_icon"
                      data-name="1737385_e-mail_email_mail_message_messagesent_icon"
                      transform="translate(16683 21275.051)"
                    >
                      <path
                        id="Path_1170"
                        data-name="Path 1170"
                        d="M562.451,362.811,459.466,297.739,561.72,222.114a11.3,11.3,0,1,0-13.434-18.164l-145,107.234-.061-.048-.06.048-145-107.234a11.3,11.3,0,1,0-13.432,18.164l102.254,75.625L244.006,362.811a11.3,11.3,0,1,0,12.077,19.107l110.4-69.762,30.029,22.215a11.292,11.292,0,0,0,13.438,0l30.022-22.215,110.4,69.762a11.3,11.3,0,0,0,12.077-19.107Z"
                        transform="translate(99.65 -201.732)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1172"
                        data-name="Path 1172"
                        d="M240.752,204.391H35.818a12.809,12.809,0,0,0,0,25.618H240.752a12.809,12.809,0,0,0,0-25.618Z"
                        transform="translate(-23.012 -200.22)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1173"
                        data-name="Path 1173"
                        d="M225.486,245.8H64.755c-5.542,0-10.042,5.73-10.042,12.806s4.5,12.812,10.042,12.812H225.486c5.55,0,10.042-5.742,10.042-12.812S231.034,245.8,225.486,245.8Z"
                        transform="translate(-4.987 -176.676)"
                        fill="#fff"
                      />
                      <path
                        id="Path_1174"
                        data-name="Path 1174"
                        d="M213.516,287.2H87.452c-4.354,0-7.874,5.736-7.874,12.806s3.52,12.812,7.874,12.812H213.516c4.347,0,7.874-5.735,7.874-12.812S217.864,287.2,213.516,287.2Z"
                        transform="translate(9.151 -153.135)"
                        fill="#fff"
                      />
                    </g>
                    <g
                      id="Rectangle_2323"
                      data-name="Rectangle 2323"
                      transform="translate(16956.904 21211.5)"
                      fill="none"
                      stroke="#fafafa"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                      strokeWidth="30"
                    >
                      <rect
                        width="457.066"
                        height="298.208"
                        rx="50"
                        stroke="none"
                      />
                      <rect
                        x="15"
                        y="15"
                        width="427.066"
                        height="268.208"
                        rx="35"
                        fill="none"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <p>Send Email</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentStatusCard;
