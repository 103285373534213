import React, { useState } from "react";
import "./index.css";
import SearchBar from "../../../_utils/SearchBar";
import SelectBank from "../../../_utils/SelectBank";
import SelectAccountBalance from "../../../SelectAccountBalance";
import ArrowLeft from "../../../../assets/CreateTransactionIcons/arrowleft.png";
import ArrowRight from "../../../../assets/CreateTransactionIcons/arrowright.png";
import { tooltipClasses, Tooltip, Button, TextField } from "@mui/material";
import { debounce } from "lodash";
import Loader from "../../../../assets/CreateTransactionIcons/inputloder.gif";
import BalanceLoader from "../../../_utils/BalanceLoader";
import BankReload from "../../../../assets/CreateTransactionIcons/BalanceReload.png";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";
import RefreshAllLoader from "../../../Accounts/OwnerAccounts/RefreshAllLoader";
import AnimationUpAndDown from "../AnimationUpAndDown";

const ActiveBanking = ({
  filterPayload,
  selectBank,
  setFilterPayload,
  setSelectBank,
  selectSortAmt,
  setSelectSortAmt,
  smallSearch,
  setSmallSearch,
  inputRef1,
  removeAccNo,
  beneOwnerData,
  beneOwnerLoading,
  setTxnPayLoad,
  txnPayLoad,
  setOpenOwner,
  handleRefresh,
  ownerAccBalanceLoading,
  refreshId,
  setRefreshId,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  BenEnableNextButton,
  transactionFilterData,
  balanceRefresh,
  balanceRefreshDisabled,
  refreshAnimationId,
  previousRefreshData,
  refreshAll,
  setRefreshAll,
  setTransactionFilterData,
  setRefreshAnimationId,
  refreshType,
  setRefreshType,
}) => {
  const handlePagination = (e) => {
    e.preventDefault();
    setFilterPayload({
      ...filterPayload,
      currentPageText: e.target.value,
    });
    if (
      e.target.value !== "" &&
      e.target.value !== 0 &&
      e.target.value !== "0"
    ) {
      handlepage(e.target.value);
    }
  };

  const [total_balance_state, setTotal_balance_state] = useState("");
  const [failedRefreshId, setFailedRefreshId] = useState();
  const [successRefreshId, setSuccessRefreshId] = useState();

  const [previousBalanceData, setPreviousBalanceData] = useState([]);

  const handlepage = debounce((page) => {
    if (page <= beneOwnerData?.title?.total_pages && page !== "00") {
      let value = page.length > 1 ? page.substring(1) : page;
      if (filterPayload?.currentPage != value) {
        setFilterPayload({
          ...filterPayload,
          currentPageText: page,
          currentPage: Number(value),
        });
      }
    } else if (page > beneOwnerData?.title?.total_pages) {
      setFilterPayload({
        ...filterPayload,
        currentPageText: "0" + beneOwnerData?.title?.total_pages,
        currentPage: beneOwnerData?.title?.total_pages,
      });
    } else {
      setFilterPayload({
        ...filterPayload,
        currentPageText: "01",
        currentPage: 1,
      });
    }
  }, 100);

  const nextPage = () => {
    let val = Number(filterPayload?.currentPageText) + 1;
    setFilterPayload({
      ...filterPayload,
      currentPage: filterPayload?.currentPage + 1,
      currentPageText: val > 9 ? val : "0" + val,
    });
  };

  const prevPage = () => {
    let val = Number(filterPayload?.currentPageText) - 1;
    setFilterPayload({
      ...filterPayload,
      currentPage: filterPayload?.currentPage - 1,
      currentPageText: val > 9 ? val : "0" + val,
    });
  };

  const handleBlur = () => {
    if (
      filterPayload?.currentPageText.length === 0 ||
      filterPayload?.currentPageText === "0"
    ) {
      setFilterPayload({
        ...filterPayload,
        currentPageText:
          filterPayload?.currentPage > 1 ? filterPayload?.currentPage : "01",
      });
    }
  };
  return (
    <div className="active_main_div_diff">
      <div className="container_page_search">
        <div className="owner_popup_sort">
          <SelectBank
            selectBank={selectBank}
            filterPayload={filterPayload}
            setFilterPayload={setFilterPayload}
            setSelectBank={setSelectBank}
            option={beneOwnerData?.summary?.available_banks || []}
          />
          <SelectAccountBalance
            filterPayload={filterPayload}
            setFilterPayload={setFilterPayload}
            selectSortAmt={selectSortAmt}
            setSelectSortAmt={setSelectSortAmt}
          />
          <Tooltip
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                  zIndex: "2147483647 !important",
                },
              },
              zIndex: "2147483647 !important",
            }}
            title="Refresh all balances"
          >
            <button
              className="allrefreshOwner"
              onClick={() => {
                const selectedData = transactionFilterData.map((user) => ({
                  id: user?.id,
                  previousBalance: user?.owner_account_balance,
                }));
                setPreviousBalanceData(selectedData);
                setRefreshType("all");
                setRefreshAll(true);
                setRefreshAnimationId("allRefresh");
              }}
            >
              <img src={BankReload} alt="relaod" />
            </button>
          </Tooltip>
        </div>

        <div className="page_search_mix">
          <div
            className="pagination_owner_acc"
            style={{ display: smallSearch ? "none" : "flex" }}
          >
            <Tooltip
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                    zIndex: "2147483647 !important",
                  },
                },
                zIndex: "2147483647 !important",
              }}
              title="Prev"
            >
              <div
                onClick={() => {
                  if (
                    filterPayload?.currentPage !== 1 ||
                    filterPayload?.currentPage > 1
                  ) {
                    prevPage();
                  }
                }}
                style={{
                  width: "30px !important",
                  paddingRight: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={ArrowLeft}
                  alt=""
                  style={{
                    opacity: filterPayload?.currentPage === 1 ? 0.5 : 1,
                  }}
                  className="arrow-images"
                />
              </div>
            </Tooltip>
            <TextField
              size="small"
              type="text"
              id="outlined-basic"
              variant="standard"
              onBlur={handleBlur}
              value={filterPayload?.currentPageText}
              defaultValue={"01"}
              onChange={handlePagination}
              InputLabelProps={{
                style: { color: "#fff", borderColor: "#fff" },
              }}
              InputProps={{
                style: {
                  font: "normal normal 600 14px/20px Open Sans",
                  padding: "2px 7px",
                },
              }}
              color="info"
              sx={{
                borderColor: "#fff !important",
                backgroundColor: "white",
                color: "#fff",
                font: "normal normal 600 12px/17px Open Sans",
                width: "30px !important",
                height: "30px !important",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                    color: "#fff",
                  },
                },
              }}
            />
            <Tooltip
              componentsProps={{
                popper: {
                  sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                      backgroundColor: (theme) => theme.palette.common.black,
                    },
                    zIndex: "2147483647 !important",
                  },
                },
                zIndex: "2147483647 !important",
              }}
              title="Next "
            >
              <div
                onClick={() => {
                  if (
                    filterPayload?.currentPage <
                    beneOwnerData?.title?.total_pages
                  ) {
                    nextPage();
                  }
                }}
                style={{
                  width: "30px !important",
                  paddingLeft: "10px",
                  cursor: "pointer",
                }}
              >
                <img
                  src={ArrowRight}
                  style={{
                    opacity:
                      filterPayload?.currentPage >=
                        beneOwnerData?.title?.total_pages ||
                      beneOwnerData?.title?.total_pages === undefined
                        ? 0.5
                        : 1,
                  }}
                  alt=""
                  className="arrow-images"
                />
              </div>
            </Tooltip>
          </div>
          <SearchBar
            smallSearch={smallSearch}
            setSmallSearch={setSmallSearch}
            inputRef1={inputRef1}
            removeAccNo={removeAccNo}
            setFilterPayload={setFilterPayload}
            filterPayload={filterPayload}
            setRefreshAnimationId={setRefreshAnimationId}
          />
        </div>
      </div>

      <div className="data_bank_acc_no">
        {refreshAll ? (
          <div className=" mt-20 w-full h-full  bg-opacity-80 flex flex-col items-center justify-center z-[9999]">
            <RefreshAllLoader
              setRefreshAll={setRefreshAll}
              data={transactionFilterData}
              setAccumulatedData={setTransactionFilterData}
              mainData={transactionFilterData}
              total_balance_state={total_balance_state}
              setTotal_balance_state={setTotal_balance_state}
              setFailedRefreshId={setFailedRefreshId}
              setSuccessRefreshId={setSuccessRefreshId}
            />
          </div>
        ) : !beneOwnerLoading && transactionFilterData ? (
          transactionFilterData?.map((val, i) => {
            return (
              <Button
                key={val?.id}
                className="data_bank_acc_no_inner"
                onClick={() => {
                  setTxnPayLoad({
                    ...txnPayLoad,
                    ownerId: val?.id,
                    ownerBankIFSC: val?.ifsc_code,
                    ownerBankBalance: val?.owner_account_balance,
                    ownerBankLogo: val?.bank_name,
                    ownerLastUpdated:
                      val?.owner_account_balance_last_fetched_at_in_words,
                    ownerBankAccountNo: val?.bank_acc_no,
                    settleType: "neft",
                  });
                  setOpenOwner(false);
                  setIsNeft(true);
                  setIsImps(false);
                  setIsRtgs(false);
                  BenEnableNextButton(false);
                  setRefreshAnimationId("");
                }}
              >
                <div className="bank_display_div">
                  <div className="bank_display_div_white">
                    <img
                      src={require("../../../../assets/IfscLogo/" +
                        IfscCodeMapper(val?.ifsc_code))}
                      alt="bank"
                      className="detail-bank-logo"
                      style={{ width: "50px", height: "13px" }}
                    />
                  </div>
                  <div className="bank_display_div_no">
                    <p className="bank_display_div_no_1">
                      {val?.bank_acc_no.toString().substring(0, 4)} ••••{" "}
                      {val?.bank_acc_no
                        .toString()
                        .substring(val?.bank_acc_no?.length - 4)}
                    </p>
                    <p className="bank_display_div_no_2">Account number</p>
                  </div>
                </div>

                <div className="bank_amt_div">
                  <AnimationUpAndDown
                    loader={ownerAccBalanceLoading}
                    currentId={val?.id}
                    previousId={refreshAnimationId}
                    currentBalance={val?.owner_account_balance}
                    previousBalance={previousRefreshData}
                    refreshType={refreshType}
                    allPrevData={previousBalanceData[i]?.previousBalance}
                  />
                  <div className="bank_amt_div_realamt_updated">
                    <p
                      className="bank_amt_div_realamt_updated_1"
                      style={{
                        color:
                          txnPayLoad?.amount > val?.owner_account_balance
                            ? "#FF5860"
                            : "#4DE659",
                      }}
                    >
                      ₹ {<FormatAmount price={val?.owner_account_balance} />}
                    </p>
                    <p className="bank_amt_div_realamt_updated_2">
                      {`Balance updated ${val?.owner_account_balance_last_fetched_at_in_words}`}
                    </p>
                  </div>
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                          zIndex: "2147483647 !important",
                        },
                      },
                      zIndex: "2147483647 !important",
                    }}
                    title="Refresh "
                  >
                    <button
                      disabled={balanceRefreshDisabled}
                      className="bank_amt_div_refresh"
                      onClick={(e) => {
                        handleRefresh(e, val?.id, val?.owner_account_balance);
                      }}
                    >
                      {ownerAccBalanceLoading && refreshId === val?.id ? (
                        <BalanceLoader
                          loader={ownerAccBalanceLoading}
                          type={"createTxn"}
                        />
                      ) : balanceRefresh && refreshId === val?.id ? (
                        <BalanceLoader
                          loader={ownerAccBalanceLoading}
                          setAccId={setRefreshId}
                          type={"createTxn"}
                        />
                      ) : (
                        <img src={BankReload} alt="reload" />
                      )}
                    </button>
                  </Tooltip>
                </div>
              </Button>
            );
          })
        ) : (
          <>
            {beneOwnerLoading && (
              <div className="loading_owner">
                <img src={Loader} alt="loader" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ActiveBanking;
