import React from "react";
import deniedImage from "../../../assets/Beneficiary Icons/Denied_Txn.png";
import createTransaction from "../../../assets/create_transaction.png";
import dashboard from "../../../assets/Dashboard.png";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const BeneTxnFailed = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const createAccountHandler = () => {
    navigate("/transaction/beneficiary_transaction/create");
  };

  const dashboardHandler = () => {
    navigate("/transaction/beneficiary_transaction/dashboard");
  };

  return (
    <div className="min-h-screen bg-[#754545]">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 pt-48">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#754545]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={deniedImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">Transaction failed</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 pt-32 pb-4">
            <div
              className="flex flex-col mx-5 bg-white rounded-t-[32px] rounded-b-[38px] items-center justify-between"
              style={{ height: "540px" }}
            >
              <div className="w-full flex flex-col gap-1 justify-left">
                <div className="flex flex-col font-bold pt-5 pb-3 px-7">
                  <p className="mx-1">tetdgfhfjtyjttjthtreryrrfghjgfjtyj</p>
                  <label className="text-[#707070] font-light text-sm mx-1">
                    Error message
                  </label>
                </div>
              </div>
              <div className="w-full divide-x-2 cursor-pointer text-white rounded-4xl bg-[#985555] flex justify-evenly items-center p-4">
                <div
                  className="flex flex-col space-y-1 w-48 h-3/4 items-center justify-center text-center"
                  onClick={createAccountHandler}
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"40%"}
                      height={"40%"}
                      src={createTransaction}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Create
                    <br />
                    transaction
                  </p>
                </div>

                <div
                  onClick={dashboardHandler}
                  className="flex flex-col space-y-1 w-48 h-3/4 items-center justify-center text-center"
                >
                  <div className="w-3/6 flex justify-center">
                    <img
                      width={"40%"}
                      height={"40%"}
                      src={dashboard}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p className="text-xs">
                    Go to <br /> dashboard
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnFailed;
