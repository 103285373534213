import { Box } from '@mui/material';
import React from 'react'
import logo from "../../assets/TransactionDashboardIcons/InstantPay card icon.png";
import formatCurrency from '../../utils/CurrencyFormater';
import "./index.css";

const InstanPayAvailBal = (props) => {
  const availbal = formatCurrency(props.availbal);
  return (
    <Box className="InstaAccSumContainer">
      <div className='Insta-avail-balance'> <span className='text-[35px]'>₹</span> {availbal}
        <br />
        <span className='Insta-avail-balance-subtitle'>Available Balance</span>
      </div>
      <div>
        <div className='flex flex-col justify-end h-full'>
          <div className="Instaflex1">
            <img alt="logo" src={logo} height="35" width="46" />
          </div>
        </div>
      </div>
    </Box>
  );
}






export default InstanPayAvailBal