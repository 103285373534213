import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  unachivedAccount,
  achivedAccount,
  beneLookUp,
  viewAccountDetail,
  resetAchivedAccount,
  resetUnAchivedAccount,
} from "../../redux/features/verifyPAN/verifyPAN";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { beneBankLookUp } from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../redux/features/login/login";

const BeneficiaryModal = ({
  close,
  id,
  type,
  setAccountType,
  message,
  panNumber,
  setIsArchived,
  setShutViewAll,
  setBankAccountType,
  setBankIsArchived,
  setBankShutViewAll,
  payload,
  createFlow,
  setShowAction,
  title,
  note,
  bankAccountType,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAchived, setIsArchivedCurrent] = useState(false);
  const [bankIsAchived, setBankIsArchivedCurrent] = useState(false);
  const [isUnAchived, setIsUnArchived] = useState(false);
  const [bankIsUnAchived, setBankIsUnArchived] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { unachivedLoading, achivedLoading, unachivedData, achivedData } =
    useSelector((state) => state.verify);

  const handlebank = async () => {
    if (type === "unarchived") {
      dispatch(checkUsertoken());
      await dispatch(unachivedAccount({ id }));
      setBankIsArchived(false);
    } else if (type === "archived") {
      dispatch(checkUsertoken());
      await dispatch(achivedAccount({ id }));
    }
    setBankShutViewAll(false);
  };

  const handlePan = async () => {
    if (type === "unarchived") {
      dispatch(checkUsertoken());
      dispatch(unachivedAccount({ id }));
      navigate(`/accounts/beneficiary_account/details/${id}`);
      setIsArchived(false);
    } else if (type === "archived") {
      dispatch(checkUsertoken());
      dispatch(achivedAccount({ id }));
    }
    setShutViewAll(false);
  };

  const displayToastError = (errorObject) => {
    if (errorObject) {
      const errorMessages = Object.values(errorObject).flat();
      if (errorMessages.length > 0) {
        toast.error(errorMessages[0], {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  useEffect(() => {
    if (!achivedLoading) {
      if (achivedData?.status === true) {
        if (createFlow === "bank") {
          setBankIsArchivedCurrent(true);
          dispatch(checkUsertoken());
          dispatch(
            beneBankLookUp({
              ifsc: payload?.ifsc_code,
              bank_account_no: payload?.bank_account,
            })
          );
          toast.success("Account archived successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (createFlow === "pan") {
          setIsArchivedCurrent(true);
          dispatch(checkUsertoken());
          dispatch(beneLookUp({ pan_number: panNumber }));
          toast.success("Account archived successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else if (achivedData?.status === false) {
        setIsArchivedCurrent(false);
        setBankIsArchivedCurrent(false);
        displayToastError(achivedData?.errors);
      }
    }
  }, [achivedData]);

  useEffect(() => {
    if (!unachivedLoading) {
      if (unachivedData?.status === true) {
        if (createFlow === "bank") {
          setBankIsUnArchived(true);
          dispatch(checkUsertoken());
          dispatch(
            beneBankLookUp({
              ifsc: payload?.ifsc_code,
              bank_account_no: payload?.bank_account,
            })
          );
          toast.success("Account unarchived successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (createFlow === "pan") {
          setIsUnArchived(true);
          dispatch(checkUsertoken());
          dispatch(beneLookUp({ pan_number: panNumber }));
          toast.success("Account unarchived successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else if (unachivedData?.status === false) {
        setIsUnArchived(false);
        setBankIsUnArchived(false);
        displayToastError(unachivedData?.errors);
      }
    }
  }, [unachivedData]);

  const handleConfirm = async () => {
    setIsButtonDisabled(true);
    try {
      if (createFlow === "bank") {
        await handlebank(); // Wait for handlebank() to complete
        setBankAccountType({ type: "", id: "" });
      } else if (createFlow === "pan") {
        await handlePan();
        setAccountType({ type: "", id: "" });
      }
      close(false);
      setShowAction(false);
    } catch (error) {
    } finally {
      dispatch(resetAchivedAccount());
      dispatch(resetUnAchivedAccount());
      setIsButtonDisabled(false); // Re-enable the button regardless of success or failure
    }
  };

  return (
    <>
      <div
        className="fixed w-screen top-0 left-0 h-screen z-[9998] "
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-70% flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl"
          style={{ width: "400px", maxWidth: "600px" }}
        >
          <div className="mx-4">
            <h6 className="text-custom-orange pb-1 md:font-bold">{title}</h6>
            <div className="flex items-center pb-2"></div>
            <div className="mb-3">
              <span
                className="text-white text-sm"
                style={{ wordWrap: "break-word" }}
              >
                {message}
              </span>
              <p
                style={{
                  color: "white",
                  fontSize: "10px",
                  marginTop: "1rem",
                  color: "#C9C9C9",
                  opacity: "1",
                }}
              >
                Note - {note}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6%",
                margin: "0px 50px",
                width: "100%",
              }}
              className="px-4 pt-4 m-4"
            >
              <Button
                onClick={() => {
                  close(false);
                }}
                sx={{
                  borderColor: "none",
                  color: "#fff",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isButtonDisabled}
                sx={{
                  borderRadius: "20px",
                  color: "#fff !important",
                  boxShadow: "0px 3px 6px #00000033",
                  textTransform: "capitalize",
                  backgroundColor: "#69B76F",
                  border: "1px solid transparent",
                  "&:hover": {
                    backgroundColor: "#69B76F",
                  },
                  marginRight: "30px",
                  width: "110px",
                }}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryModal;
