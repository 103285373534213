import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/Settings/Dashboard/cancel.png";
import search from "../../../assets/Settings/Dashboard/search.png";
import cin from "../../../assets/Settings/Dashboard/blue_cin.png";
import singleuser from "../../../assets/Settings/Dashboard/single_user.png";
import boxright from "../../../assets/Settings/Dashboard/box_right.png";
import bgDefault from "../../../assets/Settings/Dashboard/bg_default.png";
import loader from "../../../assets/Settings/Dashboard/loader.gif";
import notFound from "../../../assets/Settings/Dashboard/not_found.png";
import GeoMapLeaflet from "../../../components/_utils/GeoMapLeaflet";
import {
  verifyThisCIN,
  resetGetCinData,
  selectedDin,
  resetSelectedCin,
  verifyThisDIN,
  resetGetCinError,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import { Tooltip, tooltipClasses } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CINVerification = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(0);
  const AddressFormatter = ({ address }) => {
    const addressParts = address.split(",");

    const groupedParts = [];
    for (let i = 0; i < addressParts.length; i += 2) {
      groupedParts.push(addressParts.slice(i, i + 2).join(","));
    }

    return (
      <div>
        {groupedParts.map((part, index) => (
          <div key={index}>{part.trim()}</div>
        ))}
      </div>
    );
  };
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // All teen numbers end with 'th'
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    const ordinalSuffix = getOrdinalSuffix(day);
    return `${day}${ordinalSuffix} ${month} ${year}`;
  };

  const DateConverter = ({ date }) => {
    const formattedDate = convertDate(date);
    return <div>{formattedDate}</div>;
  };
  const dispatch = useDispatch();
  const [cinNo, setCinNo] = useState("");
  const handleCin = (e) => {
    if (e.target.value.length === 0) {
      dispatch(resetGetCinData());
      dispatch(resetSelectedCin());
    }
    if (e.target.value.length > 21) {
      return;
    }
    if (!specialRegex.test(e.target.value)) {
      return;
    }
    setCinNo(e.target.value.toUpperCase());
  };

  useEffect(() => {
    if (selectedCinData) {
      setCinNo(selectedCinData);
    }
    return () => {
      dispatch(resetSelectedCin());
      dispatch(resetGetCinData());
    };
  }, []);

  const inputRef = useRef("");
  const { verifyCIN, verifyCinError, verifyCINisLoading, selectedCinData } =
    useSelector((state) => state.verify);

  const cinRegex = /^[A-Z]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/;
  const specialRegex = /^[a-zA-Z0-9 ]*$/;

  const handleCinClick = () => {
    if ((!cinRegex.test(cinNo) && cinNo.length === 21) || cinNo.length < 21) {
      dispatch(resetGetCinError());
      toast.error("Invalid CIN", toast_position);
    } else if (cinNo.length === 21) {
      dispatch(resetGetCinData());
      dispatch(checkUsertoken());
      dispatch(resetSelectedCin());
      dispatch(verifyThisCIN({ search_string: cinNo }));
    }
    setActiveButton(0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && cinNo.length === 21) {
      handleCinClick();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [cinNo]);

  return (
    <div className="cin_main_div">
      <div className="cin_search">
        <div className="cin_search_1024">
          <div className="cin_input_div">
            <input
              ref={inputRef}
              value={cinNo}
              onChange={handleCin}
              placeholder="Enter CIN number"
            />
            {cinNo.length === 0 ? (
              <img
                alt="search"
                src={search}
                onClick={() => inputRef.current.focus()}
              />
            ) : (
              <img
                alt="cancel"
                src={cancel}
                onClick={() => {
                  setCinNo("");
                  dispatch(resetGetCinData());
                  dispatch(resetSelectedCin());
                }}
              />
            )}
          </div>
          <Button
            className="cin_fetch_button"
            disabled={cinNo.length !== 21}
            onClick={handleCinClick}
            style={{
              color: cinNo.length === 21 ? "#1d3a6d" : "#F9F9F9",
              background: cinNo.length === 21 ? "#f9f9f9" : "#707070",
            }}
          >
            Fetch Details
          </Button>
        </div>
      </div>
      {verifyCINisLoading ? (
        <div className="cin_loading_loader">
          <img
            src={loader}
            alt="inputLoader"
            style={{
              width: "60px",
              height: "42px",
              maxWidth: "60px",
              maxHeight: "42px",
            }}
          />
        </div>
      ) : !verifyCINisLoading && verifyCIN?.status ? (
        <>
          <div className="cin_details">
            <div className="cin_details_1024">
              <div className="cin_details_1">
                <div className="cin_details_1_left">
                  <h4 className="lowercase first-line:capitalize">
                    {verifyCIN?.cin_details?.company_name}
                  </h4>
                  <h6>Company name</h6>
                </div>
                <div className="cin_details_1_right">
                  <div className="cin_details_1_right_cin_no">
                    <h4>{verifyCIN?.cin_details?.cin_number}</h4>
                    <h6>CIN number</h6>
                  </div>
                  <div className="cin_img_div">
                    <img src={cin} alt="cin" />
                  </div>
                </div>
              </div>
              <div className="cin_details_1">
                <div className="cin_details_1_left">
                  <h4>
                    <DateConverter
                      date={verifyCIN?.cin_details?.incorporation_date}
                    />
                  </h4>
                  <h6>Estd. on</h6>
                </div>
                <div className="cin_details_1_right">
                  <div className="cin_details_1_right_cin_no">
                    <h4>{verifyCIN?.cin_details?.company_status}</h4>
                    <h6>Company status</h6>
                  </div>
                </div>
              </div>
              <div className="cin_details_1" style={{ borderBottom: "none" }}>
                <div className="cin_details_1_left">
                  <h4>
                    {verifyCIN?.cin_details?.company_class === "PUBC"
                      ? "Public"
                      : "Private"}
                  </h4>
                  <h6>Listing</h6>
                </div>
                <div className="cin_details_1_right">
                  <div className="cin_details_1_right_cin_no">
                    <h4>{verifyCIN?.cin_details?.listed_status}</h4>
                    <h6>Listed status</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cin_tabs">
            <div className="cin_tabs_1024">
              <div className="cin_tabs_button ">
                <Button
                  className="cin_tabs_button_1  "
                  style={{
                    background: activeButton === 0 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 0 ? "#3A3649" : "#F9F9F9",
                    font:
                      activeButton === 0
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  onClick={() => {
                    setActiveButton(0);
                  }}
                >
                  Details
                </Button>
                <Button
                  style={{
                    background: activeButton === 1 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 1 ? "#3A3649" : "#F9F9F9",
                    font:
                      activeButton === 1
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  className="cin_tabs_button_1 "
                  onClick={() => {
                    setActiveButton(1);
                  }}
                >
                  Directors
                </Button>
                <Button
                  style={{
                    background: activeButton === 2 ? "#F9F9F9" : "#373F4B",
                    color: activeButton === 2 ? "#3A3649" : "#F9F9F9",
                    font:
                      activeButton === 2
                        ? "normal normal 600 12px/17px Open Sans"
                        : "normal normal normal 12px/17px Open Sans",
                  }}
                  className="cin_tabs_button_1 "
                  onClick={() => {
                    setActiveButton(2);
                  }}
                >
                  Address
                </Button>
              </div>

              {activeButton === 0 ? (
                <div className="cin_details_slide">
                  <div className="cin_details_slide_con_cap">
                    <h4 style={{ fontWeight: "bold", color: "white" }}>
                      Contact Details
                    </h4>
                    <div className="cin_details_slide_con_cap_customer_no">
                      <h5>
                        {verifyCIN?.cin_details?.phone_number === ""
                          ? "Not provided"
                          : verifyCIN?.cin_details?.phone_number}
                      </h5>
                      <h6>Contact number</h6>
                    </div>
                    <div className="cin_details_slide_con_cap_email">
                      <h5 id="email-id-firstLetter">
                        {verifyCIN?.cin_details?.email}
                      </h5>
                      <h6>Email ID</h6>
                    </div>
                  </div>
                  <div className="cin_details_slide_con_cap">
                    <h4 style={{ fontWeight: "bold", color: "white" }}>
                      Capital Details
                    </h4>
                    <div className="cin_details_slide_con_cap_customer_no">
                      <h5>
                        ₹{" "}
                        {
                          <FormatAmount
                            price={verifyCIN?.cin_details?.authorized_capital}
                          />
                        }
                      </h5>
                      <h6>Authorized capital</h6>
                    </div>
                    <div className="cin_details_slide_con_cap_email">
                      <h5>
                        ₹{" "}
                        {
                          <FormatAmount
                            price={verifyCIN?.cin_details?.paid_up_capital}
                          />
                        }
                      </h5>
                      <h6>Paid up capital</h6>
                    </div>
                  </div>
                </div>
              ) : activeButton === 1 ? (
                <div className="cin_directors_slide">
                  {verifyCIN?.cin_details?.directors?.map((data, i) => {
                    return (
                      <div
                        className="cin_directors_box"
                        key={i}
                        onClick={() => {
                          dispatch(selectedDin(data?.din_number));
                          dispatch(
                            verifyThisDIN({
                              search_string: data?.din_number,
                            })
                          );
                          dispatch(resetGetCinData());
                          navigate("/settings/DIN_Verification");
                        }}
                      >
                        <div className="cin_directors_box_img">
                          <img src={singleuser} alt="user" />
                          <h4 className="lowercase first-line:capitalize">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      textTransform: "capitalize",
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={data?.director_name}
                            >
                              {" "}
                              {data?.director_name}
                            </Tooltip>
                          </h4>
                        </div>
                        <div className="cin_directors_box_details">
                          <div className="cin_directors_box_details_left">
                            <h5>{data?.din_number}</h5>
                            <h6>DIN number</h6>
                          </div>
                          <img alt="right" src={boxright} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="cin_address_slide">
                  <div className="cin_address_slide_left">
                    <h5>Corporate Address</h5>
                    <p className="lowercase first-line:capitalize">
                      <AddressFormatter
                        address={verifyCIN?.cin_details?.company_address}
                      />
                    </p>
                  </div>
                  <div className="cin_address_slide_right">
                    <GeoMapLeaflet
                      address={verifyCIN?.cin_details?.company_address}
                      owner="cin_details"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : !verifyCINisLoading && verifyCinError === true ? (
        <div className="cin_not_found">
          <img src={notFound} alt="notfound" />
          <p>
            No company found.
            <br /> Please check the CIN number entered above.
          </p>
        </div>
      ) : (
        <div className="cin_default_img">
          <img src={bgDefault} alt="bgDefault" />
          <p>
            Enter CIN number in the search bar, to fetch the verified corporate
            profile details.
          </p>
        </div>
      )}
    </div>
  );
};

export default CINVerification;
