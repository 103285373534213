import React, { useState } from "react";
import CustomizedStepper from "../../../../components/CreateTxn/CustomizedStepper";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addCard } from "../../../../redux/features/createClientAcc/createClientAcc";
import EnterCardDetails from "../../../../components/Accounts/CardAccount/AddCard";
import VisibilityCard from "../../../../components/Accounts/CardAccount/VisibilityCard";
import PaymentGateway from "../../../../components/PaymentGateway";

const AddCard = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    billing_cycle_day: "",
    card_name: "",
    card_number: "",
    cvv: "",
    entity_id: "",
    expiry_month: "",
    expiry_year: "",
    expiry_date: "",
    user_id: "",
  });

  const steps = ["Enter card details"];

  const handleNext = () => {
    if (activeStep === 0) {
      dispatch(addCard({ payload: payload }));
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    enableNextButton(false);
  };

  const handleBack = () => {
    if (activeStep === 0) navigate(-1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="bg-[#744991] flex flex-row justify-center overflow-y-auto overflow-x-hidden w-full hide-scrollbar vendor-txn min-h-screen">
      <div className="self-center pt-24 h-screen w-10/12">
        <div className="h-fit flex flex-col gap-2 3xl:gap-4 5xl:gap-6 py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20">
          <div className="h-[15%] flex flex-row justify-center">
            <CustomizedStepper
              nextButtonEnabled={nextButtonEnabled}
              activeStep={activeStep}
              steps={steps}
              container="clientAcc"
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </div>
          <PaymentGateway />
          <div className="h-4/5 flex flex-row justify-center pb-32">
            <div className="w-10/12 h-full">
              {activeStep === 0 && (
                <div className="flex justify-center gap-8 p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EnterCardDetails
                      payload={payload}
                      setPayload={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep}
                      editable={true}
                      title="Card details"
                      container="clientAcc"
                    />
                  </div>
                  <div className="w-1/2 h-fit">
                    <VisibilityCard
                      payload={payload}
                      setPayload={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep}
                      editable={true}
                      title="Visibility"
                      container="clientAcc"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
