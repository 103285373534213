import React, { useEffect, useState } from "react";
import CustomizedStepper from "../../../../components/CreateTxn/CustomizedStepper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EnterContactDetails from "../../../../components/Accounts/Vendor/EnterContactDetails";
import { createAcc } from "../../../../redux/features/createClientAcc/createClientAcc";
import AccCreateStatus from "../../CreateResponse";

const CreateClientAccount = () => {
  const dispatch = useDispatch();
  const [showStatus, setShowStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdAcc = useSelector(
    (state) => state.createAllAcc.createAccResponse
  );
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    entity_name: "",
    ifsc_code: "",
    bank_acc_no: "",
    contact_no: "",
    email_id: "",
    name: "",
    gstin: "",
  });

  const steps = ["Enter client details"];

  const handleNext = () => {
    if (activeStep === 0) {
      dispatch(createAcc({ type_of_txn: "client", payload: payload }));
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    enableNextButton(false);
  };

  const handleBack = () => {
    if (activeStep === 0) navigate(-1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (createdAcc?.status) setShowStatus(true);
  }, [createdAcc]);

  return showStatus ? (
    <AccCreateStatus
      title="Client account created successfully!"
      data={{
        title: createdAcc.clients[0]?.name,
        body: [
          {
            value: createdAcc.clients[0]?.contact_no,
            subtitle: "Phone",
            img: "/assets/CreateTransactionIcons/whatsapp.png",
          },
          {
            value: createdAcc.clients[0]?.email_id,
            subtitle: "Email ID",
            img: "/assets/CreateTransactionIcons/gmail.png",
          },
        ],
        links: [
          {
            title: "Create new account",
            link: 0,
            icon: "/assets/CreateTransactionIcons/Create new account.png",
          },
          {
            title: "Create transaction",
            link:
              "/create_transaction/client_transaction?client_id=" +
              createdAcc.clients[0]?.client_id,
            icon: "/assets/CreateTransactionIcons/Create transaction.png",
          },
          {
            title: "View account details",
            link: `/accounts/client/details/${createdAcc.clients[0]?.client_id}`,
            icon: "/assets/CreateTransactionIcons/View details.png",
          },
          {
            title: "Go to dashboard",
            link: -1,
            icon: "/assets/CreateTransactionIcons/Dashboard.png",
          },
        ],
      }}
    />
  ) : (
    <div className="bg-[#744991] flex flex-row justify-center overflow-y-auto overflow-x-hidden w-full hide-scrollbar vendor-txn min-h-screen">
      <div className="self-center pt-24 h-screen w-10/12">
        <div className="h-fit flex flex-col gap-2 3xl:gap-4 5xl:gap-6 py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20">
          <div className="h-[15%] flex flex-row justify-center">
            <CustomizedStepper
              nextButtonEnabled={nextButtonEnabled}
              activeStep={activeStep}
              steps={steps}
              container="clientAcc"
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </div>
          <div className="h-4/5 flex flex-row justify-center pb-24">
            <div className="w-10/12 h-full">
              {activeStep === 0 && (
                <div className="flex justify-center gap-8 p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EnterContactDetails
                      payload={payload}
                      setPayload={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep}
                      editable={true}
                      title="Client contact details"
                      container="clientAcc"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateClientAccount;
