import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action
export const getInstantpayDashboard = createAsyncThunk(
  "getInstantpayDashboard",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/Instant_pay/dashboard`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getVendorDashboard = createAsyncThunk(
  "getVendorDashboard",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/vendor/dashboard`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getFieldForceDashboard = createAsyncThunk(
  "getFieldForceDashboard",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/dashboard`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getInternalDashboard = createAsyncThunk(
  "getInternalDashboard",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/owner/internal_fund_transfer/dashboard`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getClientDashboard = createAsyncThunk(
  "getClientDashboard",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/client/dashboard`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getReimbursementDashboard = createAsyncThunk(
  "getReimbursementDashboard",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/reimbursement/dashboard`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const txnDashBoardSlice = createSlice({
  name: "txnDashBoard",
  initialState: {
    isLoading: false,
    instantpay: null,
    vendor: null,
    fieldForce: null,
    internal: null,
    client: null,
    reimbursement: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //Instantpay
    builder.addCase(getInstantpayDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInstantpayDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.instantpay = action.payload;
    });
    builder.addCase(getInstantpayDashboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Vendor
    builder.addCase(getVendorDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(getVendorDashboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //FieldForce
    builder.addCase(getFieldForceDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFieldForceDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldForce = action.payload;
    });
    builder.addCase(getFieldForceDashboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Internal
    builder.addCase(getInternalDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInternalDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internal = action.payload;
    });
    builder.addCase(getInternalDashboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Client
    builder.addCase(getClientDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(getClientDashboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Reimbursement
    builder.addCase(getReimbursementDashboard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReimbursementDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbursement = action.payload;
    });
    builder.addCase(getReimbursementDashboard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default txnDashBoardSlice.reducer;

