import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TxnDetailsBanner from "../../../components/TxnDetails/InstantPayDetails/TxnDetailsBannerCard";
import "./index.css";
import QuickAccessMenu from "../../../components/TxnDetails/QuickAccessMenu";
import { useDispatch, useSelector } from "react-redux";
import { getInstantpayViewDetail } from "../../../redux/features/txnDetails/txnDetails";
import {
  VendorTxnDetailBgMapper,
  VendorTxnDetailDarkBgMapper,
  VendorTxnDetailChildBgMapper,
} from "../../../services/VendorTxnDetailBgMapper";
import TimeLine from "../../../components/TxnDetails/TimeLine";
import TxnDetailsToggleButtons from "../../../components/TxnDetails/TxnDetailsToggleButtons";
import { Link, useParams } from "react-router-dom";
import PayMethod from "../../../components/TxnDetails/PayMethod";
import MoreActions from "../../../components/TxnDetails/MoreActions";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import AddStatus from "../../../components/TxnDetails/MoreActions/AddStatus";
import ConvertToInstantPay from "../../../components/TxnDetails/MoreActions/ConvertToInstantPay";
import SupportInfo from "../../../components/TxnDetails/SupportInfo";
import { approveVendorTxn } from "../../../redux/features/approveTxn/approveTxn";
import DeniedReason from "../../../components/ApprovalTxn/VendorApproval/DeniedReson/DeniedReason";
import { getVendorPendingTxn } from "../../../redux/features/pendingTxn/pendingTxn";
import VendorDeniedReasonComponent from "../../../components/TxnDetails/VendorDeniedReasonComponent";
import SettlePayment from "../../SettlementRequired/SettlePayment";
import { PaymentGatewayMapper } from "../../../services/paymentGatewayMapper";
import { toast } from "react-toastify";
import DeleteButton from "../../../components/TxnDetails/DeleteButton";
import { Diversity1Rounded } from "@mui/icons-material";
import IPSupportInfoCard from "../../../components/TxnDetails/IPSupportInfoCard";

const IPTxnDetails = () => {
  const [toggleView, setToggleView] = useState("details");
  const dispatch = useDispatch();
  const txnDetail = useSelector((state) => state.txnDetails.instantpay);
  const actionableTxns = useSelector((state) => state.pendingTxn.vendor);
  const data = txnDetail ? txnDetail.transactions[0] : null;
  const { id } = useParams();
  const [showAddStatus, setShowAddStatus] = useState(false);
  const [showConvertToInstantPay, setShowConvertToInstanyPay] = useState(false);
  const [deniedReason, setDeniedReason] = useState(false);
  const actionableTxnsID = actionableTxns?.actionables
    ? actionableTxns?.actionables.map((txn) => txn?.transaction?.id)
    : [];
  const isActionable = actionableTxnsID?.includes(id);
  const [selectedTxnCarousel, setSelectedTxnCarousel] = useState({});
  const [showSettlementComponent, setShowSettlementComponent] = useState(false);
  const addStatusRes = useSelector((state) => state.addStatusState?.response);
  const [checkRes, setCheckRes] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    dispatch(getInstantpayViewDetail({ txn_id: id }));
  }, [dispatch, addStatusRes, data?.approval_status, id, Link]);

  const handleToggleView = (view) => {
    setToggleView(view);
  };

  useEffect(() => {
    if (checkRes && addStatusRes?.errors) {
      toast.error(Object.values(addStatusRes.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && addStatusRes?.status) {
      toast.success("Transaction status update successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setCheckRes(true);
  }, [addStatusRes]);

  return !showSettlementComponent ? (
    <Box className="flex flex-col-reverse xl:flex-row min-h-screen">
      {deniedReason && (
        <DeniedReason
          txnId={[data?.txn_id]}
          setDeniedReason={setDeniedReason}
          dispatchFn={approveVendorTxn}
          navigatePath={"/vendor/approval_status"}
        />
      )}
      {showAddStatus && (
        <AddStatus setState={setShowAddStatus} txn_id={data?.txn_id} />
      )}
      {showConvertToInstantPay && (
        <ConvertToInstantPay
          setState={setShowConvertToInstanyPay}
          txn_id={data?.txn_id}
        />
      )}
      {/** Left Container */}
      <Box
        className="
            h-screen overflow-y-auto overflow-x-hidden hide-scrollbar
            flex flex-row justify-center w-full
            xl:w-8/12 drop-shadow-2xl"
        sx={{ bgcolor: VendorTxnDetailBgMapper(data?.approval_status) }}
      >
        <div
          className="mt-24 h-fit-content
            px-4 3xl:px-6 4xl:px-12 py-4
            w-full xl:w-[95%] flex flex-col
            justify-items-center"
        >
          {/** Left Top Banner */}
          <div className="h-[35%] p-4 3xl:p-6 4xl:p-12 5xl:p-16 6xl:p-18">
            <div className="h-full flex flex-col justify-between">
              <div className="h-full">
                <TxnDetailsBanner
                  setSelectedTxnCarousel={setSelectedTxnCarousel}
                  setShowSettlementComponent={setShowSettlementComponent}
                  vendor_name={data?.vendor_name}
                  posting_date={data?.posting_date}
                  approval_status={data?.approval_status}
                  amount={data?.amount}
                  bank_logo={
                    data?.settlement_type === "HDFC NEFT/RTGS"
                      ? bankLogoMapper(data?.owner_bank_name)
                      : data?.settlement_type === "Instant Pay"
                      ? "Instant Pay"
                      : data?.settlement_type === "Registered Card"
                      ? data?.card_type || "Default Card"
                      : null
                  }
                  bank_acc_no={
                    data?.settlement_type === "HDFC NEFT/RTGS"
                      ? data?.owner_bank_acc_no
                      : data?.settlement_type === "Registered Card"
                      ? data?.ending_with
                      : data?.settlement_type === "Instant Pay"
                      ? "Instant Pay"
                      : null
                  }
                  txn_id={id}
                  data={data}
                  setDeniedReason={setDeniedReason}
                  isActionable={isActionable}
                />
              </div>
            </div>
          </div>
          <div
            className="w-full flex h-[5%]
              px-4 3xl:px-6 4xl:px-12 5xl:px-16 6xl:px-18 self-start"
          >
            <TxnDetailsToggleButtons
              handleToggleView={handleToggleView}
              toggleView={toggleView}
            />
          </div>
          <Box className="quickAccessMenuMobile">
            <QuickAccessMenu
              bgColor={VendorTxnDetailBgMapper(data?.approval_status)}
              addStatus={setShowAddStatus}
              convertToInstantPay={setShowConvertToInstanyPay}
            />
          </Box>
          <div
            className="h-[56%]
              p-4 3xl:p-6 4xl:p-12 5xl:p-16 6xl:p-18
              flex flex-col"
          >
            {/** Left Body */}
            {toggleView === "timeline" ? (
              <div className="pb-8 3xl:pb-10 4xl:pb-12 5xl:pb-16 6xl:pb-20">
                <TimeLine approval_status={data?.approval_status} />
              </div>
            ) : (
              <div className="h-full flex flex-col">
                <div className="h-[45%]">
                  <IPSupportInfoCard
                    settle_id={data?.settle_id}
                    txn_id={data?.txn_id}
                    approval_status={data?.approval_status}
                  />
                </div>
              </div>
            )}
            <Box className="quickAccessMenuMobile">
              <Box>More Actions</Box>
              <MoreActions />
            </Box>
          </div>
        </div>
      </Box>
      {/** Right Side */}
      <div
        className="h-screen
            overflow-x-hidden overflow-y-auto hide-scrollbar
            flex flex-col
            w-full xl:w-4/12"
        style={{
          backgroundColor: VendorTxnDetailDarkBgMapper(data?.approval_status),
        }}
      >
        <div
          className="mt-24
            w-full xl:w-[95%]
            px-4 3xl:px-6 4xl:px-12 py-4
            flex flex-col justify-items-center"
        >
          <div className="p-4 3xl:p-6 4xl:p-12 5xl:p-16 6xl:p-18">
            <Box
              className="h-full drop-shadow-2xl flex flex-col
                              rounded-4xl 3xl:rounded-large 5xl:rounded-customXl
                              overflow-hidden"
              sx={{
                backgroundColor: VendorTxnDetailChildBgMapper(
                  data?.approval_status
                ),
              }}
            >
              <div
                className="w-8/12 flex justify-start
                  text-base 3xl:text-lg 4xl:text-xl 6xl:text-xl font-bold opacity-80
                  text-white items-center antaliased  p-6 2xl:p-8 3xl:p-8 4xl:p-12 5xl:p-16 6xl:p-18"
              >
                More Actions
              </div>
              <div className="w-full flex justify-center">
                <MoreActions
                  module="IP"
                  addStatus={setShowAddStatus}
                  convertToInstantPay={setShowConvertToInstanyPay}
                  status={data?.approval_status}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
    </Box>
  ) : (
    <SettlePayment getSelected={() => selectedTxnCarousel} module="IPsettle" />
  );
};

export default IPTxnDetails;
