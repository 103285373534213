import React from "react";

const SupportInfo = (props) => {
  const bgColor = props.bg_color;
  const accountId = props.account_id;
  const accountType = props.account_type;

  return (
    <div
      className="drop-shadow-xl flex flex-col gap-4 rounded-4xl px-8 py-4"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="flex h-1/3">
        <div className="self-center text-gray-300 text-sm font-bold">
          Support Info
        </div>
      </div>

      <div className={`flex flex-col divide-y divide-[#1d1d1d55]`}>
        <div className="py-2">
          <div className="text-sm text-white antialiased truncate ...">
            {accountId}
          </div>
          <div className="text-gray-300 text-xs antialiased">Account ID</div>
        </div>
        {accountType === "owner" ? (
          <div className="py-2">
            <div className="text-sm text-white antialiased truncate ...">
              {props?.wire_transfer_ref}
            </div>
            <div className="text-gray-300 text-xs antialiased">
              Wire transfer reference no.
            </div>
          </div>
        ) : (
          <> </>
        )}
      </div>
    </div>
  );
};

export default SupportInfo;
