import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const deleteVendorTxn = createAsyncThunk(
  "deleteTxn",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: values?.txn_id
      }),
    };
    return await fetch(
      `${legacyUrl}/transactions/single/Delete`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

export const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        account_id: values?.account_id
      }),
    };
    console.log("ACCOUNT DELETE OPTIONS", requestOptions);
    return await fetch(
      `${legacyUrl}/accounts/delete`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

const deleteTxn = createSlice({
  name: "deleteTxn",
  initialState: {
    isLoading: false,
    deleteResponse: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(deleteVendorTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteVendorTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deleteResponse = action.payload;
    });
    builder.addCase(deleteVendorTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(deleteAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.deleteResponse = action.payload;
      if (action.payload?.status) {
        toast.success("Account archieved successfully!", toast_position);
      }
      if (action.payload?.error) {
        toast.error(action.payload.errors, toast_position);
      }
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default deleteTxn.reducer;
