import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action
export const getInstantpayPendingTxn = createAsyncThunk(
  "getInstantpayPendingTxn",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "P",
        page_no: null,
        page_size: null,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/Instant_pay/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getVendorPendingTxn = createAsyncThunk(
  "getVendorPendingTxn",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: values?.page_no || 1,
        page_size: 50,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/vendor/actionable`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getFieldForcePendingTxn = createAsyncThunk(
  "getFieldForcePendingTxn",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "P",
        page_no: values?.page_no || 0,
        page_size: 50,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getInternalPendingTxn = createAsyncThunk(
  "getInternalPendingTxn",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "P",
        page_no: null,
        page_size: null,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/owner/internal_fund_transfer/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getClientPendingTxn = createAsyncThunk(
  "getClientPendingTxn",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: values?.page_no || 1,
        page_size: 50,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/client/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getReimbursementPendingTxn = createAsyncThunk(
  "getReimbursementPendingTxn",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "P",
        page_no: 0,
        page_size: 50,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/reimbursement/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const pendingTxnSlice = createSlice({
  name: "pendingTxn",
  initialState: {
    isLoading: false,
    instantpay: null,
    vendor: null,
    fieldForce: null,
    internal: null,
    client: null,
    reimbursement: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //Instantpay
    builder.addCase(getInstantpayPendingTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInstantpayPendingTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.instantpay = action.payload;
    });
    builder.addCase(getInstantpayPendingTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Vendor
    builder.addCase(getVendorPendingTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorPendingTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(getVendorPendingTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //FieldForce
    builder.addCase(getFieldForcePendingTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFieldForcePendingTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldForce = action.payload;
    });
    builder.addCase(getFieldForcePendingTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Internal
    builder.addCase(getInternalPendingTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInternalPendingTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internal = action.payload;
    });
    builder.addCase(getInternalPendingTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Client
    builder.addCase(getClientPendingTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientPendingTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(getClientPendingTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Reimbursement
    builder.addCase(getReimbursementPendingTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReimbursementPendingTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbursement = action.payload;
    });
    builder.addCase(getReimbursementPendingTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default pendingTxnSlice.reducer;








