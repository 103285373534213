
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVendorAccounts } from "../../redux/features/accounts/accounts";
import "./index.css";
import RegisterNewVedor from "./RegisterNewVedor";
import SelectExistingVendor from "./SelectExistingVendor";
import SelectedVendorAccount from "./SelectedVendorAccount";
import VendorDetails from "./VendorDetails";

import { setBufferState } from "../../redux/features/bufferState/bufferState";

const CreateFFTxnForm = (props) => {
  const dispatch = useDispatch();
  const selectedAcc = useSelector((state) => state.bufferState.vendor);
  const linkUser = useSelector((state) => state.bufferState.linkUser);
  const [open, setOpen] = useState(false);
  const [vendorDetailToggle, setVendorDetailToggle] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const handleRegister = () => {
    props.setRegisterNewVnerdorData({
      vendor_id: "",
      pan_no: "",
      bank_acc_no: "",
      ifsc_code: "",
      contact_no: "",
      name: "",
      skip_account_verification: "",
      email_id: "",
      gstin: "",
    });
    setOpenRegister(true);
    setOpen(false);
    dispatch(setBufferState({ vendor: null }));
  };
  const handleOpen = (type) => {
    if (type) setOpen(type);
    else {
      setOpen(true);
      setOpenRegister(false);
    }
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (selectedAcc?.vendor_id) {
      setVendorDetailToggle(true);
    }
  });

  const registeredDataEmpty = () => {
    return (
      props.registerNewVnerdorData.pan_no.length > 0 ||
      props.registerNewVnerdorData.bank_acc_no.length > 0 ||
      props.registerNewVnerdorData.ifsc_code.length > 0 ||
      props.registerNewVnerdorData.contact_no.length > 0 ||
      props.registerNewVnerdorData.email_id.length > 0
    );
  };

  return (
    <>
      {!(openRegister || selectedAcc?.account_id.length > 0 || registeredDataEmpty()) && (
        <div>
          <VendorDetails
            handleOpen={handleOpen}
            handleRegister={handleRegister}
          />
        </div>
      )}
      {(openRegister || selectedAcc?.account_id.length > 0 || registeredDataEmpty()) && (
        <div>
          {(selectedAcc?.account_id.length > 0) ? (
            <SelectedVendorAccount
              handleRegister={handleRegister}
              selectedAcc={selectedAcc}
              editable={props.editable}
              handleOpen={handleOpen}
              enableNextButton={props.enableNextButton}
            />
          ) : (
            <div className="">
              <RegisterNewVedor
                disabledToggle={props.registerToggle}
                setRegisterNewVnerdorData={props.setRegisterNewVnerdorData}
                registerNewVnerdorData={props.registerNewVnerdorData}
                activeStep={props.activeStep}
                handleOpen={handleOpen}
                enableNextButton={props.enableNextButton}
                linkUser={linkUser}
              />
            </div>
          )}
        </div>
      )}
      {
        open ? (
          <>
            <SelectExistingVendor
              type={open}
              enableNextButton={props.enableNextButton}
              handleClose={handleClose}
              linkUser={linkUser}
              selectedAcc={selectedAcc}
            />
          </>
        ) : null
      }
    </>
  );
};

export default CreateFFTxnForm;
