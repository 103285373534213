import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertToInstantPay } from "../../../../redux/features/convertToInstantPay/convertToInstantPay";
import { toast } from "react-toastify";

const ConvertToInstantPay = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const convertToInstantPayRes = useSelector((state) => state.toInstantPay?.response);
  const [checkRes, setCheckRes] = useState(false);

  useEffect(() => {
    if (checkRes && convertToInstantPayRes?.errors) {
      toast.error((Object.values(convertToInstantPayRes.errors)[0][0]), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && convertToInstantPayRes?.status) {
      toast.success("Transaction converted successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setCheckRes(true);
  }, [convertToInstantPayRes])

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 absolute z-[9999]" style={{transform: "translate(-50%, -50%)"}}>
        {/* 3xl:top-1/4 4xl:top-1/4 5xl:top-1/4 */}
        <div
          className="w-fit h-fit flex flex-col gap-8 bg-custom-deniedReasonPurple rounded-4xl
          px-2 pr-10 py-4">
          <div className="mx-8">
            <h2 className="text-[#FF7F33] py-4 font-bold text-2xl">Convert to InstantPay refill?</h2>
            <div
              className="text-base text-white antialiased truncate">
              Are you sure, you want to convert this<br/> transaction to InstantPay Refill balance?
            </div>
          </div>
          <div
            style={{
              display: "flex", justifyContent: "flex-end", gap: "5%", margin: "0px 20px", width: "100%"
            }}>
            <Button
              sx={{ borderRadius: "20px", borderColor: "#fff", color: "#fff", textTransform: "capitalize", textDecoration: "underline" }}
              onClick={() => {
                props.setState(false);
              }}
              className="4xl:text-3xl">
              Cancel
            </Button>
            <Button
              className="4xl:text-3xl"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
              onClick={() => {
                dispatch(convertToInstantPay({ txn_id: props.txn_id }));
                props.setState(false);
              }}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConvertToInstantPay;
