import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TxnStatusCard from "../../../../components/CreateTxn/CreateInternaltxn/InternalTxnStatusCard";
import "./index.css";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import { useDispatch, useSelector } from "react-redux";
import TxnDetailsCard from "../../../../components/CreateTxn/CreateClientTxn/TxnDetailsCard";
import CurrentStatusCard from "../../../../components/CreateTxn/CreateClientTxn/CurrentStatusCard";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";

const ClientCreateStatus = (props) => {
  const dispatch = useDispatch();
  const txnDetail = useSelector((state) => state.txnDetails.client);

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  return (
    <Box
      className="flex absolute xl:fixed top-0 left-0 right-0 bottom-0 -mt-[10] flex-col xl:flex-row gap-12
      min-w-screen min-h-screen justify-center pt-20 xl:h-screen items-center bg-[#466088]"
    >
      <Box className="w-4/5 flex justify-center h-[750px] xl:w-2/5 xl:h-[90%] p-6 text-center">
        {txnDetail?.transactions[0].approval_status === "Processing" ||
        txnDetail?.transactions[0].approval_status === "S" ? (
          <div className="flex flex-col items-center justify-center gap-24 text-center text-white text-2xl">
            <img
              src={require("../../../../assets/Approve transactions/Success screen.png")}
              width={"60%"}
              alt=""
              srcSet=""
            />
            <h3>
              Transaction{" "}
              <p style={{ textTransform: "lowercase" }}>
                {VendorTxnTextMapper(
                  txnDetail?.transactions[0].approval_status
                )}
              </p>
            </h3>
          </div>
        ) : (
          <CurrentStatusCard
            createdClientTxn={props.createdClientTxn}
            selectedAcc={props.selectedAcc}
            createTxnData={props.createTxnData}
          />
        )}
      </Box>
      <Box className="w-4/5 flex justify-center h-[750px] xl:w-2/5 xl:h-[90%] p-6 text-center">
        <TxnDetailsCard
          createdClientTxn={props.createdClientTxn}
          selectedAcc={props.selectedAcc}
          createTxnData={props.createTxnData}
          container={"TxnDetailsCard"}
        />
      </Box>
    </Box>
  );
};

export default ClientCreateStatus;
