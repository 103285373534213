import React from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import CardLogo from "../../../_utils/logo/CardLogo";
import { AccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";
import { filterButtonViewAll } from "../../../../services/approvalStatus";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getFFLimits } from "../../../../redux/features/ffLimits/ffLimits";
import { useDispatch, useSelector } from "react-redux";
import formatCurrency from "../../../../utils/CurrencyFormater";

const AccountFieldForceDetailBannerComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const limitApiRes = useSelector((state) => state.ffLimits.limit);
  useEffect(() => {
    dispatch(getFFLimits({}));
  }, []);

  const accountName = props.account_name;
  const firstName = (props.account_name || "").split(" ")[0];
  const accountType = props.account_type;
  const accountNumber = props.account_number;
  const bankLogo = bankLogoMapper(props.bank_name);
  const approvalStatusText = AccountStatusTextMapper(
    accountType,
    props.approval_status
  );
  const textColor = filterButtonViewAll(props?.approval_status);

  const accountLimit = limitApiRes?.limits?.maximum;
  const accountLimitColor = props.account_limit_text_color;
  const accountRemainingAmount = props.account_remaining_limit;
  const accountRemainingAmountColor = props.remaing_limit_text_color;

  const createTxnButton = () => {
    if (approvalStatusText == "Pending") {
      return (
        <div className="self-center flex flex-row gap-3">
          <div className="self-center text-white flex flex-row gap-2">
            <div
              className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-1 bg-[#FF5860]"
              onClick={() =>
                navigate(
                  `/accounts/${accountType}/transactions/${props.account_number}`
                )
              }
            >
              Decline
            </div>
          </div>
          <div className="self-center text-white flex flex-row gap-2">
            <div
              className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-1 bg-[#35AA3D]"
              onClick={() =>
                navigate(
                  `/accounts/${accountType}/transactions/${props.account_number}`
                )
              }
            >
              Approve
            </div>
          </div>
        </div>
      );
    }
    if (approvalStatusText == "Approved") {
      if (accountType == "field_force") {
        return (
          <div className="flex flex-row justify-center gap-3">
            <div className="self-center text-white flex flex-row gap-2">
              <div
                className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-6 py-1 bg-[#35AA3D]"
                onClick={() =>
                  (window.location.href = `/create_transaction/field_force_transaction?vendor_id=${props?.account_id}`)
                }
              >
                Pay
              </div>
            </div>
            <div className="self-center text-white flex flex-row gap-2">
              <div className="opacity-50 text-sm rounded-4xl px-4 py-1 bg-[#2A5298]">
                Receive
              </div>
            </div>
          </div>
        );
      }
      if (accountType == "vendor") {
        return (
          <>
            <div className="self-center text-white flex flex-row gap-2">
              <div
                className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-1 bg-[#27B882]"
                onClick={() =>
                  navigate(
                    `/ accounts / ${accountType} /transactions/${props.account_number}`
                  )
                }
              >
                Pay Vendor
              </div>
            </div>
          </>
        );
      }
      return <></>;
    } else {
      return (
        <>
          <div className="invisible self-center text-white flex flex-row gap-2">
            <div className="">Invisible</div>
          </div>
        </>
      );
    }
  };

  const fromColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "from-[#0B0F0D]";
      case "Blocked":
        return "from-[#3D2525]";
      case "Archived":
        return "from-[#303030]";
      case "N":
        return "from-[#462E19]";
      case "P":
        return "from-[#304F4F]";
      default:
        return "from-[#303030]";
    }
  };
  const toColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "to-[#3F675A]";
      case "Blocked":
        return "to-[#A65757]";
      case "Archived":
        return "to-[#5A5A5A]";
      case "N":
        return "to-[#BF793C]";
      case "P":
        return "to-[#5D9898]";
      default:
        return "to-[#5A5A5A]";
    }
  };

  return (
    <div className="h-48 w-full drop-shadow-2xl rounded-4xl  flex flex-row overflow-hidden">
      <div
        className={`z-20 bg-white w-[55%] flex flex-col justify-between p-6 `}
      >
        <div className="flex flex-col divide-y divide-[#1d1d1d55]">
          <span className=" text-gray-700 font-light text-2xl capitalize antialiased truncate ...">
            {accountName}
          </span>
          <span
            className="text-white text-base font-bold capitalize"
            style={{
              color: textColor,
            }}
          >
            {approvalStatusText}
          </span>
        </div>
        <div className="flex flex-row justify-between">
          <div className="flex flex-col">
            <span
              className="text-lg"
              style={{
                color: "#27B882",
              }}
            >
              ₹ {formatCurrency(limitApiRes?.limits.maximum)}
            </span>
            <span className="text-gray-700 font-extralight text-xs antialiased truncate ...">
              {firstName}'s 24 hour limit
            </span>
          </div>
          <div className="flex flex-col">
            <span
              className="self-end text-lg"
              style={{
                color: accountRemainingAmountColor,
              }}
            >
              ₹ {formatCurrency(accountRemainingAmount)}
            </span>
            <span className="self-end text-gray-700 font-extralight text-xs antialiased truncate ...">
              {firstName}'s remaining limit
            </span>
          </div>
        </div>
      </div>
      <div
        className={`bg-gradient-to-r ${fromColorGradient()} ${toColorGradient()} h-full w-[45%] flex flex-row`}
      >
        <div className={`w-full flex flex-col justify-between p-6`}>
          <div className="self-center w-72 truncate ... text-white flex flex-row justify-center gap-2">
            <span className="pb-1 self-end text-xs bg-red font-light">
              A/c No.
            </span>
            <span className="px-1 truncate ... self-end text-xl">
              {accountNumber}
            </span>
          </div>
          <span className="w-24 self-center">
            <CardLogo bank={bankLogo} divCssProps={"py-2 rounded-4xl"} />
          </span>
          {createTxnButton()}
        </div>
      </div>
      <div className="h-40 absolute inset-x-0 bottom-0 transform -rotate-90 -translate-x-28 bg-white"></div>
    </div>
  );
};

export default AccountFieldForceDetailBannerComponent;
