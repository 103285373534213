import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import trimSentence from "../../../../utils/trimSentence";
import { useDispatch } from "react-redux";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const AccountsApprovalStatusCard = (props) => {
  const id = props.data?.vendor_id;
  const txnDetail = props?.txnDetail;
  const statusImage = () => {
    if (txnDetail?.approval_status === "P") {
      return "Pending yellow";
    } else if (txnDetail?.approval_status === "Y") {
      return "Approved green";
    } else if (txnDetail?.approval_status === "N") {
      return "Declined red";
    } else {
      return "unknown";
    }
  };

  return (
    <div className="w-full  overflow-hidden xl:text-sm 2xl:text-lg flex rounded-4xl shadow-customCard bg-[#3e5555]">
      <div className="w-1/2 text-center rounded-4xl text-white">
        {txnDetail?.approval_status === "P" && (
          <div className="p-5 h-full flex flex-col gap-20 justify-center items-center text-white font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal">
            Pending <br /> This account has been approved, <br /> but it
            requires another user's <br />
            approval
            <br />
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal text-[#ff7f33]"
              to={`/vendor/txn/${id}`}
            >
              View accounts {">"}{" "}
            </Link>
          </div>
        )}
        {txnDetail?.approval_status === "Y" && (
          <div className="p-5 h-full flex flex-col gap-5 justify-center items-center text-white font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal">
            Approved <br />
            This account has been approved <br />
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal text-[#ff7f33]"
              onClick={() => {
                window.location.href =
                  "/accounts/vendor/details/" + props.data.vendor_id;
              }}
            >
              View account {">"}
            </Link>
          </div>
        )}
        {txnDetail?.approval_status === "N" && (
          <div className="p-5 h-full flex flex-col gap-5 justify-center items-center text-white font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal">
            Declined <br />
            This account has been declined <br />
            <div
              style={{
                margin: "2% 0",
                height: "3px",
                width: "100%",
                background:
                  "repeating-linear-gradient( to left, transparent, transparent 15px, #657777 15px,  #657777 30px)",
              }}
            ></div>
            <div className="self-start">
              <div
                className="text-left"
                style={{
                  color: "#FAFAFA",
                  font: "normal normal 600 18px/24px Open Sans",
                }}
              >
                Reason
              </div>
              <div
                className="text-left"
                style={{
                  color: "#C9C9C9",
                  font: "normal normal normal 14px/19px Open Sans",
                }}
              >
                {txnDetail?.denied_reason}
              </div>
            </div>
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal text-[#ff7f33]"
              onClick={() => {
                window.location.href =
                  "/accounts/vendor/details/" + props.data.vendor_id;
              }}
            >
              View accounts {">"}
            </Link>
          </div>
        )}
        {txnDetail?.approval_status === undefined && (
          <div className="p-5 h-full flex flex-col gap-20 justify-center items-center text-white font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal">
            Unknown <br /> Approval status of this account <br /> cannot be
            discerned, check the <br /> account detail page after sometime{" "}
            <br />
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl  leading-loose tracking-normal text-[#ff7f33]"
              onClick={() => {
                window.location.href =
                  "/accounts/vendor/details/" + props.data.vendor_id;
              }}
            >
              View accounts {">"}
            </Link>
          </div>
        )}
      </div>
      <div className="w-1/2 xl:text-sm 2xl:text-lg 4xl:text-3xl  text-center shadow-customCard rounded-4xl bg-[#4A6565]">
        <h1 className="px-6 text-xl capitalize text-center text-white  w-full my-6">
          <span className="w-4/5 truncate ...">{txnDetail?.name}</span>
        </h1>
        <div
          style={{
            height: "3px",
            opacity: "0.3",
            background:
              "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
          }}
          className="dashedLine"
        ></div>
        <div className="py-4 px-6 text-left">
          <div className=" simpleBorderBox truncate ...">
            <b>{props.data.bank_acc_no}</b> <br />
            Account number
          </div>
          <div className="dashedBorderBox">
            <div className="paraNImg">
              <p className="w-1/2 truncate ...">
                <b className="text-white">{props.data.ifsc_code} </b>
                <br />
                <span style={{ color: "#c9c9c9" }}> IFSC code </span>
              </p>
              <img
                width={"20%"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.data.bank_name) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </div>
            <div
              className="left-0 right-0 w-full"
              style={{
                height: "3px",
                opacity: "0.2",
                background:
                  "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
              }}
              className="dashedLine"
            ></div>
          </div>
        </div>

        <div className="flex flex-col gap-4 pt-2 px-4 pb-4 w-full justify-start items-center">
          <img
            margin="auto"
            width="50%"
            src={require(`../../../../assets/Approve transactions/${statusImage()}.png`)}
            alt=""
            srcSet=""
          />
        </div>
      </div>
    </div>
  );
};

export default AccountsApprovalStatusCard;
