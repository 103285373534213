import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getServices from "../../../services/getServices";
import { v4 } from "uuid";
import { env } from "../../../env";



const getIPAdress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");

    const ipAddress = await response.json();
    return ipAddress?.ip;
  } catch (error) {
    console.error(error);
  }
};


function getPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "web";
}

async function getDeviceInfo() {
  const userAgent = window.navigator.userAgent;
  let browserName = "Unknown";
  let osVersion = "Unknown";

  if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident/") > -1
  ) {
    browserName = "Microsoft Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
  }

if (/iPhone|iPad|iPod/.test(userAgent)) {
  osVersion = 'iOS';
} else if (/Mac OS X/.test(userAgent) && !/like Mac OS X/.test(userAgent)) {
  osVersion = 'macOS';
} else if (userAgent.indexOf('Windows NT') !== -1) {
  osVersion = 'Windows';
} else if (userAgent.indexOf('Linux') !== -1) {
  osVersion = 'Linux';
} else if (userAgent.indexOf('Android') !== -1) {
  osVersion = 'Android';
}

  const ipAddress = await getIPAdress();
const platform = getPlatform();

  return {
    uuid: v4(),
    platform: "web",
    browser_name: browserName,
    ip_address: ipAddress,
    os_version: osVersion,
  };
}



export const getRefreshTokenNew = createAsyncThunk(
  "getRefreshTokenNew",
  async () => {
    const baseUrl = env.REACT_APP_BASE_URL;
    const deviceInfo = await getDeviceInfo();
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        device_info: deviceInfo,
      }),
    };

    return await fetch(`${baseUrl}/me/refresh`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const refreshTokenSlice = createSlice({
  name: "refreshToken",
  initialState: {
    isLoading: false,
    data: null,
    services: null,
    new_services: null,
    sessionStatus: null,
    isError: false,
  },
  reducers: {
    logout: (state, action) => {
      state.isLoading = false;
      state.data = null;
      state.services = null;
      state.sessionStatus = null;
      state.isError = false;
      sessionStorage.setItem("q2p_token", "");
      sessionStorage.removeItem("loggedIn");
      sessionStorage.setItem("tokenGenerated", "false");
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRefreshTokenNew.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRefreshTokenNew.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data_new_token = action.payload;
      state.sessionStatus = action.payload?.status;
      sessionStorage.setItem("sessionStatus", state.sessionStatus);
      if (action.payload?.errors) console.log(action.payload?.errors);
      state.data = action.payload;
      state.services = Object.keys(
        getServices(action.payload.services).services_list
      );
      state.new_services = Object.keys(
        getServices(action.payload.services).services_list
      );
    });
    builder.addCase(getRefreshTokenNew.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export const { logout } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
