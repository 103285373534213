import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectAcc from "../../../../assets/CreateTransactionIcons/Select account.png";
import loadingLogo from "../../../../assets/Approve transactions/inputloder.gif";
import verifyLogo from "../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import trimSentence from "../../../../utils/trimSentence";
import Validation from "../../../../utils/validation";
import { toast } from "react-toastify";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import { getClientAccountsData } from "../../../../redux/features/accounts/accounts";
import { createClientAccount } from "../../../../redux/features/createClientAcc/createClientAcc";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DropdownLogo from "../../../../assets/ViewAllTransactions/Dropdown red.png";

const EnterCardDetails = (props) => {
  // validations
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const bankStatusLoading = useSelector(
    (state) => state.verify.verifyBankisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [nameValidation, setNameVaidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [cardNumberValidation, setCardNumberValidation] = useState(false);
  const [cardNameValidation, setCardNameValidation] = useState(false);
  const [expiryDateValidation, setExpiryDateValidation] = useState(false);
  const [cvvValidation, setCvvValidation] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(true);
  const allUsers = useSelector((state) => state.users.users);

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setToggleDropdown(true);
      }
    }

    if (toggleDropdown) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleFeatureChange = (value) => {
    props.setPayload({ ...props.payload, billing_cycle_day: value });
  };

  const modifyValue = (type, value) => {
    if (type === "card_name") {
      return value?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    }
    if (type === "card_number") {
      return value?.replace(/\s/g, "");
    }
    if (type === "expiry_date") {
      return value?.replace(/\//g, "");
    }
    return value;
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  const allowedValue = (name, value) => {
    if (name === "card_number") return Validation.allowedCardNumber(value);
    if (name === "card_name") return Validation.allowedName(value, name);
    if (name === "cvv") return Validation.allowedCardCvv(value);
    if (name === "expiry_date") return Validation.allowedCardExpiry(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "card_number")
      errors = Validation.validateCardNumber(name, value, errors);
    if (name === "card_name")
      errors = Validation.validateName(name, value, errors);
    if (name === "cvv")
      errors = Validation.validateCardCvv(name, value, errors);
    if (name === "expiry_date")
      errors = Validation.validateCardExpiry(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.payload, [name]: value };
      props.setPayload(newObj);
    }
    if (props.payload.card_number.length === 0) {
      setCardNumberValidation(false);
    }
    if (props.payload.card_name.length === 0) {
      setCardNameValidation(false);
    }
    if (props.payload.expiry_date.length === 0) {
      setExpiryDateValidation(false);
    }
    if (props.payload.cvv.length === 0) {
      setCvvValidation(false);
    }
  };

  useEffect(() => {
    if (!customErrors["card_name"] && props.payload.card_name.length > 6)
      setNameVaidation(true);
    if (customErrors["card_name"]) setNameVaidation(false);
    if (customErrors["card_number"]) setCardNumberValidation(false);
    if (!customErrors["card_number"] && props.payload.card_number.length === 16)
      setCardNumberValidation(true);
    if (customErrors["card_name"]) setCardNameValidation(false);
    if (!customErrors["card_name"] && props.payload.card_name.length > 0)
      setCardNameValidation(true);
    if (customErrors["expiry_date"]) setExpiryDateValidation(false);
    if (!customErrors["expiry_date"] && props.payload.expiry_date.length === 4)
      setExpiryDateValidation(true);
    if (customErrors["cvv"]) setCvvValidation(false);
    if (!customErrors["cvv"] && props.payload.cvv.length === 3)
      setCvvValidation(true);
  }, [props.payload, customErrors]);

  useEffect(() => {
    if (
      cardNameValidation &&
      cardNumberValidation &&
      expiryDateValidation &&
      cvvValidation &&
      props.payload.billing_cycle_day !== ""
    ) {
      props.setPayload({
        ...props.payload,
        expiry_month: props.payload.expiry_date.substring(0, 2),
        expiry_year: props.payload.expiry_date.substring(2, 4),
      });
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [
    cardNumberValidation,
    cardNameValidation,
    expiryDateValidation,
    cvvValidation,
    props.payload,
  ]);

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    return (
      <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
        {props.editable === false ? (
          <div className="w-2/3 cursor-pointer py-2 text-white text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl">
            <div
              className={`w-full flex flex-col
          antaliased 
           text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl text-white"}`}
            >
              <div className="w-full flex gap-5 self-start items-center">
                <div className="w-full truncate ... ">
                  {props.payload[name]}
                </div>
              </div>
              <div className="text-white text-lg opacity-75">{label}</div>
            </div>
          </div>
        ) : (
          <TextField
            className="w-full antialiased text-4xl"
            inputProps={{
              style: {
                color: "white",
                borderBottom: "1px solid white",
              },
              maxLength: { maxLength },
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontFamily: "Open Sans",
                fontWeight: 100,
                fontSize: "1.0 rem",
              },
            }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="start">
                  {name === "cvv" && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="start"
                      style={{ color: "white" }}
                      className="w-0 h-0 absolute right-5"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  )}
                  {!skip &&
                    value.length > 0 &&
                    (status ? (
                      <img
                        src={verifyLogo}
                        alt="verified logo"
                        className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                      />
                    ) : (
                      <img
                        src={skippedVerification}
                        alt="verified logo"
                        onClick={() => {
                          showErrors(name);
                        }}
                        className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                      />
                    ))}
                </InputAdornment>
              ),
            }}
            label={label}
            variant="standard"
            name={name}
            onChange={handleChange}
            value={value}
            type={
              name === "cvv" ? (showPassword ? "text" : "password") : "text"
            }
            autoComplete="off"
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="p-2 h-full bg-[#9777AC] flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-1"
      >
        <div>
          <div
            className="-m-2 bg-[#9777AC]
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18"
          >
            <div
              className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
            >
              {props?.title}
            </div>
          </div>
        </div>

        <div className="-mx-2 mt-2">
          <div
            className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-8"
          >
            {getInputElementDiv(
              "card_number",
              cardNumberValidation,
              "Card number",
              props.payload.card_number
                .replace(/(\d{4})/g, "$1 ")
                .replace(/(^\s+|\s+$)/, "")
            )}
            {getInputElementDiv(
              "card_name",
              cardNameValidation,
              "Name on the card",
              props.payload.card_name,
              20
            )}
            <div className="flex w-full justify-between items-center gap-5">
              <div className="w-3/5">
                {getInputElementDiv(
                  "expiry_date",
                  expiryDateValidation,
                  "Expiration date (MM/YY)",
                  props.payload.expiry_date.length <= 2
                    ? props.payload.expiry_date
                    : props.payload.expiry_date.slice(0, 2) +
                    "/" +
                    props.payload.expiry_date.slice(2, 6)
                )}
              </div>
              <div className="2/5">
                {getInputElementDiv(
                  "cvv",
                  cvvValidation,
                  "CVV",
                  props.payload.cvv,
                  3
                )}
              </div>
            </div>
            <div className="flex justify-between py-5 items-center gap-5">
              <div className="text-[#FFD600]">
                A test transaction of Rs10 will be made <br />
                to authenticate your card.
              </div>
              <div className="p-2">
                <img
                  className="w-40"
                  src={require("../../../../assets/BankLogos/Card Logos/Default Card.png")}
                  alt=""
                  srcSet=""
                />
              </div>
            </div>
            <div
              style={{
                pointerEvents: props.activeStep === 2 ? "none" : "auto",
              }}
              className="flex flex-col w-full gap-4"
            >
              {/* <div className="text-white capitalize
                    text-md 3xl:text-lg 5xl:text-xl truncate">
                                Account type
                            </div> */}
              {toggleDropdown ? (
                <div
                  onClick={(e) => {
                    setToggleDropdown(false);
                    e.stopPropagation();
                  }}
                  className="w-full flex flex-row justify-between items-center bg-[#744991] px-5 py-2 rounded-lg cursor-pointer"
                >
                  <div className="text-white text-lg 3xl:text-xl 5xl:2xl">
                    {props.payload.billing_cycle_day === ""
                      ? "Billing cycle"
                      : props.payload.billing_cycle_day}
                  </div>
                  <div>
                    <img alt="" src={DropdownLogo} className="w-6 h-6" />
                  </div>
                </div>
              ) : (
                <div className="relative">
                  <div
                    ref={dropdownRef}
                    className="w-full overflow-y-auto overflow-x-hidden hide-scrollbar vendor-txn absolute z-10 max-h-80 flex flex-col justify-between bg-[#B8A3C6] border-[#E25869] border px-5 py-2 rounded-lg"
                  >
                    <div>
                      {["1st Cycle", "2nd Cycle", "3rd Cycle"].map((item) => (
                        <button
                          onClick={() => {
                            handleFeatureChange(item);
                            setToggleDropdown(true);
                          }}
                          className="text-white text-lg 3xl:text-xl 5xl:2xl bg-[#B8A3C6] hover:bg-[#744991] my-1 w-full p-2 flex justify-start rounded-lg"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterCardDetails;
