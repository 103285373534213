import React from "react";

import {
  AccountVendorDataCardComponent,
  AccountVendorListComponent,
  AccountVendorRightSidebarComponent
} from './Strategy/vendorStrategy';

import {
  AccountOwnerDataCardComponent,
  AccountOwnerListComponent,
  AccountOwnerRightSidebarComponent
} from './Strategy/ownerStrategy';

import {
  AccountClientDataCardComponent,
  AccountClientListComponent,
  AccountClientRightSidebarComponent
} from './Strategy/clientStrategy';

import {
  AccountCardDataCardComponent,
  AccountCardListComponent,
  AccountCardRightSidebarComponent
} from './Strategy/cardStrategy';

import {
  AccountFieldForceDataCardComponent,
  AccountFieldForceListComponent,
  AccountFieldForceRightSidebarComponent
} from './Strategy/fieldForceStrategy';

const StrategyContext = (data) => {
  let accountType = data.accountType;
  let componentType = data.componentType;
  let dashboardData = data.dashboardData;
  let viewAllData = data.viewAllData;

  let ComponentToRender;

  // strategy map
  const strategyMap = {
    "vendor": {
      "datacardComponents": () => {
        ComponentToRender = AccountVendorDataCardComponent
      },
      "accountListComponents": () => {
        ComponentToRender = AccountVendorListComponent
      },
      "rightSidebarComponents": () => {
        ComponentToRender = AccountVendorRightSidebarComponent
      }
    },
    "owner": {
      "datacardComponents": () => {
        ComponentToRender = AccountOwnerDataCardComponent
      },
      "accountListComponents": () => {
        ComponentToRender = AccountOwnerListComponent
      },
      "rightSidebarComponents": () => {
        ComponentToRender = AccountOwnerRightSidebarComponent
      }
    },
    "client": {
      "datacardComponents": () => {
        ComponentToRender = AccountClientDataCardComponent
      },
      "accountListComponents": () => {
        ComponentToRender = AccountClientListComponent
      },
      "rightSidebarComponents": () => {
        ComponentToRender = AccountClientRightSidebarComponent
      }
    },
    "cards": {
      "datacardComponents": () => {
        ComponentToRender = AccountCardDataCardComponent
      },
      "accountListComponents": () => {
        ComponentToRender = AccountCardListComponent
      },
      "rightSidebarComponents": () => {
        ComponentToRender = AccountCardRightSidebarComponent
      }
    },
    "field_force": {
      "datacardComponents": () => {
        ComponentToRender = AccountFieldForceDataCardComponent
      },
      "accountListComponents": () => {
        ComponentToRender = AccountFieldForceListComponent
      },
      "rightSidebarComponents": () => {
        ComponentToRender = AccountFieldForceRightSidebarComponent
      }
    },
  }

  strategyMap[accountType][componentType]();
  return (
    <ComponentToRender
      accountType={accountType}
      dashboardData={dashboardData}
      viewAllData={viewAllData}
    />
  )
}

export default StrategyContext;
