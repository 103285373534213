import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import BeneTxnStepper from "../../../components/BeneTransaction/BeneTxnStepper";
import BeneTxnAccount from "../../../components/BeneTransaction/BeneTxnAccount";
import BeneTxnAmount from "../../../components/BeneTransaction/BeneTxnAmount";
import BeneTxnSettleMethod from "../../../components/BeneTransaction/BeneTxnSettleMethod";
import { beneCategories } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../redux/features/login/login";
import BeneTxnConfirm from "../../../components/BeneTransaction/BeneTxnConfirm";
import { useLocation } from "react-router-dom";

const BeneCreateTxn = () => {
  const scrollIt = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentAccountDetails = location.state;
  const [nextButtonEnabled, BenEnableNextButton] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [refreshId, setRefreshId] = useState(true);
  const [selectBank, setSelectBank] = useState(false);
  const [selectSortAmt, setSelectSortAmt] = useState(false);
  const [selectBankNonActive, setSelectBankNonActive] = useState(false);
  const [transactionFilterData, setTransactionFilterData] = useState([]);
  const [isConnectedSelected, setIsConnectedSelected] = useState(true);
  const [smallSearch, setSmallSearch] = useState(false);
  const [smallSearchNonActive, setSmallSearchNonActive] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);
  const [associateModalOpen, setAssociateModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountModalSearch, setAccountModalSearch] = useState(false);
  const [transactionDisable, setTransactionDisable] = useState(false);
  const [filterPayload, setFilterPayload] = useState({
    pagination: 0,
    accNoInput: "",
    currentPage: 1,
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    currentPageText: "01",
    beneAccountSearch: "",
    searchTerm: "",
  });

  const [filterPayloadNonActive, setFilterPayloadNonActive] = useState({
    pagination: 0,
    accNoInput: "",
    currentPage: 1,
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    currentPageText: "01",
    searchTerm: "",
  });

  const [activeStep, setActiveStep] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [isNeft, setIsNeft] = useState(false);
  const [isRtgs, setIsRtgs] = useState(false);
  const [isImps, setIsImps] = useState(false);
  const [txnPayLoad, setTxnPayLoad] = useState({
    bankAccountName: currentAccountDetails?.name
      ? currentAccountDetails.name
      : "",
    beneAccountId: currentAccountDetails?.id ? currentAccountDetails?.id : "",
    bankAccountIFSC: currentAccountDetails?.ifsc_code
      ? currentAccountDetails?.ifsc_code
      : "",
    bankAccountNo: currentAccountDetails?.bank_acc_no
      ? currentAccountDetails?.bank_acc_no
      : "",
    categories: currentAccountDetails?.category
      ? currentAccountDetails?.category
      : "",
    amount: "",
    settleType: "",
    bankLogo: currentAccountDetails?.bank_name
      ? currentAccountDetails?.bank_name
      : "",
    pan: currentAccountDetails?.pan ? currentAccountDetails?.pan : "",
    ownerId: null,
    ownerBankIFSC: null,
    ownerBankBalance: 0,
    ownerBankLogo: null,
    ownerLastUpdated: null,
    ownerBankAccountNo: null,
    selectedCategory: null,
    mergeCategory: null,
    amountWords: "",
  });

  const [additionalData, setAdditionalData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const steps = [
    "Select beneficiary account and category",
    "Enter amount",
    "Select source account",
    "Confirm and create transaction",
  ];

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const handleKeyDown = (event) => {
    if (event.target.tagName === "INPUT") {
      if (event.key === "Tab") {
        event.preventDefault();
      }
    } else if (
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Tab"
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );
  const handleNext = () => {
    setButtonDisabled(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsClicked(true);
    if (scrollIt.current) {
      scrollIt.current.scrollLeft += 1024;
      setTimeout(() => {
        scrollIt.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 700);
    }
    // --------------------------------------Window-------------------------------------------------

    setTimeout(() => {
      setButtonDisabled(false);
    }, 500);
  };

  const isActive = activeStep === 1;

  useEffect(() => {
    if (activeStep === 0 && !txnPayLoad?.selectedCategory) {
      BenEnableNextButton(true);
    } else if (activeStep === 1 && !txnPayLoad?.amount) {
      BenEnableNextButton(true);
    } else if (activeStep === 2 && !txnPayLoad?.settleType) {
      BenEnableNextButton(true);
    } else {
      if (isActive) {
        if (
          txnPayLoad.selectedCategory?.configuration?.data?.some(
            (val) => val?.mandatory === "Y"
          )
        ) {
          const mandatoryFieldsCount =
            txnPayLoad.selectedCategory?.configuration?.data?.filter(
              (val) => val?.mandatory === "Y"
            )?.length;

          const mandatoryFieldsTypedCount = txnPayLoad?.mergeCategory?.filter(
            (val) => val?.mandatory === "Y" && val?.value
          )?.length;

          const isAnyMandatoryFieldNotEmpty =
            mandatoryFieldsCount === mandatoryFieldsTypedCount;
          const isAmountFieldNotEmpty =
            txnPayLoad?.amount > 0 && txnPayLoad?.amount !== "";
          let check = isAnyMandatoryFieldNotEmpty && isAmountFieldNotEmpty;
          BenEnableNextButton(!check);
        } else if (
          (txnPayLoad?.amount && txnPayLoad?.amount > 0) ||
          (txnPayLoad?.amount && txnPayLoad?.amount !== "")
        ) {
          BenEnableNextButton(false);
        } else {
          BenEnableNextButton(true);
        }
      } else if (
        txnPayLoad?.settleType === "imps" &&
        txnPayLoad?.amount > 200000 &&
        activeStep === 2
      ) {
        BenEnableNextButton(true);
      } else if (
        txnPayLoad?.settleType === "rtgs" &&
        txnPayLoad?.amount < 200000 &&
        activeStep === 2
      ) {
        BenEnableNextButton(true);
      } else {
        BenEnableNextButton(false);
      }
    }
  }, [activeStep, txnPayLoad]);
  const handleBack = () => {
    setButtonDisabled(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsClicked(true);
    if (scrollIt.current) {
      scrollIt.current.scrollLeft -= 1024;
      setTimeout(() => {
        scrollIt.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 700);
    }
    setTimeout(() => {
      setButtonDisabled(false);
    }, 500);
  };

  const handleCategory = (selectedCategoryData) => {
    setSelectedCategory(selectedCategoryData);
  };

  return (
    <div
      className="main_Container_txn"
      style={{
        height:
          openOwner || isModalOpen || associateModalOpen
            ? "100vh"
            : "fit-content",
      }}
    >
      <div className="stepper_Div">
        <BeneTxnStepper
          nextButtonEnabled={nextButtonEnabled}
          activeStep={activeStep}
          steps={steps}
          handleBack={handleBack}
          handleNext={handleNext}
          amount={txnPayLoad.amount}
          distributionRemark={txnPayLoad.distributionRemark}
          beneAccountId={txnPayLoad.beneAccountId}
          ownerId={txnPayLoad.ownerId}
          categories={selectedCategory}
          additionalData={additionalData}
          isClicked={isClicked}
          isButtonDisabled={isButtonDisabled}
          settleType={txnPayLoad.settleType}
          transactionDisable={transactionDisable}
          setTransactionDisable={setTransactionDisable}
        />
      </div>
      <div className="form_Div" ref={scrollIt}>
        <BeneTxnAccount
          title="Beneficiary Account Details"
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          selectedCategory={selectedCategory}
          handleCategorySelect={handleCategory}
          categories={categories}
          BenEnableNextButton={BenEnableNextButton}
          additionalData={additionalData}
          setAdditionalData={setAdditionalData}
          activeStep={activeStep}
          associateModalOpen={associateModalOpen}
          setAssociateModalOpen={setAssociateModalOpen}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          filterPayload={filterPayload}
          setFilterPayload={setFilterPayload}
          accountModalSearch={accountModalSearch}
          setAccountModalSearch={setAccountModalSearch}
        />
        <BeneTxnAmount
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          selectedCategory={selectedCategory}
          handleCategorySelect={handleCategory}
          additionalData={additionalData}
          setAdditionalData={setAdditionalData}
          BenEnableNextButton={BenEnableNextButton}
          nextButtonEnabled={nextButtonEnabled}
          activeStep={activeStep}
        />
        <BeneTxnSettleMethod
          selectBank={selectBank}
          setSelectBank={setSelectBank}
          filterPayload={filterPayload}
          setFilterPayload={setFilterPayload}
          filterPayloadNonActive={filterPayloadNonActive}
          setFilterPayloadNonActive={setFilterPayloadNonActive}
          smallSearch={smallSearch}
          setSmallSearch={setSmallSearch}
          selectSortAmt={selectSortAmt}
          setSelectSortAmt={setSelectSortAmt}
          isConnectedSelected={isConnectedSelected}
          setIsConnectedSelected={setIsConnectedSelected}
          BenEnableNextButton={BenEnableNextButton}
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          isNeft={isNeft}
          setIsNeft={setIsNeft}
          isRtgs={isRtgs}
          setIsRtgs={setIsRtgs}
          isImps={isImps}
          setIsImps={setIsImps}
          transactionFilterData={transactionFilterData}
          setTransactionFilterData={setTransactionFilterData}
          refreshId={refreshId}
          setRefreshId={setRefreshId}
          openOwner={openOwner}
          setOpenOwner={setOpenOwner}
          selectBankNonActive={selectBankNonActive}
          setSelectBankNonActive={setSelectBankNonActive}
          smallSearchNonActive={smallSearchNonActive}
          setSmallSearchNonActive={setSmallSearchNonActive}
          activeStep={activeStep}
        />
        <BeneTxnConfirm
          txnPayLoad={txnPayLoad}
          setTxnPayLoad={setTxnPayLoad}
          selectedAccountId={selectedAccountId}
          selectedCategory={selectedCategory}
          handleCategorySelect={handleCategory}
          categories={categories}
          additionalData={additionalData}
          amountWords={txnPayLoad.amountWords}
        />
      </div>
    </div>
  );
};

export default BeneCreateTxn;
