import { Button, TextField } from "@mui/material";
import { toast } from "react-toastify";

function SetRange(props) {
  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div className="flex 4xl:text-3xl  justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  fixed z-[9999]">
        <div
          className="w-2/6 h-fit flex flex-col gap-8 bg-custom-deniedReasonPurple rounded-4xl
          px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-10"
        >
          <div className="flex flex-col gap-4">
            <div
              className="text-[#FF7F33] font-semibold
            text-2xl 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl"
            >
              Set range
            </div>
            <div
              className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-gray-300 antialiased truncate"
            >
              Set the range for custom rule
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <TextField
              autoFocus
              autoComplete="off"
              inputProps={{
                style: {
                  color: "white",
                  borderBottom: "1px solid grey",
                },
                type: "number",
              }}
              InputLabelProps={{
                style: {
                  color: "#DADADA",
                  font: "normal normal normal 14px/19px Open Sans",
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              label="Minimum"
              variant="standard"
              inputRef={props.minRef}
              onChange={props.handlechange}
            />
            <div className="w-full flex justify-center items-start pt-6 pb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#fff"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                />
              </svg>
            </div>
            <TextField
              autoComplete="off"
              inputProps={{
                style: {
                  color: "white",
                  borderBottom: "1px solid grey",
                },
                type: "number",
              }}
              InputLabelProps={{
                style: {
                  color: "#DADADA",
                  font: "normal normal normal 14px/19px Open Sans",
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              label="Maximum"
              variant="standard"
              inputRef={props.maxRef}
              onChange={props.handlechange}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5%",
              width: "100%",
            }}
          >
            <Button
              sx={{
                borderRadius: "20px",
                borderColor: "#fff",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
              onClick={() => {
                props.maxRef.current.value = "";
                props.minRef.current.value = "";
                props.setState(false);
                props.setShowAddRuleButton(true);
              }}
              className="4xl:text-3xl"
            >
              Cancel
            </Button>
            <Button
              className="4xl:text-3xl"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
              onClick={() => {
                if (
                  Number(props.minRef.current?.value) >=
                    Number(props.maxRef.current?.value) ||
                  (props.maxRef.current?.value !== null &&
                    props.maxRef.current?.value !== "" &&
                    props.maxRef.current?.value === undefined &&
                    Number(props.minRef.current?.value) === 0 &&
                    Number(props.maxRef.current?.value) === 0)
                ) {
                  toast.error(
                    "Minimum amount cannot be greater than maximum amount",
                    {
                      position: "bottom-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    }
                  );
                } else {
                  props.setState(false);
                  props.setShowAddRuleButton(false);
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetRange;
