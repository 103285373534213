import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Validation from "../../../../../../utils/validation";
import verifyLogo from "../../../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import { toast } from "react-toastify";
import DropdownLogo from "../../../../../../assets/ViewAllTransactions/Dropdown red.png";

const EntityAddress = (props) => {
  const [addressValidation, setAddressValidation] = useState(true);
  const [pincodeValidation, setPincodeValidation] = useState(true);
  const [customErrors, setCustomErrors] = useState({});
  const [toggleDropdown, setToggleDropdown] = useState(true);

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setToggleDropdown(true);
      }
    }

    if (toggleDropdown) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const modifyValue = (type, value) => {
    if (type === "entity_address") {
      return value?.toUpperCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "entity_pincode") return Validation.allowedPincode(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "entity_pincode")
      errors = Validation.validatePincode(
        name,
        value,
        errors,
        setPincodeValidation
      );
    if (name === "entity_address")
      errors = Validation.validateAddress(
        name,
        value,
        errors,
        setAddressValidation
      );
    setCustomErrors(errors);
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  const handleFeatureChange = (value) => {
    props.setRegisterNewEntityData({
      ...props.registerNewEntityData,
      acquired_by: value,
    });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewEntityData, [name]: value };
      props.setRegisterNewEntityData(newObj);
    }
  };

  useEffect(() => {
    if (props.registerNewEntityData.acquired_by) props.enableNextButton(true);
    else props.enableNextButton(false);
  }, [
    addressValidation,
    pincodeValidation,
    props.registerNewEntityData.acquired_by,
  ]);

  const getInputElementDiv = (name, status, label, value, maxLength) => {
    return (
      <div className="w-full flex flex-row self-center">
        <TextField
          className="w-full antialiased text-4xl"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: { maxLength },
            readOnly: props.activeStep === 2,
          }}
          InputLabelProps={{
            style: {
              color: "#DADADA",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {value.length > 0 &&
                  (status ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : (
                    <img
                      src={skippedVerification}
                      alt="verified logo"
                      onClick={() => {
                        showErrors(name);
                      }}
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ))}
              </InputAdornment>
            ),
          }}
          label={label}
          variant="standard"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  return (
    <div className="bg-[#29487E]  flex flex-col  drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
      <div>
        <div className="-m-2 bg-[#29487E] rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl flex flex-row justify-between p-4 md:p-6 lg:p-8 xl:p-10 4xl:p-24">
          <div className="text-white self-center font-semibold text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl">
            Entity details
          </div>
        </div>
        <div className="-m-2 bg-custom-lightBlue rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col drop-shadow-xl gap-4 2xl:gap-6 4xl:gap-8">
          <div className="bg-[#29487E] drop-shadow-xl h-full rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col gap-8 px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-6">
            {props.activeStep !== 2 ? (
              <>
                <div className="w-full">
                  {getInputElementDiv(
                    "entity_address",
                    addressValidation,
                    "Entity address (optional)",
                    props.registerNewEntityData.entity_address,
                    30
                  )}
                </div>
                <div className="w-full">
                  {getInputElementDiv(
                    "entity_pincode",
                    pincodeValidation,
                    "Entity PIN code (optional)",
                    props.registerNewEntityData.entity_pincode,
                    20
                  )}
                </div>{" "}
              </>
            ) : (
              <div className="flex flex-col gap-8">
                <div>
                  <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
                    {props?.registerNewEntityData?.entity_name}
                  </div>
                  <div className="antaliased text-white text-xs opacity-70">
                    Entity name - Using PAN legal name
                  </div>
                </div>
                <div>
                  <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
                    {props?.registerNewEntityData?.entity_address ||
                      "Not Given"}
                  </div>
                  <div className="antaliased text-white text-xs opacity-70">
                    Entity address (optional)
                  </div>
                </div>
                <div>
                  <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
                    {props?.registerNewEntityData?.entity_pincode ||
                      "Not Given"}
                  </div>
                  <div className="antaliased text-white text-xs opacity-70">
                    Entity PIN code (optional)
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                pointerEvents: props.activeStep === 2 ? "none" : "auto",
              }}
              className="pt-4 flex flex-col gap-4"
            >
              <div
                className="text-white capitalize
                    text-md 3xl:text-lg 5xl:text-xl truncate"
              >
                Acquired by
              </div>
              {toggleDropdown ? (
                <div
                  onClick={(e) => {
                    setToggleDropdown(false);
                    e.stopPropagation();
                  }}
                  className="w-full flex flex-row justify-between items-center bg-[#3A5686] px-5 py-2 rounded-lg cursor-pointer"
                >
                  <div className="text-white text-lg 3xl:text-xl 5xl:2xl">
                    {props.registerNewEntityData.acquired_by === ""
                      ? "Feature"
                      : props.registerNewEntityData.acquired_by}
                  </div>
                  {props.activeStep === 1 && (
                    <div>
                      <img alt="" src={DropdownLogo} className="w-6 h-6" />
                    </div>
                  )}
                </div>
              ) : (
                <div ref={dropdownRef} className="relative">
                  <div
                    style={{}}
                    className="absolute w-full max-h-80 flex flex-col justify-between bg-[#667CA3] border-[#E25869] border px-5 py-2 rounded-lg"
                  >
                    <div>
                      {["Q2Pay", "HDFC", "Lyra", "EaseBuzz"].map((item) => (
                        <button
                          onClick={() => {
                            handleFeatureChange(item);
                            setToggleDropdown(true);
                          }}
                          className="text-white text-lg 3xl:text-xl 5xl:2xl bg-[#667CA3] hover:bg-[#3A5686] my-1 w-full p-2 flex justify-start rounded-lg"
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntityAddress;
