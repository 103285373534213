import React, { useState, useEffect, useRef } from "react";
import { InputAdornment, TextField, Button } from "@mui/material";
import "./index.css";
import AssociateInfo from "../../../assets/Beneficiary Icons/infoAssociate.png";
import { useSelector } from "react-redux";
import ViewDetails from "../../../assets/Beneficiary Icons/view_details.png";
import Dropdown from "../../../assets/CreateTransactionIcons/arrowdown.png";
import Dropup from "../../../assets/CreateTransactionIcons/arrowup.png";
import Validation from "../../../utils/validation";
import { NumericFormat } from "react-number-format";
import AmountToWords from "../../_utils/AmountToWords";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";

const BeneTxnAmount = (props) => {
  const dropdownRefs = useRef(null);
  const [showInvoiceInfo, setShowInvoiceInfo] = useState(false);
  const [showRupeeSymbol, setShowRupeeSymbol] = useState(false);
  const [dropdowns, setDropdowns] = useState({});
  const [amountInWords, setAmountInWords] = useState("");
  const { beneLookData } = useSelector((state) => state.verify);

  const allowedValue = (value) => {
    return Validation.allowedAmount(value);
  };

  const handleAmountChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    const numericValue = value.replace(/[^0-9.]/g, "");

    if (name === "amount") {
      if (allowedValue(numericValue) || numericValue.length === 0) {
        const amountNumber = parseFloat(numericValue);
        const amountWords =
          numericValue.trim() === "" ? "" : AmountToWords(amountNumber);

        props.setTxnPayLoad((prevState) => ({
          ...prevState,
          amount: numericValue,
          amountWords: amountWords,
        }));

        setShowRupeeSymbol(numericValue.trim() !== "");
        setAmountInWords(amountWords);
      }
    }
  };

  const handleOptionSelect = (option, fieldName) => {
    setDropdowns((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        selectedOption: option,
        isOpen: false,
      },
    }));
    handleInputChange({ target: { name: fieldName, value: option } });
  };

  const handleInputChange = (e) => {
    if (e.preventDefault) e.preventDefault();
    const { name, value } = e.target || { name: e.name, value: e.value };

    const field = selectedCategory.configuration.data.find(
      (field) => field.name === name
    );

    let updatedData = { ...props.additionalData };

    const trimmedValue = value.replace(/^\s+/, "");

    if (field?.data_type === "numeric") {
      if (trimmedValue === "" || /^\d+$/.test(trimmedValue)) {
        updatedData[name] = trimmedValue;
        props.setAdditionalData(updatedData);
      }
    } else {
      updatedData[name] = trimmedValue;
      props.setAdditionalData(updatedData);
    }

    if (field?.data_type === "list") {
      setDropdowns((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          selectedOption: trimmedValue,
        },
      }));
    }

    if (name === "invoiceNumber" && trimmedValue.length > 0) {
      setShowInvoiceInfo(true);
    } else {
      setShowInvoiceInfo(false);
    }
  };

  const handleDropdownToggle = (fieldName) => {
    setDropdowns((prev) => {
      const newDropdowns = Object.keys(prev).reduce((acc, key) => {
        acc[key] = { ...prev[key], isOpen: false };
        return acc;
      }, {});

      return {
        ...newDropdowns,
        [fieldName]: {
          ...prev[fieldName],
          isOpen: !prev[fieldName]?.isOpen,
        },
      };
    });
  };

  const closeDropdowns = () => {
    setDropdowns((prev) => {
      const newDropdowns = Object.keys(prev).reduce((acc, key) => {
        acc[key] = { ...prev[key], isOpen: false };
        return acc;
      }, {});
      return newDropdowns;
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefs.current &&
        !dropdownRefs.current.contains(event.target)
      ) {
        let obj = {
          ...dropdowns,
          isOpen: false,
        };
        setDropdowns(obj);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefs]);

  useEffect(() => {
    props.BenEnableNextButton(true);
  }, []);

  useEffect(() => {
    if (!props.additionalData) return;

    const storeData =
      props.txnPayLoad.selectedCategory?.configuration?.data?.map((val) => {
        const dataValue = props.additionalData[val?.name];

        if (dataValue !== undefined && dataValue !== "") {
          if (val?.data_type === "numeric") {
            const integerValue = parseInt(dataValue, 10);
            if (!isNaN(integerValue)) {
              return { ...val, value: integerValue };
            } else {
              return val;
            }
          } else {
            return { ...val, value: dataValue };
          }
        } else if (val?.mandatory !== "Y") {
          return { ...val, value: "Not given" };
        }

        return val;
      });

    props.setTxnPayLoad({
      ...props.txnPayLoad,
      mergeCategory: storeData,
    });

    closeDropdowns();
  }, [props.additionalData, props.txnPayLoad.selectedCategory]);

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });

  const { selectedCategory } = props;

  const renderInputFields = () => {
    if (
      !selectedCategory ||
      !selectedCategory.configuration ||
      !selectedCategory.configuration.data ||
      selectedCategory.configuration.data.length === 0
    ) {
      return (
        <div className="text-left text-white text-base antialiased">
          Additional details are not supported for the selected category.
        </div>
      );
    }

    return selectedCategory.configuration.data.map((field, index) => {
      const isFieldRequired = field.mandatory === "Y";
      const optionalLabel = isFieldRequired ? "" : " (optional)";
      const fieldValue = props.additionalData[field.name] || "";
      const isMultiline = fieldValue.length > 30;
      switch (field.data_type) {
        case "string":
        case "numeric":
          return (
            <TextField
              key={index}
              className="w-full antialiased text-xl"
              size="small"
              variant="outlined"
              label={`${field.name}${optionalLabel}`}
              name={field.name}
              autoComplete="off"
              value={fieldValue}
              onChange={handleInputChange}
              required={isFieldRequired}
              multiline={isMultiline}
              minRows={1}
              maxRows={10}
              sx={{
                "& fieldset": {
                  borderRadius: "10px",
                  borderColor: fieldValue
                    ? "#00FF00 !important"
                    : "#dadada !important",
                  borderWidth: "2px",
                },
                "& label": {
                  color: fieldValue
                    ? "#00FF00 !important"
                    : "#dadada !important",
                  background: "#314C7F",
                  padding: "0 5px",
                },
                "& input": {
                  color: "#dadada !important",
                },
                "& label.Mui-focused": {
                  color: "#00FFF5 !important",
                },
                "& .MuiInputBase-input": {
                  color: "#dadada !important",
                },
                "& .MuiInputBase-inputMultiline": {
                  color: "#dadada !important",
                },
                "& .Mui-focused": {
                  "& fieldset": {
                    borderColor: "#00FFF5 !important",
                  },
                },
                borderRadius: "10px",
              }}
            />
          );
        case "list":
          const maxItemsToShow = 3;
          const itemHeight = 45;
          const dropdownHeight = Math.min(
            field.data.length * itemHeight,
            maxItemsToShow * itemHeight
          );

          return (
            <div className="relative rounded-md mt-4" key={field.name}>
              <label
                className="absolute left-4 -top-6 -ml-3 text-sm font-medium"
                style={{ color: fieldValue ? "#00FF00" : "#DADADA" }}
              >
                {`${field.name}${isFieldRequired ? "*" : ""}${optionalLabel}`}
              </label>

              <div className="relative">
                <div
                  onClick={() => handleDropdownToggle(field.name)}
                  className="w-full py-2 px-5 rounded-t-xl bg-[#667CA3] text-base text-[#DADADA] cursor-pointer"
                  style={{
                    borderColor: fieldValue ? "#00FF00" : "#dadada",
                    borderBottomLeftRadius: dropdowns[field.name]?.isOpen
                      ? "0"
                      : "10px",
                    borderBottomRightRadius: dropdowns[field.name]?.isOpen
                      ? "0"
                      : "10px",
                    borderLeft: dropdowns[field.name]?.isOpen
                      ? "2px solid #FF5860"
                      : "none",
                    borderRight: dropdowns[field.name]?.isOpen
                      ? "2px solid #FF5860"
                      : "none",
                    borderTop: dropdowns[field.name]?.isOpen
                      ? "2px solid #FF5860"
                      : "none",
                  }}
                >
                  {fieldValue || "Select"}
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                    {dropdowns[field.name]?.isOpen ? (
                      <img
                        src={Dropup}
                        alt="dropdown icon"
                        className="w-4 h-5"
                      />
                    ) : (
                      <img
                        src={Dropdown}
                        alt="dropdown icon"
                        className="w-4 h-5"
                      />
                    )}
                  </div>
                </div>
                {dropdowns[field.name]?.isOpen && (
                  <div
                    ref={dropdownRefs}
                    className="absolute w-full bg-[#667CA3] mt-0 rounded-b-xl z-10 pt-2"
                    style={{
                      borderTop: "2px solid #3A5686",
                      margin: "auto",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      paddingBottom: "10px",
                      maxHeight: `${dropdownHeight}px`,
                      overflowY:
                        field.data.length > maxItemsToShow ? "auto" : "hidden",
                      borderBottom: "2px solid #FF5860",
                      borderLeft: "2px solid #FF5860",
                      borderRight: "2px solid #FF5860",
                    }}
                  >
                    {!isFieldRequired && (
                      <div
                        onClick={() => handleOptionSelect("", field.name)}
                        className="py-2 px-4 mt-1 text-white hover:bg-[#506994] hover:rounded-[10px] cursor-pointer"
                      >
                        None
                      </div>
                    )}
                    {field.data.map((option, index) => (
                      <div
                        key={index}
                        onClick={() => handleOptionSelect(option, field.name)}
                        className={`py-2 px-4 text-white ${
                          dropdowns[field.name]?.selectedOption === option
                            ? "bg-[#3A5686] text-white rounded-[10px] px-10"
                            : "hover:bg-[#506994] hover:rounded-[10px]"
                        } cursor-pointer`}
                        style={{
                          borderRadius:
                            dropdowns[field.name]?.selectedOption === option
                              ? "0.5rem"
                              : "",
                          padding:
                            dropdowns[field.name]?.selectedOption === option
                              ? "0.5rem 1rem"
                              : "",
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          );

        default:
          return null;
      }
    });
  };

  return (
    <>
      <div className="beneContact_Main">
        <div className="dividecontact">
          <div className="w-1/2 h-fit contactright">
            <div className="flex flex-col relative">
              <div className="py-6 h-full bg-[#338A3E] flex flex-col rounded-t-4xl rounded-b-4xl">
                <div className="w-full flex flex-col pb-2 gap-7">
                  <NumericFormat
                    name="amount"
                    customInput={TextField}
                    thousandSeparator={true}
                    thousandsGroupStyle="lakh"
                    onChange={handleAmountChange}
                    value={props.txnPayLoad.amount}
                    decimalScale={2}
                    isAllowed={(values) => {
                      if (!values.value) return true;
                      const { floatValue } = values;
                      return allowedValue(String(floatValue));
                    }}
                    inputProps={{
                      placeholder: "Enter amount",
                      style: {
                        padding: "4px",
                        fontSize: "32px",
                        outline: "none",
                        font: "normal normal normal 32px/43px Open Sans",
                        color: "#FFFFFF",
                      },
                      className: "text-center ",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: showRupeeSymbol && (
                        <InputAdornment position="start">
                          <span
                            style={{
                              fontSize: "32px",
                              color: "#ffffff",
                              marginRight: "-30px",
                            }}
                          >
                            &#8377;
                          </span>
                        </InputAdornment>
                      ),
                      style: {
                        outline: "none",
                        boxShadow: "none",
                        borderBottom: "2px solid #DADADA",
                      },
                    }}
                    sx={{
                      border: "none",
                      outline: "none",
                      mx: "auto",
                      width: "70%",
                      "& input::placeholder": {
                        color: "white !important",
                      },
                      "& input": {
                        height: "auto",
                        color: "white",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                        "&.Mui-focused fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                </div>
                <div className="text-[#f9f9f9] text-sm mt-2 w-full flex flex-col px-[75px] ">
                  {amountInWords && (
                    <>
                      <div>
                        {amountInWords}
                        <br />
                      </div>
                      <span className="text-[#DADADA] text-xs mt-[5px]">
                        In Words
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {showInvoiceInfo && (
            <div className="w-1/2 contactright">
              <div className="flex flex-col">
                <div className="bg-[#314C7F] text-[#DADADA] font-semibold text-base flex flex-row justify-between py-4 pb-0 mt-4 px-8 rounded-t-4xl 4xl:rounded-t-large items-center">
                  <div className="text-[#DADADA] font-semibold text-base flex flex-row items-center gap-2">
                    <img src={AssociateInfo} alt="" className="w-4 h-4" />
                    <p>Invoice number in use</p>
                  </div>
                </div>
                <p className="py-2 pb-0 px-8 bg-[#314C7F] text-[#DADADA] font-normal text-xs">
                  Looks like this invoice number is already in use for some
                  other transaction/s.
                </p>
              </div>
              <div className="rounded-b-4xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
                <div
                  className="bg-[#314C7F] py-5 h-full flex flex-col rounded-b-4xl"
                  style={{ height: "240px" }}
                >
                  <div className="w-full flex flex-col px-8 gap-4 overflow-auto h-72 custom-scroll-container">
                    <div className="text-justify flex flex-col gap-6">
                      {haveApproved ? (
                        <>
                          {haveApproved && (
                            <div className="">
                              {beneLookData?.beneficiary_accounts.map(
                                (val, i) => {
                                  if (val?.approval_status === "approved") {
                                    return (
                                      <Button
                                        style={{
                                          backgroundColor: "#1D3A6D",
                                        }}
                                        className="child_scroll"
                                        key={i}
                                      >
                                        <div className="bank_name">
                                          <img
                                            src={require("../../../assets/IfscLogo/" +
                                              IfscCodeMapper(val?.ifsc_code))}
                                            alt="bank"
                                          />
                                        </div>
                                        <div className="client_name">
                                          <p
                                            className="name_client2"
                                            style={{
                                              color: "#4DDD37",
                                            }}
                                          >
                                            hello
                                          </p>
                                          <p className="bank_client2">
                                            Bank account
                                            <span className="bank_no">
                                              - Bank account number
                                            </span>
                                          </p>
                                        </div>
                                        <div className="viewProfile">
                                          <button
                                          // onClick={(e) => {
                                          //   e.stopPropagation();
                                          //   navigate(
                                          //     `/accounts/beneficiary_account/details/${val?.id}`
                                          //   );
                                          // }}
                                          >
                                            <img
                                              src={ViewDetails}
                                              alt="viewdetails"
                                            />
                                          </button>
                                          <p>View details</p>
                                        </div>
                                      </Button>
                                    );
                                  }
                                }
                              )}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="dividecontact">
          <div className=" rounded-b-4xl rounded-large">
            <div className="bg-[#314C7F] py-7 h-full flex flex-col px-5 rounded-b-4xl rounded-t-4xl items-start">
              <div className="text-[#DADADA] font-semibold text-base flex flex-row justify-between">
                <p className="text-center pb-5 px-4">Additional Details</p>
              </div>

              <div className=" w-full flex flex-col gap-5 px-4 pb-2">
                {/* <TextField
                  // key={index}
                  className="w-full antialiased text-xl "
                  size="small"
                  variant="outlined"
                  autoComplete="off"
                  label="Invoice Number"
                  name="invoiceNumber"
                  value={props.txnPayload?.invoiceNumber}
                  onChange={handleInputChange}
                  sx={{
                    "& fieldset": {
                      borderRadius: "10px",
                      borderColor: "#dadada !important",
                    },
                    "& label": {
                      color: "#dadada !important",
                      background: "#314C7F",
                      padding: "0 5px",
                    },
                    "& input": {
                      color: "#dadada !important",
                    },
                    "& label.Mui-focused": {
                      color: "#00FFF5 !important",
                    },
                    "& .Mui-focused": {
                      "& fieldset": {
                        borderColor: "#00FFF5 !important",
                      },
                    },
                 }}
                /> */}
                {renderInputFields()}
                {/* <div className="flex items-center border border-gray-300 rounded">
                  <button
                    className="px-3 py-2 border-r border-gray-300 focus:outline-none"
                    onClick={decrement}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={value}
                    onChange={(e) => setValue(Number(e.target.value))}
                    className="w-full px-3 py-2 focus:outline-none"
                  />
                  <button
                    className="px-3 py-2 border-l border-gray-300 focus:outline-none"
                    onClick={increment}
                  >
                    +
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneTxnAmount;
