import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { Box } from "@mui/material";
import { viewAccountDetail } from "../../redux/features/verifyPAN/verifyPAN";
import { beneTxnAccountDetail } from "../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import DeclinedPopup from "../../containers/Accounts/BeneficiaryAccount/BeneAcountApproval/DeclinedPopup";
import ArrowPlay from "../../assets/Beneficiary Icons/arrowPlay.png";
import NotSelect from "../../assets/Approve transactions/not selected.png";
import Selected from "../../assets/Approve transactions/selected.png";
import formatDateViewAll from "../../utils/formatDateViewAll";
import BeneTxnSideDrawer from "../SideDrawer";
import { checkUsertoken } from "../../redux/features/login/login";

const BeneficiaryMultiApprovalTxn = ({
  filterOpen,
  data,
  setAllAccounts,
  setselectAllCard,
  selectedTransactions,
  setSelectedTransactions,
  totalAmount,
  setTotalAmount,
  setSelectedSendTransactions,
  selectedSendTransactions,
}) => {
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState([]);
  const [datas, setDatas] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const multitxnApprovedata = data;
  const { beneTxnAccountData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const updateAllAccounts = (updatedAccounts) => {
    setAllAccounts(updatedAccounts);
  };
  useEffect(() => {
    setDatas({
      ...datas,
      owner_account_balance_last_fetched_at_in_words:
        beneTxnAccountData?.transaction[0]
          ?.owner_account_balance_last_fetched_at_in_words,
      owner_account_balance:
        beneTxnAccountData?.transaction[0]?.owner_account_balance,
      owner_account_balance_last_fetched_at:
        beneTxnAccountData?.transaction[0]
          ?.owner_account_balance_last_fetched_at,
    });
  }, [beneTxnAccountData]);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37"; //#4ddd37
      case "denied":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const SideDrawerOpen = async (status, id, txnId) => {
    SetDrawerStatus(status);

    setisDrawerOpen(!isDrawerOpen);
    dispatch(checkUsertoken());
    await dispatch(viewAccountDetail({ id }));
    await dispatch(beneTxnAccountDetail(txnId));
  };

  const handleCardClick = (account) => {
    if (account.beneficiary_account_approval_status === "approved") {
      setselectAllCard(false);
      setSelectedTransactions((prevSelected) => {
        const cardAmount = Number(account.amount);
        const isSelected = prevSelected.includes(account.txn_id);

        if (isSelected) {
          setTotalAmount((prevAmount) => prevAmount - cardAmount);
          let data = selectedSendTransactions.filter((val) => {
            return val?.txn_id !== account.txn_id;
          });
          setSelectedSendTransactions(data);
          setIsLimitReached(false);
          return prevSelected.filter((id) => id !== account.txn_id);
        } else {
          if (prevSelected.length < 20) {
            setTotalAmount((prevAmount) => prevAmount + cardAmount);
            setSelectedSendTransactions((prev) => [...prev, account]);
            return [...prevSelected, account.txn_id];
          } else {
            setIsLimitReached(true);
            return prevSelected;
          }
        }
      });
    }
  };

  return (
    <div className="multiApprovalViewallCard">
      {showPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <DeclinedPopup
            onClose={handleClosePopup}
            accountId={deniedId}
            allAccounts={multitxnApprovedata}
            updateAllAccounts={updateAllAccounts}
            status={"Transaction"}
          />
        </div>
      )}
      <BeneTxnSideDrawer
        isOpen={isDrawerOpen}
        onClose={SideDrawerOpen}
        txnRequiredStatus={"BeneficiaryTxnMultiApproval"}
        setIsSidebarOpen={setisDrawerOpen}
        BeneTxnDetailsPage={BeneTxnDetailsId}
        BeneTxnDetailsFunc={SetBeneTxnDetailsId}
        DrawerOpenStatus={DrawerStatus}
        multiApproveTxn={true}
        multitxnApprovedata={datas}
        setMultiApproval={setDatas}
        setAllAccounts={setAllAccounts}
        allAccounts={data}
        disabled={sideDrawerDisabled}
        setDisabled={setSideDrawerDisabled}
        // setSelectedSendTransactions={setSelectedSendTransactions}
        // selectedSendTransactions={selectedSendTransactions}
      />
      {data?.map((account, index) => (
        <Box
          key={index}
          className={`px-4 multiSelectApprovalViewAllCard relative hover:opacity-80

          `}
          style={{
            backgroundColor:
              account.beneficiary_account_approval_status === "pending"
                ? "#1C212A"
                : selectedTransactions.includes(account.txn_id)
                ? "#4A6D4B" // Apply selected card class if it's selected
                : "#3a5686",
            cursor:
              filterOpen ||
              account.beneficiary_account_approval_status === "pending"
                ? "default"
                : "pointer",
            opacity: filterOpen ? "0.1" : "",
          }}
          onClick={() => handleCardClick(account)}
        >
          <div className="multiApproval_view_all_user">
            <div
              className="multiApproval_viewall_name_div"
              style={{
                color: getStatusColor(
                  account.beneficiary_account_approval_status
                ),
              }}
            >
              {account.beneficiary_account_approval_status === "pending" ? (
                <span className="w-203 h-22 text-left font-normal text-[16px] opacity-[0.7]">
                  Account Approval Pending
                </span>
              ) : (
                <>
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "17px",
                      paddingRight: "5px",
                    }}
                  >
                    ₹{" "}
                    {Number(account.amount)?.toLocaleString("en-IN", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>

                  <span className="w-[17px] h-[17]">
                    {selectedTransactions.includes(account.txn_id) ? (
                      <img className="" src={Selected} alt="" />
                    ) : (
                      <img className="" src={NotSelect} alt="" />
                    )}
                  </span>
                </>
              )}
            </div>

            <div
              className="multiApproval_viewall_bank_acc_div"
              style={{
                opacity:
                  account.beneficiary_account_approval_status === "pending"
                    ? "0.7"
                    : "1",
                fontWeight:
                  account.beneficiary_account_approval_status === "pending"
                    ? "100"
                    : "600",
              }}
            >
              {account.beneficiary_account_name}
            </div>
            <div className="multiApproval_viewall_acc_title">
              {" "}
              {account.created_on
                ? formatDateViewAll(account.created_on, false, true)
                : "Not given"}
            </div>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();

              setDatas(account);
              if (account.beneficiary_account_approval_status === "pending") {
                setSideDrawerDisabled(false);
                SideDrawerOpen(
                  "BeneficiaryTxnMultiApprovalPending",
                  account?.beneficiary_account_id,
                  account.txn_id
                );
              } else {
                setSideDrawerDisabled(false);
                SideDrawerOpen(
                  "BeneficiaryTxnMultiApproval",
                  account?.beneficiary_account_id,
                  account.txn_id
                );
              }
            }}
            className={`multiApproval_viewall_card_container absolute bottom-0 right-0 mb-0 flex flex-row `}
            style={{
              width:
                account.beneficiary_account_approval_status === "pending"
                  ? "117px"
                  : "94px",
            }}
          >
            <p
              className="text-[#1D3A6D] p-[5px] font-normal leading-[14px] text-[11px]"
              style={{
                cursor:
                  account.beneficiary_account_approval_status === "pending" ||
                  account.beneficiary_account_approval_status === "approved"
                    ? "pointer"
                    : "default",
              }}
            >
              {account.beneficiary_account_approval_status === "pending"
                ? "Approve account"
                : " More details"}
            </p>
            <span className=" w-[20px]  mt-[6px]">
              <img src={ArrowPlay} alt="" />
            </span>
          </div>
        </Box>
      ))}
    </div>
  );
};

export default BeneficiaryMultiApprovalTxn;
