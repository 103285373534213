import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../env";


const baseUrl = env.REACT_APP_BASE_URL;
// Action
export const viewGSTINDetails = createAsyncThunk(
    "viewGSTINDetails",
    async (values) => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                // "X-Api-Key": sessionStorage.getItem("q2p_token"),
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
        };

        return await fetch(
            `https://nxt.api.q2pay.in/api/utilities/view_gstin_details?page_no=${values?.page_no || 1}&page_size=${values?.page_size || 15}&entity_id=${values?.entity_id || ""}&pan=${values?.entity_id || ""}`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);

export const collectGSTINDetails = createAsyncThunk(
    "collectGSTINDetails",
    async (values) => {
        const data = {
            'pan': values?.pan
        }
        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                // "X-Api-Key": sessionStorage.getItem("q2p_token"),
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
            body: JSON.stringify(data)
        };

        return await fetch(
            `${baseUrl}/utilities/collect_gstin_details`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);
export const forceCollectGSTINDetails = createAsyncThunk(
    "forceCollectGSTINDetails",
    async (values) => {
        const data = {
            'pan': values?.pan
        }
        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                // "X-Api-Key": sessionStorage.getItem("q2p_token"),
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
            body: JSON.stringify(data)
        };

        return await fetch(
            `${baseUrl}/utilities/collect_gstin_details`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);




const utilitiesSlice = createSlice({
    name: "utilities",
    initialState: {
        isLoading: false,
        data: [],
        collect_data: [],
        force_collect_data: [],
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(viewGSTINDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(viewGSTINDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(viewGSTINDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
        builder.addCase(collectGSTINDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(collectGSTINDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.collect_data = action.payload;
        });
        builder.addCase(collectGSTINDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
        builder.addCase(forceCollectGSTINDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(forceCollectGSTINDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.force_collect_data = action.payload;
        });
        builder.addCase(forceCollectGSTINDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default utilitiesSlice.reducer;