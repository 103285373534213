import { delFromObj } from "../utils/delFromObj";

export const fieldForceDashboardChecks = (obj, services) => {
  var data = delFromObj(obj, "status");
  if (services ? !services.includes("FATransaction") : true) {
    delete data.required_approval_txn_details;
  } else {
    data["Require approval"] = data.required_approval_txn_details;
    delete data.required_approval_txn_details;
  }
  if (services ? !services.includes("FSTransaction") : true) {
    delete data.required_settlement_txn_details;
  } else {
    data["Require settlement"] = data.required_settlement_txn_details;
    delete data.required_settlement_txn_details;
  }
  if (data.processing_txn_details) {
    data["Processing transactions"] = data.processing_txn_details;
    delete data.processing_txn_details;
  }
  if (data.settled_txn_details) {
    data["Successful transactions"] = data.settled_txn_details;
    delete data.settled_txn_details;
  }
  return data;
};

