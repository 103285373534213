const FormatAmount = ({ price }) => {
  const numberPrice = Number(price);

  const formattedPrice = Intl.NumberFormat("en-IN", {
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numberPrice);

  const [integerPart, decimalPart] = formattedPrice.split(".");
  const paddedIntegerPart = integerPart.padStart(2, "0");

  return `${paddedIntegerPart}.${decimalPart}`;
};

export default FormatAmount;
