import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../../../../../utils/validation";
import { InputAdornment, TextField } from "@mui/material";
import verifyLogo from "../../../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import { toast } from "react-toastify";
import { verifyThisPAN } from "../../../../../../redux/features/verifyPAN/verifyPAN";
import loadingLogo from "../../../../../../assets/Approve transactions/inputloder.gif";
import trimSentence from "../../../../../../utils/trimSentence";

const EntityPanDetails = (props) => {
  const panStatus = useSelector((state) => state.verify.verifyPAN);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [customErrors, setCustomErrors] = useState({});

  const dispatch = useDispatch();

  const modifyValue = (type, value) => {
    if (type === "entity_pan") {
      return value?.toUpperCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "entity_pan") return Validation.allowedPAN(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "entity_pan")
      errors = Validation.validatePan(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewEntityData, [name]: value };
      props.setRegisterNewEntityData(newObj);
    }
    if (
      props.registerNewEntityData.entity_pan.length === 0 ||
      props.registerNewEntityData.entity_pan.length < 10
    ) {
      setPanVerificationStatus(false);
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (
      props.registerNewEntityData.entity_pan.length > 0 &&
      panStatus?.status === true
    ) {
      let errors = { ...customErrors };
      delete errors["entity_pan"];
      setCustomErrors(errors);
      setPanVerificationStatus(true);
      const newObj = {
        ...props.registerNewEntityData,
        entity_name: panStatus?.pan_details?.full_name,
      };
      props.setRegisterNewEntityData(newObj);
    }

    if (
      props.registerNewEntityData.entity_pan.length > 0 &&
      panStatus?.errors
    ) {
      let errors = { ...customErrors };
      errors["entity_pan"] = panStatus.errors;
      setCustomErrors(errors);
      setPanVerificationStatus(false);
    }
  }, [panStatus]);

  useEffect(() => {
    if (
      props.registerNewEntityData.entity_pan?.length === 10 &&
      !customErrors["entity_pan"]
    ) {
      dispatch(
        verifyThisPAN({ pan_number: props.registerNewEntityData.entity_pan })
      );
    }
  }, [props.registerNewEntityData.entity_pan]);

  useEffect(() => {
    if (props.registerNewEntityData.entity_pan.length === 0) return;
    if (
      props.registerNewEntityData.entity_pan?.length === 10 &&
      panVerificationStatus
    )
      props.enableNextButton(true);
    else props.enableNextButton(false);
  }, [panVerificationStatus, props.registerNewEntityData.entity_pan]);

  // this needs to be checked

  const getPanVerifiedDiv = (
    name,
    status,
    isLoading,
    label,
    value,
    maxLength
  ) => {
    return (
      <div
        className={`
        ${value?.length === 10 && !isLoading && status && "-mx-1"}
        min-w-full flex flex-row`}
      >
        <TextField
          className="w-full disabled:text-white antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid white",
            },
            maxLength: { maxLength },
          }}
          InputLabelProps={{
            style: {
              color: "white",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: props?.readOnly,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
                {value.length === 10 &&
                  !isLoading &&
                  (status ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : (
                    <img
                      src={skippedVerification}
                      alt="verified logo"
                      onClick={() => {
                        showErrors(name);
                      }}
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ))}
                {props.readOnly && (
                  <img
                    src="/assets/CreateTransactionicons/Lock.png"
                    alt="verified logo"
                    className="cursor-pointer mx-4 w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
              </InputAdornment>
            ),
          }}
          label={label}
          variant="standard"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const panVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        <div
          className={`min-w-full
          flex justify-center py-1
          rounded-r-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {
              // Verified Vendor Pan Number Div
              getPanVerifiedDiv(
                "entity_pan",
                panVerificationStatus,
                panStatusLoading,
                "PAN number",
                props.registerNewEntityData.entity_pan,
                10
              )
            }
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="p-2 bg-custom-lightGreen  flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-4 2xl:gap-6 4xl:gap-8"
    >
      <div>
        <div
          className={`-m-2 ${props.bgColor}
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-4 md:p-6 lg:p-8 xl:p-10 4xl:p-24`}
        >
          <div
            className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
          >
            {props.title}
          </div>
        </div>
        <div
          className={`-m-2
           ${props.bgColor} rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col
          drop-shadow-xl
          gap-4 2xl:gap-6 4xl:gap-8`}
        >
          <div
            className={`${props.bgColor} drop-shadow-xl h-full
          rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl
          flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-6`}
          >
            {props.activeStep !== 2 ? (
              <div className="w-full">{panVerificationDiv()}</div>
            ) : (
              <div className="flex flex-col gap-8">
                <div>
                  <div
                    className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
                  >
                    {props?.registerNewEntityData?.entity_pan}
                  </div>
                  <div className="antaliased text-white text-xs opacity-70">
                    PAN number
                  </div>
                </div>
                <>
                  <div className="h-[1px] bg-white px-8 opacity-70"></div>
                  <div>
                    <div
                      className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
                    >
                      {props?.registerNewEntityData?.entity_name}
                    </div>
                    <div className="antaliased text-white text-xs opacity-70">
                      Legal name
                    </div>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
      {panVerificationStatus &&
        props.activeStep !== 2 &&
        props.registerNewEntityData?.entity_pan?.length > 0 && (
          <div className="-mx-4">
            <div
              className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
          gap-6"
            >
              <div
                className="bg-custom-lightGreen rounded-l-large 4xl:rounded-l-customXl 5xl:rounded-l-custom2Xl
            flex w-full p-2 flex-row justify-between"
              >
                <div className="w-[70%] flex flex-row gap-2">
                  <div className="flex flex-row w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                    <img
                      className="self-center p-1"
                      src={verifyLogo}
                      alt="verified logo"
                    />
                  </div>
                  <div className="flex flex-row h-full w-[90%]">
                    <div
                      className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                    >
                      {trimSentence(
                        props.registerNewEntityData?.entity_name
                          ? props.registerNewEntityData.entity_name.toLowerCase()
                          : panStatus?.pan_details?.full_name.toLowerCase(),
                        25
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-[30%] flex flex-row justify-end">
                  <div
                    className="self-center
                antaliased text-white text-xs "
                  >
                    Legal name
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default EntityPanDetails;
