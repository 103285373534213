import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DeleteButton from "../../../components/Accounts/Common/DeleteButton";
import StrategyContext from "./strategyContext";
import QuickAccessMenu from "../../../components/Accounts/Common/QuickAccessMenu";
import SupportInfo from "../../../components/Accounts/Common/SupportInfo";
import { accountViewAllDetails } from "../../../redux/features/accounts/viewAll";
import {
  accountDetailsBackground,
  accountDetailsCardBg,
} from "../../../services/approvalStatus";
import EditButton from "../../../components/Accounts/Common/EditButton";

const AccountDetails = () => {
  const dispatch = useDispatch();
  const { account_type, account_id } = useParams();
  const viewAllData = useSelector((state) => {
    if (!state.accountViewAll.records) {
      dispatch(
        accountViewAllDetails({
          account_type: account_type,
          account_id: account_id,
        })
      );
    }
    return state.accountViewAll.records;
  });

  const accountData = viewAllData[0];
  const services = useSelector((state) => state.refreshToken.services);
  const bgColor = accountDetailsBackground(accountData?.approval_status)[0];
  const darkBgColor = accountDetailsBackground(accountData?.approval_status)[1];
  const cardsBgColor = accountDetailsCardBg(accountData?.approval_status);

  useEffect(() => {
    dispatch(
      accountViewAllDetails({
        account_type: account_type,
        account_id: account_id,
      })
    );
  }, [dispatch, account_id, accountData?.approval_status, account_type]);

  const txn_history_url = () => {
    if (account_type === "vendor")
      return `/vendor_transactions/view_all_transactions?vendor_id=${account_id}`;
    if (account_type === "client")
      return `/client_transactions/view_all_transactions?client_id=${account_id}`;
    if (account_type === "field_force")
      return `/ff_transactions/view_all_transactions?destination_account_id=${account_id}`;
    if (account_type === "owner")
      return `/internal_transactions/view_all_transactions?owner_id=${account_id}`;
  };

  return (
    <div
      className="h-full min-h-screen"
      style={{
        background: `linear-gradient(to right,
        ${bgColor} 0%, ${bgColor} 66.66%,
        ${darkBgColor} 66.66%, ${darkBgColor} 100%)`,
      }}
    >
      <Box
        className="h-full flex flex-col-reverse lg:flex-row vendor-txn-timeline relative lg:min-w-[780px] lg:max-w-[1440px]"
        sx={{ margin: "0px auto 0px auto" }}
      >
        <Box
          sx={{ bgcolor: bgColor, backgroundSize: "cover" }}
          className="flex flex-row justify-end pb-8 w-full lg:min-w-[780px] lg:max-w-[960px]"
        >
          <div className="mt-24 py-4 w-full flex flex-col justify-items-center">
            <div className="p-12 py-6">
              <div className="flex flex-col justify-between">
                <div className="">
                  <StrategyContext
                    accountType={account_type}
                    componentType="accountBannerComponent"
                    accountData={accountData}
                  />
                </div>
              </div>
            </div>
            <div className="p-12 py-6 flex flex-col">
              <StrategyContext
                accountType={account_type}
                componentType="accountBodyComponent"
                accountData={accountData}
              />
            </div>
          </div>
        </Box>

        <div
          className="hidden lg:flex h-fit lg:min-h-screen flex-col w-full lg:max-w-[480px] overflow-hidden"
          style={{
            backgroundColor: darkBgColor,
          }}
        >
          <div
            className="py-4 mt-24 lg:fixed h-full max-w-[480px] overflow-x-hidden overflow-y-auto vendor-txn hide-scrollbar flex flex-col justify-items-center"
            style={{ width: "inherit", position: "relative" }}
          >
            <div className="px-8 xl:px-12 py-6">
              <QuickAccessMenu
                bg_color={cardsBgColor}
                tx_history_url={txn_history_url()}
                disableRecepients={account_type === "owner" ? true : false}
              />
            </div>
            <div className="px-8 xl:px-12 py-6">
              <SupportInfo
                bg_color={cardsBgColor}
                account_id={account_id}
                account_type={account_type}
                wire_transfer_ref={accountData?.nach_reference_no}
              />
            </div>
            <div className="flex gap-8 fixed right-16 lg:right-8 bottom-2 3xl:right-64 3xl:bottom-8 4xl:bottom-2/3 5xl:bottom-2/3 5xl:right-[32%] 6xl:right-[32%]">
              <div className="z-20">
                {services?.includes("Settle_txn") &&
                  (accountData?.approval_status === "Y" ||
                    accountData?.approval_status === "P" ||
                    accountData?.approval_status === "Denied") && (
                    <EditButton
                      account_id={account_id}
                      account_type={account_type}
                    />
                  )}
              </div>
              <div className="z-10">
                {services?.includes("Settle_txn") &&
                  (accountData?.approval_status === "Y" ||
                    accountData?.approval_status === "P" ||
                    accountData?.approval_status === "Denied") && (
                    <DeleteButton
                      account_id={account_id}
                      account_type={account_type}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default AccountDetails;
