export const txnPayloadMapper = {
  "Processing transactions": { approval_status: "Processing", page_no: "0", page_size: "50" },
  "Settled transactions": { approval_status: "S", page_no: "0", page_size: "50" },
  "Successful transactions": { approval_status: "S", page_no: "0", page_size: "50" },
  "Created transactions": { approval_status: "Y", page_no: "0", page_size: "50" },
  "Cancelled transactions": { approval_status: "Cancelled", page_no: "0", page_size: "50" },
  "Abandoned transactions": { approval_status: "Abandoned", page_no: "0", page_size: "50" },
  "Failed transactions":{ approval_status: "Failed", page_no: "0", page_size: "50" },
  "Waiting for approval": { approval_status: "P", page_no: "0", page_size: "50" },
};
