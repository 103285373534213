import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import SearchBar from "../../../../components/_utils/search/SearchBar";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { debounce } from "lodash";
import TotalSummation from "../../../../components/Settings/Management/Entities/TotalSummation";
import EntityCard from "../../../../components/Settings/Management/Entities/EntityCard";
import SideBar from "../../../../components/Settings/Management/Entities/SideBar";
import filterIcon from "../../../../assets/ViewAllTransactions/filter.png";
import FilterIconDesktop from "../../../../components/ViewAllTransactions/FilterDrawer/FilterIconDesktop";
import SearchTransparentLogo from "../../../../assets/ViewAllTransactions/search-transparent.png";
import SearchLogo from "../../../../assets/ViewAllTransactions/search-white.png";

import DropdownLogo from "../../../../assets/ViewAllTransactions/Dropdown red.png";
import { getAllUserFeatures } from "../../../../redux/features/UserFeatures/userFeatures";
import { getServices } from "../../../../redux/features/Settings/Management/Services";

const Services = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState();
  const viewAllData = useSelector((state) => state.services.services);
  const [searchParams] = useSearchParams();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalRecords, setRecords] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const loading = useSelector((state) => state.services.isLoading);
  const [txnStaus, setTxnStatus] = useState(
    searchParams.get("approval_status") || null
  );
  const toggleShowSearchBar = () => setShowSearchBar(!showSearchBar);
  const [data, setData] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);
  const [toggleFeatures, setToggleFeatures] = useState(true);
  const panRef = useRef();
  const entityIdRef = useRef();

  const [filterValues, setFilterValues] = useState({
    pan: "",
    entity_id: "",
    feature: "",
  });

  const handleFeatureChange = (value) => {
    setFilterValues({ ...filterValues, feature: value });
  };

  const handleClearFilter = () => {
    setFilterValues({
      pan: "",
      entity_id: "",
      feature: "",
    });
    panRef.current.value = "";
    entityIdRef.current.value = "";
    setShowSideBar(false);
  };

  const handleApplyFilter = () => {
    setFilterValues({
      ...filterValues,
      pan: panRef.current.value,
      entity_id: entityIdRef.current.value,
    });
    setShowSideBar(false);
  };

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    // deDup data
    let j = 0;
    appendedData?.forEach((item) => {
      if (
        !deDupData?.find(
          (deDupItem) => deDupItem.service_id === item.service_id
        )
      ) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    return appendedData;
  };

  const handleSearch = (search_string) => {
    if (search_string !== searchString) {
      setData([]);
      setSearchString(search_string);
    }
  };

  useEffect(() => {
    setTxnStatus(searchParams.get("approval_status"));
  }, [searchParams]);

  const getData = () => {
    dispatch(
      getServices({
        page_no: page,
        search_string: searchString,
      })
    );
  };

  useEffect(() => {
    dispatch(getAllUserFeatures());
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  const userFeaturesData = {
    status: true,
    total_count: 70,
    features: [
      {
        feature_id: "9999ii-0987-nbhg-0popo-nouopp",
        feature_name: "Tkkl 1122",
        feature_description: "Teskkr 9 4432",
        created_on: 190385,
        created_by: "Jheor",
        created_by_name: "poqie",
        user_features_line_items: [
          {
            id: "opqqhf-8389-khsg-ljaf-poq787b",
            platform_service_id: "0kkgo-6004-jlld3-klr-443mmg",
            service_id: "Sufre",
            service: "To Verify the service",
          },
          {
            id: "mmlo-l8pep-gdae-mmng-2665g9",
            platform_service_id: "mopye5-nkpu-nn55-mouui-nm997",
            service_id: "ppqu244",
            service: "pop",
          },
          {
            id: "849d9b79-261f-49ce-b201-780317494206",
            platform_service_id: "2fe17c90-daeb-4382-b59e-14b1add82fba",
            service_id: "JJgr",
            service: "To View the Details",
          },
        ],
      },
      {
        feature_id: "849d9b79-261f-49ce-a491-mopye5-nkpu",
        feature_name: "Memnm",
        feature_description: "olol pk",
        created_on: 197493,
        created_by: "W98680",
        created_by_name: "kak pqqr",
        user_features_line_items: [
          {
            id: "lakd-pnim-7354-nasd-hhrr473",
            platform_service_id: "ggfd-00nn-4nngd-hhg6-uyfd03",
            service_id: "llpp34",
            service: "fers Silllngs",
          },
        ],
      },
    ],
    title: {
      records: 70,
      current_page: 1,
      total_pages: 35,
      page_item_count: 2,
      next_page: 2,
    },
  };

  const handleScroll = debounce(() => {
    const container = document.getElementById("infinityScrollContainer");
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight <=
      1 &&
      !viewAllData?.errors
    ) {
      if (!loading && hasMore) {
        setPage(page + 1);
      }
    }
  }, 100);

  useEffect(() => {
    let container = document.getElementById("infinityScrollContainer");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (viewAllData === null) return;

    if (data?.length === 0 && viewAllData?.services?.length === 0) {
      setRecords(0);
      setTotalAmount(0.0);
    }

    if (viewAllData?.services?.length === 0) {
      setHasMore(false);
      return;
    }
    setHasMore(true);
    setData(dataAppend(data, viewAllData?.services));
    if (viewAllData?.services?.length > 0) {
      setRecords(viewAllData?.total_count);
    }

    const container = document.getElementById("infinityScrollContainer");
    if (container.scrollHeight <= container.clientHeight) setPage(page + 1);
  }, [viewAllData]);

  useEffect(() => {
    getData();
  }, [page]); //Add "page" variable in the dependacney to implement pagination

  useEffect(() => {
    setData([]);
    if (page !== 1) setPage(1);
    else {
      getData();
    }
  }, [searchString, txnStaus]);

  const getFilterDrawerContent = () => {
    return (
      <>
        <div className="flex flex-col w-full gap-10 justify-start items-center py-10 px-10">
          <div className="w-full flex flex-row justify-center items-center bg-[#3A5686] px-5 py-2 rounded-lg">
            <input
              className="w-full outline-none text-white text-lg 3xl:text-xl 5xl:2xl bg-[#3A5686]"
              placeholder="PAN no."
              onChange={(e) =>
                setFilterValues({ ...filterValues, pan: e.target.value })
              }
              value={filterValues.pan}
              ref={panRef}
            />
            <img alt="" src={SearchTransparentLogo} className="w-6 h-6" />
          </div>
          <div className="w-full flex flex-row justify-center items-center bg-[#3A5686] px-5 py-2 rounded-lg">
            <input
              className="w-full outline-none text-white text-lg 3xl:text-xl 5xl:2xl bg-[#3A5686]"
              placeholder="Entity ID"
              onChange={(e) =>
                setFilterValues({ ...filterValues, entity_id: e.target.value })
              }
              value={filterValues.entity_id}
              ref={entityIdRef}
            />
            <img alt="" src={SearchTransparentLogo} className="w-6 h-6" />
          </div>
          {toggleFeatures ? (
            <div
              onClick={() => setToggleFeatures(false)}
              className="w-full flex flex-row justify-between bg-[#3A5686] items-center px-5 py-2 rounded-lg cursor-pointer"
            >
              <div className="text-white text-lg 3xl:text-xl 5xl:2xl">
                {filterValues.feature === "" ? "Feature" : filterValues.feature}
              </div>
              <div>
                <img alt="" src={DropdownLogo} className="w-6 h-6" />
              </div>
            </div>
          ) : (
            <div className="w-full max-h-80 flex flex-col justify-between bg-[#667CA3] border-[#E25869] border px-5 py-2 rounded-lg">
              <div className="flex items-center w-full p-2 justify-between bg-[#3A5686] rounded-lg">
                <div className="flex gap-2 items-center">
                  <img alt="" src={SearchLogo} className="w-6 h-6" />
                  <input
                    className="text-white bg-[#3A5686] text-lg 3xl:text-xl 5xl:2xl outline-none"
                    placeholder="Search"
                  />
                </div>
                <svg
                  onClick={() => {
                    setToggleFeatures(true);
                    setFilterValues({ ...filterValues, feature: "" });
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#000"
                  className="w-6 h-6 cursor-pointer"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div>
                {userFeaturesData.features.map((feature) => (
                  <button
                    onClick={() => {
                      handleFeatureChange(feature.feature_name);
                      setToggleFeatures(true);
                    }}
                    className="text-white text-lg 3xl:text-xl 5xl:2xl bg-[#667CA3] hover:bg-[#3A5686] my-1 w-full p-2 flex justify-start rounded-lg"
                  >
                    {feature.feature_name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex w-full justify-between absolute bottom-10 px-12">
          <button
            onClick={handleClearFilter}
            className="py-2 px-7 text-xl 3xl:text-2xl 5xl:3xl hover:text-white text-gray-400 rounded-4xl border border-gray-400 hover:border-[#FAFAFA]"
          >
            Clear filter
          </button>
          <button
            onClick={handleApplyFilter}
            className="py-2 px-7 text-xl 3xl:text-2xl 5xl:3xl hover:text-white text-gray-400 rounded-4xl border border-gray-400 hover:border-[#FAFAFA]"
          >
            Apply filter
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      className="pt-24
    bg-custom-appBack 
      flex flex-col h-screen"
    >
      <div
        className="bg-custom-appBackLight drop-shadow-2xl
        border-b-1 border-custom-card  z-10
         w-full flex flex-col justify-center h-1/5"
      >
        <div className="w-10/12 h-1/2 self-center  flex flex-row justify-between">
          <div className="w-64 md:w-1/4 self-center">
            <TotalSummation total={totalRecords} />
          </div>
          <div className="invisible">
            <p>Filtered by :</p>
          </div>
          <div className="flex flex-col justify-center">
            <FilterIconDesktop setDrawerState={setShowSideBar} />{" "}
            {/* sidebar trigger component */}
            {/* pass all the component as children or wrap them inside in the below component */}
            <SideBar
              state={showSideBar}
              setState={setShowSideBar}
              title={
                <div className="flex items-center gap-6">
                  <img alt="" src={filterIcon} className="w-6 h-6" />
                  <div className="text-2xl 3xl:text-3xl 5xl:4xl w-96 text-white">
                    Filter
                  </div>
                </div>
              }
            >
              {getFilterDrawerContent()}
            </SideBar>
          </div>
        </div>
      </div>

      <div
        id="infinityScrollContainer"
        className="pt-2 z-0 top-1/5 bottom-0
          w-full  flex flex-col h-4/5 overflow-y-auto overflow-x-hidden viewAllvendor-txn"
      >
        <div className="self-center h-5/6 w-10/12 flex flex-col gap-4">
          <div className="h-[10%] flex left-auto right-auto flex-row justify-between">
            <Link
              to="/settings/management/entities/create"
              className="bg-white h-2/3 my-auto font-bold text-center px-4 py-2
                        hover:bg-gray-200 cursor-pointer shadow-xl
                        rounded-lg text-black flex items-center "
            >
              <span className="font-light text-3xl">+</span> &nbsp; Create
              Service
            </Link>
            <div className="w-[40%]">
              <SearchBar
                handleSearch={handleSearch}
                showSearchBar={showSearchBar}
                toggleShowSearchBar={toggleShowSearchBar}
                bgColor={"bg-custom-card"}
                bgHover={"hover:bg-custom-cardHover"}
              />
            </div>
          </div>

          <div className="h-[85%]">
            <div className="pb-6 3xl:pb-8 5xl:pb-10">
              {viewAllData?.services?.length > 0 || data?.length > 0 ? (
                <div
                  className="grid gap-6 4xl:gap-16
                  grid-cols-1
                  md:grid-cols-2
                  xl:grid-cols-4
                  4xl:grid-cols-5
                  6xl:grid-cols-6
                  justify-items-center"
                >
                  {data?.map((txn) => (
                    <div className="w-full">
                      <Link
                        style={{ textDecoration: "none" }}
                        to={`/settings/management/entities/${txn.entity_id}`}
                      >
                        <EntityCard
                          name={txn.service_name}
                          desc={txn.service_desc}
                          status={txn.status}
                          status_title="Service status"
                          title="Service description"
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
                  <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                    No services found <br />
                    Services will appear here.
                  </div>
                  <div className="w-2/6 p-4 flex flex-row justify-center">
                    <img
                      className="h-full w-full"
                      src={NoDataImg}
                      alt="NoDataImg"
                    />
                  </div>
                </div>
              )}
            </div>
            {loading && (
              <div className="w-full m-auto justify-center items-center flex">
                <img
                  src={require("../../../../assets/Approve transactions/inputloder.gif")}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
