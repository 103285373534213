import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import { useDispatch } from "react-redux";

const Datacard = (props) => {
  const numberTransformation = (number) => {
    if (number > 99) {
      return "99+";
    } else if (number < 10 && number > 0) {
      return "0" + Number(number);
    } else if (number <= 0) {
      return "00";
    } else {
      return number;
    }
  };
  const colorProp = props.color;
  const numberProp = numberTransformation(props.number);
  const textProp = props.text;
  const imgProp = props.imgName;
  // const pathProp = props.path;
  const handleDatacardClick = () => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.handleStatus) {
      // Check if handleStatus prop exists
      props.handleStatus("approved"); // Pass "approved" status to handleStatus
    }
  };

  return (
    <div
      className={`datacard  ${
        numberProp !== "00" ? "cursor-pointer hover:bg-custom-cardHover" : ""
      } ${numberProp === "00" ? "opacity-40 cursor-default" : ""}`}
      onClick={numberProp !== "00" ? handleDatacardClick : undefined}
      style={{
        opacity: numberProp === "00" ? "0.5" : "1",
      }}
    >
      <div className="colorpage" style={{ backgroundColor: colorProp }}></div>

      <div className="cardpage pt-2">
        {imgProp ? (
          <div className="image-container">
            <img
              className="image"
              src={require(`../../assets/TransactionDashboardIcons/${imgProp}`)}
              alt=""
            />
          </div>
        ) : (
          <div className="word1" style={{ color: colorProp }}>
            {numberProp}
          </div>
        )}

        <div className="word2 antialiased text-sm text-white capitalize">
          {textProp}
        </div>
      </div>
    </div>
  );
};

export default Datacard;
