const formatGSTDate = (GSTDate) => {

    let dateObj = new Date(GSTDate);
    
    let day = dateObj.getDate();
    let month = dateObj.toLocaleString('default', { month: 'long' });
    let year = dateObj.getFullYear();
    let formattedDate = `${day} ${month} ${year}`;

    if (formattedDate == null || formattedDate == undefined) {
        return 'NA';
    } else {
        return formattedDate;
    }

}


export default formatGSTDate;