import React from "react";

function BeneTxnDataFormat(timestampInSeconds) {
  if (!timestampInSeconds || typeof timestampInSeconds !== "number") return "";

  const milliseconds = timestampInSeconds * 1000; // Convert seconds to milliseconds

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateObj = new Date(milliseconds);

  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();

  return `${day} ${months[monthIndex]} ${year}`;
}

export default BeneTxnDataFormat;
