import React from "react";
import { Box } from "@mui/material";
import arrowImg from "../../assets/TransactionDashboardIcons/arrow_next.png";
import MISImg from "../../assets/TransactionDashboardIcons/MIS report.png";
import debitVoucher from "../../assets/TransactionDashboardIcons/Debit voucher.png";
import paymentAdvice from "../../assets/TransactionDashboardIcons/Payment advice.png";
import txnInitEmail from "../../assets/TransactionDashboardIcons/Transaction initiation email.png";
import "./index.css";
import { Link } from "react-router-dom";

const QuickActionsall = ({module}) => {
  let buttons = [];
  if (module === "vendor") buttons = [
    {
      Img: debitVoucher,
      buttonName: "Send debit voucher",
    },
    {
      Img: paymentAdvice,
      buttonName: "Send payment advices",
    },
    {
      Img: txnInitEmail,
      buttonName: "Send transaction initiation emails",
    },
    {
      Img: MISImg,
      buttonName: "Send MIS Report",
    },
  ];
  else buttons = [{
    Img: MISImg,
    buttonName: "Send MIS Report"
  }];

  return (
    <div className="flex flex-col
      gap-2 4xl:gap-8
      p-4 4xl:p-8
      bg-custom-card
      drop-shadow-xl
      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl 6xl:rounded-custom2Xl">
      <div className="
        text-center
        text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl
        text-gray-300">
        Quick actions
      </div>
      <div className="grid grid-cols-1 divide-y-2 divide-custom-appBack">
        {buttons.map((button, index) => {
          return (
            <div className="py-2 4xl:py-6 flex justify-center cursor-pointer">
              <div className="
                p-2 5xl:p-4 flex flex-row gap-2
                items-center w-full h-full hover:bg-custom-cardHover
                rounded-3xl 3xl:rounded-large 5xl:rounded-customXl 6xl:rounded-custom2Xl">
                <div className="w-2/12">
                  <img
                    src={button.Img}
                    alt=""
                    className="h-8 w-8 4xl:h-16 4xl:w-16"
                  />
                </div>
                <div className="w-8/12 flex justify-start
                  text-base 3xl:text-lg 4xl:text-3xl
                  text-white items-center">
                  {button.buttonName}
                </div>
                <div className="w-2/12 flex justify-end">
                  <img
                    src={arrowImg}
                    alt="arrowImg"
                    className="h-6 w-6 4xl:h-12 4xl:w-12"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div >
    </div >
  );
};

export { QuickActionsall };
