import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FilterDrawer from "../../../components/ViewAllTransactions/FilterDrawer";
import ViewAllCounts from "../../../components/ViewAllTransactions/ViewAllCounts";
import DatacardViewAll from "../../../components/ViewAllTransactions/DatacardViewAll";
import AllStatusTypePopover from "../../../components/ViewAllTransactions/AllStatusTypePopover";
import { useDispatch, useSelector } from "react-redux";
import { getReimbursementViewAll } from "../../../redux/features/txnViewAll/txnViewAll";
import { approvalStatus } from "../../../services/approvalStatus";
import NoDataImg from "../../../assets/TransactionDashboardIcons/Nothing.png";

const ReimbursementViewAllT = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReimbursementViewAll());
  }, [dispatch]);

  const viewAllData = useSelector((state) => state.txnViewAll.reimbursement);
  const viewAllDataTxn = viewAllData ? viewAllData["transactions"] : null;

  return (
    <Box sx={{ marginTop: "80px" }}>
      <Box className="headContainer">
        <Box>
          <ViewAllCounts txn={345254} amt={34523} />
        </Box>
        <Box className="filterByLabel">
          <p>Filter by:</p>
        </Box>
        <Box className="filterIconContainer">
          <FilterDrawer />
        </Box>
      </Box>
      <Box className="bodyContainer">
        <Box className="ViewAllStatusTypePopover">
          <AllStatusTypePopover />
        </Box>
        <Box className="viewAllRecentCards">
          {viewAllDataTxn ? (
            viewAllDataTxn.map((txn) => (
              <Box className="txnCard">
                <DatacardViewAll
                  amount={txn.amount}
                  account={txn.txn_initiator_name}
                  status={approvalStatus(txn.approval_status)}
                />
              </Box>
            ))
          ) : (
            <>
              <Typography sx={{ color: "white" }}>
                No transactions found <br />
                All InstantPay refill transactions will appear here.
              </Typography>
              <Box
                sx={{
                  width: "50%",
                  margin: "auto",
                  marginTop: "5%",
                }}
              >
                <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ReimbursementViewAllT;
