import React from "react";
import VerficationSearch from "../../../../components/Settings/Verification/VerificationSearch";
import { getDummy } from "../../../../redux/features/verification/verification";
import VerificationDetails from "../../../../components/Settings/Verification/VerificationDetails";
import { verificationDetailsMapper } from "../../../../services/Settings/Verification/verificationDetailsMapper";
import CorpAddress from "../../../../components/Settings/Verification/CorpAddress";
import CPSection from "../../../../components/Settings/Verification/CPSection";
import CPCard from "../../../../components/Settings/Verification/CPCard";
import logo from "../../../../assets/Settings/Verification/Group 3550.png";
import { useDispatch, useSelector } from "react-redux";
import { verifyThisDIN } from "../../../../redux/features/verifyPAN/verifyPAN";

const DinVerification = () => {
  const dispatch = useDispatch();
  const verifiedDIN = useSelector((state) => state.verify.verifyDIN);

  return (
    <div className="mt-24 w-screen">
      <div className="fixed w-4/5 right-0 left-0 m-auto">
        <VerficationSearch
          placeholder={"Enter DIN number"}
          dispatchFn={verifyThisDIN}
        />
      </div>
      {verifiedDIN?.status ? (
        <>
          <div className="flex h-screen justify-center pt-40 mx-auto w-4/6">
            <VerificationDetails
              data={verifiedDIN?.din_details}
              template={verificationDetailsMapper["DIN Verification"]}
            />
          </div>
          {/* {verifiedDIN?.din_details?.companies_associated.length > 0 && <div className='flex items-center mx-auto w-full min-h-screen bg-[#29487E] shadow-lg'>
          <CPSection title={"Corporate Profile"} subtext={"Companies Associated"}>
            {
    
              verifiedDIN?.din_details?.companies_associated?.map((director, index) => {
                return (
                  <CPCard img={logo} name="Quest 2 Travel.com India pvt ltd" number={"00955631"} label={"CIN Number"} />
                )
              })
            }
          </CPSection>
        </div>
        } */}
          <div className="flex h-screen justify-center mx-auto mt-20 w-4/6">
            <CorpAddress address={verifiedDIN?.din_details?.present_address} />
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-10 h-screen items-center pt-40 mx-auto w-2/6">
          <div className="flex">
            <img
              src={require("../../../../assets/Settings/Verification/no_data.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div
            className="text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-center w-full
              text-white antialiased"
          >
            Enter DIN number in the search bar, to fetch the verified director
            profile details.
          </div>
        </div>
      )}
    </div>
  );
};

export default DinVerification;
