const stringifyObject = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj
    }

    const keyValuePairs = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            keyValuePairs.push(`"${key}": ${value}`);
        }
    }

    return `{${keyValuePairs.join(', ')}}`;
}

export default stringifyObject;