const AmountToWords = (num) => {
  const a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const numberToWordsInternal = (n) => {
    if (n < 20) return a[n];
    if (n < 100) return b[Math.floor(n / 10)] + (n % 10 ? " " + a[n % 10] : "");
    if (n < 1000)
      return (
        a[Math.floor(n / 100)] +
        " hundred" +
        (n % 100 === 0 ? "" : " and " + numberToWordsInternal(n % 100))
      );
    if (n < 100000)
      return (
        numberToWordsInternal(Math.floor(n / 1000)) +
        " thousand" +
        (n % 1000 !== 0 ? " " + numberToWordsInternal(n % 1000) : "")
      );
    if (n < 10000000)
      return (
        numberToWordsInternal(Math.floor(n / 100000)) +
        " lakh" +
        (n % 100000 !== 0 ? " " + numberToWordsInternal(n % 100000) : "")
      );
    return (
      numberToWordsInternal(Math.floor(n / 10000000)) +
      " crore" +
      (n % 10000000 !== 0 ? " " + numberToWordsInternal(n % 10000000) : "")
    );
  };

  const convertIntegerPart = (num) => {
    const crore = Math.floor(num / 10000000);
    const lakh = Math.floor((num % 10000000) / 100000);
    const thousand = Math.floor((num % 100000) / 1000);
    const hundred = Math.floor((num % 1000) / 100);
    const rest = Math.floor(num % 100);

    let words = "";
    if (crore > 0) words += numberToWordsInternal(crore) + " crore ";
    if (lakh > 0) words += numberToWordsInternal(lakh) + " lakh ";
    if (thousand > 0) words += numberToWordsInternal(thousand) + " thousand ";
    if (hundred > 0) words += numberToWordsInternal(hundred) + " hundred ";
    if (rest > 0) words += (words ? "and " : "") + numberToWordsInternal(rest);

    return words.trim();
  };

  const integerPart = Math.floor(num);
  const decimalPart = Math.round((num % 1) * 100);

  let words = convertIntegerPart(integerPart) + " rupees";

  if (decimalPart > 0) {
    words += " and " + numberToWordsInternal(decimalPart) + " paise";
  }

  return words.trim();
};

export default AmountToWords;
