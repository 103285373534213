import { useState, useRef, useEffect } from "react";
import formatCurrency from "../../../../../../utils/CurrencyFormater";
import stringifyObject from "../../../../../../utils/stringifyObject";
import { useSelector, useDispatch } from "react-redux";
import { createTransactionAuthorization } from "../../../../../../redux/features/Settings/Authorization";

function SetStages(props) {
  const dispatch = useDispatch();
  const [optionSelected, setOptionSelected] = useState([]);
  const [enteredTotal, setEnteredTotals] = useState([]);
  const [confirmState, setConfirmState] = useState(false);

  const handleGetGradeInfo = () => {
    const totals = document.getElementsByClassName("total");
    const grades = document.getElementsByClassName("grade");
    var totalArray = Array.from(totals);
    var gradesArray = Array.from(grades);
    let gradeInfo = {};
    let notifications = [];
    gradesArray.map((item, index) => {
      let total = totalArray[index].value;
      let grade = gradesArray[index].value;
      gradeInfo[grade] = total;
      notifications.push(grade);
      setInputStages({
        ...inputStages,
        grades_info: stringifyObject(gradeInfo),
        notification: notifications.join(", "),
      });
    });
  };

  const handleGradeChange = () => {
    let optionsSelected = [];
    const grades = document.getElementsByClassName("grade");
    var gradesArray = Array.from(grades);
    gradesArray.map((item, index) => {
      let grade = gradesArray[index].value;
      optionsSelected.push(grade);
    });
    setOptionSelected(optionsSelected);
  };

  const handleTotalChange = () => {
    const totals = document.getElementsByClassName("total");
    var totalArray = Array.from(totals);
    const grades = document.getElementsByClassName("grade");
    var gradesArray = Array.from(grades);
    let gradeInfo = {};
    totalArray.map((item, index) => {
      let total = Number(totalArray[index].value);
      let grade = gradesArray[index].value;
      gradeInfo[grade] = total;
    });
    Object.values(gradeInfo).filter((totValue) => totValue <= 0).length
      ? setConfirmState(false)
      : setConfirmState(true);
  };

  const addNewStage = (grades = [1], index) => (
    <>
      {index !== 0 && <hr className="border-black w-full" />}
      <div className="flex justify-between items-center w-full">
        <div className="flex gap-10 w-1/2">
          <div className="flex flex-col w-1/3">
            <label className="text-gray-300 text-md 4xl:text-2xl">Grade</label>
            <select
              onChange={handleGradeChange}
              className="grade bg-[#667CA3] p-2 text-white rounded-md"
              name="grade"
            >
              <option value="" disabled={true} selected>
                Select Grade
              </option>
              {grades?.map((grade, index) => (
                <option
                  key={index}
                  value={grade}
                  disabled={optionSelected.includes(grade)}
                >
                  {grade}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-1/3">
            <label className="text-gray-300 text-md 4xl:text-2xl">Total</label>
            <input
              onChange={handleTotalChange}
              type="number"
              name="total"
              className="total  bg-[#667CA3] p-2 text-white rounded-md"
            />
          </div>
        </div>
        <div className="text-[#F05A6A] text-xl 4xl:text-5xl">
          Stage {index + 1}
        </div>
      </div>
    </>
  );

  const addStage = (stage, index) => (
    <>
      {index !== 0 && <hr className="border-black w-full" />}
      <div className="flex justify-between w-full items-center">
        <div className="flex gap-10 w-1/2">
          <div className="flex flex-col w-1/3">
            <label className="text-gray-300 text-md 4xl:text-2xl py-1">
              Grade
            </label>
            <select
              style={{
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                appearance: "none",
                pointerEvents: "none",
              }}
              value={stage?.grade}
              className="bg-[#667CA3] p-2 text-white rounded-md "
            >
              <option>{stage?.grade}</option>
            </select>
          </div>
          <div className="flex flex-col w-1/3">
            <label className="text-gray-300 text-md 4xl:text-2xl py-1">
              Total
            </label>
            <input
              disabled={true}
              value={stage?.total}
              type="number"
              className="bg-[#667CA3] p-2 text-white rounded-md"
            />
          </div>
        </div>
        <div className="text-[#F05A6A] text-xl 4xl:text-5xl">
          Stage {index + 1}
        </div>
      </div>
    </>
  );

  const allGrades = useSelector((state) => state.entities.grades);
  const [availableGrades, setAvailableGrades] = useState(allGrades);
  const entity_id = useSelector(
    (state) => state.refreshToken?.data?.entity.entity_id
  );

  const [stages, setStages] = useState([addNewStage(availableGrades, 0)]);
  const [edit, setEdit] = useState(props?.rule ? false : true);

  const stagesList = props?.rule?.definition.split("<->").map((stage) => {
    const [grade, total] = stage.split("~");
    return { grade, total };
  });

  const [inputStages, setInputStages] = useState({
    lower_amount: props.min,
    higher_amount: props.max,
    type_of_transaction: props.type_of_transaction,
    entity_id: entity_id,
    notification: [],
    grades_info: {},
  });

  useEffect(() => {
    if (
      inputStages?.lower_amount &&
      inputStages?.higher_amount &&
      inputStages?.type_of_transaction &&
      inputStages?.entity_id &&
      inputStages?.notification.length > 0 &&
      inputStages?.grades_info
    ) {
      dispatch(createTransactionAuthorization({ inputStages: inputStages }));
      props.setShowAddRuleButton(true);
    }
  }, [inputStages]);

  return (
    <div>
      {!props.dontShowName && (
        <div className="text-xl 4xl:text-5xl text-white mx-9 mt-8">
          {props.rule?.entity_name}
        </div>
      )}
      <div
        className={`w-11/12 m-4 flex flex-col gap-10 justify-between bg-[#314D7E] rounded-4xl px-5 py-8 ${
          props.rule?.deactivated_by_id
            ? "opacity-60 pointer-events-none"
            : "shadow-xl"
        }`}
      >
        <div className="flex items-center justify-between w-full">
          {props.rule ? (
            <div className="text-[#F05A6A] w-fit text-xl 4xl:text-5xl">
              Rule {props.index + 1}
            </div>
          ) : (
            <div className="text-[#F05A6A] w-fit text-xl 4xl:text-5xl">
              New Rule
            </div>
          )}
          <div className="text-[#4DDD37] flex gap-5 text-xl 4xl:text-5xl">
            <div>₹ {formatCurrency(props.min)}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
            <div>₹ {formatCurrency(props.max)}</div>
            {props.rule && (
              <div className="text-gray-400 flex items-center justify-center">
                |
                <div
                  onClick={() => props.setArchive(props?.rule?.id)}
                  className="text-[#F05A6A] w-fit text-sm 4xl:text-md flex justify-center items-center hover:underline cursor-pointer px-2"
                >
                  {props.rule?.deactivated_by_id ? "Archived" : "Archive Rule"}
                </div>
              </div>
            )}
          </div>
        </div>
        {stagesList?.map((stage, index) => {
          return addStage(stage, index);
        })}
        {!props.rule &&
          stages?.map((stage) => {
            return stage;
          })}
        {!props?.rule && (
          <>
            <hr className="border-black w-full" />
            <div>
              <div className="relative flex justify-between items-center -my-10">
                <div
                  onClick={() => {
                    setStages([
                      ...stages,
                      addNewStage(availableGrades, stages.length),
                    ]);
                    setConfirmState(false);
                  }}
                  className={`${
                    allGrades.length !== optionSelected.length
                      ? ""
                      : "pointer-events-none opacity-50"
                  } flex justify-center items-center gap-2 py-4 cursor-pointer hover:opacity-60`}
                >
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="#314D7E"
                      className="w-10 h-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div className="text-white font-semibold text-xl 4xl:text-5xl">
                    Add new stage
                  </div>
                </div>
                <div className="absolute right-0 w-fit flex bg-[#3A5686] p-5 rounded-tl-4xl gap-16 justify-center rounded-br-4xl -mr-5">
                  <button
                    className="text-gray-300 hover:underline"
                    onClick={() => props.handleCancel()}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      handleGetGradeInfo();
                    }}
                    className={`py-1 px-5 ${
                      confirmState ? "bg-green-600" : "bg-gray-500"
                    } rounded-lg shadow-lg text-white`}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SetStages;
