function convertDate(inputDate) {
  if (!inputDate) return "";
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Parse the input date
  const dateParts = inputDate.split(" ")[0].split("-");
  const dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

  // Format the date
  const day = dateObj.getDate();
  const month = months[dateObj.getMonth()];
  const year = dateObj.getFullYear();

  return `${day} ${month} ${year}`;
}

export default convertDate;
