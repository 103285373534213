import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const getSettleVendorTxn = createAsyncThunk(
  "getSettleVendorTxn",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "Y",
        page_no: values?.page_no || 0,
        page_size: values?.page_size || 50,
        search_string: values?.search_string
      }),
    };

    if (values?.clearState === true) {
      console.log("DEBUG SETTLE clearState", values?.clearState);
      return null;
    }
    console.log("DEBUG SETTLE requestOptions", requestOptions);
    return await fetch(
      `${legacyUrl}/transactions/vendor/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getSettleFFTxn = createAsyncThunk(
  "getSettleFFTxn",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "Y",
        page_no: values?.page_no || 0,
        page_size: values?.page_size || 50,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const settleFFTxns = createAsyncThunk(
  "settleFFTxns",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: values?.txn_id,
        settlement_type: values?.settlement_type,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/settle`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getFFTxnPayMethods = createAsyncThunk(
  "getFFTxnPayMethods",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: "Y",
        page_no: 0,
        page_size: 50,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/get_payment_methods`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getSettleType = createAsyncThunk("getSettleType", async () => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({}),
  };

  return await fetch(
    `${legacyUrl}/settlement_type`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
});

export const instaPaySettle = createAsyncThunk("instaPaySettle", async (values) => {
  const { txn_id } = values;
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      txn_id: txn_id,
    }),
  };

  return await fetch(
    `${legacyUrl}/transactions/instant_pay/settle`,
    requestOptions
  )
    .then((response) => {
      console.log("response", response);
      return response.json();
    })
    .catch((error) => {
      return console.log("error", error);
    });
});

export const wireSettle = createAsyncThunk("wireSettle", async (values) => {
  const { account_pin, source_owner_id, txn_id } = values;
  console.log("values", values)
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      account_pin: account_pin,
      source_owner_id: source_owner_id,
      txn_id: txn_id,
    }),
  };

  return await fetch(
    `${legacyUrl}/transactions/vendor/settle/wire_transfer`,
    requestOptions
  )
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      return console.log("error", error)
    });
});

export const cardSettle = createAsyncThunk("cardSettle", async (values) => {
  const { cvv, settlement_id, settlement_type, txn_id } = values;
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({ cvv, settlement_id, settlement_type, txn_id }),
  };

  return await fetch(
    `${legacyUrl}/transactions/vendor/settle`,
    requestOptions
  )
    .then((response) => {
      return response.json()
    })
    .catch((error) => {
      alert(error)
    });
});

const settleTxn = createSlice({
  name: "settleTxn",
  initialState: {
    vendor: null,
    ff: null,
    reimbursement: null,
    type: null,
    settleInstantPayRes: null,
    settleWireRes: null,
    settleCardRes: null,
    FFSettleRes: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(getSettleVendorTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSettleVendorTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(getSettleVendorTxn.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(getSettleType.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSettleType.fulfilled, (state, action) => {
      state.isLoading = false;
      state.type = action.payload;
    });
    builder.addCase(getSettleType.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(instaPaySettle.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(instaPaySettle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settleInstantPayRes = action.payload;
    }
    );
    builder.addCase(instaPaySettle.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    builder.addCase(wireSettle.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(wireSettle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settleWireRes = action.payload;
    }
    );
    builder.addCase(wireSettle.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    builder.addCase(cardSettle.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(cardSettle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.settleCardRes = action.payload;
    }
    );
    builder.addCase(cardSettle.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    builder.addCase(getSettleFFTxn.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(getSettleFFTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ff = action.payload;
    }
    );
    builder.addCase(getSettleFFTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    builder.addCase(getFFTxnPayMethods.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(getFFTxnPayMethods.fulfilled, (state, action) => {
      state.isLoading = false;
      state.type = action.payload;
    }
    );
    builder.addCase(getFFTxnPayMethods.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    builder.addCase(settleFFTxns.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(settleFFTxns.fulfilled, (state, action) => {
      state.isLoading = false;
      state.FFSettleRes = action.payload;
    }
    );
    builder.addCase(settleFFTxns.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
  },
});

export default settleTxn.reducer;

