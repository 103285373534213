import React from "react";
import { Box } from "@mui/material";
import formatCurrency from "../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import "./index.css";
import arrowIcon from "../../assets/TransactionDashboardIcons/Internal arrow.png";
import accTransform from "../../utils/accTransform";

const RecentInternalTransactions = (props) => {
  const navigate = useNavigate();

  const sourceAccount = accTransform(props.sourceAccount);
  const destinationAccount = accTransform(props.destinationAccount);
  const amountProp = formatCurrency(props.amount);
  const propStatus = props.status;
  return (
    <div
      className="w-full"
      onClick={() => {
        navigate(`/internal/txn/${props.txn_id}`);
      }}
      style={{ textDecoration: "none" }}
    >
      <div
        className="
        bg-custom-card rounded-customXl flex flex-col justify-center
        min-w-full
        py-4 4xl:py-6 5xl:py-8 6xl:py-10
        overflow-hidden
        hover:bg-custom-cardHover
        cursor-pointer drop-shadow-xl antialiased
        group"
      >
        <div className="flex flex-row w-full gap-1">
          <div
            className="w-full flex flex-row justify-between
            px-4 4xl:px-6 5xl:px-8 6xl:px-10"
          >
            <div
              className="
              w-3/5  flex flex-row justify-between"
            >
              <div
                className="text-base 4xl:text-xl 6xl:text-2xl font-semibold
            text-gray-200"
              >
                {sourceAccount}
              </div>
              <div>
                <img
                  src={arrowIcon}
                  alt="ArrowLogo"
                  className="w-5 h-5 3xl:w-5 3xl:h-5 4xl:h-7 4xl:w-7 5xl:h-9 5xl:w-9"
                  width="20px"
                />
              </div>
              <div
                className="text-base 4xl:text-xl 6xl:text-2xl font-semibold
            text-gray-200"
              >
                {destinationAccount}
              </div>
            </div>
            <div
              className="w-2/5 text-base 4xl:text-xl 5xl:text-2xl 6xl:text-3xl"
              style={{ color: propStatus }}
            >
              <span className="flex justify-end font-semibold truncate ...">
                {`₹ ${amountProp}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentInternalTransactions;

// const accTransform = (acc) => {
//   function isNumeric(value) {
//     return /^-?\d+$/.test(value);
//   }
//   if (isNumeric(acc)) {
//     return `⋅⋅⋅⋅ ${acc.slice(-4)}`;
//   } else {
//     return acc;
//   }
// };
// const sourceAccount = accTransform(props.sourceAccount);
// const destinationAccount = accTransform(props.destinationAccount);
// const amountProp = formatCurrency(props.amount);
// const propStatus = props.status;
// return (
//   <>
//     <Box className="IrecentTransactionsCard">
//       <div className="Iaccounts">
//         <text className="Isource-account-div">{sourceAccount}</p>
//         <img
//           src={arrowIcon}
//           alt="ArrowLogo"
//           width="20px"
//         />
//         <text className="Idest-account-div">{destinationAccount}</p>
//       </div>
//       <div className="Iamount-internal-div" style={{ color: propStatus }}>
//         {amountProp}
//       </div>
//     </Box>
//   </>
// );
