import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import selectAcc from "../../../assets/CreateTransactionIcons/Select account.png";
import dorpdownLogo from "../../../assets/TxnDetails/asset-1.png";

const VendorDetails = (props) => {
  return (
    <div className="h-full bg-custom-card flex flex-col
      p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl
      overflow-hidden gap-4 3xl:gap-6 4xl:gap-8 5xl:gap-10">
      <div className="h-1/3 flex flex-row justify-between">
        <div className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl">
          Agent details
        </div>
        <div className="flex flex-row gap-2">
          <div className="self-center">
            <img src={selectAcc} className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8" />
          </div>
          <div className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
            onClick={() => {
              props.handleOpen();
            }}>
            Select field force agent
          </div>
        </div>
      </div>
      <div className="h-1/3 text-white
          self-center font-semibold
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl">
        Or
      </div>
      <div className="h-1/3 cursor-pointer flex flex-row justify-center gap-2"
        onClick={() => {
          props.handleRegister();
        }}>
        <div className="
          antaliased underline
          text-gray-200
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl">
          Register a new agent
        </div>
        <div className="flex flex-col justify-center">
          <img
            className="w-1 h-1 xl:w-2 xl:h-2 3xl:w-3 3xl:h-3 4xl:w-4 4xl:h-4 5xl:w-5 5xl:h-5"
            src={dorpdownLogo}
            alt="down arrow logo"
          />
        </div>
      </div>
    </div>
  );
};

export default VendorDetails;
