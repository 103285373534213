import React, { useEffect, useState } from "react";
import "./index.css";
import LoaderIcon from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { refreshAllOwnerAccBalance } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";

const RefreshAllLoader = ({
  data,
  setRefreshAll,
  setAccumulatedData,
  mainData,
  setTotal_balance_state,
  total_balance_state,
  setFailedRefreshId,
  setSuccessRefreshId,
}) => {
  const [value, setValue] = useState(0);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  let newData = mainData;

  const handleRefresh = async () => {
    let count = 0;
    let successCountId = [];
    let failedCountId = [];
    let balance = Number(total_balance_state);
    const results = await Promise.all(
      data.map(async (item, i) => {
        try {
          let response = await dispatch(refreshAllOwnerAccBalance(item.id));
          count += 1;
          setProgress((count / data.length) * 100);
          setValue((prevstate) => prevstate + 1);

          if (response?.payload?.status == true) {
            successCountId.push(
              response.payload.owner_accounts[0].owner_account_id
            );

            newData = newData.map((item) => {
              if (
                response.payload.owner_accounts[0].owner_account_id === item.id
              ) {
                balance =
                  balance -
                  Number(item.owner_account_balance) +
                  Number(
                    response.payload.owner_accounts[0].owner_account_balance
                  );

                return {
                  ...item,
                  owner_account_balance_last_fetched_at_in_words:
                    response.payload.owner_accounts[0]
                      .owner_account_balance_last_fetched_at_in_words,
                  owner_account_balance:
                    response.payload.owner_accounts[0].owner_account_balance,
                  owner_account_balance_last_fetched_at:
                    response.payload.owner_accounts[0]
                      .owner_account_balance_last_fetched_at,
                  difference: Math.abs(
                    Number(item.owner_account_balance) -
                      Number(
                        response.payload.owner_accounts[0].owner_account_balance
                      )
                  ),
                  increaseOrDecrease:
                    Number(item.owner_account_balance) >
                    Number(
                      response.payload.owner_accounts[0].owner_account_balance
                    )
                      ? "decreased"
                      : Number(item.owner_account_balance) <
                        Number(
                          response.payload.owner_accounts[0]
                            .owner_account_balance
                        )
                      ? "increased"
                      : "",
                };
              }
              return item;
            });
          } else {
            failedCountId.push(item.id);
          }
        } catch (error) {
          toast.error(error, toast_position);
          throw error;
        }
      })
    );
    setTimeout(() => {
      setAccumulatedData(newData);
      setFailedRefreshId(failedCountId);
      setSuccessRefreshId(successCountId);
      setTotal_balance_state(Number(balance));
      setRefreshAll(false);
      if (failedCountId.length == data.length) {
        toast.error(`Failed to Fetch Balance`, toast_position);
      } else {
        toast.success(
          `${successCountId.length} out of ${count} Updated Successfully`,
          toast_position
        );
      }
    }, 200);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  const Parentdiv = {
    width: "370px",
    height: "18px",
    backgroundColor: "#6B6B6B",
    borderRadius: 40,
    marginLeft: "20px",
    marginBottom: "40px",
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: "#69B76F",
    borderRadius: 40,
    textAlign: "right",
  };

  return (
    <>
      <div className="refreshloader">
        <img src={LoaderIcon} alt="" />
      </div>
      <div className="refreshtext">
        <p>{`Refreshing balances ${value} of ${data.length}`}</p>
      </div>
      <div style={Parentdiv}>
        <div className="widthtrans" style={Childdiv}>
          <p className="progressdiv">
            <p className=" progresstext">{`${progress.toFixed(0)}%`}</p>
          </p>
        </div>
      </div>
    </>
  );
};

export default RefreshAllLoader;
