import { createSlice } from "@reduxjs/toolkit";

const hidebreadCrumbSlice = createSlice({
  name: "hidebreadCrumb",
  initialState: {
    hide: false,
    menu: "Home",
  },
  reducers: {
    setHideBreadCrumb: (state, action) => {
      state.hide = action.payload.hide;
    },
    setCurrentMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
});
export const { setHideBreadCrumb, setCurrentMenu } =
  hidebreadCrumbSlice.actions;

export default hidebreadCrumbSlice.reducer;
