import React from "react";
import { Box } from "@mui/material";
import logo from "../../assets/TransactionDashboardIcons/InstantPay card icon.png";
import formatCurrency from "../../utils/CurrencyFormater";
import "./index.css";
import CardLogo from "../_utils/logo/CardLogo";

const AccountSummaryInstaPay = (props) => {
  const minBalance = formatCurrency(props.minBalance)
  const availbal = formatCurrency(props.availbal);
  const processingAmt = formatCurrency(props.processingAmt);
  return (
    <div className="flex flex-col justify-between
      bg-custom-card  drop-shadow-xl
        rounded-3xl 3xl:rounded-medium 5xl 6xl:rounded-customXl pr-7 gap-7 5xl 6xl:gap-16  5xl 6xl:pb-5">
      <div className="flex flex-row justify-between h-1/2">
        <div className="w-[30%] flex items-center justify-center h-fit rounded-tl-3xl rounded-br-3xl  bg-white  6xl:rounded-tl-5xl 6xl:rounded-br-5xl">
          <img
            src={require("../../assets/TransactionDashboardIcons/ip_cropped.png")}
            alt=""
            className="w-1/2 2xl:p-1 3xl:p-2 4xl:p-3 5xl:p-5 6xl:p-6 overflow-hidden"
          />
        </div>
        <div className="flex flex-col flex-end mt-7 w-3/5 h-4/5">
          <div className="self-end text-green-400 4xl 3xl: text-2xl 5xl 6xl:text-4xl whitespace-nowrap truncate ...">
            {`₹ ${availbal}`}
          </div>
          <div className="text-right text-[#FFFFFF] text-xs 5xl 6xl:text-lg whitespace-nowrap truncate ...">
            Available balance
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between h-1/2">
        <div className="flex-col items-end w-[40%] pl-5 text-white text-1xl">
          <div className="4xl 3xl: text-xl 5xl 6xl:text-3xl whitespace-nowrap truncate ...">
            {`₹ ${minBalance}`}
          </div>
          <div className="text-xs text-[#DADADA] 5xl 6xl:text-lg whitespace-nowrap truncate ... ">
            Minimum balance
          </div>
        </div>
        <div className="flex flex-col items-end w-[60%] pb-4">
          <div className="text-[#FBC02D] 4xl 3xl: text-xl 5xl 6xl:text-3xl whitespace-nowrap truncate ...">{`₹ ${processingAmt}`}</div>
          <div className="text-right text-xs text-[#DADADA] 5xl 6xl:text-lg whitespace-nowrap truncate ...">
            <p>Processing amount</p> <br />
            <p>*Gateway charges may apply</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSummaryInstaPay;
