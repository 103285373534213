import React from 'react'
import EditFeaturesLogo from '../../../../../../assets/Settings/Entity Details/Edit features.png'
import EditRoles from '../../../../../../assets/Settings/Entity Details/Edit roles.png'
import EditServices from '../../../../../../assets/Settings/Entity Details/Edit services.png'
import EditUsers from '../../../../../../assets/Settings/Entity Details/Edit users.png'
import EditGrades from '../../../../../../assets/Settings/Entity Details/Grades.png'


function EditFeatures() {
  return (
    <div className='w-[80%] rounded-4xl grid grid-cols-1 divide-y divide-[#1D3A6D] p-3 bg-[#3A5686]'>
      <div className='flex py-3 divide-x divide-[#1D3A6D]'>
        <button className='flex flex-col items-center gap-2 w-1/3'>
          <img src={EditRoles} className='w-10 h-10' />
          <div className='text-white text-xs'>Roles</div>
        </button>
        <button className='flex flex-col items-center gap-2 w-1/3'>
          <img src={EditFeaturesLogo} className='w-10 h-10' />
          <div className='text-white text-xs'>Features</div>
        </button>
        <button className='flex flex-col items-center gap-2 w-1/3'>
          <img src={EditUsers} className='w-10 h-10' />
          <div className='text-white text-xs'>Users</div>
        </button>
      </div>
      <div className='flex py-3 divide-x divide-[#1D3A6D]'>
        <button className='flex flex-col items-center gap-2 w-1/3'>
          <img src={EditServices} className='w-10 h-10' />
          <div className='text-white text-xs'>Services</div>
        </button>
        <button className='flex flex-col items-center gap-2 w-1/3'>
          <img src={EditGrades} className='w-10 h-10' />
          <div className='text-white text-xs'>Grades</div>
        </button>
        <button className='flex flex-col items-center gap-2 w-1/3'>
          <div className='w-10 h-10'></div>
        </button>
      </div>
    </div>
  )
}

export default EditFeatures
