import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
import formatCurrency from "../../../../utils/CurrencyFormater";
import formatDate from "../../../../utils/formatdate";
import trimSentence from "../../../../utils/trimSentence";
import { useDispatch, useSelector } from "react-redux";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";

const ApprovalStatusCard = (props) => {
  const id = props.data?.txn_id;
  const txnDetail = props?.txnDetail;
  const dispatch = useDispatch();
  const services = useSelector((state) => state.refreshToken.services);
  const statusImage = () => {
    if (txnDetail?.transactions[0].approval_status === "P") {
      return "Pending yellow";
    } else if (
      txnDetail?.transactions[0].approval_status === "Y" ||
      txnDetail?.transactions[0].approval_status === "Processing"
    ) {
      return "Approved green";
    } else if (txnDetail?.transactions[0].approval_status === "N") {
      return "Declined red";
    } else {
      return "unknown";
    }
  };

  return (
    <div className="w-full  overflow-hidden smallHeight:h-[90vh] h-[75vh] xl:text-sm 2xl:text-lg flex rounded-4xl shadow-customCard bg-[#3e5555]">
      <div className="w-1/2 text-center rounded-4xl text-white">
        {txnDetail?.transactions[0].approval_status === "P" && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white text-xl 4xl:text-4xl leading-loose tracking-normal">
            This transaction has been approved, but it requires another users
            approval before settlement <br />
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal text-[#ff7f33]"
              to={`/ff/txn/${id}`}
            >
              View transaction {">"}{" "}
            </Link>
          </div>
        )}
        {(txnDetail?.transactions[0].approval_status === "Y" ||
          txnDetail?.transactions[0].approval_status === "Processing") && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white text-xl 4xl:text-4xl leading-loose tracking-normal">
            {txnDetail?.transactions[0].approval_status === "Y" &&
              "This transaction has been approved"}
            {txnDetail?.transactions[0].approval_status === "Processing" &&
              "This transaction has been approved, and settled using InstantPay"}
            <br />
            {txnDetail?.transactions[0].approval_status === "Y" &&
              services.includes("Settle_txn") && (
                <Link
                  className="text-center underline  text-xl 4xl:text-4xl leading-loose tracking-normal text-[#ff7f33]"
                  onClick={() => {
                    dispatch(setHideBreadCrumb({ hide: true }));
                    props.setSelectedTxnCarousel({
                      amt: txnDetail?.transactions[0].amount,
                      selected: [txnDetail?.transactions[0].txn_id],
                    });
                    props.setShowSettlementComponent(true);
                  }}
                >
                  Settle now {">"}{" "}
                </Link>
              )}
            {txnDetail?.transactions[0].approval_status === "Processing" && (
              <Link
                className="text-center underline font-semibold text-2xl 4xl:text-4xl  leading-loose tracking-normal text-[#ff7f33]"
                to={`/ff/txn/${id}`}
              >
                View transaction {">"}
              </Link>
            )}{" "}
          </div>
        )}
        {txnDetail?.transactions[0].approval_status === "N" && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white text-xl 4xl:text-4xl leading-loose tracking-normal">
            This transaction has been declined. <br />
            <div
              style={{
                margin: "2% 0",
                height: "3px",
                width: "100%",
                background:
                  "repeating-linear-gradient( to left, transparent, transparent 15px, #657777 15px,  #657777 30px)",
              }}
            ></div>
            <div className="self-start">
              <div
                className="text-left"
                style={{
                  color: "#FAFAFA",
                  font: "normal normal 600 18px/24px Open Sans",
                }}
              >
                {txnDetail?.transactions[0].reason}
              </div>
              <div
                className="text-left"
                style={{
                  color: "#C9C9C9",
                  font: "normal normal normal 14px/19px Open Sans",
                }}
              >
                Denied reason
              </div>
            </div>
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal text-[#ff7f33]"
              to={`/ff/txn/${id}`}
            >
              View transaction {">"}
            </Link>
          </div>
        )}
        {txnDetail?.transactions[0].approval_status === undefined && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal">
            Approval status of this transaction cannot be discerned, check the
            transaction detail page after sometime
            <Link
              className="text-center underline font-semibold text-2xl 4xl:text-4xl  leading-loose tracking-normal text-[#ff7f33]"
              to={`/ff/txn/${id}`}
            >
              View transaction {">"}
            </Link>
          </div>
        )}
      </div>
      <div className="w-1/2 xl:text-sm 2xl:text-lg 4xl:text-3xl  text-center shadow-customCard rounded-4xl bg-[#4A6565]">
        <h1 className="xl:text-xl 2xl:text-3xl 4xl:text-7xl capitalize text-center text-white  w-full my-6">
          {trimSentence(txnDetail?.transactions[0].account_holder_name, 20)}
        </h1>
        <div className="bg-[#def5ed] text-center xl:py-1.5 2xl:py-3 4xl:py-6">
          <div className="xl:text-3xl 2xl:text-4xl 4xl:text-8xl  font-semibold xl:p-2 2xl:p-4 4xl:p-8  text-[#69B76F]">
            {" "}
            ₹ {formatCurrency(txnDetail?.transactions[0].amount)}
          </div>
          <div className="text-[#707070]">
            {formatDate(txnDetail?.transactions[0].initiate_date)}
          </div>
        </div>
        <div className="flex flex-col gap-9 p-8 w-full justify-center items-center">
          <img
            margin="auto"
            width="50%"
            src={require(`../../../../assets/Approve transactions/${statusImage()}.png`)}
            alt=""
            srcSet=""
          />
          <div className="xl:text-3xl text-4xl 4xl:text-7xl text-white ">
            {VendorTxnTextMapper(txnDetail?.transactions[0].approval_status)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalStatusCard;
