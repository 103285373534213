import convertTo2DArray from "../../../../../../utils/convertTo2DArray"

const menuOptions = [
  "Details",
  "Payable CC/DC",
  "Receivable CC/DC",
  "Net banking",
  "UPI",
  "Wallet",
]

function EntityDetailsMenuButtons({ setSelectedOption, selectedOption }) {
  return (
    <div className="flex flex-row divide divide-x divide-[#C9C9C9]">
      {convertTo2DArray(menuOptions, 3).map((menuRow, indexMenu) => {
        return (
          <>
            <div className='flex flex-row gap-2 px-2'>
              {menuRow.map((menuOption) => {
                return (
                  <div
                    onClick={() => setSelectedOption(menuOption)}
                    className={`${menuOption.includes(selectedOption) ? "bg-white text-black" : "bg-[#373F4B] text-white"} rounded-3xl shadow-none px-3 py-1 flex justify-center items-center`}>
                    {menuOption}
                  </div>
                )
              })}
            </div>
          </>
        )
      })}
    </div>
  )
}
export default EntityDetailsMenuButtons
