import React, { useState, useEffect, useRef } from "react";
import "./index.css";

const TxnSearchBar = (props) => {
  const {
    showSearchBar,
    handleSearch,
    handlePaste,
    setTxnAmount,
    txnAmount,
    setShowSearchBar,
    searchBarRef,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [localAmount, setLocalAmount] = useState(txnAmount);

  const inputRef = useRef(null);

  const handleChange = (e) => {
    const newAmount = e.target.value;
    if (/^\d*\.?\d*$/.test(newAmount)) {
      setLocalAmount(newAmount);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch(localAmount);
    }
  };

  const handleClear = (e) => {
    e?.stopPropagation();

    setLocalAmount("");
    setTxnAmount("");
  };

  useEffect(() => {
    if (localAmount === "") {
      handleClear();
    }
  }, [localAmount]);

  const handleButtonClick = () => {
    handleSearch(localAmount);
  };

  useEffect(() => {
    setLocalAmount(txnAmount);
  }, [txnAmount]);

  const handleInputClick = (e) => {
    e.stopPropagation();
  };
  // useEffect(() => {
  //   const handleClickOutside = (e) => {
  //     if (searchBarRef.current && !searchBarRef.current.contains(e.target)) {
  //       setIsOpen(false);
  //       setShowSearchBar(false);
  //     }
  //   };

  //   window.addEventListener("mousedown", handleClickOutside);
  //   return () => window.removeEventListener("mousedown", handleClickOutside);
  // }, [setIsOpen, setShowSearchBar]);

  const handleOpenSearchBar = () => {
    setIsOpen(true);
    setShowSearchBar(true);
  };

  return (
    <div
      className={`bene-txn-searchbar ${isOpen ? "open" : "closed"}`}
      onClick={handleOpenSearchBar}
    >
      {showSearchBar && (
        <div className="bene-txn-viewall-search-bar">
          <div className="search-amount-div">Amount</div>
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative h-[49px]">
            <input
              autoFocus
              ref={inputRef}
              onClick={handleInputClick}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              autoComplete="off"
              id="txn_search"
              name="search"
              className={`block h-8 rounded-[5px] bg-[#244277] text-[#DADADA] placeholder:text-[#DADADA] placeholder:text-[14px]`}
              placeholder="Search"
              type="text"
              value={localAmount}
            />
            <div className="cursor-pointer m-auto absolute inset-y-0 right-0 flex items-center pr-3">
              {localAmount ? (
                <img
                  onClick={handleClear}
                  className="bene_txn_search_close_image"
                  src={require("../../../assets/Approve transactions/Close-icon.png")}
                  alt="search-close"
                />
              ) : (
                <img
                  className="bene_txn_viewall_search_inner_image"
                  src={require("../../../assets/Approve transactions/search-white.png")}
                  alt="search-inner-image"
                />
              )}
            </div>
          </div>
          <div
            className="bene_txn_viewall_search_button"
            onClick={handleButtonClick}
            style={{
              cursor: localAmount ? "pointer" : "default",
              backgroundColor: localAmount ? "#F9F9F9" : "#707070",
            }}
          >
            <p
              className="text-sm"
              style={{ color: localAmount ? "#707070" : "#F9F9F9" }}
            >
              Search
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TxnSearchBar;
