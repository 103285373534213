const SimpleLogo = (props) => {
  return (
    <div className="flex py-2 5xl:py-4 justify-center group-hover:bg-blue-100 text-center bg-white rounded-tl-4xl 5xl:rounded-tl-large">
      <img
        className="w-[80%]"
        src={require("../../../assets/Banks/" + props.bank + ".png")}
        alt="BankLogo"
      />
    </div>
  );
};
export default SimpleLogo;
