import { Box, Typography } from "@mui/material";
import Datacard from "../../../../components/Datacard";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { useNavigate } from "react-router-dom";
import DashboardAccountList from "../../../../components/Accounts/Common/DashboardAccountList";
import DashboardCreateDiv from "../../../../components/Accounts/Common/DashboardCreateDiv";
import accTransform from "../../../../utils/accTransform";

const AccountCardDataCardComponent = ({ accountType, dashboardData, viewAllData }) => {
  const validDataCards = () => {
    if (Object.keys(dashboardData).length === 0) {
      return {};
    }
    const {
      active_card_result,
      blocked_card_result,
      inactive_card_result
    } = dashboardData;
    return ({
      active_card_result,
      blocked_card_result,
      inactive_card_result
    });
  }

  const cardTextColor = (key) => {
    if (key === "active_card_result") {
      return "#4ddd37";
    } else if (key === "blocked_card_result") {
      return "#FF5860";
    } else if (key === "inactive_card_result") {
      return "#B4B4B4";
    }
  }

  const cardTextData = (key) => {
    if (key === "active_card_result") {
      return "Active cards";
    } else if (key === "blocked_card_result") {
      return "Blocked cards";
    } else if (key === "inactive_card_result") {
      return "Inactive cards";
    }
  }

  const cardPath = (key) => {
    if (key === "active_card_result") {
      return "/accounts/cards/view/Active";
    } else if (key === "blocked_card_result") {
      return "/accounts/cards/view/Blocked";
    } else if (key === "inactive_card_result") {
      return "/accounts/cards/view/Inactive";
    }
  }

  const processDataCardDetails = () => {
    let dataCards = validDataCards();
    return (
      Object.entries(dataCards).map(([key, value]) => {
        return ({
          key: key,
          count: value.records,
          color: cardTextColor(key),
          text: cardTextData(key),
          path: cardPath(key)
        })
      })
    );
  }

  const dataCardComponents = () => {
    return (
      Object.entries(processDataCardDetails()).map(([key, data]) => {
        return (
          <Datacard
            key={data.key}
            number={data.count}
            color={data.color}
            text={data.text}
            path={data.path}
          />
        );
      })
    );
  }

  return dataCardComponents();
}

const AccountCardListComponent = ({ accountType, dashboardData, viewAllData }) => {
  const navigate = useNavigate();

  const emptyAccountListComponent = () => {
    return (
      <>
        <Typography sx={{ color: "white" }}>
          No cards found <br />
          All cards will appear here.
        </Typography>
        <Box
          sx={{
            width: "50%",
            margin: "auto",
            marginTop: "5%",
          }}
        >
          <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
        </Box>
      </>
    );
  }

  const listTextColor = (key) => {
    if (key === "Active") {
      return "#4DDD37";
    } else if (key === "Blocked") {
      return "#FF5860";
    } else if (key === "Inactive") {
      return "#B4B4B4";
    }
  }

  const cardTypePngPath = (key) => {
    if (key === "Mastercard")
      return "MC"
    else if (key === "Amex")
      return 'AmEx';
    else if (key === "Amex")
      return 'Diners';
    else if (key === "Diners")
      return 'AmEx';
    else if (key === "Rupay")
      return 'RuPay';
    else if (key === "Jcb")
      return 'JCB';
    return key;
  }

  const modifiedAccountListData = () => {
    return Object.entries(viewAllData).map(([key, value]) => {
      return ({
        ending_with: `${accTransform(value.ending_with, true)}`,
        card_type: cardTypePngPath(value.card_type),
        text_color: listTextColor(value.status),
      });
    });
  }

  const accountListComponents = () => {
    if (Object.keys(viewAllData).length === 0) {
      return emptyAccountListComponent();
    } else {
      return (
        <>
          <div className="px-4 flex flex-row justify-between">
            <span className="text-white
                    text-xl
                    3xl:text-2xl
                    4xl:text-3xl
                    5xl:text-4xl">
              Recent
            </span>
            <span className="cursor-pointer
                    text-xl
                    3xl:text-2xl
                    4xl:text-3xl
                    5xl:text-4xl">
              <p onClick={() => navigate(`/accounts/${accountType}/view`)} className="underline text-custom-orange">View All</p >
            </span >
          </div >

          <div className="pb-6 3xl:pb-8 5xl:pb-10
                  mt-5 w-full
                  gap-y-4 4xl:gap-y-8
                  grid grid-cols-1
                  md:grid-cols-2
                  3xl:grid-cols-3
                  md:gap-x-8
                  lg:gap-x-10
                  justify-items-center">
            {
              Object.entries(modifiedAccountListData()).map(([key, value]) => {
                return (
                  <DashboardAccountList
                    key={key}
                    name={value.ending_with}
                    scheme_logo={value.card_type}
                    textColor={value.text_color}
                    navigate_url={`/accounts/${accountType}/view_all`}
                  />
                );
              })
            }
          </div>
        </>
      );
    }
  }

  return accountListComponents();
}

const AccountCardRightSidebarComponent = ({ accountType, dashboardData, viewAllData }) => {

  const rightSidebarComponents = () => {
    return (
      <>
        <div>
          {
            <DashboardCreateDiv
              heading={"Start adding your cards"}
              first_button_text={"Create"}
              second_button_text={"View all"}
              first_button_url={"/accounts/card/create"}
              second_button_url={"/accounts/cards/view/"}
            />
          }
        </div>
      </>
    );
  }

  return rightSidebarComponents();
}

export { AccountCardDataCardComponent, AccountCardListComponent, AccountCardRightSidebarComponent };

