import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    status: false,
    userInfo: false,
}

export const getUserDetailsState = createSlice({
    name: 'getUserDetailsState',
    initialState,
    reducers: {
        getUserDetailsStateAction: (state, action) => {
            state.status = true;
            state.userInfo = action.payload;
        }
    }
})

export const { getUserDetailsStateAction } = getUserDetailsState.actions;

export default getUserDetailsState.reducer;