import React from "react";
import PageNot from "../../../assets/loginImage/pagenotfound.png";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const checkToken = window.sessionStorage.getItem("q2p_token");

  const goToHomePage = () => {
    if (checkToken) {
      navigate("/home");
    } else {
      navigate("/");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen overflow-hidden mt-5">
      <img
        src={PageNot}
        alt="Centered Image"
        className="h-96 max-w-full max-h-full"
      />
      <div className="text-center">
        <h4 className="text-4xl text-[#F9F9F9] mb-4">Page not found</h4>
        <p className="text-lg text-[#F9F9F9] ">
          Sorry, the page you are looking for does not exist.
        </p>
        <div className="pt-5 mt-7">
          <button
            className="bg-white text-black p-4 rounded-4xl font-semibold hover:bg-[#DBDBDB]"
            onClick={goToHomePage}
          >
            GO TO {checkToken ? "HOME DASHBOARD" : "HOME"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
