import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

function EditFeeModal({ title, fee, editModalState, setFee }) {
  const [input, setInput] = useState(fee[title]);

  const handlechange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = () => {
    if (fee[title] === "") {
      toast.error("Please enter a valid fee");
    } else {
      editModalState(false);
      setFee({ ...fee, [title]: input });
    }
  };
  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div className="flex 4xl:text-3xl  justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  fixed z-[9999]">
        <div
          className="w-2/5 h-fit flex flex-col gap-8 bg-custom-deniedReasonPurple rounded-4xl
          px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-10"
        >
          <div className="flex flex-col gap-4">
            <div
              className="text-white font-semibold
            text-2xl 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl"
            >
              Edit {title.toLowerCase()} fee
            </div>
          </div>
          <div className="flex flex-col justify-center">
            <TextField
              autoFocus
              autoComplete="off"
              onChange={handlechange}
              inputProps={{
                style: {
                  color: "white",
                  borderBottom: "1px solid grey",
                },
                type: "number",
              }}
              InputLabelProps={{
                style: {
                  color: "#DADADA",
                  font: "normal normal normal 14px/19px Open Sans",
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              label={`${title} fee`}
              variant="standard"
              value={input}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5%",
              width: "100%",
            }}
          >
            <Button
              onClick={() => editModalState(false)}
              sx={{
                borderRadius: "20px",
                borderColor: "#fff",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
              className="4xl:text-3xl"
            >
              Cancel
            </Button>
            <Button
              className="4xl:text-3xl"
              variant="outlined"
              onClick={() => handleSubmit()}
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditFeeModal;
