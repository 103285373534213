import convertTo2DArray from "../../../../utils/convertTo2DArray"

function DetailsMenuButton({ menuOptions, setSelectedOption, selectedOption }) {
    return (
        <div className="flex justify-between">
            {convertTo2DArray(menuOptions, 2).map((menuRow, indexMenu) => {
                return (
                    <>
                        <div className='flex gap-2'>
                            {menuRow.map((menuOption) => {
                                return (
                                    <button
                                        onClick={() => setSelectedOption(menuOption)}
                                        className={`${menuOption.includes(selectedOption) ? "bg-white text-black" : "bg-[#373F4B] text-white"} rounded-3xl px-6 py-1 flex justify-center items-center`}>
                                        {menuOption}
                                    </button>
                                )
                            })}
                        </div>
                        {(indexMenu === 0 || indexMenu === 1) && <div className="h-[4vh] border-[#C9C9C9] border-l"></div>}
                    </>
                )
            })}
        </div>
    )
}

export default DetailsMenuButton