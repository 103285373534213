import mobile from "../../../assets/DisplayEnvironment/Platforms/mobile.svg";
import web from "../../../assets/DisplayEnvironment/Platforms/web.svg";
import default_platform from "../../../assets/DisplayEnvironment/Platforms/default platform.svg";

import chrome from "../../../assets/DisplayEnvironment/Browser/chrome.svg";
import edge from "../../../assets/DisplayEnvironment/Browser/microsoft_edge.svg";
import explorer from "../../../assets/DisplayEnvironment/Browser/explorer.svg";
import safari from "../../../assets/DisplayEnvironment/Browser/safari.svg";
import firefox from "../../../assets/DisplayEnvironment/Browser/mozilla_firefox.svg";
import default_browser from "../../../assets/DisplayEnvironment/Browser/default browser.svg";

import windows from "../../../assets/DisplayEnvironment/OS_version/Windows.svg";
import macOS from "../../../assets/DisplayEnvironment/OS_version/mac_os.svg";
import android from "../../../assets/DisplayEnvironment/OS_version/android.svg";
import linux from "../../../assets/DisplayEnvironment/OS_version/Linux.svg";
import ios from "../../../assets/DisplayEnvironment/OS_version/ios.svg";
import default_os from "../../../assets/DisplayEnvironment/OS_version/unknown_os.svg";

const displayPlatform = (platform) => {
  try {
    if (platform === "web") return web;
    else return mobile;
  } catch (error) {
    return default_platform;
  }
};

const displayBrowser = (browser) => {
  try {
    if (browser === "Google Chrome") return chrome;
    else if (browser === "Apple Safari") return safari;
    else if (browser === "Mozilla Firefox") return firefox;
    else if (browser === "Microsoft Edge") return edge;
    else if (browser === "Internet Explorer") return explorer;
    else return default_browser;
  } catch (error) {
    return default_browser;
  }
};

const displayOS = (OS_version) => {
  const os = OS_version.split(" ")?.[0];
  try {
    if (os === "macOS" || os === "Mac") return macOS;
    else if (os === "Android") return android;
    else if (os === "Linux") return linux;
    else if (os === "Windows") return windows;
    else if (os === "iOS") return ios;
    else return default_os;
  } catch (error) {
    return default_os;
  }
};

export { displayPlatform, displayBrowser, displayOS };
