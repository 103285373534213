function AddRuleButton(props) {
  return (
    <button
      onClick={() => props.showPopup(true)}
      className="w-11/12 m-4 flex justify-center items-center shadow-xl bg-[#314D7E] cursor-pointer min-h-[25vh] rounded-4xl"
    >
      <div className="flex justify-center items-center gap-2">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#314D7E"
            className="w-10 h-10 3xl:w-14 3xl:h-14 4xl:w-24 4xl:h-24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div className="text-white font-semibold text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl ">
          Add custom rule
        </div>
      </div>
    </button>
  );
}
export default AddRuleButton;
