import React from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import {
  accountApprovalStatusColor,
  accountApprovalStatusColorRGBA,
} from "../../../../services/approvalStatus";
import CardLogo from "../../../_utils/logo/CardLogo";
import { VendorAccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";

const AccountClientViewAllCard = (data) => {
  const name = data.name;
  const mobile = data.mobile;
  const bankLogo = bankLogoMapper(data.bank_name);
  const approvalColor = accountApprovalStatusColorRGBA(data.approval_status);
  const color = accountApprovalStatusColor(data.approval_status);
  const approvalStatusText = VendorAccountStatusTextMapper(
    data.approval_status
  );

  const mobileViewAllCard = () => {
    return (
      <>
        <div className="flex flex-row justify-between -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
          <div
            className="shrink-0 h-full w-3/5 truncate ... font-semibold
            text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased capitalize"
            style={{ color: color }}
          >
            +91 {mobile}
          </div>
          <div className="min-h-full text-sm flex flex-row gap-1 justify-end">
            <div className="flex flex-row justify-start">
              <div
                className="h-full w-1.5 4xl:w-2"
                style={{ backgroundColor: color }}
              ></div>
              <div
                className="flex group-hover:px-4"
                style={{ backgroundColor: approvalColor }}
              >
                <span
                  className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                  style={{ color: color }}
                >
                  {approvalStatusText}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div
            className="invisible w-3/5
            flex flex-col justify-between font-medium text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased"
          >
            <span className="text-[#FAFAFA] truncate ... uppercase">
              +91 {mobile}
            </span>
            <span className="text-gray-300 text-xs font-semibold">
              Contact Number
            </span>
          </div>
          <div className="self-end -mr-4 -mb-4 4xl:-mr-6 4xl:-mb-6 5xl:-mr-8 5xl:-mb-8 6xl:-mr-10 6xl:-mb-10 px-3 py-1.5 h-12 flex flex-row justify-center bg-[#506994] rounded-full rounded-tl-3xl">
            <span className="bg-white text-[#506994] self-center shrink-0 p-1 rounded-4xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>
      </>
    );
  };

  const nameViewAllCard = () => {
    return (
      <>
        <div className="flex flex-row justify-between -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
          <div
            className="shrink-0 h-full w-3/5 truncate ... font-semibold
            text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased capitalize"
            style={{ color: color }}
          >
            {name}
          </div>
          <div className="min-h-full text-sm flex flex-row gap-1 justify-end">
            <div className="flex flex-row justify-start">
              <div
                className="h-full w-1.5 4xl:w-2"
                style={{ backgroundColor: color }}
              ></div>
              <div
                className="flex group-hover:px-4"
                style={{ backgroundColor: approvalColor }}
              >
                <span
                  className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                  style={{ color: color }}
                >
                  {approvalStatusText}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div
            className="w-3/5
            flex flex-col justify-between font-medium text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased"
          >
            <span className="text-[#FAFAFA] truncate ... uppercase">
              +91 {mobile}
            </span>
            <span className="text-gray-300 text-xs font-semibold">
              Contact Number
            </span>
          </div>
          <div className="self-end -mr-4 -mb-4 4xl:-mr-6 4xl:-mb-6 5xl:-mr-8 5xl:-mb-8 6xl:-mr-10 6xl:-mb-10 px-3 py-1.5 h-12 flex flex-row justify-center bg-[#506994] rounded-full rounded-tl-3xl">
            <span className="bg-white text-[#506994] font-extrabold self-center shrink-0 px-3  py-1 rounded-4xl">
              {name?.charAt(0).toUpperCase()}
            </span>
          </div>
        </div>
      </>
    );
  };

  const viewAllCard = () => {
    if (name) {
      return nameViewAllCard();
    } else {
      return mobileViewAllCard();
    }
  };

  return (
    <>
      <div
        className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-xl
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden"
      >
        {viewAllCard()}
      </div>
    </>
  );
};

export default AccountClientViewAllCard;
