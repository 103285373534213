function parseCustomDate(dateString) {
  if (dateString === undefined || dateString === null || dateString === "") {
    return "undefined";
  } else {
    const parts = dateString.split("-");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  }
}
const formatDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDay();
  const dayNumber = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const diffTime = Math.abs(dateObj - new Date());
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
  if (diffDays === 0) {
    return {
      dayNumber,
      month: months[month],
      year,
      day: weekdays[day],
      diff: "Today",
    };
  }
  if (diffDays === 1) {
    return {
      dayNumber,
      month: months[month],
      year,
      day: weekdays[day],
      diff: "Tomorrow",
    };
  }
  if (diffDays < 30 && diffDays > 1) {
    return {
      dayNumber,
      month: months[month],
      year,
      day: weekdays[day],
      diff: "In " + String(diffDays) + " days",
    };
  }
  const diffMonths = Math.round(diffTime / (1000 * 60 * 60 * 24 * 30));
  if (diffMonths < 12) {
    return {
      dayNumber,
      month: months[month],
      year,
      day: weekdays[day],
      diff: "In " + String(diffMonths) + " months",
    };
  }

  return "Invalid Date";
};

export default formatDate;
