import { useEffect, useState } from "react";
import convertTo2DArray from "../../../../../../utils/convertTo2DArray";
import EditCCDCValuesModal from "../EditCCDCValuesModal";

function EditCCDCValues({ title, data }) {
  const [selectedOption, setSelectedOption] = useState(
    data?.map((item) => item.title)[0]
  );
  const [selectedOptionData, setSelectedOptionData] = useState(
    data?.find((item) => item.title === selectedOption)
  );
  const [editModal, editModalState] = useState(false);

  useEffect(() => {
    setSelectedOptionData(data?.find((item) => item.title === selectedOption));
  }, [selectedOption]);

  return (
    <div className="bg-[#506994] w-full rounded-4xl shadow-lg flex flex-col p-5">
      {editModal && (
        <EditCCDCValuesModal
          title={title}
          editModalState={editModalState}
          selectedOption={selectedOption}
          selectedOptionData={selectedOptionData}
        />
      )}
      <div className="flex justify-between items-center w-full">
        <div>
          <div className="text-[#C9C9C9] text-lg font-semibold">
            {title} gateway
          </div>
        </div>
        <div
          className="w-5 h-5 cursor-pointer"
          onClick={() => editModalState(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        </div>
      </div>
      <div>
        <div className="w-full flex py-5">
          {data?.map((item, index) => {
            return (
              <button
                onClick={() => setSelectedOption(item.title)}
                className={`${
                  selectedOption === item.title
                    ? "border-[#F05A6A] text-[#F05A6A] border-b-4"
                    : "border-white text-white border-b"
                } py-3 px-5`}
                key={index}
              >
                {item.title}
              </button>
            );
          })}
          <div className="border-white border-b w-full px-5"></div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col divide-y divide-gray-500">
          {convertTo2DArray(
            Object.keys(selectedOptionData).filter(
              (value) => value !== "title"
            ),
            2
          ).map((options) => (
            <div className="flex w-full divide-x divide-gray-500">
              {options?.map((option) => (
                <div className="flex flex-col w-1/2 justify-center items-start p-5 divide-gray-500">
                  <div className="text-lg text-white">
                    {selectedOptionData[option]}
                  </div>
                  <div className="text-sm text-white">{option}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EditCCDCValues;
