import React from "react";
import { useSelector } from "react-redux";
import HolidayDateCardnew from "../HolidayDateCardnew";
import convertTo2DArray from "../../../utils/convertTo2DArray";
import "../../ViewAllTransactions/FilterDrawer/index.css";

function HolidaysSidebarnew({ state, setState }) {
  const holiday_list = useSelector((state) => state.date?.holiday_list);

  const holidays = holiday_list?.holidays
    ?.map((a) => a)
    .sort((a, b) => a.id - b.id);

  const holiday_all_list = convertTo2DArray(holidays, 3);
  const working_hour = useSelector(
    (state) => state.date?.holiday_list?.working_hour
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const groupByMonth = (holidays) => {
    return holidays.reduce((acc, holiday) => {
      const date = new Date(holiday.date);
      const month = date.toLocaleString("default", { month: "long" });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(holiday);
      return acc;
    }, {});
  };

  const holidaysByMonth = groupByMonth(holidays);

  const list = () => (
    <div className="Caraouseldatemain" style={{ padding: "0px" }}>
      {Object.keys(holidaysByMonth).map((month) => (
        <div key={month}>
          <div className="datehader">{month}</div>
          {holidaysByMonth[month].map((holiday, index) => (
            <React.Fragment key={holiday.id}>
              <HolidayDateCardnew
                date={holiday.date}
                occasion={holiday.occasion}
              />
              {index < holidaysByMonth[month].length - 1 && (
                <hr className="new"></hr>
              )}
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );

  return <div>{list()}</div>;
}

export default HolidaysSidebarnew;
