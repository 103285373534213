import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const AccountDetailCard = (props) => {
  const heading = props.heading;
  const data = props.data;
  const bgColor = props.bg_color;
  const [check, setCheck] = useState(false);
  const buttonRef = useRef(null);
  const toggleRef = useRef(null);

  const enableVendorPayment = (element) => {
    setCheck(!check);
  };

  useEffect(() => {
    if (!buttonRef.current) return;
    if (check) {
      buttonRef.current.setAttribute("aria-checked", "true");
      buttonRef.current.classList.add("bg-[#80DD15]");
      toggleRef.current.classList.remove("translate-x-0");
      toggleRef.current.classList.add("translate-x-6");
      buttonRef.current.classList.remove("bg-gray-200");
    } else {
      buttonRef.current.setAttribute("aria-checked", "false");
      buttonRef.current.classList.remove("bg-[#80DD15]");
      toggleRef.current.classList.remove("translate-x-6");
      toggleRef.current.classList.add("translate-x-0");
      buttonRef.current.classList.add("bg-gray-200");
    }
  }, [check]);

  const bodyCard = (key) => {
    if (heading === "Permissions" && key === "Vendor Payments") {
      return (
        <div className="py-2 w-full flex flex-row justify-between">
          <div className="self-center text-base text-white antialiased truncate ...">
            {key}
          </div>
          <div className="self-center text-gray-300 text-xs antialiased">
            <button
              type="button"
              className="self-center bg-gray-200 relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-4xl border-2 border-transparent transition-colors duration-200 ease-in-out"
              role="switch"
              aria-checked="false"
              onClick={() => {
                enableVendorPayment();
              }}
              ref={buttonRef}
            >
              <span className="sr-only">enable vendor payment</span>
              <span
                ref={toggleRef}
                aria-hidden="true"
                className="self-center translate-x-0 pointer-events-none inline-block h-4 w-4 transform rounded-4xl bg-white shadow ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="w-full flex flex-row justify-between">
        <div className="py-2 w-full flex flex-col">
          <div className="self-start text-base text-white antialiased truncate ...">
            {data[key]
              ? Array.isArray(data[key])
                ? data[key][0]
                : data[key]
              : "Not given"}
          </div>
          <div className="text-gray-300 text-xs antialiased">{key}</div>
        </div>
        {Array.isArray(data[key]) ? (
          <div className="py-3 self-start w-full flex flex-col">
            <div className="self-end text-xs text-gray-300 antialiased truncate ...">
              {data[key][1]}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  return (
    <div
      className="drop-shadow-2xl flex flex-col
      rounded-4xl
      px-6 py-6"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="flex pb-4">
        <div className="self-center text-gray-300 text-sm font-bold">
          {heading}
        </div>
      </div>
      <div className="flex flex-col divide-y divide-[#1d1d1d55]">
        {Object.keys(data).map((key, index) => {
          return bodyCard(key);
        })}
      </div>
    </div>
  );
};

export default AccountDetailCard;
