import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectAcc from "../../../../assets/CreateTransactionIcons/Select account.png";
import loadingLogo from "../../../../assets/Approve transactions/inputloder.gif";
import verifyLogo from "../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import trimSentence from "../../../../utils/trimSentence";
import Validation from "../../../../utils/validation";
import { toast } from "react-toastify";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import SelectDebitAccount from "./SelectClientAccount";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import { getClientAccountsData } from "../../../../redux/features/accounts/accounts";
import { createClientAccount } from "../../../../redux/features/createClientAcc/createClientAcc";

const ClientDetailsForm = (props) => {
  // validations
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const bankStatusLoading = useSelector(
    (state) => state.verify.verifyBankisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [nameValidation, setNameVaidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const clientAcc = useSelector((state) => state.bufferState.clientAcc);
  const clientPrimaryAcc = useSelector(
    (state) => state.bufferState.clientPrimaryAcc
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const dispatch = useDispatch();

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    }
    return value;
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  const allowedValue = (name, value) => {
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    if (name === "name") return Validation.allowedName(value, name);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewClientData, [name]: value };
      props.setRegisterNewClientData(newObj);
    }
    if (
      props.registerNewClientData.contact_no.length === 0 ||
      props.registerNewClientData.contact_no.length < 10
    ) {
      setContactNumberValidation(false);
    }
    if (props.registerNewClientData.email_id.length === 0) {
      setEmailValidation(false);
    }
  };

  useEffect(() => {
    if (customErrors["contact_no"]) setContactNumberValidation(false);
    if (
      !customErrors["contact_no"] &&
      props.registerNewClientData.contact_no.length === 10
    )
      setContactNumberValidation(true);
    if (customErrors["email_id"]) setEmailValidation(false);
    if (
      !customErrors["email_id"] &&
      props.registerNewClientData.email_id.length > 0 &&
      emailRegex.test(props.registerNewClientData.email_id)
    )
      setEmailValidation(true);
  }, [customErrors]);

  useEffect(() => {
    props.registerNewClientData.contact_no.length === 10 &&
      getClientAccountsData({}, (res) => {
        let accExist = res?.clients?.filter((acc) => {
          return props.registerNewClientData.contact_no === acc.contact_no;
        });
        if (accExist[0]) {
          dispatch(
            setBufferState({
              clientAcc: accExist[0],
              clientPrimaryAcc: clientPrimaryAcc,
            })
          );
        }
      });
  }, [props.registerNewClientData.contact_no]);

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    return (
      <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
        <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
          {name === "contact_no" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 512"
            >
              <g id="_552489" data-name="552489" transform="translate(0 0)">
                <ellipse
                  id="Ellipse_59"
                  data-name="Ellipse 59"
                  cx="256"
                  cy="256"
                  rx="256"
                  ry="256"
                  transform="translate(0 0)"
                  fill="#fff"
                />
                <path
                  id="Path_626"
                  data-name="Path 626"
                  d="M348.054,299.359a23.9,23.9,0,0,0-26.948.071l-11.679,8.016a36.426,36.426,0,0,1-42.812-1.18,399.631,399.631,0,0,1-33.713-29.1,399.181,399.181,0,0,1-29.1-33.713,36.426,36.426,0,0,1-1.18-42.812l8.016-11.679a23.907,23.907,0,0,0,.07-26.948l-33.431-49.267a32.411,32.411,0,0,0-41.793-10.561l-.009,0a62.791,62.791,0,0,0-28.317,81.262,452.176,452.176,0,0,0,76.987,118.682l23.79,23.79A452.23,452.23,0,0,0,326.616,402.91a62.792,62.792,0,0,0,81.262-28.317l0-.009a32.411,32.411,0,0,0-10.561-41.793Z"
                  transform="translate(4.84 3.715)"
                  fill="#9f9f9f"
                />
              </g>
            </svg>
          )}
          {name === "name" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 512"
            >
              <path
                id="Path_2074"
                data-name="Path 2074"
                d="M257,224.429A109.714,109.714,0,1,0,147.286,114.714,109.256,109.256,0,0,0,257,224.429Zm0,73.143c-85.211,0-256,42.789-256,128V517H513V425.572C513,340.36,342.211,297.571,257,297.571Z"
                transform="translate(-1 -5)"
                fill="#fafafa"
              />
            </svg>
          )}
          {name === "email_id" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 392.778"
            >
              <g id="iconfinder_gmail_4975304" transform="translate(-0.001 0)">
                <g
                  id="Group_14"
                  data-name="Group 14"
                  transform="translate(33.38 0)"
                >
                  <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M484.973,122.808,452.288,453.324H59.712L33.379,129.205,256,254.723Z"
                    transform="translate(-33.379 -60.548)"
                    fill="#f2f2f2"
                  />
                  <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M473.886,60.983,256,267.1,38.114,60.983H473.886Z"
                    transform="translate(-33.379 -60.983)"
                    fill="#f2f2f2"
                  />
                </g>
                <path
                  id="Path_210"
                  data-name="Path 210"
                  d="M59.712,155.8V453.4H24.139A24.229,24.229,0,0,1,0,429.092V111.967l39,1.07Z"
                  transform="translate(0.001 -60.625)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_211"
                  data-name="Path 211"
                  d="M512,111.988V429.115a24.234,24.234,0,0,1-24.154,24.309H452.288V155.82l19.692-46.852Z"
                  transform="translate(0 -60.646)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_212"
                  data-name="Path 212"
                  d="M512,85.292v27.034l-59.712,43.832L256,300.232,59.712,156.158,0,112.326V85.292A24.23,24.23,0,0,1,24.139,60.983H38.114L256,220.909,473.886,60.983h13.962A24.232,24.232,0,0,1,512,85.292Z"
                  transform="translate(0.001 -60.983)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_213"
                  data-name="Path 213"
                  d="M59.712,155.8,0,146.476V111.967Z"
                  transform="translate(0.001 -60.625)"
                  fill="#9f9f9f"
                />
              </g>
            </svg>
          )}
        </div>
        {clientAcc ? (
          <div
            onClick={() => props.editable && handleOpen("Client")}
            className="w-2/3 cursor-pointer py-2 text-white text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl"
          >
            <div
              className={`w-full flex flex-col
          antaliased 
          ${
            name === "contact_no"
              ? "text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl font-semibold"
              : "text-lg"
          } ${
                props.editable && name === "contact_no"
                  ? "text-custom-orange"
                  : "text-white"
              }`}
            >
              <div className="w-full flex gap-5 self-start items-center">
                <div className="w-full truncate ... ">
                  {(name === "contact_no" ? "+91 " : "") +
                    (clientAcc[name] ? clientAcc[name] : "Not Given")}
                  {name === "contact_no" && props.editable && (
                    <div className="text-orange inline-flex px-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-10 5xl:h-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
              <div className="text-white text-lg opacity-75">{label}</div>
            </div>
          </div>
        ) : (
          <TextField
            className="w-full antialiased text-4xl"
            inputProps={{
              style: {
                color: "white",
                borderBottom: "1px solid grey",
              },
              maxLength: { maxLength },
              readOnly: props.activeStep === 2,
            }}
            InputLabelProps={{
              style: {
                color: "#C9C9C9",
                fontFamily: "Open Sans",
                fontWeight: 100,
                fontSize: "1.0 rem",
              },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: name === "contact_no" && (
                <InputAdornment position="start">
                  <div className="text-white">+91</div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8">
                    {!skip &&
                      value.length > 0 &&
                      (status ? (
                        <img
                          src={verifyLogo}
                          alt="verified logo"
                          className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                        />
                      ) : (
                        <img
                          src={skippedVerification}
                          alt="verified logo"
                          onClick={() => {
                            showErrors(name);
                          }}
                          className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                        />
                      ))}
                  </div>
                </InputAdornment>
              ),
            }}
            label={label}
            variant="standard"
            name={name}
            onChange={handleChange}
            value={value}
            autoComplete="off"
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="p-2 h-full bg-custom-blue flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-4 2xl:gap-6 4xl:gap-8"
      >
        <div>
          <div
            className="-m-2 bg-custom-card
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18"
          >
            <div
              className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
            >
              Client Details
            </div>
            {props.editable && (
              <div className="flex flex-row gap-2">
                {clientAcc ? (
                  <div
                    className="self-center
          antaliased underline cursor-pointer text-white
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
                    onClick={() => {
                      dispatch(
                        setBufferState({
                          clientAcc: null,
                          clientPrimaryAcc: clientPrimaryAcc,
                        })
                      );
                    }}
                  >
                    Register a new account
                  </div>
                ) : (
                  <>
                    <div className="self-center">
                      <img
                        src={selectAcc}
                        className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                      />
                    </div>{" "}
                    <div
                      className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      Select an existing client
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div
            className="bg-custom-card drop-shadow-xl h-fit
          rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl
          flex flex-col p-4 md:p-6 lg:p-8 xl:p-10 4xl:p-24 pb-8 -mx-2"
          >
            <div className="w-full">
              {getInputElementDiv(
                "contact_no",
                contactNumberValidation,
                "Phone",
                props.registerNewClientData.contact_no,
                10
              )}
            </div>
          </div>
        </div>

        <div className="-mx-2 mt-2">
          <div
            className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-8"
          >
            {getInputElementDiv(
              "name",
              nameValidation,
              "Name (optional)",
              props.registerNewClientData.name,
              20,
              true
            )}
            {getInputElementDiv(
              "email_id",
              emailValidation,
              "Email-ID (optional)",
              props.registerNewClientData.email_id,
              20
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className="">
          <SelectDebitAccount
            componentType={open}
            enableNextButton={props.enableNextButton}
            handleClose={handleClose}
          />
        </div>
      )}
    </>
  );
};

export default ClientDetailsForm;
