const IfscCodeMapper = (ifscCode) => {
  if (!ifscCode || ifscCode.length < 4) {
    return "bank_default.svg"; // Return null or a default image if IFSC code is invalid
  }

  const bankPrefix = ifscCode.substring(0, 4).toLowerCase();

  try {
    const img = require("../../../assets/IfscLogo/" + bankPrefix + ".svg");
    return `${bankPrefix}.svg`;
  } catch (error) {
    return "bank_default.svg";
  }
};

export default IfscCodeMapper;
