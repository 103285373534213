import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettleType } from "../../redux/features/settleTxn/settleTxn";
import PaymentMethodCard from "../../components/CreateTxn/CreateVendorTxn/PaymentMethodCard";
import TxnSettlePaymentLeftCard from "../../components/SettleTxn/TxnSettlePaymentLeftCard";
import { useParams } from "react-router-dom";
import "./index.css";
import { getVendorViewDetail } from "../../redux/features/txnDetails/txnDetails";

const VendorSettleVendorTxns = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const settlementType = useSelector((state) => state.settleTxn.type);

  const settleTypeData = settlementType ? settlementType.settle_type : [];

  const txnDetail = useSelector((state) => state.txnDetails.vendor);
  const data = txnDetail ? txnDetail.transactions[0] : null;

  useEffect(() => {
    dispatch(getSettleType());
    dispatch(getVendorViewDetail({ txn_id: id }));
  }, [dispatch, id]);

  return (
    <Box className="selectPayContainer">
      <Box className="selectPayLeft">
        <Box className="selectPayHeading">
          <p>Select</p>
          <span>the payment method you want to use</span>
        </Box>
        <Box id="selectPayHeadingMobile" className="selectTxnsHeadingBoxMobile">
          <Box sx={{ display: "flex" }}>
            <Button className="selectTxnSearch">
              <img
                className="selectTxnSearchImg"
                src={require("../../assets/Approve transactions/Close.png")}
                alt=""
                srcSet=""
              />
            </Button>
            &nbsp; &nbsp;
            <Box className="selectTxnsHeading">Vendor Transactions</Box>
          </Box>
        </Box>
        <Box id="TxnCreatedCardDesktop">
          <TxnSettlePaymentLeftCard
            totalSelected={[id].length}
            txn_initiator_name={data?.txn_initiator_name}
            amount={data?.amount}
            vendor_bank_acc_no={data?.vendor_bank_acc_no}
            vendor_ifsc_code={data?.vendor_ifsc_code}
            vendor_bank_name={data?.vendor_bank_name}
          />
        </Box>
        <Box id="TxnCreatedCardMobile">
          <p className="txnCreatedText">SettleMent For</p>
          <h2 style={{ color: "white" }}>{data?.txn_initiator_name}</h2>
        </Box>
      </Box>
      <Box className="selectPayRight" sx={{ height: "100vh" }}>
        <h1 className="paymenthodText">Payment method</h1>
        <Box>
          <Box>
            {settleTypeData &&
              settleTypeData.map((item) => (
                <>
                  <Box className="paymentCardHeading">
                    <Box>{item.settlement_type}</Box>
                    <Box>Instant transfer</Box>
                  </Box>
                  <PaymentMethodCard
                    key={item.id}
                    settlement_id={item.settlement_id}
                    settlement_title={item.settlement_title}
                    settlement_method={item.settlement_method}
                    settlement_type={item.settlement_type}
                    account_holder_name={item.account_holder_name}
                    is_primary={item.is_primary}
                    current_balance={item.current_balance}
                    transactions={[id]}
                  />
                  <br />
                </>
              ))}
          </Box>
        </Box>
      </Box>
      <Box id="paymentBTsMobile" className="approveDetailsBTsMobile">
        <Button
          variant="contained"
          className="approveBt"
          // onClick={() =>
          //   dispatch(
          //     approveVendorTxn({ txn_id: props.getSelected(), status: "Y" })
          //   )
          // }
        >
          Pay
        </Button>
      </Box>
    </Box>
  );
};

export default VendorSettleVendorTxns;
