import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./index.css";
import TxnDetailsBanner from "../../../components/TxnDetails/InternalDetails/TxnDetailsBannerCard";
import AdditionalDetailsCard from "../../../components/TxnDetails/AdditionalDetailsCard";
import QuickAccessMenu from "../../../components/TxnDetails/QuickAccessMenu";
import { useDispatch, useSelector } from "react-redux";
import { getInternalViewDetail } from "../../../redux/features/txnDetails/txnDetails";
import {
  VendorTxnDetailBgMapperMain,
  VendorTxnDetailBgMapper,
  VendorTxnDetailDarkBgMapper,
  VendorTxnDetailChildBgMapper,
} from "../../../services/VendorTxnDetailBgMapper";
import TimeLine from "../../../components/TxnDetails/TimeLine";
import TxnDetailsToggleButtons from "../../../components/TxnDetails/TxnDetailsToggleButtons";
import { useParams } from "react-router-dom";
import PayMethod from "../../../components/TxnDetails/PayMethod";
import MoreActions from "../../../components/TxnDetails/MoreActions";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import AddStatus from "../../../components/TxnDetails/MoreActions/AddStatus";
import ConvertToInstantPay from "../../../components/TxnDetails/MoreActions/ConvertToInstantPay";
import SupportInfo from "../../../components/TxnDetails/SupportInfo";
import { approveVendorTxn } from "../../../redux/features/approveTxn/approveTxn";
import DeniedReason from "../../../components/ApprovalTxn/VendorApproval/DeniedReson/DeniedReason";
import { getVendorPendingTxn } from "../../../redux/features/pendingTxn/pendingTxn";
import VendorDeniedReasonComponent from "../../../components/TxnDetails/VendorDeniedReasonComponent";
import SettlePayment from "../../SettlementRequired/SettlePayment";
import { PaymentGatewayMapper } from "../../../services/paymentGatewayMapper";
import { toast } from "react-toastify";
import DeleteButton from "../../../components/TxnDetails/DeleteButton";

const VendorTxnDetails = () => {
  const [toggleView, setToggleView] = useState("details");
  const dispatch = useDispatch();
  const txnDetail = useSelector((state) => state.txnDetails.internal);
  const data = txnDetail ? txnDetail.transactions[0] : null;
  const { id } = useParams();
  const [showAddStatus, setShowAddStatus] = useState(false);
  const [showConvertToInstantPay, setShowConvertToInstanyPay] = useState(false);
  const [deniedReason, setDeniedReason] = useState(false);
  const [selectedTxnCarousel, setSelectedTxnCarousel] = useState({});
  const [showSettlementComponent, setShowSettlementComponent] = useState(false);
  const addStatusRes = useSelector((state) => state.addStatusState?.response);
  const [checkRes, setCheckRes] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    dispatch(getInternalViewDetail({ txn_id: id }));
  }, [dispatch, addStatusRes, data?.approval_status, id]);

  const handleToggleView = (view) => {
    setToggleView(view);
  };

  useEffect(() => {
    if (checkRes && addStatusRes?.errors) {
      toast.error(Object.values(addStatusRes.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && addStatusRes?.status) {
      toast.success("Transaction status update successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setCheckRes(true);
  }, [addStatusRes]);

  return !showSettlementComponent ? (
    <Box
      sx={{
        background: `linear-gradient(to right, ${
          VendorTxnDetailBgMapperMain(data?.approval_status)[0]
        } 0%, ${
          VendorTxnDetailBgMapperMain(data?.approval_status)[0]
        } 66.66%, ${
          VendorTxnDetailBgMapperMain(data?.approval_status)[1]
        } 66.66%, ${
          VendorTxnDetailBgMapperMain(data?.approval_status)[1]
        } 100%)`,
      }}
    >
      <Box
        className="flex flex-col-reverse lg:flex-row vendor-txn-timeline relative"
        sx={{ maxWidth: "1440px", margin: "0px auto 0px auto" }}
      >
        {deniedReason && (
          <DeniedReason
            txnId={[data?.txn_id]}
            setDeniedReason={setDeniedReason}
            dispatchFn={approveVendorTxn}
            navigatePath={"/vendor/approval_status"}
          />
        )}
        {showAddStatus && (
          <AddStatus setState={setShowAddStatus} txn_id={data?.txn_id} />
        )}
        {showConvertToInstantPay && (
          <ConvertToInstantPay
            setState={setShowConvertToInstanyPay}
            txn_id={data?.txn_id}
          />
        )}
        {/** Left Container */}
        <Box
          className="flex flex-row justify-end pb-8 w-[100%] lg:max-w-[960px]"
          sx={{ bgcolor: VendorTxnDetailBgMapper(data?.approval_status) }}
        >
          <div
            className="mt-24
            py-4 w-full
            flex flex-col
            justify-items-center"
          >
            {/** Left Top Banner */}
            <div className="p-12 py-6">
              <div className="flex flex-col justify-between">
                <div className="">
                  <TxnDetailsBanner
                    destination_bank_name={data?.destination_bank_name}
                    destination_bank_acc_no={data?.destination_bank_acc_no}
                    source_bank_name={data?.source_bank_name}
                    source_bank_acc_no={data?.source_bank_acc_no}
                    posting_date={data?.posting_date}
                    approval_status={data?.approval_status}
                    amount={data?.amount}
                    txn_id={id}
                    data={data}
                    setDeniedReason={setDeniedReason}
                    utr_no={data?.utr_no}
                  />
                </div>
              </div>
            </div>
            <div
              className="w-full flex
              px-12 self-start"
            >
              <TxnDetailsToggleButtons
                handleToggleView={handleToggleView}
                toggleView={toggleView}
              />
            </div>
            <Box className="quickAccessMenuMobile">
              <QuickAccessMenu
                bgColor={VendorTxnDetailBgMapper(data?.approval_status)}
                addStatus={setShowAddStatus}
                convertToInstantPay={setShowConvertToInstanyPay}
              />
            </Box>
            <div className="p-12 py-6 flex flex-col">
              {/** Left Body */}
              {toggleView === "timeline" ? (
                <div className="pb-8 3xl:pb-10 4xl:pb-12 5xl:pb-16 6xl:pb-20">
                  <TimeLine approval_status={data?.approval_status} />
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="mb-8">
                    <AdditionalDetailsCard
                      approval_status={data?.approval_status}
                      invoice_no={data?.invoice_no}
                      distribution_remark={data?.distribution_remark}
                    />
                  </div>

                  <div className="flex flex-row gap-4">
                    <div
                      className={`w-1/2
                        ${
                          data?.settlement_type && data?.payment_gateway_used
                            ? "bg-white"
                            : null
                        }
                        rounded-t-large`}
                    >
                      {data?.approval_status === "N" && (
                        <VendorDeniedReasonComponent
                          denied_reason={data?.reason}
                          approval_status={data?.approval_status}
                        />
                      )}
                      {data?.settlement_type && (
                        <PayMethod
                          module={"Internal"}
                          bank_acc_no={
                            data?.settlement_type === "HDFC NEFT/RTGS"
                              ? data?.owner_bank_acc_no
                              : data?.settlement_type === "Registered Card"
                              ? data?.card_ending_with
                              : data?.settlement_type === "Instant Pay"
                              ? "Instant Pay"
                              : "N/A"
                          }
                          name={
                            data?.settlement_type === "HDFC NEFT/RTGS"
                              ? data?.owner_name
                              : data?.settlement_type === "Registered Card"
                              ? data?.card_holder_name
                              : ""
                          }
                          bank_logo={
                            data?.settlement_type === "HDFC NEFT/RTGS"
                              ? bankLogoMapper(data?.owner_bank_name)
                              : data?.settlement_type === "Instant Pay"
                              ? "Instant Pay"
                              : data?.settlement_type === "Registered Card"
                              ? data?.card_type || "Default Card"
                              : "Status - pending"
                          }
                          status={data?.status}
                          approval_status={data?.approval_status}
                          payment_gateway_used={data?.payment_gateway_used}
                        />
                      )}
                      {data?.settlement_type && data?.payment_gateway_used && (
                        <div
                          className="bg-white flex flex-row justify-between
                          rounded-b-4xl
                          py-2 px-8"
                        >
                          <div className="self-center flex flex-col">
                            <div
                              className="text-sm
                            text-black antialiased truncate ..."
                            >
                              {data?.payment_gateway_used}
                            </div>
                            <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                              Payment gateway
                            </div>
                          </div>
                          <div
                            className="w-2/5
                            -mr-8
                            flex flex-col justify-center"
                          >
                            <div
                              className="py-1
                              flex justify-center text-center drop-shadow-xl"
                            >
                              <img
                                className="w-[70%]"
                                src={require(`../../../assets/TxnDetails/${PaymentGatewayMapper(
                                  data?.payment_gateway_used
                                )}.png`)}
                                alt="BankLogo"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <Box className="quickAccessMenuMobile">
                <Box>More Actions</Box>
                <MoreActions />
              </Box>
            </div>
          </div>
        </Box>
        {/** Right Side */}
        <div
          className="hidden lg:flex h-fit lg:min-h-screen flex-col
             lg:max-w-[480px] overflow-hidden"
          style={{
            backgroundColor: VendorTxnDetailDarkBgMapper(data?.approval_status),
          }}
        >
          <div
            className="mt-24 lg:fixed
            max-w-[480px] py-4 overflow-x-hidden overflow-y-auto
            flex flex-col justify-items-center"
            style={{ width: "inherit", position: "relative" }}
          >
            <div className="p-12 py-6">
              <QuickAccessMenu
                addStatus={setShowAddStatus}
                convertToInstantPay={setShowConvertToInstanyPay}
                status={data?.approval_status}
                bgColor={VendorTxnDetailChildBgMapper(data?.approval_status)}
                txnHistoryPath={`/internal_transactions/view_all_transactions?destination_owner_id=${data?.destination_owner_id}&source_owner_id=${data?.source_owner_id}`}
                txnStatementPath={`/internal_transactions/view_all_transactions?settle_id=${data?.settle_id}`}
                source_owner_id={data?.source_owner_id}
                destination_owner_id={data?.destination_owner_id}
                settle_id={data?.settle_id}
                txn_id={data?.txn_id}
                secondary_bg={VendorTxnDetailBgMapper(data?.approval_status)}
              />
            </div>
            <div className="p-12 py-6">
              <SupportInfo
                settle_id={data?.settle_id}
                txn_id={data?.txn_id}
                utr_no={data?.utr_no}
                account_id={data?.account_id}
                bgColor={VendorTxnDetailChildBgMapper(data?.approval_status)}
                secondary_bg={VendorTxnDetailBgMapper(data?.approval_status)}
              />
            </div>
          </div>
        </div>
      </Box>
    </Box>
  ) : (
    <SettlePayment getSelected={() => selectedTxnCarousel} />
  );
};

export default VendorTxnDetails;
