import React, { useEffect, useState } from "react";
import { Box, Input } from "@mui/material";
import "./index.css";
import generateOTP from "../../../../utils/instantPayOTP";
import formatCurrency from "../../../../utils/CurrencyFormater";
import accTransform from "../../../../utils/accTransform";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import { PayCardsLogoMapper } from "../../../../services/payCardsLogoMapper";
import { toast } from "react-toastify";

const PaymentMethodCard = (props) => {
  const [otp, setOtp] = useState("1234");

  const handleClick = (event) => {
    props.setIsActive(props.settlement_id);
  };

  useEffect(() => {
    setOtp(generateOTP());
  }, [props.isActive]);

  const handlechange = (value, event) => {
    if (event.target.value.length === 4 && value != event.target.value) {
      toast.error("Enter correct code!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    props.setCodeVerified(value == event.target.value);
  };

  const InstantPay = (
    <Box className="paymethodRight">
      <Box
        className="paymethodAmount"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {props.current_balance && (
          <b
            style={{
              color:
                props.txn_amount > +props.current_balance ? "#FC4D4D" : "auto",
            }}
          >
            ₹ {formatCurrency(props.current_balance)}
          </b>
        )}{" "}
        <Box
          style={{
            color:
              props.isActive === props.settlement_id ? "#DEE3EE" : "#FFFFFF",
          }}
        >
          Available balance
        </Box>
      </Box>
      {props.isActive === props.settlement_id && (
        <Box className="payMethodActive">
          <span className="text-xs 3xl:text-base">
            Confirmation code is {otp}
          </span>
          <Input
            autoFocus={true}
            className="payMethodInput"
            placeholder="Code"
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Backspace",
                "Delete",
                "ArrowLeft",
                "ArrowRight",
                "ArrowUp",
                "ArrowDown",
              ];
              let numeric = /\d/g;
              if (allowedKeys.indexOf(e.key) === -1 && !numeric.test(e.key)) {
                e.preventDefault();
              }
            }}
            inputProps={{
              style: { textAlign: "center", fontSize: "20px" },
              maxLength: 4,
            }}
            onChange={(event) => handlechange(otp, event)}
            disableunderline
          />
        </Box>
      )}
    </Box>
  );

  const NEFTRTGS = (
    <Box className="paymethodRight">
      <Box
        className="paymethodAmount"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ fontSize: "22px" }}>
          <b
            style={{
              color:
                props.isActive === props.settlement_id ? "#446AFF" : "white",
            }}
          >
            {accTransform(props.settlement_title)}
          </b>
        </span>
        {props.current_balance && (
          <b
            style={{
              color:
                props.txn_amount > +props.current_balance ? "#FC4D4D" : "auto",
            }}
          >
            {" "}
            ₹ {formatCurrency(props.current_balance)}
          </b>
        )}
      </Box>
      {props.isActive === props.settlement_id && (
        <Box className="payMethodActive">
          <span className="text-xs 3xl:text-base">
            Pin is the last 4 digits of account number
          </span>
          <Input
            autoFocus={true}
            className="payMethodInput"
            placeholder="Code"
            type="text"
            inputProps={{
              style: { textAlign: "center", fontSize: "20px" },
              maxLength: 4,
            }}
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Backspace",
                "Delete",
                "ArrowLeft",
                "ArrowRight",
                "ArrowUp",
                "ArrowDown",
              ];
              let numeric = /\d/g;
              if (allowedKeys.indexOf(e.key) === -1 && !numeric.test(e.key)) {
                e.preventDefault();
              }
            }}
            disableunderline
            onChange={(event) => handlechange(props.settlement_title, event)}
          />
        </Box>
      )}
    </Box>
  );

  const RegisteredCard = (
    <Box className="paymethodRight">
      <Box
        className="paymethodAmount"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <span style={{ fontSize: "22px" }}>
          <b
            style={{
              color:
                props.isActive === props.settlement_id ? "#446AFF" : "white",
            }}
          >
            {accTransform(props.settlement_title)}
          </b>
        </span>
      </Box>
      {props.isActive === props.settlement_id && (
        <Box className="payMethodActive">
          <span className="text-xs 3xl:text-base">
            The CVV code is at the back of your card
          </span>
          <Input
            autoFocus={true}
            className="payMethodInput"
            placeholder="CVV"
            type="text"
            inputProps={{
              style: { textAlign: "center", fontSize: "20px" },
              maxLength: 3,
            }}
            onKeyDown={(e) => {
              const allowedKeys = [
                "Tab",
                "Backspace",
                "Delete",
                "ArrowLeft",
                "ArrowRight",
                "ArrowUp",
                "ArrowDown",
              ];
              let numeric = /\d/g;
              if (allowedKeys.indexOf(e.key) === -1 && !numeric.test(e.key)) {
                e.preventDefault();
              }
            }}
            disableunderline
            onChange={(event) => props.setCvv(event.target.value)}
          />
        </Box>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        pointerEvents:
          props.txn_amount > +props.current_balance ? "none" : "auto",
      }}
      onClick={handleClick}
      className={
        props.isActive === props.settlement_id
          ? "active"
          : "cursor-pointer payMethodContainer"
      }
    >
      <Box sx={{ backgroundColor: "white" }} className="methodLogo">
        {props.settlement_type === "Instant Pay" ? (
          <img
            width="36px"
            src={require("../../../../assets/CreateTransactionIcons/InstantPay.png")}
            alt=""
            srcSet=""
          />
        ) : props.settlement_type === "NEFT/RTGS" ? (
          <Box className="flex flex-col justify-center h-full">
            <Box className="flex">
              <img
                className="self-center p-2"
                src={require(`../../../../assets/Banks/${bankLogoMapper(
                  props.settlement_method
                )}.png`)}
              />
            </Box>
            <Box className="flex w-full justify-evenly items-center">
              {props.is_primary === "Y" && (
                <Box className="w-1/5">
                  <img
                    src={require("../../../../assets/Banks/Types/Primary.png")}
                    alt=""
                    srcSet=""
                  />
                </Box>
              )}
              {props.maintenance_account === "Y" && (
                <Box className="w-1/5">
                  <img
                    src={require("../../../../assets/Banks/Types/Maintenance.png")}
                    alt=""
                    srcSet=""
                  />
                </Box>
              )}
            </Box>
          </Box>
        ) : props.settlement_type === "Registered Card" ? (
          <img
            width="100%"
            src={
              props.RegisteredCardCount === 1
                ? require("../../../../assets/BankLogos/Card Logos/Default Card.png")
                : require(`../../../../assets/BankLogos/Card Logos/${PayCardsLogoMapper(
                    props.settlement_method
                  )}.png`)
            }
            alt=""
            srcSet=""
          />
        ) : null}
      </Box>
      {props.settlement_type === "Instant Pay"
        ? InstantPay
        : props.settlement_type === "NEFT/RTGS"
        ? NEFTRTGS
        : props.settlement_type === "Registered Card"
        ? RegisteredCard
        : null}
    </Box>
  );
};

export default PaymentMethodCard;
