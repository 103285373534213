import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dashboardDetails } from "../../../redux/features/accounts/dashboard";
import { accountViewAllDetails } from "../../../redux/features/accounts/viewAll";
import StrategyContext from "./strategyContext";

const AccountDashboard = () => {
  const { account_type } = useParams();
  const dispatch = useDispatch();

  const dataCardDetails = useSelector(
    (state) => state.accountDashboard.records
  );

  const loading = useSelector((state) => state.accountDashboard.isLoading);
  const accountListDetails = useSelector(
    (state) => state.accountViewAll.records
  );

  useEffect(() => {
    dispatch(
      dashboardDetails({
        account_type: account_type,
      })
    );
    dispatch(
      accountViewAllDetails({
        account_type: account_type,
      })
    );
  }, [account_type, dispatch]);

  useEffect(() => {}, [accountListDetails]);

  return (
    <div className="bg-custom-appBack min-h-screen max-h-full flex flex-col-reverse lg:flex-row overflow-y-scroll overflow-x-hidden md:overflow-y-auto vendor-txn hide-scrollbar">
      {loading && (
        <div className="w-full m-auto justify-center items-center flex">
          <img
            src={require("../../../assets/Approve transactions/inputloder.gif")}
            alt=""
          />
        </div>
      )}
      {!loading && (
        <>
          <div className="md:bg-custom-appBackLight flex flex-wrap justify-center lg:justify-end w-full lg:w-8/12">
            <div className="p-4 lg:mt-24 md:pr-8 md:py-8 lg:pr-10 lg:py-10 xl:pr-12 xl:py-12 4xl:pr-24 4xl:py-24 w-full lg:w-11/12 flex flex-col gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-16 6xl:gap-24">
              <div className="grid gap-6 4xl:gap-16 grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7 justify-items-center">
                <StrategyContext
                  accountType={account_type}
                  componentType={"datacardComponents"}
                  dashboardData={dataCardDetails}
                  viewAllData={accountListDetails}
                />
              </div>

              <div className="p-2 flex flex-col h-full">
                <StrategyContext
                  accountType={account_type}
                  componentType={"accountListComponents"}
                  dashboardData={dataCardDetails}
                  viewAllData={accountListDetails}
                />
              </div>
            </div>
            <div className="p-2 visible xl:p-0 xl:w-0 xl:h-0 xl:invisible"></div>
          </div>
          <div className="h-full lg:fixed lg:right-0 p-4 md:pl-8 md:py-8 lg:pl-10 lg:py-10 xl:pl-12 xl:py-12 4xl:pl-24 4xl:py-24 flex flex-col bg-custom-appBack w-full lg:w-4/12 overflow-y-scroll overflow-x-hidden md:overflow-y-auto vendor-txn hide-scrollbar">
            <div className="mt-24 w-full lg:w-11/12 flex flex-col gap-10 2xl:gap-12 3xl:gap-16 4xl:gap-20 justify-items-center">
              <StrategyContext
                accountType={account_type}
                componentType={"rightSidebarComponents"}
                dashboardData={dataCardDetails}
                viewAllData={accountListDetails}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountDashboard;
