import React from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import { tooltipClasses, Tooltip } from "@mui/material";

function BeneEditConfirm(props) {
  const bankStatus = useSelector(
    (state) => state.beneAllVerification.verifyBank
  );

  return (
    <div className="beneBank_Main_edit">
      <div className="dividebank_edit">
        <div className="w-1/2 h-fit bankright_edit">
          <div className="bankContainer">
            <div className="bankAccount">
              <p>Beneficiary's Bank Details</p>
              <div className="bankNumber">
                <span>
                  {formatAccountNumber(
                    bankStatus?.verification_details[0]?.bank_acc_no
                  )}
                </span>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  Bank account number
                </p>
              </div>
              <div className="w-1/2 h-fit flex">
                <div className="ifscCode">
                  <span>{bankStatus?.verification_details[0]?.ifsc_code}</span>
                  <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                    IFSC code
                  </p>
                </div>
                <div className="items-center justify-between ml-[250px] w-32 h-[41px]">
                  <div className="bankImg bg-[#ffffff] rounded-tl-[30px] rounded-br-[30px] flex items-center justify-around mt-[24px]  ">
                    {
                      <img
                        src={require("../../../assets/IfscLogo/" +
                          IfscCodeMapper(
                            bankStatus?.verification_details[0]?.ifsc_code
                          ))}
                        alt="bank"
                      />
                    }
                  </div>
                </div>
              </div>

              <div className="bankNumber capitalize">
                <span>
                  {bankStatus?.verification_details[0]?.full_name.toLowerCase()}
                </span>

                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  Account holders name
                </p>
              </div>
              <div className="h-[2px] bg-[#1D3A6D] mt-[10px] w-[92%] opacity-100"></div>
            </div>
            <div className="bankAccount">
              <p>Address Details</p>
              <>
                <div className="space-y-1 mt-3">
                  <div className="w-full antaliased text-[#FAFAFA] font-normal capitalize self-center text-base truncate ...">
                    {bankStatus?.verification_details[0].branch
                      ? bankStatus?.verification_details[0].branch.toLowerCase()
                      : "NA"}
                  </div>
                  <div className="antaliased text-[#D5D5D5] text-xs opacity-100">
                    Branch
                  </div>
                </div>
                <div className="space-y-1 mt-3">
                  <div className="w-full antaliased text-[#FAFAFA] font-normal capitalize  self-center text-base truncate ...">
                    {bankStatus?.verification_details[0].city
                      ? bankStatus?.verification_details[0].city.toLowerCase()
                      : "NA"}
                  </div>
                  <div className="antaliased text-[#D5D5D5] text-xs opacity-100">
                    City
                  </div>
                </div>
                <div className="space-y-1 mt-3">
                  <div className="w-full antaliased text-[#FAFAFA] font-normal capitalize  self-center text-base truncate ...">
                    {bankStatus?.verification_details[0].district
                      ? bankStatus?.verification_details[0].district.toLowerCase()
                      : "NA"}
                  </div>
                  <div className="antaliased text-[#D5D5D5] text-xs opacity-100">
                    District
                  </div>
                </div>
                <div className="space-y-1 mt-3">
                  <div className="pr-6 w-full antialiased text-[#FAFAFA] font-normal capitalize text-base whitespace-pre-line break-words overflow-ellipsis">
                    {bankStatus?.verification_details[0].address
                      ? bankStatus?.verification_details[0].address.toLowerCase()
                      : "NA"}
                  </div>
                  <div className="antialiased text-[#D5D5D5] text-xs opacity-100">
                    Address
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="dividebank_edit">
        <div className="bankleft_edit">
          <div className="bankContainerRight">
            <div className="archieveAccount">
              <p>Archive Account Details</p>
              <div className="pandetail">
                <h5>Beneficiary PAN details</h5>
                <span>{props?.payload?.pan_no}</span>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  PAN details
                </p>
              </div>
              <div className="h-[2px] bg-[#1D3A6D] w-[92%] opacity-100"></div>
              <div className="benebank">
                <h5>Beneficiary bank details</h5>
                <span>
                  {formatAccountNumber(props?.payload?.old_bank_account)}
                </span>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  Bank account number
                </p>
                <span>{props?.payload?.old_ifsc_code}</span>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  IFSC code
                </p>
              </div>
              <div className="h-[2px] bg-[#1D3A6D] mt-[10px] w-[92%] opacity-100"></div>
              <div className="contactdetail">
                <h5>Beneficiary contact details</h5>
                <span className="capitalize">
                  {props?.payload?.legal_name.toLowerCase()}
                </span>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  Beneficiary name - Using legal name
                </p>
                <span>+91 {props?.payload?.contact_no}</span>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  Contact number
                </p>

                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title={props?.payload?.email_id}
                >
                  <span
                    id="email-id-firstLetter"
                    className="email-bank block mt-0 mb-0 w-[92%] overflow-hidden whitespace-nowrap overflow-ellipsis pt-0"
                    style={{ paddingTop: "0px" }}
                  >
                    {props?.payload?.email_id}
                  </span>
                </Tooltip>
                <p className="antaliased text-[#D5D5D5] text-xs opacity-100">
                  Email
                </p>
              </div>
              <div className="antaliased text-[#D5D5D5] text-xs mt-5 opacity-100">
                This account will be archived
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeneEditConfirm;
