export const approvalStatus = (status) => {
  switch (status) {
    case "S":
      return "#4DDD37";
    case "Processing":
      return "#FBC02D";
    case "Stalled":
      return "#FBC02D";
    case "D":
      return "#FF5860";
    case "P":
      return "#31E5E5";
    case "Y":
      return "#B196FA";
    case "Cancelled":
      return "#FF5860";
    case "Abandoned":
      return "#B4B4B4";
    case "Expired":
      return "#FF5860";
    case "N":
      return "#FF5860";
    case "Failed":
      return "#FF5860";
    case "Refund":
      return "#A921A9";
    default:
      return "#ECECEC";
  }
}

export const approvalStatusRGBA = (status) => {
  switch (status) {
    case "S":
      return "rgba(77, 221, 55, 0.4)";
    case "Processing":
      return "rgba(251, 192, 45, 0.4)";
    case "Stalled":
      return "rgba(251, 192, 45, 0.4)";
    case "D":
      return "rgba(255, 88, 96, 0.4)";
    case "P":
      return "rgba(49, 229, 229, 0.4)";
    case "Y":
      return "rgba(177, 150, 250, 0.4)";
    case "Cancelled":
      return "rgba(255, 88, 96, 0.4)";
    case "Abandoned":
      return "rgba(180, 180, 180, 0.4)";
    case "Expired":
      return "rgba(49, 77, 126, 0.4)";
    case "N":
      return "rgba(255, 88, 96, 0.4)";
    case "Failed":
      return "rgba(49, 77, 126, 0.4)";
    case "Refund":
      return "rgba(220, 118, 220, 0.2)";
    default:
      return "rgba(236, 236, 236, 0.4)";
  }
}

export const accountApprovalStatusColor = (status) => {
  switch (status) {
    case "Y":
      return "#4DDD37";
    case "Active":
      return "#4DDD37";
    case "Inactive":
      return "#B4B4B4";
    case "N":
      return "#FB923C";
    case "Blocked":
      return "#FF5860";
    case "Archived":
      return "#B4B4B4";
    case "P":
      return "#31E5E5";
    default:
      return "#ECECEC";
  }
}

export const accountApprovalStatusColorRGBA = (status) => {
  switch (status) {
    case "Y":
      return "rgba(77, 221, 55, 0.4)";
    case "Active":
      return "rgba(77, 221, 55, 0.4)";
    case "N":
      return "rgba(251, 146, 60, 0.4)";
    case "Blocked":
      return "rgba(255, 88, 96, 0.4)";
    case "Archived":
      return "rgba(180, 180, 180, 0.4)";
    case "Inactive":
      return "rgba(180, 180, 180, 0.4)";
    case "P":
      return "rgba(49, 229, 229, 0.4)";
    default:
      return "rgba(236, 236, 236, 0.4)";
  }
}

export const accountOwnerStatusColor = (status) => {
  switch (status) {
    case "Y":
      return "#4DDD37";
    case "N":
      return "#FF5860";
    case "F":
      return "#FB923C";
    case "D":
      return "#FF5860";
    case "Blocked":
      return "#FF5860";
    case "Archived":
      return "#B4B4B4";
    case "P":
      return "#FB923C";
    default:
      return "#ECECEC";
  }
}

export const accountOwnerStatusColorRGBA = (status, hdfc_umrn = null) => {
  switch (status) {
    case "Y":
      return "rgba(77, 221, 55, 0.4)";
    case "N":
      return "rgba(255, 88, 96, 0.4)";
    case "F":
      return "rgba(251, 146, 60, 0.4)";
    case "D":
      return "rgba(255, 88, 96, 0.4)";
    case "Blocked":
      return "rgba(255, 88, 96, 0.4)";
    case "Archived":
      return "rgba(180, 180, 180, 0.4)";
    case "P":
      return "rgba(251, 146, 60, 0.4)";
    default:
      return "rgba(236, 236, 236, 0.4)";
  }
}

export const filterButtonViewAll = (status) => {
  switch (status) {
    case "Y":
      return "#27B882";
    case "Active":
      return "#27B882";
    case "P":
      return "#31E5E5";
    case "N":
      return "#E48532";
    case "F":
      return "#E48532";
    case "D":
      return "#FF5860";
    case "Archived":
      return "#727272";
    case "Blocked":
      return "#FF5860";
    case "Inactive":
      return "#727272";
    default:
      return "#ECECEC";
  }
}

export const accountDetailsBackground = (status) => {
  switch (status) {
    case "P":
      return ["#375151", "#364949"];
    case "Y":
      return ["#445952", "#364943"]
    case "Blocked":
      return ["#513939", "#493636"];
    case "Archived":
      return ["#313131", "#2A2A2A"];
    case "N":
      return ["#514639", "#494036"];
    default:
      return ["#313131", "#2A2A2A"];
  }
}

export const accountDetailsCardBg = (status) => {
  switch (status) {
    case "P":
      return "#426161";
    case "Y":
      return "#4D655D";
    case "Blocked":
      return "#674444";
    case "Archived":
      return "#404040";
    case "Expired":
      return "#674444";
    case "N":
      return "#675949";
    default:
      return "#404040";
  }
}
