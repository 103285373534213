import React, { useEffect, useState } from "react";
import "./App.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "@fontsource/open-sans";
import { useDispatch, useSelector } from "react-redux";
import { getRefreshTokenNew } from "./redux/features/refreshToken/refreshToken";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Oops from "./assets/svg/oops.svg";
import { Button } from "@mui/material";
import ReLoginModal from "./containers/LoginPage/ReLogin";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  handleClick = () => {
    window.location.href = "https://www.q2pay.in/landing/contact";
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className=" mx-auto flex justify-center items-center h-screen">
          <div
            style={{
              width: "1024px ",
              height: "524px",
              paddingLeft: "60px",
              background: "#29487E 0% 0% no-repeat padding-box",
              "box-shadow": "5px 5px 11px #00000033",
              "border-radius": "40px",
            }}
            className=" flex"
          >
            <div className=" flex justify-center items-center">
              <img
                style={{ width: "473px", height: "320px" }}
                src={Oops}
                alt="oops"
              />
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center">
              <div
                style={{ font: "normal normal 600 40px/55px Open Sans" }}
                className=" text-5xl text-[#FAFAFA] mb-6"
              >
                Oops!
              </div>
              <div
                style={{ font: "normal normal normal 20px/27px Open Sans" }}
                className="text-[#FAFAFA] pb-8 text-center"
              >
                An unknown error has occurred,
                <br /> kindly retry the operation later.
              </div>
              <div className="text-[#dadada] text-md text-center">
                If the problem still persists
                <br /> contact &nbsp;
                <span
                  style={{
                    color: "#EB725F",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={this.handleClick}
                >
                  customer support
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "0px 20px",
                  width: "65%",
                  margin: "50px 20px 0px 0px",
                }}
              >
                <Button
                  style={{
                    font: "normal normal normal 16px/22px Open Sans",
                    borderRadius: "20px",
                    borderColor: "#fff",
                    color: "#EB725F",
                    textTransform: "capitalize",
                    textDecoration: "underline",
                    marginRight: "5px",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Try Again
                </Button>
                <HomeButton />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export const HomeButton = () => {
  const handleHomeClick = () => {
    window.location.reload();
    window.location.href = "/home";
  };

  return (
    <Button
      style={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 10px 10px #00000029",
        borderRadius: "33px",
        color: "black",
        width: "126px",
        height: "52px",
        fontSize: "16px",
        fontWeight: "bolder",
        border: "none",
        padding: "6px",
      }}
      variant="contained"
      onClick={handleHomeClick}
    >
      Home
    </Button>
  );
};

function App() {
  const [showModalPopup, setShowModalPopup] = useState(false);
  const { sessionStatus, isLoading, isError, data } = useSelector(
    (state) => state.refreshToken
  );
  const { showReloginModal } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      const hasSessionExpired = window.sessionStorage.getItem("q2p_token");

      if (hasSessionExpired) {
        try {
          // localStorage.setItem("redirectPath", pathname);
          dispatch(getRefreshTokenNew());
        } catch (error) {
          setShowModalPopup(true);
        }
      }
    };
    checkSession();
  }, [dispatch, pathname]);

  useEffect(() => {
    if (data?.employees?.first_login_flag == "Y") {
      navigate("/setNewPassword");
    }
  }, [data]);

  // useEffect(() => {
  //   if (showReloginModal && pathname !== "/login") {
  //     if (!sessionStorage.getItem("q2p_token")) {
  //       sessionStorage.removeItem("loggedIn");
  //     }
  //     setShowModalPopup(true);
  //   } else {
  //     setShowModalPopup(false);
  //   }
  // }, [showModalPopup, pathname, showReloginModal]);

  useEffect(() => {
    if (showReloginModal && pathname !== "/login" && pathname !== "/") {
      if (!sessionStorage.getItem("q2p_token")) {
        sessionStorage.removeItem("loggedIn");
      }
      setShowModalPopup(true);
    } else {
      setShowModalPopup(false);
    }
  }, [showModalPopup, pathname, showReloginModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <ErrorBoundary>
        <Outlet />
        {showModalPopup && (
          <ReLoginModal
            isLoading={isLoading}
            isError={isError}
            showModalPopup={showModalPopup}
            setShowModalPopup={setShowModalPopup}
          />
        )}
        <ToastContainer style={{ zIndex: "2147483647" }} />
      </ErrorBoundary>
    </div>
  );
}

export default App;
