// import { useSelect } from "@mui/base";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CreateTxnMenuItemCard from "../../components/CreateTxnMenuItemCard";
import InstanPayAvailBal from "../../components/InstanPayAvailBal";
import { getInstantPayBalance } from "../../redux/features/instantPayBalance/instantPayBalance";
import "./index.css";
import { checkUsertoken } from "../../redux/features/login/login";

const CreateTxn = () => {
  const dispatch = useDispatch();
  const instantAccountSummary = useSelector(
    (state) => state.instantPayBalance.balance
  );

  const instantPayAvailBal = instantAccountSummary
    ? instantAccountSummary.balance_details &&
      instantAccountSummary.balance_details.length
      ? instantAccountSummary.balance_details[0]
      : null
    : null;

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getInstantPayBalance());
  }, [dispatch]);
  return (
    <>
      <div className="create-txn-container-top">
        <div className="create-txn-headings">
          <div className="create-txn-container-title">Select</div>
          <div className="create-txn-container-subtitle">
            The transaction type you want to create
          </div>
        </div>
        {/* <div className="create-txn-create-txn">
          <InstanPayAvailBal
            availbal={
              instantPayAvailBal ? instantPayAvailBal.current_balance : ""
            }
          />
        </div> */}
      </div>
      <div className="create-txn-container-bottom">
        <div className="create-txn-menu-title">
          DEBIT TRANSACTIONS
          <div className="create-txn-menu-items">
            <CreateTxnMenuItemCard
              icon="Beneficiary.png"
              label="Beneficiary"
              checkservice="CBeneficiaryTxn"
              subtext="Start making all your beneficiary payments"
              to="/transaction/beneficiary_transaction/create"
            />
            {/* <CreateTxnMenuItemCard
              icon="Vendor.png"
              label="Vendor"
              subtext="Start making all your vendor payments"
              to="/create_transaction/vendor_transaction"
            /> */}
          </div>
        </div>

        {/* <div className="create-txn-menu-title">
          CORPORATE TRANSACTIONS
          <div className="create-txn-menu-items">
            <CreateTxnMenuItemCard
              icon="InstantPay card icon.png"
              label="InstantPay Refill"
              subtext="Refill your InstantPay balance"
              to="/create_transaction/instantpay_transactions"
            />
            <CreateTxnMenuItemCard
              icon="Internal.png"
              label="Internal"
              subtext="Transfer funds from one owner account to another"
              to="/create_transaction/internal"
            />
          </div>
        </div> */}

        {/*<div className="create-txn-menu-title">
           EMPLOYEE TRANSACTIONS
          <div className="create-txn-menu-items">
            <CreateTxnMenuItemCard
              icon="Field force.png"
              label="Field Force"
              subtext="Create a field force transaction"
              to="/create_transaction/field_force_transaction"
            />
            <CreateTxnMenuItemCard
              icon="Reimbursement.png"
              label="Reimbursement"
              subtext="Create a transaction to reimburse your employees"
              to="#"
            />
            <span>
              *If disabled - the feature is not available for your ID.
            </span>
          </div>
        </div>*/}
        {true && (
          <>
            {/* <hr
              style={{
                width: "100%",
                margin: "50px 0",
                borderColor: "#B4B4B4",
              }}
            /> */}
            {/* <div className="create-txn-menu-title">
              ADMIN TRANSACTIONS
              <div className="create-txn-menu-items">
                <CreateTxnMenuItemCard
                  icon="Adjustment.png"
                  label="Adjustment"
                  subtext="Adjust amount to entity payouts"
                />
                <CreateTxnMenuItemCard
                  icon="Chargeback.png"
                  label="Chargebacky"
                  subtext="Create a chargeback transaction"
                />
                <CreateTxnMenuItemCard
                  icon="Nodal.png"
                  label="Nodal"
                  subtext="Create a Nodal transaction"
                />
              </div>
            </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default CreateTxn;
