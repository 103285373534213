import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addStatus } from "../../../../redux/features/addStatus/addStatus";

const AddStatus = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [status, setStatus] = useState("");

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 absolute z-[9999]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div className="h-fit w-fit flex flex-col gap-2 pt-12 px-4 bg-custom-deniedReasonPurple rounded-4xl">
          <div className="mx-8">
            <h2 className="text-[#FF7F33] font-semibold text-2xl ">
              Add Status
            </h2>
          </div>
          <label
            className="mx-8"
            htmlFor="status"
            style={{
              color: "#C9C9C9",
              font: "normal normal normal 1.25em/1.5em Open Sans",
              textAlign: "left",
            }}
          >
            Status
          </label>
          <div className="mx-8 flex justify-between w-5/6">
            <button
              onChange={(e) => setStatus(e.target.value)}
              onClick={() => setShowOptions(!showOptions)}
              style={{
                color: "#fff",
                font: "normal normal normal 1.25em/1.5em Open Sans",
                backgroundColor: "#302D3B",
                height: "3em",
                textAlign: "left",
                width: "100%",
                margin: "2% 0",
                borderRadius: "10px",
                appearance: "none",
                "-webkit-appearance": "none",
                "-moz-appearance": "none",
                border: "",
                padding: " 0 5%",
                // borderBottom: "1px solid #fff",
                // padding: "5%"
              }}
            >
              <div
                className="flex whitespace-nowrap justify-between hover:bg-custom-deniedReasonPurple"
                style={{ padding: "0 5%", borderRadius: "5px" }}
              >
                <span>{status ? status : "Select Status"}</span>
                <span className="text-custom-pink">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </button>
          </div>
          {showOptions && (
            <div className="relative">
              <div
                className="bg-[#302D3B] -top-20 z-[9999] px-[5%] mx-8 w-5/6 rounded-[10px] absolute
                  border-2 border-custom-pink"
              >
                <button
                  className="hover:bg-custom-deniedReasonPurple"
                  style={{
                    color: "#fff",
                    font: "normal normal normal 1.25em/1.5em Open Sans",
                    width: "100%",
                    textAlign: "left",
                    margin: "2% 0",
                    appearance: "none",
                    "-webkit-appearance": "none",
                    "-moz-appearance": "none",
                    border: "none",
                    padding: "0 5%",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setStatus("Abandoned");
                    setShowOptions(false);
                  }}
                >
                  <div className="flex justify-between">
                    <span>Abandoned</span>
                    {status === "Abandoned" && (
                      <span className="text-custom-pink">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            fillRule="evenodd"
                            d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </button>
                <br />
                <hr className="w-full border-custom-pink"></hr>
                <button
                  className="hover:bg-custom-deniedReasonPurple"
                  onClick={() => {
                    setStatus("Distribution/Enet Pending");
                    setShowOptions(false);
                  }}
                  style={{
                    color: "#fff",
                    textAlign: "left",
                    font: "normal normal normal 1.25em/1.5em Open Sans",
                    width: "100%",
                    margin: "2% 0",
                    appearance: "none",
                    "-webkit-appearance": "none",
                    "-moz-appearance": "none",
                    border: "none",
                    padding: "0 5%",
                    borderRadius: "5px",
                  }}
                >
                  <div className="flex whitespace-nowrap justify-between">
                    <span>Distribution/Enet Pending</span>
                    {status === "Distribution/Enet Pending" && (
                      <span className="text-custom-pink">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            fillRule="evenodd"
                            d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </button>
              </div>
            </div>
          )}
          <div className="w-5/6 mx-8 pt-4 flex justify-end gap-5">
            <Button
              sx={{
                borderRadius: "20px",
                borderColor: "#fff",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
              onClick={() => {
                props.setState(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              disabled={status.length === 0 ? true : false}
              style={{ opacity: status.length === 0 ? 0.5 : 1 }}
              onClick={() => {
                dispatch(
                  addStatus({
                    status: status,
                    txn_id: [props.txn_id],
                  })
                );
                props.setState(false);
              }}
              sx={{
                borderRadius: "25px",
                borderColor:
                  status.length === 0
                    ? "#FFFFFF !important"
                    : "#9F7EC0 !important",
                color: "#fff !important",
                backgroundColor:
                  status.length === 0 ? "transparent" : "#9F7EC0",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
              }}
            >
              Confirm
            </Button>
          </div>
          <p className="text-white p-0 leading-0 mx-8 opacity-0 ">
            Please state the reason for declining the transaction/s
          </p>
        </div>
      </div>
    </>
  );
};

export default AddStatus;
