import React from "react";
import { useEffect } from "react";
import { getFFLimits } from "../../../../redux/features/ffLimits/ffLimits";
import { useDispatch, useSelector } from "react-redux";
import formatCurrency from "../../../../utils/CurrencyFormater";

const FFAccountLimit = ({ props }) => {
  const dispatch = useDispatch();
  const limitApiRes = useSelector((state) => state.ffLimits.limit);

  useEffect(() => {
    dispatch(getFFLimits({}));
  }, []);

  const amountLimitDiv = () => {
    if (limitApiRes?.limits) {
      return (
        <>
          <span
            className="self-center text-[#4DE659] text-center
                text-sm 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl"
          >
            ₹ {formatCurrency(limitApiRes?.limits.maximum)}
          </span>
          <span className="self-end bg-[#304770] rounded-4xl p-1">
            <svg
              className="text-white w-3 3xl:w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </>
      );
    } else {
      return (
        <>
          <span
            className="self-center text-[#4DE659] text-center
                text-sm 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl"
          >
            ₹ Amount
          </span>
          <span className="self-end bg-white rounded-4xl p-1">
            <svg
              className="text-[#304770] w-3 3xl:w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z" />
            </svg>
          </span>
        </>
      );
    }
  };
  return (
    <div
      className="flex flex-col gap-4 3xl:gap-6 4xl:gap-10
      p-4 3xl:p-6 4xl:p-10 justify-evenly
      bg-custom-card
      drop-shadow-xl
      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl 6xl:rounded-custom2Xl"
    >
      <div
        className=" text-white
        text-center
        text-sm 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl"
      >
        24 hour transaction limit
      </div>
      <div
        className=" text-gray-300
        text-center font-extralight
        text-xs 3xl:text-sm 4xl:text-lg 5xl:text-xl 6xl:text-2xl"
      >
        The default 24 hour transaction amount permitted for each account
      </div>
      <div
        className="self-center p-2 w-4/5 bg-[#576F98] flex flex-row justify-center gap-4
                      drop-shadow-lg
                      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl 6xl:rounded-custom2Xl"
      >
        {amountLimitDiv()}
      </div>
    </div>
  );
};

export default FFAccountLimit;
