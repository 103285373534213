import React from 'react'

function EntityDetailsBanner({ data }) {
    return (
        <div className='bg-white w-full h-60 rounded-4xl shadow-xl pr-10 flex'>
            <div className='bg-gradient-to-l to-[#4F7A9C] from-[#1C2327] shadow-xl w-2/3 h-full rounded-4xl p-10'>
                <div className='flex flex-col w-full h-full justify-between'>
                    <div className='text-white w-full truncate text-2xl 3xl:text-3xl 5xl:text-4xl'>{data?.entity_name}</div>
                    <div>
                        <div className='text-white text-lg 5xl:text-xl'>{data?.entity_id}</div>
                        <div className='text-[#D3D3D3] text-sm 5xl:text-lg'>Entity ID</div>
                    </div>
                </div>
            </div>
            <div className='w-1/3 flex flex-col justify-end items-end py-10'>
                <div className='w-full truncate text-black text-lg text-right pl-4 5xl:text-xl'>{data?.acquired_by ? data?.acquired_by : "N.A."}</div>
                <div className='text-sm text-[#707070] 5xl:text-xl'>Acquired by</div>
            </div>
        </div>
    )
}

export default EntityDetailsBanner