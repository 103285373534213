import React from "react";
import CPCard from "../components/Settings/Verification/CPCard";
import logo from '../assets/Settings/Verification/Group 1437.png'
import CPSection from "../components/Settings/Verification/CPSection";
import CreateAccount from "./CreateAccount";

const Development = () => {
  return (
    <CreateAccount />
  );
};

export default Development;
