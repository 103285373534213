import React from "react";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";
import { VendorTxnTextMapper } from "../../../services/VendorTxnTextMapper";
import CardLogo from "../../_utils/logo/CardLogo";
import accTransform from "../../../utils/accTransform";

const BeneficiaryDetailsComponent = (props) => {
  const getApprovalIcon = () => {
    let status = VendorTxnTextMapper(props?.vendor_account_status);
    if (status === "Pending") {
      return "pending";
    }
    if (status === "Approved") {
      return "approved";
    }
    if (status === "Denied") {
      return "denied";
    }
    else {
      return "pending";
    }
  }
  return (
    <div
      style={{ backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status) }}
      className="min-h-full h-full text-white drop-shadow-2xl flex flex-col
        rounded-4xl 3xl:rounded-large 5xl:rounded-customXl
        px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-4 gap-5
        overflow-hidden">
      <div className="h-[30%] flex flex-row justify-between">
        <div className="self-center w-3/5 text-gray-300 text-sm 3xl:text-base 5xl:text-lg 6xl:text-2xl font-bold">
          Beneficiary Account
        </div>
        <div className="self-center w-2/5 flex flex-row justify-between">
          <div className="self-center">

          </div>
        </div>
      </div>
      <div className={`h-[30%] flex ${props.vendor_bank_acc_no ? "" : "invisible"}`}>
        <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-white antialiased truncate ...">
          {accTransform(props.vendor_bank_acc_no)}
        </div>
      </div>
      <div className="h-[40%] flex flex-row justify-between">
        <div className="self-center w-3/5">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-white antialiased truncate ...">{props.owner_ifsc_code}</div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            IFSC code
          </div>
        </div>
        <div className="w-2/5
            -mr-8 -mb-4
            3xl:-mr-10
            4xl:-mr-12
            5xl:-mr-16
            6xl:-mr-20
            flex flex-col justify-end">
          <CardLogo bank={props?.bank_logo}
            divCssProps={
              'rounded-tl-4xl 3xl:rounded-tl-large 5xl:rounded-tl-customXl'
            }
          />
        </div>
      </div>
    </div >
  );
};
export default BeneficiaryDetailsComponent;
