import React, { useState } from "react";
import { Box, Button, Popover } from "@mui/material";
import "./index.css";
import formatCurrency from "../../../../utils/CurrencyFormater";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import formatdate from "../../../../utils/formatdate";
import SimpleLogo from "../../../_utils/logo/SimpleLogo";
import { InternalTxnDetailBannerBgMapper } from "../../../../services/VendorTxnDetailBgMapper";
import accTransform from "../../../../utils/accTransform";
import { approvalStatus } from "../../../../services/approvalStatus";
import info from "../../../../assets/TxnDetails/info-black-border.png";
import PopOverContainer from "../../../PopOverContainer";

const TxnDetailsBanner = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleImageClick = (event) => {
    event.stopPropagation();
    handleClick(event);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Box
        id="txnDetailsBannerInternal"
        className="txnDetailsBanner flex justify-between h-full bg-white relative overflow-hidden "
      >
        <Box
          sx={{
            background: `transparent linear-gradient(90deg, ${InternalTxnDetailBannerBgMapper(props.approval_status)[0]
              } 0%, ${InternalTxnDetailBannerBgMapper(props.approval_status)[1]
              } 100%) 0% 0% no-repeat padding-box`,
          }}
          className="internalTxnDetailsBannerLeft w-1/3 p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16"
        >
          <div
            className="h-4/5
          text-white text-semibold
            text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl
            font-light capitalize antialiased truncate ..."
          >
            {accTransform(props.source_bank_acc_no)}
          </div>
          <SimpleLogo bank={bankLogoMapper(props?.source_bank_name)} />
        </Box>
        <Box
          sx={{
            background:
              "radial-gradient(110% 110% at left, transparent 50%, white 50%)",
          }}
          className="w-16 absolute top-0 bottom-0 -ml-16 left-1/3"
        ></Box>
        <Box className="internalTxnDetailsBannerMiddle w-1/3 p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16">
          <div
            className="self-center text-center
          text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl font-bold
          capitalize  flex justify-center items-center flex-wrap"
            style={{ color: approvalStatus(props.approval_status) }}
          >
            {VendorTxnTextMapper(props.approval_status)}
            {props.utr_no ? (
              <div className="text-[#707070] text-sm flex">
                &nbsp;
                <div className="w-0.5 h-5 bg-gray-300"></div>
                &nbsp;
                <span className="text-[#707070]">UTR No&nbsp;:&nbsp; </span>
                <span className="text-[#000000] font-thin">{props.utr_no}</span>
              </div>
            ) : null}
          </div>
          <div></div>
          <div className="flex justify-center">
            <div
              className="text-green-500
            text-semibold
            text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl truncate ..."
            >
              ₹ {formatCurrency(props.amount)}
            </div>
            <div className="cursor-pointer" onClick={handleImageClick}>
              <img src={info} alt="info" className="w-3 h-3" />
            </div>
            <Popover
              sx={{
                "& .MuiPopover-paper": {
                  "border-radius": "40px",
                },
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <PopOverContainer
                data={props.data}
                approval_status={props.approval_status}
              />
            </Popover>
          </div>
          <Box>
            <img
              className="w-10"
              src={require("../../../../assets/TxnDetails/Green_arrow_internal.png")}
              alt=""
            />
          </Box>
          <Box sx={{ color: "#707070" }}>
            {" "}
            <div
              className="h-1/5
            text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl antaliased"
            >
              {props.posting_date == undefined
                ? {}
                : formatdate(props.posting_date)}
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            background:
              "radial-gradient(110% 110% at right, transparent 50%, white 50%)",
          }}
          className="w-16 absolute top-0 bottom-0 -mr-16 right-1/3"
        ></Box>
        <Box
          sx={{
            background: `transparent linear-gradient(90deg, ${InternalTxnDetailBannerBgMapper(props.approval_status)[1]
              } 0%, ${InternalTxnDetailBannerBgMapper(props.approval_status)[0]
              } 100%) 0% 0% no-repeat padding-box`,
          }}
          className="internalTxnDetailsBannerRight w-1/3 p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16"
        >
          <div
            className="h-4/5
          text-white text-semibold
            text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl
            font-light capitalize antialiased truncate ..."
          >
            {accTransform(props.destination_bank_acc_no)}
          </div>
          <SimpleLogo bank={bankLogoMapper(props?.destination_bank_name)} />
        </Box>
      </Box>
      <Box id="txnDetailsBannerClientMb" className="txnDetailsBannerMobile">
        <Box className="internalTxnDetailsBannerMiddle">
          <Box
            sx={{ color: props.bgColor }}
            className="internalTxnDetailsBannerMiddleTop"
          >
            {" "}
            {VendorTxnTextMapper(props.approval_status)}
          </Box>
          <Box
            sx={{ color: props.bgColor }}
            className="internalTxnDetailsBannerMiddleBottom"
          >
            {formatCurrency(props.amount)}
          </Box>
          <Box sx={{ color: "#707070" }}>
            {" "}
            <p>
              {props.posting_date == undefined
                ? {}
                : formatdate(props.posting_date)}
            </p>
          </Box>
        </Box>
        <Box className="txnDetailsBannerClientMbBottom">
          <Box
            sx={{ background: props.bgColor }}
            className="internalTxnDetailsBannerLeft"
          >
            <Box className="internalTxnDetailsBannerLeftTop">
              {" "}
              {"**** " +
                props?.source_bank_acc_no?.substr(
                  props.source_bank_acc_no.length - 5
                )}
            </Box>
            <Box className="ClientTxnDetailsBannerLeftBottom">
              <img
                width={"104px"}
                height={"36px"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.img) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </Box>
          </Box>
          <Box
            sx={{ background: props.bgColor }}
            className="internalTxnDetailsBannerRight"
          >
            <Box className="internalTxnDetailsBannerLeftTop">
              {" "}
              {"**** " +
                props.destination_bank_acc_no?.substr(
                  props.destination_bank_acc_no.length - 5
                )}
            </Box>
            <Box className="ClientTxnDetailsBannerLeftBottom">
              <img
                width={"104px"}
                height={"36px"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.img) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TxnDetailsBanner;
