import React from 'react'
import next from '../../../../assets/Approve transactions/Next.png'

function CPCard(props) {
    return (
        <div className='bg-[#667CA3] cursor-pointer hover:opacity-70 w-72 p-6 flex flex-shrink flex-col justify-between items-center rounded-xl gap-16 shadow-xl'>
            <div className='flex flex-col items-center gap-4'>
                <div className='w-28 h-28 3xl:w-32 3xl:h-32 4xl:w-36 4xl:h-36 6xl:w-40 6xl:h-40'>
                    <img src={props.img} alt='logo' />
                </div>
                <div className='text-center text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-white tracking-wide'>{props.name}</div>
            </div>
            <div className='w-full flex items-end justify-between'>
                <div className='flex flex-col items-start'>
                    <div className='text-left text-white text-md'>{props.number}</div>
                    <div className='text-left text-[#C9C9C9] text-xs'>{props.label}</div>
                </div>
                <div>
                    <div className='w-5 h-5'>
                        <img className='opacity-40' src={next} alt='logo' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CPCard