import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Box } from "@mui/material";

import OwnerTransactionCardLogo from "../OwnerTransactionCardLogo";
import formatDateViewAll from "../../../../utils/formatDateViewAll";

const OwnerTransactionViewAllCard = ({ data, closeModal }) => {
  const navigate = useNavigate();
  const benealldata = data;

  useEffect(() => {}, [benealldata]);

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "blocked":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";

      case "queued":
        return "#FBC02D";
      default:
        return "#4ddd37";
    }
  };

  return (
    <div className="OwnerTransactionCard">
      {benealldata?.map((transactions, index) => (
        <Box
          key={index}
          className="multiSelectOwnerTransactionCard relative 5.6xl:w-[265px] 3.1xl:w-[264px]"
          onClick={() => {
            closeModal();
            navigate(
              `/transaction/beneficiary_transaction/details/${transactions.txn_id}`
            );
          }}
        >
          <div
            className="ownerTransaction_details"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "",
            }}
          >
            <div className="ownerTransaction_user">
              <div
                className="ownerTransaction_name_div"
                style={{
                  color: getStatusColor(transactions.lifecycle_status),
                }}
              >
                ₹ {Number(transactions.amount).toFixed(2)}
              </div>

              <div className="ownerTransaction_bank_acc_div">
                {transactions.beneficiary_account_name}
              </div>
              <div className="ownerTransaction_acc_title">
                {" "}
                {transactions.created_on
                  ? formatDateViewAll(transactions.created_on, false, true)
                  : "Not given"}
              </div>
            </div>
            <div className="ownerTransaction_label_div">
              <div
                className="ownerTransaction_box_status"
                style={{
                  backgroundColor: getStatusColor(
                    transactions.lifecycle_status
                  ),
                }}
              ></div>
              <div
                className={`ownerTransaction_assigned ${
                  transactions.lifecycle_status === "pending"
                    ? "ownerTransaction_assigned_pending_box"
                    : transactions.lifecycle_status === "approved"
                    ? "ownerTransaction_assigned_approved_box"
                    : transactions.lifecycle_status === "denied"
                    ? "ownerTransaction_assigned_denied_box"
                    : transactions.lifecycle_status === "blocked"
                    ? "ownerTransaction_assigned_blocked_box"
                    : transactions.lifecycle_status === "abandoned"
                    ? "ownerTransaction_assigned_abandoned_box"
                    : transactions.lifecycle_status === "settled"
                    ? "ownerTransaction_assigned_settled_box"
                    : transactions.lifecycle_status === "rejected"
                    ? "ownerTransaction_assigned_rejected_box"
                    : ""
                }`}
                // style={{ color: getStatusColor(transactions.lifecycle_status) }}
              >
                {transactions.lifecycle_status === "approved"
                  ? "Awaiting Verification"
                  : transactions.lifecycle_status === "rejected"
                  ? "Denied"
                  : transactions.lifecycle_status}
              </div>
            </div>
          </div>
          <div className="ownerTransaction_card_container absolute bottom-0 right-0 mb-0 flex flex-col justify-end ">
            {/* <img src="../../../assets/BankLogos/DCB bank.png" /> */}
            <OwnerTransactionCardLogo data={transactions} />
          </div>
        </Box>
      ))}
    </div>
  );
};

export default OwnerTransactionViewAllCard;
