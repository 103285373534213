import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import "./index.css";

export default function SettledUsingFilter(props) {
  const [value, setValue] = useState();
  const [settledUsing, setSettledUsing] = useState();
  const handleChange = (event, newValue) => {
    setSettledUsing(newValue);
  };
  return (
    <Box>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        onInputChange={(event, newValue) => handleChange(event, newValue)}
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={props.items.map((option) => option.title)}
        ListboxProps={{
          className: "autoCompleteListBox",
        }}
        renderInput={(params) => (
          <TextField
            id="filled-basic"
            variant="filled"
            {...params}
            label={props.label}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
          />
        )}
      />
      <Box className="settledUsingBox2">
        {settledUsing && (
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={props.items.map((option) => option.title)}
            ListboxProps={{
              className: "autoCompleteListBox",
            }}
            renderInput={(params) => (
              <TextField
                id="filled-basic"
                variant="filled"
                {...params}
                label={props.label}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        )}
      </Box>
    </Box>
  );
}
