import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;
// Action
export const getRoles = createAsyncThunk(
    "getRoles",
    async (values) => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                // "X-Api-Key": sessionStorage.getItem("q2p_token"),
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
        };

        return await fetch(
            `${baseUrl}/roles${values?.role_id ? "/" + values?.role_id : ""}?page_no=${values?.page_no || 1}&page_size=${values?.page_size || 15}&search_string=${values?.search_string || ""}`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);



const rolesSlice = createSlice({
    name: "roles",
    initialState: {
        isLoading: false,
        roles: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getRoles.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.isLoading = false;
            state.roles = action.payload;
        });
        builder.addCase(getRoles.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default rolesSlice.reducer;