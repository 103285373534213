import React from "react";
import "./index.css";
import formatCurrency from "../../../utils/CurrencyFormater";
import arrowIcon from "../../../assets/TransactionDashboardIcons/Internal arrow.png";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import { useNavigate } from "react-router-dom";
import { VendorTxnTextMapper } from "../../../services/VendorTxnTextMapper";
import { approvalStatusRGBA } from "../../../services/approvalStatus";
import CardLogo from "../../_utils/logo/CardLogo";
import formatDateViewAll from "../../../utils/formatDateViewAll";

const DatacardViewAllInternal = (props) => {
  const accTransform = (acc) => {
    function isNumeric(value) {
      return /^-?\d+$/.test(value);
    }
    if (isNumeric(acc)) {
      return `⋅⋅⋅⋅ ${acc.slice(-4)}`;
    } else {
      return acc;
    }
  };
  const cardStatusRGBA = approvalStatusRGBA(props.approvalStatus);
  const sourceAccount = accTransform(props.sourceAccount);
  const destinationAccount = accTransform(props.destinationAccount);
  const amountProp = formatCurrency(props.amount);
  const propStatus = props.status;
  const navigate = useNavigate();
  const transaction_id = props.transaction_id;
  let sourceBankLogo = bankLogoMapper(props.sourceBankName);
  let destinationBankLogo = bankLogoMapper(props.destinationBankName);
  return (
    <div className="w-full"
      style={{ textDecoration: "none" }}>
      <div className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-xl
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden">
        <div className="flex flex-row -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
          <div className="font-semibold
            w-3/5 text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased"
            style={{ color: propStatus }}>
            {`₹ ${amountProp}`}
          </div>
          <div className="w-2/5 text-sm flex flex-row gap-1
            justify-end">
            <div className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased group-hover:invisible group-hover:hidden">
              {formatDateViewAll(props.postingDate, false)}
            </div>
            <div className="flex flex-row justify-start">
              <div className="w-1.5 4xl:w-2"
                style={{ backgroundColor: propStatus }}>
              </div>
              <div className="flex group-hover:px-4"
                style={{ backgroundColor: cardStatusRGBA }}>
                <span className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                  style={{ color: propStatus }}>
                  {VendorTxnTextMapper(props.approvalStatus)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row2 flex flex-row justify-between text-left">
          <div className="text-white font-semibold
            w-3/5 text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased">
            {sourceAccount}

          </div>

          <img
            src={arrowIcon}
            className="w-5 h-5 3xl:w-5 3xl:h-5 4xl:h-7 4xl:w-7 5xl:h-9 5xl:w-9"
            alt="ArrowLogo"
            width="20px"
          />

          <div className="text-white font-semibold
            w-3/5 text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased text-right">
            {destinationAccount}
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="w-[40%]
              -ml-4 -mb-4
              4xl:-ml-6 4xl:-mb-6
              5xl:-ml-8 5xl:-mb-8
              6xl:-ml-10 6xl:-mb-10
              flex flex-col justify-end rounded-md">
            <CardLogo bank={sourceBankLogo}
              divCssProps={
                'rounded-tr-4xl 3xl:rounded-tr-large 5xl:rounded-tr-customXl'
              } />
          </div>
          <div className="w-[40%]
              -mr-4 -mb-4
              4xl:-mr-6 4xl:-mb-6
              5xl:-mr-8 5xl:-mb-8
              6xl:-mr-10 6xl:-mb-10
              flex flex-col justify-end">
            <CardLogo bank={destinationBankLogo} />
          </div>
        </div>
      </div>
    </div>

  );
};

export default DatacardViewAllInternal;


