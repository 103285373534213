import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const getTxnTimeline = createAsyncThunk("getTxnTimeline", async (values) => {
    const { txn_id } = values;
    var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        body: JSON.stringify({
            txn_id: txn_id
        }),
    };

    return await fetch(
        `${legacyUrl}/transactions/view_timeline_details`,
        requestOptions
    )
        .then((response) => {
            return response.json();
        })
        .catch((error) => console.log("error", error));
});

const txnTimelineSlice = createSlice({
    name: "txnTimeline",
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getTxnTimeline.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getTxnTimeline.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getTxnTimeline.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        });
    },
});

export default txnTimelineSlice.reducer;