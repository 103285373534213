import React, { useEffect, useState } from "react";
import "./RejectingTransactionPopup.css";
import xpentraloader from "../../../../../assets/Beneficiary Icons/lodemore.gif";
import dataLoading from "../../../../../assets/Beneficiary Icons/inputloder.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import OwnerTransactionViewAllCard from "../../../../../components/Accounts/OwnerAccounts/OwnerTransactionViewAllCard";
import {
  beneTxnMultiDeniedApproval,
  beneTxnViewAllAcc,
} from "../../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../../../redux/features/login/login";
import BeneViewAllCard from "../../BeneViewAllCard";
import PendingTransactionAllCards from "../PendingTransactionAllCards/PendingTransactionAllCards.jsx";
import { toast } from "react-toastify";

const RejectingTransactionPopup = (props) => {
  const [loading, setLoading] = useState(true);
  const [pendingTxn, setPendingTxn] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [showCancleBtn, setShowCancleBtn] = useState(true);

  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  const {
    beneTxnViewAllAccLoading,
    beneTxnViewAllAccData,
    beneTxnViewAllAccError,
  } = useSelector((state) => state?.beneTxnViewAccData);

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleRejectAllTransaction = async () => {
    setShowCancleBtn(false);
    setShowLoader(true);
    let AccountIdData = props.declineReason;

    let id =
      !beneTxnViewAllAccLoading &&
      beneTxnViewAllAccData?.transactions?.length > 0
        ? beneTxnViewAllAccData?.transactions.map((data) => {
            return data?.txn_id;
          })
        : [];

    dispatch(checkUsertoken());
    const data = await dispatch(
      beneTxnMultiDeniedApproval({ id, AccountIdData })
    );

    await new Promise((resolve) => setTimeout(resolve, 2000));

    if (data?.payload?.status === false) {
      const error_msg = data?.payload?.errors?.base[0]
        ? data?.payload?.errors?.base[0]
        : data?.payload?.errors?.base[0].errors[0];
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setShowCancleBtn(true);
      setShowLoader(false);
    } else if (data?.payload?.status) {
      toast.success("Transaction Rejected successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        props.handleConfirm(props.accountId, props.declineReason);
      }, 500);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (currentPage === 1) {
          setLoading(true);
        }
        let queryParams = {
          page_no: currentPage,
          page_item_count: pageSize,
          lifecycle_status: "pending",
          beneficiary_account_id: props?.accountId,
        };
        dispatch(checkUsertoken());
        const response = await dispatch(beneTxnViewAllAcc(queryParams));
        const newData = response?.payload?.transactions || [];
        setPendingTxn((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
  }, [dispatch, currentPage]);

  return (
    <div className="reject-txn-popup">
      <div className="reject-txn-popup-title">
        <h2 className="reject-txn-popup-title-head">
          Associated transactions found
        </h2>
        <div className="reject-txn-popup-title-subhead">
          The following transactions are linked to this account. To reject the
          account, please reject the transactions listed below by clicking the
          “Reject all” button. The transactions will get rejected with the
          reason 'Incorrect account number'.
        </div>
      </div>
      <div id="infinityScrollContainerAccountTransaction">
        {loading ? (
          <div className="loader-container m-auto justify-center items-center flex">
            <img src={dataLoading} alt="loading..." />
          </div>
        ) : pendingTxn?.length > 0 ? (
          <div className="scroll w-full flex flex-col items-center justify-center m-auto">
            <InfiniteScroll
              style={{ height: "inherit" }}
              dataLength={pendingTxn.length || []}
              next={fetchMoreData}
              hasMore={
                currentPage <
                Math.ceil(beneTxnViewAllAccData?.total_count / pageSize)
              }
              loader={
                <div className="loader-container m-auto justify-center items-center flex">
                  <img src={dataLoading} alt="loading..." />
                </div>
              }
              scrollableTarget="infinityScrollContainerAccountTransaction"
              scrollThreshold={0.3}
            >
              <PendingTransactionAllCards pendingTxn={pendingTxn} />
            </InfiniteScroll>
          </div>
        ) : null}
      </div>
      <div className="reject-txn-popup-btn-container">
        {showCancleBtn && (
          <button
            id="cancelBtn"
            className={`reject-txn-popup-cancleBtn`}
            onClick={() => props?.handleClosePopup()}
          >
            Cancel
          </button>
        )}
        {showLoader ? (
          <button className="expentraLoaderclass">
            <img src={xpentraloader} alt="Loading..." />
          </button>
        ) : (
          <button
            className="reject-txn-popup-confirmBtn"
            onClick={() => handleRejectAllTransaction()}
          >
            Reject all
          </button>
        )}
      </div>
    </div>
  );
};

export default RejectingTransactionPopup;
