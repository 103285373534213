import React, { useRef, useState } from "react";
import "./index.css";
import copyClipboard from "../../../assets/Settings/Dashboard/Devices/copyCipboard.png";

const DeviceTokenPopup = ({ onClose, token, isPopupOpen }) => {
  const [isCopied, setIsCopied] = useState(false);
  const tokenRef = useRef(null);

  const copyToClipboard = async (code) => {
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(code);
        console.log("Code copied to clipboard");
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      } catch (e) {
        console.error("Error while copying code", e);
      }
    }
  };

  const handleCopyClick = () => {
    const tokenText = tokenRef.current.innerText;
    copyToClipboard(tokenText);
  };

  const handleBackgroundClick = (e) => {
    if (e.target.className === "device-mainPopup") {
      onClose();
    }
  };

  return (
    <div className="device-mainPopup" onClick={handleBackgroundClick}>
      <div className="token-popup" onClick={(e) => e.stopPropagation()}>
        <div className="token-detail-text">
          <h5>Token Details</h5>
        </div>

        <div className=" api-box flex items-center gap-[20px]">
          <p ref={tokenRef} id="id-firstLetter">
            {token}
          </p>
          <div className="clipboard-img">
            <img
              src={copyClipboard}
              alt="copy clipboard"
              onClick={handleCopyClick}
            />
            {isCopied && <span className="copied-notification">Copied!</span>}
          </div>
        </div>

        <p>
          Please copy this token as it will not be shown again. <br />
          In case this token is misplaced, a new token must be generated.
        </p>
      </div>
    </div>
  );
};

export default DeviceTokenPopup;
