import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const baseUrl = env.REACT_APP_BASE_URL;
export const getAllUserFeatures = createAsyncThunk(
    "getAllUserFeatures",
    async () => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
        };

        return await fetch(
            `${baseUrl}/user_features`,
            requestOptions
        )
            .then((response) => {
                return response.json();
            })
            .catch((error) => console.log("error", error));
    }
);

const userFeaturesSlice = createSlice({
    name: "userFeatures",
    initialState: {
        isLoading: false,
        data: [],
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(getAllUserFeatures.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAllUserFeatures.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getAllUserFeatures.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    }
});

export default userFeaturesSlice.reducer;