import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action
export const getClientRefunds = createAsyncThunk("getClientRefunds", async (values) => {
    var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        body: JSON.stringify(values),
    };

    return await fetch(
        "https://11d9041a-6b0a-4f59-8d56-6686344d9f10.mock.pstmn.io/api/v1/transactions/client/refund/view",
        requestOptions
    )
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
});

const clientRefundsSlice = createSlice({
    name: "clientRefunds",
    initialState: {
        isLoading: false,
        limit: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getClientRefunds.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getClientRefunds.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getClientRefunds.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        });
    },
});

export default clientRefundsSlice.reducer;