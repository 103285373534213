import React from "react";
import TxnInfo from "../TxnInfo";
import "./index.css";

const PopOverContainer = (props) => {
  return (
    <div className="popup-container">
      <TxnInfo data={props.data} approval_status={props.approval_status} />
    </div>
  );
};

export default PopOverContainer;
