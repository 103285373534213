import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const getAllDevices = createAsyncThunk(
  "getAllDevices",
  async (values) => {
    const { page_no, page_size } = values;
    try {
      const response = await axios.get(`${baseUrl}/devices`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error, toast_position);
      console.error("Error", error);
      throw error;
    }
  }
);

export const getAPIToken = createAsyncThunk("getAPIToken", async (id) => {
  try {
    const response = await fetch(
      `${baseUrl}/devices/${id}/generate_api_token`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      }
    );
    return response.json();
  } catch (error) {
    toast.error(error, toast_position);
    console.error("Error", error);
    throw error;
  }
});

export const deleteDevice = createAsyncThunk("deleteDevice", async (id) => {
  try {
    const response = await fetch(`${baseUrl}/devices/${id}`, {
      method: "DELETE",
      headers: {
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
        "Content-Type": "application/json",
      },
    });
    return response.json();
  } catch (error) {
    toast.error(error, toast_position);
    console.error("Error", error);
    throw error;
  }
});

const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    allDevicesData: null,
    allDevicesDataLoading: null,
    allDevicesDataError: null,

    deleteDeviceStatus: null,
    deleteDeviceLoading: null,
    deleteDeviceError: null,
    
    deviceData: null,
    
    apiToken: null,
    apiTokenLoading: null,
    apiTokenError: null,
  },
  reducers: {
    setDeviceData: (state, action) => {
      state.deviceData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllDevices.pending, (state, action) => {
      state.allDevicesDataLoading = true;
    });
    builder.addCase(getAllDevices.fulfilled, (state, action) => {
      state.allDevicesDataLoading = false;
      state.allDevicesData = action.payload;
    });
    builder.addCase(getAllDevices.rejected, (state, action) => {
      state.allDevicesDataError = true;
      state.allDevicesData = null;
    });

    builder.addCase(deleteDevice.pending, (state, action) => {
      state.deleteDeviceLoading = true;
    });
    builder.addCase(deleteDevice.fulfilled, (state, action) => {
      state.deleteDeviceLoading = false;
      state.deleteDeviceStatus = action.payload;
    });
    builder.addCase(deleteDevice.rejected, (state, action) => {
      state.deleteDeviceError = true;
      state.deleteDeviceStatus = null;
    });

    builder.addCase(getAPIToken.pending, (state, action) => {
      state.apiTokenLoading = true;
    });
    builder.addCase(getAPIToken.fulfilled, (state, action) => {
      state.apiTokenLoading = false;
      state.apiToken = action.payload;
    });
    builder.addCase(getAPIToken.rejected, (state, action) => {
      state.apiTokenError = true;
      state.apiToken = null;
    });
  },
});

export const { setDeviceData } = devicesSlice.actions;
export default devicesSlice.reducer;
