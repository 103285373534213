import React from 'react'

const ArchiveAccountData = ({ data }) => {
    return (
        <div className='flex w-full p-10 rounded-4xl flex-col bg-[#453550] items-center gap-20'>
            <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl w-full
              text-white text-center antialiased truncate ...'>
                Archive account details
            </div>
            <div className='grid grid-cols-2 grid-flow-row-dense  gap-y-5 w-full justify-around' >
                <div className='flex flex-col gap-10' >
                    <div className='flex flex-col gap-4' >
                        <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl w-full
              text-[#C9C9C9] text-left antialiased truncate ...' >Vendor pan details</div>
                        <div className='flex flex-col gap-1'>
                            <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>{data?.pan_no}
                            </div>
                            <div className='text-gray-300 text-lg antialiased' >PAN</div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl w-full
              text-[#C9C9C9]  antialiased truncate ...' >Employee bank details</div>
                        <div className='flex flex-col'>
                            <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>{data?.bank_acc_no}
                            </div>
                            <div className='text-gray-300 text-lg antialiased' >Bank account number</div>
                        </div>
                        <div className='flex flex-col'>
                            <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>{data?.ifsc_code}
                            </div>
                            <div className='text-gray-300 text-lg antialiased' >IFSC code</div>
                        </div>
                    </div>
            </div>
                <div className='flex flex-col gap-4'>
                    <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl w-full
              text-[#C9C9C9] antialiased truncate ...' >Vendor contact details</div>
                    <div className='flex flex-col'>
                        <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>{data?.bank_acc_no}
                        </div>
                        <div className='text-gray-300 text-lg antialiased' >Vendor name - Using legal name</div>
                    </div>
                    <div className='flex flex-col'>
                        <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>{data?.contact_no}
                        </div>
                        <div className='text-gray-300 text-lg antialiased' >Contact number</div>
                    </div>
                    <div className='flex flex-col'>
                        <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>{data?.email_id}
                        </div>
                        <div className='text-gray-300 text-lg antialiased' >Email</div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ArchiveAccountData