import React from "react";
import Datacard from "../../components/Datacard";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import accTransform from "../../utils/accTransform";
import StartCreateYourTransaction from "../../components/StartCreateYourTransaction";
import { QuickActionsall } from "../../components/QuickActions";
import AccountSummaryInstaPay from "../../components/AccountSummaryInstaPay";
import NoDataImg from "../../assets/TransactionDashboardIcons/Nothing.png";
import colorCode from "../../utils/colorCodeMapper";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { approvalStatus } from "../../services/approvalStatus";
import { useEffect } from "react";
import { getInstantpayDashboard } from "../../redux/features/txnDashboard/txnDashboard";
import { getInstantpayViewAll } from "../../redux/features/txnViewAll/txnViewAll";
import { txnPathMapper } from "../../services/txnPathMapper";
import { txnPayloadMapper } from "../../services/txnPayloadMapper";
import { bankLogoMapper } from "../../services/bankLogoMapper";
import { getInstantPayBalance } from "../../redux/features/instantPayBalance/instantPayBalance";
import { instantpayDashboardChecks } from "../../services/instantpayDashboardChecks";
import RecentInstantPayTransactions from "../../components/RecentInstantPayTransactions";

const InstantPayRefill = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getInstantPayBalance());
    dispatch(getInstantpayDashboard());
    dispatch(getInstantpayViewAll());
  }, []);

  const services = useSelector((state) => state.refreshToken.services);
  const instantpayDasboardData = useSelector(
    (state) => state.txnDashBoard.instantpay
  );

  const loading = useSelector(
    (state) => state.txnDashBoard.isLoading
  );

  const instantpayViewAllData = useSelector(
    (state) => state.txnViewAll.instantpay
  );

  const instantAccountSummary = useSelector(
    (state) => state.instantPayBalance.balance
  );

  const dataCardDetails = instantpayDasboardData
    ? instantpayDashboardChecks(instantpayDasboardData, services)
    : null;

  const recentTransactionsData = instantpayViewAllData
    ? instantpayViewAllData["transactions"]?.slice(0, 12)
    : null;

  const accountSummary = instantAccountSummary
    ? instantAccountSummary.balance_details[0]
    : null;

  const getDataCardDetailsDiv = (dataCardDetails) => {
    return dataCardDetails ? (
      Object.entries(dataCardDetails).map(([key, value]) => {
        return (
          <Datacard
            key={key}
            color={colorCode[key]}
            text={key}
            number={value?.records}
            path={txnPathMapper("instant_pay_refill")[key] + "?approval_status=" + txnPayloadMapper[key]?.approval_status}
          />
        );
      })
    ) : (
      <>
        <Box sx={{ opacity: "0.5" }}>
          <Datacard
            color={"yellow"}
            text={"Processing transactions"}
            number={0}
          />
        </Box>
        <Box sx={{ opacity: "0.5" }}>
          <Datacard
            color={"green"}
            text={"Successful transactions"}
            number={0}
          />
        </Box>
      </>
    );
  }

  const getRecentTransactionsDiv = (recentTransactionsData) => {
    return recentTransactionsData.map((transaction) => {
      let card_type = transaction?.card_type?.toLowerCase() == "amex" ? "AmEx" : transaction?.card_type;
      return (
        <RecentInstantPayTransactions
          img={
            transaction?.settlement_type === "HDFC NEFT/RTGS"
              ? bankLogoMapper(transaction?.owner_bank_name)
              : transaction?.settlement_type === "Instant Pay"
                ? "Instant Pay"
                : transaction?.settlement_type === "Registered Card"
                  ? card_type || "Default Card"
                  : "Status - pending"
          }
          txn_initiator={transaction.settlement_type == undefined && transaction.payment_method == "Refill" &&
            transaction.approval_status == "Y" ? "Refill now" :
            (transaction.parent_txn_id != null ? "Converted" : transaction.settlement_type == "HDFC NEFT/RTGS") ? accTransform(transaction.owner_bank_acc_no) :
              (transaction.settlement_type == "Registered Card") ? transaction.card_type : ""}
          amount={transaction.amount}
          account={transaction.vendor_name}
          status={approvalStatus(transaction.approval_status)}
          transaction_id={transaction.txn_id}
        />
      );
    });
  }

  return (

    <div className="
    bg-custom-appBack min-h-screen max-h-full
      flex flex-col-reverse lg:flex-row vendor-txn overflow-y-scroll hide-scrollbar">
      <div className="md:bg-custom-appBackLight
          flex flex-wrap justify-center lg:justify-end  w-full
          lg:w-8/12">
        <div className="
          p-4 lg:mt-24
          md:pr-8 md:py-8
          lg:pr-10 lg:py-10
          xl:pr-12 xl:py-12
          4xl:pr-24 4xl:py-24
          w-full lg:w-11/12 flex flex-col
          gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-16 6xl:gap-24">

          <div className="
             grid gap-6 4xl:gap-16 grid-cols-2
            lg:grid-cols-4
            2xl:grid-cols-5
            4xl:grid-cols-6
            5xl:grid-cols-7
            justify-items-center">
            {getDataCardDetailsDiv(dataCardDetails)}
          </div>

          {
            loading && <div className="w-full m-auto justify-center items-center flex" >
              <img src={require("../../assets/Approve transactions/inputloder.gif")} alt="" />
            </div>
          }
          {!loading &&

            <div className="p-2 flex flex-col h-full">
              {recentTransactionsData ? (
                <>
                  <div className="px-4 flex flex-row justify-between">
                    <span className="text-white
                    text-xl
                    3xl:text-2xl
                    4xl:text-3xl
                    5xl:text-4xl">
                      Recent
                    </span>
                    <span className="cursor-pointer
                    text-xl
                    3xl:text-2xl
                    4xl:text-3xl
                    5xl:text-4xl">
                      <p onClick={() => navigate("/instant_pay_refill/view_all_transactions")} className="underline text-custom-orange">View All</p>
                    </span>
                  </div>

                  <div className="pb-6 3xl:pb-8 5xl:pb-10
                  mt-5 w-full
                  gap-y-4 4xl:gap-y-8
                  grid grid-cols-1
                  md:grid-cols-2
                  3xl:grid-cols-3
                  md:gap-x-8
                  lg:gap-x-10
                  justify-items-center">
                    {getRecentTransactionsDiv(recentTransactionsData)}
                  </div>
                </>
              ) : (

                <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
                  <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                    No transactions found <br />
                    All InstantPay refill transactions will appear here.
                  </div>
                  <div className="w-2/6 p-4 flex flex-row justify-center">
                    <img
                      className="h-full w-full"
                      src={NoDataImg} alt="NoDataImg" />
                  </div>
                </div>

              )}
            </div>
          }
        </div>
        <div className="p-2 visible xl:p-0 xl:w-0 xl:h-0 xl:invisible">
          <QuickActionsall />
        </div>
      </div>
      <div className="
          h-full lg:fixed lg:right-0
          p-4
          md:pl-8 md:py-8
          lg:pl-10 lg:py-10
          xl:pl-12 xl:py-12
          4xl:pl-24 4xl:py-24
          flex flex-col bg-custom-appBack
          w-full lg:w-4/12 overflow-y-scroll overflow-x-hidden md:overflow-y-auto hide-scrollbar">
        <div className="mt-24 w-full lg:w-11/12
            flex flex-col
            gap-10 2xl:gap-12 3xl:gap-16 4xl:gap-20
            justify-items-center">
          <div>
            <AccountSummaryInstaPay
              minBalance={accountSummary ? +accountSummary.minimum_balance : 0}
              availbal={accountSummary ? +accountSummary.current_balance : 0}
              processingAmt={
                accountSummary ? +accountSummary.pending_balance : 0
              }
            />
          </div>
          <div className="">
            <StartCreateYourTransaction
              create_path="/create_transaction/instantpay_transactions"
              viewall_path="/instant_pay_refill/view_all_transactions"
            />
          </div>
          <div className="invisible xl:visible">
            <QuickActionsall />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantPayRefill;

