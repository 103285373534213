import React from "react";

const LineWithImage = ({ imageUrl }) => {

  const dashedLine = "repeating-linear-gradient(to right,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)";

  return (
    <div className="flex py-4 w-full items-center justify-center">
      <div style={{ background: dashedLine }} className="h-0.5 flex-grow" />
      <div className="px-2" >
        <img className="w-8 bg-[repeating-linear-gradient(to right,transparent,transparent 10px,#CBCBCB 10px, #CBCBCB 20px)]" src={imageUrl} alt="" />
      </div>
      <div className="text-xl px-2 opacity-70" >Verified Details</div>
      <div style={{ background: dashedLine }} className="h-0.5 flex-grow" />
    </div>
  );
};

export default LineWithImage;
