import React from "react";
import VerficationSearch from "../../../../components/Settings/Verification/VerificationSearch";
import VerificationDetails from "../../../../components/Settings/Verification/VerificationDetails";
import { verificationDetailsMapper } from "../../../../services/Settings/Verification/verificationDetailsMapper";
import CorpAddress from "../../../../components/Settings/Verification/CorpAddress";
import CPSection from "../../../../components/Settings/Verification/CPSection";
import CPCard from "../../../../components/Settings/Verification/CPCard";
import logo from "../../../../assets/Settings/Verification/Group 1437.png";
import { useSelector } from "react-redux";
import { verifyThisCIN } from "../../../../redux/features/verifyPAN/verifyPAN";

const CinVerification = () => {
  const verifiedCIN = useSelector((state) => state.verify.verifyCIN);
  return (
    <div className="mt-24 w-screen">
      <div className="fixed w-4/5 right-0 left-0 m-auto">
        <VerficationSearch
          placeholder={"Enter CIN number"}
          dispatchFn={verifyThisCIN}
        />
      </div>
      {verifiedCIN?.status ? (
        <>
          <div className="flex h-screen justify-center pt-40 mx-auto w-4/6">
            <VerificationDetails
              data={verifiedCIN?.cin_details}
              template={verificationDetailsMapper["CIN Verification"]}
            />
          </div>
          <div className="flex items-center mx-auto w-full min-h-screen  bg-[#29487E] shadow-lg">
            <CPSection title={"Directors"} subtext={""}>
              {verifiedCIN?.cin_details?.directors.map((director, index) => {
                return (
                  <CPCard
                    img={logo}
                    name={director?.director_name}
                    number={director?.din_number}
                    label={"CIN Number"}
                  />
                );
              })}
            </CPSection>
          </div>
          <div className="flex h-screen justify-center mx-auto mt-20 w-4/6">
            <CorpAddress address={verifiedCIN?.cin_details?.company_address} />
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-10 h-screen items-center pt-40 mx-auto w-2/6">
          <div className="flex">
            <img
              src={require("../../../../assets/Settings/Verification/no_data.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div className="text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-center w-full text-white antialiased">
            Enter CIN number in the search bar, to fetch the verified corporate
            profile details.
          </div>
        </div>
      )}
    </div>
  );
};

export default CinVerification;
