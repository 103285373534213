import React from "react";

import {
  AccountVendorDetailsBannerComponent,
  AccountVendorDetailsBodyComponent
} from './Strategy/vendorStrategy';

import {
  AccountFieldForceDetailsBannerComponent,
  AccountFieldForceDetailsBodyComponent
} from './Strategy/fieldForceStrategy';

import {
  AccountClientDetailsBannerComponent,
  AccountClientDetailsBodyComponent
} from './Strategy/clientStrategy';

import {
  AccountOwnerDetailsBannerComponent,
  AccountOwnerDetailsBodyComponent
} from './Strategy/ownerStrategy';

const StrategyContext = (data) => {
  let accountType = data.accountType;
  let componentType = data.componentType;
  let accountData = data.accountData;

  let ComponentToRender;

  // strategy map
  const strategyMap = {
    "vendor": {
      "accountBannerComponent": () => {
        ComponentToRender = AccountVendorDetailsBannerComponent
      },
      "accountBodyComponent": () => {
        ComponentToRender = AccountVendorDetailsBodyComponent
      }
    },
    "client": {
      "accountBannerComponent": () => {
        ComponentToRender = AccountClientDetailsBannerComponent
      },
      "accountBodyComponent": () => {
        ComponentToRender = AccountClientDetailsBodyComponent
      }
    },
    "owner": {
      "accountBannerComponent": () => {
        ComponentToRender = AccountOwnerDetailsBannerComponent
      },
      "accountBodyComponent": () => {
        ComponentToRender = AccountOwnerDetailsBodyComponent
      }
    },
    "field_force": {
      "accountBannerComponent": () => {
        ComponentToRender = AccountFieldForceDetailsBannerComponent
      },
      "accountBodyComponent": () => {
        ComponentToRender = AccountFieldForceDetailsBodyComponent
      }
    }
  }

  strategyMap[accountType][componentType]();
  return (
    <ComponentToRender
      accountData={accountData}
    />
  )
}

export default StrategyContext;
