import { Box } from "@mui/material";
import React from "react";
import TxnStatusCard from "../../../../components/CreateTxn/CreateVendorTxn/TxnStatusCard";
import "./index.css";

const TransactionStatus = (props) => {
  return (
    <Box
      className="flex absolute xl:fixed top-0 left-0 right-0 bottom-0  -mt-[10] flex-col xl:flex-row gap-12
      min-w-screen min-h-screen justify-center pt-24 xl:h-screen items-center bg-[#6BA673]"
    >
      <Box className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 p-6 flex flex-col items-center justify-center gap-24 text-center text-white text-2xl">
        <img
          src={require("../../../../assets/Approve transactions/Success screen.png")}
          width={"70%"}
          alt=""
          srcSet=""
        />
        <h3>
          Transaction{" "}
          <p style={{ textTransform: "lowercase" }}>{props.txn_status}</p>
        </h3>
      </Box>
      <Box className="w-4/5 flex justify-start h-[750px] xl:w-2/5 xl:h-[90%] p-0 text-center">
        <TxnStatusCard
          approval_status={props.approval_status}
          type={props.type}
          name={props.vendor_name}
          account={props.account}
          amount={props.amount}
          ifsc={props.ifsc}
          bank_name={props.bank_name}
          txn_id={props.txn_id}
        />
      </Box>
    </Box>
  );
};

export default TransactionStatus;
