import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";

const baseUrl = env.REACT_APP_BASE_URL;
export const getHolidays = createAsyncThunk("getHolidays", async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
  };

  return await fetch(`${baseUrl}/holidays`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error", error);
    });
});

const date = createSlice({
  name: "date",
  initialState: {
    holiday_list: null,
    isError: false,
    isLoading: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(getHolidays.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getHolidays.fulfilled, (state, action) => {
      state.isLoading = false;
      state.holiday_list = action.payload;
    });
    builder.addCase(getHolidays.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default date.reducer;
