import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "@fontsource/open-sans";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

function AppBar() {
  return (
    <div className="App">
      <div>
        <ResponsiveAppBar />
      </div>
      <Outlet />
    </div>
  );
}

export default AppBar;
