import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import pci from "../../assets/Landing/pci.png";
import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";
import "../../assets/Landing/css/owl.carousel.css";

const PrivacyPage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const workSectionRef = useRef(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [isMenuActive, setIsMenuActive] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        setIsMenuActive(true);
      } else {
        setIsMenuActive(false);
      }
    };

    if (window.innerWidth > 991 && isMenuActive) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landingbody">
      <div id="preloder">
        <div className="loader"></div>
      </div>
      <header
        className={`header-section ${isSticky ? "sticky slideInDown" : ""}`}
      >
        <div className="header-content">
          <div className="landingcontainer">
            <a
              onClick={() => navigate("/")}
              className="site-logo"
              style={{ cursor: "pointer" }}
            >
              <img className="landingimg" src={xpentraLogo} alt="xpentraLogo" />
            </a>
            <div className="responsive-bar" onClick={toggleMenu}>
              <i className="fa fa-bars"></i>
            </div>
            <a href="" className="user">
              <i className="fa fa-user"></i>
            </a>
            <button
              className="site-btn sb-gradients sbg-line"
              id="loginbtn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            {isMenuActive && (
              <nav className="main-menu">
                <ul className="menu-list">
                  <li onClick={() => navigate("/")}>
                    <a>Home</a>
                  </li>
                  <li onClick={() => navigate("/featruepage")}>
                    <a>Features</a>
                  </li>
                  <li onClick={() => navigate("/howitwork")}>
                    <a>How it works</a>
                  </li>
                  <li onClick={() => navigate("/aboutus")}>
                    <a>About</a>
                  </li>
                  <li onClick={() => navigate("/contact")}>
                    <a>Contact</a>
                  </li>
                  <li onClick={() => navigate("/faq")}>
                    <a>FAQ</a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>

      <main>
        <section className="inner-section gradient gradient-primary-auxiliary">
          <div className="shapes-container">
            <div className="static-shape ghost-shape ghost-shape-1"></div>
            <div className="static-shape pattern-dots-1"></div>
            <div className="static-shape pattern-dots-2"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="landinginner-text">
                  <h2>Privacy Policy</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-section boxshadow spad">
          <div className="shapes-container">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
          </div>
          <div className="landingcontainer">
            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-12"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-bodyd">
                      This Privacy Policy covers our treatment of personally
                      identifiable information collected from our users who use
                      or may want to use our payment services, as well as
                      consumer information that we acquire in the course of our
                      business. This Policy also covers the treatment of
                      personally identifiable information that our back end
                      technology providers / partners may share with it.
                      <br />
                      <br />
                      We respect your privacy and want to promote trust and
                      confidence on the internet. As such, we feel it is
                      necessary to disclose to you what information this site
                      collects and tracks and what we do with the information
                      that we collect.
                      <br />
                      <br />
                      Information security is critical to our business. We work
                      to protect the security of your information during
                      transmission by using Secure Sockets Layer (SSL) software,
                      which encrypts information you input. We store information
                      gathered on secure computers. The number of employees
                      involved in the management of the data centre that have
                      physical access to these computers is limited. We use
                      advanced security technology to prevent our computers from
                      being accessed by unauthorized persons. It is important
                      for you (the user) to protect against unauthorized access
                      to your Login ID/password and to your computer. Be sure to
                      protect the password used to access our services.
                      <br />
                      <br />
                      Currently, we collect personal information of our users,
                      through the user registration process, the service
                      agreement that we execute and various submitted documents
                      of the user's creditability as required by us from time to
                      time. We may ask users to enter personal data when placing
                      an request, such as name, address, phone number, credit
                      card/ banking details and E-mail address. We use this data
                      to protect the user and ourselves against risk. Some of
                      our web pages use "cookies" so that we can better serve
                      you with customized information and in some cases protect
                      ourselves and the sub-merchant from the risk of fraud.
                      Cookies are identifiers which a web site can send to your
                      browser to keep on your computer to facilitate your next
                      visit to our site. You can set your browser to notify you
                      when you are sent a cookie, giving you the option to
                      decide whether or not to accept it. The information we
                      collect and analyze is used to improve our service to you.
                      <br />
                      <br />
                      We also track information like the domain name from which
                      you contact us, the pages you request, the products you
                      buy, the referring site, and the time that you spend on
                      the site. For everyone who visits our site, we log IP
                      addresses, the type of operating system that your computer
                      uses, and the type of browser software used by you. From
                      the IP address, we can determine the Internet Service
                      Provider and the geographic location of your point of
                      connectivity. This is done to protect ourselves and the
                      user from fraud risk.
                      <br />
                      <br />
                      If you are our user then the personal information you
                      provide - name, address, etc.-allows us to inform you
                      about updates, or changes to the service and to notify you
                      of products and services of Xpentra, its backend service
                      providers and affiliates that we believe may be of
                      interest to you.
                      <br />
                      <br />
                      We use reasonable precautions to keep the personal
                      information disclosed to us secure and do not disclose
                      this information to other individuals or organizations
                      unless required by law. However we will exchange
                      information with other companies and organizations for the
                      sole purposes of fraud protection and risk reduction.
                      However, in the case of abuse or breach of security, we
                      are not responsible for any breach of security or for any
                      actions of any third parties which receive the information
                      illegally. We will not distribute customer information to
                      be used in mailing lists, surveys, or any other purpose
                      other than what is required to perform our services.
                      However we will send personally identifiable information
                      about you (the user) to other companies or people when:
                      <br />
                      <br />
                      <ol style={{ listStyle: "conic-gradient" }}>
                        <li style={{ margin: "0px 15px" }}>
                          We have your consent to share the information;
                        </li>
                        <li style={{ margin: "0px 15px" }}>
                          We need to share your information to provide the
                          product or service you have requested;
                        </li>
                        <li style={{ margin: "0px 15px" }}>
                          We need to send the information to companies who work
                          on behalf of us to provide a product or service to you
                          (unless we tell youdifferently these companies do not
                          have any right to use the personally identifiable
                          information we provide them beyond what is necessary
                          to assist us).
                        </li>
                      </ol>
                      <br />
                      <br />
                      Personally identifiable consumer information is also
                      shared with third parties (such as our Acquiring banks and
                      credit card processors) to the extent necessary for us to
                      deliver payment processing services. We are committed to
                      safeguarding the privacy of our customers' personal data.
                      <br />
                      <br />
                      If you (our user) would like to view the information we
                      have collected from you and your customers or you want to
                      correct your information or the details of your customers
                      or their IP addresses, please go to Merchant Login page
                      and log into your account to view the above details and to
                      review your personal information.
                      <br />
                      <br />
                      We respond to government and/ or legal demands/orders
                      regarding information that is contained within our access
                      logs concerning user(s). We reserve the right to share
                      such information concerning to any user who we believe are
                      in violation of our content guidelines, even without a
                      government and/ or legal demands/orders.
                      <br />
                      <br />
                      We also reserve the right to report any illegal activity
                      by our users or their vendors to law enforcement for
                      prosecution. Please be aware that we do not control the
                      acts of our users, customers or visitors.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>

      <footer className="footer-section footer">
        <div className="landingcontainer">
          <div className="landingrow spad">
            <div className="coll-md-5 footer-widget">
              <p>
                Payments should be Quick, Secure and Quintessentially yours.
              </p>
              <span>
                &copy; Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                rights reserved
              </span>
              <br />
              <br />
              <img
                src={pci}
                className="mb-4 landingimg"
                alt=""
                style={{ width: "100px", marginRight: "20px", float: "left" }}
              />
              <a
                id="fC4seLa5xl"
                href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                target="_blank"
              >
                <img
                  className="landingimg"
                  src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                  alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                  style={{ width: "50px", float: "left" }}
                />
              </a>
            </div>
            <div className="coll-md-4 footer-widget">
              <h5 className="widget-title">Quick Links</h5>
              <div className="landingrow">
                <div className="coll-md-5 footer-widget">
                  <ul>
                    <li className="actived" onClick={() => navigate("/")}>
                      <a>Home</a>
                    </li>
                    <li onClick={() => navigate("/featruepage")}>
                      <a>Features</a>
                    </li>
                    <li onClick={() => navigate("/howitwork")}>
                      <a>How it works</a>
                    </li>
                    <li onClick={() => navigate("/aboutus")}>
                      <a>About</a>
                    </li>
                    <li onClick={() => navigate("/contact")}>
                      <a>Contact</a>
                    </li>
                    <li onClick={() => navigate("/faq")}>
                      <a>FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="coll-md-7 footer-widget">
                  <ul>
                    <li onClick={() => navigate("/privacy")}>
                      <a>Privacy Policy</a>
                    </li>
                    <li onClick={() => navigate("/terms")}>
                      <a>Terms of Use</a>
                    </li>
                    <li onClick={() => navigate("/cancellation")}>
                      <a>Cancellation Policy</a>
                    </li>
                    <li onClick={() => navigate("/refund")}>
                      <a>Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coll-md-3 footer-widget">
              <h5 className="widget-title">Supported By</h5>
              <div className="visacard"></div>
              <div className="mastercard"></div>
              <div className="dinerscard"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPage;
