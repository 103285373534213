import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { Link } from "react-router-dom";
import AccountClientViewAllCard from "../../../../components/Accounts/ClientAccount/ViewAllCard";

const AccountClientViewAllBody = ({ viewAllData }) => {
  const emptyAccountListComponent = () => {
    return (
      <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
        <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
          No accounts found <br />
          All client accounts will appear here.
        </div>
        <div className="w-2/6 p-4 flex flex-row justify-center">
          <img
            className="h-full w-full"
            src={NoDataImg} alt="NoDataImg" />
        </div>
      </div>
    );
  }

  const accountViewAllComponents = () => {
    if (Object.keys(viewAllData).length === 0) {
      return emptyAccountListComponent();
    } else {
      return (
        <div className="
          grid gap-4 lg:gap-6 4xl:gap-16
          grid-cols-1
          md:grid-cols-2
          xl:grid-cols-4
          4xl:grid-cols-5
          6xl:grid-cols-6
          justify-items-center">
          {
            viewAllData.map((account) => (
              <div className="w-full min-h-full">
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    window.location.href = `/accounts/client/details/${account.client_id}`;
                  }}
                >
                  <AccountClientViewAllCard
                    name={account.name}
                    mobile={account.contact_no}
                    bank_name={account.bank_name}
                    approval_status={account.approval_status}
                  />
                </Link>
              </div>
            ))
          }
        </div>
      );
    }
  }

  return accountViewAllComponents();
}

export { AccountClientViewAllBody };

