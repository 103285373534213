const formatDateViewAll = (timestamp, need_time, need_day) => {
  const date = new Date(timestamp * 1000);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  let day = "";
  if (need_day) {
    day = weekdays[date.getDay()]; // Get day abbreviation if needed
  }
  const dayNumber = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  let hours = date.getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours || 12;
  const minutes = date.getMinutes();
  let formattedDate = "";

  // Format date based on the need_time parameter
  if (!need_time || need_time === undefined) {
    if (need_day) {
      formattedDate = `${day}, ${dayNumber} ${month} ${year}`;
    } else {
      formattedDate = `${dayNumber} ${month} ${year}`;
    }
  } else {
    formattedDate = `${day}, ${dayNumber} ${month} ${year}, ${hours}:${minutes < 10 ? "0" + minutes : minutes} ${ampm}`;
  }
  
  return formattedDate;
};

export default formatDateViewAll;
