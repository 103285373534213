import React from "react";
import { useState, useRef } from "react";
import { Box, Checkbox } from "@mui/material";
import formatCurrency from "../../../../utils/CurrencyFormater";
import trimSentence from "../../../../utils/trimSentence";
import CardLogo from "../../../_utils/logo/CardLogo";

const FFAccountApprovalMultiSelectVendorCard = (props) => {
  const accTransform = (acc) => {
    function isNumeric(value) {
      return /^-?\d+$/.test(value);
    }
    if (isNumeric(acc)) {
      return `⋅⋅⋅⋅ ${acc.slice(-4)}`;
    } else {
      return acc;
    }
  };
  const propStatus = props.status;
  const handleRadioBTClick = props.handleRadioBTClick;
  const datum = props.datum;

  const handleCardClick = () => {
    let value = props.account_id;
    let checked = !props.selectedTxn[props.account_id] ? true : false;
    handleRadioBTClick(null, value, checked, datum);
  };

  return (
    <>
      <div
        className={`py-1 px-2 2xl:py-2 2xl:px-4 4xl:py-4 4xl:px-6 flex flex-col justify-between
          gap-4 overflow-hidden
          flex-grow rounded-3xl 4xl:rounded-4xl shadow-customCard cursor-pointer
          ${props?.parent === "approvalTxns"
            ? props?.selectedTxn[props.account_id]
              ? "bg-custom-selectedCard"
              : "bg-custom-notSelectedCardApproval"
            : props?.selectedTxn[props.account_id]
              ? "bg-custom-selectedCard"
              : "bg-custom-notSelectedCardSettlement"
          }`}
        onClick={handleCardClick}
        key={props.account_id}
      >
        <div className="flex justify-between items-center my-1 mx-2">
          <div
            className="w-3/5 truncate ...
             text-white
            text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased"
            style={{ color: propStatus }}
          >
            <span className=" truncate ...">{props.vendor_name}</span>
          </div>
          <div
            className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased"
          >
            {props.posting_date.split(" ")[0]}
          </div>
        </div>

        <div className="flex semi-bold justify-between items-center my-1 mx-2">
          <div
            className="text-white visible self-center
              text-md 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased align-right"
          >
            {props.account}
            <br />
            <span
              className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased"
            >
              Account number
            </span>
          </div>
          <div
            className="text-white visible self-center
              text-md 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased text-right"
          >
            {props.ifsc}
            <br />
            <span
              className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased"
            >
              IFSC code
            </span>
          </div>
        </div>

        <div className="flex justify-between items-center my-1 mx-2">
          <div
            className="w-[35%]
            -ml-4 -mb-2 2xl:-ml-6 2xl:-mb-3 4xl:-ml-8 4xl:-mb-5
            flex flex-col justify-end"
          >
            <CardLogo bank={props?.bank_logo} divCssProps={"rounded-tr-4xl"} />
          </div>
          <div>
            {props.selectedTxn[props.account_id] ? (
              <img
                className="w-6 h-6"
                src={require("../../../../assets/Approve transactions/selected.png")}
                alt=""
              />
            ) : (
              <img
                className="w-6 h-6"
                src={require("../../../../assets/Approve transactions/not selected.png")}
                alt=""
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FFAccountApprovalMultiSelectVendorCard;
