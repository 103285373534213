import React, { useEffect, useState } from "react";
import CustomizedStepper from "../../../../components/CreateTxn/CustomizedStepper";
import { useDispatch, useSelector } from "react-redux";
import EntityPanDetails from "../../../../components/Settings/Management/Entities/CreateEntity/EntityPanDetails";
import EnterBankDetails from "../../../../components/Accounts/Common/EnterBankDetails";
import { createAcc } from "../../../../redux/features/createClientAcc/createClientAcc";
import LinkUserFFAcc from "../../../../components/Accounts/FieldForceAccount/LinkUser";
import SelectExistingVendor from "../../../../components/CreateFFTxnForm/SelectExistingVendor";
import AccCreateStatus from "../../CreateResponse";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const CreateFFAccount = () => {
  const dispatch = useDispatch();
  const [showStatus, setShowStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdAcc = useSelector(
    (state) => state.createAllAcc.createAccResponse
  );
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const linkUser = useSelector((state) => state.bufferState.linkUser);
  const selectedAcc = useSelector((state) => state.bufferState.vendor);
  const limitApiRes = useSelector((state) => state.ffLimits.limit);
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState({
    entity_pan: "",
    entity_name: "",
    ifsc_code: "",
    bank_acc_no: "",
    contact_no: "",
    email_id: "",
    name: "",
    txn_limit: "",
    gstin: "",
  });

  const steps = [
    "Enter PAN details",
    "Enter bank details",
    "Link user and set transaction limit",
    "Confirm and create account",
  ];

  const handleOpen = (type) => {
    if (type) setOpen(type);
    else {
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  const handleNext = () => {
    if (activeStep === 3) {
      dispatch(createAcc({ type_of_txn: "field_force", payload: payload }));
      return;
    }
    if (activeStep === 2) {
      setPayload({
        ...payload,
        user_id: linkUser.user_id,
        contact_no: linkUser?.mob_no,
        email_id: linkUser?.email_id,
      });
      if (payload.txn_limit === undefined)
        setPayload({
          ...payload,
          txn_limit: limitApiRes?.ammount_limit?.maximum,
        });
    }
    if (activeStep === 1) {
      setPayload({
        ...payload,
        name: payload.entity_name,
        pan_no: payload.entity_pan,
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    enableNextButton(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (createdAcc?.status) setShowStatus(true);
  }, [createdAcc]);

  return showStatus ? (
    <AccCreateStatus
      title="Agent created successfully!!"
      data={{
        title: createdAcc.accounts[0]?.name,
        body: [
          {
            value: createdAcc.accounts[0]?.bank_acc_no,
            subtitle: "Account Number",
          },
          {
            value: createdAcc.accounts[0]?.ifsc_code,
            subtitle: "IFSC Code",
            img:
              "/assets/Banks/" +
              bankLogoMapper(createdAcc.accounts[0]?.bank_name) +
              ".png",
          },
          {
            value: createdAcc.accounts[0]?.contact_no,
            subtitle: "Phone",
            img: "/assets/CreateTransactionIcons/whatsapp.png",
          },
          {
            value: createdAcc.accounts[0]?.email_id,
            subtitle: "Email ID",
            img: "/assets/CreateTransactionIcons/gmail.png",
          },
        ],
        links: [
          {
            title: "Create new account",
            link: 0,
            icon: "/assets/CreateTransactionIcons/Create new account.png",
          },
          {
            title: "Create transaction",
            link: "/create_transaction/vendor_transaction",
            icon: "/assets/CreateTransactionIcons/Create transaction.png",
          },
          {
            title: "View account details",
            link:
              "/accounts/field_force/details/" +
              createdAcc.accounts[0].account_id,
            icon: "/assets/CreateTransactionIcons/View details.png",
          },
          {
            title: "Go to dashboard",
            link: -1,
            icon: "/assets/CreateTransactionIcons/Dashboard.png",
          },
        ],
      }}
    />
  ) : (
    <div className="bg-[#744991] flex flex-row justify-center overflow-y-auto overflow-x-hidden w-full hide-scrollbar vendor-txn min-h-screen">
      <div className="pt-24 self-center h-screen w-10/12">
        <div className="h-fit flex flex-col gap-2 3xl:gap-4 5xl:gap-6 py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20">
          <div className="h-[15%] flex flex-row justify-center">
            <CustomizedStepper
              nextButtonEnabled={nextButtonEnabled}
              activeStep={activeStep}
              steps={steps}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </div>
          <div className="h-4/5 flex flex-row justify-center pb-24">
            <div className="w-10/12 h-full">
              {activeStep === 0 && (
                <div className="flex justify-between gap-8 p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EntityPanDetails
                      title="Agent PAN details"
                      bgColor="bg-[#9777AC]"
                      registerNewEntityData={payload}
                      setRegisterNewEntityData={setPayload}
                      enableNextButton={enableNextButton}
                    />
                  </div>
                  <div className="w-1/2 h-fit"></div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="flex flex-row justify-start p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EnterBankDetails
                      payload={payload}
                      setPayload={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep}
                      title="Agent bank details"
                    />
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <>
                  <div className="flex flex-row justify-end p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <LinkUserFFAcc
                        registerNewVnerdorData={payload}
                        setRegisterNewVnerdorData={setPayload}
                        activeStep={activeStep}
                        editable={true}
                        enableNextButton={enableNextButton}
                        handleOpen={handleOpen}
                        linkUser={linkUser}
                      />
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <div className="flex flex-row justify-between p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-[49%] flex flex-col justify-between gap-8 h-fit">
                    <EntityPanDetails
                      title="Agent PAN details"
                      bgColor="bg-[#9777AC]"
                      registerNewEntityData={payload}
                      setRegisterNewEntityData={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep - 1}
                    />
                    <EnterBankDetails
                      payload={payload}
                      setPayload={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep}
                      title="Agent bank details"
                    />
                  </div>
                  <div className="w-[49%]">
                    <LinkUserFFAcc
                      registerNewVnerdorData={payload}
                      setRegisterNewVnerdorData={setPayload}
                      activeStep={activeStep}
                      editable={false}
                      enableNextButton={enableNextButton}
                      handleOpen={handleOpen}
                      linkUser={linkUser}
                    />
                  </div>
                </div>
              )}
              {open ? (
                <>
                  <SelectExistingVendor
                    type={open}
                    enableNextButton={enableNextButton}
                    handleClose={handleClose}
                    linkUser={linkUser}
                    selectedAcc={selectedAcc}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFFAccount;
