import { Drawer } from '@mui/material';
import CloseIcon from '../../../../../assets/ViewAllTransactions/Clear filter.png'

function SideBar({ children, title, state, setState, bgColor }) {

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState(open);
    };


    return (
        <Drawer
            anchor={"right"}
            open={state}
            onClose={toggleDrawer(false)}
            sx={{ zIndex: 100 }}
        >
            <div
                className={`w-fit  ${bgColor?.body ? bgColor?.body : 'bg-[#1D3A6D]'} overflow-hidden h-screen`}
            >
                <div className={`${bgColor?.title ? bgColor?.title : 'bg-[#314D7E]'} flex justify-between items-center py-4 px-8 pt-36 shadow-lg`}>
                    {title}
                    <div
                        className={`cursor-pointer w-7 h-7 p-1 ${bgColor?.body ? bgColor?.body : 'bg-[#1D3A6D]'} flex justify-center items-center rounded-md`}
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}>
                        <img src={CloseIcon} />
                    </div>
                </div>
                {children}
            </div>
        </Drawer>
    );
}

export default SideBar