import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import googlepay from "../../assets/Landing/Google_play.png";
import dashbord from "../../assets/Landing/dashbord.png";
import mobiledashboard from "../../assets/Landing/mobiledashboard.png";
import App from "../../assets/Landing/App.png";
import developer from "../../assets/Landing/developer1.png";
import encryption1 from "../../assets/Landing/encryption1.png";
import wiretransfer from "../../assets/Landing/Wire-transfer.gif";
import pci from "../../assets/Landing/pci.png";
import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";

const LandingPage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const workSectionRef = useRef(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landingbody">
      <div id="preloder">
        <div className="loader"></div>
      </div>
      <header
        className={`header-section ${isSticky ? "sticky slideInDown" : ""}`}
      >
        <div className="header-content">
          <div className="landingcontainer">
            <a
              onClick={() => navigate("/")}
              className="site-logo"
              style={{ cursor: "pointer" }}
            >
              <img className="landingimg" src={xpentraLogo} alt="xpentraLogo" />
            </a>
            <div className="responsive-bar">
              <i className="fa fa-bars"></i>
            </div>
            <a href="" className="user">
              <i className="fa fa-user"></i>
            </a>
            <button
              className="site-btn sb-gradients sbg-line"
              id="loginbtn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            <nav className="main-menu">
              <ul className="menu-list">
                <li className="actived" onClick={() => navigate("/")}>
                  <a>Home</a>
                </li>
                <li onClick={() => navigate("/featruepage")}>
                  <a>Features</a>
                </li>
                <li onClick={() => navigate("/howitwork")}>
                  <a>How it works</a>
                </li>
                <li onClick={() => navigate("/aboutus")}>
                  <a>About</a>
                </li>
                <li onClick={() => navigate("/contact")}>
                  <a>Contact</a>
                </li>
                <li onClick={() => navigate("/faq")}>
                  <a>FAQ</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <main>
        <section className="main-section gradient gradient-primary-auxiliary">
          <div className="shapes-container">
            <div className="static-shape ghost-shape ghost-shape-1"></div>
            <div className="static-shape pattern-dots-1"></div>
            <div className="static-shape pattern-dots-2"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div
                className="coll-lg-5 coll-md-5 main-text"
                data-aos="fade-right"
                data-aos-delay="0"
              >
                <h2 className="rw-sentence">
                  <span>Endless</span>
                  <div className="rw-words rw-words-1">
                    <span>payments</span>
                    <span>scope</span>
                    <span>customisation</span>
                    <span>optimisation</span>
                    <span>automation</span>
                  </div>
                  <br />
                  <span>starts here</span>
                </h2>
                <h4>
                  We are revolutionizing the way payments and interfacing with
                  banking is done in enterprise business.
                </h4>
              </div>
            </div>
            <a
              onClick={() => scrollToSection(workSectionRef)}
              className="landingscroll"
            >
              Scroll down<div className="bounce"></div>
            </a>
            <a
              className="appstoreimg"
              href="https://play.google.com/store/apps/details?id=com.xtempo.q2pay"
              target="blank"
            >
              <img
                className="landingimg"
                src={googlepay}
                alt=""
                data-aos="fade-left"
                data-aos-delay="0"
              />
            </a>
          </div>
        </section>
        <section
          className="main-section-image"
          data-aos="fade-left"
          data-aos-delay="0"
        >
          <div className="landingcontainer">
            <div className="browser shadow-lg">
              <img src={dashbord} alt="" />
            </div>
            <div className="iphonemain phone-small">
              <div className="screen">
                <img src={mobiledashboard} alt="" />
              </div>
            </div>
            <div className="main-section-google"></div>
          </div>
        </section>

        <section
          className="features-section spad"
          ref={workSectionRef}
          id="work"
        >
          <div className="shapes-container">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div
                  className="section-title text-center"
                  data-aos="fade-down"
                  data-aos-delay="0"
                >
                  <h2>Universal Banking Portal</h2>
                </div>
                <br className="clearfix" />
              </div>
            </div>
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="vertical-center-inner">
                  <div className="landingrow">
                    <div
                      className="coll-lg-6"
                      data-aos="fade-right"
                      data-aos-delay="0"
                    >
                      <div className="cardview-text">
                        <div className="cardview-body">
                          <div className="landingrow">
                            <div
                              className="coll-lg-12"
                              data-aos="fade-right"
                              data-aos-delay="0"
                            >
                              <h6>Stay Connected</h6>
                              <p>
                                Connect multiple current accounts to a single
                                platform
                              </p>
                            </div>
                            <div
                              className="coll-lg-12"
                              data-aos="fade-right"
                              data-aos-delay="0"
                            >
                              <h6>One Interface</h6>
                              <p>
                                Get a unified view of all your accounts and
                                transactions
                              </p>
                            </div>
                            <div
                              className="coll-lg-12"
                              data-aos="fade-right"
                              data-aos-delay="0"
                            >
                              <h6>Seamless Banking</h6>
                              <p>
                                Transfer funds, check balances, view & download
                                statements
                              </p>
                            </div>
                            <div
                              className="coll-lg-12"
                              data-aos="fade-right"
                              data-aos-delay="0"
                            >
                              <h6>Fully Secure</h6>
                              <p>
                                Your account is safe with robust infrastructure
                                security complying with leading industry
                                standards
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="coll-lg-6"
                      data-aos="fade-left"
                      data-aos-delay="200"
                    >
                      <div className="landingvideo">
                        <img className="landingimg" src={wiretransfer} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features-section spad" id="work">
          <div className="shapes-container">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div
                  className="section-title text-center"
                  data-aos="fade-down"
                  data-aos-delay="0"
                >
                  <h2>Redefine Payments</h2>
                </div>
                <br className="clearfix" />
              </div>
            </div>
            <div className="landingrow">
              <div
                className="coll-md-4 process"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                <div className="process-step">
                  <figure className="process-icon">
                    <img
                      src={App}
                      alt="#"
                      className="online-payment landingimg"
                    />
                  </figure>
                  <h4>App</h4>
                  <p>
                    With the in-house built Android app and web app on iOS, you
                    can avail all the functionality of the website on your
                    phone. Create, approve and view vendor accounts as well as
                    transactions on the move with the highest level of security.
                  </p>
                </div>
                <br className="clearfix" />
              </div>
              <div
                className="coll-md-4 process"
                data-aos="fade-down"
                data-aos-delay="600"
              >
                <div className="process-step">
                  <figure className="process-icon">
                    <img
                      src={developer}
                      alt="#"
                      className="developer landingimg"
                    />
                  </figure>
                  <h4>Access Governance</h4>
                  <p>
                    A user is an individual who accesses the portal through a
                    web browser or App, as assigned a specific role and grade
                    within the system. This will determine their level of access
                    and authority within the portal, as well as the specific
                    tasks and responsibilities they are authorized to perform.
                  </p>
                </div>
                <br className="clearfix" />
              </div>
              <div
                className="coll-md-4 process"
                data-aos="fade-down"
                data-aos-delay="800"
              >
                <div className="process-step">
                  <figure className="process-icon">
                    <img
                      src={encryption1}
                      alt="#"
                      className="security landingimg"
                    />
                  </figure>
                  <h4>360&#176; Security</h4>
                  <p>
                    A 360-degree approach to security, covering all aspects of
                    the banking system, providing a more secure and reliable
                    platform for users to conduct financial transactions and
                    protect sensitive information.
                  </p>
                </div>
                <br className="clearfix" />
              </div>
            </div>
            <div className="landingrow">
              <div className="coll-md-12">
                <div
                  className="section-btn text-center"
                  data-aos="fade-down"
                  data-aos-delay="400"
                >
                  <a
                    onClick={() => navigate("/featruepage")}
                    className="site-btn sb-gradients sbg-line"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>

      <footer className="footer-section footer">
        <div className="landingcontainer">
          <div className="landingrow spad">
            <div className="coll-md-5 footer-widget">
              <p>
                Payments should be Quick, Secure and Quintessentially yours.
              </p>
              <span>
                &copy; Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                rights reserved
              </span>
              <br />
              <br />
              <img
                src={pci}
                className="mb-4 landingimg"
                alt=""
                style={{ width: "100px", marginRight: "20px", float: "left" }}
              />
              <a
                id="fC4seLa5xl"
                href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                target="_blank"
              >
                <img
                  className="landingimg"
                  src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                  alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                  style={{ width: "50px", float: "left" }}
                />
              </a>
            </div>
            <div className="coll-md-4 footer-widget">
              <h5 className="widget-title">Quick Links</h5>
              <div className="landingrow">
                <div className="coll-md-5 footer-widget">
                  <ul>
                    <li className="actived" onClick={() => navigate("/")}>
                      <a>Home</a>
                    </li>
                    <li onClick={() => navigate("/featruepage")}>
                      <a>Features</a>
                    </li>
                    <li onClick={() => navigate("/howitwork")}>
                      <a>How it works</a>
                    </li>
                    <li onClick={() => navigate("/aboutus")}>
                      <a>About</a>
                    </li>
                    <li onClick={() => navigate("/contact")}>
                      <a>Contact</a>
                    </li>
                    <li onClick={() => navigate("/faq")}>
                      <a>FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="coll-md-7 footer-widget">
                  <ul>
                    <li onClick={() => navigate("/privacy")}>
                      <a>Privacy Policy</a>
                    </li>
                    <li onClick={() => navigate("/terms")}>
                      <a>Terms of Use</a>
                    </li>
                    <li onClick={() => navigate("/cancellation")}>
                      <a>Cancellation Policy</a>
                    </li>
                    <li onClick={() => navigate("/refund")}>
                      <a>Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coll-md-3 footer-widget">
              <h5 className="widget-title">Supported By</h5>
              <div className="visacard"></div>
              <div className="mastercard"></div>
              <div className="dinerscard"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
