import React, { useRef, useEffect, useState } from "react";
import "./index.css";
import Search from "../../../assets/CreateTransactionIcons/search.png";
import CircleCancel from "../../../assets/CreateTransactionIcons/cancel.png";
import ArrowDown from "../../../assets/CreateTransactionIcons/arrowdown.png";
import {
  beneCategories,
  beneWaitingApproved,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../redux/features/login/login";
import { Button, Tooltip, tooltipClasses } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const SelectCategoryTransaction = ({
  initCategory,
  setInitCategory,
  setPage,
  label,
  setlabel,

  setCurrentPage,
}) => {
  const [categorySearch, setCategorySearch] = useState("");
  const searchRef = useRef(null);

  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const handleClick = (name) => {
    setCurrentPage(1);
    setPage(1);
    setlabel(name);
    setInitCategory(false);
  };

  const handleCategorySearchChange = (e) => {
    setCategorySearch(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && filteredCategories.length > 0) {
      handleClick(filteredCategories[0].name);
    }
  };

  return (
    <div className="select-category-transaction txn_sort_by_category_InMultiview">
      <>
        <p
          style={{
            color: "#F05A6A",
            width: "35px",
            height: "19px",
            font: "normal normal 600 14px/19px Open Sans",
          }}
        >
          Filter
        </p>
        <p
          style={{
            color: "#C9C9C9",
            textTransform: "lowercase",
          }}
        >
          category
        </p>
      </>

      <div
        className="txn_category_select"
        onClick={() => {
          setInitCategory(!initCategory);
        }}
        style={{
          marginTop: "2px",
          height: initCategory ? "198px" : "39px",
          outline: initCategory ? "1px solid #E25869" : "",
        }}
      >
        <div className="txn_category_select_select">
          <div
            className="txn_category_select_scroll_line"
            style={{ display: initCategory ? "block" : "none" }}
          ></div>

          <span
            className="focus-span"
            style={{
              fontSize: initCategory ? "12px" : "14px",
              paddingLeft: initCategory ? "9px" : "15px",
            }}
          >
            {label}
          </span>
          <img
            src={ArrowDown}
            alt="down"
            style={{
              transform: initCategory ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>

        {initCategory ? (
          <div className="txn_category_select_scroll">
            <div className="input_div_element">
              <img
                src={Search}
                alt="search"
                className="search_owner_element"
                onClick={(e) => {
                  e.stopPropagation();
                  searchRef.current.focus();
                }}
              />
              <input
                ref={searchRef}
                type="text"
                onKeyPress={handleKeyPress}
                value={categorySearch}
                onChange={handleCategorySearchChange}
                placeholder="Search"
                onClick={(e) => e.stopPropagation()}
              />
              <img
                src={CircleCancel}
                alt="cancel"
                className="search_owner_element"
                onClick={(e) => {
                  e.stopPropagation();
                  setCategorySearch("");
                  setInitCategory(false);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>

            {categorySearch === "" ? (
                    <div className="txn_sort_by_category_scroll">
                      {categories.map((category) => (
                        <div key={category.id} className="category-wrapper">
                          <Tooltip
                            key={category.id}
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={category.name}
                          >
                            <button
                              className="bene_txn_category_select_scroll_com"
                              onClick={() => {
                                handleClick(category.name);
                              }}
                            >
                              <span style={{ width: "175px", textOverflow: "ellipsis", overflow: "hidden"}}>{category.name}</span>
                            </button>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="bene_trans_filter_by_category_scroll">
                      {filteredCategories.map((category) => (
                        <div key={category.id} className="category-wrapper">
                          <Tooltip
                            key={category.id}
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={category.name}
                          >
                            <button
                              className="txn_category_select_scroll_com"
                              onClick={() => {
                                handleClick(category.name);
                              }}
                            >
                              {category.name}
                            </button>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                )}

            

           
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SelectCategoryTransaction;
