const getServices = (services) => {
  if (!Array.isArray(services)) {
    return { total_services: 0, services_list: {} };
  }

  let newObj = {};
  for (let i = 0; i < services.length; i++) {
    newObj[services[i]["service_id"]] = services[i]["service_name"];
  }
  let obj_length = Object.keys(newObj).length;
  return { total_services: obj_length, services_list: newObj };
};

export default getServices;
