import React from "react";
import forgotSuccess from "../../../assets/loginImage/forgotSuccess.png";
import xpentraLogo from "../../../assets/TransactionDashboardIcons/Xpentra.png";
import Approved from "../../../assets/loginImage/Approved.png";
import { useNavigate } from "react-router-dom";
import {
  resetFirstLoginData,
  setMultipleEntity,
  setShowReloginModalFalse,
} from "../../../redux/features/login/login";
import { logout } from "../../../redux/features/refreshToken/refreshToken";
import { useDispatch } from "react-redux";

const SetNewPasswordSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateClick = () => {
    dispatch(setMultipleEntity());
    dispatch(setShowReloginModalFalse());
    dispatch(logout());
    dispatch(resetFirstLoginData());
    navigate("/login");
  };

  return (
    <div
      className="flex flex-col md:flex-row justify-center items-center max-w-[1024px] h-full"
      style={{ margin: "50px auto" }}
    >
      <div className="flex-1 w-full md:w-1/2 flex flex-col items-center">
        <img src={forgotSuccess} className="w-full" alt="bg_image" />
        <div className="text-center mt-8 text-[#FAFAFA]">
          <h1 className="text-2xl">Login successful!</h1>
          <p className="mt-4 pt-5">
            It is mandatory for you to set <br />a new password, which is not
            the same as the <br /> password provided by the admin.
          </p>
        </div>
      </div>
      <div className="flex-1 w-full md:w-1/2 px-4 mt-4">
        <div className="bg-white px-10 py-8 shadow-md rounded-[40px]">
          <div className="w-1/2 mx-auto mb-16 flex justify-center">
            <img src={xpentraLogo} alt="xpentraLogo" />
          </div>

          <div className="mt-6 text-center">
            <div className="w-11 h-11 m-auto pb-5">
              <img src={Approved} alt="Approved" />
            </div>
            <h1 className="block text-[20px] font-semibold mb-8 mt-4">
              Password reset successfully!
            </h1>
            <p className="text-center mt-8 text-base font-semibold">
              Your password has been successfully reset.
              <br />
              Click the button below and login with your new credentials.
            </p>
          </div>
          <div className="text-center mt-28 pt-2 mb-2">
            <button
              onClick={handleNavigateClick}
              id="button"
              className="bg-[#69b76f] hover:bg-[#69b76f] text-white font-bold py-2 px-4 rounded-[20px] w-full focus:outline-none"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetNewPasswordSuccess;
