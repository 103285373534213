import React, { useEffect, useState } from "react";
import TransactionType from "../../../../components/Settings/Configuration/AuthorizationMatrix/TransactionType";
import { AuthMatrixTransactions } from "../../../../services/Settings/Configuration/AuthMatrix";
import AddCustomRule from "../../../../components/Settings/Configuration/AuthorizationMatrix/AddCustomRule";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { getAllTransactionAuthorization } from "../../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../../redux/features/login/login";
import ArchivedDialogBox from "../../../../components/Settings/Configuration/AuthorizationMatrix/AddCustomRule/ArchivedDialogBox";

const AuthMatrix = () => {
  const [selected, setSelected] = useState("vendor");
  const [archive, setArchive] = useState(false);
  const dispatch = useDispatch();
  const allTransactionsAuth = useSelector(
    (state) => state.AuthMatrix.allTransactionAuth
  );
  const archieveRes = useSelector((state) => state.AuthMatrix.archiveRuleRes);
  const createTxnAuthRes = useSelector(
    (state) => state.AuthMatrix.createTransactionAuth
  );
  const [sortedArray, setSortedArray] = useState([]);

  const handleSelected = (value) => {
    setSelected(value);
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getAllTransactionAuthorization({ type_of_transaction: selected }));
  }, [selected, archieveRes, createTxnAuthRes]);

  useEffect(() => {
    let sortedArr = allTransactionsAuth?.transaction_auth_matrices
      ? [...allTransactionsAuth?.transaction_auth_matrices]
      : [];
    setSortedArray(
      sortedArr
        ?.slice()
        .sort((a, b) => a.entity_name.localeCompare(b.entity_name))
    );
  }, [allTransactionsAuth]);

  return (
    <div className="flex w-screen h-screen">
      <div className="bg-[#153164] w-1/5 pt-24 h-full fixed">
        <TransactionType
          AuthMatrixTransactions={AuthMatrixTransactions}
          selected={selected}
          handleSelected={handleSelected}
        />
      </div>
      <div className="bg-[#1D3A6D] w-4/5 pt-24 h-full absolute right-0 overflow-y-auto vendor-txn hide-scrollbar">
        <AddCustomRule
          archive={archive}
          setArchive={setArchive}
          type_of_transaction={selected}
        />
        {sortedArray?.map((rule, index, arr) => {
          return (
            <AddCustomRule
              dontShowName={arr[index - 1]?.entity_id === rule.entity_id}
              archive={archive}
              setArchive={setArchive}
              rule={rule}
              index={index}
            />
          );
        })}
      </div>
      {archive && <ArchivedDialogBox id={archive} setState={setArchive} />}
    </div>
  );
};

export default AuthMatrix;
