import React, { useEffect, useState, useRef } from "react";
import "./index.css";
import OwnerViewAllCard from "../OwnerViewAllCard/index";
import ListView from "../../../../components/Accounts/OwnerAccounts/listView/index";
import RefreshAllLoader from "../../../../components/Accounts/OwnerAccounts/RefreshAllLoader/index";
import { Box, tooltipClasses, Tooltip, TextField } from "@mui/material";
import RectangleSearchBar from "../../../../components/_utils/RectangleSearchBar/index";
import SelectBank from "../../../../components/_utils/SelectBank";
import TypeOfAccountDropdown from "../../../../components/_utils/TypeOfAccountDropdown/index";
import CheckIcon from "../../../../assets/Beneficiary Icons/whitecheckmark.png";
import SearchIcon from "../../../../assets/Approve transactions/search-white.png";
import SearchBlue from "../../../../assets/ViewAllTransactions/search_blue.png";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast/index";
import SelectAccountBalance from "../../../../components/SelectAccountBalance/index";
import {
  ownerListViewAllAcc,
  getOwnerAccBalance,
  setshowsuccestoast,
  setToggle,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { useDispatch, useSelector } from "react-redux";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import ConnectedBankingDropdown from "../../../../components/_utils/connectedBankingDropdopwn/selectConnectedBanking";
import noaccount from "../../../../assets/Beneficiary Icons/noaccfound.png";
import { useLocation } from "react-router-dom";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import Refreshicon from "../../../../assets/OwnerAccount/refresh.png";
import GridBlue from "../../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../../assets/OwnerAccount/gridview-white.png";
import ListBlue from "../../../../assets/OwnerAccount/listview-blue.png";
import ListWhite from "../../../../assets/OwnerAccount/listview-white.png";
import clearFilter from "../../../../assets/OwnerAccount/clear-filter.png";
import ArrowLeft from "../../../../assets/OwnerAccount/arrowleft.png";
import { checkUsertoken } from "../../../../redux/features/login/login";
import ArrowRight from "../../../../assets/OwnerAccount/arrowright.png";
import ArrowDown from "../../../../assets/OwnerAccount/arrowdown1.png";
import ArrowUp from "../../../../assets/OwnerAccount/arrowup.png";
import ArrowDown8 from "../../../../assets/OwnerAccount/arrowdown8.png";
import ArrowUp4 from "../../../../assets/OwnerAccount/arrowdown4png.png";
import Arrowdonw5 from "../../../../assets/OwnerAccount/arrowdown5.png";
import FilterBlue from "../../../../assets/OwnerAccount/filterblue.png";
import SplitView1 from "../../../../assets/OwnerAccount/splitview1.png";
import SplitView2 from "../../../../assets/OwnerAccount/splitview2.png";
import Filter from "../../../../assets/ViewAllTransactions/filter.png";
import { debounce } from "lodash";
import Info from "../../../../assets/OwnerAccount/info.png";
import Validation from "../../../../utils/validation";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";

const OwnerAccViewAll = () => {
  const location = useLocation();
  const dropdownOptions =
    window.innerWidth < 1600
      ? [60, 120, 180, 240]
      : window.innerWidth < 2560
      ? [80, 160, 240, 320]
      : [100, 200, 300, 400];
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageText, setCurrentPageText] = useState("01");
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [connectedBankingData, setConnectedBankingData] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [previd, setID] = useState(false);
  const [searchUserText, setSearchUserText] = useState("");
  const [mainData, setMainData] = useState([]);
  const [selectSortAmt, setSelectSortAmt] = useState(false);
  const [selectTypeOfAccount, setSelectTypeofAccount] = useState(false);
  const [showTotalBalancePopup, setShowTotalBalancePopup] = useState(false);
  const [filterApply, setFilterApply] = useState(location.state ? true : false);
  const [total_balance_state, setTotal_balance_state] = useState(0);
  const [prev_total_balance_state, setPrev_Total_balance_state] = useState(0);
  const [refreshAllData, setRefreshAllData] = useState([]);
  const [successRefreshId, setSuccessRefreshId] = useState([]);
  const [failedRefreshId, setFailedRefreshId] = useState([]);
  const [refreshType, setRefreshtype] = useState("");
  const [selectAmtType, setSelectAmtType] = useState("Default");
  const [refreshAll, setRefreshAll] = useState(false);
  const [splitView, setSplitView] = useState("");
  const [isPerPageOpen, setIsPerPageOpen] = useState(false);
  const [selectConnectedBanking, setSelectConnectedBanking] = useState(false);
  const [showTotalBalanceDiff, setShowTotalBalanceDiff] = useState(false);
  const [showSelectBank, setShowSelectBank] = useState(false);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [filterPayload, setFilterPayload] = useState({
    pagination: 0,
    accNoInput: "",
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    connectedBanking: location.state ? location.state.approvalStatus : "",
    typeofaccount: "",
    accountnumber: "",
    ifscnumber: "",
    pannumber: "",
    panFilter: false,
    BankFilter: false,
    TypeAccFilter: false,
    ConnectedFilter: location.state ? true : false,
    AccIfscFilter: false,
  });
  const {
    ownerAccBalanceLoading,
    ownerAccBalanceError,
    toggle,
    showSuccessToast,
    storedPage,
  } = useSelector((state) => state.ownerAllVerification);

  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const filterButtonRef = useRef(null);
  const balanceRef = useRef(null);
  const balanceButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const searchRef = useRef(null);
  const searchButtonRef = useRef(null);
  const totalBalanceRef = useRef(null);

  useEffect(() => {
    if (Number(storedPage) > 1) {
      setCurrentPage(Number(storedPage));
      setCurrentPageText(
        Number(storedPage) > 9 ? String(storedPage) : "0" + String(storedPage)
      );
    }
  }, []);

  const setCurrentWidth = () => {
    setSelectedValue(dropdownOptions[0]);
    setPageSize(dropdownOptions[0]);
  };
  window.onresize = setCurrentWidth;

  useEffect(() => {
    fetchData(filterApply);
  }, [currentPage, toggle, searchUserText, pageSize]);

  const fetchData = async (val) => {
    let applied = val ? val : false;
    setFailedRefreshId([]);
    setSuccessRefreshId([]);
    setRefreshtype(false);

    try {
      setLoading(true);
      dispatch(checkUsertoken());
      let response = await dispatch(
        ownerListViewAllAcc({
          page_no: currentPage,
          page_size: pageSize,
          search_string: searchUserText.length > 0 ? searchUserText : undefined,
          type_of_account:
            applied && filterPayload.TypeAccFilter
              ? filterPayload.typeofaccount.length > 0
                ? filterPayload.typeofaccount
                : undefined
              : undefined,
          bank_name:
            applied && filterPayload.BankFilter
              ? filterPayload.bankNameInput.length > 0
                ? filterPayload.bankNameInput
                : undefined
              : undefined,
          connected_banking:
            applied && filterPayload.ConnectedFilter
              ? filterPayload.connectedBanking.length > 0
                ? filterPayload.connectedBanking === "Active"
                  ? "Y"
                  : "N"
                : undefined
              : undefined,
          bank_account_no:
            applied && filterPayload.AccIfscFilter
              ? filterPayload.accountnumber.length > 3
                ? filterPayload.accountnumber
                : undefined
              : undefined,
          ifsc:
            applied && filterPayload.AccIfscFilter
              ? filterPayload.ifscnumber.length > 10
                ? filterPayload.ifscnumber
                : undefined
              : undefined,
          pan:
            applied && filterPayload.panFilter
              ? filterPayload.pannumber.length > 9
                ? filterPayload.pannumber
                : undefined
              : undefined,
        })
      );

      const newData = response?.payload?.owner_accounts || [];
      setMainData(response?.payload);
      setTotal_balance_state(Number(response?.payload?.total_balance || 0));
      setAccumulatedData(newData);
      setConnectedBankingData(
        newData.filter((account) => {
          return account.connected_banking === "Y";
        })
      );
      setLoading(false);
    } catch (error) {
      setAccumulatedData([]);
      setMainData([]);
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    let val = Number(currentPageText) + 1;
    setCurrentPageText(val > 9 ? val : "0" + val);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    let val = Number(currentPageText) - 1;
    setCurrentPageText(val > 9 ? val : "0" + val);
  };

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPageText("01");
    setIsPerPageOpen(false);
  };

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();

        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  const handleApplyEnable = () => {
    if (
      filterPayload.pannumber.length > 0 &&
      filterPayload.pannumber.length !== 10
    ) {
      return false;
    } else if (
      filterPayload.accountnumber.length > 0 &&
      filterPayload.ifscnumber.length !== 11
    ) {
      return false;
    } else if (
      filterPayload.accountnumber.length < 4 &&
      filterPayload.ifscnumber.length > 10
    ) {
      return false;
    } else if (
      filterPayload.accountnumber.length === 0 &&
      filterPayload.ifscnumber.length > 0
    ) {
      return false;
    } else if (
      filterPayload.sortAccountNo.length == 0 &&
      filterPayload.pannumber.length == 0 &&
      filterPayload.connectedBanking.length == 0 &&
      filterPayload.typeofaccount.length == 0 &&
      filterPayload.bankNameInput.length == 0 &&
      filterPayload.accountnumber.length == 0 &&
      filterPayload.ifscnumber.length == 0
    ) {
      return false;
    }
    return true;
  };

  const handleRefresh = async (id) => {
    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getOwnerAccBalance(id));
      if (response?.payload?.status == true) {
        setSuccessRefreshId([id]);
        let balance = 0;
        let data = accumulatedData.map((item) => {
          if (response.payload.owner_accounts[0].owner_account_id === item.id) {
            balance =
              Number(total_balance_state) -
              Number(item.owner_account_balance) +
              Number(response.payload.owner_accounts[0].owner_account_balance);
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response.payload.owner_accounts[0].owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload.owner_accounts[0]
                  .owner_account_balance_last_fetched_at,
              difference: Math.abs(
                Number(item.owner_account_balance) -
                  Number(
                    response.payload.owner_accounts[0].owner_account_balance
                  )
              ),
              increaseOrDecrease:
                Number(item.owner_account_balance) >
                Number(response.payload.owner_accounts[0].owner_account_balance)
                  ? "decreased"
                  : Number(item.owner_account_balance) <
                    Number(
                      response.payload.owner_accounts[0].owner_account_balance
                    )
                  ? "increased"
                  : "",
            };
          }
          return item;
        });
        setTotal_balance_state(Number(balance));
        setAccumulatedData(data);
      } else {
        setFailedRefreshId([id]);

        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error?.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  useEffect(() => {
    if (
      !filterPayload.AccIfscFilter &&
      !filterPayload.BankFilter &&
      !filterPayload.ConnectedFilter &&
      !filterPayload.TypeAccFilter &&
      !filterPayload.panFilter
    ) {
      setFilterApply(false);
      fetchData(false);
    } else {
      if (
        filterPayload.typeofaccount.length === 0 &&
        filterPayload.sortAccountNo.length === 0 &&
        filterPayload.ifscnumber.length === 0 &&
        filterPayload.connectedBanking.length === 0 &&
        filterPayload.bankNameInput.length === 0 &&
        filterPayload.pannumber.length === 0 &&
        filterPayload.accountnumber.length === 0 &&
        filterOpen
      ) {
        setTimeout(() => {
          setFilterApply(false);
          fetchData(filterApply);
        }, 500);
      }

      if (
        (filterPayload.typeofaccount.length === 0 ||
          filterPayload.sortAccountNo.length === 0 ||
          filterPayload.ifscnumber.length === 0 ||
          filterPayload.connectedBanking.length === 0 ||
          filterPayload.bankNameInput.length === 0 ||
          filterPayload.pannumber.length === 0 ||
          filterPayload.accountnumber.length === 0) &&
        filterApply &&
        !filterOpen
      ) {
        fetchData(filterApply);
      }
      if (
        filterPayload.typeofaccount.length === 0 &&
        filterPayload.sortAccountNo.length === 0 &&
        filterPayload.ifscnumber.length === 0 &&
        filterPayload.bankNameInput.length === 0 &&
        filterPayload.connectedBanking.length === 0 &&
        filterPayload.pannumber.length === 0 &&
        filterPayload.accountnumber.length === 0 &&
        filterApply &&
        !filterOpen
      ) {
        setFilterApply(false);
        fetchData(false);
      }
      if (
        filterPayload.typeofaccount.length === 0 &&
        filterPayload.sortAccountNo.length === 0 &&
        filterPayload.ifscnumber.length === 11 &&
        filterPayload.bankNameInput.length === 0 &&
        filterPayload.connectedBanking.length === 0 &&
        filterPayload.pannumber.length === 0 &&
        filterPayload.accountnumber.length > 3 &&
        filterApply &&
        !filterOpen &&
        !filterPayload.AccIfscFilter
      ) {
        setFilterApply(false);
      }
      if (
        filterPayload.typeofaccount.length === 0 &&
        filterPayload.sortAccountNo.length === 0 &&
        filterPayload.ifscnumber.length === 0 &&
        filterPayload.bankNameInput.length === 0 &&
        filterPayload.connectedBanking.length === 0 &&
        filterPayload.pannumber.length === 10 &&
        filterPayload.accountnumber.length === 0 &&
        filterApply &&
        !filterOpen &&
        !filterPayload.panFilter
      ) {
        setFilterApply(false);
      }
    }
  }, [filterPayload]);

  const filteredData = accumulatedData;
  const postiveBalanceData = accumulatedData.filter(
    (item) =>
      item.connected_banking == "Y" && Number(item.owner_account_balance) >= 0
  );
  const negativeBalanceData = accumulatedData.filter(
    (item) =>
      item.connected_banking == "Y" && Number(item.owner_account_balance) < 0
  );

  const handlepage = debounce((page) => {
    if (Number(page) <= mainData?.title?.total_pages && page !== "00") {
      let value = page.length > 1 ? page.substring(1) : page;
      if (currentPage != value) {
        setCurrentPage(Number(value));
        setCurrentPageText(page);
      }
    } else if (page > mainData?.title?.total_pages) {
      setCurrentPage(mainData?.title?.total_pages);
      setCurrentPageText("0" + mainData?.title?.total_pages);
    } else {
      setCurrentPage(1);
      setCurrentPageText("01");
    }
  }, 100);

  const handleBlur = () => {
    if (currentPageText.length === 0 || currentPageText === "0") {
      setCurrentPageText(currentPage > 1 ? currentPage : "01");
    } else {
      if (currentPageText.length === 1) {
        setCurrentPageText("0" + currentPage);
      }
    }
  };

  const handlePagination = (e) => {
    e.preventDefault();
    setCurrentPageText(e.target.value);
    if (
      e.target.value !== "" &&
      e.target.value !== 0 &&
      e.target.value !== "0"
    ) {
      handlepage(e.target.value);
    }
  };

  const allowedValue = (name, value) => {
    if (name === "accountnumber") return Validation.allowedBankAccNum(value);
    if (name === "ifscnumber") return Validation.allowedBankIfsc(value);
    if (name === "pannumber") return Validation.allowedPAN(value);
    return true;
  };

  const handleInput = (e) => {
    let { name, value } = e.target;
    let val = value.replaceAll(" ", "");
    if (allowedValue(name, val)) {
      const newObj = {
        ...filterPayload,
        [name]: val.toUpperCase(),
      };
      if ((name = "accountnumber") && value == "") {
        newObj.ifscnumber = "";
        setFilterPayload(newObj);
      } else {
        setFilterPayload(newObj);
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    setSearchUserText(e.target.value);
  };

  useEffect(() => {
    const handlefilter = (e) => {
      if (filterRef.current && filterRef.current.contains(e.target)) {
      } else if (
        filterButtonRef.current &&
        filterButtonRef.current.contains(e.target)
      ) {
      } else {
        setFilterOpen(false);
      }
    };
    document.addEventListener("click", handlefilter);
  }, [filterRef]);

  useEffect(() => {
    const handlePopup = (e) => {
      if (balanceRef.current && balanceRef.current.contains(e.target)) {
      } else if (
        balanceButtonRef.current &&
        balanceButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowTotalBalancePopup(false);
      }
    };
    document.addEventListener("click", handlePopup);
  }, [balanceRef]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      } else {
        setIsPerPageOpen(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [dropdownRef]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (
        totalBalanceRef.current &&
        totalBalanceRef.current.contains(e.target)
      ) {
      } else {
        setShowTotalBalanceDiff(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [totalBalanceRef]);

  useEffect(() => {
    const handleDropDown = (e) => {
      if (searchRef.current && searchRef.current.contains(e.target)) {
      } else if (
        searchButtonRef.current &&
        searchButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowSearchBar(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [searchRef]);

  const handleSelectAmt = () => {
    if (selectAmtType == "Default") {
      return "66px";
    } else if (selectAmtType == "Lakhs") {
      return "128px";
    } else if (selectAmtType == "Crores") {
      return "183px";
    } else if (selectAmtType == "Millions") {
      return "244px";
    } else if (selectAmtType == "Billions") {
      return "305px";
    }
  };

  return (
    <>
      {refreshAll && (
        <div className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex flex-col items-center justify-center z-[9999]">
          <RefreshAllLoader
            setRefreshAll={setRefreshAll}
            data={connectedBankingData}
            setAccumulatedData={setAccumulatedData}
            mainData={filteredData}
            total_balance_state={total_balance_state}
            setTotal_balance_state={setTotal_balance_state}
            setFailedRefreshId={setFailedRefreshId}
            setSuccessRefreshId={setSuccessRefreshId}
          />
        </div>
      )}
      {showTotalBalancePopup && (
        <div className="fixed top-0 w-[40%] 2xl:w-[44%] 3xl:w-[48%] 3.6xl:w-[48%] 4xl:w-[47%] 4.3xl:w-[45%]4xl:w-[47%] 5.6xl:w-[46%] 6xl:w-[49%] 6.5xl:w-[48%] 7xl:w-[49%] h-[350px] flex  items-center justify-end z-[9999]">
          <div
            ref={balanceRef}
            style={{
              width: "242px",
              height: "57px",
              borderRadius: "10px",
              backgroundColor: "#314D7E",
              padding: "20px 15px",
              boxShadow: "6px #00000029",
            }}
          >
            <p
              style={{
                width: "250px",
                height: "17px",
                font: "normal normal normal 12px/17px Open Sans",
                color: "#C9C9C9",
              }}
            >
              Total balance:
              <span
                style={{
                  height: "19px",
                  font: "normal normal 600 14px/19px Open Sans",
                  color: "#4DDD37",
                  paddingLeft: "5px",
                }}
              >
                {<FormatAmount price={Number(total_balance_state)} />}
              </span>
            </p>
          </div>
        </div>
      )}
      <div
        className={`bg-custom-appBack flex flex-col h-screen overflow-y-hidden`}
        style={{ overflow: filterOpen && "hidden" }}
      >
        <div
          className={`owner_div_container ${
            showSearchBar && "owner_div_height"
          }`}
        >
          <div className={"owner_div"}>
            <div
              style={{
                height: showTotalBalanceDiff ? "260px" : " 140px",
                transition: "height 0.2s ease-in-out",
                padding: "35px 20px 0px 20px",
              }}
              className=" all-containers"
            >
              <div
                style={{
                  color: total_balance_state >= 0 ? "#4DDD37" : "#ff5860",
                }}
                className="No-of-balancess"
              >
                <div>
                  <span className="rupee-count-symbol">₹ </span>
                  {total_balance_state !== undefined
                    ? formatTotalBalance(total_balance_state, selectAmtType)
                        .amount
                    : "00.00"}
                  {total_balance_state !== undefined &&
                    formatTotalBalance(total_balance_state).prefix ==
                      "crores" && (
                      <span
                        ref={balanceButtonRef}
                        onClick={() => {
                          setShowTotalBalancePopup(true);
                        }}
                        className="owner-total-div"
                      >
                        <img src={Info} alt="Info" />
                      </span>
                    )}
                </div>
                <span
                  style={{
                    position: "relative",
                    top: "-5px",
                  }}
                >
                  <AnimationUpAndDown
                    loader={ownerAccBalanceLoading}
                    currentId={true}
                    previousId={previd}
                    currentBalance={total_balance_state}
                    refreshType={"all"}
                    allPrevData={prev_total_balance_state}
                  />
                </span>
              </div>
              <div className="view-counts">
                <p>
                  Total balance{" "}
                  {formatTotalBalance(total_balance_state, selectAmtType)
                    .prefix == ""
                    ? ""
                    : "in " +
                      formatTotalBalance(total_balance_state, selectAmtType)
                        .prefix}
                </p>
                {successRefreshId.length > 0 && (
                  <div
                    ref={totalBalanceRef}
                    onClick={() =>
                      setShowTotalBalanceDiff(!showTotalBalanceDiff)
                    }
                    style={{
                      cursor: "pointer",
                      height: "20px",
                      width: "20px",
                      borderRadius: "3px",
                      padding: "5px",
                      backgroundColor: "#1D3A6D",
                    }}
                  >
                    <img
                      style={{
                        height: "10px",
                        width: "10px",
                      }}
                      src={showTotalBalanceDiff ? ArrowUp : ArrowDown}
                      alt=""
                    />
                  </div>
                )}
              </div>
              {showTotalBalanceDiff && successRefreshId.length > 0 && (
                <div
                  style={{
                    width: "210px",
                    borderTop: "1px solid #1D3A6D",
                    height: "50px",
                    position: "absolute",
                    bottom: "10px",
                    paddingTop: "7px",
                    paddingLeft: "20px",
                    marginLeft: "-20px",
                  }}
                >
                  <p
                    style={{
                      color:
                        prev_total_balance_state < total_balance_state
                          ? "#4DE659"
                          : prev_total_balance_state > total_balance_state
                          ? "#FF5860"
                          : "#F9F9F9",
                    }}
                  >
                    {
                      formatTotalBalance(
                        prev_total_balance_state - total_balance_state,
                        selectAmtType
                      ).amount
                    }
                  </p>
                  <p className="account_text">
                    Balance{" "}
                    {prev_total_balance_state <= total_balance_state
                      ? "increased"
                      : "decreased"}{" "}
                    by{" "}
                    {formatTotalBalance(
                      prev_total_balance_state - total_balance_state,
                      selectAmtType
                    ).prefix == ""
                      ? ""
                      : "- in " +
                        formatTotalBalance(
                          prev_total_balance_state - total_balance_state,
                          selectAmtType
                        ).prefix}
                  </p>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "30px",
                height: "140px",
                width: "570px",
                padding: "35px 0 0 10px",
              }}
            >
              <div
                className="owner-refresh-div hover:bg-[#506994] cursor-pointer"
                onClick={() => {
                  dispatch(checkUsertoken());
                  dispatch(getRefreshTokenNew());
                  setTimeout(() => {
                    let selectedData = accumulatedData?.map((item) => {
                      return {
                        id: item.id,
                        prevbalance: item.owner_account_balance,
                      };
                    });
                    setFailedRefreshId([]);
                    setSuccessRefreshId([]);
                    setRefreshtype("all");
                    setPrev_Total_balance_state(total_balance_state);
                    setID(true);
                    setRefreshAllData(selectedData);
                    setRefreshAll(true);
                  }, 500);
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Refresh all balances"
                >
                  <img src={Refreshicon} alt="" />
                </Tooltip>
              </div>

              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Search"
              >
                <div
                  ref={searchButtonRef}
                  style={{
                    backgroundColor:
                      searchUserText.length > 0 ? "#fff" : "#244277",
                  }}
                  className="owner_search_div"
                  onClick={() => setShowSearchBar(!showSearchBar)}
                >
                  <img
                    className="h-[15px] w-[15px]"
                    src={searchUserText.length > 0 ? SearchBlue : SearchIcon}
                    alt="search-image"
                  />
                  <img
                    style={{
                      transform: showSearchBar && "rotate(180deg)",
                    }}
                    src={searchUserText.length > 0 ? ArrowDown8 : ArrowDown}
                    alt=""
                    className="filter-images"
                  />
                </div>
              </Tooltip>

              <div
                ref={filterButtonRef}
                onClick={() => {
                  setFilterOpen(!filterOpen);
                  setSelectTypeofAccount(false);
                  setSelectSortAmt(false);
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      backgroundColor:
                        (filterPayload.typeofaccount.length > 0 ||
                          filterPayload.ifscnumber.length > 0 ||
                          filterPayload.accountnumber.length > 0 ||
                          filterPayload.bankNameInput.length > 0 ||
                          filterPayload.connectedBanking.length > 0 ||
                          filterPayload.pannumber.length > 0 ||
                          filterPayload.sortAccountNo.length > 0) &&
                        filterApply
                          ? "#F9F9F9"
                          : "",
                    }}
                    onClick={() => {
                      setFilterOpen(!filterOpen);

                      setSelectTypeofAccount(false);
                      setSelectSortAmt(false);
                    }}
                    className="filter-containers"
                  >
                    <Box className="filters-box">
                      <img
                        src={
                          (filterPayload.typeofaccount.length > 0 ||
                            filterPayload.ifscnumber.length > 0 ||
                            filterPayload.accountnumber.length > 0 ||
                            filterPayload.connectedBanking.length > 0 ||
                            filterPayload.bankNameInput.length > 0 ||
                            filterPayload.pannumber.length > 0 ||
                            filterPayload.sortAccountNo.length > 0) &&
                          filterApply
                            ? FilterBlue
                            : Filter
                        }
                        alt=""
                        className="filter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          filterOpen
                            ? (filterPayload.typeofaccount.length > 0 ||
                                filterPayload.ifscnumber.length > 0 ||
                                filterPayload.connectedBanking.length > 0 ||
                                filterPayload.accountnumber.length > 0 ||
                                filterPayload.bankNameInput.length > 0 ||
                                filterPayload.pannumber.length > 0 ||
                                filterPayload.sortAccountNo.length > 0) &&
                              filterApply
                              ? ArrowUp4
                              : ArrowUp
                            : (filterPayload.typeofaccount.length > 0 ||
                                filterPayload.ifscnumber.length > 0 ||
                                filterPayload.accountnumber.length > 0 ||
                                filterPayload.bankNameInput.length > 0 ||
                                filterPayload.connectedBanking.length > 0 ||
                                filterPayload.pannumber.length > 0 ||
                                filterPayload.sortAccountNo.length > 0) &&
                              filterApply
                            ? ArrowDown8
                            : ArrowDown
                        }
                        alt=""
                        className="filter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>

              <div className="owner-viewall-page-select-div">
                <div
                  style={{
                    height: "36px",
                    width: "76px",
                    padding: "7px 0px 0px 8px",
                    color: "#f9f9f9",
                    fontSize: "12px",
                  }}
                >
                  Per page :
                </div>
                <div
                  ref={dropdownRef}
                  style={{
                    height: "36px",
                    width: "56px",
                    borderRadius: "0 4px 4px 4px",
                  }}
                  className="statement-type"
                >
                  <div
                    className="owner-main-btn"
                    onClick={() => {
                      setIsPerPageOpen(!isPerPageOpen);
                    }}
                    style={{
                      borderRadius: isPerPageOpen
                        ? "0 4px 4px 4px"
                        : "0 4px 4px 0px",
                      height: isPerPageOpen ? "156px" : "36px",
                    }}
                  >
                    <div
                      style={{
                        height: "36px",
                        width: "56px",
                        backgroundColor: isPerPageOpen ? "#667CA3" : "#F9F9F9",
                      }}
                      className="bank_high_to_low_select_select"
                    >
                      <span
                        style={{
                          color: isPerPageOpen ? "#f9f9f9" : "#244277",
                          fontSize: "12px",
                          paddingLeft: "8px",
                        }}
                      >
                        {selectedValue}
                      </span>
                      <img
                        src={isPerPageOpen ? ArrowUp : ArrowDown8}
                        alt="Status Icon"
                        style={{
                          height: "12px",
                          width: "12px",
                        }}
                        className="status_icon-img"
                      />
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        width: "56px",
                        top: "36px",
                      }}
                    ></div>
                    <div
                      style={{
                        padding: "5px",
                        top: "30px",
                        width: "56px",
                      }}
                      className="bank_high_to_low_select_scroll"
                    >
                      {dropdownOptions.map((value) => (
                        <div
                          key={value}
                          style={{
                            width: "47px",
                            backgroundColor:
                              selectedValue == value && "#244277",
                          }}
                          className="owner_select_scroll_com"
                          onClick={() => handleSelect(value)}
                        >
                          {value}
                          <div
                            style={{
                              width: "56px",
                              display: selectedValue == value ? "flex" : "none",
                              textAlign: "right",
                              justifyContent: "end",
                              paddingRight: "1px",
                            }}
                          >
                            <img
                              style={{
                                height: "13px",
                                width: "11px",
                              }}
                              src={CheckIcon}
                              alt="check"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="pagination-containers">
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Prev"
                >
                  <div
                    onClick={() => {
                      if (currentPage !== 1 || currentPage > 1) {
                        prevPage();
                      }
                    }}
                    style={{
                      height: "36px",
                      width: "34px",
                      paddingTop: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={ArrowLeft}
                      alt=""
                      style={{
                        opacity: currentPage === 1 ? 0.5 : 1,
                      }}
                      className="arrow-images"
                    />
                  </div>
                </Tooltip>
                <TextField
                  size="small"
                  type="text"
                  id="outlined-basic"
                  variant="standard"
                  onBlur={handleBlur}
                  value={currentPageText}
                  onChange={handlePagination}
                  InputLabelProps={{
                    style: { color: "#244277 !important", borderColor: "#fff" },
                  }}
                  InputProps={{
                    style: {
                      font: "normal normal 600 14px/20px Open Sans",
                      padding: "0px 8px",
                      height: "36px",
                      width: "34px",
                      color: "#244277 !important",
                    },
                  }}
                  color="info"
                  sx={{
                    borderColor: "#fff !important",
                    backgroundColor: "white",
                    marginTop: "1px",
                    color: "#244277 !important",
                    font: "normal normal 600 12px/17px Open Sans",
                    width: "34px ",
                    height: "34px !important",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#fff !important",
                        color: "#244277 !important",
                      },
                    },
                  }}
                />
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Next "
                >
                  <div
                    onClick={() => {
                      if (currentPage < mainData?.title?.total_pages) {
                        nextPage();
                      }
                    }}
                    style={{
                      height: "36px",
                      width: "68px",
                      padding: "10px 0px 0px 0px",
                      color: "#f9f9f9",
                      display: "flex",
                      justifyContent: "space-evenly",
                      font: "normal normal normal 13px/17px Open Sans",
                    }}
                  >
                    <p id="id-firstLetter">
                      of {mainData?.title?.total_pages || 1}
                    </p>
                    <img
                      src={ArrowRight}
                      style={{
                        opacity:
                          currentPage >= mainData?.title?.total_pages ||
                          mainData?.title?.total_pages === undefined
                            ? 0.5
                            : 1,
                      }}
                      alt=""
                      className="arrow-images"
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="toggle-containers">
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Grid"
                >
                  <Box
                    onClick={() => {
                      setFailedRefreshId([]);
                      setSuccessRefreshId([]);
                      dispatch(setToggle(false));
                      setCurrentPage(1);
                      setCurrentPageText("01");
                    }}
                    sx={{
                      backgroundColor: toggle ? "" : "#fff",
                    }}
                    className={`toggle-box`}
                  >
                    <img
                      src={toggle ? GridWhite : GridBlue}
                      alt=""
                      className="toggle-images"
                    />
                  </Box>
                </Tooltip>
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="List"
                >
                  <Box
                    onClick={() => {
                      setFailedRefreshId([]);
                      setSuccessRefreshId([]);
                      dispatch(setToggle(true));
                      setCurrentPage(1);
                      setCurrentPageText("01");
                    }}
                    sx={{
                      backgroundColor: toggle ? "#fff" : "",
                    }}
                    className={`toggle-box `}
                  >
                    <img
                      src={toggle ? ListBlue : ListWhite}
                      alt=""
                      className="toggle-images"
                    />
                  </Box>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className={`filter-transition-${filterOpen ? "open" : "close"}`}>
            {filterOpen && (
              <div ref={filterRef}>
                <div className="owner_filterdropdown">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "25%",
                      height: "157px",
                      paddingTop: "4px",
                    }}
                  >
                    <TypeOfAccountDropdown
                      selectTypeOfAccount={selectTypeOfAccount}
                      filterPayload={filterPayload}
                      setFilterPayload={setFilterPayload}
                      setSelectTypeofAccount={setSelectTypeofAccount}
                    />

                    <SelectBank
                      option={mainData?.summary?.available_banks || []}
                      filterPayload={filterPayload}
                      setFilterPayload={setFilterPayload}
                      selectBank={showSelectBank}
                      setSelectBank={setShowSelectBank}
                      owner={true}
                    />
                  </div>
                  <div className="owner-border-div"></div>
                  <div
                    style={{
                      paddingTop: "40px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                      width: "25%",
                      gap: "25px",
                      height: "270px",
                      paddingLeft: "10px",
                      paddingRight: "5px",
                    }}
                  >
                    <div>
                      <p className="owner_account_number_text">
                        Account number
                      </p>

                      <input
                        className="owner_account_number_text_input"
                        type="text"
                        value={filterPayload.accountnumber.toUpperCase()}
                        onChange={handleInput}
                        name="accountnumber"
                        placeholder="Enter account number"
                      />
                    </div>
                    <div>
                      <p className="owner_account_number_text">IFSC code</p>

                      <input
                        maxLength={11}
                        className="owner_account_number_text_input"
                        type="text"
                        onChange={handleInput}
                        value={filterPayload.ifscnumber.toUpperCase()}
                        name="ifscnumber"
                        placeholder="Enter IFSC code"
                      />
                    </div>
                    <div>
                      <p className="owner_account_number_text">
                        Note : Account number and IFSC code must be filled to
                        filter by bank details
                      </p>
                    </div>
                  </div>
                  <div className="owner-border-div"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "25%",
                      gap: "25px",
                      height: "194px",
                      paddingTop: "40px",
                    }}
                  >
                    <ConnectedBankingDropdown
                      filterPayload={filterPayload}
                      setFilterPayload={setFilterPayload}
                      selectConnectedBanking={selectConnectedBanking}
                      setSelectConnectedBanking={setSelectConnectedBanking}
                      owner={true}
                    />
                    <div>
                      <p className="owner_account_number_text">PAN number</p>

                      <input
                        className="owner_account_number_text_input "
                        type="text"
                        onChange={handleInput}
                        value={filterPayload.pannumber.toUpperCase()}
                        name="pannumber"
                        placeholder="Enter PAN number"
                      />
                    </div>
                  </div>
                  <div className="owner-border-div"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "25%",
                      height: "290px",
                      paddingTop: "40px",
                    }}
                  >
                    <div
                      style={{
                        height: "118px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <SelectAccountBalance
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        selectSortAmt={selectSortAmt}
                        setSelectSortAmt={setSelectSortAmt}
                        owner={true}
                      />

                      <div style={{ position: "relative" }}>
                        <p
                          style={{
                            color: "#C9C9C9",
                            height: "22px",
                            fontSize: "12px",
                            marginBottom: "5px",
                          }}
                        >
                          Assigned tags
                        </p>
                        <div
                          className="owner_connected_bank"
                          style={{
                            height: "37px",
                            opacity: "0.5",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              color: "#dadada",
                              padding: "8px 15px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              Select tags
                            </span>
                            <img
                              src={Arrowdonw5}
                              alt="down"
                              style={{
                                height: "12px",
                                transform: "rotate(270deg)",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="buttoncontainer">
                      <div
                        style={{
                          cursor: handleApplyEnable() ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (
                            filterPayload.typeofaccount.length > 0 ||
                            filterPayload.accountnumber.length > 0 ||
                            filterPayload.connectedBanking.length > 0 ||
                            filterPayload.bankNameInput.length > 0 ||
                            filterPayload.ifscnumber.length > 0 ||
                            filterPayload.pannumber.length > 0 ||
                            filterPayload.sortAccountNo.length > 0
                          ) {
                            setFilterPayload({
                              pagination: 0,
                              connectedBanking: "",
                              accNoInput: "",
                              sortBank: "",
                              sortAccountNo: "",
                              bankNameInput: "",
                              accountnumber: "",
                              pannumber: "",
                              ifscnumber: "",
                              typeofaccount: "",
                              panFilter: false,
                              BankFilter: false,
                              TypeAccFilter: false,
                              ConnectedFilter: false,
                              AccIfscFilter: false,
                            });
                            setCurrentPage(1);
                            setCurrentPageText("01");
                          }
                        }}
                        className={`clear ${
                          handleApplyEnable() ? "hover:bg-[#506994]" : ""
                        }`}
                      >
                        <p className="buttoncontainer-text">Clear</p>
                      </div>
                      <div
                        onClick={() => {
                          if (handleApplyEnable()) {
                            let obj = filterPayload;
                            if (
                              filterPayload.accountnumber.length > 3 &&
                              filterPayload.ifscnumber.length === 11
                            ) {
                              obj = {
                                ...obj,
                                AccIfscFilter: true,
                              };
                            }
                            if (filterPayload.pannumber.length === 10) {
                              obj = {
                                ...obj,
                                panFilter: true,
                              };
                            }
                            if (filterPayload.bankNameInput.length > 0) {
                              obj = {
                                ...obj,
                                BankFilter: true,
                              };
                            }
                            if (filterPayload.typeofaccount.length > 0) {
                              obj = {
                                ...obj,
                                TypeAccFilter: true,
                              };
                            }
                            if (filterPayload.connectedBanking.length > 0) {
                              obj = {
                                ...obj,
                                ConnectedFilter: true,
                              };
                            }
                            setFilterPayload(obj);
                            setFilterApply(true);
                            setCurrentPage(1);
                            setCurrentPageText("01");
                            setFilterOpen(false);
                          }
                        }}
                        className={`apply ${
                          handleApplyEnable() ? "hover:opacity-90" : ""
                        }`}
                        style={{
                          cursor: handleApplyEnable() ? "pointer" : "default",
                          backgroundColor: handleApplyEnable()
                            ? "#FB5963"
                            : "#707070",
                        }}
                      >
                        <p className="buttoncontainer-text">Apply</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="w-full mx-auto">
            {showSearchBar && (
              <RectangleSearchBar
                handleSearch={setSearchUserText}
                showSearchBar={showSearchBar}
                searchUserText={searchUserText}
                setSearchUserText={setSearchUserText}
                onPaste={handlePaste}
                toggleShowSearchBar={() => {
                  setFilterOpen(false);
                  setShowSearchBar(!showSearchBar);
                }}
                bgColor={"bg-[#1D3A6D]"}
                bgHover={"hover:bg-custom-cardHover"}
                setCurrentPage={setCurrentPage}
                setCurrentPageText={setCurrentPageText}
                searchRef={searchRef}
              />
            )}
          </div>
        </div>
        {filterApply &&
          (filterPayload.AccIfscFilter ||
            filterPayload.BankFilter ||
            filterPayload.ConnectedFilter ||
            filterPayload.TypeAccFilter ||
            filterPayload.panFilter) && (
            <div className="filter_applied_div w-full">
              <div
                style={{
                  borderTop: "1px solid #031F4F",
                }}
                className="w-[1024px] mx-auto py-[20px] flex"
              >
                <p className="filterby_text">Filtered by :</p>
                <div className="filter_badge_div">
                  {filterPayload.typeofaccount.length > 0 &&
                    filterPayload.TypeAccFilter && (
                      <div className="filter_badge">
                        <p className="filter_badge_text">
                          {filterPayload.typeofaccount}
                        </p>
                        <img
                          onClick={() => {
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                ifscnumber: "",
                                TypeAccFilter: false,
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                pannumber: "",
                                TypeAccFilter: false,
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                accountnumber: "",
                                ifscnumber: "",
                                TypeAccFilter: false,
                              };
                            } else {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                TypeAccFilter: false,
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilter}
                          alt="X"
                        />
                      </div>
                    )}
                  {filterPayload.sortAccountNo.length > 0 && (
                    <div className="filter_badge">
                      <p className="filter_badge_text">
                        {filterPayload.sortAccountNo}
                      </p>
                      <img
                        onClick={() => {
                          let obj = filterPayload;
                          if (filterPayload.ifscnumber.length !== 11) {
                            obj = {
                              ...obj,
                              sortAccountNo: "",
                              ifscnumber: "",
                            };
                          }
                          if (filterPayload.pannumber.length != 10) {
                            obj = {
                              ...obj,
                              sortAccountNo: "",
                              pannumber: "",
                            };
                          }
                          if (filterPayload.accountnumber.length < 4) {
                            obj = {
                              ...obj,
                              sortAccountNo: "",
                              accountnumber: "",
                              ifscnumber: "",
                            };
                          } else {
                            obj = {
                              ...obj,
                              sortAccountNo: "",
                            };
                          }
                          setFilterPayload(obj);
                        }}
                        className="filter_badge_img"
                        src={clearFilter}
                        alt="X"
                      />
                    </div>
                  )}
                  {filterPayload.connectedBanking.length > 0 &&
                    filterPayload.ConnectedFilter && (
                      <div className="filter_badge">
                        <p className="filter_badge_text">
                          {filterPayload.connectedBanking}
                        </p>
                        <img
                          onClick={() => {
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                ifscnumber: "",
                                ConnectedFilter: false,
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                pannumber: "",
                                ConnectedFilter: false,
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                accountnumber: "",
                                ifscnumber: "",
                                ConnectedFilter: false,
                              };
                            } else {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                ConnectedFilter: false,
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilter}
                          alt="X"
                        />
                      </div>
                    )}
                  {filterPayload.bankNameInput.length > 0 &&
                    filterPayload.BankFilter && (
                      <div className="filter_badge">
                        <p className="filter_badge_text">
                          {filterPayload.bankNameInput}
                        </p>
                        <img
                          onClick={() => {
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                ifscnumber: "",
                                BankFilter: false,
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                pannumber: "",
                                BankFilter: false,
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                accountnumber: "",
                                ifscnumber: "",
                                BankFilter: false,
                              };
                            } else {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                BankFilter: false,
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilter}
                          alt="X"
                        />
                      </div>
                    )}
                  {filterPayload.accountnumber.length > 0 &&
                    filterPayload.ifscnumber.length === 11 &&
                    filterPayload.AccIfscFilter && (
                      <div className="filter_badge">
                        <p className="filter_badge_text">
                          {filterPayload.accountnumber}
                        </p>
                        <img
                          onClick={() => {
                            if (filterPayload.pannumber.length != 10) {
                              const obj = {
                                ...filterPayload,
                                accountnumber: "",
                                ifscnumber: "",
                                pannumber: "",
                                AccIfscFilter: false,
                              };
                              setFilterPayload(obj);
                            } else {
                              const obj = {
                                ...filterPayload,
                                accountnumber: "",
                                ifscnumber: "",
                                AccIfscFilter: false,
                              };
                              setFilterPayload(obj);
                            }
                          }}
                          className="filter_badge_img"
                          src={clearFilter}
                          alt="X"
                        />
                      </div>
                    )}
                  {filterPayload.pannumber.length > 9 &&
                    filterPayload.panFilter && (
                      <div className="filter_badge">
                        <p className="filter_badge_text">
                          {filterPayload.pannumber}
                        </p>
                        <img
                          onClick={() => {
                            if (
                              filterPayload.accountnumber.length < 4 ||
                              filterPayload.ifscnumber.length !== 11
                            ) {
                              const obj = {
                                ...filterPayload,
                                accountnumber: "",
                                ifscnumber: "",
                                pannumber: "",
                                panFilter: false,
                              };
                              setFilterPayload(obj);
                            } else {
                              const obj = {
                                ...filterPayload,
                                pannumber: "",
                                panFilter: false,
                              };
                              setFilterPayload(obj);
                            }
                          }}
                          className="filter_badge_img"
                          src={clearFilter}
                          alt="X"
                        />
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        <div
          className={
            filterOpen
              ? "overflow-y-hidden h-screen ownerviewallscroll"
              : "overflow-y-auto h-screen mb-[5px] ownerviewallscroll"
          }
        >
          <div className="balance_type_container">
            <div className="select_balance_type">
              <p
                style={{ color: "#dadada", marginRight: "10px" }}
                className="select_balance_text"
              >
                Balance:{" "}
              </p>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#667CA3",
                  width: selectAmtType == "Lakhs" ? "45px" : "51px",
                  height: "26px",
                  left: handleSelectAmt(),
                  borderRadius: "4px",
                  zIndex: "1",
                  transition: "all 0.2s ease-in",
                }}
              ></div>
              <div
                onClick={() => setSelectAmtType("Default")}
                className="select_balance_btn"
              >
                <p className="select_balance_text">Default</p>
              </div>
              <div
                style={{ width: "55px" }}
                onClick={() => setSelectAmtType("Lakhs")}
                className="select_balance_btn"
              >
                <p style={{ width: "45px" }} className="select_balance_text">
                  Lakhs
                </p>
              </div>
              <div
                onClick={() => setSelectAmtType("Crores")}
                className="select_balance_btn"
              >
                <p className="select_balance_text">Crores</p>
              </div>
              <div
                onClick={() => setSelectAmtType("Millions")}
                className="select_balance_btn"
              >
                <p className="select_balance_text">Millions</p>
              </div>
              <div
                onClick={() => setSelectAmtType("Billions")}
                className="select_balance_btn"
              >
                <p className="select_balance_text">Billions</p>
              </div>
            </div>
            <div
              onClick={() =>
                splitView == "" ? setSplitView(true) : setSplitView(!splitView)
              }
              className="split_view"
            >
              <img
                className={`${
                  splitView
                    ? "animm-img"
                    : splitView !== ""
                    ? "split_view_img"
                    : "split_view_img_def"
                }`}
                src={splitView ? SplitView2 : SplitView1}
                alt="split"
              />
              <p
                className={`${
                  splitView
                    ? "animm-text"
                    : splitView !== ""
                    ? "split_view_text"
                    : "split_view_text_def"
                }`}
              >
                Split view
              </p>
            </div>
          </div>
          <div className={filterOpen ? `h-full bg-black opacity-80` : ""}>
            <div className="flex flex-col items-center justify-center">
              {loading ? (
                <div className="owner_loader-container m-auto justify-center items-center flex">
                  <img
                    style={{ width: "50px", height: "50px" }}
                    src={dataLoading}
                    alt=""
                  />
                </div>
              ) : filteredData?.length > 0 ||
                accumulatedData?.length > 0 ||
                splitView ? (
                <div
                  className={`flex flex-col items-center justify-center p-1 mx-auto w-full ${
                    filterOpen && "bg-black opacity-10 z-[-9999]"
                  }`}
                >
                  {!toggle ? (
                    <OwnerViewAllCard
                      isopen={filterOpen}
                      setFailedRefreshId={setFailedRefreshId}
                      failedRefreshId={failedRefreshId}
                      successRefreshId={successRefreshId}
                      setSuccessRefreshId={setSuccessRefreshId}
                      handleRefresh={handleRefresh}
                      data={filteredData}
                      page={currentPage}
                      refreshAllData={refreshAllData}
                      refreshType={refreshType}
                      setRefreshtype={setRefreshtype}
                      setId={setID}
                      setPrev_Total_balance_state={setPrev_Total_balance_state}
                      total_balance={total_balance_state}
                      currency={selectAmtType}
                      splitView={splitView}
                      postiveBalanceData={postiveBalanceData}
                      negativeBalanceData={negativeBalanceData}
                    />
                  ) : (
                    <ListView
                      handleRefresh={handleRefresh}
                      data={filteredData}
                      isopen={filterOpen}
                      page={currentPage}
                      refreshAllData={refreshAllData}
                      refreshType={refreshType}
                      setRefreshtype={setRefreshtype}
                      setFailedRefreshId={setFailedRefreshId}
                      failedRefreshId={failedRefreshId}
                      successRefreshId={successRefreshId}
                      setSuccessRefreshId={setSuccessRefreshId}
                      currency={selectAmtType}
                      splitView={splitView}
                      postiveBalanceData={postiveBalanceData}
                      negativeBalanceData={negativeBalanceData}
                    />
                  )}
                </div>
              ) : (
                <div
                  className={
                    filterOpen
                      ? `h-full bg-black opacity-80 flex flex-col items-center justify-center m-auto`
                      : "flex flex-col items-center justify-center m-auto"
                  }
                >
                  <img
                    src={noaccount}
                    alt="No accounts found"
                    className={
                      filterOpen ? "no-acc-image-opacity" : "no-acc-image"
                    }
                  />
                  <p
                    className={
                      filterOpen
                        ? "no-owneraccount-text-opacity"
                        : "no-owneraccount-text"
                    }
                  >
                    No accounts found.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerAccViewAll;
