import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const approveVendorAccount = createAsyncThunk(
    "approveVendorAccount",
    async (values) => {
        const { status, vendor_id, denied_reason } = values
            ? values
            : {
                status: null,
                vendor_id: null,
                reason: null,
            };
        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
            body: JSON.stringify({
                action: status,
                vendor_id: vendor_id,
                reason: denied_reason ? denied_reason : "N/A",
            }),
        };
        console.log("requestOptions", requestOptions);
        return await fetch(
            `${legacyUrl}/accounts/vendor/action`,
            requestOptions
        )
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
);

export const approveFieldForceAccount = createAsyncThunk(
    "approveFieldForceAccount",
    async (values) => {
        const { status, account_id, denied_reason } = values
            ? values
            : {
                status: null,
                account_id: null,
                reason: null,
            };
        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
            body: JSON.stringify({
                action: status,
                account_id: account_id,
                reason: denied_reason ? denied_reason : "N/A",
            }),
        };
        console.log("requestOptions", requestOptions);
        return await fetch(
            `${legacyUrl}/accounts/field_force/action`,
            requestOptions
        )
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                console.log("error", error);
            });
    }
);

const approveAccountSlice = createSlice({
    name: "approveAccount",
    initialState: {
        vendor: null,
        isError: false,
        reimbursement: null,
        ff: null,
    },
    extraReducers: (builder) => {
        //vendor
        builder.addCase(approveVendorAccount.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(approveVendorAccount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.vendor = action.payload;
        });
        builder.addCase(approveVendorAccount.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        });
        //field force
        builder.addCase(approveFieldForceAccount.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(approveFieldForceAccount.fulfilled, (state, action) => {
            state.isLoading = false;
            state.fieldForce = action.payload;
        });
        builder.addCase(approveFieldForceAccount.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        });
    },
});

export default approveAccountSlice.reducer;
