import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectAcc from "../../../../assets/CreateTransactionIcons/Select account.png";
import loadingLogo from "../../../../assets/Approve transactions/inputloder.gif";

import {
  verifyThisPAN,
  verifyThisBank,
  resetBankVerification,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { checkUsertoken } from "../../../../redux/features/login/login";
import verifyLogo from "../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";

// import "./index.css";
import trimSentence from "../../../../utils/trimSentence";
import Validation from "../../../../utils/validation";
import { toast } from "react-toastify";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import BankAccIcon from "../../../../assets/Accounts/Create/Bank.png";
import IfscIcon from "../../../../assets/Accounts/Create/IFSC.png";
import AccountTypeIcon from "../../../../assets/Accounts/Create/Type.png";
import DropdownLogo from "../../../../assets/ViewAllTransactions/Dropdown red.png";

import { all } from "axios";
const EnterOwnerBankDetails = (props) => {
  // validations
  const panStatus = useSelector((state) => state.verify.verifyPAN);
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const bankStatusLoading = useSelector(
    (state) => state.verify.verifyBankisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [gstinValidation, setGstinValidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;
  const [toggleDropdown, setToggleDropdown] = useState(true);

  const dispatch = useDispatch();
  const disabledToggle = Boolean(props.disabledToggle);
  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setToggleDropdown(true);
      }
    }

    if (toggleDropdown) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const modifyValue = (type, value) => {
    if (
      type === "pan_no" ||
      type === "bank_acc_no" ||
      type === "ifsc_code" ||
      type === "gstin"
    ) {
      return value?.toUpperCase();
    }
    if (type === "email_id") {
      return value?.toLowerCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "bank_acc_no") return Validation.allowedBankAccNum(value);
    if (name === "ifsc_code") return Validation.allowedBankIfsc(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "ifsc_code")
      errors = Validation.validateIfsc(name, value, errors);
    if (name === "bank_acc_no")
      errors = Validation.validateBankAcc(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.payload, [name]: value };
      props.setPayload(newObj);
    }
    if (props.payload.bank_acc_no.length === 0) {
      setBankVerificationStatus(false);
    }

    if (
      props.payload.ifsc_code.length === 0 ||
      props.payload.ifsc_code.length < 11
    ) {
      setBankVerificationStatus(false);
    }
    props?.enableNextButton(false);
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (!errorMessage && name === "ifsc_code" && bankValuesFilled())
      errorMessage = customErrors["bank_acc_no"];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (bankStatus?.errors) {
      let errors = { ...customErrors };
      errors["bank_acc_no"] = bankStatus.errors;
      setCustomErrors(errors);
      props.setPayload({ ...props.payload, skip_account_verification: "Y" });
      setBankVerificationStatus(false);
      props?.enableNextButton(true);
    }

    if (bankStatus?.status === true) {
      let errors = { ...customErrors };
      delete errors["bank_acc_no"];
      setCustomErrors(errors);
      props.setPayload({
        ...props.payload,
        acc_holder_name: bankStatus?.verification_details[0].legal_name,
        skip_account_verification: "N",
      });
      setBankVerificationStatus(true);
      props?.enableNextButton(true);
    }
  }, [bankStatus]);

  useEffect(() => {
    if (customErrors["bank_acc_no"]) setBankVerificationStatus(false);
    if (customErrors["ifsc_code"]) setBankVerificationStatus(false);
  }, [customErrors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bankValuesFilled = () => {
    return (
      props.payload.bank_acc_no.length >= 4 &&
      props.payload.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.payload.ifsc_code)
    );
  };

  useEffect(() => {
    if (
      props.payload.bank_acc_no.length >= 4 &&
      props.payload.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.payload.ifsc_code)
    ) {
      dispatch(checkUsertoken());
      dispatch(
        verifyThisBank({
          ifsc_code: props.payload.ifsc_code,
          bank_acc_no: props.payload.bank_acc_no,
        })
      );
    }
  }, [props.payload.ifsc_code, props.payload.bank_acc_no]);

  const handleFeatureChange = (value) => {
    props.setPayload({ ...props.payload, account_type: value });
  };

  const getBankVerifiedElementDiv = (isLoading, bankDetails, ifscDetails) => {
    return (
      <div
        className={`min-w-full flex flex-col self-center gap-2
        ${bankValuesFilled() && "-mx-1"}`}
      >
        <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
          <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
            <img src={BankAccIcon} alt="" srcSet="" />
          </div>
          <TextField
            className="w-full antialiased text-xs"
            inputProps={{
              style: {
                color: "white",
                borderBottom: "1px solid white",
              },
              maxLength: bankDetails.maxLength,
              readOnly: props.activeStep === 2,
            }}
            InputLabelProps={{
              style: {
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontWeight: 100,
                fontSize: "1.0 rem",
              },
            }}
            InputProps={{
              disableUnderline: true,
              readOnly: props.activeStep === 2,
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading && (
                    <img
                      src={loadingLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  )}
                  {!isLoading &&
                  bankVerificationStatus &&
                  bankDetails.value?.length >= 4 ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : null}
                  {!isLoading &&
                  bankDetails.value?.length > 0 &&
                  customErrors["bank_acc_no"] ? (
                    <img
                      src={skippedVerification}
                      onClick={() => {
                        showErrors(bankDetails.name);
                      }}
                      alt="verified logo"
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            label={bankDetails.label}
            variant="standard"
            name={bankDetails.name}
            onChange={handleChange}
            value={bankDetails.value}
            readOnly={disabledToggle}
            autoComplete="off"
          />
        </div>
        <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
          <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
            <img src={IfscIcon} alt="" srcSet="" />
          </div>
          <TextField
            className="w-full antialiased text-xs"
            inputProps={{
              style: {
                color: "white",
                borderBottom: "1px solid white",
              },
              maxLength: ifscDetails.maxLength,
              readOnly: props.activeStep === 2,
            }}
            InputLabelProps={{
              style: {
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontWeight: 100,
                fontSize: "1.0 rem",
              },
            }}
            InputProps={{
              disableUnderline: true,
              readOnly: props.activeStep === 2,
              endAdornment: (
                <InputAdornment position="end">
                  {isLoading && (
                    <img
                      src={loadingLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  )}

                  {!isLoading &&
                  bankVerificationStatus &&
                  ifscDetails.value?.length === 11 ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : null}
                  {!isLoading &&
                  ((ifscDetails.value?.length <= 11 &&
                    customErrors["ifsc_code"] &&
                    ifscDetails.value?.length > 0) ||
                    (bankValuesFilled() && customErrors["bank_acc_no"])) ? (
                    <img
                      src={skippedVerification}
                      onClick={() => {
                        showErrors(ifscDetails.name);
                      }}
                      alt="verified logo"
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
            label={ifscDetails.label}
            variant="standard"
            name={ifscDetails.name}
            onChange={handleChange}
            value={ifscDetails.value}
            readOnly={disabledToggle}
            autoComplete="off"
          />
        </div>
      </div>
    );
  };

  const bankVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-col
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        <div
          className={`min-w-full
          flex justify-center py-1
         px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {getBankVerifiedElementDiv(
              bankStatusLoading,
              {
                name: "bank_acc_no",
                status: bankVerificationStatus,
                label: "Bank account number",
                value: props.payload.bank_acc_no,
                maxLength: 15,
              },
              {
                name: "ifsc_code",
                status: bankVerificationStatus,
                label: "Bank IFSC code",
                value: props.payload.ifsc_code,
                maxLength: 11,
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`p-2 pb-0 ${
        bankValuesFilled() && !bankVerificationStatus && bankStatus?.errors
          ? "bg-custom-red"
          : "bg-custom-lightGreen"
      } flex flex-col relative
      shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-4`}
    >
      <div className="z-20">
        <div
          className="-m-2 bg-[#9777AC]
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
         p-4 md:p-6 lg:p-8 xl:p-10 4xl:p-24"
        >
          <div
            className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
          >
            {props.title}
          </div>
        </div>
        <div
          className="-m-2
          pb-4 bg-[#9777AC] rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col
          drop-shadow-xl
          gap-4 2xl:gap-6 4xl:gap-8"
        >
          <div
            className="w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
          gap-2"
          >
            <div className="w-full">{bankVerificationDiv()}</div>
            <div className="max-h-80  w-full pt-4 flex flex-row items-start gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 self-center">
              <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
                <img src={AccountTypeIcon} alt="" srcSet="" />
              </div>
              <div
                style={{
                  pointerEvents: props.activeStep === 2 ? "none" : "auto",
                }}
                className="flex flex-col w-full gap-4"
              >
                <div
                  className="text-white capitalize
                    text-md 3xl:text-lg 5xl:text-xl truncate"
                >
                  Account type
                </div>
                {toggleDropdown ? (
                  <div
                    onClick={(e) => {
                      setToggleDropdown(false);
                      e.stopPropagation();
                    }}
                    className="w-full flex flex-row justify-between items-center bg-[#744991] px-5 py-2 rounded-lg cursor-pointer"
                  >
                    <div className="text-white text-lg 3xl:text-xl 5xl:2xl">
                      {props.payload.account_type === ""
                        ? "Select account type"
                        : props.payload.account_type}
                    </div>
                    <div>
                      <img alt="" src={DropdownLogo} className="w-6 h-6" />
                    </div>
                  </div>
                ) : (
                  <div className="relative z-20">
                    <div
                      ref={dropdownRef}
                      className="w-full overflow-y-auto overflow-x-hidden hide-scrollbar vendor-txn account_type absolute z-20 max-h-80 flex flex-col justify-between bg-[#B8A3C6] border-[#E25869] border px-5 py-2 rounded-lg"
                    >
                      <div>
                        {[
                          "Current account",
                          "Special current account",
                          "Nodal account",
                          "Savings account",
                          "Salary account",
                          "Fixed deposit account",
                          "Recurring deposit account",
                        ].map((item) => (
                          <button
                            onClick={() => {
                              handleFeatureChange(item);
                              setToggleDropdown(true);
                            }}
                            className="text-white text-lg 3xl:text-xl 5xl:2xl bg-[#B8A3C6] hover:bg-[#744991] my-1 w-full p-2 flex justify-start rounded-lg"
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TextField
              className="w-full antialiased text-xs"
              inputProps={{
                style: {
                  color: "white",
                  borderBottom: "1px solid white",
                },
                maxLength: 10,
              }}
              InputLabelProps={{
                style: {
                  color: "#FFFFFF",
                  fontFamily: "Open Sans",
                  fontWeight: 100,
                  fontSize: "1.0 rem",
                },
              }}
              InputProps={{
                disableUnderline: true,
                readOnly: props.activeStep === 2,
              }}
              label="HDFC UMRN number (Optional)"
              variant="standard"
              name="hdfc_umrn"
              onChange={handleChange}
              value={props.payload.gstin}
              readOnly={disabledToggle}
              autoComplete="off"
            />
            <div className="text-[#FFC83C] py-4">
              {" "}
              Note :This account should not be owned by Qa Pvt Ltd{" "}
            </div>
          </div>
        </div>
      </div>
      {bankValuesFilled() &&
      props.activeStep !== 3 &&
      bankVerificationStatus &&
      !customErrors["bank_acc_no"] &&
      bankStatus?.verification_details?.length > 0 ? (
        <div
          className="bg-custom-lightGreen h-28 rounded-b-4xl 4xl:rounded-b-large 5xl:rounded-b-customXl 
            flex w-full p-2 gap-4 flex-col justify-between overflow-hidden"
          style={{}}
        >
          <div className="flex flex-row">
            <div className="w-[60%] flex flex-row gap-2">
              <div className="flex flex-row w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                <img
                  className="self-center p-1"
                  src={verifyLogo}
                  alt="verified logo"
                />
              </div>
              <div className="flex flex-row h-full w-[90%]">
                <div
                  className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                >
                  {bankStatus?.verification_details[0].legal_name}
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-end w-[40%]">
              <div
                className="self-center
                antaliased text-white text-xs "
              >
                Account holders name
              </div>
            </div>
          </div>
          <div className="flex absolute pointer-events-none h-full w-full bottom-0 right-0 flex-row justify-end">
            <div
              className="w-1/2
                flex flex-col justify-end items-end"
            >
              <div
                className="w-2/3
                  drop-shadow-2xl
                  text-center p-2 flex flex-row justify-center
                  bg-white rounded-tl-large rounded-br-large "
              >
                <img
                  className="p-1 w-4/5"
                  src={require("../../../../assets/Banks/" +
                    bankLogoMapper(props.payload.bank_name) +
                    ".png")}
                  alt="BankLogo"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {bankValuesFilled() &&
      props.activeStep !== 3 &&
      !bankVerificationStatus &&
      bankStatus?.errors ? (
        <div
          className="
              h-28 -m-2 mb-0 relative"
        >
          <div className="flex flex-col h-full">
            <div className="bg-custom-red p-2 truncate flex rounded-b-4xl 4xl:rounded-b-large 5xl:rounded-b-customXl">
              <div className="p-2 flex flex-row items-start gap-2 pr-5">
                <div className="flex flex-col w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                  <img
                    className="p-1"
                    src={skippedVerification}
                    alt="verified logo"
                  />
                </div>

                <div
                  className="h-full flex flex-col justify-between p-1
                     text-white text-base"
                >
                  <div className="text-md font-extrabold">
                    SKIPPING VERIFICATION
                  </div>
                  <div className="flex font-normal">
                    •
                    <p className="ml-4 font-bold">
                      Account could not be verified
                    </p>
                  </div>
                  <div className="flex font-normal whitespace-nowrap truncate ...">
                    •
                    <p className="ml-4 font-bold">
                      Crosscheck details and proceed with caution
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-custom-dark-orange w-[85%] mx-auto rounded-b-large flex absolute left-0 right-0 -bottom-26 px-5 text-white">
            <div className="flex items-start flex-col  p-4">
              <div className="capitalize px-2 text-base italic font-semibold">
                BANK REMARK:
              </div>
              <div className="text-xs px-2 3xl:text-sm">
                IMPS maybe currently disabled for this <br /> account or this
                account may not exist.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default EnterOwnerBankDetails;
