import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import formatCurrency from "../../../utils/CurrencyFormater";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import NextDate from "../../NextDate";
import trimSentence from "../../../utils/trimSentence";

const TxnSettlePaymentLeftCard = (props) => {
  const totalSelected = props.totalSelected;
  const muliCardDesc = totalSelected === 1 ? false : true;
  return (
    <>
      {muliCardDesc ? (
        <Box className="SettleTxnContainer p-10 3xl:p-12 4xl:p-14 5xl:p-16 6xl:p-20 flex flex-col gap-5">
          <Box
            sx={{
              font: "normal normal 600 34px/46px Open Sans",
              textAlign: "center",
              letterSpacing: "0px",
              color: "#4DE659",
              opacity: "1",
            }}
          >
            <span>₹ {formatCurrency(props.amount)}</span>
          </Box>
          <Box className="px-6 2xl:px-8 3xl:px-8 4xl:p-x12 5xl:px-16 6xl:px-18 text-white text-2xl 4xl:text-3xl capitalize truncate ...">
            {totalSelected} transactions
          </Box>
          <Box className="estimatedDateSettleTxn">
            <span>
              {props.txnCompletionDate == 1 ? (
                <NextDate Tplus2 />
              ) : props.txnCompletionDate == 2 ? (
                <NextDate />
              ) : null}
            </span>
            <br />
            {props.txnCompletionDate != 0 ? (
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal normal 11px/15px Open Sans",
                  letterSpacing: "0px",
                  color: "#CDCDCD",
                  opacity: "1",
                }}
              >
                Estimated transaction completion on
              </span>
            ) : (
              <span />
            )}
          </Box>
        </Box>
      ) : (
        <Box className="txnStatusCard text-center">
          <h2 className="amountTextTxnCreated  p-4">
            {" "}
            ₹ {formatCurrency(props.amount)}
          </h2>
          {props.txnCompletionDate && (
            <Box className="estimatedDate2">
              <b>
                {" "}
                {props.txnCompletionDate === 2 ? (
                  <NextDate />
                ) : (
                  <NextDate Tplus2={true} />
                )}{" "}
              </b>{" "}
              <br />
              <span>Estimated transaction completion on</span>
            </Box>
          )}
          <Box className="txnCreatedIntName w-4/5 m-auto capitalize truncate ...">
            {props.vendor_name}
          </Box>
          <div className="mx-5">
            <Box className="dashedLine"></Box>
          </div>
          <Box className="detailsText">
            <div className="w-full ">
              <div className="w-full truncate ...">
                <b> {props.vendor_bank_acc_no}</b>
              </div>
              <p>Account number</p>
            </div>
            <Box className="simpleLine"></Box>
            <b>{props.vendor_ifsc_code}</b>
            <Box className="paraNImg">
              <p>IFSC code</p>
              <Box>
                <img
                  width={"101px"}
                  src={require("../../../assets/Banks/" +
                    bankLogoMapper(props.vendor_bank_name) +
                    ".png")}
                  alt=""
                  srcSet=""
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TxnSettlePaymentLeftCard;
