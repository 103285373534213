// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import "./index.css";
// import { Box } from "@mui/material";
// // import BeneTxnCardLogo from "../BeneTxnCardLogo";
// import formatDateViewAll from "../../../utils/formatDateViewAll";
// import IfscCodeMapper from "../../_utils/IfscLogoMapper";
// import salary from "../../../assets/Beneficiary Icons/salaries.png";

// const BeneTxnViewAllCard = ({
//   data,
//   isSidebarOpen,
//   numberOfPages,
//   isDistributionPopoverOpen,
//   isPopoverOpen,
// }) => {
//   const [hoveredCardIndex, setHoveredCardIndex] = useState(null);
//   const navigate = useNavigate();
//   const benealldata = data;

//   const getStatusColor = (status) => {
//     switch (status) {
//       case "pending":
//         return "#30e5e4";
//       case "approved":
//         return "#B196FA";
//       case "denied":
//       case "failed":
//         return "#ff5860";
//       case "abandoned":
//         return "#a3a3a3";
//       case "rejected":
//         return "#ff5860";
//       case "hold":
//         return "#FBC02D";
//       case "queued":
//         return "#FBC02D";
//       default:
//         return "#4ddd37";
//     }
//   };
//   const getDistriStatusColor = (status) => {
//     switch (status) {
//       case "awaiting_verification":
//       case "awaiting_funds":
//       case "distribution_initiated":
//       case "created":
//       case "distribution_pending":
//       case "approved":
//         return "#FBC02D";
//       case "denied":
//       case "failed":
//       case "rejected":
//         return "#ff5860";
//       case "abandoned":
//         return "#B4B4B4";
//       case "distribution_complete":
//         return "#4DDD37";
//       default:
//         return "#F9F9F9";
//     }
//   };
//   return (
//     <div
//       className={`beneTxnViewallCard ${isSidebarOpen ? "sidebar-open" : ""}`}
//       style={{
//         opacity:
//           isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//             ? "0.8"
//             : "1",
//         background:
//           isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//             ? "#000"
//             : "",
//         // height:
//         //   isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//         //     ? "100%"
//         //     : "",
//         overflowY:
//           isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//             ? "hidden"
//             : "",
//       }}
//     >
//       {benealldata?.map((transactions, index) => (
//         <Box
//           key={index}
//           className={`multiSelectBeneTxnViewAllCard relative ${
//             hoveredCardIndex === index && !isSidebarOpen ? "hovered" : ""
//           }`}
//           style={{
//             opacity:
//               isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//                 ? "0.8"
//                 : "1",
//             background:
//               isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//                 ? "#000"
//                 : "#3a5686",
//             cursor:
//               isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
//                 ? "default"
//                 : "pointer",
//             zIndex:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? 1
//                 : 0,
//             position:
//               (hoveredCardIndex === index && !isSidebarOpen) ||
//               !isPopoverOpen ||
//               !isDistributionPopoverOpen
//                 ? "relative"
//                 : "static",
//             overflow: "visible",
//             borderRadius:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "20px 20px 0px 0px"
//                 : "20px",
//             borderTop:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "1px solid #FF5860"
//                 : "none",
//             borderLeft:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "1px solid #FF5860"
//                 : "none",
//             borderRight:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "1px solid #FF5860"
//                 : "none",
//             transition:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "height 0.2s"
//                 : "none",
//             boxShadow:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "0px 3px 6px #00000061"
//                 : "none",
//             background:
//               hoveredCardIndex === index &&
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//                 ? "#506994"
//                 : "#3a5686",
//           }}
//           onClick={() => {
//             if (
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//             ) {
//               navigate(
//                 `/transaction/beneficiary_transaction/details/${transactions.txn_id}`
//                 // ,
//                 // {
//                 //   state: numberOfPages,
//                 // }
//               );
//             }
//           }}
//           onMouseEnter={() => {
//             if (
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//             ) {
//               setHoveredCardIndex(index);
//             }
//           }}
//           onMouseLeave={() => {
//             if (
//               !isSidebarOpen &&
//               !isPopoverOpen &&
//               !isDistributionPopoverOpen
//             ) {
//               setHoveredCardIndex(null);
//             }
//           }}
//         >
//           <div
//             className="beneTxn_viewall_details"
//             style={{
//               visibility: isSidebarOpen ? "visible" : "",
//               animation: isSidebarOpen ? "none" : "",
//             }}
//           >
//             <div className="beneTxn_view_all_user">
//               <div
//                 className="beneTxn_viewall_name_div"
//                 style={{
//                   visibility: isSidebarOpen ? "visible" : "",
//                   animation: isSidebarOpen ? "none" : "",
//                 }}
//               >
//                 <span
//                   style={{
//                     display: "inline-block",
//                     fontSize: "17px",
//                     paddingRight: "5px",
//                   }}
//                 >
//                   ₹
//                 </span>
//                 {Number(transactions.amount)?.toLocaleString("en-IN", {
//                   minimumFractionDigits: 2,
//                   maximumFractionDigits: 2,
//                 })}
//               </div>

//               <div className="beneTxn_viewall_bank_acc_div">
//                 {transactions.beneficiary_account_name}
//               </div>
//               <div className="bene_txn_viewall_inner_card_container flex flex-row justify-between w-[241px]">
//                 <div className="beneTxn_viewall_acc_title">
//                   {" "}
//                   {transactions.created_on
//                     ? formatDateViewAll(transactions.created_on, false, true)
//                     : "Not given"}
//                 </div>
//                 <div className="beneTxn_viewall_card_container mb-0 ">
//                   <div
//                     className={`flex justify-center group-hover:bg-blue-100 text-center bg-white `}
//                     style={{
//                       position: "relative",
//                       borderRadius:
//                         hoveredCardIndex === index
//                           ? "20px 0px 0px 20px"
//                           : "20px 0px 20px 20px",
//                     }}
//                   >
//                     <div className="beneTxn_viewall_category_div">
//                       <img
//                         className="w-[20px] h-[20px]"
//                         src={salary}
//                         alt="category"
//                       />
//                     </div>
//                     <div className="beneTxn_viewall_payment_mode_div">
//                       <img
//                         src={require("../../../assets/IfscLogo/" +
//                           IfscCodeMapper(
//                             transactions.beneficiary_account_ifsc_code
//                           ))}
//                         alt="bank"
//                         className="beneTxn_viewall_paymentmode_image"
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {!isSidebarOpen &&
//             !isPopoverOpen &&
//             !isDistributionPopoverOpen &&
//             hoveredCardIndex === index && (
//               <div className="bene_txn_viewall_hover_content">
//                 <div className="pl-[10px] pt-[5px]">
//                   <div
//                     className="text-sm pb-[4px]"
//                     style={{
//                       color: getStatusColor(transactions.lifecycle_status),
//                     }}
//                   >
//                     {transactions.lifecycle_status}
//                   </div>
//                   <div className="text-[#DADADA] text-[10px]">
//                     Approval Status
//                   </div>
//                 </div>
//                 <div className="pl-[10px] pt-[8px]">
//                   <div
//                     className="text-sm pb-[4px]"
//                     style={{
//                       color:
//                         transactions.distribution_lifecycle_status === "N/A"
//                           ? "#F9F9F9"
//                           : getDistriStatusColor(
//                               transactions.distribution_lifecycle_status
//                             ),
//                     }}
//                   >
//                     {transactions.distribution_lifecycle_status || "N/A"}
//                   </div>
//                   <div className="text-[#DADADA] text-[10px]">
//                     Distribution Status
//                   </div>
//                 </div>
//               </div>
//             )}
//         </Box>
//       ))}
//     </div>
//   );
// };

// export default BeneTxnViewAllCard;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Box } from "@mui/material";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import salary from "../../../assets/Beneficiary Icons/salaries.png";

const BeneTxnViewAllCard = ({
  data,
  isSidebarOpen,
  isDistributionPopoverOpen,
  isPopoverOpen,
}) => {
  const navigate = useNavigate();
  const benealldata = data;

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "failed":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      default:
        return "#4ddd37";
    }
  };

  const getDistriStatusColor = (status) => {
    switch (status) {
      case "awaiting_verification":
      case "awaiting_funds":
      case "distribution_initiated":
      case "created":
      case "distribution_pending":
      case "approved":
        return "#FBC02D";
      case "denied":
      case "failed":
      case "rejected":
        return "#ff5860";
      case "abandoned":
        return "#B4B4B4";
      case "distribution_complete":
        return "#4DDD37";
      default:
        return "#F9F9F9";
    }
  };

  return (
    <div
      className={`beneTxnViewallCard gap-[20px] ${
        isSidebarOpen ? "sidebar-open" : ""
      }`}
      style={{
        opacity:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "0.4"
            : "1",
        background:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "#000"
            : "",
      }}
    >
      {benealldata?.map((transactions, index) => (
        <div className="bene_txn_card-container" key={transactions.id}>
          <Box
            // key={index}
            className={`multiSelectBeneTxnViewAllCard relative hover:bg-[#506994] ${
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "disabled"
                : ""
            }`}
            onClick={() => {
              if (
                !isSidebarOpen &&
                !isPopoverOpen &&
                !isDistributionPopoverOpen
              ) {
                navigate(
                  `/transaction/beneficiary_transaction/details/${transactions.txn_id}`
                );
              }
            }}
          >
            <div
              className="beneTxn_viewall_details"
              style={{
                visibility: isSidebarOpen ? "visible" : "",
                animation: isSidebarOpen ? "none" : "",
              }}
            >
              <div className="beneTxn_view_all_user">
                <div className="beneTxn_viewall_name_div pl-[10px] pt-[6px]">
                  <span
                    style={{
                      display: "inline-block",
                      fontSize: "17px",
                      paddingRight: "5px",
                    }}
                  >
                    ₹
                  </span>
                  {Number(transactions.amount)?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div className="beneTxn_viewall_bank_acc_div pl-[10px]">
                  {transactions.beneficiary_account_name}
                </div>
                <div className="bene_txn_viewall_inner_card_container flex flex-row justify-between pl-[10px]">
                  <div className="beneTxn_viewall_acc_title">
                    {transactions.created_on
                      ? formatDateViewAll(transactions.created_on, false, true)
                      : "Not given"}
                  </div>
                  <div className="beneTxn_viewall_card_container mb-0 ">
                    <div
                      className="bene_txn_logo flex justify-center group-hover:bg-blue-100 text-center bg-white"
                      style={{
                        position: "relative",
                      }}
                    >
                      <div className="beneTxn_viewall_category_div">
                        <img
                          className="w-[20px] h-[20px]"
                          src={salary}
                          alt="category"
                        />
                      </div>
                      <div className="beneTxn_viewall_payment_mode_div">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(
                              transactions.beneficiary_account_ifsc_code
                            ))}
                          alt="bank"
                          className="beneTxn_viewall_paymentmode_image"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bene_txn_viewall_hover_content">
              <div className="pl-[10px] pt-[5px]">
                <div
                  className="text-sm pb-[4px]"
                  style={{
                    color: getStatusColor(transactions.lifecycle_status),
                  }}
                >
                  {transactions.lifecycle_status}
                </div>
                <div className="text-[#DADADA] text-[10px]">
                  Approval Status
                </div>
              </div>
              <div className="pl-[10px] pt-[8px]">
                <div
                  className="text-sm pb-[4px]"
                  style={{
                    color:
                      transactions.distribution_lifecycle_status === "N/A"
                        ? "#F9F9F9"
                        : getDistriStatusColor(
                            transactions.distribution_lifecycle_status
                          ),
                  }}
                >
                  {transactions.distribution_lifecycle_status || "N/A"}
                </div>
                <div className="text-[#DADADA] text-[10px]">
                  Distribution Status
                </div>
              </div>
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};

export default BeneTxnViewAllCard;
