import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.css";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import internalTransaction from "../../../../assets/Beneficiary Icons/Internal_transaction.png";
import errorIcon from "../../../../assets/Beneficiary Icons/error_with_white_outlined_circle.png";
import viewStatement from "../../../../assets/Beneficiary Icons/view_statement.png";
import assignTag from "../../../../assets/Beneficiary Icons/assign_tag.png";
import refreshBalance from "../../../../assets/Beneficiary Icons/refreshIcon.png";
import editIcon from "../../../../assets/Beneficiary Icons/edit_white.png";
import arrowRight from "../../../../assets/Accounts/Create/arrowright.png";
import {
  ownerAccDetail,
  addOwnerAccTags,
  removeOwnerAccTags,
  setTags,
  getOwnerAccBalance,
  setshowsuccestoast,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber/index";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast/index";
import BalanceLoader from "../../../../components/_utils/BalanceLoader";
import formatTotalBalance from "../../../../utils/formatTotalBalance";
import OwnerTransactionHistoryModal from "../../../../components/Accounts/OwnerAccounts/OwnerTransactionHistoryModal";
import OwnerDetailsSelectTag from "../../../../components/Accounts/OwnerAccounts/OwnerDetailsSelectTag";
import OwnerViewStatementModal from "../../../../components/Accounts/OwnerAccounts/OwnerViewStatementModal";
import transactionHistory from "../../../../assets/TxnDetails/new_transaction_history.png";
import OwnerAccountIFSC from "../OwnerAccountIFSC/index";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { Box, Button } from "@mui/material";
import Refreshicon from "../../../../assets/OwnerAccount/refresh.png";
import formatCurrency from "../../../../utils/CurrencyFormater";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import { ConstructionOutlined } from "@mui/icons-material";

function OwnerDetailsPage() {
  const [loading, setLoading] = useState(false);

  const [transactionOpenPop, setTransactionOpenPop] = useState(false);
  const [viewStatementOpenPop, setViewstatementOpenPop] = useState(false);
  const [selectedTagOpenPop, setSelectedTagOpenPop] = useState(false);

  const [ownerDetailData, setOwnerdetaildata] = useState([]);
  const [accId, setAccId] = useState("");
  const [currentConnectedBal, setCurrentConnectedBal] = useState("");
  const [prevConnectedBal, setPrevConnectedBal] = useState("");
  const [refreshedConnectedId, setRefreshedConnectedId] = useState("");
  const [isMore, setIsMore] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showAssignedTags, setShowAssignedTags] = useState(false);
  const [showDisplay, setShowDisplay] = useState(false);
  const [balanceFailed, setBalanceFailed] = useState(false);

  const [newTags, setNewTags] = useState([]);
  const [existingTags, setExistingTags] = useState([]);

  const haveName = newTags?.some((val) => val?.type === "ownerTags");

  const {
    ownerAccDetailLoading,
    ownerAccDetailData,
    ownerAccBalanceLoading,
    ownerAccBalanceError,
    ownerAccountAvailabletags,
    showSuccessToast,
    addOwnerAccTagsLoading,
    addOwnerAccTagsData,
    addOwnerAccTagsError,
    removeOwnerAccTagsLoading,
    removeOwnerAccTagsData,
    removeOwnerAccTagsError,
  } = useSelector((state) => state.ownerAllVerification);

  const services = useSelector((state) => state.refreshToken.services);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getOwnerDetaildata = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());
      const response = await dispatch(ownerAccDetail(id));
      setOwnerdetaildata(response?.payload?.owner_accounts[0]);

      if (
        response?.payload?.owner_accounts[0]?.tags &&
        response?.payload?.owner_accounts[0]?.tags.length > 0
      ) {
        const data = response?.payload?.owner_accounts[0].tags?.map((res) => {
          return { selected: false, value: res, type: "ownerTags" };
        });

        setNewTags(data);
      }

      // setNewTags([...response?.payload?.owner_accounts[0].tags]);
      setLoading(false);
      if (response?.error?.message == "Request failed with status code 404") {
        navigate("*");
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getOwnerDetaildata();
    if (ownerAccountAvailabletags?.length == 0) {
      let availableTags = sessionStorage?.getItem("AvailableTags");
      const parsedTags = JSON?.parse(availableTags, ...newTags);
      dispatch(setTags(parsedTags));
    }
  }, []);

  const handleAddTags = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());

      const selectedTags = newTags
        .filter((tag) => !tag.selected)
        .map((tag) => tag.value);

      let response = await dispatch(
        addOwnerAccTags({ owner_id: [id], tags: selectedTags })
      );

      if (response?.payload?.status === true) {
        // setNewTags(response?.payload.tags?.value);
        getOwnerDetaildata();
        setShowDisplay(false);
        setSelectedTagOpenPop(false);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch (error) {
      setLoading(false);
      // toast.error("An error occurred while trying to add tags");
    }
  };

  const handleRemoveTags = async () => {
    setLoading(true);
    try {
      dispatch(checkUsertoken());

      const selectedTags = newTags
        .filter((tag) => tag.selected)
        .map((tag) => tag.value);

      let response = await dispatch(
        removeOwnerAccTags({ owner_id: [id], tags: selectedTags })
      );
      if (response?.payload?.status == true) {
        getOwnerDetaildata();
        // setNewTags(response?.payload.tags?.value);
        setSelectedTagOpenPop(false);
        setShowDisplay(false);

        setLoading(false);
      } else {
        setLoading(false);
        toast.error(response?.payload?.message);
      }
    } catch {
      setLoading(false);
      // toast.error("An error occurred while trying to add tags");
    }
  };

  const handleToastClick = () => {
    toast.info("Currently in Development", toast_position);
  };

  const handleRefresh = async (ownerid) => {
    setPrevConnectedBal(ownerDetailData?.owner_account_balance);
    try {
      dispatch(checkUsertoken());
      setBalanceFailed(false);
      let response = await dispatch(getOwnerAccBalance(ownerid));
      if (response?.payload?.status == true) {
        setRefreshedConnectedId(id);
        setCurrentConnectedBal(
          response?.payload?.owner_accounts[0]?.owner_account_balance
        );

        setShowDisplay(true);

        setOwnerdetaildata((prevState) => {
          return {
            ...prevState,
            owner_account_balance_last_fetched_at_in_words:
              response?.payload?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
            owner_account_balance:
              response?.payload?.owner_accounts[0]?.owner_account_balance,
            owner_account_balance_last_fetched_at:
              response.payload?.owner_accounts[0]
                .owner_account_balance_last_fetched_at,
            difference: Math.abs(
              Number(prevState.owner_account_balance) -
                Number(response.payload.owner_accounts[0].owner_account_balance)
            ),
            increaseOrDecrease:
              Number(prevState.owner_account_balance) >
              Number(response.payload.owner_accounts[0].owner_account_balance)
                ? "decreased"
                : Number(prevState.owner_account_balance) <
                  Number(
                    response.payload.owner_accounts[0].owner_account_balance
                  )
                ? "increased"
                : "",
          };
        });
      } else {
        setTimeout(() => {
          toast.dismiss();

          setShowDisplay(true);
          setBalanceFailed(true);
          toast.error(response?.error?.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  const handleCloseModal = () => {
    setTransactionOpenPop(false);
    setViewstatementOpenPop(false);
    setSelectedTagOpenPop(false);
  };
  const handleSelectedtag = () => {
    setSelectedTagOpenPop(true);
  };

  const handleViewStatement = () => {
    setViewstatementOpenPop(true);
  };

  useEffect(() => {
    if (currentConnectedBal > prevConnectedBal) {
      setIsMore(true);
    } else {
      setIsMore(false);
    }
  }, [currentConnectedBal, prevConnectedBal]);

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }

    if (!ownerAccBalanceLoading && !ownerAccBalanceError) {
      setTimeout(() => {
        toast.dismiss();

        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  const toggleDetails = () => {
    setShowDetails(true);
    setShowAssignedTags(false);
  };

  const toggleAdditionalDetails = () => {
    setShowAssignedTags(true);
    setShowDetails(false);
  };

  return (
    <>
      <div
        style={{
          overflowY:
            transactionOpenPop || viewStatementOpenPop || selectedTagOpenPop
              ? "hidden"
              : "unset",
        }}
      >
        {transactionOpenPop && (
          <div className="fixed top-0 w-full  h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]">
            {<OwnerTransactionHistoryModal onClose={handleCloseModal} />}
          </div>
        )}
        {viewStatementOpenPop && (
          <div className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]">
            {
              <OwnerViewStatementModal
                statementId={id}
                allData={ownerDetailData}
                onClose={handleCloseModal}
              />
            }
          </div>
        )}
        {selectedTagOpenPop && (
          <div className="fixed top-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9999]">
            {
              <OwnerDetailsSelectTag
                onClose={handleCloseModal}
                handleAddTags={handleAddTags}
                existingTags={existingTags}
                setExistingTags={setExistingTags}
                handleRemoveTags={handleRemoveTags}
                newTags={newTags}
                setNewTags={setNewTags}
                ownerExistingTag={ownerDetailData?.tags}
              />
            }
          </div>
        )}
      </div>
      {loading ? (
        <div className="owner-loader-container m-auto justify-center items-center flex">
          <img src={dataLoading} alt="" />
        </div>
      ) : (
        <div className="content-page">
          <div className="container">
            <div className="acc-section">
              <div className="major-div">
                <div className="individual-bank-detail">
                  <div className="individual-account">
                    <span className="individual-account-holdername capitalize">
                      {ownerDetailData?.name?.toLowerCase()}
                    </span>
                    <div className="acc-number-label text-xs">Account name</div>
                  </div>
                  <div className="acc-balance">
                    {ownerDetailData?.connected_banking === "Y" ? (
                      <div className="acc-balance-content">
                        <div
                          className="text-sm"
                          style={{
                            fontWeight: "600",
                            color:
                              ownerDetailData?.owner_account_balance >= 0
                                ? "#4DE659"
                                : "#ff5860",
                            paddingRight: "20px",
                          }}
                        >
                          ₹{" "}
                          {
                            formatTotalBalance(
                              Number(ownerDetailData?.owner_account_balance)
                            ).amount
                          }
                        </div>

                        <span
                          style={{
                            position: "relative",
                            left: "-15px",
                            marginLeft: "5px",
                            top: "-5px",
                          }}
                        >
                          <AnimationUpAndDown
                            loader={ownerAccBalanceLoading}
                            currentId={refreshedConnectedId}
                            previousId={id}
                            currentBalance={currentConnectedBal}
                            previousBalance={prevConnectedBal}
                            refreshType={"single"}
                            classType={"owner_details"}
                          />
                        </span>
                      </div>
                    ) : (
                      <div className="acc-balance-content">
                        <div className={`text-sm`}>N/A</div>
                      </div>
                    )}
                    <div className="acc-number-balance  text-xs">
                      {ownerDetailData?.connected_banking === "Y" ? (
                        formatTotalBalance(
                          Number(ownerDetailData?.owner_account_balance)
                        ).prefix == "crores" ? (
                          <p>
                            Balance - In crores{" "}
                            {ownerDetailData?.connected_banking === "Y"
                              ? `- updated  ${ownerDetailData?.owner_account_balance_last_fetched_at_in_words}`
                              : null}
                          </p>
                        ) : (
                          <p>
                            Balance{" "}
                            {ownerDetailData?.connected_banking === "Y"
                              ? `- updated  ${ownerDetailData?.owner_account_balance_last_fetched_at_in_words}`
                              : null}
                          </p>
                        )
                      ) : (
                        <>
                          <p>
                            Balance{" "}
                            {ownerDetailData?.connected_banking === "Y"
                              ? `- updated  ${ownerDetailData?.owner_account_balance_last_fetched_at_in_words}`
                              : null}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="acc-status-main">
                  <div className="acc-connected">
                    <div
                      className={`text-base ${
                        ownerDetailData?.connected_banking === "Y"
                          ? "active-status"
                          : ownerDetailData?.connected_banking === "N"
                          ? "not-active-status"
                          : ""
                      }`}
                    >
                      {ownerDetailData?.connected_banking === "Y"
                        ? "Active"
                        : "Y" || ownerDetailData?.connected_banking === "N"
                        ? "Inactive"
                        : "N"}
                    </div>
                    <div className="acc-number-label">Connected banking</div>
                  </div>

                  {ownerDetailData?.connected_banking == "Y" &&
                  showDisplay &&
                  !balanceFailed ? (
                    <div className="acc-status-detail-page">
                      <div className="acc-status-content">
                        <div
                          className="text-sm"
                          style={{
                            fontWeight: "600",

                            paddingRight: "20px",
                            color:
                              ownerDetailData?.increaseOrDecrease ===
                              "increased"
                                ? "#4DE659"
                                : ownerDetailData?.increaseOrDecrease ===
                                  "decreased"
                                ? "#FF5860"
                                : "#F9F9F9",
                          }}
                        >
                          ₹{" "}
                          {
                            formatTotalBalance(ownerDetailData?.difference)
                              .amount
                          }
                        </div>
                        <div className="acc-number-label">
                          Balance{" "}
                          {ownerDetailData?.increaseOrDecrease == ""
                            ? "increased"
                            : ownerDetailData?.increaseOrDecrease}{" "}
                          by{" "}
                          {formatTotalBalance(ownerDetailData?.difference)
                            .prefix == "crores"
                            ? "- in crores"
                            : ""}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="bank-details">
                  <div className="accno-details">
                    <span className="accno-response">
                      {formatAccountNumber(ownerDetailData?.bank_acc_no)}
                    </span>
                    <div className="acc-number-label text-xs">
                      Account number
                    </div>
                  </div>
                  <div className="ifsc-div-main">
                    <div
                      onClick={() => {
                        if (services?.includes("VIFSC")) {
                          navigate(
                            `/accounts/owner/owner_account_details/IFSC_Details/${ownerDetailData?.ifsc_code}`
                          );
                        } else {
                          //
                        }
                      }}
                      className="ifsc-div-content"
                    >
                      <div
                        className={` ${
                          services?.includes("VIFSC")
                            ? "hover:bg-[#667CA3] cursor-pointer rounded-lg flex flex-row h-full w-full p-[2px]"
                            : "cursor-default"
                        }`}
                      >
                        <div className="ifsc-response ">
                          {ownerDetailData?.ifsc_code}
                        </div>
                        <div className="grid place-content-center w-5 h-5 ml-[10px] bg-[#1D3A6D] rounded-md ">
                          <img
                            src={arrowRight}
                            alt="document"
                            className={`w-2.5 h-2.5 ${
                              services?.includes("VIFSC")
                                ? "cursor-pointer"
                                : "cursor-default"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ifsc-code-element text-xs p-[2px]">
                      IFSC code
                    </div>
                  </div>
                </div>
              </div>

              <div className="minor-div">
                <div className="recipient flex flex-row py-5 bg-[#506994]">
                  <div className="recipient-box1 flex-grow  ">
                    <Box
                      className="text-center h-full flex flex-col justify-center gap-1 p-2"
                      sx={{
                        opacity:
                          ownerDetailData?.connected_banking == "Y"
                            ? "1"
                            : "0.5",
                        cursor:
                          ownerDetailData?.connected_banking == "Y"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      <div className="recipient-image-div flex justify-center">
                        <div className="refresh-div">
                          {ownerAccBalanceLoading &&
                          !ownerAccBalanceError &&
                          accId == ownerDetailData?.id ? (
                            <BalanceLoader
                              ownerDetails={"BalanceownerDetails"}
                              loader={ownerAccBalanceLoading}
                            />
                          ) : !ownerAccBalanceLoading &&
                            accId == ownerDetailData?.id ? (
                            <BalanceLoader
                              loader={ownerAccBalanceLoading}
                              setAccId={setAccId}
                              ownerDetails={"BalanceownerDetails"}
                            />
                          ) : (
                            <div
                              className={
                                ownerDetailData?.connected_banking == "Y"
                                  ? "hover:bg-[#667CA3] w-[102px] h-[90px] mr-2 p-1"
                                  : null
                              }
                            >
                              <Button
                                disabled={
                                  ownerDetailData?.connected_banking == "N"
                                }
                                onClick={() => {
                                  setAccId(ownerDetailData?.id);
                                  handleRefresh(ownerDetailData?.id);
                                }}
                                startIcon={
                                  <img
                                    className="refresh-img-icon"
                                    alt="refresh"
                                    src={refreshBalance}
                                  />
                                }
                                variant="text"
                                size="small"
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  fontSize: "10px",
                                  color: "#f9f9f9 !important",
                                  textTransform: "none",
                                }}
                              >
                                <div className="flex justify-center pr-2 mt-1">
                                  <Box className="image-label h-8/12 self-center antialiased truncate...">
                                    Refresh balance
                                  </Box>
                                </div>
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  </div>
                  <div className="recipient-box2 flex-grow  ">
                    <Box
                      className=" opacity-50  text-center h-full flex flex-col justify-center gap-1 p-2"
                      onClick={handleToastClick}
                    >
                      <div className="recipient-image-div flex justify-center  ">
                        <Box className="flex justify-center w-10">
                          <img
                            src={internalTransaction}
                            alt="document"
                            className="recipient-img"
                          />
                        </Box>
                      </div>
                      <div className="flex justify-center px-2">
                        <Box className="image-label h-8/12 self-center antialiased truncate...">
                          Initiate internal transaction
                        </Box>
                      </div>
                    </Box>
                  </div>
                  <div className=" recipient-box3 flex-grow">
                    <Box
                      className="text-center  opacity-50  h-full flex flex-col justify-center gap-1 p-2"
                      // onClick={() => handleTransaction()}
                      onClick={handleToastClick}
                    >
                      <div className="recipient-image-div flex justify-center">
                        <Box className="flex justify-center w-10">
                          <img
                            src={transactionHistory}
                            alt="document"
                            className="recipient-img"
                          />
                        </Box>
                      </div>
                      <div className="flex justify-center px-2">
                        <Box className="image-label h-8/12 self-center antialiased truncate...">
                          Transaction history
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>

                <div className="statement flex flex-row  ">
                  <div className="bank-logo-details h-full">
                    <div className="logo-bank-container">
                      <img
                        src={require("../../../../assets/IfscLogo/" +
                          IfscCodeMapper(ownerDetailData?.ifsc_code))}
                        alt="bank"
                        className="detailed-bank-logo"
                      />
                    </div>
                  </div>
                  <div className="border-l-2 border-[#1D3A6D] h-12 mt-5 ml-[1px]"></div>
                  <div className=" recipient-box5  flex-grow">
                    <Box
                      className="text-center h-full flex flex-col justify-center gap-1 p-2 "
                      sx={{
                        opacity:
                          ownerDetailData?.connected_banking == "Y"
                            ? "1"
                            : "0.5",
                        cursor:
                          ownerDetailData?.connected_banking == "Y"
                            ? "pointer"
                            : "default",

                        "&:hover": {
                          backgroundColor:
                            ownerDetailData?.connected_banking === "Y"
                              ? "#667CA3"
                              : "inherit",
                        },
                      }}
                      onClick={() => {
                        if (ownerDetailData?.connected_banking == "Y") {
                          handleViewStatement();
                        }
                      }}
                    >
                      <div className="recipient-image-div  flex justify-center">
                        <Box className="flex justify-center w-10">
                          <img
                            src={viewStatement}
                            alt="document"
                            className="recipient-img"
                          />
                        </Box>
                      </div>
                      <div className="flex justify-center px-2">
                        <Box className="image-label h-8/12 self-center antialiased truncate...">
                          View account statement
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>

                <div className="statement-view-acc flex flex-row  bg-[#506994]">
                  <div className="signature-error-div">
                    {ownerDetailData?.connected_banking === "N" ? (
                      <>
                        {/* <div className="statementNotFoundError flex flex-col justify-center pl-5 gap-1">
                          <span className="text-[12px] text-white">
                            Signature did not match
                          </span>
                          <span className="text-[10px] text-[#DADADA]">
                            Bank response
                          </span>
                        </div> */}
                      </>
                    ) : ownerDetailData?.connected_banking === "Y" &&
                      balanceFailed &&
                      showDisplay ? (
                      <>
                        <div className="statementNotFoundError flex flex-row justify-center  p-4 gap-2">
                          <div className="flex justify-center items-center">
                            <img
                              src={errorIcon}
                              alt=""
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          </div>
                          <span className="text-[12px] text-white w-36">
                            Currently unable to fetch balance details.Please try
                            again later
                          </span>
                        </div>
                      </>
                    ) : ownerDetailData?.connected_banking === "Y" &&
                      balanceFailed &&
                      showDisplay ? (
                      <>
                        <div className="statementNotFoundError flex flex-row justify-center  p-4 gap-2">
                          <div className="flex justify-center items-center">
                            <img
                              src={errorIcon}
                              alt=""
                              style={{
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          </div>
                          <span className="text-[12px] text-white w-36">
                            Currently unable to fetch balance details.Please try
                            again later
                          </span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {ownerDetailData?.connected_banking === "Y" &&
                    balanceFailed &&
                    showDisplay && (
                      <div className="border-l-2 border-[#1D3A6D] h-12 mt-5 ml-[-1px]"></div>
                    )}
                  <div className="recipient-box6 flex-grow hover:bg-[#667CA3]">
                    <Box
                      className="text-center h-full flex flex-col justify-center gap-1 p-2"
                      onClick={() => handleSelectedtag()}
                    >
                      <div className="recipient-image-div  flex justify-center">
                        <Box className="flex justify-center w-10">
                          <img
                            src={assignTag}
                            alt="document"
                            className="recipient-img"
                          />
                        </Box>
                      </div>
                      <div className="flex justify-center px-2">
                        <Box className="image-label h-8/12 self-center antialiased truncate...">
                          Assign tags
                        </Box>
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="details-div-section w-screen">
            <div className="details-section">
              <div className="buttonsforOwnerdetails">
                <button
                  className={`DetailsOwnerButton w-20 h-7 mr-5 ${
                    showDetails ? "effective" : "hover:bg-[#A3A3A3]"
                  }`}
                  onClick={toggleDetails}
                >
                  <span
                    className={`text-xs text-center font-sans font-semibold ${
                      showDetails ? "text-black" : "text-white"
                    } `}
                  >
                    Details
                  </span>
                </button>
                <button
                  className={`AssignedButton w-[7rem] h-7 ${
                    showAssignedTags ? "effective" : "hover:bg-[#A3A3A3]"
                  }`}
                  onClick={toggleAdditionalDetails}
                >
                  <span
                    className={`text-xs text-center font-sans font-semibold ${
                      showAssignedTags ? "text-black" : "text-white"
                    }`}
                  >
                    Assigned tags
                  </span>
                </button>
              </div>
              {showDetails ? (
                <div
                  key={ownerDetailData?.id}
                  className="bank-card-row  gap-10"
                >
                  <div className="bank-detail-card  w-1/4 ">
                    <h4 className="title1">Bank Details</h4>

                    <div className="w-50 h-50 acc-type-section-div">
                      <div className="data-div">
                        {ownerDetailData?.account_type}
                      </div>
                      <div className="acc-type-div">Account type</div>
                    </div>
                  </div>

                  <div className="support-detail-card w-1/4">
                    <div className="w-50 h-50">
                      <h4 className="title1">Support Info</h4>
                      <div className="mt-[5px]">
                        <div className="data-div">
                          {ownerDetailData?.pan_no}
                        </div>
                        <div className="  border-b-2  border-solid border-[#1D3A6D] owner-div">
                          PAN number
                        </div>
                      </div>
                      <div className="mt-[8px]">
                        <div id="email-id-firstLetter" className="data-div">
                          {ownerDetailData?.email_id}
                        </div>
                        <div className="  border-b-2  border-solid border-[#1D3A6D] owner-div">
                          Entity email ID
                        </div>
                      </div>
                    </div>
                    <div className="w-50 h-50">
                      <div
                        className=" flex flex-row mt-2 justify-between 
"
                      >
                        <div id="id-firstLetter" className="data-div">
                          {ownerDetailData?.id}
                        </div>
                      </div>
                      <div className="pb-3  ifsc-code-div">
                        Owner account ID
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="assigned-card-row  gap-10">
                  <div
                    className="assigned-detail-card  w-1/4 flex flex-row flex-wrap"
                    style={{ height: haveName > 0 ? "auto" : "66px" }}
                  >
                    {haveName ? (
                      newTags
                        ?.filter((tag) => tag.type === "ownerTags")
                        ?.map((tag) => {
                          return (
                            <>
                              <Box
                                className="tag-box-mainitem"
                                key={tag?.value}
                              >
                                <div>
                                  <>
                                    <span
                                      style={{
                                        display: "inline-block",
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                      }}
                                    >
                                      {tag?.value}
                                    </span>
                                  </>
                                </div>
                              </Box>
                            </>
                          );
                        })
                    ) : (
                      <p className="NotagsAssigned">
                        No tags assigned to this account.
                      </p>
                    )}

                    <div
                      className="editTag"
                      onClick={() => handleSelectedtag()}
                    >
                      <img
                        src={editIcon}
                        alt="editIcon"
                        className="edit-icon"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OwnerDetailsPage;
