import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import verifyLogo from "../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import trimSentence from "../../../../utils/trimSentence";
import Validation from "../../../../utils/validation";
import { toast } from "react-toastify";
import SideBar from "../../../Settings/Management/Entities/SideBar";
import { verifyThisGST } from "../../../../redux/features/verifyPAN/verifyPAN";

const EnterContactDetails = (props) => {
  const panStatus = useSelector((state) => state.verify.verifyGST);
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [nameValidation, setNameVaidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [showSideBar, setShowSideBar] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const dispatch = useDispatch();

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    }
    return value;
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  const allowedValue = (name, value) => {
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    if (name === "name") return Validation.allowedName(value, name);
    if (name === "gstin") return Validation.allowedGstin(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    if (name === "name") errors = Validation.validateName(name, value, errors);
    if (name === "gstin")
      errors = Validation.validateGstin(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.payload, [name]: value };
      props.setPayload(newObj);
    }
    if (
      props.payload.contact_no.length === 0 ||
      props.payload.contact_no.length < 10
    ) {
      setContactNumberValidation(false);
    }
    if (props.payload.email_id.length === 0) {
      setEmailValidation(false);
    }
    if (props.payload.name.length === 0) {
      setNameVaidation(false);
    }
    if (props.payload.gstin?.length === 0 || props.payload.gstin?.length < 15) {
      setPanVerificationStatus(false);
    }
  };

  useEffect(() => {
    if (customErrors["contact_no"]) setContactNumberValidation(false);
    if (!customErrors["contact_no"] && props.payload.contact_no.length === 10)
      setContactNumberValidation(true);
    if (customErrors["email_id"]) setEmailValidation(false);
    if (!customErrors["name"] && props.payload.name.length > 6)
      setNameVaidation(true);
    if (customErrors["name"]) setNameVaidation(false);
    if (
      !customErrors["email_id"] &&
      props.payload.email_id.length > 0 &&
      emailRegex.test(props.payload.email_id)
    )
      setEmailValidation(true);
    if (customErrors["gstin"]) setPanVerificationStatus(false);
    if (!customErrors["gstin"] && props.payload.gstin?.length === 15)
      setPanVerificationStatus(true);
  }, [customErrors]);

  useEffect(() => {
    if (props.payload?.gstin?.length === 15 && !customErrors["gstin"]) {
      dispatch(verifyThisGST({ search_string: props.payload.gstin }));
    }
  }, [props.payload.gstin]);

  useEffect(() => {
    if (props.payload.gstin?.length > 0 && panStatus?.status === true) {
      let errors = { ...customErrors };
      delete errors["gstin"];
      setCustomErrors(errors);
      setPanVerificationStatus(true);
    }

    if (props.payload.gstin?.length > 0 && panStatus?.errors) {
      let errors = { ...customErrors };
      errors["gstin"] = panStatus.errors;
      setCustomErrors(errors);
      setPanVerificationStatus(false);
    }
  }, [panStatus]);

  useEffect(() => {
    if (contactNumberValidation && props?.container === "clientAcc") {
      props.enableNextButton(true);
      return;
    }
    if (
      props.payload.contact_no.length === 0 ||
      props.payload.email_id.length === 0
    ) {
      props.enableNextButton(false);
      return;
    }
    if (emailValidation && contactNumberValidation && nameValidation) {
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [
    contactNumberValidation,
    emailValidation,
    nameValidation,
    panVerificationStatus,
  ]);

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    return (
      <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
        {name !== "gstin" && (
          <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
            {name === "contact_no" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 512 512"
              >
                <g id="_552489" data-name="552489" transform="translate(0 0)">
                  <ellipse
                    id="Ellipse_59"
                    data-name="Ellipse 59"
                    cx="256"
                    cy="256"
                    rx="256"
                    ry="256"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                  <path
                    id="Path_626"
                    data-name="Path 626"
                    d="M348.054,299.359a23.9,23.9,0,0,0-26.948.071l-11.679,8.016a36.426,36.426,0,0,1-42.812-1.18,399.631,399.631,0,0,1-33.713-29.1,399.181,399.181,0,0,1-29.1-33.713,36.426,36.426,0,0,1-1.18-42.812l8.016-11.679a23.907,23.907,0,0,0,.07-26.948l-33.431-49.267a32.411,32.411,0,0,0-41.793-10.561l-.009,0a62.791,62.791,0,0,0-28.317,81.262,452.176,452.176,0,0,0,76.987,118.682l23.79,23.79A452.23,452.23,0,0,0,326.616,402.91a62.792,62.792,0,0,0,81.262-28.317l0-.009a32.411,32.411,0,0,0-10.561-41.793Z"
                    transform="translate(4.84 3.715)"
                    fill="#9f9f9f"
                  />
                </g>
              </svg>
            )}
            {name === "name" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 512 512"
              >
                <path
                  id="Path_2074"
                  data-name="Path 2074"
                  d="M257,224.429A109.714,109.714,0,1,0,147.286,114.714,109.256,109.256,0,0,0,257,224.429Zm0,73.143c-85.211,0-256,42.789-256,128V517H513V425.572C513,340.36,342.211,297.571,257,297.571Z"
                  transform="translate(-1 -5)"
                  fill="#fafafa"
                />
              </svg>
            )}
            {name === "email_id" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 512 392.778"
              >
                <g
                  id="iconfinder_gmail_4975304"
                  transform="translate(-0.001 0)"
                >
                  <g
                    id="Group_14"
                    data-name="Group 14"
                    transform="translate(33.38 0)"
                  >
                    <path
                      id="Path_208"
                      data-name="Path 208"
                      d="M484.973,122.808,452.288,453.324H59.712L33.379,129.205,256,254.723Z"
                      transform="translate(-33.379 -60.548)"
                      fill="#f2f2f2"
                    />
                    <path
                      id="Path_209"
                      data-name="Path 209"
                      d="M473.886,60.983,256,267.1,38.114,60.983H473.886Z"
                      transform="translate(-33.379 -60.983)"
                      fill="#f2f2f2"
                    />
                  </g>
                  <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M59.712,155.8V453.4H24.139A24.229,24.229,0,0,1,0,429.092V111.967l39,1.07Z"
                    transform="translate(0.001 -60.625)"
                    fill="#9f9f9f"
                  />
                  <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M512,111.988V429.115a24.234,24.234,0,0,1-24.154,24.309H452.288V155.82l19.692-46.852Z"
                    transform="translate(0 -60.646)"
                    fill="#9f9f9f"
                  />
                  <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M512,85.292v27.034l-59.712,43.832L256,300.232,59.712,156.158,0,112.326V85.292A24.23,24.23,0,0,1,24.139,60.983H38.114L256,220.909,473.886,60.983h13.962A24.232,24.232,0,0,1,512,85.292Z"
                    transform="translate(0.001 -60.983)"
                    fill="#9f9f9f"
                  />
                  <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M59.712,155.8,0,146.476V111.967Z"
                    transform="translate(0.001 -60.625)"
                    fill="#9f9f9f"
                  />
                </g>
              </svg>
            )}
          </div>
        )}
        {props.editable === false ? (
          <div className="w-2/3 cursor-pointer py-2 text-white text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl">
            <div
              className={`w-full flex flex-col antaliased text-lg xl:text-xl 3xl:text-2xl 5xl:text-3xl text-white"}`}
            >
              <div className="w-full flex gap-5 self-start items-center">
                <div className="w-full truncate ... ">
                  {props.payload[name]}
                </div>
              </div>
              <div className="text-white text-lg opacity-75">{label}</div>
            </div>
          </div>
        ) : (
          <TextField
            className="w-full antialiased text-4xl"
            inputProps={{
              style: {
                color: "white",
                borderBottom: "1px solid white",
              },
              maxLength: { maxLength },
              readOnly:
                props.activeStep === 3 ||
                (name === "name" && props.container !== "clientAcc"),
            }}
            InputLabelProps={{
              style: {
                color: "white",
                fontFamily: "Open Sans",
                fontWeight: 100,
                fontSize: "1.0 rem",
              },
            }}
            InputProps={{
              disableUnderline: true,
              startAdornment: name === "contact_no" && (
                <InputAdornment position="start">
                  <div className="text-white">+91</div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8">
                    {!skip &&
                      value?.length > 0 &&
                      (status ? (
                        <img
                          src={verifyLogo}
                          alt="verified logo"
                          className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                        />
                      ) : (
                        <img
                          src={skippedVerification}
                          alt="verified logo"
                          onClick={() => {
                            showErrors(name);
                          }}
                          className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                        />
                      ))}
                  </div>
                </InputAdornment>
              ),
            }}
            label={label}
            variant="standard"
            name={name}
            onChange={handleChange}
            value={value}
            autoComplete="off"
          />
        )}
      </div>
    );
  };

  const getSideDraweContent = () => {
    return (
      <div className="h-full flex flex-col gap-12 py-8 px-8 max-w-xl">
        <div className="flex flex-col gap-8">
          <div>
            <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
              {panStatus?.gstin_details?.business_name || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Company name
            </div>
          </div>
          <div>
            <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
              {panStatus?.gstin_details?.date_of_registration || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              ESTD. on
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-white px-8 opacity-70"></div>
        <div className="flex flex-col gap-8">
          <div>
            <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
              {panStatus?.gstin_details?.pan_no || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              PAN number
            </div>
          </div>
          <div>
            <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
              {panStatus?.gstin_details?.status || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Corporate listing
            </div>
          </div>
          <div>
            <div className="w-full antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl truncate ...">
              {panStatus?.gstin_details?.status || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Company listing
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-white px-8 opacity-70"></div>
        <div>
          <div>
            <div className="antaliased font-[100] text-white capitalize self-center text-xl 3xl:text-2xl 5xl:text-3xl">
              {panStatus?.gstin_details?.address || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Corporate address
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-custom-lightGreen rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl ">
      <div className="p-2 h-full bg-[#9777AC] flex flex-col drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
        <div>
          <div className="-m-2 bg-[#9777AC] rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl flex flex-row justify-between p-4 md:p-6 lg:p-8 xl:p-10 4xl:p-24">
            <div className="text-white self-center font-semibold text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl">
              {props?.title}
            </div>
          </div>
        </div>

        <div className="-mx-2 mt-2">
          <div
            className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-6"
          >
            {getInputElementDiv(
              "name",
              nameValidation,
              props.container === "clientAcc"
                ? "Client name (Optional)"
                : "Vendor name - Using legal name",
              props.payload.name,
              20
            )}
            {getInputElementDiv(
              "contact_no",
              contactNumberValidation,
              "Contact number",
              props.payload.contact_no,
              10
            )}
            {getInputElementDiv(
              "email_id",
              emailValidation,
              props.container === "clientAcc"
                ? "Email-ID (optional)"
                : "Email-ID",
              props.payload.email_id,
              20
            )}
          </div>
        </div>
        {props?.container !== "clientAcc" &&
          !(props?.activeStep === 3 && !props.payload.gstin) && (
            <div className="pt-5 flex flex-col gap-4 pb-6">
              <div
                className=" bg-[#9777AC]
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        px-6 2xl:px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-18"
              >
                <div
                  className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
                >
                  Vendor GST details
                </div>
              </div>
              <div
                className="bg-[#9777AC] h-fit
          rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl
          flex flex-col px-6 2xl:px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-18"
              >
                <div className="w-full">
                  {getInputElementDiv(
                    "gstin",
                    panVerificationStatus,
                    "GSTIN (Optional)",
                    props.payload.gstin,
                    20,
                    false
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
      {
        <SideBar
          bgColor={{ title: "bg-[#9777AC]", body: "bg-[#744991]" }}
          state={showSideBar}
          setState={setShowSideBar}
          title={
            <div className="flex items-center gap-6">
              <div className="text-2xl 3xl:text-3xl 5xl:4xl w-96 text-white">
                GST Verification details
              </div>
            </div>
          }
        >
          {getSideDraweContent()}
        </SideBar>
      }
      {panVerificationStatus &&
        props.activeStep === 2 &&
        props.payload?.gstin?.length > 0 && (
          <div className="-mx-2">
            <div className=" w-full flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 gap-6">
              <div className=" py-2 rounded-l-large 4xl:rounded-l-customXl 5xl:rounded-l-custom2Xl flex w-full p-2 flex-col justify-center items-start">
                <div className="flex mx-2 flex-row justify-end">
                  <div className="antaliased text-white text-xs ">
                    Company name
                  </div>
                </div>
                <div
                  onClick={() => setShowSideBar(true)}
                  className="w-full m-2 p-1 cursor-pointer rounded-md bg-[#4A7C40] flex items-center flex-row gap-2"
                >
                  <div className="flex flex-row">
                    <img
                      className="self-center w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11 p-1"
                      src={verifyLogo}
                      alt="verified logo"
                    />
                  </div>
                  <div className="flex flex-row h-full w-[90%]">
                    <div className="w-full antaliased font-[100] text-white capitalize self-center text-sm 3xl:text-base 5xl:text-lg truncate ...">
                      {trimSentence(
                        props.payload?.business_name
                          ? props.payload?.business_name.toLowerCase()
                          : panStatus?.gstin_details?.business_name.toLowerCase(),
                        25
                      )}
                    </div>
                  </div>
                  <div className="flex flex-row w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                    <img
                      className="self-center p-1"
                      src={require("../../../../assets/TransactionDashboardIcons/arrow_next.png")}
                      alt="verified logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default EnterContactDetails;
