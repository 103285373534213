import { Box, Typography } from "@mui/material";
import Datacard from "../../../../components/Datacard";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import DashboardAccountList from "../../../../components/Accounts/Common/DashboardAccountList";
import DashboardCreateDiv from "../../../../components/Accounts/Common/DashboardCreateDiv";
import { QuickActionsall } from "../../../../components/QuickActions";

const AccountVendorDataCardComponent = ({ dashboardData }) => {
  const validDataCards = () => {
    if (Object.keys(dashboardData).length === 0) {
      return {};
    }
    const {
      pending_accounts_details,
      denied_accounts_details,
      blocked_accounts_details,
      archived_accounts_details,
    } = dashboardData;
    return {
      pending_accounts_details,
      denied_accounts_details,
      blocked_accounts_details,
      archived_accounts_details,
    };
  };

  const cardTextColor = (key) => {
    if (key === "pending_accounts_details") {
      return "#32E5E6";
    } else if (key === "denied_accounts_details") {
      return "#fb923c";
    } else if (key === "blocked_accounts_details") {
      return "#FF5860";
    } else if (key === "archived_accounts_details") {
      return "#B4B4B4";
    }
  };

  const cardTextData = (key) => {
    if (key === "pending_accounts_details") {
      return "Require approval";
    } else if (key === "denied_accounts_details") {
      return "Denied vendors";
    } else if (key === "blocked_accounts_details") {
      return "Blocked vendors";
    } else if (key === "archived_accounts_details") {
      return "Archived vendors";
    }
  };

  const cardPath = (key) => {
    if (key === "pending_accounts_details") {
      return "/approval_required/vendor_accounts";
    } else if (key === "denied_accounts_details") {
      return "/accounts/vendor/view/N";
    } else if (key === "blocked_accounts_details") {
      return "/accounts/vendor/view/Blocked";
    } else if (key === "archived_accounts_details") {
      return "/accounts/vendor/view/Archived";
    }
  };

  const processDataCardDetails = () => {
    let dataCards = validDataCards();
    return Object.entries(dataCards).map(([key, value]) => {
      return {
        key: key,
        count: value.records,
        color: cardTextColor(key),
        text: cardTextData(key),
        path: cardPath(key),
      };
    });
  };

  const dataCardComponents = () => {
    return Object.entries(processDataCardDetails()).map(([key, data]) => {
      return (
        <Datacard
          key={data.key}
          number={data.count}
          color={data.color}
          text={data.text}
          path={data.path}
        />
      );
    });
  };

  return dataCardComponents();
};

const AccountVendorListComponent = ({ accountType, viewAllData }) => {
  const emptyAccountListComponent = () => {
    return (
      <>
        <Typography sx={{ color: "white" }}>
          No accounts found <br />
          All vendor accounts will appear here.
        </Typography>
        <Box
          sx={{
            width: "50%",
            margin: "auto",
            marginTop: "5%",
          }}
        >
          <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
        </Box>
      </>
    );
  };

  const listTextColor = (key) => {
    if (key === "Y") {
      return "#4DDD37";
    } else if (key === "N") {
      return "#fb923c";
    } else if (key === "Blocked") {
      return "#FF5860";
    } else if (key === "Archived") {
      return "#B4B4B4";
    } else if (key === "P") {
      return "#FBC02D";
    }
  };

  const modifiedAccountListData = () => {
    return Object.entries(viewAllData).map(([key, value]) => {
      return {
        name: value.name,
        bank_name: value.bank_name,
        text_color: listTextColor(value.approval_status),
        vendor_id: value.vendor_id,
      };
    });
  };

  const accountListComponents = () => {
    if (Object.keys(viewAllData).length === 0) {
      return emptyAccountListComponent();
    } else {
      return (
        <>
          <div className="px-4 flex flex-row justify-between">
            <span className="text-white text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
              Recent
            </span>
            <span className="cursor-pointer text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
              <p
                onClick={() =>
                  (window.location.href = `/accounts/${accountType}/view/`)
                }
                className="underline text-custom-orange"
              >
                View All
              </p>
            </span>
          </div>

          <div className="pb-6 3xl:pb-8 5xl:pb-10 mt-5 w-full gap-y-4 4xl:gap-y-8 grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 md:gap-x-8 lg:gap-x-10 justify-items-center">
            {Object.entries(modifiedAccountListData()).map(([key, value]) => {
              return (
                <DashboardAccountList
                  key={key}
                  name={value.name}
                  bank_name={value.bank_name}
                  textColor={value.text_color}
                  navigate_url={`/accounts/${accountType}/details/${value.vendor_id}`}
                />
              );
            })}
          </div>
        </>
      );
    }
  };

  return accountListComponents();
};

const AccountVendorRightSidebarComponent = () => {
  const rightSidebarComponents = () => {
    return (
      <>
        <div>
          {
            <DashboardCreateDiv
              heading={"Start creating your accounts"}
              first_button_text={"Create"}
              second_button_text={"View all"}
              first_button_url={"/accounts/vendor/create"}
              second_button_url={"/accounts/vendor/view/"}
            />
          }
        </div>
        <div className="hidden lg:block">{<QuickActionsall />}</div>
      </>
    );
  };

  return rightSidebarComponents();
};

export {
  AccountVendorDataCardComponent,
  AccountVendorListComponent,
  AccountVendorRightSidebarComponent,
};
