import AccountDetailsClientBannerComponent from "../../../../components/Accounts/ClientAccount/AccountDetailsClientBannerComponent";
import AccountDetailCard from "../../../../components/Accounts/Common/AccountDetailCard";
import { accountDetailsCardBg } from "../../../../services/approvalStatus";

const AccountClientDetailsBannerComponent = ({ accountData }) => {
  const bannerComponent = () => {
    return (
      <AccountDetailsClientBannerComponent
        name={accountData?.name}
        mobile={accountData?.contact_no}
        account_type={"client"}
        account_id={accountData?.client_id}
        approval_status={accountData?.approval_status}
        account_number={accountData?.bank_acc_no}
      />
    );
  }

  return bannerComponent();
}

const AccountClientDetailsBodyComponent = ({ accountData }) => {
  const accountBodyComponents = () => {
    let cardsNeeded = {
      "Contact Details": {
        "Contact number": `+91 ${accountData?.contact_no}`,
        "Email ID": accountData?.email_id
      }
    }
    return (
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        {
          cardsNeeded && Object.keys(cardsNeeded).map((key, index) => {
            return (
              <div className="w-full h-fit" >
                <AccountDetailCard
                  heading={key}
                  data={cardsNeeded[key]}
                  bg_color={accountDetailsCardBg(accountData?.approval_status)}
                />
              </div>
            )
          })
        }
      </div>
    )
  }
  return accountBodyComponents();
}


export { AccountClientDetailsBannerComponent, AccountClientDetailsBodyComponent };

