import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FilterIconDesktop from "./FilterIconDesktop";
import "./index.css";
import SettleIdSearch from "./SettleIdSearch";
import CommonSearchInput from "./CommonSeachInput";
import { Button } from "@mui/material";
import SettledUsingFilter from "./SettledUsing";
import DateRangeComp from "./DateRangePicker";

export default function FilterDrawer() {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box className="w-1/3 filterDrawer" role="presentation">
      <DateRangeComp />
      <SettleIdSearch />
      <CommonSearchInput
        label="Client"
        items={[
          { title: "Client 1" },
          { title: "Client 2" },
          { title: "Client 3" },
          { title: "Client 4" },
        ]}
      />
      <SettledUsingFilter
        label="Settled Using"
        items={[
          { title: "Client 1" },
          { title: "Client 2" },
          { title: "Client 3" },
          { title: "Client 4" },
        ]}
      />
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box>
            <Box className="filterIconDesktop">
              <FilterIconDesktop toggleDrawer={toggleDrawer(anchor, true)} />
            </Box>
          </Box>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <Box className="filterLabel">
              <Box className="crossIcon">
                <img
                  width="100%"
                  src={require("../../../assets/ViewAllTransactions/filter.png")}
                  alt="filter"
                />
                <p className="filterText">Filter</p>
              </Box>
              <Box className="crossIcon2">
                <Button onClick={toggleDrawer(anchor, false)}>
                  <img
                    width="100%"
                    src={require("../../../assets/ViewAllTransactions/Close blue.png")}
                    alt="close"
                  />
                </Button>
              </Box>
            </Box>
            {list(anchor)}
            <Box className="filterButtons">
              <Button variant="outlined" className="filterButton1">
                Clear Filter
              </Button>
              <Button variant="filled" className="filterButton2">
                Apply Filter
              </Button>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
