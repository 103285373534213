// countryDemonyms.js
export const countryDemonyms = {
  AF: "Afghan",
  AX: "Åland Islander",
  AL: "Albanian",
  DZ: "Algerian",
  AS: "American Samoan",
  AD: "Andorran",
  AO: "Angolan",
  AI: "Anguillian",
  AQ: "Antarctican",
  AG: "Antiguan or Barbudan",
  AR: "Argentine",
  AM: "Armenian",
  AW: "Aruban",
  AU: "Australian",
  AT: "Austrian",
  AZ: "Azerbaijani",
  BS: "Bahamian",
  BH: "Bahraini",
  BD: "Bangladeshi",
  BB: "Barbadian",
  BY: "Belarusian",
  BE: "Belgian",
  BZ: "Belizean",
  BJ: "Beninese",
  BM: "Bermudian",
  BT: "Bhutanese",
  BO: "Bolivian",
  BA: "Bosnian or Herzegovinian",
  BW: "Botswanan",
  BR: "Brazilian",
  BN: "Bruneian",
  BG: "Bulgarian",
  BF: "Burkinabé",
  BI: "Burundian",
  CV: "Cape Verdean",
  KH: "Cambodian",
  CM: "Cameroonian",
  CA: "Canadian",
  KY: "Caymanian",
  CF: "Central African",
  TD: "Chadian",
  CL: "Chilean",
  CN: "Chinese",
  CO: "Colombian",
  KM: "Comoran",
  CG: "Congolese (Congo)",
  CD: "Congolese (DRC)",
  CK: "Cook Islander",
  CR: "Costa Rican",
  CI: "Ivorian",
  HR: "Croatian",
  CU: "Cuban",
  CW: "Curaçaoan",
  CY: "Cypriot",
  CZ: "Czech",
  DK: "Danish",
  DJ: "Djiboutian",
  DM: "Dominican (Dominica)",
  DO: "Dominican (Dominican Republic)",
  EC: "Ecuadorian",
  EG: "Egyptian",
  SV: "Salvadoran",
  GQ: "Equatorial Guinean",
  ER: "Eritrean",
  EE: "Estonian",
  SZ: "Swazi",
  ET: "Ethiopian",
  FJ: "Fijian",
  FI: "Finnish",
  FR: "French",
  GA: "Gabonese",
  GM: "Gambian",
  GE: "Georgian",
  DE: "German",
  GH: "Ghanaian",
  GI: "Gibraltar",
  GR: "Greek",
  GL: "Greenlandic",
  GD: "Grenadian",
  GU: "Guamanian",
  GT: "Guatemalan",
  GG: "Channel Islander",
  GN: "Guinean",
  GW: "Bissau-Guinean",
  GY: "Guyanese",
  HT: "Haitian",
  HN: "Honduran",
  HK: "Hong Konger",
  HU: "Hungarian",
  IS: "Icelander",
  IN: "Indian",
  ID: "Indonesian",
  IR: "Iranian",
  IQ: "Iraqi",
  IE: "Irish",
  IM: "Manx",
  IL: "Israeli",
  IT: "Italian",
  JM: "Jamaican",
  JP: "Japanese",
  JE: "Channel Islander",
  JO: "Jordanian",
  KZ: "Kazakhstani",
  KE: "Kenyan",
  KI: "I-Kiribati",
  KP: "North Korean",
  KR: "South Korean",
  KW: "Kuwaiti",
  KG: "Kyrgyzstani",
  LA: "Laotian",
  LV: "Latvian",
  LB: "Lebanese",
  LS: "Basotho",
  LR: "Liberian",
  LY: "Libyan",
  LI: "Liechtensteiner",
  LT: "Lithuanian",
  LU: "Luxembourgish",
  MO: "Macanese",
  MG: "Malagasy",
  MW: "Malawian",
  MY: "Malaysian",
  MV: "Maldivian",
  ML: "Malian",
  MT: "Maltese",
  MH: "Marshallese",
  MQ: "Martiniquais",
  MR: "Mauritanian",
  MU: "Mauritian",
  YT: "Mahoran",
  MX: "Mexican",
  FM: "Micronesian",
  MD: "Moldovan",
  MC: "Monégasque",
  MN: "Mongolian",
  ME: "Montenegrin",
  MS: "Montserratian",
  MA: "Moroccan",
  MZ: "Mozambican",
  MM: "Burmese",
  NA: "Namibian",
  NR: "Nauruan",
  NP: "Nepali",
  NL: "Dutch",
  NZ: "New Zealander",
  NI: "Nicaraguan",
  NE: "Nigerien",
  NG: "Nigerian",
  NU: "Niuean",
  NF: "Norfolk Islander",
  MK: "Macedonian",
  MP: "Northern Marianan",
  NO: "Norwegian",
  OM: "Omani",
  PK: "Pakistani",
  PW: "Palauan",
  PS: "Palestinian",
  PA: "Panamanian",
  PG: "Papua New Guinean",
  PY: "Paraguayan",
  PE: "Peruvian",
  PH: "Filipino",
  PL: "Polish",
  PT: "Portuguese",
  PR: "Puerto Rican",
  QA: "Qatari",
  RO: "Romanian",
  RU: "Russian",
  RW: "Rwandan",
  BL: "Saint Barthélemy Islander",
  SH: "Saint Helenian",
  KN: "Kittitian or Nevisian",
  LC: "Saint Lucian",
  MF: "Saint-Martinois",
  PM: "Saint-Pierrais or Miquelonnais",
  VC: "Saint Vincentian",
  WS: "Samoan",
  SM: "Sammarinese",
  ST: "São Toméan",
  SA: "Saudi Arabian",
  SN: "Senegalese",
  RS: "Serbian",
  SC: "Seychellois",
  SL: "Sierra Leonean",
  SG: "Singaporean",
  SX: "Sint Maartener",
  SK: "Slovak",
  SI: "Slovenian",
  SB: "Solomon Islander",
  SO: "Somali",
  ZA: "South African",
  ES: "Spanish",
  LK: "Sri Lankan",
  SD: "Sudanese",
  SR: "Surinamese",
  SE: "Swedish",
  CH: "Swiss",
  SY: "Syrian",
  TW: "Taiwanese",
  TJ: "Tajikistani",
  TZ: "Tanzanian",
  TH: "Thai",
  TL: "Timorese",
  TG: "Togolese",
  TK: "Tokelauan",
  TO: "Tongan",
  TT: "Trinidadian or Tobagonian",
  TN: "Tunisian",
  TR: "Turkish",
  TM: "Turkmen",
  TC: "Turks and Caicos Islander",
  TV: "Tuvaluan",
  UG: "Ugandan",
  UA: "Ukrainian",
  AE: "Emirati",
  GB: "British",
  US: "American",
  UY: "Uruguayan",
  UZ: "Uzbekistani",
  VU: "Ni-Vanuatu",
  VE: "Venezuelan",
  VN: "Vietnamese",
  VG: "Virgin Islander (British)",
  VI: "Virgin Islander (U.S.)",
  EH: "Sahrawi",
  YE: "Yemeni",
  ZM: "Zambian",
  ZW: "Zimbabwean",
};
