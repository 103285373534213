import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TxnStatusCard from "../../../../components/ApprovalTxn/VendorApproval/TxnStatusCard";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import "./index.css";

const VenApprovalStatus = () => {
  const dispatch = useDispatch();
  const vendorApprovalResponse = useSelector(
    (state) => state.approveTxn.vendor
  );
  const transactions = vendorApprovalResponse?.transactions[0];
  const amount = transactions?.amount;
  const status = transactions?.approval_status;
  const txns = vendorApprovalResponse
    ? vendorApprovalResponse.transactions
    : [];

  if (txns.length > 1) {
    var total_amt = txns.reduce(function (previousValue, currentValue) {
      return {
        value:
          (Number(previousValue.value) || 0) +
          (Number(currentValue.amount) || 0),
      };
    }, {});
  }

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  return (
    <div
      className="flex flex-col xl:flex-row gap-0
      min-w-screen min-h-screen w-screen justify-center pt-24 xl:h-screen items-center"
      style={{
        backgroundColor:
          status === "Y" || status === "P" ? "#6ba673" : "#BC5555",
      }}
    >
      <div className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 p-0 flex flex-col items-center justify-center gap-6 text-center text-white text-2xl">
        {status === "Y" || status === "P" ? (
          <img
            src={require("../../../../assets/Approve transactions/Success screen.png")}
            width={"70%"}
            alt=""
            srcSet=""
          />
        ) : (
          <img
            src={require("../../../../assets/Approve transactions/Failed screen.png")}
            width={"70%"}
            alt=""
            srcSet=""
          />
        )}
        {status === "Y" || status === "P" ? (
          <div>
            Vendor {txns.length > 1 ? "transactions have" : "transaction has"}
            <br />
            been approved!
          </div>
        ) : (
          <div>
            Vendor {txns.length > 1 ? "transactions have" : "transaction has"}
            <br />
            been declined!
          </div>
        )}
      </div>
      <div className="w-4/5 flex justify-start h-[750px] xl:w-2/5 xl:h-[90%] p-0 text-center">
        {!(txns.length > 1) ? (
          <TxnStatusCard
            name={transactions?.vendor_name}
            status={status}
            amount={amount}
            txn={transactions?.txn_id}
          />
        ) : (
          <TxnStatusCard
            name={`${txns.length} Transactions`}
            status={status}
            // amount={total_amt.value}
            amount={total_amt ? total_amt.value : 0}
            txn_len={txns.length}
          />
        )}
      </div>
    </div>
  );
};

export default VenApprovalStatus;
