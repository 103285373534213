import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import StatusIMG from "../../../assets/TxnDetails/Group 1253.png";
import InsPayIMG from "../../../assets/TxnDetails/Group 1254.png";
import arrowImg from "../../../assets/TransactionDashboardIcons/arrow_next.png";
import instantPayLogo from "../../../assets/TransactionDashboardIcons/InstantPay.png";
import "./index.css";
import { useSelector } from "react-redux";

const MoreActions = (props) => {
  const services = useSelector((state) => state.refreshToken.services);

  return (
    <Box className="px-4 py-2 w-full gap-0">
      {services?.includes("AStatus") && services?.includes("Admin") &&
        <div className="cursor-pointer hoverEffect px-0">
          <div className="py-0 flex cursor-pointer items-center"
            onClick={() => props.addStatus(true)}>
            <div className="p-2 flex flex-row gap-2 items-center w-full h-full rounded-3xl">
              <div className="w-2/12">
                <img
                  src={StatusIMG}
                  className="w-8 h-8"
                />
              </div>
              <div className="w-8/12 flex justify-start text-base text-white items-center">
                Add Status
              </div>
            </div>
            <div className="w-2/12 flex justify-center">
              <img
                src={arrowImg}
                alt="arrowImg"
                className="w-6 h-6"
              />
            </div>
          </div>
        </div>
      }
      {props?.module !== "IP" && services?.includes("TRANSACTION_UPSERT_CONVERT_TO_INSTANTPAY") && (props.status === "Stalled" || props.status === "S") &&
        <div className="py-1 -mr-2 -ml-2 ">
          <hr className="w-full border-[#1d1d1d55]" />
        </div>}
      {props?.module !== "IP" && services?.includes("TRANSACTION_UPSERT_CONVERT_TO_INSTANTPAY") && (props.status === "Stalled" || props.status === "S") &&

        <div className="cursor-pointer hoverEffect px-0">
          <div className="py-0 flex cursor-pointer items-center"
            onClick={() => props.convertToInstantPay(true)}>
            <div className="p-2 flex flex-row gap-2 items-center w-full h-full rounded-3xl">
              <div className="w-2/12">
                <img
                  src={instantPayLogo}
                  className="w-8 h-8"
                />
              </div>
              <div className="w-fit flex justify-start text-base text-white items-center">
                Convert to InstantPay
              </div>
            </div>
            <div className="w-2/12 flex justify-center">
              <img
                src={arrowImg}
                alt="arrowImg"
                className="w-6 h-6"
              />
            </div>
          </div>
        </div>
      }
    </Box>
  );
};

export default MoreActions;
