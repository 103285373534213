import React from "react";
import Box from "@mui/material/Box";
import StatusIMG from "../../../../assets/TxnDetails/Group 1253.png";
import arrowImg from "../../../../assets/TransactionDashboardIcons/arrow_next.png";
import instantPayLogo from "../../../../assets/TransactionDashboardIcons/InstantPay.png";
import RefundTxnLogo from "../../../../assets/TxnDetails/Refund_txn.png";
import { useSelector } from "react-redux";

const ClientTxnDetailsMoreActions = (props) => {
  const services = useSelector((state) => state.refreshToken.services);

  return (
    <Box className="p-2 3xl:p-4 5xl:p-6 w-[80%] flex flex-col justify-center gap-1">
      {services?.includes("AStatus") && services?.includes("Admin") &&
        <div className="flex flex-col justify-center cursor-pointer">
          <div className="py-2 flex flex-row gap-1
            items-center w-full h-full hover:bg-transparent
            rounded-3xl 4xl:rounded-customXl"
            onClick={() => props.addStatus(true)}>
            <div className="w-1/12">
              <img
                src={StatusIMG}
                alt=""
                className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
              />
            </div>
            <div className="font-thin w-9/12 flex justify-start
                  text-xs 3xl:text-sm 4xl:text-base 6xl:text-xl
                  text-white items-center antaliased">
              Add Status
            </div>
            <div className="w-2/12 flex justify-end">
              <img
                src={arrowImg}
                alt="arrowImg"
                className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
              />
            </div>
          </div>
        </div>
      }
      {props?.module !== "IP" && services?.includes("TRANSACTION_UPSERT_CONVERT_TO_INSTANTPAY") && (props.status === "Stalled" || props.status === "S") &&
        <div className="py-1 -mr-2 -ml-2 ">
          <hr className="w-full border-[#1d1d1d55]" />
        </div>}
      {props?.module !== "IP" && services?.includes("TRANSACTION_UPSERT_CONVERT_TO_INSTANTPAY") && (props.status === "Stalled" || props.status === "S") &&
        <div className="flex justify-center cursor-pointer">
          <div className="py-2 flex flex-row gap-2
            items-center w-full h-full hover:bg-transparent
            rounded-3xl 4xl:rounded-customXl"
            onClick={() => props.convertToInstantPay(true)}>
            <div className="w-1/12">
              <img
                src={instantPayLogo}
                className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
              />
            </div>
            <div className="font-thin w-9/12 flex justify-start
                  text-xs 3xl:text-sm 4xl:text-base 6xl:text-xl
                  text-white items-center">
              Convert to InstantPay
            </div>
            <div className="w-2/12 flex justify-end">
              <img
                src={arrowImg}
                alt="arrowImg"
                className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
              />
            </div>
          </div>
        </div>
      }
      {services?.includes("RRefund") && (props?.status == "S" || props?.status === "Refund" || props?.status === "Processing" || props?.status === "Stalled") &&
        <><div className="py-1 -mr-2 -ml-2 ">
          <hr className="w-full border-[#1d1d1d55]" />
        </div>
          <div className="flex justify-center cursor-pointer">
            <div className="py-2 flex flex-row gap-1
            items-center w-full h-full hover:bg-transparent
            rounded-3xl 4xl:rounded-customXl"
              onClick={() => props.showTransactionRefund(true)}>
              <div className="w-1/12">
                <img
                  src={RefundTxnLogo}
                  className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
                />
              </div>
              <div className="font-thin w-9/12 flex justify-start
                  text-xs 3xl:text-sm 4xl:text-base 6xl:text-xl
                  text-white items-center">
                Transaction Refund
              </div>
              <div className="w-2/12 flex justify-end">
                <img
                  src={arrowImg}
                  alt="arrowImg"
                  className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
                />
              </div>
            </div>
          </div></>}

    </Box>
  );
};

export default ClientTxnDetailsMoreActions;
