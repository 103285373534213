import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import accTransform from "../../../../utils/accTransform";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const AccStatusCard = (props) => {
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);

  return (
    <>
      <Box className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <Box className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6">
          {props.data?.title ? (
            <>
              <div className="flex flex-col gap-2 font-bold p-4 mt-4 pb-4">
                <div className="text-2xl 4xl:text-4xl font-semibold w-full">
                  {props.data.title}
                </div>
              </div>
              <Box className="dashedLine"></Box>
            </>
          ) : (
            <div className="h-5"></div>
          )}
          <Box className="detailsText flex flex-col gap-8 w-full">
            {props.data?.body?.map((item, index) => {
              return (
                item.value && (
                  <div
                    key={index}
                    className="flex flex-row justify-between cursor-pointer"
                    style={{ marginRight: "-24px" }}
                  >
                    <div className="flex flex-col">
                      <div
                        className="text-base 3xl:text-xl font-bold 5xl:text-2xl 6xl:text-3xl
                    antialiased truncate ..."
                      >
                        {item.value}
                      </div>
                      <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                        {item.subtitle}
                      </div>
                    </div>
                    {item.img && (
                      <div className="flex flex-row justify-end w-2/6">
                        <img className="self-center" src={item.img} alt="" />
                      </div>
                    )}
                  </div>
                )
              );
            })}
          </Box>
        </Box>
        <Box className="w-full divide-x-2 cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl bg-[#A891B7] flex justify-evenly items-center gap-0">
          {props.data.links?.map((item, index) => {
            return (
              <Box
                key={index}
                className="flex flex-col w-2/6 h-3/4 gap-2 items-center justify-center"
                onClick={() => navigate(item.link)}
              >
                <Box className="w-3/6 flex justify-center">
                  <img width={"50%"} src={item.icon} alt="icon" />
                </Box>
                <p className="w-2/3 flex flex-wrap">{item.title}</p>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default AccStatusCard;