import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";

const AdditionalDetailsCard = (props) => {
  return (
    <div
      className="drop-shadow-2xl flex flex-col
      rounded-4xl
      px-6 py-6"
      style={{
        backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status)
      }}>
      <div className="flex h-1/3 pb-4">
        <div className="self-center text-gray-300 text-sm font-bold">
          Additional Details
        </div>
      </div>
      <div className="h-2/3 flex flex-row pb-4">
        <div className="self-center w-1/2 flex flex-col">
          <div className="text-base
           text-white antialiased break-words ...">
            {props.invoice_no ? props.invoice_no : "Not given"}
          </div>
          <div className="text-gray-300 text-xs antialiased">
            Invoice number
          </div>
        </div>
        <div className="self-center w-1/2 flex flex-col">
          <div className="text-base
           text-white antialiased break-words ...">
            {props.distribution_remark ? props.distribution_remark : "Not given"}
          </div>
          <div className="text-gray-300 text-xs antialiased">
            Distribution remark
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalDetailsCard;