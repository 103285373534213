import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./index.css";
import EnterDetailsVendorTxn from "../../../components/CreateTxn/CreateVendorTxn/EnterDetails";
import { useSelector, useDispatch } from "react-redux";
import { setBufferState } from "../../../redux/features/bufferState/bufferState";
import CustomizedStepper from "../../../components/CreateTxn/CustomizedStepper";
import { toast } from "react-toastify";
import CreateInternalTxnForm from "../../../components/CreateTxn/CreateInternaltxn/CreateInternalTxnForm";
import { createInternalTxn } from "../../../redux/features/createTxn/createTxn";
import FilledCardComponent from "../../../components/CreateTxn/CreateInternaltxn/FilledCardComponent";
import EnterDetailsInstantPayTxn from "../../../components/CreateTxn/CreateInstantPayTxn/EnterDetails";
import SelectPayment from "./SelectPayment";
import TransactionStatus from "./TransactionStatus";

const CreateIPTxn = () => {
  const services = useSelector((state) => state.refreshToken.services);
  const dispatch = useDispatch();
  const [showPayMethod, setshowPayMethod] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdIPTxn = useSelector((state) => state.createTxn.instantpay);
  const createInternalTxnLoading = useSelector(
    (state) => state.createTxn.isLoading
  );
  const [checkRes, setCheckRes] = useState(false);
  const [createIPData, setCreateIPData] = useState({
    amount: null,
  });

  useEffect(() => {
    if (checkRes && createdIPTxn?.errors) {
      toast.error(Object.values(createdIPTxn?.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && createdIPTxn?.status) setshowPayMethod(true);
    setCheckRes(true);
  }, [createdIPTxn]);

  return (
    <Box
      className="bg-custom-appBack
      flex flex-row justify-center
      h-screen overflow-hidden"
    >
      {!showPayMethod ? (
        <div
          className="mt-24 h-screen
            flex flex-col gap-2 3xl:gap-4 5xl:gap-6
            py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20"
        >
          <div className="flex h-full p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
            <div className="mx-auto h-full h-fit">
              <EnterDetailsInstantPayTxn
                setCreateTxnData={setCreateIPData}
                createTxnData={createIPData}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          {services.includes("Settle_txn") &&
          createdIPTxn?.transaction_detail[0].approval_status === "Y" ? (
            <SelectPayment createdIPTxn={createdIPTxn} />
          ) : (
            <Box sx={{ marginTop: "-120px" }}>
              <TransactionStatus
                approval_status={
                  createdIPTxn?.transaction_detail[0].approval_status
                }
                txn_id={createdIPTxn?.transaction_detail[0].txn_id}
                txn_status={"created succesfully!"}
                account={createdIPTxn?.transaction_detail[0].bank_acc_no}
                amount={createdIPTxn?.transaction_detail[0].amount}
                vendor_name={"InstantPay Refill"}
                bank_name={createdIPTxn?.transaction_detail[0].bank_name}
                vendor_id={createdIPTxn.vendor_id}
              />
            </Box>
          )}
        </div>
      )}
    </Box>
  );
};

export default CreateIPTxn;
