import React from "react";
import "./index.css";
import formatCurrency from "../../../utils/CurrencyFormater";

const Refund = (props) => {
  return (
    <div className="flex flex-row gap-12 m-4 p-4 bg-[#6b476b] rounded-4xl 5xl:p-16 5xl:rounded-custom2Xl">
      <div className="flex flex-row gap-10 w-1/2 text-white">
        <div className="w-8 h-8 3xl:w-10 3xl:h-10 4xl:h-12 4xl:w-12 self-center">
          {props.status == "Refunded" ? (
            <img
              src={require("../../../assets/TxnDetails/711239.png")}
              alt=""
              srcSet=""
            />
          ) : (
            <img
              src={require("../../../assets/TxnDetails/4407211.png")}
              alt=""
              srcSet=""
            />
          )}
        </div>
        <div className="flex flex-col gap-3">
          <div>
            <div className="text-xm 5xl:text-3xl">
              ₹ {formatCurrency(props.refund_amount)} refund successful
            </div>
            <div className="text-gray-300  text-xs 6xl:text-2xl 5xl:text-2xl">
              {props.refund_date}
            </div>
          </div>
          <div>
            <div className="text-xm 5xl:text-3xl">{props.reference_id}</div>
            <div className="text-gray-300 text-xs 5xl:text-2xl">
              Gateway reference no.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-1/2 gap-4 text-white">
        <div>
          <div className="text-xm 5xl:text-3xl">{props.refund_id}</div>
          <div className="text-gray-300 text-xs 5xl:text-2xl">Refund id</div>
        </div>
        <div>
          <div className="text-xm 5xl:text-3xl">{props.status}</div>
          <div className="text-gray-300 text-xs 5xl:text-2xl">
            Gateway status
          </div>
        </div>
      </div>
    </div>
  );
};
export default Refund;
