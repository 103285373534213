import React, { useEffect } from "react";
import { div } from "@mui/material";
import "./index.css";
import accTransform from "../../../../utils/accTransform";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import SimpleLogo from "../../../_utils/logo/SimpleLogo";
import { useDispatch, useSelector } from "react-redux";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";

const TxnStatusCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DebitAcc = useSelector((state) => state.bufferState.internalDebit);
  const CreditAcc = useSelector((state) => state.bufferState.internalCredit);

  useEffect(() => {
    return () => {
      dispatch(setBufferState({ internalCredit: null, internalDebit: null }));
    };
  }, []);
  return (
    <>
      <div className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <div className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6 items-center justify-center">
          <div className="flex text-4xl pt-12 font-semibold text-[#27B882] gap-6 p-4 mt-4 pb-4">
            {" "}
            ₹ {CurrencyFormater(props.createdInternalTxn.amount)}
          </div>
          <div
            className="w-11/12 h-1 opacity-40"
            style={{
              background:
                "repeating-linear-gradient(to left,transparent,transparent 15px, #000000 15px, #000000 30px)",
            }}
          ></div>
          <div className="flex w-5/6 m-auto flex-col gap-4 3xl:gap-8 6xl:gap-12">
            <div className="flex justify-between items-center">
              <div
                className="text-left text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                antialiased truncate ..."
              >
                <div className="opacity-70">From</div>
                <div>
                  {accTransform(props.createdInternalTxn.source_bank_acc_no)}
                </div>
                <div className="opacity-60">Debit account number</div>
              </div>
              <div className="w-1/4">
                <SimpleLogo bank={bankLogoMapper(DebitAcc?.bank_name)} />
              </div>
            </div>
            <hr/> 
            <div className="flex justify-between items-center">
              <div
                className="text-left text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                antialiased truncate ..."
              >
                <div className="opacity-70">To</div>
                <div>
                  {accTransform(
                    props.createdInternalTxn.destination_bank_acc_no
                  )}
                </div>
                <div className="opacity-60">Credit account number</div>
              </div>
              <div className="w-1/4">
                <SimpleLogo bank={bankLogoMapper(CreditAcc?.bank_name)} />
              </div>
            </div>
            {/* <hr /> */}
          </div>
        </div>
        <div className="w-full cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl bg-[#7fb085] flex justify-evenly items-center gap-2 pt-3 pb-3">
          <div
            className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
            onClick={() => navigate(0)}
          >
            <div className="w-3/6 flex justify-center">
              <img
                width={"70%"}
                src={require("../../../../assets/CreateTransactionIcons/Create transaction.png")}
                alt=""
                srcSet=""
              />
            </div>
            <p>
              Create new <br /> transactions
            </p>
          </div>
          <div className="h-4/5 w-0.5 bg-white" />
          {props.createdInternalTxn.txn_id && (
            <>
              <div
                className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
                onClick={() =>
                  navigate(`/internal/txn/${props.createdInternalTxn.txn_id}`)
                }
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"70%"}
                    src={require("../../../../assets/CreateTransactionIcons/View details.png")}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p>
                  View transaction <br /> details
                </p>
              </div>
              <div className="h-4/5 w-0.5 bg-white" />
            </>
          )}
          <div
            className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
            onClick={() => navigate("/internal_transactions")}
          >
            <div className="w-3/6 flex justify-center">
              <img
                width={"70%"}
                src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                alt=""
                srcSet=""
              />
            </div>
            <p>
              Go to <br /> dashboard
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TxnStatusCard;
