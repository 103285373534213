import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TxnStatusCard from "../../../../components/ApprovalTxn/VendorApproval/TxnStatusCard";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import "./index.css";
import TxnStatusCardFF from "../../../../components/ApprovalTxn/FieldForceApproval/TxnStatusCard";

const FFApprovalStatus = () => {
  const dispatch = useDispatch();
  const vendorApprovalResponse = useSelector((state) => state.approveTxn.ff);
  const transactions = vendorApprovalResponse?.transactions[0];
  const amount = transactions?.amount;
  const status = transactions?.approval_status;
  const txns = vendorApprovalResponse
    ? vendorApprovalResponse.transactions
    : [];

  if (txns.length > 1) {
    var total_amt = txns.reduce(function (previousValue, currentValue) {
      return {
        value: Number(previousValue.amount) + Number(currentValue.amount),
      };
    });
  }

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  return (
    <div
      className="flex flex-col xl:flex-row gap-0
      min-w-screen min-h-screen w-screen justify-center pt-24 xl:h-screen items-center"
      style={{
        backgroundColor:
          status === "Y" || status === "P" || status === "Processing"
            ? "#6ba673"
            : "#BC5555",
      }}
    >
      <div className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 p-0 flex flex-col items-center justify-center gap-6 text-center text-white text-2xl">
        {status === "Y" || status === "P" ? (
          <img
            src={require("../../../../assets/Approve transactions/Success screen.png")}
            width={"70%"}
            alt=""
            srcSet=""
          />
        ) : (
          <img
            src={require("../../../../assets/Approve transactions/Failed screen.png")}
            width={"70%"}
            alt=""
            srcSet=""
          />
        )}
        {status === "Processing" ? (
          <div>
            Field force {txns.length > 1 ? "transactions" : "transaction"}{" "}
            <br />
            under processing!
          </div>
        ) : status === "Y" || status === "P" ? (
          <div>
            Field force{" "}
            {txns.length > 1 ? "transactions have" : "transaction has"} <br />
            been approved!
          </div>
        ) : (
          <div>
            Field force{" "}
            {txns.length > 1 ? "transactions have" : "transaction has"} <br />
            been declined!
          </div>
        )}
      </div>
      <div className="w-4/5 flex justify-start h-[750px] xl:w-2/5 xl:h-[90%] p-0 text-center">
        {!(txns.length > 1) ? (
          <TxnStatusCardFF
            name={transactions?.account_holder_name}
            status={status}
            amount={amount}
            txn={transactions?.txn_id}
          />
        ) : (
          <TxnStatusCardFF
            name={`${txns.length} Transactions`}
            status={status}
            amount={total_amt.value}
            txn_len={txns.length}
          />
        )}
      </div>
    </div>
  );
};

export default FFApprovalStatus;
