export const AuthMatrixTransactions = [
    {
        title: "Vendor matrix",
        type_of_transaction: "vendor",
        icon: "Vendor",
    },
    {
        title: "Client matrix",
        type_of_transaction: "client",
        icon: "Client",
    },
    {
        title: "Field Force matrix",
        type_of_transaction: "field_force",
        icon: "Field force",
    },
    {
        title: "Reimbursement matrix",
        type_of_transaction: "reimbursement",   
        icon: "Reimbursement",
    }

]