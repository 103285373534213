import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./index.css";
import SideDrawerOptions from "../SideDrawerOptions";
import { initiateEmail } from "../../../redux/features/initiateEmail/initiateEmail";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const SideDrawer = (props) => {
  const dispatch = useDispatch();
  const initiateEmailRes = useSelector((state) => state.initiateEmailState.response)
  const [checkRes,setCheckRes] = useState(false)

  useEffect(() => {
    if (checkRes && initiateEmailRes?.errors) {
      toast.error((Object.values(initiateEmailRes.errors)[0][0]), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && initiateEmailRes?.status) {
      toast.success("Transaction initiation email sent successfully.", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setCheckRes(true)
  }, [initiateEmailRes])


  return (
    <Box className="container-drawer expandable">
      <Box className="items">
        <SideDrawerOptions
          img_path={"Group 1763.png"}
          text={"Send transaction initiation email"}
          fn={() => dispatch(initiateEmail({ txn_id: [props.id] }))}
        />
        <SideDrawerOptions
          disable={true}
          img_path={"Group 1764.png"}
          text={"Send payment advice to vendor"}
        />
        <SideDrawerOptions
          disable={true}
          img_path={"Group 1760.png"}
          text={"Download payment advice"}
        />
        <SideDrawerOptions
          disable={true}
          img_path={"Group 1759.png"}
          text={"Send HDFC payment advice to vendor"}
        />
        <SideDrawerOptions
          disable={true}
          img_path={"Group 1759.png"}
          text={"Download HDFC payment advice"}
        />
        <SideDrawerOptions
          disable={true}
          img_path={"Group 1752.png"}
          text={"Send refund initiation email"}
        />
        <SideDrawerOptions
          disable={true}
          img_path={"Group 1761.png"}
          text={"Send refund successful email"}
        />
      </Box>
    </Box>
  );
};

export default SideDrawer;
