import React from "react";
import { useDispatch } from "react-redux";
import { approveVendorTxn } from "../../../../redux/features/approveTxn/approveTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import alert from "../../../../../src/assets/Approve transactions/Alert.png";
import verified from "../../../../../src/assets/Approve transactions/Verified.png";
import "./index.css";
import LineWithImage from "../LineWithImage";
import formatDate from "../../../../utils/formatdate";
import trimSentence from "../../../../utils/trimSentence";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const ApprovalDetailsCard = (props) => {
  const dispatch = useDispatch();

  return (
    <div className="w-full overflow-hidden text-sm flex rounded-4xl bg-[#3e5555]">
      <div className="w-1/2 rounded-4xl text-white">
        <div className="p-8">
          <div
            style={{
              borderColor:
                props.data?.vendor?.status === "Y" ? "#a0a0a0" : "#ff9130",
            }}
            className="border-dashed border-2 pb-4"
          >
            <div
              className="text-center font-normal text-lg leading-normal tracking-normal bg-[#3e5555] w-4/6 mx-auto py-0 px-2 -mt-4 mb-5"
              style={{
                color:
                  props.data?.vendor?.status === "Y" ? "#ffffff" : "#ff9130",
              }}
            >
              {props.data?.vendor?.status === "Y"
                ? "Approved account details"
                : "Pending account details"}
            </div>
            <div className="px-4">
              <div className="simpleBorderBox truncate ...">
                <span className="text-[white] text-lg font-medium truncate ...">
                  +91 {props.data?.vendor.contact_no}
                </span>{" "}
                <br />
                Contact number
              </div>
              <div className="simpleBorderBox truncate ...">
                <span className="text-[white] text-lg font-medium truncate ...">
                  {props.data?.vendor.email_id}
                </span>{" "}
                <br />
                Email ID
              </div>
              <div className="dashedBorderBox truncate ...">
                <span className="text-[white] text-lg font-medium truncate ...">
                  {props.data?.vendor.gstin
                    ? props.data?.vendor.gstin
                    : "Not given"}
                </span>{" "}
                <br />
                GSTIN
              </div>
            </div>
            <LineWithImage imageUrl={verified} />
            <div className="px-4 text-[#c9c9c9]">
              <div className="simpleBorderBox mb-3 pb-3 truncate ...">
                <span className="text-[white] text-lg font-medium truncate ...">
                  {props.data?.pan.pan ? props.data?.pan.pan : "Not available"}
                </span>{" "}
                <br />
                PAN number
              </div>
              <div className="simpleBorderBox mb-3 pb-3 truncate ...">
                <span className="text-[white] text-lg font-medium truncate ...">
                  {props.data?.pan.name
                    ? props.data?.pan.name
                    : "Not available"}
                </span>{" "}
                <br />
                Legal name
              </div>
              <div className="flex w-full justify-between items-center ">
                {!props.data?.account?.legal_name.length >= 1 ? (
                  <>
                    <b className="text-lg">
                      We were unable to verify bank credentials, as{" "}
                      <span className="capitalize">
                        {props.data?.transaction?.initiator_name.toLowerCase()}
                      </span>{" "}
                      has chosen to skip verification.
                    </b>
                    <img
                      width={"101px"}
                      style={{
                        width: "8%",
                        height: "8%",
                        background: "inherit",
                      }}
                      src={alert} // conditional icon rendering
                      alt=""
                      srcSet=""
                    />
                  </>
                ) : (
                  <div style={{ paddingTop: "" }}>
                    <span className="capitalize text-[white] text-lg">
                      {props.data?.account?.legal_name.toLowerCase()}
                    </span>{" "}
                    <br />
                    <span style={{ color: "#C9C9C9" }}>
                      Account holder name
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-[#C9C9C9] text-lg py-2">
            Requested by{" "}
            <span className="capitalize">
              {props.data?.transaction?.initiator_name.toLowerCase()}
            </span>
          </div>
        </div>
      </div>
      <div className="w-1/2 rounded-4xl bg-[#4A6565]">
        <div
          className="text-2xl font-semibold capitalize py-0 px-6 text-center text-white  w-full my-6
          truncate ..."
        >
          {props?.data?.vendor.name}
        </div>
        <div className="bg-[#def5ed] text-center py-3">
          <div className="text-4xl font-semibold pb-4 text-[#69B76F]">
            {" "}
            ₹ {formatCurrency(props.data?.transaction.amount)}
          </div>
          <p className="text-[#707070] text-lg">
            {formatDate(props.data?.transaction.initiation_date)}
          </p>
        </div>
        <div className="p-4 px-6">
          <div className=" simpleBorderBox truncate ...">
            <span className="text-lg text-white font-medium">
              {props.data?.account.bank_account_no}
            </span>{" "}
            <br />
            Account number
          </div>
          <div className="dashedBorderBox">
            <div className="paraNImg">
              <p className="w-1/2 truncate ...">
                <span className="text-lg text-white font-medium">
                  {props.data?.account.ifsc_code}{" "}
                </span>
                <br />
                <span style={{ color: "#c9c9c9" }}> IFSC code </span>
              </p>
              <img
                width={"25%"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.data?.account.bank) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </div>
          </div>
          <div
            style={{
              height: "3px",
              padding: 0,
              margin: "10px 0",
              opacity: "0.2",
              marginBottom: "27.21px",
              background:
                "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
            }}
            className="dashedLine"
          ></div>
          <div className="simpleBorderBox truncate ...">
            <span className="text-lg text-white font-medium">
              {props.data?.transaction?.invoice_no
                ? props.data?.transaction.invoice_no
                : "Not given"}
            </span>{" "}
            <br />
            Invoice number
          </div>
          <div className="noBorderBox truncate ...">
            <span className="text-lg text-white font-medium">
              {props.data?.transaction?.distribution_remark
                ? props.data?.transaction.distribution_remark
                : "Not given"}
            </span>{" "}
            <br />
            Distribution remark
          </div>
        </div>
        <div className="flex text-white text-base justify-center gap-6 m-auto my-8">
          <button
            className="bg-[#ea545b] w-2/6 font-normal px-1 rounded-4xl text-lg flex items-center justify-center"
            onClick={() => {
              props.setDeniedReasonData({
                txnId: [props?.data.transaction.id],
                dispatchFn: approveVendorTxn,
                setShowStatusCard: () =>
                  props.setShowStatusCard([
                    ...props.showStatusCard,
                    props.index,
                  ]),
              });
              props.setDeniedReason(true);
            }}
          >
            <img
              width={"19px"}
              src={require("../../../../assets/Approve transactions/Decline white.png")}
              alt=""
            />{" "}
            &nbsp;&nbsp; Decline
          </button>
          <button
            onClick={() => {
              dispatch(
                approveVendorTxn({
                  txn_id: [props?.data.transaction.id],
                  status: "Y",
                })
              );
              props.setShowStatusCard([...props.showStatusCard, props.index]);
            }}
            className="bg-[#69b76f] w-2/6 font-normal text-xl px-1 py-2 rounded-4xl flex items-center justify-center "
          >
            <img
              width={"19px"}
              src={require("../../../../assets/Approve transactions/Approve white.png")}
              alt=""
            />
            &nbsp;&nbsp; Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalDetailsCard;
