import React from 'react'
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../redux/features/refreshToken/refreshToken";
import "./index.css";
import {
    setShowReloginModalFalse,
    setMultipleEntity,
} from "../../../redux/features/login/login";

const LogoutModel = ({
    onClose
}) => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    // const handleCancel = () => {
    //     setIsLogoutModelOpen(false)
    // }

    // const handleConfirm = () => {
    //     dispatch(logout())
    // }

    return (
        <div
            className="fixed w-screen top-0 left-0 h-screen z-[9999]  bg-black bg-opacity-80 overflow-hidden"
        // style={{ backgroundColor: "#000000", opacity: "0.8" }}
        >

            <div
                className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
                style={{ transform: "translate(-50%, -50%)" }}
            >
                <div
                    className="h-fit w-70% flex flex-col gap-6 rounded-2xl"
                    style={{ width: "561px", height: "211px", backgroundColor: "#1D3A6D", padding: "20px 20px 20px 20px" }}
                >
                    <h1 className='Logoutheader'>Logout</h1>
                    <h2 className='middleContentlogout'>Are you sure you want to logout?</h2>
                    <div className='lastSection'>

                        <div className='updateEdit' onClick={() => {
                            dispatch(setMultipleEntity());
                            dispatch(setShowReloginModalFalse());
                            dispatch(logout());
                            navigate("/login");
                        }
                        } >
                            <p>Confirm</p>
                        </div>
                        <div className="cancelLogout" onClick={() => {
                            onClose()
                        }}>
                            <p>Cancel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default LogoutModel
