import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";

const baseUrl = env.REACT_APP_BASE_URL;
export const transactionApproval = createAsyncThunk(
  "transactionApproval",
  async (values) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(`${baseUrl}/transactions/beneficiary`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const transactionSettlement = createAsyncThunk(
  "transactionSettlement",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({}),
    };

    return await fetch(
      `${baseUrl}/dashboard/get_actionables/transactions/settlement`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const accountApproval = createAsyncThunk(
  "accountApproval",
  async (values) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(`${baseUrl}/accounts/beneficiary`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const get_categories = createAsyncThunk(
  "get_categories",
  async (values) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(`${baseUrl}/transaction_categories`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const homeDashboard = createSlice({
  name: "homeDashboard",
  initialState: {
    isLoading: null,
    txnApprovalReq: null,
    txnSettlementReq: null,
    accApprovalReq: null,
    isError: false,
    categories_Data: null,
    categories_Loading: false,
    categories_Error: false,
  },
  extraReducers: (builder) => {
    builder.addCase(transactionApproval.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(transactionApproval.fulfilled, (state, action) => {
      state.isLoading = false;
      state.txnApprovalReq = action.payload;
    });
    builder.addCase(transactionApproval.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(transactionSettlement.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(transactionSettlement.fulfilled, (state, action) => {
      state.isLoading = false;
      state.txnSettlementReq = action.payload;
    });
    builder.addCase(transactionSettlement.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(accountApproval.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(accountApproval.fulfilled, (state, action) => {
      state.isLoading = false;
      state.accApprovalReq = action.payload;
    });
    builder.addCase(accountApproval.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(get_categories.pending, (state, action) => {
      state.categories_Loading = true;
    });
    builder.addCase(get_categories.fulfilled, (state, action) => {
      state.categories_Loading = false;
      state.categories_Data = action.payload;
    });
    builder.addCase(get_categories.rejected, (state, action) => {
      state.categories_Loading = false;
      state.categories_Error = true;
    });
  },
});

export default homeDashboard.reducer;
