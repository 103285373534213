import React, { useEffect, useRef, useState } from "react";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../assets/OwnerAccount/arrowdown1.png";
import clear_filter from "../../../assets/ViewAllTransactions/Clear filter.png";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import "./index.css";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const DistributionStatusPopover = ({
  data,
  setData,
  getAccStatus,
  lifecycleStatus,
  selectedStatus,
  setPage,
  isStatusSelected,
  isDistributionSelected,
  setIsDistributionSelected,
  isDistributionPopoverOpen,
  setIsDistributionPopoverOpen,
}) => {
  // const [isDistributionPopoverOpen, setIsDistributionPopoverOpen] = useState(false);
  const distributionRef = useRef(null);

  const handleClick = () => {
    if (isStatusSelected) {
      toast.error(
        "Only one status filter can be selected at a time.",
        toast_position
      );
    } else {
      setIsDistributionPopoverOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    setIsDistributionPopoverOpen(false);
  };

  const handleClearStatus = (event) => {
    if (event) event.stopPropagation();
    handleClose();
    getAccStatus(null, "distribution");
    setIsDistributionSelected(false);
    setPage(1);
  };

  const handleStatus = (status) => {
    handleClose();

    let statusToSend;

    if (status === "denied") {
      statusToSend = "denied";
    } else if (status === "awaiting verification") {
      statusToSend = "approved";
    } else {
      statusToSend = status;
    }
    getAccStatus(statusToSend, "distribution");
    setPage(1);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "rejected":
      case "cancelled":
      case "denied":
      case "failed":
        return "#ff5860";
      case "hold":
      case "queued":
        return "#FBC02D";
      case "settled":
        return "#4ddd37";
      case "all":
        return "#ffff";
      case "awaiting verification":
        return "#b196fa";
      case "abandoned":
        return "#a3a3a3";
      default:
        return "#ffff";
    }
  };

  const formatStatusText = (status) => {
    if (!status) {
      return "All";
    }
    return `${status[0]?.toUpperCase()}${status.slice(1)?.toLowerCase()}`;
  };

  const getIcon = () => {
    if (isDistributionPopoverOpen) {
      return { src: arrowup, onClick: handleClick };
    }
    if (
      (!isDistributionPopoverOpen && !isDistributionSelected) ||
      selectedStatus === null
    ) {
      return { src: arrowdown, onClick: handleClick };
    }
    if (
      !isDistributionPopoverOpen &&
      selectedStatus !== "All" &&
      selectedStatus !== null
    ) {
      return { src: clear_filter, onClick: handleClearStatus };
    }
    return { src: null, onClick: null };
  };

  const { src: icon, onClick: iconClickHandler } = getIcon();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        distributionRef.current &&
        !distributionRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={distributionRef}
      className="beneTxnViewAllDistriPopover"
      style={{
        opacity: isStatusSelected ? 0.5 : 1,
        cursor: isStatusSelected ? "default" : "pointer",
      }}
    >
      <div
        className="benTxnViewall_distri_inner_div"
        style={{
          borderBottom: isDistributionPopoverOpen
            ? "1px solid #1d3a6d"
            : "none",
          opacity: isStatusSelected ? 0.5 : 1,
          cursor: isStatusSelected ? "default" : "pointer",
        }}
        onClick={handleClick}
      >
        <button
          id="bene_txn_distri_popover_section"
          className={`bene_txn_pop_overbtn ${formatStatusText(selectedStatus)}`}
          style={{ color: getStatusColor(selectedStatus) }}
        >
          {selectedStatus === "denied"
            ? "Denied"
            : selectedStatus === "approved"
            ? "awaiting verification"
            : selectedStatus === "rejected"
            ? "Failed"
            : formatStatusText(selectedStatus || "All")}
        </button>
        <p className="bene_viewall_distri_subHeading">Distribution Status</p>
        <div
          className="bene_distri_viewall_popover_icon p-[4px]"
          onClick={(event) => {
            event.stopPropagation();
            if (iconClickHandler) {
              iconClickHandler();
            }
          }}
        >
          {icon && (
            <img
              src={icon}
              alt={
                selectedStatus === "All" ? "Arrow Icon" : "Clear Filter Icon"
              }
              className={`bene_txn_status_icon ${
                selectedStatus === "All" ? "arrow-icon" : "cancel-icon"
              }`}
            />
          )}
        </div>
      </div>
     <div className={`bene-txn-distri-viewall-popover ${isDistributionPopoverOpen ? "open" : "closed" }`}>
          {[
            "all",
            "settled",
            "queued",
            "hold",
            "awaiting verification",
            "pending",
            "denied",
            "failed",
            "abandoned",
          ].map((status) => (
            <div
              className={`popover-item ${formatStatusText(
                status
              )}_bene_txn_distri_popover_btn h-[30px] hover:bg-[#3A5686] rounded-[5px] flex items-center justify-between mt-[5px] pl-[14px]`}
              key={status}
              onClick={() => handleStatus(status)}
              style={{
                transition: 'opacity 0.3s ease-in',
                opacity: isDistributionPopoverOpen ? '1' : '0',
                visibility: isDistributionPopoverOpen ? "visible" : "hidden",
                color: getStatusColor(status),
                backgroundColor:
                  selectedStatus === status && selectedStatus !== "all"
                    ? "#3A5686"
                    : "",
              }}
            >
              {formatStatusText(status)}
              {selectedStatus === status && (
                <img
                  src={allChecker}
                  alt="Checkmark"
                  className="all-txn-status-icon mr-1"
                />
              )}
            </div>
          ))}
        </div>
    </div>
  );
};

export default DistributionStatusPopover;
