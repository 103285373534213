import { Box, Button } from "@mui/material";
import React from "react";
import "./index.css";

const TxnDetailsToggleButtons = ({ handleToggleView, toggleView, clientTxn }) => {
  return (
    <Box className="px-2 self-center flex flex-row gap-4">
      <div className="px-4 py-1 self-center rounded-3xl
        text-sm 
        cursor-pointer"
        style={{
          backgroundColor: toggleView === "details" ? "white" : "#373f4b",
          color: toggleView === "details" ? "black" : "white",
          textTransform: "none",
          "&:hover": {
            background: "none",
          }
        }}
        onClick={() => {
          handleToggleView("details");
        }}
      >
        Details
      </div>
      <div className="px-4 py-1 self-center rounded-3xl
        text-sm 
        cursor-pointer"
        style={{
          backgroundColor: toggleView === "timeline" ? "white" : "#373f4b",
          color: toggleView === "timeline" ? "black" : "white",
          textTransform: "none",
          "&:hover": {
            background: "none",
          }
        }}
        onClick={() => {
          handleToggleView("timeline");
        }}
      >
        Timeline
      </div>
      {clientTxn ? (
        <div className="px-4 py-1 self-center rounded-3xl
        text-sm 
        cursor-pointer"
        style={{
          backgroundColor: toggleView === "refunds" ? "white" : "#373f4b",
          color: toggleView === "refunds" ? "black" : "white",
          textTransform: "none",
          "&:hover": {
            background: "none",
          }
        }}
        onClick={() => {
          handleToggleView("refunds");
        }}
      >
        Refunds
      </div>
      ) : null}
    </Box>
  );
};

export default TxnDetailsToggleButtons;
