import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const initiateRefund = createAsyncThunk(
  "initiateRefund",
  async (values) => {
    const { txn_id, amount } = values;
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: txn_id,
        amount: amount,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/Initiate_refund_against_txn`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const initiateRefundState = createSlice({
  name: "initiateRefundState",
  initialState: {
    isLoading: null,
    response: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(initiateRefund.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(initiateRefund.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
      if (action.payload.status === "true") {
        toast.success("Transaction converted successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error(`Error: ${Object.keys(action.payload.errors)}`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
    builder.addCase(initiateRefund.rejected, (state, action) => {
      state.isError = true;
      toast.error(`Error: ${action.payload}`, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  },
});

export default initiateRefundState.reducer;