import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getTxnTimeline } from "../../../redux/features/txnTimeline/txnTimeline";
import { useParams } from "react-router-dom";
import ReactTimeConvertor from "react-time-convertor";
import formatdate from "../../../utils/formatdate";
import "./index.css";
import { timelineLogoMapper } from "../../../services/timelineLogoMapper";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";

export default function TimeLine(props) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const txnTimeline = useSelector((state) => state.txnTimeline.data);

  useEffect(() => {
    dispatch(getTxnTimeline({ txn_id: id }));
  }, [dispatch, id]);

  const TimeLineCard = ({ txn }) => {
    let logo = timelineLogoMapper(txn);
    return (
      <Accordion className="timeLineAccordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box className="flex items-center flex-row justify-start gap-4">
            <div className="w-[10%]">
              <img
                src={require("../../../assets/TxnDetails/" + logo)}
                alt="Avatar"
              />
            </div>
            <Box className="flex justify-center flex-col">
              <Box
                className="text-base
              text-white antialiased "
              >
                {txn?.status}
              </Box>
              <Box className="text-gray-300 text-xs antialiased">
                {txn?.timestamp == undefined
                  ? {}
                  : formatdate(txn?.timestamp)}
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          expandIcon={<ExpandMoreIcon />}
          className="flex flex-row justify-start gap-4"
        >
          <div className="w-[10%]"></div>
          <div className="flex flex-col gap-2">
            {(txn.first_name || txn.last_name) && (
              <Box className="">
                <Box
                  className="text-base
           text-white antialiased truncate ..."
                >
                  {txn.first_name + " " + txn.last_name}
                </Box>
                <Box className="text-gray-300 text-xs antialiased">Owner</Box>
              </Box>
            )}
            {(txn.model || txn.brand || txn.browser) && (
              <Box className="timeLineCardMiddle">
                <Box
                  className="text-base
           text-white antialiased truncate ..."
                >
                  {txn.model} {txn.brand} {txn.browser}
                </Box>
                <Box className="text-gray-300 text-xs antialiased">Model</Box>
              </Box>
            )}
            {txn?.grade && (
              <Box className="timeLineCardMiddle">
                <Box
                  className="text-base
           text-white antialiased truncate ..."
                >
                  {txn?.grade}
                </Box>
                <Box className="text-gray-300 text-xs antialiased">Grade</Box>
              </Box>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };
  return (
    <div
      className="h-full drop-shadow-2xl flex flex-col
      rounded-4xl p-8"
      style={{
        backgroundColor: VendorTxnDetailChildBgMapper(props?.approval_status),
        "&:hover": { background: "#fafafa33" },
      }}
    >
      <div className="flex">
        <div className="self-center text-gray-300 text-sm font-bold">
          Transaction Timeline
        </div>
      </div>
      <div className="py-0 grid grid-cols-2">
        {txnTimeline
          ? txnTimeline["transaction_detail"]?.map((txn) => (
            <Grid
              key={txn}
              className="min-w-full timeLineGridItem"
              item
              xs={12}
              sm={12}
              md={6}
            // sx={{ '&:hover': { background: '#fafafa33' } }}
            >
              <TimeLineCard txn={txn} />
            </Grid>
          ))
          : null}
      </div>
    </div>
  );
}
