import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const approveVendorTxn = createAsyncThunk(
  "approveVendorTxn",
  async (values) => {
    const { status, txn_id, denied_reason } = values
      ? values
      : {
        status: null,
        txn_id: null,
        reason: null,
      };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        status: status,
        txn_id: txn_id,
        reason: denied_reason ? denied_reason : "N/A",
      }),
    };
    console.log("requestOptions", requestOptions);
    return await fetch(
      `${legacyUrl}/transactions/vendor/action`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

export const approveFFTxn = createAsyncThunk(
  "approveFFTxn",
  async (values) => {
    const { status, txn_id, denied_reason } = values
      ? values
      : {
        status: null,
        txn_id: null,
        denied_reason: null,
      };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        action: status,
        txn_id: txn_id,
        reason: denied_reason ? denied_reason : "N/A",
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/action`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

export const approveReimbursementTxn = createAsyncThunk(
  "approveReimbursementTxn",
  async (values) => {
    const { status, txn_id, denied_reason } = values
      ? values
      : {
        status: null,
        txn_id: null,
        denied_reason: null,
      };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        action: status,
        txn_id: txn_id,
        denied_reason: denied_reason,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/reimbursement/action`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

const approveTxn = createSlice({
  name: "approveTxn",
  initialState: {
    vendor: null,
    isError: false,
    reimbursement: null,
    ff: null,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(approveVendorTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(approveVendorTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(approveVendorTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    builder.addCase(approveFFTxn.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(approveFFTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.ff = action.payload;
    }
    );
    builder.addCase(approveFFTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    builder.addCase(approveReimbursementTxn.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(approveReimbursementTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbursement = action.payload;
    }
    );
    builder.addCase(approveReimbursementTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
  },
});

export default approveTxn.reducer;














