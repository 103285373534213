import React, { useState } from "react";
import Datacard from "../../components/Datacard";
import { Link } from "react-router-dom"
import { Box, capitalize, Grid, Typography } from "@mui/material";
import RecentTransactions from "../../components/RecentTransactions";
import StartCreateYourTransaction from "../../components/StartCreateYourTransaction";
import NoDataImg from "../../assets/TransactionDashboardIcons/Nothing.png";
import colorCode from "../../utils/colorCodeMapper";
import "./index.css";

const DuplicateTransactions = ({ data }) => {
  const [recentTransactions] = useState([
    {
      account_number: "Aalesh",
      amount: "100",
      bankLogo: "axis.png",
      status: "#B196FA",
    },
    {
      account_number: "Aalesh",
      amount: "100",
      bankLogo: "axis.png",
      status: "#31E5E5",
    },
    {
      account_number: "1234567890",
      amount: "100",
      bankLogo: "axis.png",
      status: "#FBC02D",
    },
    {
      account_number: "1234567890",
      amount: "100",
      bankLogo: "axis.png",
      status: "#FF5860",
    },
    {
      account_number: "1234567890",
      amount: "100",
      bankLogo: "axis.png",
      status: "#4DDD37",
    },
  ]);
  const [dataCardDetails] = useState({
    processing_txn_details: {
      records: "11",
    },
    settled_txn_details: {
      records: "11",
    },
    abandoned_txn_details: {
      records: "34",
    },
    pending_txn_details: {
      records: "34",
    },
  });

  return (
    <Box sx={{ display: "flex", marginTop: "100px" }}>
      <Box className="leftBody">
        <Box className="createTransaction">
          <StartCreateYourTransaction />
        </Box>
        <Box className="dataCards">
          {dataCardDetails ? (
            Object.entries(dataCardDetails).map(([key, value]) => {
              return (
                <Datacard
                  key={key}
                  color={colorCode[key]}
                  text={capitalize(key.split("_")[0] + " transactions")}
                  number={value.records}
                />
              );
            })
          ) : (
            <>
              {" "}
              <Box sx={{ opacity: "0.5" }}>
                <Datacard
                  color={"yellow"}
                  text={"Processing transactions"}
                  number={0}
                />
              </Box>
              <Box sx={{ opacity: "0.5" }}>
                <Datacard
                  color={"green"}
                  text={"Successful transactions"}
                  number={0}
                />
              </Box>
            </>
          )}
        </Box>
        <Box>
          {recentTransactions ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 2%",
                  marginTop: "10%",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "left",
                    font: "normal normal bold 20px/27px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >
                  Recent
                </Typography>
                <Link
                  to="/duplicate_transactions/view_all_transactions"
                  style={{
                    textAlign: "right",
                    font: "normal normal bold 20px/27px Open Sans",
                    letterSpacing: "0px",
                    color: "#FF7F33",
                    opacity: 1,
                    textDecoration: "underline"
                    }} className="viewAllLink"
                >
                  View All
                </Link>
              </Box>
              <Box className="recentTransactions">
                {recentTransactions.map((transaction) => {
                  return (
                    <RecentTransactions
                      img={transaction.bankLogo}
                      amount={transaction.amount}
                      account={transaction.account_number}
                      status={transaction.status}
                    />
                  );
                })}
              </Box>
            </>
          ) : (
            <>
              <Typography sx={{ color: "white" }}>
                No transactions found <br />
                All InstantPay refill transactions will appear here.
              </Typography>
              <Box
                sx={{
                  width: "50%",
                  margin: "auto",
                  marginTop: "5%",
                }}
              >
                <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box
        className="sideMenu"
        sx={{
          width: "25%",
          backgroundColor: "#1D3A6D",
          minHeight: "100vh",
          padding: "3% 5%",
        }}
      >
        <Grid spacing={5} container>
          <Grid item>
            <StartCreateYourTransaction />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DuplicateTransactions;
