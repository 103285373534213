import AccountDetailCard from "../../../../components/Accounts/Common/AccountDetailCard";
import { accountDetailsCardBg } from "../../../../services/approvalStatus";
import AccountOwnerDetailBannerComponent from "../../../../components/Accounts/OwnerAccounts/AccountOwnerDetailBannerComponent";

const AccountOwnerDetailsBannerComponent = ({ accountData }) => {
  const bannerComponent = () => {
    return (
      <AccountOwnerDetailBannerComponent
        bank_name={accountData?.bank_name}
        account_name={accountData?.name}
        account_id={accountData?.owner_id}
        account_type={"vendor"}
        last_fetched_at={accountData?.last_fetched_balance}
        balance={accountData?.available_balance}
        approval_status={accountData?.approval_status}
        account_number={accountData?.bank_acc_no}
        vendor_payment={accountData?.vendor_payment === "Y" ? true : false}
        accountLinkedDiv={accountData?.hdfc_umrn ? true : false}
      />
    );
  };

  return bannerComponent();
};

const AccountOwnerDetailsBodyComponent = ({ accountData }) => {
  const accountBodyComponents = () => {
    let cardsNeeded = {
      "Bank Details": {
        "IFSC code": accountData?.ifsc_code,
        "Account type": accountData?.mode_of_account || "Other",
      },
      Permissions: { "Vendor Payments": accountData?.vendor_payment },
    };
    return (
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        {cardsNeeded &&
          Object.keys(cardsNeeded).map((key, index) => {
            return (
              <div className="w-full h-fit">
                <AccountDetailCard
                  heading={key}
                  data={cardsNeeded[key]}
                  bg_color={accountDetailsCardBg(accountData?.approval_status)}
                />
              </div>
            );
          })}
      </div>
    );
  };
  return accountBodyComponents();
};

export { AccountOwnerDetailsBannerComponent, AccountOwnerDetailsBodyComponent };
