import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";

const BeneAccListView = ({ data, isSidebarOpen, isPopoverOpen }) => {
  const [columns, setColumns] = useState(3);
  const navigate = useNavigate();

  const addSpacesToNumber = (number) => {
    return number.replace(/(.{4})/g, "$1 ").trim();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      case "all":
        return "#ffff";
      case "settled":
        return "#4ddd37";
      default:
        return "#ffff";
    }
  };

  const listdata = data || [];

  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      let newColumns;
      let newCardWidth;

      if (width >= 3840) {
        newColumns = 5;
        newCardWidth = "720px";
      } else if (width >= 2560) {
        newColumns = 4;
        newCardWidth = "600px";
      } else if (width >= 1920) {
        newColumns = 3;
        newCardWidth = "425px";
      } else {
        newColumns = 3;
        newCardWidth = "425px";
      }

      setColumns(newColumns);
    };

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  return (
    <div
      className="bene-txn-list-container_acc"
      style={{
        paddingBottom: "20px",
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        columnGap: "30px",
        opacity: isSidebarOpen || isPopoverOpen ? "0.4" : "1",
        background: isSidebarOpen || isPopoverOpen ? "#000" : "",
      }}
    >
      {listdata.map((item) => (
        <div
          key={item.id}
          style={{
            height: "49px",
            cursor: isSidebarOpen || isPopoverOpen ? "default" : "pointer",
            pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
            opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
            background: isSidebarOpen || isPopoverOpen ? "#000" : "",
            marginBottom: "10px",
          }}
        >
          <div
            onClick={async () => {
              let selection = window.getSelection();
              if (selection.toString().length === 0) {
                if (!isSidebarOpen) {
                  navigate(`/accounts/beneficiary_account/details/${item?.id}`);
                }
              }
            }}
            style={{
              opacity: isSidebarOpen || isPopoverOpen ? "0.8" : "1",
              background: isSidebarOpen || isPopoverOpen ? "#000" : "",
              cursor: isSidebarOpen || isPopoverOpen ? "default" : "pointer",
              pointerEvents: isSidebarOpen || isPopoverOpen ? "none" : "",
            }}
            className="bene_txn_list_section_acc"
          >
            <div className="bene_txn_listview_bank_div_img_acc">
              <img
                src={require("../../../../assets/IfscLogo/" +
                  IfscCodeMapper(item?.ifsc_code))}
                alt="bank"
              />
            </div>
            <div className="bene_txn_list_accounts_div_acc font-normal w-[180px] overflow-hidden whitespace-nowrap overflow-ellipsis text-[#F9F9F9]">
              <p
                className="bene_txn_list_name_acc w-[159px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                style={{
                  font: "normal normal normal 12px/17px Open Sans",
                  marginBottom: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {" "}
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title={
                    <span style={{ textTransform: "capitalize" }}>
                      {item?.name}
                    </span>
                  }
                >
                  <span>{item?.name}</span>
                </Tooltip>
              </p>

              <p
                className="font-normal text-[12px] leading-19 text-[#DADADA] opacity-100"
                style={{
                  font: "normal normal normal 12px/17px Open Sans;",
                  width: "119px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {addSpacesToNumber(item?.bank_acc_no)}
              </p>
            </div>

            <div className="bene_txn_list_amount_div_acc">
              <p
                className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[#4DE659]"
                style={{
                  font: "normal normal normal 12px/17px Open Sans",
                  color: getStatusColor(item?.approval_status),
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    fontSize: "13px",
                    paddingRight: "5px",
                  }}
                >
                  {item?.approval_status}
                </span>
              </p>
              <p
                className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                style={{
                  font: "normal normal normal 10px/14px Open Sans",
                }}
              >
                <span>Approval status</span>
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
    // </div>
  );
};

export default BeneAccListView;
