import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./index.css";
import EnterDetailsVendorTxn from "../../../components/CreateTxn/CreateVendorTxn/EnterDetails";
import { useSelector, useDispatch } from "react-redux";
import { setBufferState } from "../../../redux/features/bufferState/bufferState";
import TransactionStatus from "./TransactionStatus";
import CustomizedStepper from "../../../components/CreateTxn/CustomizedStepper";
import { toast } from "react-toastify";
import CreateInternalTxnForm from "../../../components/CreateTxn/CreateInternaltxn/CreateInternalTxnForm";
import { createInternalTxn } from "../../../redux/features/createTxn/createTxn";
import FilledCardComponent from "../../../components/CreateTxn/CreateInternaltxn/FilledCardComponent";

const CreateInternalTxn = () => {
  const services = useSelector((state) => state.refreshToken.services);
  const dispatch = useDispatch();
  const DebitAcc = useSelector((state) => state.bufferState.internalDebit);
  const CreditAcc = useSelector((state) => state.bufferState.internalCredit);
  const [showStatus, setShowStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdInternalTxn = useSelector((state) => state.createTxn.internal);
  const createInternalTxnLoading = useSelector(
    (state) => state.createTxn.isLoading
  );
  const [checkRes, setCheckRes] = useState(false);
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const [createInternalData, setCreateInternalData] = useState({
    amount: null,
    destination_owner_id: null,
    source_owner_id: null,
    distribution_remark: null,
    invoice_no: null,
  });

  useEffect(() => {
    if (checkRes && createdInternalTxn?.errors) {
      toast.error(Object.values(createdInternalTxn.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && createdInternalTxn?.status) setShowStatus(true);
    setCheckRes(true);
  }, [createdInternalTxn]);

  const steps = [
    "Enter amount and additional details",
    "Select debit and credit accounts",
    "Confirm and transfer money",
  ];

  const handleNext = () => {
    if (activeStep === 2) {
      dispatch(createInternalTxn(createInternalData));
    }
    if (activeStep === 0 && createInternalData.amount) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      enableNextButton(false);
    }
    if (
      activeStep === 1 &&
      createInternalData.source_owner_id &&
      createInternalData.destination_owner_id
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      enableNextButton(true);
    }
    if (createdInternalTxn && createdInternalTxn.status === "true") {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1 && createInternalData.amount) {
      enableNextButton(true);
    }
    if (activeStep === 2) {
      enableNextButton(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (CreditAcc && DebitAcc) {
      setCreateInternalData({
        ...createInternalData,
        source_owner_id: DebitAcc.owner_id,
        destination_owner_id: CreditAcc.owner_id,
      });
      if (activeStep === 1) enableNextButton(true);
    }
  }, [CreditAcc, DebitAcc, activeStep]);

  useEffect(() => {
    return () => {
      dispatch(setBufferState({ internalCredit: null, internalDebit: null }));
    };
  }, []);

  return (
    <Box
      className="bg-custom-appBack
      flex flex-row justify-center overflow-auto vendor-txn
      min-h-screen"
    >
      {showStatus && !createInternalTxnLoading ? (
        <Box sx={{ marginTop: "-120px" }}>
          <TransactionStatus
            createdInternalTxn={createdInternalTxn?.transactions[0]}
          />
        </Box>
      ) : (
        <div
          className="self-center
          h-screen w-full
          w-10/12"
        >
          <div
            className="mt-20 h-fit
            flex flex-col gap-2 3xl:gap-4 5xl:gap-6
            py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20"
          >
            <div
              className="h-[15%]
              flex flex-row justify-center"
            >
              <CustomizedStepper
                nextButtonEnabled={nextButtonEnabled}
                activeStep={activeStep}
                steps={steps}
                componentType="Internal"
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </div>
            <div className="h-4/5 flex flex-row justify-center pb-24">
              <div className="w-10/12 h-full">
                {activeStep === 0 && (
                  <div className="p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <EnterDetailsVendorTxn
                        module={"Internal"}
                        setCreateTxnData={setCreateInternalData}
                        createTxnData={createInternalData}
                        enableNextButton={enableNextButton}
                      />
                    </div>
                  </div>
                )}
                {activeStep === 1 && (
                  <div className="flex flex-row justify-end p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <CreateInternalTxnForm editable={true} />
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div
                    className="flex flex-row justify-between
                    p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12"
                  >
                    <div className="w-[49%] h-fit">
                      <EnterDetailsVendorTxn
                        module={"Internal"}
                        disabledToggle={
                          createInternalData.amount ? true : false
                        }
                        setCreateTxnData={setCreateInternalData}
                        createTxnData={createInternalData}
                        enableNextButton={enableNextButton}
                        filled="true"
                      />
                    </div>
                    <div className="w-[49%] flex flex-col justify-between gap-8">
                      <div className="h-4/5">
                        <FilledCardComponent
                          activeStep={activeStep}
                          componentType="Debit"
                          data={DebitAcc}
                        />
                      </div>
                      <div
                        className="text-center h-1/5 flex justify-center items-center text-white font-semibold
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
                      >
                        <img
                          className="w-6 xl:w-8 3xl:w-10 5xl:w-12"
                          src={require("../../../assets/CreateTransactionIcons/White_arrow_internal.png")}
                          alt=""
                        />
                        To
                      </div>
                      <div className="h-4/5">
                        <FilledCardComponent
                          activeStep={activeStep}
                          componentType="Credit"
                          data={CreditAcc}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default CreateInternalTxn;
