import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CreateTxnMenuItemCard from "../../components/CreateTxnMenuItemCard";
import InstanPayAvailBal from "../../components/InstanPayAvailBal";
import { getInstantPayBalance } from "../../redux/features/instantPayBalance/instantPayBalance";

const RecieveTxn = () => {
  const dispatch = useDispatch();
  const instantAccountSummary = useSelector(
    (state) => state.instantPayBalance.balance
  );

  const instantPayAvailBal =
    instantAccountSummary && instantAccountSummary.balance_details
      ? instantAccountSummary.balance_details[0]
      : null;

  useEffect(() => {
    dispatch(getInstantPayBalance());
  }, [dispatch]);
  return (
    <>
      <div className="create-txn-container-top">
        <div className="create-txn-headings">
          <div className="create-txn-container-title">Select</div>
          <div className="create-txn-container-subtitle">
            The transaction type you want to create
          </div>
        </div>
        <div className="create-txn-create-txn">
          <InstanPayAvailBal
            availbal={
              instantPayAvailBal ? instantPayAvailBal.current_balance : ""
            }
          />
        </div>
      </div>
      <div className="create-txn-container-bottom">
        <div className="create-txn-menu-title">
          CREDIT TRANSACTIONS
          <div className="create-txn-menu-items">
            <CreateTxnMenuItemCard
              icon="Client.png"
              label="Client"
              subtext="Requets money from your clients"
              to="/create_transaction/client_transaction"
            />
            <CreateTxnMenuItemCard
              icon="Field force.png"
              label="Field Force"
              subtext="Create a field force transaction"
              to="/create_transaction/field_force_transaction?flow=R"
            />
            <CreateTxnMenuItemCard
              icon="Reimbursement.png"
              label="Reimbursement"
              subtext="Create a transaction to reimburse your employees"
              to="#"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RecieveTxn;
