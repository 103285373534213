import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./index.css";
import EnterDetailsVendorTxn from "../../../components/CreateTxn/CreateVendorTxn/EnterDetails";
import CreateVendorTxnForm from "../../../components/CreateVendorTxnForm";
import { useSelector, useDispatch } from "react-redux";
import { setBufferState } from "../../../redux/features/bufferState/bufferState";
import TransactionStatus from "./TransactionStatus";
import {
  createFFAcc,
  createVendorAccount,
} from "../../../redux/features/createVendorAcc/createVendorAcc";
import SelectPayment from "./SelectPayment";
import { resetVerification } from "../../../redux/features/verifyPAN/verifyPAN";
import RegisterdFilledForm from "../../../components/CreateVendorTxnForm/RegisterdFilledForm";
import CustomizedStepper from "../../../components/CreateTxn/CustomizedStepper";
import SelectedVendorAccount from "../../../components/CreateVendorTxnForm/SelectedVendorAccount";
import {
  createFFTxn,
  createVendorTxn,
} from "../../../redux/features/createTxn/createTxn";
import { toast } from "react-toastify";
import CreateFFTxnForm from "../../../components/CreateFFTxnForm";
import { useSearchParams } from "react-router-dom";
import { getFFAccountsData } from "../../../redux/features/accounts/accounts";
import { getUserView } from "../../../redux/features/users";
import { accountViewAllDetails } from "../../../redux/features/accounts/viewAll";

const CreateFFTxn = () => {
  const services = useSelector((state) => state.refreshToken.services);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const vendor_id = searchParams.get("vendor_id");

  const selectedAcc = useSelector((state) => state.bufferState.vendor);
  const linkUser = useSelector((state) => state.bufferState.linkUser);
  const createAcc = useSelector((state) => state.createAcc.fieldforce);
  const [showStatus, setShowStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdVendorTxn = useSelector((state) => state.createTxn.fieldforce);
  const createdVendorTxnLoading = useSelector(
    (state) => state.createTxn.isLoading
  );
  const [checkRes, setCheckRes] = useState(false);
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const limitApiRes = useSelector((state) => state.ffLimits.limit);
  const [createTxnData, setCreateTxnData] = useState({
    amount: null,
    distribution_remark: null,
    invoice_no: null,
    account_id: null,
  });
  const [registerNewVnerdorData, setRegisterNewVnerdorData] = useState({
    pan_no: "",
    bank_acc_no: "",
    ifsc_code: "",
    contact_no: "",
    name: "",
    skip_account_verification: "",
    txn_limit: "",
    email_id: "",
    gstin: "",
  });

  useEffect(() => {
    if (checkRes && createAcc.errors) {
      toast.error(Object.values(createAcc.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && createAcc.status) {
      dispatch(setBufferState({ vendor: createAcc.accounts[0] }));
      let newCreateTxnData = {
        ...createTxnData,
        ["account_id"]: createAcc.accounts[0].user_id
          ? createAcc.accounts[0].user_id
          : "",
      };
      setCreateTxnData(newCreateTxnData);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setCheckRes(true);
  }, [createAcc]);

  useEffect(() => {
    if (checkRes && createdVendorTxn.errors) {
      toast.error(Object.values(createdVendorTxn.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && createdVendorTxn.status) {
      setShowStatus(true);
    }
    setCheckRes(true);
  }, [createdVendorTxn]);

  useEffect(() => {
    let newCreateTxnData = { ...createTxnData, ["account_id"]: null };
    if (selectedAcc && activeStep === 0) {
      newCreateTxnData = {
        ...newCreateTxnData,
        ["account_id"]: selectedAcc.user_id,
      };
      setCreateTxnData(newCreateTxnData);
      enableNextButton(true);
    }
    if (activeStep >= 1 && createTxnData.amount && selectedAcc) {
      enableNextButton(true);
    }
  }, [selectedAcc, activeStep, registerNewVnerdorData]);

  let steps = [
    "Enter agent details",
    "Enter amount and additional details",
    "Confirm and create transaction",
  ];

  const handleNext = () => {
    if (activeStep === 2) {
      dispatch(createFFTxn(createTxnData));
    }
    if (activeStep === 0) {
      if (!selectedAcc?.account_id) {
        dispatch(
          createFFAcc({
            ...registerNewVnerdorData,
            user_id: linkUser.user_id,
            name: linkUser.first_name + " " + linkUser.last_name,
            email_id: linkUser.email_id,
            contact_no: linkUser.contact_no,
            txn_limit:
              registerNewVnerdorData.amount_limit ||
              limitApiRes?.limits?.maximum,
          })
        );
      }
    }
    if (activeStep === 0 && selectedAcc) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1 && createTxnData.amount && selectedAcc) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      enableNextButton(true);
    }
  };

  const handleBack = () => {
    if (activeStep === 1 && searchParams.get("flow") === "R") return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (searchParams.get("flow") === "R") {
      setActiveStep(1);
      getFFAccountsData(
        {
          user_id: localStorage.getItem("user_id"),
        },
        (res) => {
          dispatch(setBufferState({ vendor: res?.accounts[0] }));
        }
      );
    }
    return () => {
      dispatch(setBufferState({ vendor: null }));
      dispatch(resetVerification());
    };
  }, []);

  const vendor = useSelector((state) => {
    if (!vendor_id) return null;
    if (!state.accountViewAll.records) {
      dispatch(
        accountViewAllDetails({
          account_type: "field_force",
          account_id: vendor_id,
        })
      );
    }
    return state.accountViewAll.records
      ? state.accountViewAll.records[0]
      : null;
  });

  useEffect(() => {
    if (!vendor_id) return;
    dispatch(
      accountViewAllDetails({
        account_type: "field_force",
        account_id: vendor_id,
      })
    );
  }, [vendor_id]);

  useEffect(() => {
    if (vendor) {
      dispatch(setBufferState({ vendor: vendor }));
    }
  }, [vendor]);

  return (
    <Box
      className="bg-custom-appBack
      flex flex-row justify-center overflow-auto vendor-txn
      min-h-screen"
    >
      {showStatus && !createdVendorTxnLoading ? (
        <div>
          {services.includes("Settle_txn") &&
            createdVendorTxn?.transactions[0].approval_status === "Y" ? (
            <SelectPayment
              createTxnData={createTxnData}
              selectedAcc={selectedAcc}
              registerNewVnerdorData={registerNewVnerdorData}
              account={
                selectedAcc && selectedAcc.bank_acc_no
                  ? selectedAcc.bank_acc_no
                  : registerNewVnerdorData.bank_acc_no
              }
              amount={createTxnData.amount}
              txn_initiator_name={
                selectedAcc && selectedAcc.created_by_name
                  ? selectedAcc.created_by_name
                  : registerNewVnerdorData.name
              }
              ifsc={
                selectedAcc && selectedAcc.ifsc_code
                  ? selectedAcc.ifsc_code
                  : registerNewVnerdorData.ifsc_code
              }
              distribution_remark={createTxnData.distribution_remark}
              invoice_no={createTxnData.invoice_no}
              account_id={createTxnData.account_id}
            />
          ) : (
            <Box sx={{ marginTop: "-120px" }}>
              <TransactionStatus
                txn_status={
                  createdVendorTxn?.transactions[0].approval_status ===
                    "Processing"
                    ? "under Processing!"
                    : "created succesfully!"
                }
                approval_status={
                  createdVendorTxn?.transactions[0].approval_status
                }
                txn_id={createdVendorTxn?.transactions[0].txn_id}
                account={
                  selectedAcc && selectedAcc.bank_acc_no
                    ? selectedAcc.bank_acc_no
                    : registerNewVnerdorData.bank_acc_no
                }
                amount={createTxnData.amount}
                vendor_name={
                  selectedAcc && selectedAcc.name
                    ? selectedAcc.name
                    : registerNewVnerdorData.name
                }
                ifsc={
                  selectedAcc && selectedAcc.ifsc_code
                    ? selectedAcc.ifsc_code
                    : registerNewVnerdorData.ifsc_code
                }
                distribution_remark={createTxnData.distribution_remark}
                invoice_no={createTxnData.invoice_no}
                account_id={createTxnData.account_id}
              />
            </Box>
          )}
        </div>
      ) : (
        <div
          className="self-center
          h-screen w-full
          w-10/12"
        >
          <div
            className="mt-24 h-fit
            flex flex-col gap-2 3xl:gap-4 5xl:gap-6
            py-6 3xl:py-8 4xl:py-14 5xl:py-16 6xl:py-20"
          >
            <div
              className="h-1/5
              flex flex-row justify-center"
            >
              <CustomizedStepper
                nextButtonEnabled={nextButtonEnabled}
                activeStep={activeStep}
                steps={steps}
                twoSteps={searchParams.get("flow") === "R"}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </div>
            <div className="h-4/5 flex flex-row justify-center pb-24">
              <div className="w-10/12 h-full">
                {activeStep === 0 && (
                  <div className="p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <CreateFFTxnForm
                        setRegisterNewVnerdorData={setRegisterNewVnerdorData}
                        registerNewVnerdorData={registerNewVnerdorData}
                        enableNextButton={enableNextButton}
                        editable={true}
                      />
                    </div>
                  </div>
                )}
                {activeStep === 1 && (
                  <div
                    className="flex flex-row justify-between
                    p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12"
                  >
                    <div className="w-[49%] h-fit h-fit">
                      <CreateFFTxnForm
                        setRegisterNewVnerdorData={setRegisterNewVnerdorData}
                        registerNewVnerdorData={registerNewVnerdorData}
                        enableNextButton={enableNextButton}
                        editable={false}
                      />
                    </div>
                    <div className="w-[49%] h-fit h-fit">
                      <EnterDetailsVendorTxn
                        setCreateTxnData={setCreateTxnData}
                        createTxnData={createTxnData}
                        enableNextButton={enableNextButton}
                      />
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div
                    className="flex flex-row justify-between
                    p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12"
                  >
                    <div className="w-[49%] h-fit">
                      <CreateFFTxnForm
                        setRegisterNewVnerdorData={setRegisterNewVnerdorData}
                        registerNewVnerdorData={registerNewVnerdorData}
                        enableNextButton={enableNextButton}
                        editable={false}
                      />
                    </div>
                    {selectedAcc?.account_id ? (
                      <div className="w-[49%] h-fit">
                        <EnterDetailsVendorTxn
                          setCreateTxnData={setCreateTxnData}
                          createTxnData={createTxnData}
                          enableNextButton={enableNextButton}
                          filled={"true"}
                        />
                      </div>
                    ) : (
                      <div className="w-[49%] h-fit">
                        <RegisterdFilledForm
                          registerNewVnerdorData={registerNewVnerdorData}
                          enableNextButton={enableNextButton}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default CreateFFTxn;
