import React, { useEffect, useState, useRef, useCallback } from "react";
import "./index.css";
import { debounce } from "lodash";
import BeneViewAllCard from "../BeneViewAllCard";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import SearchBar from "../../../../components/_utils/search/SearchBar";
import {
  beneViewAllAcc,
  setToggle,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useDispatch, useSelector } from "react-redux";
import AllStatusTypeButton from "../AllStatusTypeButton";
import BeneAccFilterSidebar from "../BeneAccFilterSidebar";
import noaccount from "../../../../assets/Beneficiary Icons/noaccfound.png";
import { useLocation } from "react-router-dom";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import closeBtnImg from "../../../../assets/CategoryFilterIcons/cross.png";
import rentalImg from "../../../../assets/CategoryFilterIcons/rental.png";
import arrowForward from "../../../../assets/Beneficiary Icons/arrowForward.png";
import arrowup from "../../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../../assets/OwnerAccount/arrowdown1.png";
import ArrowDown8 from "../../../../assets/OwnerAccount/arrowdown8.png";
import ArrowUp4 from "../../../../assets/OwnerAccount/arrowdown4png.png";
import FilterBlue from "../../../../assets/OwnerAccount/filterblue.png";
import Filter from "../../../../assets/ViewAllTransactions/filter.png";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../../redux/features/login/login";
import search_blue from "../../../../assets/ViewAllTransactions/search_blue.png";
import search_white from "../../../../assets/Approve transactions/search-white.png";
import allChecker from "../../../../assets/Beneficiary Icons/whitecheckmark.png";
import ArrowLeft from "../../../../assets/OwnerAccount/arrowleft.png";
import ArrowRight from "../../../../assets/OwnerAccount/arrowright.png";
import ListBlue from "../../../../assets/OwnerAccount/listview-blue.png";
import ListWhite from "../../../../assets/OwnerAccount/listview-white.png";
import GridBlue from "../../../../assets/OwnerAccount/gridview-blue.png";
import GridWhite from "../../../../assets/OwnerAccount/gridview-white.png";
import BeneAccListView from "../BeneAccListView";

const BeneAccViewAll = () => {
  const location = useLocation();
  const approvalStatus = location.state ? location.state.approvalStatus : "";
  const [searchPanText, setSearchPanText] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(approvalStatus);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [searchUserText, setSearchUserText] = useState("");
  const [initCategory, setInitCategory] = useState("");
  const [startDateFilter, setStartDateFilter] = useState("");
  const [endDateFilter, setEndDateFilter] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [searchIfscText, setSearchIfscText] = useState("");
  const [isIfsc, setIsIfsc] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [searchAccountText, setSearchAccountText] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const dropdownRef = useRef(null);
  const [dropdownOptions, setDropdownOptions] = useState([50, 100, 150, 200]);
  const [selectedValue, setSelectedValue] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  const filterRef = useRef(null);
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRefAcc.current &&
        !searchBarRefAcc.current.contains(event.target) &&
        searchBarRefAcc2.current &&
        !searchBarRefAcc2.current.contains(event.target)
      ) {
        setShowSearchBar(false);
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const newTotalPages = Math.ceil(totalCount / pageSize);
    setTotalPages(newTotalPages);

    setCurrentPage((prevPage) => {
      if (newTotalPages === 0) {
        return 1;
      }
      if (prevPage > newTotalPages) {
        return newTotalPages;
      }
      return prevPage;
    });
  }, [totalCount, pageSize]);

  useEffect(() => {
    setDropdownOptions(
      [50, 100, 150, 200].filter((value) => value !== selectedValue)
    );
  }, [selectedValue]);

  const getIcon = () => {
    if (showSearchBar) {
      return searchUserText !== "" ? ArrowUp4 : arrowup;
    } else {
      return searchUserText !== "" ? ArrowDown8 : arrowdown;
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(Number(storedPage));
    setAccumulatedData([]);
    toggleDropdown();
  };

  const dispatch = useDispatch();

  const searchBarRefAcc = useRef(null);
  const searchBarRefAcc2 = useRef(null);

  const { beneViewAllAccData, storedPage, toggle } = useSelector(
    (state) => state.beneAllVerification
  );

  const handleFilterCategory = (selectedCategoryName) => {
    setCurrentCategory(selectedCategoryName);
  };

  const lastElementRef = useRef();

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
    setShowDatePicker(false);
  };

  useEffect(() => {
    if (approvalStatus && approvalStatus !== "all") {
      setIsStatusSelected(true);
    } else {
      setIsStatusSelected(false);
    }
    setCurrentStatus(approvalStatus);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        if (currentPage === 1) {
          setLoading(true);
        }

        let queryParams = {
          page_no: currentPage,
          page_size: pageSize,
          // search_string: searchUserText,
        };

        const addParamsBasedOnSelection = () => {
          if (currentStatus !== "all") {
            queryParams.approval_status = currentStatus;
          }
          if (currentCategory !== "") {
            queryParams.categoryName = currentCategory;
          }
          if (startDateFilter !== "" && endDateFilter !== "") {
            queryParams.start_date = startDateFilter;
            queryParams.end_date = endDateFilter;
          }
          if (accountNumber !== "") {
            queryParams.bank_account_no = accountNumber;
          }
          if (panNumber !== "") {
            queryParams.pan = panNumber;
          }
          if (searchUserText !== "") {
            queryParams.search_string = searchUserText;
          }
          if (isIfsc !== "") {
            queryParams.ifsc = isIfsc;
          }
        };
        addParamsBasedOnSelection();
        dispatch(checkUsertoken());
        const response = await dispatch(beneViewAllAcc(queryParams));
        const newData = response?.payload?.beneficiary_accounts || [];

        setAccumulatedData(newData);
        setTotalCount(response?.payload?.total_count || 0);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
    fetchData();
  }, [
    dispatch,
    currentPage,
    currentStatus,
    currentCategory,
    searchUserText,
    startDateFilter,
    endDateFilter,
    accountNumber,
    pageSize,
    panNumber,
    isIfsc,
  ]);
  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  const getAccStatus = (item) => {
    setCurrentStatus(item);
  };
  useEffect(() => {
    updateIsFilterApplied();
  }, [
    currentCategory,
    startDateFilter,
    endDateFilter,
    accountNumber,
    panNumber,
    isIfsc,
  ]);
  const updateIsFilterApplied = () => {
    setIsFilterApplied(
      !!currentCategory ||
        !!(startDateFilter && endDateFilter) ||
        !!accountNumber ||
        !!panNumber ||
        !!isIfsc
    );
  };

  const getArrowIcon = (isSidebarOpen, isFilterApplied) => {
    if (isSidebarOpen) {
      return isFilterApplied ? ArrowUp4 : arrowup;
    } else {
      return isFilterApplied ? ArrowDown8 : arrowdown;
    }
  };
  const handleFilterSticker = () => {
    setCurrentCategory("");
    setInitCategory("");
  };
  const handleFilterPanSticker = () => {
    setPanNumber("");
    setSearchPanText("");
  };
  const handleFilterAccountSticker = () => {
    setAccountNumber("");
    setSearchAccountText("");
    setIsIfsc("");
    setSearchIfscText("");
  };
  const handleFilterDateSticker = () => {
    setStartDate("");
    setStartDateFilter("");
    setEndDateFilter("");
    setEndDate("");
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const filteredData = accumulatedData;
  const handlePaste = (e) => {
    e.preventDefault();
    let pastedText = e.clipboardData.getData("text").trim();

    const isPhoneNumber = /^\+\d/.test(pastedText);

    if (isPhoneNumber) {
      pastedText = pastedText.replace(/^\+\d{1,3}|[^0-9]/g, "");
    } else {
      setSearchUserText(pastedText);
      setSearchTerm(pastedText);
    }

    setSearchUserText(pastedText);
    setSearchTerm(pastedText);
  };

  const handleAccPaste = (e) => {
    e.preventDefault();
    setAccountNumber(e.target.value);
  };
  const handleFilterDateRange = (startDate, endDate) => {
    setStartDateFilter(startDate);
    setEndDateFilter(endDate);
  };
  const handleAccountSearch = (selectedAccNumber) => {
    setAccountNumber(selectedAccNumber);
  };
  const handlePanSearch = (selectedPanNumber) => {
    setAccountNumber(selectedPanNumber);
  };
  const handleIfscSearch = (selectedIfscNumber) => {
    setIsIfsc(selectedIfscNumber);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  const [inputValue, setInputValue] = useState(1);

  useEffect(() => {
    setInputValue(String(currentPage).padStart(String(totalPages).length, "0"));
  }, [currentPage, totalPages]);

  useEffect(() => {
    setCurrentPage(1);
    setInputValue("1");
  }, []);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage + 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => {
        const newPage = prevPage - 1;
        setInputValue(String(newPage).padStart(String(totalPages).length, "0"));
        return newPage;
      });
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (value === "") {
        setInputValue("");
        setCurrentPage(1);
      } else if (numericValue > totalPages) {
        setInputValue(
          String(totalPages).padStart(String(totalPages).length, "0")
        );
        setCurrentPage(totalPages);
      } else if (numericValue < 1) {
        setInputValue(1);
        setCurrentPage(1);
      } else {
        setInputValue(value);
        handlePageChange(value);
      }
    }
  };

  const handlePageChange = useCallback(
    debounce((page) => {
      const pageNumber = Number(page);
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(1);
      }
    }, 1000),

    [totalPages]
  );

  return (
    <div
      id="beneScrollContainer"
      className={`bg-custom-appBack flex flex-col h-screen`}
    >
      {isPopoverOpen && <div className="acc_black_div"></div>}
      {isSidebarOpen && <div className="acc_black_div"></div>}

      <div
        className="bene_div_container"
        style={{
          height: showSearchBar ? "269px" : "177px",
          transition: showSearchBar ? "all 0.2s ease-in" : "all 0.2s ease-in",
        }}
      >
        <div
          className={"bene_div"}
          style={{ borderBottom: isSidebarOpen ? "1px solid #1D3A6D" : "" }}
        >
          <div className="acc_left">
            <AllStatusTypeButton
              getAccStatus={getAccStatus}
              selectedStatus={currentStatus}
              setPage={setCurrentPage}
              setIsPopoverOpen={setIsPopoverOpen}
              isPopoverOpen={isPopoverOpen}
              isStatusSelected={isStatusSelected}
              setIsStatusSelected={setIsStatusSelected}
              setShowSearchBar={setShowSearchBar}
            />
          </div>
          <div className="acc_right">
            <>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Search"
              >
                <div
                  ref={searchBarRefAcc}
                  className={`beneTxn_viewAll_search_div_acc ${
                    searchUserText !== "" ? "searched" : ""
                  } hover:bg-[#506994]`}
                  onClick={() => {
                    setShowSearchBar((prev) => !prev);
                    setIsSidebarOpen(false);
                  }}
                >
                  <img
                    className="bene_txn_search-image"
                    style={{ borderRadius: "0" }}
                    src={searchUserText ? search_blue : search_white}
                    alt=""
                  />

                  <img src={getIcon()} alt="" className="w-[13px] h-[13px]" />
                </div>
              </Tooltip>
              {showSearchBar && (
                <SearchBar
                  showSearchBar={showSearchBar}
                  setShowSearchBar={setShowSearchBar}
                  setCurrentPage={setCurrentPage}
                  searchUserText={searchUserText}
                  setSearchUserText={setSearchUserText}
                  handlePaste={handlePaste}
                  bgColor={"bg-custom-card"}
                  bgHover={"hover:bg-custom-cardHover"}
                  searchBarRefAcc={searchBarRefAcc2}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              )}
            </>
            <div
              ref={sidebarRef}
              className={`beneacc_filter_div ${
                currentCategory !== "" ||
                startDateFilter ||
                endDateFilter ||
                panNumber ||
                accountNumber ||
                isIfsc
                  ? "filtered"
                  : ""
              }`}
            >
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Filters"
              >
                <div
                  className="filter-container_acc"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSidebar();
                  }}
                >
                  <Box className="filter-box">
                    <img
                      src={isFilterApplied ? FilterBlue : Filter}
                      alt=""
                      className="filter-image"
                    />
                  </Box>
                  <Box className="filter-box">
                    <img
                      src={getArrowIcon(isSidebarOpen, isFilterApplied)}
                      alt=""
                      className="filter-image"
                    />
                  </Box>
                </div>
              </Tooltip>
              <div>
                <BeneAccFilterSidebar
                  isOpen={isSidebarOpen}
                  onClose={toggleSidebar}
                  categoryName={currentCategory}
                  sendCategory={handleFilterCategory}
                  setPage={setCurrentPage}
                  setIsSidebarOpen={setIsSidebarOpen}
                  toggleSidebar={toggleSidebar}
                  initCategory={initCategory}
                  setInitCategory={setInitCategory}
                  sendDateRange={handleFilterDateRange}
                  setEndDateFilter={setEndDateFilter}
                  setStartDateFilter={setStartDateFilter}
                  setAccountNumber={setAccountNumber} //this is for storing and displaying acc numb
                  accountNumber={accountNumber}
                  sendAccountNumber={handleAccountSearch}
                  sendPanNumber={handlePanSearch}
                  onPaste={handleAccPaste}
                  panNumber={panNumber}
                  setPanNumber={setPanNumber}
                  searchPanText={searchPanText}
                  setSearchPanText={setSearchPanText}
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  sendIfscNumber={handleIfscSearch}
                  isIfsc={isIfsc}
                  setIsIfsc={setIsIfsc}
                  searchIfscText={searchIfscText}
                  setSearchIfscText={setSearchIfscText}
                  searchAccountText={searchAccountText}
                  setSearchAccountText={setSearchAccountText}
                  showDatePicker={showDatePicker}
                  setShowDatePicker={setShowDatePicker}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
            <div className="bene-txn-viewall-page-select-div_acc">
              <div
                style={{
                  height: "34px",
                  width: "70px",
                  padding: "7px 0px 0px 8px",
                  color: "#DADADA",
                  fontSize: "12px",
                }}
              >
                Per page :
              </div>

              <div
                ref={dropdownRef}
                onClick={toggleDropdown}
                className="w-[60px] bene_viewall_data_dropdown"
                style={{
                  backgroundColor: isOpen ? "#667CA3" : "#F9F9F9",
                  height: isOpen ? "114px" : "34px",
                }}
              >
                <div
                  style={{
                    height: "17px",
                    width: "47px",
                    display: "flex",
                    backgroundColor: isOpen ? "#1D3A6D" : "#F9F9F9",
                    borderRadius: isOpen ? "2px" : "",
                    gap: "7px",
                    marginLeft: "8px",
                  }}
                >
                  <span
                    className="text-xs text-center ml-[4px]"
                    style={{ color: isOpen ? "#FFFFFF" : "#314D7E" }}
                  >
                    {selectedValue}
                  </span>
                  <img
                    src={isOpen ? allChecker : ArrowDown8}
                    alt={isOpen ? "Checkmark" : "Arrow Down"}
                    className="w-[10px] h-[11px] mt-[3px]"
                  />
                </div>
                {isOpen && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "#667CA3",
                      boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                      borderRadius: "5px",
                      marginTop: "20px",
                      zIndex: 1,
                    }}
                  >
                    {dropdownOptions.map((value) => (
                      <div
                        key={value}
                        onClick={() => handleSelect(value)}
                        className="bene_txn_dropdown_options"
                      >
                        <span>{value}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="bene-txn-viewall-pagination-containers_acc">
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Prev"
              >
                <div
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  style={{
                    height: "34px",
                    width: "32px",
                    padding: "10px 0px 0px 8px",
                    cursor: currentPage === 1 ? "default" : "pointer",
                  }}
                >
                  <img
                    src={ArrowLeft}
                    alt=""
                    style={{
                      opacity: currentPage === 1 ? 0.5 : 1,
                      cursor: currentPage === 1 ? "default" : "pointer",
                    }}
                    className="w-[11px] h-[14px]"
                  />
                </div>
              </Tooltip>
              <input
                type="text"
                value={inputValue}
                // onBlur={handleBlur}
                onChange={handleChange}
                className="bene_txn_viewall_pagination_input"
                maxLength={String(totalPages).length}
              />
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Next "
              >
                <div
                  onClick={nextPage}
                  disabled={currentPage === totalPages}
                  style={{
                    height: "34px",
                    width: "74px",
                    padding: "8px 0px 0px 10px",
                    display: "flex",
                    gap: "5px",
                    cursor: currentPage === totalPages ? "default" : "pointer",
                  }}
                >
                  <span
                    className="text-xs text-center text-[#DADADA]"
                    id="id-firstLetter"
                  >
                    of {totalPages > 0 ? totalPages : 1}
                  </span>
                  <img
                    src={ArrowRight}
                    style={{
                      opacity: currentPage >= totalPages ? 0.5 : 1,
                      cursor: currentPage >= totalPages ? "default" : "pointer",
                    }}
                    alt=""
                    className="w-[11px] h-[14px] pt-[1px]"
                  />
                </div>
              </Tooltip>
            </div>
            <div className="bene_txn_viewall_toggle_containers_acc">
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Grid"
              >
                <Box
                  onClick={() => {
                    // setSearchUserText("");
                    dispatch(setToggle(false));
                    setCurrentPage(1);
                    // setCurrentPageText("01");
                  }}
                  sx={{
                    backgroundColor: toggle ? "" : "#fff",
                  }}
                  className={`toggle-box`}
                >
                  <img
                    src={toggle ? GridWhite : GridBlue}
                    alt=""
                    className="toggle-images"
                  />
                </Box>
              </Tooltip>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="List"
              >
                <Box
                  onClick={() => {
                    // setSearchUserText("");
                    dispatch(setToggle(true));
                    // toast.info("Currently in development", toast_position);
                    setCurrentPage(1);
                    // setCurrentPageText("01");
                  }}
                  sx={{
                    backgroundColor: toggle ? "#fff" : "",
                  }}
                  className={`toggle-box `}
                >
                  <img
                    src={toggle ? ListBlue : ListWhite}
                    alt=""
                    className="toggle-images"
                  />
                </Box>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      {(currentCategory !== "" ||
        panNumber !== "" ||
        accountNumber !== "" ||
        isIfsc !== "" ||
        (startDateFilter !== "" && endDateFilter !== "")) &&
        showSearchBar === false && (
          <div className="bene_acc_filterdropdown-container-div">
            <div className="bene_acc_filterdropdown">
              <div
                style={{
                  display: "flex",
                  // width: "45%",
                }}
              >
                <div className="filter--div--left">
                  <p className="pr-2">Filtered by : </p>
                  {currentCategory && (
                    <div className="filter-body px-2 h-[16px]">
                      <div className="img-div">
                        <img src={rentalImg} alt={currentCategory} />
                      </div>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={currentCategory}
                      >
                        <p className="px-2 text-xs truncate w-[100px] h-[16px]">
                          {currentCategory}
                        </p>
                      </Tooltip>
                      <div
                        className="close-btn"
                        onClick={handleFilterSticker}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <img src={closeBtnImg} alt="close-btn" />
                      </div>
                    </div>
                  )}
                  {panNumber && (
                    <div className="filter-body px-2">
                      {/* <div className="img-div">
                    <img src={rentalImg} alt={currentCategory} />
                  </div> */}
                      <p className="px-2">{panNumber}</p>
                      <div
                        className="close-btn"
                        onClick={() => {
                          handleFilterPanSticker();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <img src={closeBtnImg} alt="close-btn" />
                      </div>
                    </div>
                  )}

                  {accountNumber && (
                    <div className="filter-body px-2">
                      {/* <div className="img-div">
                    <img src={rentalImg} alt={currentCategory} />
                  </div> */}
                      <p className="px-2">
                        {" "}
                        {accountNumber && formatAccountNumber(accountNumber)}
                      </p>
                      <div
                        className="close-btn"
                        onClick={() => {
                          handleFilterAccountSticker();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        <img src={closeBtnImg} alt="close-btn" />
                      </div>
                    </div>
                  )}
                  {startDateFilter && endDateFilter && (
                    <div className="filter-body px-2 w-[295px]">
                      {/* <div className="img-div">
                    <img src={rentalImg} alt={currentCategory} />
                  </div> */}
                      <div className="px-2">
                        {formatDate(new Date(startDateFilter))}
                      </div>
                      <img
                        src={arrowForward}
                        className="bene_acc_viewall_calendar_icon"
                      />
                      <div className="px-2">
                        {formatDate(new Date(endDateFilter))}
                      </div>
                      <div
                        className="close-btn"
                        onClick={() => {
                          handleFilterDateSticker();
                        }}
                        style={{ cursor: "pointer", marginRight: "0.5rem" }}
                      >
                        {" "}
                        <img src={closeBtnImg} alt="close-btn" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

      {!toggle ? (
        <div
          id="infinityScrollContainerBeneView"
          style={{
            marginTop:
              currentCategory !== "" ||
              panNumber !== "" ||
              accountNumber !== "" ||
              isIfsc !== "" ||
              (startDateFilter !== "" && endDateFilter !== "")
                ? "257px"
                : showSearchBar
                ? "267px"
                : "177px",
          }}
        >
          {loading ? (
            <div className="loader-container m-auto justify-center items-center flex ">
              <img
                src={dataLoading}
                alt=""
                style={{ width: "120px", height: "120px" }}
              />
            </div>
          ) : filteredData?.length > 0 ? (
            <div
              className={`scroll flex flex-col items-center justify-center w-full ${
                isSidebarOpen && "overflow-y-hidden"
              }`}
              ref={filterRef}
            >
              <BeneViewAllCard
                data={filteredData}
                lastElementRef={lastElementRef}
                isSidebarOpen={isSidebarOpen}
              />
            </div>
          ) : accumulatedData?.length === 0 &&
            filteredData?.length === 0 &&
            !loading ? (
            <div className="flex flex-col items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No accounts found"
                className="bene-acc-no-acc-image"
              />
              <p className="bene-acc-no-beneaccount-text">No accounts found.</p>
            </div>
          ) : (
            <div className="loader-container m-auto justify-center items-center flex ">
              <img
                src={dataLoading}
                alt=""
                style={{ width: "120px", height: "120px" }}
              />
            </div>
          )}

          {(currentStatus === "" || currentStatus === "all") && (
            <div ref={lastElementRef}></div>
          )}
        </div>
      ) : (
        <div
          id="infinityScrollContainerBeneView"
          style={{
            marginTop:
              currentCategory !== "" ||
              panNumber !== "" ||
              accountNumber !== "" ||
              isIfsc !== "" ||
              (startDateFilter !== "" && endDateFilter !== "")
                ? "258px"
                : showSearchBar
                ? "268px"
                : "177px",
          }}
        >
          {loading ? (
            <div className="loader-container m-auto justify-center items-center flex ">
              <img
                src={dataLoading}
                alt=""
                style={{ width: "120px", height: "120px" }}
              />
            </div>
          ) : filteredData?.length > 0 ? (
            <div
              className={`scroll flex flex-col items-center justify-center w-full ${
                isSidebarOpen && "overflow-y-hidden"
              }`}
              ref={filterRef}
            >
              <BeneAccListView
                data={accumulatedData}
                isSidebarOpen={isSidebarOpen}
                page={currentPage}
                isPopoverOpen={isPopoverOpen}
              />
            </div>
          ) : accumulatedData?.length === 0 &&
            filteredData?.length === 0 &&
            !loading ? (
            <div className="flex flex-col items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No accounts found"
                className="bene-acc-no-acc-image"
              />
              <p className="bene-acc-no-beneaccount-text">No accounts found.</p>
            </div>
          ) : (
            <div className="loader-container m-auto justify-center items-center flex ">
              <img
                src={dataLoading}
                alt=""
                style={{ width: "120px", height: "120px" }}
              />
            </div>
          )}

          {(currentStatus === "" || currentStatus === "all") && (
            <div ref={lastElementRef}></div>
          )}
        </div>
      )}
    </div>
  );
};

export default BeneAccViewAll;
