import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ApprovalCardsCarousel from "../../../components/ApprovalTxn/VendorApproval/ApprovalDetailsCarousel";
import "./index.css";
import SelectVendorTxns from "./SelectTxnList";
import { useDispatch, useSelector } from "react-redux";
import { getVendorPendingTxn } from "../../../redux/features/pendingTxn/pendingTxn";
import DeniedReason from "../../../components/ApprovalTxn/VendorApproval/DeniedReson/DeniedReason";
import SettlePayment from "../../SettlementRequired/SettlePayment";

const VendorApproval = () => {
  const dispatch = useDispatch();
  const [selectedTxnCarousel, setSelectedTxnCarousel] = useState({});
  const [showMultiple, setShowMultiple] = useState(false);
  const [deniedReason, setDeniedReason] = useState(false);
  const [deniedReasonData, setDeniedReasonData] = useState({});
  const [showSettlementComponent, setShowSettlementComponent] = useState(false);
  const [page, setPage] = useState(1);
  const VendorPendingApprovals = useSelector(
    (state) => state.pendingTxn.vendor
  );
  const [vendorData, setVendorData] = useState([]);

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    // deDup data
    let j = 0;
    appendedData?.forEach((item) => {
      if (
        !deDupData?.find(
          (deDupItem) => deDupItem.transaction.id === item.transaction.id
        )
      ) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    // sort data w.r.t txn_id
    appendedData.sort(
      (timestamp, nextTimestamp) =>
        Number(nextTimestamp.txn_id) - Number(timestamp.txn_id)
    );
    return appendedData;
  };

  useEffect(() => {
    setVendorData(
      dataAppend(vendorData, VendorPendingApprovals?.actionables || [])
    );
  }, [VendorPendingApprovals]);

  useEffect(() => {
    if (showMultiple) {
      setPage(1);
      setVendorData([]);
    }
    dispatch(getVendorPendingTxn({ page_no: page }));
  }, [showMultiple, page, showSettlementComponent]);

  return !showSettlementComponent ? (
    <>
      <Box className="selectTxnsHeadingBoxMobile">
        <Box sx={{ display: "flex" }}>
          <Button className="selectTxnSearch">
            <img
              className="selectTxnSearchImg"
              src={require("../../../assets/Approve transactions/Close.png")}
              alt=""
              srcSet=""
            />
          </Button>
          &nbsp; &nbsp;
          <Box className="selectTxnsHeading">Vendor Transactions</Box>
        </Box>
      </Box>
      {deniedReason && (
        <DeniedReason
          setDeniedReason={setDeniedReason}
          txnId={deniedReasonData.txnId}
          dispatchFn={deniedReasonData.dispatchFn}
          setShowStatusCard={deniedReasonData.setShowStatusCard}
        />
      )}
      {/* <div className="w-full pl-24 pt-32 pr-12 py-2 flex drop-shadow-xl h-[165px] justify-between bg-[#3e5555] items-center fixed bg-no-repeat bg-padding-box">

        </div> */}
      <Box className="vendorApproval">
        {showMultiple ? (
          <SelectVendorTxns data={vendorData} />
        ) : (
          <ApprovalCardsCarousel
            records={VendorPendingApprovals?.title.records}
            page={page}
            setPage={setPage}
            setSelectedTxnCarousel={setSelectedTxnCarousel}
            setShowSettlementComponent={setShowSettlementComponent}
            setDeniedReason={setDeniedReason}
            setDeniedReasonData={setDeniedReasonData}
            data={vendorData}
          />
        )}
        <Button
          onClick={() => setShowMultiple(!showMultiple)}
          className="showMultipleBT right-0 5.6xl:right-[825px]"
        >
          <img
            className="selectTxnSearchImg"
            src={require("../../../assets/Approve transactions/Task switcher.png")}
            alt=""
            srcSet=""
          />
        </Button>
      </Box>
      {/* <Button
          onClick={() => setShowMultiple(!showMultiple)}
          className="showMultipleBT"
        >
          <img
            className="selectTxnSearchImg"
            src={require("../../../assets/Approve transactions/Task switcher.png")}
            alt=""
            srcSet=""
          />
        </Button> */}
    </>
  ) : (
    <SettlePayment getSelected={() => selectedTxnCarousel} />
  );
};

export default VendorApproval;
