import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  beneCategories,
  beneWaitingApproved,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import arrow_down_red from "../../../assets/TransactionDashboardIcons/arrow_down_red.png";
import cancel from "../../../assets/TransactionDashboardIcons/cancel.png";
import search from "../../../assets/ViewAllTransactions/search-white.png";
import arrowforward from "../../../assets/Beneficiary Icons/arrowForward.png";
import "./index.css";
import DatePickerComponent from "../../_utils/Calendar";
import { tooltipClasses, Tooltip, Button } from "@mui/material";
import BeneTxnViewModal from "../BeneTxnViewModal";
import { checkUsertoken } from "../../../redux/features/login/login";
import { ViewAgenda } from "@mui/icons-material";
function BeneTxnFilterSidebar({
  isOpen,
  onClose,
  sendCategory,
  setPage,
  setIsSidebarOpen,
  sendDateRange,
  initCategory,
  setInitCategory,
  isSidebarOpen,
  BeneTxnData,
  AllBeneTxnData,
  sendPaymentMethod,
  isPaymentMethod,
  setIsPaymentMethod,
  setStartDateFilter,
  setEndDateFilter,
  multitxnApprovedata,
  // setTxnAmount,
  // txnAmount,

  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setPaymentMethod,
  paymentMethod,
  selectedBeneAccId,
  setSelectedBeneAccId,
  status,
  beneAccountId,
  setBeneAccountId,
  accountName,
  setAccountName,
  showDatePicker,
  setShowDatePicker,
  isFilterApplied,
  setIsFilterApplied,
  categoryDropdownRef,
  settlementDropdownRef,
  dateRef,
  dropdownOpen,
  setDropdownOpen,
  isDropdownOpen,
  setIsDropdownOpen,
  handleOpenViewModal,
  isViewModalOpen,
  viewModalRef,
}) {
  const [settlementSearch, setSettlementSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [sideDrawer, setSideDrawer] = useState(true);
  const [BeneTxnSideDrawerLoader, SetbeneTxnSideDrawerLoder] = useState(false);
  const [DrawerData, setDrawerData] = useState({});
  const [showDeclinedPopup, setshowDeclinedPopup] = useState(false);
  const [deniedTxnId, SetdeniedTxnId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [refreshId, SetrefreshId] = useState("");
  const [scrollAmount, setScrollAmount] = useState(0);

  const [activeSection, setActiveSection] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const categories = useSelector(
    (state) => state?.beneAllVerification.beneTransCategories?.categories
  );

  const dispatch = useDispatch();

  // const settlementDropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchRef = useRef(null);
  const yearDropdownRef = useRef(null);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const handleClick = (name) => {
    setInitCategory(name);
    setPage(1);
  };
  const handleSettlementClick = (settlement) => {
    setPaymentMethod(settlement);
    setPage(1);
  };
  const {
    viewAccountDetailData,
    viewAccountDetailLoading,
    achivedDataDetail,
    unachivedLoadingDetail,
    achivedLoadingDetail,
    unachivedDataDetail,
  } = useSelector((state) => state.verify);
  const { beneTxnAccountLoading, beneTxnAccountData } = useSelector(
    (state) => state?.beneTxnViewAccData
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
      if (
        categoryDropdownRef.current &&
        !categoryDropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
      if (
        settlementDropdownRef.current &&
        !settlementDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categoryDropdownRef, settlementDropdownRef, dateRef]);

  useEffect(() => {
    if (viewAccountDetailData) {
      setDrawerData(viewAccountDetailData?.beneficiary_accounts[0]);
    }
  }, [viewAccountDetailData]);
  const handleScroll = (scrollAmount) => {
    window.scrollBy({
      top: scrollAmount,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 87) {
        setScrollAmount(-30);
      } else if (e.keyCode === 83) {
        setScrollAmount(30);
      }
    };

    const handleKeyUp = () => {
      setScrollAmount(0);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    const scrollInterval = setInterval(() => {
      if (scrollAmount !== 0) {
        handleScroll(scrollAmount);
      }
    }, 10);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      clearInterval(scrollInterval);
    };
  }, [scrollAmount]);
  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
  };
  const clearFilter = () => {
    setInitCategory("");
    // setIsPaymentMethod("");
    setPaymentMethod("");
    setIsDropdownOpen(false);
    setCategorySearch("");
    setSettlementSearch("");
    // setIsSidebarOpen(false);
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    sendPaymentMethod("");
    sendCategory("");
    setStartDateFilter("");
    setEndDateFilter("");
    // setTxnAmount("");
    setSelectedBeneAccId("");
    setBeneAccountId("");
    setAccountName("");
    setIsFilterApplied(false);
  };
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };

  const filterCategory = () => {
    sendCategory(initCategory);
    setPage(1);
    if (startDate && endDate) {
      sendDateRange(convertDate(startDate), convertDate(endDate));
    }
    sendPaymentMethod(paymentMethod);
    setSelectedBeneAccId(beneAccountId);
    // setTxnAmount(sanitizedAmount);
    setShowDatePicker(false);
    setIsSidebarOpen(false);
  };
  // const handleTxnSearch = (text) => {
  //   const sanitizedText = text.replace(/[^0-9.]/g, "");
  //   const amount = parseFloat(sanitizedText);
  //   if (!isNaN(amount) && amount >= 0) {
  //     setSearchTxnAmount(sanitizedText);
  //   } else if (sanitizedText === "") {
  //     setSearchTxnAmount("");
  //   }
  // };

  const filteredSettlements = ["NEFT", "RTGS"].filter((settlement) =>
    settlement.toLowerCase().includes(settlementSearch.toLowerCase())
  );
  const handleCategorySearchChange = (event) => {
    setCategorySearch(event.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const additional_Data = beneTxnAccountData?.transaction[0]?.additional_data;

  const handleApproval = async (id) => {
    SetbeneTxnSideDrawerLoder(true);
    dispatch(checkUsertoken());
    const data = await dispatch(beneWaitingApproved(id));

    if (data) {
      const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
        (item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                // item.approval_status === "approved" ? "pending" : "approved",
                item.approval_status === "pending" ? "approved" : "pending",
              status: data?.payload.status,
            };
          }
          return item;
        }
      );
      setDrawerData(updatedData[0]);
    }

    setTimeout(() => {
      SetbeneTxnSideDrawerLoder(false);
      setRefreshData(true);
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        window.location.reload();
      }
    }, 2000);
  };

  useEffect(() => {
    setRefreshData(false);
  }, [refreshData, dispatch]);

  const handleDeclinedTxn = (id) => {
    setshowDeclinedPopup(true);
    SetdeniedTxnId(id);
  };
  const handleClosePopup = () => {
    setshowDeclinedPopup(false);
  };

  const handleSettlementSearchChange = (event) => {
    setSettlementSearch(event.target.value);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose(open);
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      <div
        // onClose={toggleSidebar(false)}
        className={`beneTxnviewallsidebar ${isOpen ? "open" : "closed"}`}
        style={{
          backgroundColor: isViewModalOpen ? "#000" : "#3a5686",
          pointerEvents: isViewModalOpen ? "none" : "",
        }}
      >
        <div
          className="beneTxnviewallFilterDiv pl-5"
          style={{
            visibility: isOpen ? "visible" : "hidden",
            transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
            opacity: isOpen ? "1" : "0",
          }}
        >
          <div className="beneTxnFilterSection1">
            <span className="text-[#F05A6A] font-normal">Filter </span>
            {/* <div className="relative w-[200px] mt-[10px]">
            <p
              style={{
                color: "#C9C9C9",
                display: "inline-block",
                marginTop: "5px",
                // width: "113px",
                height: "17px",
                fontSize: "12px",
              }}
            >
              Transaction amount
            </p>

            <input
              ref={searchInputRef}
              className="w-[200px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#CFCFCF] text-xs mt-[5px]"
              type="text"
              placeholder="Enter transaction amount"
              value={searchTxnAmount}
              onChange={(e) => handleTxnSearch(e.target.value)}
              onFocus={() => setActiveSection("txnamount")}
            />
            {/* {searchTxnAmount && (
                <img
                  src={closeIcon}
                  alt="Clear"
                  className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    setSearchTxnAmount("");
                    searchInputRef.current.focus();
                  }}
                />
              )}{" "}
              {!searchTxnAmount && (
                <img
                  src={search}
                  alt="search"
                  className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )} */}
            {/* </div> */}
            <div className="relative w-[200px] mt-[7px]">
              <span
                style={{
                  color: "#C9C9C9",
                  width: "35px",
                  height: "19px",
                  font: "normal normal 12px/19px Open Sans",
                }}
              >
                Beneficiary account
              </span>
              <div
                ref={viewModalRef}
                className="relative flex flex-col items-center rounded-xl h-[35px] pl-[12px] pr-[7px] py-2.5 bg-[#667CA3] w-[200px] mt-[5px]"
                // onClick={() => setDropdownOpen((prev) => !prev)}
              >
                <button
                  className="flex items-center justify-between border-transparent active:border-white w-full mt-[-1px]"
                  onClick={handleOpenViewModal}
                >
                  <span
                    className="bene_txn_filter_acc_selected text-xs truncate"
                    style={{ color: accountName ? "#f9f9f9" : "#CFCFCF" }}
                    // onClick={() => setDropdownOpen((prev) => !prev)}
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   setDropdownOpen((prev) => !prev);
                    // }}
                  >
                    {accountName === ""
                      ? "Select beneficiary account"
                      : accountName}
                  </span>
                  <img
                    className="txn_viewall_select_account h-[11px] w-[11px]"
                    src={arrow_down_red}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="beneTxnFilterSection2 pl-[30px] mt-[32px]">
            <div className="relative w-[200px]">
              <span
                style={{
                  color: "#C9C9C9",
                  width: "35px",
                  height: "19px",
                  font: "normal normal 12px/19px Open Sans",
                }}
              >
                Beneficiary category
              </span>

              <div
                className="bene_txn_category_select mt-[10px]"
                ref={categoryDropdownRef}
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                  setIsDropdownOpen(false);
                  setShowDatePicker(false); // Close date picker if it's open
                }}
                style={{
                  height: dropdownOpen ? "198px" : "35px",
                  outline: dropdownOpen ? "1px solid #E25869" : "",
                  transition: dropdownOpen ? "none" : "",
                  position: "relative",
                }}
              >
                <div className="bene_txn_category_select_select">
                  <div
                    className="txn_category_select_scroll_line"
                    style={{ display: dropdownOpen ? "block" : "none" }}
                  ></div>

                  <span
                    style={{
                      fontSize: "12px",
                      paddingLeft: dropdownOpen ? "9px" : "15px",
                      color: initCategory ? "#f9f9f9" : "#dadada",
                    }}
                  >
                    {initCategory || "Select a category"}
                  </span>
                  <img
                    src={arrow_down_red}
                    alt="down"
                    style={{
                      transform: dropdownOpen
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    }}
                  />
                </div>

                {dropdownOpen && (
                  <div className="txn_category_select_scroll">
                    <div className="bene_input_div_element">
                      <img
                        src={search}
                        alt="search"
                        className="search_owner_element"
                        onClick={(e) => {
                          e.stopPropagation();
                          searchRef.current.focus();
                        }}
                      />
                      <input
                        ref={searchRef}
                        type="text"
                        value={categorySearch}
                        onChange={handleCategorySearchChange}
                        placeholder="Search"
                        onClick={(e) => e.stopPropagation()}
                        className="placeholder-[#dadada] text-xs"
                      />
                      <img
                        src={cancel}
                        alt="cancel"
                        className="search_owner_element"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCategorySearch("");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>

                    {categorySearch === "" ? (
                      <div className="bene_txn_sort_by_category_scroll">
                        {categories.map((category) => (
                          <div key={category.id} className="category-wrapper">
                            <Tooltip
                              key={category.id}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={category.name}
                            >
                              <button
                                className="bene_txn_category_select_scroll_com"
                                onClick={() => {
                                  handleClick(category.name);
                                }}
                              >
                                {category.name}
                              </button>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="bene_txn_filter_by_category_scroll">
                        {filteredCategories.map((category) => (
                          <div key={category.id} className="category-wrapper">
                            <Tooltip
                              key={category.id}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={category.name}
                            >
                              <button
                                className="bene_txn_category_select_scroll_com"
                                onClick={() => {
                                  handleClick(category.name);
                                }}
                              >
                                {category.name}
                              </button>
                            </Tooltip>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!dropdownOpen && (
              <div className="relative w-[200px] mt-[61px]">
                <span
                  style={{
                    color: "#C9C9C9",
                    width: "35px",
                    height: "19px",
                    font: "normal normal 12px/19px Open Sans",
                  }}
                >
                  Payment method
                </span>

                <div
                  ref={settlementDropdownRef}
                  className="relative flex flex-col items-center rounded-xl h-auto mt-[5px] pl-[12px] pr-[5px] py-2.5 bg-[#667CA3]"
                  style={{
                    opacity: dropdownOpen ? 0 : 1,
                  }}
                >
                  <button
                    className="flex items-center justify-between border-transparent active:border-white w-full pr-[5px]"
                    onClick={() => setIsDropdownOpen((prev) => !prev)}
                  >
                    <span
                      className="text-xs truncate"
                      style={{ color: paymentMethod ? "#f9f9f9" : "#cfcfcf" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsDropdownOpen((prev) => !prev);
                      }}
                    >
                      {paymentMethod === ""
                        ? "Select payment method"
                        : paymentMethod}
                    </span>
                    <img
                      className="h-[11px] w-[11px]"
                      src={arrow_down_red}
                      alt=""
                    />
                  </button>

                  {isDropdownOpen && (
                    <div className="bg-[#667CA3] absolute top-12 flex flex-col items-start rounded-[10px] w-[200px] h-[82px]">
                      {["NEFT", "RTGS"].map((settlement) => (
                        <div
                          key={settlement}
                          className="flex w-[190px] h-[35px] hover:bg-[#506994] cursor-pointer rounded-[5px] border-l-transparent mt-[4px] ml-[4px]"
                          onClick={() => {
                            handleSettlementClick(settlement);
                            setIsDropdownOpen(false);
                          }}
                        >
                          <span className="pl-5 py-2.5 text-[#dadada] text-xs truncate">
                            {settlement}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="beneTxnFilterSection3 pl-[30px] mt-[42px]">
            <span
              style={{
                color: "#C9C9C9",
                width: "62px",
                height: "17px",
                font: "normal normal 12px/19px Open Sans",
                marginBottom: "5px",
              }}
            >
              Date range
            </span>
            <div
              className="bene_Txn_Calendar_main_div relative flex flex-col items-center bg-[#314D7E] cursor-pointer"
              // style={{ opacity: dropdownOpen ? 0 : 1 }}
              ref={dateRef}
              style={{
                width: "305px",
                height: showDatePicker ? "" : "35px",
                border: showDatePicker ? "1px solid #ff5860" : "none",
                // borderRadius: showDatePicker ? "10px" : "auto",
              }}
            >
              <div
                className="flex flex-row items-center justify-between rounded-[10px] bg-[#314D7E] cursor-pointer"
                style={{
                  width: "305px",
                  height: showDatePicker ? "" : "35px",
                  border: showDatePicker ? "none" : "1px solid #ff5860",
                }}
                onClick={() => {
                  setShowDatePicker(!showDatePicker);
                  setDropdownOpen(false);
                  setIsDropdownOpen(false);
                  setActiveSection("date");
                }}
                // style={{ opacity: dropdownOpen ? 0 : 1 }}
              >
                <div
                  className="beneTxn_date_heading_div cursor-pointer"
                  style={{
                    borderTopLeftRadius: showDatePicker ? "7px" : "none",
                    borderLeft: showDatePicker ? "1px solid #ff5860" : "none",
                  }}
                  // onClick={() => setShowDatePicker((prev) => !prev)}
                >
                  <div className="beneTxn_date_heading_left">
                    {startDate ? formatDate(startDate) : "Select from date"}
                  </div>
                </div>
                <img
                  src={arrowforward}
                  className="bene_txn_viewall_calendar_icon"
                />
                <div
                  className="beneTxn_date_select_div cursor-pointer"
                  style={{
                    borderTopRightRadius: showDatePicker ? "7px" : "none",
                    borderRight: showDatePicker ? "1px solid #ff5860" : "none",
                  }}
                  // onClick={() => setShowDatePicker((prev) => !prev)}
                >
                  <div className="beneTxn_date_heading_right">
                    {" "}
                    {endDate ? formatDate(endDate) : "Select to date"}
                  </div>
                </div>
              </div>
              {showDatePicker && (
                <DatePickerComponent
                  startDate={startDate}
                  endDate={endDate}
                  handleDateChange={handleDateChange}
                  handleCancel={handleCancel}
                  handleConfirm={handleConfirm}
                  showDatePicker={showDatePicker}
                  yearDropdownRef={yearDropdownRef}
                  dateRef={dateRef}
                  setShowDatePicker={setShowDatePicker}
                />
              )}
            </div>
          </div>
          <div className="beneTxnFilterbuttonContainer">
            <div
              className="acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
              onClick={() => {
                if (
                  initCategory ||
                  paymentMethod ||
                  startDate ||
                  endDate ||
                  beneAccountId
                ) {
                  clearFilter();
                }
              }}
              style={{
                cursor:
                  initCategory ||
                  paymentMethod ||
                  (startDate && endDate) ||
                  beneAccountId
                    ? "pointer"
                    : "default",
                // opacity: dropdownOpen ? 0 : 1,
              }}
            >
              Clear
            </div>
            <div
              className="acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px] hover:opacity-80"
              onClick={() => {
                if (
                  initCategory ||
                  paymentMethod ||
                  (startDate && endDate) ||
                  beneAccountId
                ) {
                  filterCategory();
                }
              }}
              style={{
                backgroundColor:
                  initCategory ||
                  paymentMethod ||
                  (startDate && endDate) ||
                  beneAccountId
                    ? "#F05A6A"
                    : "#727272",
                cursor:
                  initCategory ||
                  paymentMethod ||
                  (startDate && endDate) ||
                  beneAccountId
                    ? "pointer"
                    : "default",
                // opacity: dropdownOpen ? 0 : 1,
              }}
            >
              Apply
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BeneTxnFilterSidebar;
