import React, { useEffect } from "react";
import CreateIcon from "@mui/icons-material/Create";
import dorpdownLogo from "../../../assets/TxnDetails/asset-1.png";
import { useSelector } from "react-redux";
import { bankLogoMapper } from "../../../services/bankLogoMapper";

const SelectedVendorAccount = (props) => {
  const selectedAcc = useSelector((state) => state.bufferState.vendor);

  useEffect(() => {
    if (selectedAcc && selectedAcc.vendor_id) {
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [selectedAcc]);

  return (
    <div
      className="h-full bg-custom-blue
      flex flex-col drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl overflow-hidden"
    >
      <div
        className="h-1/2 bg-custom-card flex flex-col
        p-6 2xl:p-8 3xl:p-8 4xl:p-12 5xl:p-16 6xl:p-18
        drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl
        gap-6 xl:gap-8 2xl:gap-10 3xl:gap-12 4xl:gap-14 5xl:gap-16 overflow-hidden"
      >
        <div className="h-1/3 flex flex-row justify-between">
          <div
            className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
          >
            Vendor Details
          </div>
          {props.editable && (
            <div
              className="h-1/3 cursor-pointer flex flex-row justify-center gap-2"
              onClick={() => {
                props.handleRegister();
              }}
            >
              <div
                className="
                antaliased underline
                text-gray-200
                text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
              >
                Register a new vendor
              </div>
              <div className="flex flex-col justify-center">
                <img
                  className="w-1 h-1 xl:w-2 xl:h-2 3xl:w-3 3xl:h-3 4xl:w-4 4xl:h-4 5xl:w-5 5xl:h-5"
                  src={dorpdownLogo}
                  alt="down arrow logo"
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={`h-1/2
          ${props.editable ? "cursor-pointer" : ""}
          flex flex-row justify-center gap-2`}
          onClick={() => {
            props.handleOpen();
          }}
        >
          <div
            className="capitalize
          antaliased text-custom-orange font-semibold
          text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl truncate ..."
          >
            {props.selectedAcc?.name.toLowerCase()}
          </div>
          <div className="flex flex-row">
            {props.editable && (
              <span className="text-custom-orange self-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-10 5xl:h-10"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                  />
                </svg>
              </span>
            )}
          </div>
        </div>
      </div>
      <div
        className="h-1/2 flex flex-col divide-y divide-gray-300
        p-4 md:p-6 lg:p-8 xl:p-10 2xl:p-12 3xl:p-14 4xl:p-16 5xl:p-18
        shadow-xl rounded-large overflow-hidden gap-4"
      >
        <div className="w-full">
          <div
            className="text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
           text-white antialiased truncate ..."
          >
            {props.selectedAcc?.bank_acc_no}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            Account number
          </div>
        </div>
        <div className="py-4 flex flex-row justify-between">
          <div className="w-4/5">
            <div
              className="text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
           text-white antialiased truncate ..."
            >
              {props.selectedAcc?.ifsc_code}
            </div>
            <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
              IFSC Code
            </div>
          </div>
          <div
            className="w-1/5
            flex flex-col justify-center"
          >
            <div
              className="
              group-hover:bg-blue-100
              text-center
              bg-white rounded-custom2Xl"
            >
              <img
                className="p-2"
                src={require("../../../assets/Banks/" +
                  bankLogoMapper(props.selectedAcc?.bank_name) +
                  ".png")}
                alt="BankLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedVendorAccount;
