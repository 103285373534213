import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import alert from "../../../../../src/assets/Approve transactions/Alert.png";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import { approveVendorAccount } from "../../../../redux/features/accounts/Vendor/Approval/approveAccount";
import { verifyThisPAN } from "../../../../redux/features/verifyPAN/verifyPAN";
import LineWithImage from "../../../ApprovalTxn/VendorApproval/LineWithImage";
import verified from "../../../../../src/assets/Approve transactions/Verified.png";

const AccountApprovalDetailsCard = (props) => {
  const dispatch = useDispatch();
  const panVerifyData = useSelector((state) => state.verify.verifyPAN);

  useEffect(() => {
    dispatch(verifyThisPAN({ pan_number: props.data.pan_no }));
  }, [props.vendor_id]);

  return (
    <div className="w-full overflow-hidden xl:text-sm 2xl:text-lg flex rounded-4xl bg-[#3e5555]">
      <div className="w-1/2  rounded-4xl text-white text-base">
        <div className="p-4 flex flex-col justify-between">
          <div className="mt-[20%]">
            <div
              className="text-center font-normal xl:text-base 2xl:text-xl 4xl:text-4xl leading-normal tracking-normal bg-[#3e5555] w-4/6 mx-auto py-0 xl:px-1 px-2 -mt-4 mb-5"
              style={{
                color:
                  props.data?.account?.approval_status === "Y"
                    ? "#ffffff"
                    : "#ff9130",
              }}
            ></div>
            <div className="border-dashed border-2 border-[gray] pb-4">
              <div className="text-center font-normal text-base opacity-1 4xl:text-4xl leading-normal flex items-center justify-center gap-4 tracking-normal bg-[#3e5555] w-1/2 mx-auto py-0 xl:px-1 px-2 -mt-4 mb-5">
                <img
                  className="w-4 xl:bg-[repeating-linear-gradient(to right,transparent,transparent 10px,#CBCBCB 10px, #CBCBCB 20px)] bg-[repeating-linear-gradient(to right,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)]"
                  src={verified}
                  alt=""
                />
                {"Verified details"}
              </div>
              <div className="px-4 text-[#c9c9c9]">
                <div className="simpleBorderBox mb-3 pb-3 truncate ...">
                  <b className="text-[white] truncate ...">
                    {props.data.pan_no ? props.data.pan_no : "Not available"}
                  </b>{" "}
                  <br />
                  PAN
                </div>
                <div className="simpleBorderBox mb-3 pb-3 truncate ...">
                  <b className="text-[white] truncate ...">
                    {panVerifyData?.pan_details
                      ? panVerifyData.pan_details.full_name
                      : "Not available"}
                  </b>{" "}
                  <br />
                  Legal name
                </div>
                <div className="flex w-full justify-between items-center ">
                  {!props.data?.account?.legal_name.length >= 1 ? (
                    <>
                      <b>
                        We were unable to verify bank credentials, as{" "}
                        <span className="capitalize">
                          {props.data?.transaction?.initiator_name}
                        </span>{" "}
                        has chosen to skip verification.
                      </b>
                      <img
                        width={"101px"}
                        style={{
                          width: "8%",
                          height: "8%",
                          background: "inherit",
                        }}
                        src={alert} // conditional icon rendering
                        alt=""
                        srcSet=""
                      />
                    </>
                  ) : (
                    <div style={{ paddingTop: "10%" }}>
                      <b>{props.data.legal_name}</b> <br />
                      <span style={{ color: "#C9C9C9" }}>
                        Account holder name
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-[#C9C9C9] text-lg mt-[20%]">
            Requested by {props.data.created_by_name}
          </div>
        </div>
      </div>
      <div className="w-1/2  rounded-4xl bg-[#4A6565]">
        <div className="w-full flex justify-center px-4 text-xl  capitalize text-white my-6">
          <span className="max-w-4/5 self-center truncate ...">
            {props?.data?.name}{" "}
          </span>
        </div>
        <div
          style={{
            height: "3px",
            padding: 0,
            margin: "10px 22px",
            opacity: "0.3",
            background:
              "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
          }}
          className="dashedLine"
        ></div>

        <div className="p-4 text-base">
          <div className=" simpleBorderBox truncate ...">
            <b>{props.data.bank_acc_no}</b> <br />
            Account number
          </div>
          <div className="dashedBorderBox">
            <div className="paraNImg">
              <p className="w-1/2 truncate ...">
                <b className="text-white">{props.data.ifsc_code} </b>
                <br />
                <span style={{ color: "#c9c9c9" }}> IFSC code </span>
              </p>
              <img
                width={"20%"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.data.bank_name) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </div>
            <div
              style={{
                height: "3px",
                padding: 0,
                margin: "10px 0",
                opacity: "0.2",
                marginBottom: "27.21px",
                background:
                  "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
              }}
              className="dashedLine"
            ></div>
          </div>
          <div>
            <div className="simpleBorderBox truncate ...">
              <b className="text-[white] truncate ...">
                {props.data?.contact_no}
              </b>{" "}
              <br />
              Contact number
            </div>
            <div className="simpleBorderBox truncate ...">
              <b className="text-[white] truncate ...">
                {props.data?.email_id}
              </b>{" "}
              <br />
              Email
            </div>
            <div className="dashedBorderBox truncate ...">
              <b className="text-[white] truncate ...">
                {props.data?.gstin ? props.data?.gstin : "Not given"}
              </b>{" "}
              <br />
              GSTIN
            </div>
          </div>
        </div>
        <div className="flex text-white text-base justify-evenly m-auto my-3">
          <button
            className="bg-[#ea545b] w-2/6 font-normal px-2 py-3 rounded-4xl flex items-center justify-center"
            onClick={() => {
              props.setDeniedReasonData({
                vendor_id: [props?.data.vendor_id],
                dispatchFn: approveVendorAccount,
                setShowStatusCard: () =>
                  props.setShowStatusCard([
                    ...props.showStatusCard,
                    props.index,
                  ]),
              });
              props.setDeniedReason(true);
            }}
          >
            <img
              width={"19px"}
              src={require("../../../../assets/Approve transactions/Decline white.png")}
              alt=""
            />{" "}
            &nbsp;&nbsp; Decline
          </button>
          <button
            onClick={() => {
              dispatch(
                approveVendorAccount({
                  vendor_id: [props.data?.vendor_id],
                  status: "Y",
                })
              );
              props.setShowStatusCard([...props.showStatusCard, props.index]);
            }}
            className="bg-[#69b76f] w-2/6 font-normal px-2 py-3 rounded-4xl flex items-center justify-center "
          >
            <img
              width={"19px"}
              src={require("../../../../assets/Approve transactions/Approve white.png")}
              alt=""
            />
            &nbsp;&nbsp; Approve
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountApprovalDetailsCard;
