import React from "react";
import Datacard from "../../components/Datacard";
import { Link, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import RecentTransactions from "../../components/RecentTransactions";
import StartCreateYourTransaction from "../../components/StartCreateYourTransaction";
import { QuickActionsall } from "../../components/QuickActions";
import NoDataImg from "../../assets/TransactionDashboardIcons/Nothing.png";
import colorCode from "../../utils/colorCodeMapper";
import "./index.css";
import { fieldForceDashboardChecks } from "../../services/fieldForceDashboardChecks";
import { useDispatch, useSelector } from "react-redux";
import { approvalStatus } from "../../services/approvalStatus";
import { useEffect } from "react";
import { getFieldForceDashboard } from "../../redux/features/txnDashboard/txnDashboard";
import { getFieldForceViewAll } from "../../redux/features/txnViewAll/txnViewAll";
import { txnPathMapper } from "../../services/txnPathMapper";
import { txnPayloadMapper } from "../../services/txnPayloadMapper";
import { bankLogoMapper } from "../../services/bankLogoMapper";
import { getSettleVendorTxn } from "../../redux/features/settleTxn/settleTxn";

const FieldForceTransactions = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFieldForceDashboard());
    dispatch(getFieldForceViewAll());
    dispatch(getSettleVendorTxn({ clearState: true }));
  }, []);

  const services = useSelector((state) => state.refreshToken.services);
  const fieldForceDashboardData = useSelector(
    (state) => state.txnDashBoard.fieldForce
  );

  const loading = useSelector(
    (state) => state.txnDashBoard.isLoading
  );

  const fieldForceViewAllData = useSelector((state) => state.txnViewAll.fieldForce);

  const dataCardDetails = fieldForceDashboardData
    ? fieldForceDashboardChecks(fieldForceDashboardData, services)
    : null;

  const recentTransactionsData = fieldForceViewAllData
    ? fieldForceViewAllData["transactions"]?.slice(0, 12)
    : null;

  const getDataCardDetailsDiv = (dataCardDetails) => {
    return dataCardDetails ? (
      Object.entries(dataCardDetails).map(([key, value]) => {
        return (
          <Datacard
            key={key}
            color={colorCode[key]}
            text={key}
            number={value.records}
            path={txnPathMapper("ff_transactions")[key] + "?approval_status=" + txnPayloadMapper[key]?.approval_status}
          />
        );
      })
    ) : (
      <>
        <Box sx={{ opacity: "0.5" }}>
          <Datacard
            color={"yellow"}
            text={"Processing transactions"}
            number={0}
          />
        </Box>
        <Box sx={{ opacity: "0.5" }}>
          <Datacard
            color={"green"}
            text={"Successful transactions"}
            number={0}
          />
        </Box>
      </>
    );
  }

  const getRecentTransactionsDiv = (recentTransactionsData) => {
    return recentTransactionsData.map((transaction) => {
      return (
        <RecentTransactions
          img={
            transaction?.settlement_type === "HDFC NEFT/RTGS"
              ? bankLogoMapper(transaction?.owner_bank_name)
              : transaction?.settlement_type === "Instant Pay"
                ? "Instant Pay"
                : transaction?.settlement_type === "Registered Card"
                  ? transaction?.card_type || "Default Card"
                  : "Status - pending"
          }
          amount={transaction.amount}
          account={transaction.destination_owner_name}
          status={approvalStatus(transaction.approval_status)}
          transaction_id={transaction.txn_id}
          navigate_url={`/ff/txn/${transaction.txn_id}`}
        />
      );
    });
  }

  return (

    <div className="
      bg-custom-appBack min-h-screen max-h-full
      flex flex-col-reverse lg:flex-row vendor-txn overflow-y-scroll hide-scrollbar">
      <div className="md:bg-custom-appBackLight
          flex flex-wrap justify-center lg:justify-end  w-full
          lg:w-8/12">
        <div className="
          p-4 lg:mt-24
          md:pr-8 md:py-8
          lg:pr-10 lg:py-10
          xl:pr-12 xl:py-12
          4xl:pr-24 4xl:py-24
          w-full lg:w-11/12 flex flex-col
          gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-16 6xl:gap-24">

          <div className="
            grid gap-6 4xl:gap-16 grid-cols-2
            lg:grid-cols-4
            2xl:grid-cols-5
            4xl:grid-cols-6
            5xl:grid-cols-7
            justify-items-center">
            {getDataCardDetailsDiv(dataCardDetails)}
          </div>

          {
            loading && <div className="w-full m-auto justify-center items-center flex" >
              <img src={require("../../assets/Approve transactions/inputloder.gif")} alt="" />
            </div>
          }
          {!loading &&

            <div className="p-2 flex flex-col h-full">
              {recentTransactionsData?.length ? (
                <>
                  <div className="px-4 flex flex-row justify-between">
                    <span className="text-white
                    text-xl
                    3xl:text-2xl
                    4xl:text-3xl
                    5xl:text-4xl">
                      Recent
                    </span>
                    <span className="cursor-pointer
                    text-xl
                    3xl:text-2xl
                    4xl:text-3xl
                    5xl:text-4xl">
                      <Link to="/ff_transactions/view_all_transactions">
                        <p className="underline text-custom-orange">View All</p>
                      </Link>
                    </span>
                  </div>

                  <div className="pb-6 3xl:pb-8 5xl:pb-10
                  mt-5 w-full
                  gap-y-4 4xl:gap-y-8
                  grid grid-cols-1
                  md:grid-cols-2
                  3xl:grid-cols-3
                  md:gap-x-8
                  lg:gap-x-10
                  justify-items-center">
                    {getRecentTransactionsDiv(recentTransactionsData)}
                  </div>
                </>
              ) : (
                <>
                  <Typography className="pt-8" sx={{
                    color: "white", margin: "auto", textAlign: "center"
                  }}>
                    No transactions found <br />
                    All Field Force transactions will appear here.
                  </Typography>
                  <Box
                    sx={{
                      width: "50%",
                      margin: "auto",
                      marginTop: "5%",
                    }}
                  >
                    <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
                  </Box>
                </>
              )}
            </div>
          }
        </div>
        <div className="p-2 visible xl:p-0 xl:w-0 xl:h-0 xl:invisible">
          <QuickActionsall module="vendor" />
        </div>
      </div>
      <div className="
        h-full lg:fixed lg:right-0
          p-4
          md:pl-8 md:py-8
          lg:pl-10 lg:py-10
          xl:pl-12 xl:py-12
          4xl:pl-24 4xl:py-24
          flex flex-col bg-custom-appBack
          w-full lg:w-4/12 overflow-y-scroll overflow-x-hidden md:overflow-y-auto hide-scrollbar">
        <div className="
            mt-24 w-full lg:w-11/12
            flex flex-col
            gap-10 2xl:gap-12 3xl:gap-16 4xl:gap-20
            justify-items-center">
          <div className="">
            <StartCreateYourTransaction
              container="FF"
              create_path="/create_transaction/field_force_transaction"
              viewall_path="/create_transaction/field_force_transaction?flow=R"
            />
          </div>
          <div className="invisible xl:visible">
            <QuickActionsall />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FieldForceTransactions;


