import React, { useEffect } from "react";
import Datacard from "../../components/Datacard";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import RecentTransactions from "../../components/RecentTransactions";
import StartCreateYourTransaction from "../../components/StartCreateYourTransaction";
import { QuickActionsall } from "../../components/QuickActions";
import NoDataImg from "../../assets/TransactionDashboardIcons/Nothing.png";
import colorCode from "../../utils/colorCodeMapper";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { reimbursementDashboardChecks } from "../../services/reimbursementDashboardChecks";
import { approvalStatus } from "../../services/approvalStatus";
import { getReimbursementDashboard } from "../../redux/features/txnDashboard/txnDashboard";
import { getReimbursementViewAll } from "../../redux/features/txnViewAll/txnViewAll";
import { ReimbursementMapper } from "../../services/txnPathMapper";

const ReimbursementTransactions = ({ data }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReimbursementDashboard());
    dispatch(getReimbursementViewAll());
  }, [dispatch]);
  const services = useSelector((state) => state.refreshToken.services);
  const reimbursementDashboardData = useSelector(
    (state) => state.txnDashBoard.reimbursement
  );

  const loading = useSelector(
    (state) => state.txnDashBoard.isLoading
  );

  const reimbursementViewAllData = useSelector(
    (state) => state.txnViewAll.reimbursement
  );

  const dataCardDetails = reimbursementDashboardData
    ? reimbursementDashboardChecks(reimbursementDashboardData, services)
    : null;

  const recentTransactionsData = reimbursementViewAllData
    ? reimbursementViewAllData["transactions"].slice(0, 10)
    : null;

  return (
    <Box sx={{ display: "flex", marginTop: "100px" }}>
      <Box className="leftBody">
        <Box className="createTransaction">
          <StartCreateYourTransaction
            create_path="/create_transaction/reimbursement"
            viewall_path="/reimbursement_transactions/view_all_transactions"
          />
        </Box>
        <Box className="dataCards">
          {dataCardDetails ? (
            Object.entries(dataCardDetails).map(([key, value]) => {
              return (
                <Datacard
                  key={key}
                  color={colorCode[key]}
                  text={key}
                  number={value.records}
                  path={ReimbursementMapper[key]}
                />
              );
            })
          ) : (
            <>
              {" "}
              <Box sx={{ opacity: "0.5" }}>
                <Datacard
                  color={"yellow"}
                  text={"Processing transactions"}
                  number={0}
                />
              </Box>
              <Box sx={{ opacity: "0.5" }}>
                <Datacard
                  color={"green"}
                  text={"Successful transactions"}
                  number={0}
                />
              </Box>
            </>
          )}
        </Box>
        {
          loading && <div className="w-full m-auto justify-center items-center flex" >
            <img src={require("../../assets/Approve transactions/inputloder.gif")} alt="" />
          </div>
        }
        {!loading &&
          <Box>
            {recentTransactionsData ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 2%",
                    marginTop: "10%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "left",
                      font: "normal normal bold 20px/27px Open Sans",
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      opacity: 1,
                    }}
                  >
                    Recent
                  </Typography>
                  <Link
                    to="/reimbursement_transactions/view_all_transactions"
                    style={{
                      textAlign: "right",
                      font: "normal normal bold 20px/27px Open Sans",
                      letterSpacing: "0px",
                      color: "#FF7F33",
                      opacity: 1,
                      textDecoration: "underline",
                    }}
                    className="viewAllLink"
                  >
                    View All
                  </Link>
                </Box>
                <Box className="recentTransactions">
                  {recentTransactionsData.map((transaction) => {
                    return (
                      <Link
                        to={`/reimbursement/txn/${transaction.txn_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <RecentTransactions
                          img={""}
                          amount={transaction.amount}
                          account={transaction.txn_initiator_name}
                          status={approvalStatus(transaction.approval_status)}
                        />
                      </Link>
                    );
                  })}
                </Box>
              </>
            ) : (
              <>
                <Typography className="pt-8" sx={{
                  color: "white", margin: "auto", textAlign: "center"
                }}>
                  No transactions found <br />
                  All Reimbursement transactions will appear here.
                </Typography>
                <Box
                  sx={{
                    width: "50%",
                    margin: "auto",
                    marginTop: "5%",
                  }}
                >
                  <img width={"100%"} src={NoDataImg} alt="NoDataImg" />
                </Box>
              </>
            )}
          </Box>
        }
        <Box className="createTransaction">
          <QuickActionsall />
        </Box>
      </Box>
      <Box
        className="sideMenu"
        sx={{
          width: "25%",
          backgroundColor: "#1D3A6D",
          minHeight: "100vh",
          padding: "3% 5%",
        }}
      >
        <Grid spacing={5} container>
          <Grid item>
            <StartCreateYourTransaction
              create_path="/create_transaction/reimbursement"
              viewall_path="/reimbursement_transactions/view_all_transactions"
            />
          </Grid>
          <Grid item>
            <QuickActionsall />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReimbursementTransactions;
