import React, { useEffect, useState } from "react";
import FilterDrawer from "../../../components/ViewAllTransactions/FilterDrawer";
import AccountStatusTypePopOver from "../../../components/Accounts/Common/AccountStatusTypePopOver";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import SearchBar from "../../../components/_utils/search/SearchBar";
import AccountViewAllCountDiv from "../../../components/Accounts/Common/ViewAllCountDiv";
import { accountViewAllDetails } from "../../../redux/features/accounts/viewAll";
import StrategyContext from "./strategyContext";

const AccountViewAll = () => {
  const { account_type, approval_status } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewAllData = useSelector((state) => state.accountViewAll.records);
  const [searchString, setSearchString] = useState();
  const count = useSelector((state) => state.accountViewAll.total_count);

  const [searchParams] = useSearchParams();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const loading = useSelector((state) => state.txnViewAll.isLoading);
  const [accountStatus, setAccountStatus] = useState(
    searchParams.get("approval_status") || approval_status
  );
  const toggleShowSearchBar = () => setShowSearchBar(!showSearchBar);
  const [data, setData] = useState([]);

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    let j = 0;
    appendedData?.forEach((item) => {
      if (
        !deDupData?.find((deDupItem) => {
          let dupItem, actualItem;
          switch (account_type) {
            case "vendor":
              dupItem = deDupItem.vendor_id;
              actualItem = item.vendor_id;
              break;
            case "field_force":
              dupItem = deDupItem.account_id;
              actualItem = item.account_id;
              break;
            case "client":
              dupItem = deDupItem.client_id;
              actualItem = item.client_id;
              break;
            case "owner":
              dupItem = deDupItem.owner_id;
              actualItem = item.owner_id;
              break;
            case "cards":
              dupItem = deDupItem.card_id;
              actualItem = item.card_id;
              break;
          }

          return dupItem === actualItem;
        })
      ) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    return appendedData;
  };

  const handleSearch = (search_string) => {
    if (search_string !== searchString) {
      setData([]);
      setSearchString(search_string);
    }
  };

  useEffect(() => {
    setAccountStatus(approval_status);
  }, [searchParams, approval_status]);

  const getData = () => {
    let account_id = searchParams.get("account_id");
    dispatch(
      accountViewAllDetails({
        account_type: account_type,
        account_id: account_id,
        approval_status: accountStatus || approval_status,
        status: accountStatus,
        page_no: page,
        search_string: searchString,
      })
    );
  };

  const handleScroll = debounce(() => {
    const container = document.getElementById("infinityScrollContainer");
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight <=
      1
    ) {
      if (!loading && hasMore) {
        setPage(page + 1);
      }
    }
  }, 100);

  useEffect(() => {
    let container = document.getElementById("infinityScrollContainer");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (viewAllData === null) return;

    if (Object.keys(viewAllData).length === 0) {
      setHasMore(false);
      return;
    }
    setHasMore(true);
    setData(dataAppend(data, viewAllData));

    const container = document.getElementById("infinityScrollContainer");
    if (container.scrollHeight <= container.clientHeight) setPage(page + 1);
  }, [viewAllData]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    navigate(`/accounts/${account_type}/view/${accountStatus || ""}`);
    setData([]);
    if (page !== 0) setPage(0);
    else {
      getData();
    }
  }, [searchString, accountStatus]);

  const humanizeSnakeCase = (snakeCaseString) => {
    const words = snakeCaseString.split("_");
    const humanizedString = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return humanizedString;
  };

  return (
    <div className="bg-custom-appBack flex flex-col h-screen">
      <div className="bg-custom-appBackLight drop-shadow-2xl border-b-1 border-custom-card w-full flex flex-col justify-center h-60">
        <div className="w-10/12 h-1/2 mt-16 md:mt-24 self-center  flex flex-row justify-between">
          <div className="hidden md:block w-1/4 self-center">
            <AccountViewAllCountDiv count={count} />
          </div>
          <div className="block md:hidden w-fit self-center">
            <div className="w-full h-full rounded-lg p-2 flex flex-row gap-2">
              <div
                className="cursor-pointer flex px-3 py-2 rounded-xl bg-custom-card drop-shadow-md hover:bg-custom-card/80"
                onClick={() => {
                  navigate(`/accounts/${account_type}/dashboard`);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="self-center text-white w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="w-full flex flex-col justify-between">
                <span className="text-lg text-white capitalize text-semibold">
                  {humanizeSnakeCase(account_type)}
                </span>
                <span className="text-sm text-gray-300 capitalize text-semibold">
                  Account
                </span>
              </div>
            </div>
          </div>
          <div className="invisible">
            <p>Filtered by :</p>
          </div>
          <div className="hidden sm:flex  flex-col justify-center">
            <FilterDrawer />
          </div>
        </div>
      </div>

      <div
        id="infinityScrollContainer"
        className="bg-custom-appBack py-4 3xl:py-6 5xl:py-8 w-full flex flex-col h-4/5 overflow-y-auto overflow-x-hidden viewAllvendor-txn"
      >
        <div className="self-center h-5/6 w-10/12 flex flex-col gap-4">
          <div className="h-[10%] flex flex-row justify-between">
            <AccountStatusTypePopOver
              accountType={account_type}
              setAccountStatus={setAccountStatus}
              accountStatus={accountStatus}
              data={data}
            />
            <div className="w-[40%]">
              <SearchBar
                handleSearch={handleSearch}
                showSearchBar={showSearchBar}
                toggleShowSearchBar={toggleShowSearchBar}
                bgColor={"bg-custom-card"}
                bgHover={"hover:bg-custom-cardHover"}
              />
            </div>
          </div>

          <div className="h-[85%]">
            <div className="pb-6 3xl:pb-8 5xl:pb-10">
              <StrategyContext accountType={account_type} viewAllData={data} />
            </div>
            {loading && (
              <div className="w-full m-auto justify-center items-center flex">
                <img
                  src={require("../../../assets/Approve transactions/inputloder.gif")}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountViewAll;
