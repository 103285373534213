import React, { useEffect } from "react";
import { Input, InputAdornment, Box } from "@mui/material";
import logo from "../../../../assets/TransactionDashboardIcons/InstantPay card icon.png";

import formatCurrency from "../../../../utils/CurrencyFormater";
import NextDate from "../../../NextDate";
import { toast } from "react-toastify";
import Validation from "../../../../utils/validation";
import { getInstantPayBalance } from "../../../../redux/features/instantPayBalance/instantPayBalance";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { refillInstantPay } from "../../../../redux/features/createTxn/createTxn";

const EnterDetailsInstantPayTxn = (props) => {
  const allowedValue = (value) => {
    return Validation.allowedAmount(value);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInstantPayBalance());
  }, [dispatch]);
  const balance = useSelector((state) => state.instantPayBalance.balance);
  const balance_details = balance?.balance_details[0];

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "amount") {
      if (allowedValue(value) || value.length === 0) {
        const newObj = { ...props.createTxnData, [name]: value };
        props.setCreateTxnData(newObj);
      } else {
        e.target.value = props.createTxnData.amount;
      }
      let floatAmount = value?.length > 0 ? parseFloat(value).toFixed(2) : 0.0;
      if (
        floatAmount >= balance_details?.minimum_txn_amount &&
        floatAmount <= 999999999.99
      ) {
        props.enableNextButton(true);
      } else {
        props.enableNextButton(false);
        toast.error(
          `Amount must be >= ${balance_details?.minimum_txn_amount} AND <= 999999999.99`,
          {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } else {
      const newObj = { ...props.createTxnData, [name]: value };
      props.setCreateTxnData(newObj);
    }
  };

  return (
    <div
      className="h-fit flex flex-col justify-between bg-custom-card drop-shadow-2xl
      rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl
      overflow-hidden"
    >
      <div
        className="flex flex-col bg-custom-green justify-center rounded-b-large
        p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12 drop-shadow-xl"
      >
        <div className="flex flex-row justify-center text-white text-lg 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
          <Input
            name="amount"
            disableunderline
            autoFocus
            placeholder="Enter amount"
            className="enterAmountInput p-4 xl:p-8 4xl:p-10 5xl:p-12 6xl:p-14"
            id="standard-adornment-amount"
            autoComplete="off"
            readOnly={props.disabledToggle}
            onChange={handleChange}
            value={props.createTxnData.amount}
            startAdornment={
              <InputAdornment position="start">
                <p
                  style={{
                    color: "white",
                    paddingRight: "10px",
                    textDecoration: "none",
                    underline: "none",
                  }}
                >
                  ₹
                </p>
              </InputAdornment>
            }
          />
        </div>
        <div
          className="p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12
          self-center flex flex-col justify-center text-white"
        >
          <div
            className="self-center font-light
            antaliased text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl"
            style={{
              fontWeight: "100",
              fontFamily: "Open Sans",
            }}
          >
            <NextDate />
          </div>
          <div className="font-thin antaliased text-sm 3xl:text-base 5xl:text-xl 6xl:text-2xl">
            Estimated transaction completion on
          </div>
        </div>
      </div>
      <Box
        className="
        p-6 2xl:p-8 3xl:p-8 4xl:p-12 5xl:p-16 6xl:p-18 flex flex-col h-full justify-evenly gap-6 xl:gap-8 2xl:gap-10 3xl:gap-12 4xl:gap-14 5xl:gap-16"
      >
        <div className="flex justify-between items-center">
          <div clasName="flex flex-col gap-4">
            <div
              className="antaliased text-white text-base 3xl:text-lg
          4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-thin"
            >
              Current balance
            </div>
            <div
              className="self-center font-light
            antaliased text-2xl 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl text-[#4DE659]"
            >
              ₹ {formatCurrency(balance_details?.current_balance)}
            </div>
          </div>
          <div className="px-4 py-1 bg-white rounded-xl shadow-lg ">
            <img
              className="w-8 xl:w-10 3xl:w-12 5xl:w-14"
              alt="logo"
              src={logo}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-left">
            <p
              className="self-center font-light
            antaliased text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-white"
            >
              {balance_details?.suggested_balance == 0.0
                ? "N/A"
                : formatCurrency(balance_details?.suggested_balance)}
            </p>
            <br />
            <div
              className="antaliased text-white text-base 3xl:text-lg
              4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-thin opacity-75"
            >
              Suggested refill amount
            </div>
          </div>
          <div className="text-right">
            <p
              className="self-center font-light
            antaliased text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-[#FFD528]"
            >
              ₹ {formatCurrency(balance_details?.pending_balance)}*
            </p>
            <br />
            <div
              className="antaliased text-white text-base 3xl:text-lg
              4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-thin opacity-75"
            >
              Processing amount
            </div>
            <div
              className="antaliased text-white text-base 3xl:text-lg
              4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-thin opacity-75"
            >
              * Gateway charges may apply.
            </div>
            <br />
          </div>
        </div>
      </Box>
      <button
        disabled={props.createTxnData.amount > 0 ? false : true}
        className={`cursor-pointer p-3 2xl:p-4 3xl:p-4 4xl:p-6 5xl:p-8 6xl:p-9 rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl ${props.createTxnData.amount === null ||
            props.createTxnData.amount === ""
            ? "bg-[#666666]"
            : "bg-white"
          }`}
        onClick={() => {
          dispatch(refillInstantPay({ amount: props.createTxnData.amount }));
        }}
      >
        <span
          className={`
        text-lg cursor-pointer
        3xl:text-xl 4xl:text-2xl 5xl:text-3xl
        6xl:text-4xl antialiased
        ${props.createTxnData.amount === null ||
              props.createTxnData.amount === ""
              ? "text-white"
              : "text-black"
            }
        `}
        >
          Next
        </span>
      </button>
    </div>
  );
};

export default EnterDetailsInstantPayTxn;
