import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "./index.css";
import EnterDetailsClientTxn from "../../../components/CreateTxn/CreateClientTxn/EnterDetails";
import { useSelector, useDispatch } from "react-redux";
import { setBufferState } from "../../../redux/features/bufferState/bufferState";
import { createVendorAccount } from "../../../redux/features/createVendorAcc/createVendorAcc";
import { resetVerification } from "../../../redux/features/verifyPAN/verifyPAN";
import RegisterdFilledForm from "../../../components/CreateVendorTxnForm/RegisterdFilledForm";
import CustomizedStepper from "../../../components/CreateTxn/CustomizedStepper";
import SelectedVendorAccount from "../../../components/CreateVendorTxnForm/SelectedVendorAccount";
import {
  createClientTxn,
  createVendorTxn,
} from "../../../redux/features/createTxn/createTxn";
import { toast } from "react-toastify";
import ClientDetailsForm from "../../../components/CreateTxn/CreateClientTxn/ClientDetailsForm";
import ClientCreateStatus from "./ClientCreateStatus";
import { createClientAccount } from "../../../redux/features/createClientAcc/createClientAcc";
import { useSearchParams } from "react-router-dom";
import { accountViewAllDetails } from "../../../redux/features/accounts/viewAll";

const CreateClientTxn = () => {
  const services = useSelector((state) => state.refreshToken.services);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const client_id = searchParams.get("client_id");
  const selectedAcc = useSelector((state) => state.bufferState.clientAcc);
  const clientPrimaryAcc = useSelector(
    (state) => state.bufferState.clientPrimaryAcc
  );
  const [showStatus, setShowStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdClientTxn = useSelector((state) => state.createTxn.client);
  const createdVendorTxnLoading = useSelector(
    (state) => state.createTxn.isLoading
  );
  const [checkRes, setCheckRes] = useState(false);
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const newClientAccRes = useSelector((state) => state.createAcc.client);
  const [createTxnData, setCreateTxnData] = useState({
    amount: null,
    owner_id: null,
    distribution_remark: null,
    invoice_no: null,
    client_id: null,
  });
  const [registerNewClientData, setRegisterNewClientData] = useState({
    contact_no: "",
    email_id: "",
    name: "",
  });

  useEffect(() => {
    if (checkRes && createdClientTxn.errors) {
      toast.error(Object.values(createdClientTxn.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && createdClientTxn?.status) setShowStatus(true);
    setCheckRes(true);
  }, [createdClientTxn]);

  useEffect(() => {
    if (checkRes && newClientAccRes.errors) {
      setActiveStep(1);
      toast.error(Object.values(newClientAccRes.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && newClientAccRes?.status) {
      dispatch(
        setBufferState({
          clientAcc: newClientAccRes?.clients[0],
          clientPrimaryAcc: clientPrimaryAcc,
        })
      );
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      enableNextButton(true);
    }
    setCheckRes(true);
  }, [newClientAccRes]);

  const steps = [
    "Enter amount and additional details",
    "Enter client details",
    "Confirm and create transaction",
  ];

  const handleNext = () => {
    if (activeStep === 2) {
      dispatch(
        createClientTxn({ ...createTxnData, client_id: selectedAcc.client_id })
      );
    }
    if (activeStep === 0 && createTxnData.amount) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      enableNextButton(false);
    }
    if (activeStep === 1 && !selectedAcc) {
      dispatch(createClientAccount(registerNewClientData));
    }
    if (activeStep === 1 && selectedAcc) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      enableNextButton(true);
    }
  };

  const handleBack = () => {
    if (activeStep === 1 && createTxnData.amount) {
      enableNextButton(true);
    }
    if (activeStep === 2 && selectedAcc) {
      enableNextButton(true);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (createTxnData.amount && activeStep === 0) {
      enableNextButton(true);
    } else enableNextButton(false);
    setCreateTxnData({
      ...createTxnData,
      owner_id: clientPrimaryAcc?.owner_id,
    });
  }, [clientPrimaryAcc, createTxnData.amount]);

  useEffect(() => {
    if (
      (selectedAcc || registerNewClientData.contact_no.length === 10) &&
      activeStep === 1
    ) {
      enableNextButton(true);
    }
    if (
      !(selectedAcc || registerNewClientData.contact_no.length === 10) &&
      activeStep === 1
    )
      enableNextButton(false);
    setCreateTxnData({
      ...createTxnData,
      client_id:
        selectedAcc?.client_id ||
        registerNewClientData.contact_no.length === 10,
    });
  }, [selectedAcc, registerNewClientData.contact_no, activeStep]);

  useEffect(() => {
    return () => {
      dispatch(setBufferState({}));
    };
  }, []);

  const client = useSelector((state) => {
    if (!client_id) return null;
    if (!state.accountViewAll.records) {
      dispatch(
        accountViewAllDetails({
          account_type: "client",
          account_id: client_id,
        })
      );
    }
    return state.accountViewAll.records
      ? state.accountViewAll.records[0]
      : null;
  });

  useEffect(() => {
    if (!client_id) return;
    dispatch(
      accountViewAllDetails({
        account_type: "client",
        account_id: client_id,
      })
    );
  }, [client_id]);

  useEffect(() => {
    if (client) {
      dispatch(setBufferState({ clientAcc: client }));
    }
  }, [client]);

  return (
    <Box
      className="bg-custom-appBack
      flex flex-row justify-center overflow-y-auto overflow-x-hidden w-full hide-scrollbar vendor-txn
      min-h-screen"
    >
      {showStatus && !createdVendorTxnLoading ? (
        <Box sx={{ marginTop: "-120px" }}>
          <ClientCreateStatus
            createdClientTxn={createdClientTxn}
            selectedAcc={selectedAcc}
            createTxnData={createTxnData}
          />
        </Box>
      ) : (
        <div
          className="self-center
          h-screen w-full
          xl:w-10/12"
        >
          <div
            className="mt-24 h-fit
            flex flex-col gap-2 3xl:gap-4 5xl:gap-6
            py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20"
          >
            <div
              className="h-[15%]
              flex flex-row justify-center"
            >
              <CustomizedStepper
                nextButtonEnabled={nextButtonEnabled}
                activeStep={activeStep}
                steps={steps}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            </div>
            <div className="h-4/5 flex flex-row justify-center pb-24">
              <div className="w-10/12 h-full">
                {activeStep === 0 && (
                  <div className="p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <EnterDetailsClientTxn
                        setCreateTxnData={setCreateTxnData}
                        createTxnData={createTxnData}
                        enableNextButton={enableNextButton}
                      />
                    </div>
                  </div>
                )}
                {activeStep === 1 && (
                  <div className="flex flex-row justify-end p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <ClientDetailsForm
                        setRegisterNewClientData={setRegisterNewClientData}
                        registerNewClientData={registerNewClientData}
                        enableNextButton={enableNextButton}
                        editable={true}
                      />
                    </div>
                  </div>
                )}
                {activeStep === 2 && (
                  <div
                    className="flex flex-row justify-between
                    p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12"
                  >
                    <div className="w-[49%] h-fit">
                      <EnterDetailsClientTxn
                        disabledToggle={createTxnData.amount ? true : false}
                        setCreateTxnData={setCreateTxnData}
                        createTxnData={createTxnData}
                        enableNextButton={enableNextButton}
                        filled="true"
                      />
                    </div>
                    <div className="w-[49%] h-fit">
                      <ClientDetailsForm
                        setRegisterNewClientData={setRegisterNewClientData}
                        registerNewClientData={registerNewClientData}
                        enableNextButton={enableNextButton}
                        editable={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default CreateClientTxn;
