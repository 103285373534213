import React, { useEffect } from "react";
import { Box } from "@mui/material";
import formatCurrency from "../../../utils/CurrencyFormater";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import trimSentence from "../../../utils/trimSentence";
import { approvalStatus, approvalStatusRGBA } from "../../../services/approvalStatus";
import { VendorTxnTextMapper } from "../../../services/VendorTxnTextMapper";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import CardLogo from "../../_utils/logo/CardLogo";

const DataCardClientViewAll = (props) => {
  const amountProp = props.container === "ClientRefunds" ? formatCurrency(props.txnData.refund_amount) : formatCurrency(props.txnData.amount);
  const propStatus = props.container === "ClientRefunds" ? approvalStatus("Refund") : approvalStatus(props.txnData.approval_status);
  const cardStatusRGBA = props.container === "ClientRefunds" ? approvalStatusRGBA("Refund") : approvalStatusRGBA(props.txnData.approval_status);

  let payMethodLogo = props?.txnData.settlement_type === "HDFC NEFT/RTGS"
    ? bankLogoMapper(props?.txnData.owner_bank_name)
    : props?.txnData.settlement_type === "Instant Pay"
      ? "Instant Pay"
      : props?.txnData.settlement_type === "UPI"
        ? "UPI"
        : props?.txnData.settlement_type === "Registered Card"
          ? props?.txnData.card_type || "Default Card" :
          props?.txnData.settlement_type === "Net Banking" ?
            "Bank_default"
            : "Status - pending";

  const name =
    props?.txnData?.settlement_type === "HDFC NEFT/RTGS"
      ? props?.txnData?.owner_name
      : props?.txnData.settlement_type === "UPI"
        ? props?.txnData?.upi_id
        : props?.txnData?.settlement_type === "Registered Card"
          ? props?.txnData?.cardnum
          : "";

  return (
    <>
      <div className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-xl
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden">
        <div className="flex flex-row -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
          <div className="font-semibold
            w-3/5 text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased"
            style={{ color: propStatus }}>
            {`₹ ${amountProp}`}
          </div>
          <div className="w-2/5 text-sm flex flex-row gap-1
            justify-end">
            <div className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased group-hover:invisible group-hover:hidden">
              {props.container === "ClientRefunds" ? props.txnData.initiated_on : formatDateViewAll(props.txnData.posting_date, false)}
            </div>
            <div className="flex flex-row justify-start">
              <div className="w-1.5 4xl:w-2"
                style={{ backgroundColor: propStatus }}>
              </div>
              <div className="flex group-hover:px-4"
                style={{ backgroundColor: cardStatusRGBA }}>
                <span className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                  style={{ color: propStatus }}>
                  {props.container === "ClientRefunds" ? "Refunded" : VendorTxnTextMapper(props.txnData.approval_status) === "Approved" ? "Created" : VendorTxnTextMapper(props.txnData.approval_status)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div className="w-3/5
            flex text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased">
            <span className="self-center text-white truncate capitalize">
              {
                props.container === "ClientRefunds" ? name :
                  props.txnData.client_name ?
                    trimSentence(props.txnData.client_name, 15)?.toLowerCase() :
                    "+91 " + props.txnData.client_contact_no.replace(/(.{5})/g, '$1 ')
              }
            </span>
          </div>
          <div className="w-2/5
            -mr-4 -mb-4
            4xl:-mr-6 4xl:-mb-6
            5xl:-mr-8 5xl:-mb-8
            6xl:-mr-10 6xl:-mb-10
            flex flex-col justify-end">
            <CardLogo bank={payMethodLogo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DataCardClientViewAll;