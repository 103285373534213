import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const addStatus = createAsyncThunk(
  "addStatus",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(values),
    };

    return await fetch(
      `${legacyUrl}/transactions/add_status`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const addStatusState = createSlice({
  name: "addStatusState",
  initialState: {
    isLoading: null,
    response: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(addStatus.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addStatus.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
    });
    builder.addCase(addStatus.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default addStatusState.reducer;