import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import pci from "../../assets/Landing/pci.png";

import Xpentrabanks from "../../assets/Landing/Xpentrabanks-1.gif";
import wiretransfer from "../../assets/Landing/Wire-transfer.gif";
import animationapp from "../../assets/Landing/animation-app.gif";
import Security from "../../assets/Landing/Security.gif";
import Authorisationandautomation from "../../assets/Landing/AuthorisationandAutomation.gif";
import Assignrolesgrades from "../../assets/Landing/Assign-roles-grades.gif";
import Vendorverification from "../../assets/Landing/Vendor-verification.gif";
import TransactionAnalytics from "../../assets/Landing/Transaction-Analytics.gif";
import Transactiontimeline from "../../assets/Landing/Transaction-timeline.gif";
import Instantnotification from "../../assets/Landing/Instant-Notification.gif";
import Standinginstructions from "../../assets/Landing/Standing-Instructions.gif";
import FA from "../../assets/Landing/2FA.gif";
import receivemoney from "../../assets/Landing/receive-money.gif";
import DayInterestfreecredit from "../../assets/Landing/30-days-interest-free-credit.gif";
import costoftxn from "../../assets/Landing/cost-of-txn.gif";
import ERPLink from "../../assets/Landing/ERP-Link.gif";

import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";

const FeatruePage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [isMenuActive, setIsMenuActive] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        setIsMenuActive(true);
      } else {
        setIsMenuActive(false);
      }
    };

    if (window.innerWidth > 991 && isMenuActive) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landingbody">
      <div id="preloder">
        <div className="loader"></div>
      </div>
      <header
        className={`header-section ${isSticky ? "sticky slideInDown" : ""}`}
      >
        <div className="header-content">
          <div className="landingcontainer">
            <a
              onClick={() => navigate("/")}
              className="site-logo"
              style={{ cursor: "pointer" }}
            >
              <img className="landingimg" src={xpentraLogo} alt="xpentraLogo" />
            </a>
            <div className="responsive-bar" onClick={toggleMenu}>
              <i className="fa fa-bars"></i>
            </div>
            <a href="" className="user">
              <i className="fa fa-user"></i>
            </a>
            <button
              className="site-btn sb-gradients sbg-line"
              id="loginbtn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            {isMenuActive && (
              <nav className="main-menu">
                <ul className="menu-list">
                  <li onClick={() => navigate("/")}>
                    <a>Home</a>
                  </li>
                  <li
                    className="actived"
                    onClick={() => navigate("/featruepage")}
                  >
                    <a>Features</a>
                  </li>
                  <li onClick={() => navigate("/howitwork")}>
                    <a>How it works</a>
                  </li>
                  <li onClick={() => navigate("/aboutus")}>
                    <a>About</a>
                  </li>
                  <li onClick={() => navigate("/contact")}>
                    <a>Contact</a>
                  </li>
                  <li onClick={() => navigate("/faq")}>
                    <a>FAQ</a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>

      <main>
        <section className="inner-section gradient gradient-primary-auxiliary">
          <div className="shapes-container">
            <div className="static-shape ghost-shape ghost-shape-1"></div>
            <div className="static-shape pattern-dots-1"></div>
            <div className="static-shape pattern-dots-2"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="landinginner-text">
                  <h2>Features</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-section boxshadow spad">
          <div className="shapes-container">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
          </div>
          <div className="landingcontainer" id="webview">
            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Compatible with all Banks</h4>
                      <p>
                        Link and access all your bank accounts within the
                        country on our platform with single sign in.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={Xpentrabanks} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={wiretransfer} alt="" />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Wire transfer</h4>
                      <p>
                        Link all your current accounts, saving accounts and bank
                        overdrafts that you have with every bank to Xpentra and
                        pay your vendors using wire transfer as well as complete
                        internal transfers between them. No need to login into
                        each NetBanking portal anymore!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>App</h4>
                      <p>
                        With the in-house built Android app and web app on iOS,
                        you can avail all the functionality of the website on
                        your phone. Create, approve and view vendor accounts as
                        well as transactions on the move with the level of
                        security that best suits you.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={animationapp} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={Security} alt="" />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>360&#176; Security</h4>
                      <p>
                        Xpentra allows you to restrict or secure functions of or
                        platform with an OTP/fingerprint/location. If you want
                        to make sure only certain people access details of a
                        transaction even if they can view it then you can
                        restrict details with an OTP allowing selective access
                        to details of the transactions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Authorisation and automation</h4>
                      <p>
                        Xpentra utilises an authorisation matrix that can be
                        customised to your organisational needs. Authorisation
                        matrix allows you to assign roles for transaction
                        approval based on transaction value and assigned level
                        of the user. Users can be assigned levels and roles that
                        would enable them to create, approve and settle
                        transactions. Matrix can be customized to enable you to
                        auto-approve your transactions based upon your vendors
                        or transaction value. Let's take a transaction that
                        requires approval from two senior executives and is
                        created by an intern. If the matrix is set to receive
                        approval from both the senior executives then the intern
                        would require approval of both the senior executives to
                        proceed. Settlement can then be assigned to a third
                        employee who is only responsible for settlements.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Authorisationandautomation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Assignrolesgrades}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Roles</h4>
                      <p>
                        Define users, roles, grades as per the company
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Real-time Vendor Verification</h4>
                      <p>
                        Instant PAN verification and account verification at the
                        time of vendor creation acts as a check to ensure the
                        details are correct without exposing the data to a third
                        party. This prevents the possibility of human errors and
                        frauds while minimizing failed transfers and reversals.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Vendorverification}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={TransactionAnalytics}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Transaction Analytics</h4>
                      <p>
                        Understand your expenditure with the help of transaction
                        analytics to get insights of your cash flow. For
                        example, if you want to understand your expenditure for
                        a specific vendor and duration you can use MIS report.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Transaction timeline</h4>
                      <p>
                        Monitor your transactions as they go through the process
                        of approval followed by settlement. Transaction timeline
                        shows you the current status of a transaction. For
                        instance if the vendors haven't received the payment
                        then you can track at what stage has the transaction
                        reached.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Transactiontimeline}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Instantnotification}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Get instant notifications</h4>
                      <p>
                        Maybe you want to be notified when a transaction needs
                        your approval, or maybe you created a transaction and
                        want to be notified when the payment has been processed.
                        Our platform delivers real time notification for all
                        such scenarios.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Make Utility Payments</h4>
                      <p>
                        Make Utility payments for your business using a variety
                        of payment options available with the ability to
                        configure separate methods of payments for different
                        utilities. Standing instruction capability can also be
                        set-up to ensure these periodic payments are made on
                        time so as to ensure smooth flow of your business. For
                        example, if you have rent payment scheduled for the end
                        of the month, with standing instructions you can create
                        a recurring payment to the landlord's account and rent
                        payment gets credited to the landlord's account
                        automatically on the day of payment.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Standinginstructions}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Instantnotification}
                      alt=""
                    />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Notify Vendors</h4>
                      <p>
                        Notify vendors once payment has been initiated with
                        payment advice capturing all payment details upon
                        settlement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Multi-Factor authentication modules</h4>
                      <p>
                        All the service modules within Xpentra can be secured
                        with two factor authentication for an added level of
                        security. This add-on would allow you to restrict or
                        secure areas of Xpentra with an OTP. If you want to make
                        sure only certain people access details of a transaction
                        even if they can view it then you can restrict details
                        with an OTP allowing selective access to details of the
                        transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={FA} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={receivemoney} alt="" />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Receivables Solution</h4>
                      <p>
                        Accept payments from customers using 100+ payment
                        options through multiple partnered gateways enabling you
                        to take advantage of higher success rate and competitive
                        costs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>30+ days interest free credit</h4>
                      <p>
                        When you pay using a credit card on Xpentra, the vendor
                        receives your payment in a short time while the money
                        will leave your bank in 30+ days giving you flexible use
                        of the available cash. Assume you have a card with a
                        credit period of 45 days, any payments made to the
                        vendor within that period are interest free until the
                        payment due date when the amount must be settled. You
                        can enjoy upto 45 days of free credit period as compared
                        to credit line/overdraft facility when using a credit
                        card on Xpentra.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={DayInterestfreecredit}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={costoftxn} alt="" />
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Cost of credit card transaction</h4>
                      <p>
                        For vendor payments using a credit card, the cost of
                        transaction can be setup differently for each vendor so
                        that either the vendor bears the cost, you bear the cost
                        or it is split between the two in any proportion you
                        agree.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>ERP link</h4>
                      <p>
                        Xpentra can link with your ERP to automate transactions,
                        we can manage your transaction receiving instructions
                        from your ERP with minimal human intervention. Let's
                        take a scenario where ERP and Xpentra are linked for the
                        highest level of automation. ERP will prepare and send
                        data feed to Xpentra for transaction processing and
                        Xpentra will send a reverse feed to ERP with all the
                        transaction details.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={ERPLink} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landingcontainer" id="mobileview">
            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Compatible with all Banks</h4>
                      <p>
                        Link and access all your bank accounts within the
                        country on our platform with single sign in.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={Xpentrabanks} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Wire transfer</h4>
                      <p>
                        Link all your current accounts, saving accounts and bank
                        overdrafts that you have with every bank to Xpentra and
                        pay your vendors using wire transfer as well as complete
                        internal transfers between them. No need to login into
                        each NetBanking portal anymore!
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={wiretransfer} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>App</h4>
                      <p>
                        With the in-house built Android app and web app on iOS,
                        you can avail all the functionality of the website on
                        your phone. Create, approve and view vendor accounts as
                        well as transactions on the move with the level of
                        security that best suits you.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={animationapp} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>360&#176; Security</h4>
                      <p>
                        Xpentra allows you to restrict or secure functions of or
                        platform with an OTP/fingerprint/location. If you want
                        to make sure only certain people access details of a
                        transaction even if they can view it then you can
                        restrict details with an OTP allowing selective access
                        to details of the transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={Security} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Authorisation and automation</h4>
                      <p>
                        Xpentra utilises an authorisation matrix that can be
                        customised to your organisational needs. Authorisation
                        matrix allows you to assign roles for transaction
                        approval based on transaction value and assigned level
                        of the user. Users can be assigned levels and roles that
                        would enable them to create, approve and settle
                        transactions. Matrix can be customized to enable you to
                        auto-approve your transactions based upon your vendors
                        or transaction value. Let's take a transaction that
                        requires approval from two senior executives and is
                        created by an intern. If the matrix is set to receive
                        approval from both the senior executives then the intern
                        would require approval of both the senior executives to
                        proceed. Settlement can then be assigned to a third
                        employee who is only responsible for settlements.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Authorisationandautomation}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Roles</h4>
                      <p>
                        Define users, roles, grades as per the company
                        requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Assignrolesgrades}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Real-time Vendor Verification</h4>
                      <p>
                        Instant PAN verification and account verification at the
                        time of vendor creation acts as a check to ensure the
                        details are correct without exposing the data to a third
                        party. This prevents the possibility of human errors and
                        frauds while minimizing failed transfers and reversals.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Vendorverification}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Transaction Analytics</h4>
                      <p>
                        Understand your expenditure with the help of transaction
                        analytics to get insights of your cash flow. For
                        example, if you want to understand your expenditure for
                        a specific vendor and duration you can use MIS report.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={TransactionAnalytics}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Transaction timeline</h4>
                      <p>
                        Monitor your transactions as they go through the process
                        of approval followed by settlement. Transaction timeline
                        shows you the current status of a transaction. For
                        instance if the vendors haven't received the payment
                        then you can track at what stage has the transaction
                        reached.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Transactiontimeline}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Get instant notifications</h4>
                      <p>
                        Maybe you want to be notified when a transaction needs
                        your approval, or maybe you created a transaction and
                        want to be notified when the payment has been processed.
                        Our platform delivers real time notification for all
                        such scenarios.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Instantnotification}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Make Utility Payments</h4>
                      <p>
                        Make Utility payments for your business using a variety
                        of payment options available with the ability to
                        configure separate methods of payments for different
                        utilities. Standing instruction capability can also be
                        set-up to ensure these periodic payments are made on
                        time so as to ensure smooth flow of your business. For
                        example, if you have rent payment scheduled for the end
                        of the month, with standing instructions you can create
                        a recurring payment to the landlord's account and rent
                        payment gets credited to the landlord's account
                        automatically on the day of payment.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Standinginstructions}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Notify Vendors</h4>
                      <p>
                        Notify vendors once payment has been initiated with
                        payment advice capturing all payment details upon
                        settlement.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={Instantnotification}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Multi-Factor authentication modules</h4>
                      <p>
                        All the service modules within Xpentra can be secured
                        with two factor authentication for an added level of
                        security. This add-on would allow you to restrict or
                        secure areas of Xpentra with an OTP. If you want to make
                        sure only certain people access details of a transaction
                        even if they can view it then you can restrict details
                        with an OTP allowing selective access to details of the
                        transactions.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={FA} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Receivables Solution</h4>
                      <p>
                        Accept payments from customers using 100+ payment
                        options through multiple partnered gateways enabling you
                        to take advantage of higher success rate and competitive
                        costs.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={receivemoney} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>30+ days interest free credit</h4>
                      <p>
                        When you pay using a credit card on Xpentra, the vendor
                        receives your payment in a short time while the money
                        will leave your bank in 30+ days giving you flexible use
                        of the available cash. Assume you have a card with a
                        credit period of 45 days, any payments made to the
                        vendor within that period are interest free until the
                        payment due date when the amount must be settled. You
                        can enjoy upto 45 days of free credit period as compared
                        to credit line/overdraft facility when using a credit
                        card on Xpentra.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img
                      className="landingimg"
                      src={DayInterestfreecredit}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>Cost of credit card transaction</h4>
                      <p>
                        For vendor payments using a credit card, the cost of
                        transaction can be setup differently for each vendor so
                        that either the vendor bears the cost, you bear the cost
                        or it is split between the two in any proportion you
                        agree.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={costoftxn} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      <h4>ERP link</h4>
                      <p>
                        Xpentra can link with your ERP to automate transactions,
                        we can manage your transaction receiving instructions
                        from your ERP with minimal human intervention. Let's
                        take a scenario where ERP and Xpentra are linked for the
                        highest level of automation. ERP will prepare and send
                        data feed to Xpentra for transaction processing and
                        Xpentra will send a reverse feed to ERP with all the
                        transaction details.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <div className="landingvideo">
                    <img className="landingimg" src={ERPLink} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>

      <footer className="footer-section footer">
        <div className="landingcontainer">
          <div className="landingrow spad">
            <div className="coll-md-5 footer-widget">
              <p>
                Payments should be Quick, Secure and Quintessentially yours.
              </p>
              <span>
                &copy; Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                rights reserved
              </span>
              <br />
              <br />
              <img
                src={pci}
                className="mb-4"
                alt=""
                style={{ width: "100px", marginRight: "20px", float: "left" }}
              />
              <a
                id="fC4seLa5xl"
                href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                target="_blank"
              >
                <img
                  src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                  alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                  style={{ width: "50px", float: "left" }}
                />
              </a>
            </div>
            <div className="coll-md-4 footer-widget">
              <h5 className="widget-title">Quick Links</h5>
              <div className="landingrow">
                <div className="coll-md-5 footer-widget">
                  <ul>
                    <li className="actived" onClick={() => navigate("/")}>
                      <a>Home</a>
                    </li>
                    <li onClick={() => navigate("/featruepage")}>
                      <a>Features</a>
                    </li>
                    <li onClick={() => navigate("/howitwork")}>
                      <a>How it works</a>
                    </li>
                    <li onClick={() => navigate("/aboutus")}>
                      <a>About</a>
                    </li>
                    <li onClick={() => navigate("/contact")}>
                      <a>Contact</a>
                    </li>
                    <li onClick={() => navigate("/faq")}>
                      <a>FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="coll-md-7 footer-widget">
                  <ul>
                    <li onClick={() => navigate("/privacy")}>
                      <a>Privacy Policy</a>
                    </li>
                    <li onClick={() => navigate("/terms")}>
                      <a>Terms of Use</a>
                    </li>
                    <li onClick={() => navigate("/cancellation")}>
                      <a>Cancellation Policy</a>
                    </li>
                    <li onClick={() => navigate("/refund")}>
                      <a>Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coll-md-3 footer-widget">
              <h5 className="widget-title">Supported By</h5>
              <div className="visacard"></div>
              <div className="mastercard"></div>
              <div className="dinerscard"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FeatruePage;
