import { Box } from "@mui/material";
import React, { useEffect } from "react";
import "./index.css";
import AccStatusCard from "../../../components/Accounts/Common/AccStatusCard";
import { setBufferState } from "../../../redux/features/bufferState/bufferState";
import { useDispatch } from "react-redux";
import { setHideBreadCrumb } from "../../../redux/features/hideBreadCrumb/hideBreadCrumb";

const AccCreateStatus = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  return (
    <Box
      className="flex absolute xl:fixed top-0 left-0 right-0 bottom-0  -mt-[10] flex-col xl:flex-row gap-12
      min-w-screen min-h-screen justify-center pt-24 xl:h-screen items-center bg-[#966EB0]"
    >
      <Box className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 p-6 flex flex-col items-center justify-center gap-24 text-center text-white text-2xl">
        <img
          src={require("../../../assets/Approve transactions/Success screen.png")}
          width={"70%"}
          alt=""
          srcSet=""
        />
        <h3>{props?.title}</h3>
      </Box>
      <Box className="w-4/5 flex justify-start h-[750px] xl:w-2/5 xl:h-[90%] p-0 text-center">
        <AccStatusCard data={props?.data} />
      </Box>
    </Box>
  );
};

export default AccCreateStatus;
