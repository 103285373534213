import React, { useEffect, useRef, useState } from "react";
import DropdownLogo from "../../../../assets/ViewAllTransactions/Dropdown red.png";
import { getUsers } from "../../../../redux/features/Settings/Management/Users";
import { useDispatch, useSelector } from "react-redux";

const VisibilityCard = (props) => {
  const dispatch = useDispatch();
  const [toggleDropdown, setToggleDropdown] = useState(true);
  const all_users = useSelector((state) => state.users.users);
  const handleFeatureChange = (value, name) => {
    props.setPayload({ ...props.payload, user_id: value, user_name: name });
  };

  useEffect(() => {
    dispatch(getUsers({ page_size: "1000" }));
  }, []);

  const dropdownRefVisibility = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRefVisibility.current &&
        !dropdownRefVisibility.current?.contains(event.target)
      ) {
        setToggleDropdown(true);
      }
    }

    if (toggleDropdown) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className="p-2 bg-[#9777AC] flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-2"
      >
        <div>
          <div
            className="-m-2 bg-[#9777AC]
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18"
          >
            <div
              className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
            >
              {props?.title}
            </div>
          </div>
        </div>

        <div className="-mx-2 mt-2">
          <div
            className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-8"
          >
            <div className="flex justify-between items-center gap-5">
              <div className="flex flex-col gap-4">
                <div className="text-white text-lg">Visible to all users</div>
                <div className="text-[#FFD600]">
                  This card will be visible to all users.
                </div>
              </div>
              <div className="p-2">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-custom-lightGreen dark:peer-focus:ring-green-800 rounded-xl peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-xl after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-custom-lightGreen"></div>
                </label>
              </div>
            </div>
            {all_users?.employees && (
              <div
                style={{
                  pointerEvents: props.activeStep === 2 ? "none" : "auto",
                }}
                className="flex flex-col w-full gap-4"
              >
                {/* <div className="text-white capitalize
                    text-md 3xl:text-lg 5xl:text-xl truncate">
                                Account type
                            </div> */}
                {toggleDropdown ? (
                  <div
                    onClick={(e) => {
                      setToggleDropdown(false);
                      e.stopPropagation();
                    }}
                    className="w-full flex flex-row justify-between items-center bg-[#744991] px-5 py-2 rounded-lg cursor-pointer"
                  >
                    <div className="text-white text-lg 3xl:text-xl 5xl:2xl">
                      {props.payload?.user_name
                        ? props.payload.user_name
                        : "Select user"}
                    </div>
                    <div>
                      <img alt="" src={DropdownLogo} className="w-6 h-6" />
                    </div>
                  </div>
                ) : (
                  <div ref={dropdownRefVisibility} className="relative">
                    <div className="w-full absolute z-10 overflow-y-scroll max-h-80 flex flex-col justify-between vendor-txn account_type bg-[#B8A3C6] border-[#E25869] border px-5 py-2 rounded-lg">
                      <div>
                        {all_users?.employees?.map((item) => (
                          <button
                            key={item?.user_id}
                            onClick={() => {
                              handleFeatureChange(
                                item?.user_id,
                                item?.first_name + " " + item?.last_name
                              );
                              setToggleDropdown(true);
                            }}
                            className="text-white text-lg 3xl:text-xl 5xl:2xl bg-[#B8A3C6] hover:bg-[#744991] my-1 w-full p-2 flex justify-start truncate rounded-lg"
                          >
                            {item.first_name + " " + item.last_name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="text-[#FFD600]">
                  This card will be visible to the selected user only.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VisibilityCard;
