import React from "react";
import { Box } from "@mui/material";
import formatCurrency from "../../../../utils/CurrencyFormater";
import NextDate from "../../../NextDate";
import logo from "../../../../assets/TransactionDashboardIcons/InstantPay card icon.png";



const TxnCreatedCard = (props) => {
    return (
        <Box className="SettleTxnContainer p-10 3xl:p-12 4xl:p-14 5xl:p-16 6xl:p-20 flex flex-col gap-5">
            <Box
                sx={{
                    font: "normal normal 600 34px/46px Open Sans",
                    textAlign: "center",
                    letterSpacing: "0px",
                    color: "#4DE659",
                    opacity: "1",
                    whiteSpace: "nowrap",
                }}
            >
                <div>₹ {formatCurrency(props?.amount)}</div>
            </Box>
            {
                props.txnCompletionDate != 0 &&
                <Box className="estimatedDateSettleTxn">
                    <span>{props.txnCompletionDate == 1 ? <NextDate Tplus2 /> : props.txnCompletionDate == 2 ? <NextDate /> : null}</span>
                    <br />
                    <span
                        style={{
                            textAlign: "left",
                            font: "normal normal normal 11px/15px Open Sans",
                            letterSpacing: "0px",
                            color: "#CDCDCD",
                            opacity: "1",
                        }}
                    >
                        Estimated transaction completion on
                    </span>
                </Box>
            }
            { props.totalSelected > 1 ? <div className="px-6 2xl:px-8 3xl:px-8 4xl:p-x12 5xl:px-16 6xl:px-18 text-white text-2xl 4xl:text-3xl capitalize truncate ...">
                    { props.totalSelected}  transactions
            </div>:
            <div className="flex gap-4 w-full justify-center items-center">
                <div className="px-4 py-1 bg-white rounded-xl">
                    <img className="w-4 xl:w-6 3xl:w-8 5xl:w-10" alt="logo" src={logo} />
                </div>
                <div className="text-white text-2xl 4xl:text-3xl capitalize truncate ...">
                    InstantPay Refill
                </div>
            </div> 
            
            }
        </Box>
    );
};

export default TxnCreatedCard;
