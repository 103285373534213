import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./index.css";
import SelectDebitAccount from "./SelectDebitAccount";
import DebitAccount from "../DebitAccount";
import FilledCardComponent from "../FilledCardComponent";

const CreateInternalTxnForm = (props) => {
  const DebitAcc = useSelector((state) => state.bufferState.internalDebit);
  const CreditAcc = useSelector((state) => state.bufferState.internalCredit);
  const [open, setOpen] = useState(false);
  const [updatedDivHeight, setUpdatedDivHeight] = useState(0);
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      const updatedDivHeight = divRef.current.clientHeight;
      divRef.current.style.height = updatedDivHeight + "px";
      setUpdatedDivHeight(divRef.current.style.height);
    }
  }, [DebitAcc, CreditAcc]);

  const handleOpen = (type) => {
    setOpen(type);
  };

  const handleClose = () => setOpen(false);

  return (
    <div className="flex gap-8 flex-col justify-between ">
      {DebitAcc?.owner_id ? (
        <div className="flex flex-col gap-6">
          <div ref={divRef}>
            <FilledCardComponent
              data={DebitAcc}
              componentType={"Debit"}
              onClickFn={() => handleOpen("Debit")}
              editable={props.editable}
              handleOpen={handleOpen}
              enableNextButton={props.enableNextButton}
            />
          </div>
          <div className="text-center flex justify-center items-center text-white font-semibold text-base xl:text-lg 3xl:text-xl 5xl:text-3xl">
            <img
              className="w-6 xl:w-8 3xl:w-10 5xl:w-12"
              src={require("../../../../assets/CreateTransactionIcons/White_arrow_internal.png")}
              alt=""
            />
            To
          </div>
        </div>
      ) : (
        <div>
          <DebitAccount componentType={"Debit"} handleOpen={handleOpen} />
        </div>
      )}
      {DebitAcc?.owner_id &&
        (CreditAcc?.owner_id ? (
          <div className="flex flex-col gap-6">
            <div style={{ height: updatedDivHeight }}>
              <FilledCardComponent
                componentType={"Credit"}
                onClickFn={() => handleOpen("Credit")}
                data={CreditAcc}
                editable={props.editable}
                handleOpen={handleOpen}
                enableNextButton={props.enableNextButton}
              />
            </div>
          </div>
        ) : (
          <div style={{ height: updatedDivHeight }}>
            <DebitAccount componentType={"Credit"} handleOpen={handleOpen} />
          </div>
        ))}
      {open ? (
        <>
          <SelectDebitAccount
            componentType={open}
            enableNextButton={props.enableNextButton}
            handleClose={handleClose}
            selectedAccCredit={CreditAcc}
            selectedAccDebit={DebitAcc}
          />
        </>
      ) : null}
    </div>
  );
};

export default CreateInternalTxnForm;
