import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast_position } from "../Settings/Authorization";
import { toast } from "react-toastify";
import { stringify } from "postcss";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const createVendorTxn = createAsyncThunk("createVendorTxn", async (values) => {

  const { amount, distribution_remark, invoice_no, vendor_id } = values
    ? values
    : {
      amount: null,
      distribution_remark: null,
      invoice_no: null,
      vendor_id: null,
    };
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      amount: amount,
      distribution_remark: distribution_remark,
      invoice_no: invoice_no,
      vendor_id: vendor_id,
    }),
  };

  return await fetch(
    `${legacyUrl}/transactions/vendor/create`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error", error);
    });
});

export const createClientTxn = createAsyncThunk("createClientTxn", async (values) => {
  const { amount, distribution_remark, invoice_no, client_id } = values
    ? values
    : {
      amount: null,
      distribution_remark: null,
      invoice_no: null,
      client_id: null,
    };
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      amount: amount,
      distribution_remark: distribution_remark,
      invoice_no: invoice_no,
      client_id: client_id,
    }),
  };

  return await fetch(
    `${legacyUrl}/transactions/client/create`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error", error);
    });
});

export const createInternalTxn = createAsyncThunk(
  "createInternalTxn",
  async (values) => {
    const {
      amount,
      destination_owner_id,
      source_owner_id,
      distribution_remark,
    } = values
        ? values
        : {
          amount: null,
          destination_owner_id: null,
          source_owner_id: null,
          distribution_remark: null,
        };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        amount: amount,
        destination_owner_id: destination_owner_id,
        source_owner_id: source_owner_id,
        distribution_remark: distribution_remark,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/owner/internal_fund_transfer/create`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("An Error was encountered", toast_position)
      });
  }
);

export const refillInstantPay = createAsyncThunk(
  "refillInstantPay",
  async (values) => {
    const { amount } = values
      ? values
      : {
        amount: null,
      };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        amount: amount,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/instant_pay/refill_balance`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

export const createFFTxn = createAsyncThunk(
  "createFFTxn",
  async (values) => {
    const { amount, distribution_remark, invoice_no, account_id } = values
      ? values
      : {
        amount: null,
        distribution_remark: null,
        invoice_no: null,
        vendor_id: null,
      };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        amount: amount,
        distribution_remark: distribution_remark,
        invoice_no: invoice_no,
        user_id: account_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/create`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

const createTxn = createSlice({
  name: "createTxn",
  initialState: {
    vendor: null,
    client: null,
    instantpay: null,
    internal: null,
    fieldforce: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(createVendorTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createVendorTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(createVendorTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //client
    builder.addCase(createClientTxn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createClientTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(createClientTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //instantpay
    builder.addCase(refillInstantPay.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(refillInstantPay.fulfilled, (state, action) => {
      state.isLoading = false;
      state.instantpay = action.payload;
    }
    );
    builder.addCase(refillInstantPay.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    //internal
    builder.addCase(createInternalTxn.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createInternalTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internal = action.payload;
    })
    builder.addCase(createInternalTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    })
    //fieldforce
    builder.addCase(createFFTxn.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(createFFTxn.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldforce = action.payload;
    }
    );
    builder.addCase(createFFTxn.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
  },
});

export default createTxn.reducer;





































