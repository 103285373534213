import React from "react";
import { useNavigate } from "react-router-dom";
import CardLogo from "../../../_utils/logo/CardLogo";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const DashboardClientAccountList = (props) => {
  const navigate = useNavigate();
  const name = props.name;
  const mobile = props.mobile;
  const textColor = props.textColor;
  const url = props.navigate_url;

  const mobileCard = () => {
    return (
      <>
        <div className="w-16 flex flex-row justify-center group-hover:bg-custom-card bg-[#506994] rounded-full rounded-tr-3xl">
          <span className="bg-white text-[#506994] self-center shrink-0 p-1 rounded-4xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
        <div className="px-4 w-32 xs:w-56 truncate ... flex flex-row justify-start py-4 4xl:py-6 5xl:py-8 6xl:py-10">
          <span
            className="font-medium truncate ..."
            style={{ color: textColor }}
          >
            +91 {mobile}
          </span>
        </div>
      </>
    );
  };

  const nameCard = () => {
    return (
      <>
        <div className="w-16 flex flex-row justify-center bg-[#506994] rounded-full rounded-tr-3xl">
          <span className="bg-white text-[#506994] font-extrabold self-center shrink-0 px-3  py-1.5 rounded-4xl">
            {name.charAt(0).toUpperCase()}
          </span>
        </div>
        <div className="px-4 w-32 xs:w-56 truncate ... flex flex-row justify-start py-4 4xl:py-6 5xl:py-8 6xl:py-10">
          <span
            className="font-medium truncate ... capitalize"
            style={{ color: textColor }}
          >
            {name}
          </span>
        </div>
      </>
    );
  };

  const cardContentDiv = () => {
    if (name) return nameCard();
    else return mobileCard();
  };

  return (
    <div
      className="w-full"
      onClick={() => {
        navigate(url);
      }}
      style={{ textDecoration: "none" }}
    >
      <div
        className="
        bg-custom-card rounded-customXl flex flex-row
        min-w-full
        overflow-hidden
        hover:bg-custom-cardHover
        cursor-pointer drop-shadow-xl antialiased
        group"
      >
        {cardContentDiv()}
      </div>
    </div>
  );
};

export default DashboardClientAccountList;
