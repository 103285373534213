import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Action

export const dummyVerification = createAsyncThunk("dummyVerification", async (values) => {
    return { data: "dummy data" }
});



const verification = createSlice({
    name: "verification",
    initialState: {
        data: null,
    },
    reducers: {
        getDummy(state, action) {
            state.data = action.payload;
        },
    },
});

export const { getDummy } =
    verification.actions;

export default verification.reducer;