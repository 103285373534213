import React from "react";
import { useNavigate } from "react-router-dom";
import trimSentence from "../../../../utils/trimSentence";
import CardLogo from "../../../_utils/logo/CardLogo";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const DashboardAccountList = (props) => {
  const navigate = useNavigate();
  const name = props.name;
  const textColor = props.textColor;
  const scheme_logo = props.scheme_logo;
  const bank_logo = bankLogoMapper(props.bank_name);
  const url = props.navigate_url;

  return (
    <div
      className="w-full"
      onClick={() => {
        navigate(url);
      }}
      style={{ textDecoration: "none" }}
    >
      <div
        className="
        bg-custom-card rounded-customXl flex flex-col justify-center
        min-w-full
        py-4 4xl:py-6 5xl:py-8 6xl:py-10
        overflow-hidden
        hover:bg-custom-cardHover
        cursor-pointer drop-shadow-xl antialiased
        group"
      >
        <div className="flex flex-row w-full gap-1">
          <div
            className="w-[70%] flex font-medium flex-row text-md justify-between gap-5
            pl-4 4xl:pl-6 5xl:pl-8 6xl:pl-10"
            style={{ color: textColor }}
          >
            <span className="truncate ...">{name}</span>
          </div>
          <div
            className="
            w-[30%]
            -mr-6 -mb-4
            4xl:-mr-10 4xl:-mb-6
            5xl:-mr-12 5xl:-mb-8
            6xl:-mr-14 6xl:-mb-10
            flex flex-col justify-end"
          >
            <CardLogo
              bank={bank_logo}
              scheme_logo={scheme_logo}
              divCssProps={"rounded-tl-3xl 5xl:rounded-tl-large drop-shadow-xl"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAccountList;
