import React from "react";
import VerficationSearch from "../../../../components/Settings/Verification/VerificationSearch";
import VerificationDetails from "../../../../components/Settings/Verification/VerificationDetails";
import { verificationDetailsMapper } from "../../../../services/Settings/Verification/verificationDetailsMapper";
import CorpAddress from "../../../../components/Settings/Verification/CorpAddress";
import AssociatedGSTs from "../../../../components/Settings/Verification/AssociatedGSTs";
import { useDispatch, useSelector } from "react-redux";
import { verifyThisPAN_New } from "../../../../redux/features/verifyPAN/verifyPAN";

const PanVerification = () => {
  const dispatch = useDispatch();
  const verifiedPAN = useSelector((state) => state.verify.verifyPAN_New);

  return (
    <div className="mt-24 w-screen">
      <div className="fixed w-4/5 right-0 left-0 m-auto">
        <VerficationSearch
          placeholder={"Enter PAN number"}
          dispatchFn={verifyThisPAN_New}
        />
      </div>
      {verifiedPAN?.status ? (
        <>
          <div className="flex h-screen justify-center pt-40 mx-auto w-4/6">
            <VerificationDetails
              data={verifiedPAN?.pan_details}
              template={verificationDetailsMapper["PAN Verification"]}
            />
          </div>
          <div className="flex justify-center py-20 mx-auto w-full bg-[#29487E]">
            <AssociatedGSTs />
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-10 h-screen items-center pt-40 mx-auto w-2/6">
          <div className="flex">
            <img
              src={require("../../../../assets/Settings/Verification/no_data.png")}
              alt=""
              srcSet=""
            />
          </div>
          <div
            className="text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-center w-full
              text-white antialiased"
          >
            Enter PAN number in the search bar, to fetch the verified PAN
            details.
          </div>
        </div>
      )}
    </div>
  );
};

export default PanVerification;
