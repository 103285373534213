import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import "./index.css";
import MultiSelectInstantPayCard from "../../../../components/SettleTxn/MultiSelectInstantPayCard";

import { useDispatch, useSelector } from "react-redux";
import {
  getSettleInstantPayTxn,
  getSettleVendorTxn,
} from "../../../../redux/features/settleTxn/settleTxn";
import SettlePayment from "../../SettlePayment";
import formatCurrency from "../../../../utils/CurrencyFormater";
import formatdate from "../../../../utils/formatdate";
import TransactionAmount from "../../../../components/TransactionAmount";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { debounce } from "lodash";
import SearchBar from "../../../../components/_utils/search/SearchBar";
import { getInstantpayViewAll } from "../../../../redux/features/txnViewAll/txnViewAll";

const SettleInstantPayTxns = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState();

  const [hasMore, setHasMore] = useState(true);

  const toggleShowSearchBar = () => setShowSearchBar(!showSearchBar);

  const [selectedTxns, setSelectedTxns] = useState({});
  const [toPaymentSelect, setToPaymentSelect] = useState(false);
  const [selectAllToggle, setSelectAllToggle] = useState(false);
  const [selectAllCheck, setSelectAllCheck] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const vendorSettleData = useSelector((state) => state.txnViewAll.instantpay);
  const loading = useSelector((state) => state.txnViewAll.isLoading);

  const [data, setData] = useState([]);
  const [selectedDatum, setSelectedDatum] = useState([]);
  // dataSumAndDeDup
  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    // deDup data
    let j = 0;
    appendedData?.forEach((item) => {
      if (!deDupData?.find((deDupItem) => deDupItem.txn_id === item.txn_id)) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    return appendedData;
  };

  const handleRadioBTClick = (e, value, checked, datum) => {
    let selected = [...selectedDatum];
    if (!checked) {
      selected = selected.filter((item) => item.txn_id !== value);
    } else {
      selected.push(datum);
    }
    setSelectedDatum(selected);
    if (!e) {
      setSelectedTxns({ ...selectedTxns, [value]: checked });
    } else {
      let { value, checked } = e.target;
      setSelectedTxns({ ...selectedTxns, [value]: checked });
    }
  };

  const handleSelectAll = () => {
    setSelectAllCheck(!selectAllCheck);
    let selectedTxn = { ...selectedTxns };
    let selectedDatumArr = [...selectedDatum];
    if (selectAllCheck) {
      data.forEach((item) => {
        selectedTxn[item.txn_id] = false;
        selectedDatumArr = selectedDatumArr.filter(
          (datum) => datum.txn_id !== item.txn_id
        );
      });
    } else {
      data.forEach((item) => {
        selectedTxn[item.txn_id] = true;
        selectedDatumArr.push(item);
      });
    }
    setSelectedTxns(selectedTxn);
    setSelectedDatum(selectedDatumArr);
  };

  const getSelected = () => {
    let selected = [];
    let amt = 0.0;
    for (let key in selectedTxns) {
      if (selectedTxns[key]) {
        selected.push(key);
        let item = data.find((item) => item.txn_id === key);
        item = item ? item : selectedDatum.find((item) => item.txn_id === key);
        amt += Number(item.amount);
      }
    }
    return { selected: selected, amt: amt };
  };

  const getData = () => {
    dispatch(
      getInstantpayViewAll({
        search_string: searchString,
        page_no: page,
        approval_status: "Y",
      })
    );
  };

  useEffect(() => {
    if (vendorSettleData === null || vendorSettleData === undefined) return;

    if (vendorSettleData?.transactions.length === 0) {
      setHasMore(false);
      return;
    }
    setHasMore(true);
    setData(dataAppend(data, vendorSettleData?.transactions));
    const container = document.getElementById("infinityScrollContainer");
    if (container.scrollHeight <= container.clientHeight)
      // logic change required here
      setPage(page + 1);
  }, [vendorSettleData]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    setData([]);
    if (page !== 0) setPage(0);
    else getData();
  }, [searchString]);

  const handleSearch = (search_string) => {
    if (search_string !== searchString) {
      setData([]);
      setSearchString(search_string);
    }
  };

  const handleScroll =
    !toPaymentSelect &&
    debounce(() => {
      const container = document.getElementById("infinityScrollContainer");
      if (
        container.scrollHeight - container.scrollTop - container.clientHeight <=
        1
      ) {
        if (!loading && hasMore) {
          setPage(page + 1);
        }
      }
    }, 100);

  useEffect(() => {
    let container = null;
    if (!toPaymentSelect) {
      container = document.getElementById("infinityScrollContainer");
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (!toPaymentSelect)
        container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    let checkMark = data?.length > 0 ? true : false;
    data?.forEach((item) => {
      if (!selectedTxns[item.txn_id]) {
        checkMark = false;
      }
    });
    setSelectAllCheck(checkMark);
  }, [data, selectedTxns, selectAllCheck, selectedDatum]);

  return (
    <>
      {toPaymentSelect ? (
        <SettlePayment getSelected={getSelected} module="IPsettle" />
      ) : (
        <div className="flex h-screen">
          <div className="drop-shadow-xl w-4/6 pl-24 pt-32 pr-12 py-2 flex justify-between items-center bg-[#454059] fixed bg-no-repeat bg-padding-box z-50 ">
            <div
              className="w-[50%] h-full pl-8
            text-white self-center
              text-xl xl:text-2xl 3xl:text-3xl 5xl:text-5xl"
            >
              Select InstantPay transactions
            </div>
            <div className="w-[50%] flex flex-row justify-end gap-4">
              <div className="w-[60%]">
                <SearchBar
                  handleSearch={handleSearch}
                  showSearchBar={showSearchBar}
                  toggleShowSearchBar={toggleShowSearchBar}
                  bgColor={"bg-custom-notSelectedCardSettlement"}
                  bgHover={"hover:bg-custom-lightGray"}
                />
              </div>
              <FormControlLabel
                className="text-white text-base xl:text-xl 3xl:text-3xl 5xl:text-5xl"
                value="start"
                control={
                  <Checkbox
                    icon={
                      <img
                        className="w-6 h-6 3xl:w-7 3xl:h-7 4xl:w-8 4xl:h-8 6xl:w-10 6xl:h-10"
                        src={require("../../../../assets/Approve transactions/not selected.png")}
                        alt=""
                      />
                    }
                    checkedIcon={
                      <img
                        className="w-6 h-6 3xl:w-7 3xl:h-7 4xl:w-8 4xl:h-8 6xl:w-10 6xl:h-10"
                        src={require("../../../../assets/Approve transactions/selected.png")}
                        alt=""
                      />
                    }
                    className="radioBT"
                    value={"option1"}
                    name="selectAllBT"
                    inputProps={{ "aria-label": "A" }}
                    onClick={handleSelectAll}
                    checked={selectAllCheck}
                  />
                }
                label={
                  <div className="text-white text-sm xl:text-lg 3xl:text-2xl 5xl:text-4xl antialiased">
                    Select all
                  </div>
                }
                sx={{
                  textAlign: "center",
                  letterSpacing: "0px",
                  color: "#dddddd",
                  textDecoration: "underline",
                  ".MuiFormControlLabel-label": {
                    font: "normal normal 600 20px Open Sans",
                  },
                }}
                labelPlacement="start"
              />
            </div>
          </div>
          <div
            id="infinityScrollContainer"
            className="w-4/6
            mt-32 3xl:mt-36 5xl:mt-44 pb-8 pl-24
            overflow-y-auto hide-scrollbar bg-no-repeat bg-cover bg-center bg-[#454059]"
          >
            {data?.length > 0 ? (
              <div
                className="grid
              grid-cols-2
              3xl:grid-cols-3
              4xl:grid-cols-4
              pl-8 pr-12 mt-20 gap-4 3xl:gap-6 5xl:gap-8"
              >
                {data.map((item, index) => {
                  return (
                    <MultiSelectInstantPayCard
                      datum={item}
                      handleRadioBTClick={handleRadioBTClick}
                      account={item.vendor_bank_acc_no}
                      amount={item.amount}
                      txn_id={item.txn_id}
                      checked={selectAllToggle}
                      selectedTxns={selectedTxns}
                      vendor_name={item.vendor_name}
                      posting_date={formatdate(item.posting_date, false)}
                    />
                  );
                })}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
                <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                  No transactions found <br />
                  InstantPay transactions will appear here.
                </div>
                <div className="w-2/6 p-4 flex flex-row justify-center">
                  <img
                    className="h-full w-full"
                    src={NoDataImg}
                    alt="NoDataImg"
                  />
                </div>
              </div>
            )}
            {loading && (
              <div className="w-full m-auto justify-center items-center flex">
                <img
                  src={require("../../../../assets/Approve transactions/inputloder.gif")}
                  alt=""
                />
              </div>
            )}
          </div>
          <div className="w-2/6 p-2 pt-36 flex justify-between flex-col bg-[#3A3649]">
            {getSelected().selected.length === 0 ? (
              <TransactionAmount showText={true} />
            ) : (
              <TransactionAmount
                txn_count={getSelected().selected.length}
                amount={formatCurrency(getSelected().amt)}
              />
            )}
            <div className="w-4/6 h-28">
              <button
                disabled={getSelected().selected.length === 0 ? true : false}
                className="w-full font text-xl capitalize py-4 leading-33 tracking-normal shadow-customCard text-white bg-padding-box rounded-3xl flex items-center justify-center flex-col mx-7 flex-shrink-1"
                style={{
                  background:
                    getSelected().selected.length === 0 ? "#666666" : "#69B76F",
                }}
                onClick={() => {
                  setToPaymentSelect(true);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SettleInstantPayTxns;
