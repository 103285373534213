import { createSlice } from "@reduxjs/toolkit";

const bufferSlice = createSlice({
    name: "bufferState",
    initialState: {
        isLoading: false,
        isError: false,
        vendor: null,
        fieldForce: null,
        internalDebit: null,
        internalCredit: null,
        clientAcc: null,
        reimbursement: null,
        instantpay: null,
        txnDetails: null,
        linkUser: null,
    },
    reducers: {
        setBufferState: (state, action) => {
            state.isLoading = action.payload.isLoading;
            state.isError = action.payload.isError;
            state.vendor = action.payload.vendor;
            state.linkUser = action.payload.linkUser;
            state.fieldForce = action.payload.fieldForce;
            state.internalDebit = action.payload.internalDebit;
            state.internalCredit = action.payload.internalCredit
            state.clientAcc = action.payload.clientAcc;
            state.clientPrimaryAcc = action.payload.clientPrimaryAcc
            state.reimbursement = action.payload.reimbursement;
            state.instantpay = action.payload.instantpay;
            state.txnDetails = action.payload.txnDetails;
        },
    },
});

export const { setBufferState } = bufferSlice.actions;

export default bufferSlice.reducer;
