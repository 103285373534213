import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import pci from "../../assets/Landing/pci.png";
import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";
import "../../assets/Landing/css/owl.carousel.css";
import isEqual from "lodash/isEqual";

const FaqPage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [isActive, setIsActive] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");

  const [isOpen, setIsOpen] = useState({});

  const toggleOpen = (id) => () =>
    setIsOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

  const [isMenuActive, setIsMenuActive] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const faqs = [
    {
      id: 1,
      question: "How does Xpentra work for linking multiple bank accounts?",
      answer:
        "Xpentra provides a user-friendly interface that allows you to securely link multiple bank accounts from different banks. You can simply enter the necessary account information and follow the prompts to link your accounts seamlessly.",
    },
    {
      id: 2,
      question:
        "Is my financial information safe and secure when using Xpentra?",
      answer:
        "Yes, we take data security seriously. Xpentra uses industry-standard encryption and security protocols to protect your financial information. We do not store any sensitive financial information, such as bank account numbers or credit card details, on our servers.",
    },
    {
      id: 3,
      question: "Can I link bank accounts from different banks using Xpentra?",
      answer:
        "Yes, Xpentra supports linking bank accounts from different banks, allowing you to manage and access multiple accounts in one place, making it convenient and efficient for your financial transactions.",
    },
    {
      id: 4,
      question: "What are the benefits of using Xpentra for vendor payments?",
      answer:
        "Xpentra streamlines vendor payments by providing a centralized platform to manage payments, track invoices, and automate payment processes. It helps you save time and effort, reduce errors, and improve cash flow management.",
    },
    {
      id: 5,
      question: "How does Xpentra handle customer collections?",
      answer:
        "Xpentra enables you to easily collect money from customers by providing payment gateways, payment tracking, and automated reminders. It simplifies the payment collection process, making it convenient for your customers and efficient for your business.",
    },
    {
      id: 6,
      question: "Can I set up recurring payments using Xpentra?",
      answer:
        "Yes, Xpentra allows you to set up recurring payments for regular expenses or subscription payments. You can easily configure the frequency, amount, and duration of recurring payments, saving you time and ensuring timely payments.",
    },
    {
      id: 7,
      question: "What types of businesses can benefit from using Xpentra?",
      answer:
        "Xpentra is designed for businesses of all sizes and across various industries, including e-commerce, retail, professional services, subscription-based businesses, and more. Any business that needs to manage multiple bank accounts, make vendor payments, and collect payments from customers can benefit from our platform.",
    },
    {
      id: 8,
      question: "How do I sign up for Xpentra?",
      answer:
        "You can sign up for Xpentra by visiting our website or contacting our sales team. We offer a simple and straightforward sign-up process that includes providing your business information, setting up your account, and accessing the platform.",
    },
    {
      id: 9,
      question: "Can I pay with any credit card provider?",
      answer:
        "We provide you with the flexibility to use any credit card without roll over. You can avail lower fees on your card by opting for a card provided by our bank partners.",
    },
    {
      id: 10,
      question: "How to get started with Xpentra?",
      answer:
        "We will arrange a meeting with you and collect essential details for setting up your Xpentra account for you. Our bank partners will help you with your existing credit card or provide you with a new credit card. Once you are set up, we will provide you with a demo of our platform and our team is always here to help you.<br/><br/>To get started, you can contact us at the link below.<br/><br/>",
    },
    {
      id: 11,
      question:
        "My vendor doesn't accept cards, can I still pay with a credit/debit card?",
      answer:
        "Xpentra allows you to transfer money to your vendor's bank account anywhere in India using a credit card. Your vendors do not require a POS or a merchant ID to receive the money. You will only need to provide IFSC code and Account number for your vendors.",
    },
    {
      id: 12,
      question: "Can I schedule payments ahead of the bill due date?",
      answer:
        "Yes, Xpentra allows you to schedule your payments. You can even set recurring transactions.",
    },
    {
      id: 13,
      question:
        "Will the name of my vendor appear on my bank transactions or will it show as Xpentra?",
      answer:
        "It will show Q2Pay Technologies Pvt Ltd in your bank statements. You can find detailed information about your transaction on the Xpentra app or website.",
    },
    {
      id: 14,
      question: "Does Xpentra work on mobile or desktop?",
      answer:
        "We have a Website and an Android app where you can use Xpentra. Both have the same functionality giving you flexibility.",
    },
    {
      id: 15,
      question: "Is it safe to pay through Xpentra?",
      answer:
        "We are PCI certified and our transactions are secured by our bank partners that ensure maximum security when you make any transaction.",
    },
    {
      id: 16,
      question: "Can I use Xpentra to pay my vendors internationally?",
      answer:
        "Xpentra is currently available for all transactions between Indian Bank accounts. We will let you know once we add international transaction.",
    },
    {
      id: 17,
      question: "What is the payment processing time?",
      answer:
        "All transactions would be completed in T+1 day and you can expect money to show up in your vendor's account in 3-4 working days.",
    },
    {
      id: 18,
      question: "Does Xpentra bulk pay my vendors?",
      answer:
        "We have provided you with a bulk upload feature which would require you to fill in the details of your vendors and payment in the given format, all you have to do is upload the file and we will manage the rest. All your transactions will show in the transaction dashboard like other transaction made on Xpentra.",
    },
    {
      id: 19,
      question: "Can I link a debit card to my Xpentra account?",
      answer:
        "Xpentra is exclusive to credit cards without roll over, any other card is not accepted on our platform.",
    },
    {
      id: 20,
      question: "How to auto approve a transaction?",
      answer:
        "Transaction Auto Approve can be set by creating a matrix that allows transaction upto a certain value to be approved automatically. This can be done in the Matrix tab on Xpentra.",
    },
    // Add more FAQs as needed
  ];

  const highlightSearchTerm = (text, query) => {
    if (!query || typeof query !== "string") return text;

    const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters in regex
    const regex = new RegExp(`(${escapedQuery})`, "gi");

    const filteredFaqs = faqs.filter((faq) => {
      const lowerCaseText = text.toLowerCase();
      if (
        faq.question.toLowerCase().includes(lowerCaseText) ||
        faq.answer.toLowerCase().includes(lowerCaseText)
      ) {
        isOpen[faq.id] = true;
      }
    });

    // Replace occurrences of query with <mark>query</mark>
    return text.replace(regex, (match) => `<mark>${match}</mark>`);
  };

  const FAQItem = ({ text }) => {
    const createMarkup = () => {
      // Apply the highlight function to the text
      const highlightedHTML = highlightSearchTerm(text, searchQuery);
      return { __html: highlightedHTML }; // This must be an object with __html property
    };

    return <div dangerouslySetInnerHTML={createMarkup()} />;
  };

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        setIsMenuActive(true);
      } else {
        setIsMenuActive(false);
      }
    };

    if (window.innerWidth > 991 && isMenuActive) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleKeyUp = (e) => {
    const value = e.target.value.trim();
    if (value === "") {
      setIsOpen({});
    }
  };

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  console.log("searchQuery:", searchQuery);

  return (
    <div className="landingbody">
      <div id="preloder">
        <div className="loader"></div>
      </div>
      <header
        className={`header-section ${isSticky ? "sticky slideInDown" : ""}`}
      >
        <div className="header-content">
          <div className="landingcontainer">
            <a
              onClick={() => navigate("/")}
              className="site-logo"
              style={{ cursor: "pointer" }}
            >
              <img className="landingimg" src={xpentraLogo} alt="xpentraLogo" />
            </a>
            <div className="responsive-bar" onClick={toggleMenu}>
              <i className="fa fa-bars"></i>
            </div>
            <a href="" className="user">
              <i className="fa fa-user"></i>
            </a>
            <button
              className="site-btn sb-gradients sbg-line"
              id="loginbtn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            {isMenuActive && (
              <nav className="main-menu">
                <ul className="menu-list">
                  <li onClick={() => navigate("/")}>
                    <a>Home</a>
                  </li>
                  <li onClick={() => navigate("/featruepage")}>
                    <a>Features</a>
                  </li>
                  <li onClick={() => navigate("/howitwork")}>
                    <a>How it works</a>
                  </li>
                  <li onClick={() => navigate("/aboutus")}>
                    <a>About</a>
                  </li>
                  <li onClick={() => navigate("/contact")}>
                    <a>Contact</a>
                  </li>
                  <li className="actived" onClick={() => navigate("/faq")}>
                    <a>FAQ</a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>

      <main>
        <section className="inner-section gradient gradient-primary-auxiliary">
          <div className="shapes-container">
            <div className="static-shape ghost-shape ghost-shape-1"></div>
            <div className="static-shape pattern-dots-1"></div>
            <div className="static-shape pattern-dots-2"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="landinginner-text">
                  <h2>FAQ</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faqs-section boxshadow" id="faq">
          <div className="shapes-container">
            <div className="icon">
              <i className="fa fa-question"></i>
            </div>
          </div>
          <div className="landingcontainer">
            <div className="vertical-center-inner">
              <div className="landingrow">
                <div className="coll-md-12">
                  <div className="accordion">
                    <div className="accordion_search_container">
                      <input
                        type="text"
                        className="accordion_search_input_box search_icon"
                        onKeyUp={handleKeyUp}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search ..."
                      />
                    </div>
                    {filteredFaqs.map((faq) => (
                      <div className="accordion-item" key={faq.id}>
                        <div
                          className="accordion-title"
                          onClick={toggleOpen(faq.id)}
                        >
                          <div style={{ width: "90%", padding: "0px 5px" }}>
                            <FAQItem text={faq.question} />
                          </div>
                          <div className="plusminusn">
                            {isOpen[faq.id] ? "-" : "+"}
                          </div>
                        </div>
                        <div
                          className={`accordion-content ${
                            isOpen[faq.id] ? "open" : "closed"
                          }`}
                        >
                          {isOpen[faq.id] && (
                            <FAQItem
                              text={faq.answer}
                              searchQuery={searchQuery}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>

      <footer className="footer-section footer">
        <div className="landingcontainer">
          <div className="landingrow spad">
            <div className="coll-md-5 footer-widget">
              <p>
                Payments should be Quick, Secure and Quintessentially yours.
              </p>
              <span>
                &copy; Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                rights reserved
              </span>
              <br />
              <br />
              <img
                src={pci}
                className="mb-4 landingimg"
                alt=""
                style={{ width: "100px", marginRight: "20px", float: "left" }}
              />
              <a
                id="fC4seLa5xl"
                href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                target="_blank"
              >
                <img
                  className="landingimg"
                  src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                  alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                  style={{ width: "50px", float: "left" }}
                />
              </a>
            </div>
            <div className="coll-md-4 footer-widget">
              <h5 className="widget-title">Quick Links</h5>
              <div className="landingrow">
                <div className="coll-md-5 footer-widget">
                  <ul>
                    <li className="actived" onClick={() => navigate("/")}>
                      <a>Home</a>
                    </li>
                    <li onClick={() => navigate("/featruepage")}>
                      <a>Features</a>
                    </li>
                    <li onClick={() => navigate("/howitwork")}>
                      <a>How it works</a>
                    </li>
                    <li onClick={() => navigate("/aboutus")}>
                      <a>About</a>
                    </li>
                    <li onClick={() => navigate("/contact")}>
                      <a>Contact</a>
                    </li>
                    <li onClick={() => navigate("/faq")}>
                      <a>FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="coll-md-7 footer-widget">
                  <ul>
                    <li onClick={() => navigate("/privacy")}>
                      <a>Privacy Policy</a>
                    </li>
                    <li onClick={() => navigate("/terms")}>
                      <a>Terms of Use</a>
                    </li>
                    <li onClick={() => navigate("/cancellation")}>
                      <a>Cancellation Policy</a>
                    </li>
                    <li onClick={() => navigate("/refund")}>
                      <a>Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coll-md-3 footer-widget">
              <h5 className="widget-title">Supported By</h5>
              <div className="visacard"></div>
              <div className="mastercard"></div>
              <div className="dinerscard"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FaqPage;
