import React, { useState } from "react";
import { Box, Button, Popover } from "@mui/material";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { approveVendorTxn } from "../../../../redux/features/approveTxn/approveTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import formatdate from "../../../../utils/formatdate";
import { useNavigate } from "react-router-dom";
import { IPTextMapper } from "../../../../services/VendorTxnTextMapper";
import { InternalTxnDetailBannerBgMapper } from "../../../../services/VendorTxnDetailBgMapper";
import SimpleLogo from "../../../_utils/logo/SimpleLogo";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import accTransform from "../../../../utils/accTransform";
import { approvalStatus } from "../../../../services/approvalStatus";
import info from "../../../../assets/TxnDetails/info-black-border.png";
import PopOverContainer from "../../../PopOverContainer";

const TxnDetailsBanner = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleImageClick = (event) => {
    event.stopPropagation();
    handleClick(event);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <Box id="instantpayTxnDetailsBanner" className="txnDetailsBanner h-full overflow-hidden p-0">
        <Box
          style={{
            background: `transparent linear-gradient(90deg, ${InternalTxnDetailBannerBgMapper(props.approval_status)[0]} 0%, ${InternalTxnDetailBannerBgMapper(props.approval_status)[1]} 100%) 0% 0% no-repeat padding-box`,
          }}
          className="flex w-1/2 rounded-r-customXl justify-between flex-col p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16"
        >
          <div className="flex">
            <Box sx={{ textAlign: "left" }} className="text-white
            text-semibold
            text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl truncate ...">
              ₹ {formatCurrency(props.amount)}
            </Box>
            <div className="cursor-pointer" onClick={handleImageClick}>
              <img
                src={info}
                alt="info"
                className="w-3 h-3"
              />
            </div>
            <Popover
              sx={{
                "& .MuiPopover-paper": {
                  "border-radius": "40px",
                },
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <PopOverContainer data={props.data} approval_status={props.approval_status} />
            </Popover>
          </div>
          <Box className="text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl antaliased text-white">
            <div>
              {props.posting_date == undefined
                ? {}
                : formatdate(props.posting_date)}
            </div>{" "}
          </Box>
        </Box>
        <Box
          className="flex flex-col justify-between items-center h-full w-1/2 p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16"
        >
          <Box className="self-center
            text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl font-bold
          capitalize"
            style={{ color: approvalStatus(props.approval_status) }} >
            {IPTextMapper(props.approval_status)}
          </Box>
          <div></div>
          {props?.bank_acc_no && <Box className="
          text-black text-semibold
            text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl
            font-light capitalize antialiased truncate ...">{accTransform(props?.bank_acc_no)}</Box>}
          {props?.bank_logo && <Box className="w-1/3">
            <SimpleLogo bank={props?.bank_logo} />
          </Box>}
          {props.approval_status === "Y" &&
            services?.includes("Settle_txn") && <Box className="approveTxnDetailsBTs">
              <Button
                onClick={() => {
                  props.setSelectedTxnCarousel({ amt: props.amount, selected: [props.txn_id] })
                  props.setShowSettlementComponent(true)
                }}
                variant="contained"
                className="approveBt"
              >
                <div className="py-2 px-4 capitalize text-sm 3xl:text-base 5xl:text-lg">Refill</div>
              </Button>
            </Box>
          }
        </Box>
      </Box>
      <Box className="txnDetailsBannerMobile">
        <Box className="txnDetailsBannerMobileTop">
          <Box className="txnDetailsBannerLeftTop">{props.vendor_name}</Box>
          <Box className="txnDetailsBannerLeftBottom">
            <p>
              {props.posting_date == undefined
                ? {}
                : formatdate(props.posting_date)}
            </p>{" "}
          </Box>
        </Box>
        <Box className="txnDetailsBannerMobileBottom">
          <Box className="txnDetailsBannerRightTop">{"back acc no"}</Box>
          <Box className="txnDetailsBannerRightMiddle">{/* logo */}</Box>
          <Box className="txnDetailsBannerRightBottom"></Box>
        </Box>
      </Box>
    </>
  );
};

export default TxnDetailsBanner;
