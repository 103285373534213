import React, { useRef } from "react";
import "./index.css";
import Cancel from "../../../assets/CreateTransactionIcons/cancelrecent.png";
import NonActiveBanking from "./NonActiveBanking";
import ActiveBanking from "./ActiveBanking";
import { Button } from "@mui/material";

const OwnerPopup = ({
  setOpenOwner,
  selectBank,
  setSelectBank,
  filterPayload,
  setFilterPayload,
  smallSearch,
  setSmallSearch,
  selectSortAmt,
  setSelectSortAmt,
  beneOwnerData,
  beneOwnerLoading,
  isConnectedSelected,
  setIsConnectedSelected,
  txnPayLoad,
  setTxnPayLoad,
  handleRefresh,
  refreshId,
  setRefreshId,
  ownerAccBalanceLoading,
  filterPayloadNonActive,
  setFilterPayloadNonActive,
  smallSearchNonActive,
  setSmallSearchNonActive,
  selectBankNonActive,
  setSelectBankNonActive,
  beneOwnerDataNonActive,
  beneOwnerLoadingNonActive,
  setIsNeft,
  setIsImps,
  setIsRtgs,
  BenEnableNextButton,
  createTransactionBankRefreshData,
  transactionFilterData,
  balanceRefreshDisabled,
  balanceRefresh,
  refreshAnimationId,
  setRefreshAnimationId,
  previousRefreshData,
  setPreviousRefreshData,
  refreshAll,
  setRefreshAll,
  setTransactionFilterData,
  refreshType,
  setRefreshType,
}) => {
  const removeAccNo = () => {
    setSmallSearch(false);
    setFilterPayload({ ...filterPayload, accNoInput: "", searchTerm: "" });
  };

  const removeAccNoNonActive = () => {
    setSmallSearchNonActive(false);
    setFilterPayloadNonActive({
      ...filterPayloadNonActive,
      accNoInput: "",
      searchTerm: "",
    });
  };

  const inputRef1 = useRef(null);

  return (
    <div className="owner_popup_main">
      <div className="owner_popup_box">
        <div className="owner_popup_search">
          <span>Select owner account:</span>
          <button
            className="owner_popup_cancel"
            onClick={() => {
              setOpenOwner(false);
              setFilterPayload({
                ...filterPayload,
                pagination: 0,
                accNoInput: "",
                sortBank: "",
                sortAccountNo: "",
                bankNameInput: "",
                currentPage: 1,
                currentPageText: "01",
                searchTerm: "",
              });
              setFilterPayloadNonActive({
                ...filterPayload,
                pagination: 0,
                accNoInput: "",
                sortBank: "",
                sortAccountNo: "",
                bankNameInput: "",
                currentPage: 1,
                currentPageText: "01",
                searchTerm: "",
              });
              setSelectBank(false);
              setSmallSearch(false);
              setSelectSortAmt(false);
              setSelectBankNonActive(false);
              setSmallSearchNonActive(false);
              setIsConnectedSelected(true);
              setRefreshAnimationId(null);
              setRefreshId(null);
            }}
          >
            <img src={Cancel} alt="cancel" />
          </button>
        </div>
        <div className="active_noavtive_owner">
          <Button
            className="active_noavtive_owner_butt"
            onClick={() => {
              setIsConnectedSelected(true);
            }}
            style={{
              backgroundColor: isConnectedSelected ? "#FFFFFF" : "#373F4B",
              color: isConnectedSelected ? "#3A3649" : "#FFFFFF",
            }}
          >
            Connected Banking (Active)
          </Button>
          <Button
            className="active_noavtive_owner_butt_2"
            onClick={() => {
              setIsConnectedSelected(false);
            }}
            style={{
              backgroundColor: !isConnectedSelected ? "#FFFFFF" : "#373F4B",
              color: !isConnectedSelected ? "#3A3649" : "#FFFFFF",
            }}
          >
            Connected Banking (Inactive)
          </Button>
        </div>
        {isConnectedSelected ? (
          <ActiveBanking
            filterPayload={filterPayload}
            selectBank={selectBank}
            setFilterPayload={setFilterPayload}
            setSelectBank={setSelectBank}
            selectSortAmt={selectSortAmt}
            setSelectSortAmt={setSelectSortAmt}
            smallSearch={smallSearch}
            setSmallSearch={setSmallSearch}
            inputRef1={inputRef1}
            removeAccNo={removeAccNo}
            beneOwnerData={beneOwnerData}
            beneOwnerLoading={beneOwnerLoading}
            isConnectedSelected={isConnectedSelected}
            setTxnPayLoad={setTxnPayLoad}
            txnPayLoad={txnPayLoad}
            setOpenOwner={setOpenOwner}
            handleRefresh={handleRefresh}
            ownerAccBalanceLoading={ownerAccBalanceLoading}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            setIsNeft={setIsNeft}
            setIsImps={setIsImps}
            setIsRtgs={setIsRtgs}
            BenEnableNextButton={BenEnableNextButton}
            createTransactionBankRefreshData={createTransactionBankRefreshData}
            transactionFilterData={transactionFilterData}
            balanceRefresh={balanceRefresh}
            balanceRefreshDisabled={balanceRefreshDisabled}
            refreshAnimationId={refreshAnimationId}
            setRefreshAnimationId={setRefreshAnimationId}
            previousRefreshData={previousRefreshData}
            setPreviousRefreshData={setPreviousRefreshData}
            refreshAll={refreshAll}
            setRefreshAll={setRefreshAll}
            setTransactionFilterData={setTransactionFilterData}
            refreshType={refreshType}
            setRefreshType={setRefreshType}
          />
        ) : (
          <NonActiveBanking
            filterPayload={filterPayloadNonActive}
            selectBank={selectBankNonActive}
            setFilterPayload={setFilterPayloadNonActive}
            setSelectBank={setSelectBankNonActive}
            beneOwnerData={beneOwnerDataNonActive}
            beneOwnerLoading={beneOwnerLoadingNonActive}
            smallSearch={smallSearchNonActive}
            setSmallSearch={setSmallSearchNonActive}
            isConnectedSelected={isConnectedSelected}
            setRefreshAnimationId={setRefreshAnimationId}
            removeAccNo={removeAccNoNonActive}
            inputRef1={inputRef1}
          />
        )}
      </div>
    </div>
  );
};

export default OwnerPopup;
