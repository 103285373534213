import React from "react";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";

const IPSupportInfoCard = (props) => {
  return (
    <div
      className="h-[90%] drop-shadow-2xl flex flex-col
      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl
      px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-4"
      style={{
        backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status)
      }}>
      <div className="flex h-1/3">
        <div className="self-center text-gray-300 text-sm 3xl:text-base 5xl:text-lg 6xl:text-2xl font-bold">
          Support Info
        </div>
      </div>
      <div className="h-2/3 flex flex-row">
        {props.txn_id && <div className="self-center w-1/2 flex flex-col">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-white antialiased truncate ...">
            {props.txn_id}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            Transaction ID
          </div>
        </div>}
        {props.settle_id && <div className="self-center w-1/2 flex flex-col">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-white antialiased truncate ...">
            {props.settle_id}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            Settle ID
          </div>
        </div>}
      </div>
    </div>
  );
};

export default IPSupportInfoCard;

