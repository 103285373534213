import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import SearchBar from "../../../components/_utils/SearchBar";
import AllStatusTypeButton from "../../Accounts/BeneficiaryAccount/AllStatusTypeButton";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../redux/features/Settings/Management/Users";
import { checkUsertoken } from "../../../redux/features/login/login";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import noaccount from "../../../assets/Beneficiary Icons/noaccfound.png";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getUserDetailsStateAction } from "../../../redux/features/users/userDetails";

const UsersSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const all_users = useSelector((state) => state.users.users);
  const loading = useSelector((state) => state.users.isLoading);
  const exception = useSelector((state) => state.users.isError);
  const [totalCount, setTotalCount] = useState(false);
  const [usersDetails, setUsersDetails] = useState([]);
  const [searchUser, setSearchUser] = useState({
    searchTerm: "",
    accNoInput: "",
  });
  const [searchUserText, setSearchUserText] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const inputRef1 = useRef();
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  const handleCloseSearchBar = () => {
    setShowSearchBar(false);
    setLoader(true);
    setSearchUser("");
  };

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleUserDetails = (userId) => {
    let userDetailsInfo = false;
    userDetailsInfo = usersDetails.filter((fil) => {
      if (fil.id == userId) {
        return fil;
      }
    });
    if (userDetailsInfo) {
      navigate(`/settings/Users/User_Details/${userId}`);
      dispatch(getUserDetailsStateAction(userDetailsInfo));
      sessionStorage.setItem(
        "userDetailsInfostorage",
        JSON.stringify(userDetailsInfo)
      );
    }
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    let queryParams = {
      page_no: currentPage,
      page_size: "50",
      search_string: searchUser.searchTerm,
    };

    async function accData() {
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(getUsers(queryParams));
        const newData = response?.payload?.employees || [];

        setTotalCount(response?.payload?.total_count);

        if (response?.payload?.employees) {
          setLoader(false);
        }

        setUsersDetails((prevData) => {
          return currentPage === 1 ? newData : [...prevData, ...newData];
        });
      } catch (error) {
        console.log("Error User Setting", error);
      }
    }

    accData();
  }, [searchUser, pageSize, currentPage, showSearchBar]);

  useEffect(() => {
    handleWindowSizeChange();
    sessionStorage.removeItem("userDetailsInfostorage");
  }, []);

  return (
    <>
      <div className={`flex flex-col h-screen`} style={{ overflow: "hidden" }}>
        <div className="user-div-container">
          <div className="user-div-container"></div>
          <div className="user-gradient w-[26%] 3xl:w-[32%] 4xl:w-[37%] 5.6xl:w-[41%]"></div>
          <div className={"user-div"}>
            <div>
              <div className="w-full p-4">
                <div className="user-acc-viewall">
                  <div className="No-of-user-accounts">
                    <span className="user-acc-total-count-symbol">Σ </span>
                    {!totalCount ? "00" : totalCount}
                  </div>
                  <div className="view-count-user">Total users</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="snak-bar"
          style={{
            marginTop: "1rem",
          }}
        >
          <div className="create-user">
            <div className="crt-icon">+</div>
            <div className="crt-label">Create User</div>
          </div>
          <div className="mr-4 bene-search-div">
            <SearchBar
              smallSearch={showSearchBar}
              setSmallSearch={setShowSearchBar}
              inputRef1={inputRef1}
              removeAccNo={handleCloseSearchBar}
              setFilterPayload={setSearchUser}
              filterPayload={searchUser}
              toggleShowSearchBar={() => {
                setShowSearchBar(!showSearchBar);
              }}
              bgColor={"bg-custom-card"}
              bgHover={"hover:bg-custom-cardHover"}
              setRefreshAnimationId={() => null} //wip
            />
          </div>
        </div>
        {loader ? (
          <>
            <div className="loader-container m-auto justify-center items-center flex loader-height">
              <img src={dataLoading} alt="" />
            </div>
          </>
        ) : usersDetails.length != 0 ? (
          <>
            <div id="infinityScrollContainerUserView" className={"opacity-100"}>
              <div
                className={`scroll flex flex-col items-center justify-center w-full`}
              >
                <InfiniteScroll
                  style={{ width: "inherit" }}
                  dataLength={usersDetails.length || []}
                  next={fetchMoreData}
                  hasMore={currentPage < Math.ceil(totalCount / pageSize)}
                  loader={
                    <div className="loader-container m-auto justify-center items-center flex">
                      <img src={dataLoading} alt="" />
                    </div>
                  }
                  scrollableTarget="infinityScrollContainerUserView"
                  scrollThreshold={0.3}
                >
                  <div
                    style={{
                      opacity: "1",
                    }}
                    className="UserCard"
                  >
                    {usersDetails.map((userDtls) => {
                      return (
                        <div
                          key={userDtls?.id}
                          className="user-details-card"
                          onClick={() => {
                            handleUserDetails(userDtls.id);
                          }}
                        >
                          <div className="user-name">
                            {userDtls.first_name + " " + userDtls.last_name}
                          </div>
                          <div className="user-id">{userDtls.user_id}</div>
                          <div className="user-label">User ID</div>
                        </div>
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No accounts found"
                className="bene-acc-no-acc-image"
              />
              <p className="user-not-found">No users found.</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UsersSettings;
