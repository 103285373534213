import React from "react";

const dividerElement = (props) => {
  return (
    <span style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}><hr style={{ width: "100%", borderColor: props.secondary_bg }} /></span>
  );
}

const ReceivedDetailsTxnDetails = (props) => {
  return (
    <div
      className="drop-shadow-2xl flex flex-col justify-between
      gap-4 w-full h-full
      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl
      p-6 2xl:p-8 3xl:p-8 4xl:p-12 5xl:p-16 6xl:p-18"
      style={{
        backgroundColor: props.bgColor
      }}>
      <div className="flex flex-row justify-between gap-32 w-full">
        <div className=" text-gray-300  text-sm 3xl:text-base 5xl:text-lg 6xl:text-2xl font-bold">
          <span className="">Received funds from</span>
        </div>
        <div className="">
          <img className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
            src={require("../../../../assets/TransactionDashboardIcons/arrow_next.png")} />
        </div>
      </div >

      {
        props.clientName ?
          <div className="text-white w-full flex flex-row items-center gap-3">
            <div className="w-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 512 512">
                <path id="Path_2074" data-name="Path 2074" d="M257,224.429A109.714,109.714,0,1,0,147.286,114.714,109.256,109.256,0,0,0,257,224.429Zm0,73.143c-85.211,0-256,42.789-256,128V517H513V425.572C513,340.36,342.211,297.571,257,297.571Z" transform="translate(-1 -5)" fill="#fafafa" />
              </svg>
            </div>
            <div className="text-white w-32 truncate ...">
              {props.clientName}
            </div>
          </div> : null
      }

      {
        props.clientContactNumber ?
          <div className="text-white w-full flex items-center flex-row gap-3">
            <div className="w-4 justify-center mt-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 512 512">
                <g id="_552489" data-name="552489" transform="translate(0 0)">
                  <ellipse id="Ellipse_59" data-name="Ellipse 59" cx="256" cy="256" rx="256" ry="256" transform="translate(0 0)" fill="#fff" />
                  <path id="Path_626" data-name="Path 626" d="M348.054,299.359a23.9,23.9,0,0,0-26.948.071l-11.679,8.016a36.426,36.426,0,0,1-42.812-1.18,399.631,399.631,0,0,1-33.713-29.1,399.181,399.181,0,0,1-29.1-33.713,36.426,36.426,0,0,1-1.18-42.812l8.016-11.679a23.907,23.907,0,0,0,.07-26.948l-33.431-49.267a32.411,32.411,0,0,0-41.793-10.561l-.009,0a62.791,62.791,0,0,0-28.317,81.262,452.176,452.176,0,0,0,76.987,118.682l23.79,23.79A452.23,452.23,0,0,0,326.616,402.91a62.792,62.792,0,0,0,81.262-28.317l0-.009a32.411,32.411,0,0,0-10.561-41.793Z" transform="translate(4.84 3.715)" fill="#9f9f9f" />
                </g>
              </svg>
            </div>
            <div className="justify-center">
              {props.clientContactNumber}
            </div>
          </div> : null
      }
      {
        props.clientEmail ?
          <div className="text-white flex flex-row items-center gap-3">
            <div className="w-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 512 392.778">
                <g id="iconfinder_gmail_4975304" transform="translate(-0.001 0)">
                  <g id="Group_14" data-name="Group 14" transform="translate(33.38 0)">
                    <path id="Path_208" data-name="Path 208" d="M484.973,122.808,452.288,453.324H59.712L33.379,129.205,256,254.723Z" transform="translate(-33.379 -60.548)" fill="#f2f2f2" />
                    <path id="Path_209" data-name="Path 209" d="M473.886,60.983,256,267.1,38.114,60.983H473.886Z" transform="translate(-33.379 -60.983)" fill="#f2f2f2" />
                  </g>
                  <path id="Path_210" data-name="Path 210" d="M59.712,155.8V453.4H24.139A24.229,24.229,0,0,1,0,429.092V111.967l39,1.07Z" transform="translate(0.001 -60.625)" fill="#9f9f9f" />
                  <path id="Path_211" data-name="Path 211" d="M512,111.988V429.115a24.234,24.234,0,0,1-24.154,24.309H452.288V155.82l19.692-46.852Z" transform="translate(0 -60.646)" fill="#9f9f9f" />
                  <path id="Path_212" data-name="Path 212" d="M512,85.292v27.034l-59.712,43.832L256,300.232,59.712,156.158,0,112.326V85.292A24.23,24.23,0,0,1,24.139,60.983H38.114L256,220.909,473.886,60.983h13.962A24.232,24.232,0,0,1,512,85.292Z" transform="translate(0.001 -60.983)" fill="#9f9f9f" />
                  <path id="Path_213" data-name="Path 213" d="M59.712,155.8,0,146.476V111.967Z" transform="translate(0.001 -60.625)" fill="#9f9f9f" />
                </g>
              </svg>
            </div>
            <div>
              {props.clientEmail}
            </div>
          </div> : null
      }
    </div>
  )
}

export default ReceivedDetailsTxnDetails
