import React from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import {
  accountOwnerStatusColor,
  accountOwnerStatusColorRGBA,
} from "../../../../services/approvalStatus";
import CardLogo from "../../../_utils/logo/CardLogo";
import { AccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";
import accTransform from "../../../../utils/accTransform";
import formatCurrency from "../../../../utils/CurrencyFormater";
import timeDifference from "../../../../utils/accountTimeFormat";

const AccountOwnerViewAllCard = (data) => {
  const accountNumber = accTransform(data.account_number, true);
  const subData = data.sub_data;
  const subDataLabel = data.sub_data_label;
  const balance = data.balance ? "₹ " + formatCurrency(data.balance) : "";
  const lastFetchedAt = data.last_fetched_at
    ? timeDifference(new Date(), new Date(data.last_fetched_at * 1000))
    : null;
  const approvalColor = accountOwnerStatusColorRGBA(data.approval_status);
  const color = accountOwnerStatusColor(data.approval_status);
  const bankLogo = bankLogoMapper(data.bank_name);
  const approvalStatusText = AccountStatusTextMapper(
    "owner",
    data.approval_status
  );

  const bottomCardleft = () => {
    if (balance) {
      return (
        <>
          <span className="self-start font-semibold text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl text-[#35AA3D] truncate ...">
            {balance}
          </span>
          <span className="italic text-gray-700 self-start text-sm font-semibold truncate ...">
            {lastFetchedAt}
          </span>
        </>
      );
    } else {
      return (
        <span className="text-gray-700 self-start truncate ...">
          Balance not available
        </span>
      );
    }
  };

  return (
    <div className="h-full w-full flex flex-col bg-white rounded-3xl 4xl:rounded-large drop-shadow-md">
      <div
        className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-md
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden"
      >
        <div className="flex flex-row justify-between -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
          <div
            className="shrink-0 h-full w-3/5 truncate ... font-semibold
            text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased capitalize"
            style={{ color: color }}
          >
            {accountNumber}
          </div>
          <div className="min-h-full text-sm flex flex-row gap-1 justify-end">
            <div className="flex flex-row justify-start">
              <div
                className="h-full w-1.5 4xl:w-2"
                style={{ backgroundColor: color }}
              ></div>
              <div
                className="flex group-hover:px-4"
                style={{ backgroundColor: approvalColor }}
              >
                <span
                  className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                  style={{ color: color }}
                >
                  {approvalStatusText}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <div
            className="w-3/5
            flex flex-col justify-between font-medium text-sm 3xl:text-base 4xl:text-lg 5xl:text-xl 6xl:text-2xl antialiased"
          >
            <span className="text-[#FAFAFA] truncate ...">
              {subData || "Other"}
            </span>
            <span className="text-gray-300 text-xs font-semibold">
              {subDataLabel}
            </span>
          </div>
          <div
            className="w-2/5
            -mr-4 -mb-4
            4xl:-mr-6 4xl:-mb-6
            5xl:-mr-8 5xl:-mb-8
            6xl:-mr-10 6xl:-mb-10
            flex flex-col justify-end"
          >
            <CardLogo bank={bankLogo} />
          </div>
        </div>
      </div>
      <div className="h-16 py-2 px-4 4xl:px-6 5xl:px-8 6xl:px-10">
        <div className="h-12 flex flex-row gap-1 divide-x divide-gray-300">
          <div className="w-[70%] flex flex-col h-full justify-center truncate ...">
            {bottomCardleft()}
          </div>
          <div className="h-full group w-[30%] flex flex-row justify-evenly">
            <span className="text-gray-700 self-center group-hover:text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            <span className="hidden sm:block text-gray-700  group-hover:text-gray-500 self-center text-sm font-semibold truncate ...">
              Refresh
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountOwnerViewAllCard;
