import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import accTransform from "../../../utils/accTransform";
import CurrencyFormater from "../../../utils/CurrencyFormater";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import trimSentence from "../../../utils/trimSentence";

const SettleTxnStatusCard = (props) => {
  const navigate = useNavigate();
  const txnDetails = props?.txnDetails;

  return (
    <>
      <Box className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <Box className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6">
          <div className="flex flex-col gap-2 p-4 mt-4 pb-4">
            <div className="text-2xl 4xl:text-4xl font-semibold text-[#27B882] w-full">
              ₹ {CurrencyFormater(txnDetails?.amount)}
            </div>
            <div className="text-black text-xl 4xl:text-3xl font-semibold capitalize truncate ...">
              {txnDetails?.vendor_name}
            </div>
          </div>

          <Box className="dashedLine"></Box>
          {true ? (
            <Box className="detailsText">
              <div
                className="text-base font-bold 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                antialiased truncate ..."
              >
                {txnDetails?.vendor_bank_acc_no}
              </div>
              <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                Account number
              </div>
              <hr className="simpleLine"></hr>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <div
                    className="text-base font-bold 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                    antialiased truncate ..."
                  >
                    {txnDetails?.vendor_ifsc_code}
                  </div>
                  <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                    IFSC code
                  </div>
                </div>
                <div className="flex flex-row justify-end w-2/6">
                  <img
                    className="w-[70%] self-center"
                    src={require("../../../assets/Banks/" +
                      bankLogoMapper(txnDetails.vendor_bank_name) +
                      ".png")}
                    alt=""
                  />
                </div>
              </div>
            </Box>
          ) : (
            <Box sx={{ padding: "50px 0" }}>
              Waiting for{" "}
              {txnDetails?.approval_status === "P"
                ? "approval"
                : txnDetails?.approval_status === "Y"
                  ? "settlement"
                  : null}
            </Box>
          )}
        </Box>
        <Box className="w-full cursor-pointer divide-x-2 divide-inherit h-[30%] xl:h-[25%] text-white rounded-4xl bg-[#7fb085] flex justify-evenly items-center gap-4">
          {txnDetails?.txn_id && (
            <Box
              onClick={() => {
                if (txnDetails?.module === "FF")
                  navigate(`/ff/txn/${txnDetails?.txn_id}`);
                else navigate(`/vendor/txn/${txnDetails?.txn_id}`);
              }}
              className="logoWithText gap-2 font-base"
              sx={{ width: "100%", alignItems: "center" }}
            >
              <img
                src={require("../../../assets/CreateTransactionIcons/View details.png")}
                alt=""
                srcSet=""
              />
              <Box>
                View transaction <br /> details
              </Box>
            </Box>
          )}
          <Box
            onClick={() => {
              if (txnDetails?.module === "FF")
                navigate("/field_force_transactions");
              else navigate("/vendor_transactions");
            }}
            className="logoWithText gap-2 font-base"
            sx={{
              width: "100%",
              alignItems: "center",
              borderLeft: "1px solid #d3d3d3",
            }}
          >
            <img
              src={require("../../../assets/CreateTransactionIcons/Dashboard.png")}
              alt=""
              srcSet=""
              width="50% !important"
            />
            <Box className="font-base">
              Go to <br /> dashboard
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SettleTxnStatusCard;
