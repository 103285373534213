import React from 'react'

const CorpAddress = ({ address }) => {
    return (
        <div className='h-screen flex flex-col w-full gap-40'>
            <div className='flex justify-between' >
                <div className='flex items-center w-28 h-28 3xl:w-32 3xl:h-32 4xl:w-36 4xl:h-36 6xl:w-40 6xl:h-40' >
                    <img src={require('../../../../assets/Settings/Verification/Group 1438.png')} alt="" />
                </div>
                <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-right w-full
              text-white antialiased truncate ...'>
                    Corporate Address
                </div>
            </div>
            <div className='flex justify-between' >
                <div className='flex justify-between gap-5 w-1/2'>
                    <div className='flex items-center w-7 h-7 3xl:w-8 3xl:h-8 4xl:w-9 4xl:h-9 6xl:w-10 6xl:h-10' >
                        <img src={require('../../../../assets/Settings/Verification/Path 1416.png')} alt="" />
                    </div>
                    <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased' >
                        {address}
                    </div>
                </div>
                <div>
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4436.499425565234!2d73.89161941213118!3d18.539273999581372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c104223d4011%3A0xc331c31a620d8f90!2s421%2C%20Koregaon%20Rd%2C%20Suyojana%20Society%2C%20Kavadewadi%2C%20Koregaon%20Park%2C%20Pune%2C%20Maharashtra%20411001!5e0!3m2!1sen!2sin!4v1690535911965!5m2!1sen!2sin"
                        width="500" height="500" allowfullscreen=""
                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}

export default CorpAddress