import React from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import CardLogo from "../../../_utils/logo/CardLogo";
import { AccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";
import { filterButtonViewAll } from "../../../../services/approvalStatus";
import { useNavigate } from "react-router-dom";

const AccountVendorDetailBannerComponent = (props) => {
  const navigate = useNavigate();
  const accountName = props.account_name;
  const accountType = props.account_type;
  const accountNumber = props.account_number;
  const bankLogo = bankLogoMapper(props.bank_name);
  const approvalStatusText = AccountStatusTextMapper(
    accountType,
    props.approval_status
  );
  const textColor = filterButtonViewAll(props?.approval_status);

  const createTxnButton = () => {
    if (approvalStatusText == "Pending") {
      return (
        <div className="self-center flex flex-row gap-3">
          <div className="self-center text-white flex flex-row gap-2">
            <div
              className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-1 bg-[#FF5860]"
              onClick={() =>
                navigate(
                  `/accounts/${accountType}/transactions/${props.account_number}`
                )
              }
            >
              Decline
            </div>
          </div>
          <div className="self-center text-white flex flex-row gap-2">
            <div
              className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-1 bg-[#35AA3D]"
              onClick={() =>
                navigate(
                  `/accounts/${accountType}/transactions/${props.account_number}`
                )
              }
            >
              Approve
            </div>
          </div>
        </div>
      );
    }
    if (approvalStatusText == "Approved") {
      return (
        <>
          <div className="self-center text-white flex flex-row gap-2">
            <div
              className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-1 bg-[#27B882]"
              onClick={() =>
                navigate(
                  `/create_transaction/vendor_transaction?vendor_id=${props?.account_id}`
                )
              }
            >
              Pay Vendor
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="invisible self-center text-white flex flex-row gap-2">
            <div className="">Invisible</div>
          </div>
        </>
      );
    }
  };

  const fromColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "from-[#0B0F0D]";
      case "Blocked":
        return "from-[#3D2525]";
      case "Archived":
        return "from-[#303030]";
      case "N":
        return "from-[#462E19]";
      case "P":
        return "from-[#304F4F]";
      default:
        return "from-[#303030]";
    }
  };
  const toColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "to-[#3F675A]";
      case "Blocked":
        return "to-[#A65757]";
      case "Archived":
        return "to-[#5A5A5A]";
      case "N":
        return "to-[#BF793C]";
      case "P":
        return "to-[#5D9898]";
      default:
        return "to-[#5A5A5A]";
    }
  };

  return (
    <div className="h-48 w-full drop-shadow-2xl rounded-4xl  flex flex-row overflow-hidden">
      <div
        className={`z-20 bg-white w-[40%] flex flex-col justify-between p-6`}
      >
        <span className=" text-gray-700 font-light text-2xl capitalize antialiased truncate ...">
          {accountName}
        </span>
        <span
          className="text-white text-base font-bold capitalize"
          style={{
            color: textColor,
          }}
        >
          {approvalStatusText}
        </span>
      </div>
      <div
        className={`bg-gradient-to-r ${fromColorGradient()} ${toColorGradient()} h-full w-[60%] flex flex-row`}
      >
        <div className="w-2/5 h-full"></div>
        <div className={`w-3/5 flex flex-col justify-between p-6`}>
          <div className="self-center w-72 truncate ... text-white flex flex-row justify-center gap-2">
            <span className="pb-1 self-end text-xs bg-red font-light">
              A/c No.
            </span>
            <span className="px-1 truncate ... self-end text-xl">
              {accountNumber}
            </span>
          </div>
          <span className="w-24 self-center">
            <CardLogo bank={bankLogo} divCssProps={"py-2 rounded-4xl"} />
          </span>
          {createTxnButton()}
        </div>
      </div>
      <div className="h-56 absolute inset-x-0 bottom-0 transform -rotate-45 -translate-x-28 bg-white"></div>
    </div>
  );
};

export default AccountVendorDetailBannerComponent;
