import React, { useState, useRef, useEffect } from "react";
import { IconButton, Button, Box } from "@mui/material";
import { Close, Height } from "@mui/icons-material";
import cancel from "../../../../assets/Settings/Dashboard/cancel.png";
import NotSelect from "../../../../assets/Approve transactions/not selected.png";
import cancelBtn from "../../../../assets/Beneficiary Icons/close_with_blue_circle.png";
import Selected from "../../../../assets/Approve transactions/selected.png";
import search_icon from "../../../../assets/Approve transactions/search-white.png";
import arrowup from "../../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../../assets/OwnerAccount/arrowdown1.png";
import "./index.css";

const OwnerDetailsSelectTag = (props) => {
  const [tagSearchName, setTagSearchName] = useState("");
  const [icon, setIcon] = useState(arrowup);
  const [selectedTagValue, setSelectedTagValue] = useState([]);
  const [tagSelection, setTagSelection] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const tagInputRef = useRef("");
  const [allTags, setAllTags] = useState([]);
  const handleClose = () => {
    props.onClose();
  };

  let tagselection = [];
  let selectedTagvalue = [];

  useEffect(() => {
    let data = [];
    let newData = [];
    if (props?.ownerExistingTag) {
      data = props?.ownerExistingTag?.map((res) => {
        return { selected: false, value: res, type: "ownerTags" };
      });
    }

    const availableTags = sessionStorage?.getItem("AvailableTags");
    if (availableTags) {
      const parsedTags = JSON.parse(availableTags);
      parsedTags?.forEach((tag) => {
        if (!props?.ownerExistingTag?.includes(tag)) {
          newData?.push({ selected: true, value: tag, type: "existingTag" });
        }
      });
    }

    props?.setNewTags([...data, ...newData]);
  }, []);

  const handleClick = () => {
    setIcon(icon === arrowup ? arrowdown : arrowup);
  };

  const handleConfirmTag = () => {
    let flag1 = false;
    let flag2 = false;
    props.newTags.forEach((tag) => {
      if (tag.selected) {
        flag2 = true;
      } else {
        flag1 = true;
      }
    });
    if (flag1) props?.handleAddTags();
    if (flag2) props?.handleRemoveTags();
  };

  const handleNewTag = () => {
    const tagExists = props?.newTags.some(
      (tag) => tag.value.toLowerCase() === tagSearchName.toLowerCase()
    );
    if (tagExists) {
    } else {
      props?.setNewTags([
        ...props?.newTags,

        { selected: false, value: tagSearchName, type: "newTag" },
      ]);
    }
    setTagSearchName("");
  };
  const handleSelectandDeselect = (selectedTag) => {
    let updateddata = [];
    props?.newTags?.map((res) => {
      if (res.value == selectedTag) {
        updateddata.push({ selected: !res.selected, ...res });
      } else {
        updateddata.push(res);
      }
    });

    props?.setNewTags([...updateddata]);
  };

  useEffect(() => {
    props?.newTags?.forEach((tag) => {
      if (tag.type === "ownerTags") {
        selectedTagvalue.push(tag.value);
      }

      setIsDisabled(true);
    });
    setIsDisabled(true);
  }, [props.newTags]);

  useEffect(() => {
    props.newTags?.forEach((tag) => {
      if (tag.selected == false) {
        tagselection.push(tag.value);
      }
      if (
        tagselection.length > selectedTagvalue.length ||
        tagselection.length < selectedTagvalue.length
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    });
  }, [props.newTags]);

  const handleExistingTag = (selectedTag) => {
    const updatedTags = props?.newTags.map((tag) => {
      if (tag.value === selectedTag && tag.type !== "newTag") {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });
    props?.setNewTags(updatedTags);
  };

  const filteredExistingTags = props?.newTags?.filter((tag) => {
    return (
      (tag.type === "existingTag" || tag.type === "ownerTags") &&
      tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase())
    );
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNewTag();
    }
  };
  return (
    <>
      <div
        className="flex w-full h-full justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit flex flex-col relative mt-10 w-[561px] h-[605px] bg-[#1D3A6D] rounded-2xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-[25px]">
            <div className="flex justify-between">
              <h6 className="text-[#DADADA] pb-1 md:font-bold">Assign tags:</h6>
            </div>
            <div className="tag-partition tag-search mt-5">
              <div
                className="mx-2 my-0"
                onClick={() => tagInputRef.current.focus()}
              >
                <img className="tag-down-search" src={search_icon} alt="" />
              </div>
              <div className="tag-partition-one label-left">
                <input
                  ref={tagInputRef}
                  className="input-custom-search"
                  type="text"
                  value={tagSearchName}
                  placeholder="Search/Enter new tag"
                  onChange={(e) => setTagSearchName(e.target.value)}
                  onKeyDown={handleKeyPress}
                  autoFocus
                />
              </div>
              <div
                className="tag-partition-two"
                onClick={() => tagInputRef.current.focus()}
              >
                {tagSearchName.length > 0 ? (
                  <>
                    <img
                      className="w-3 h-3 cursor-pointer m-auto"
                      alt="cancel"
                      src={cancel}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTagSearchName("");
                      }}
                    />
                  </>
                ) : null}
              </div>
              <div className="border-l-2 border-[#1d3a6d] p-[2px]">
                <Button
                  className={`${
                    tagSearchName === ""
                      ? "btn-custom-primary"
                      : "btn-custom-secondary"
                  }`}
                  disabled={tagSearchName === ""}
                  onClick={() => handleNewTag()}
                >
                  Assign new tag
                </Button>
              </div>
            </div>
            {props?.newTags?.filter((fil) => fil?.type === "newTag")?.length >
              0 && filteredExistingTags ? (
              <>
                <div className="tag-list mt-3">
                  <p>New assigned tags</p>
                </div>
                <div className="flex flex-wrap flex-row">
                  {props?.newTags?.length > 0 &&
                    props?.newTags
                      ?.filter((fil) => fil?.type === "newTag")
                      ?.map((tag) => {
                        return (
                          <>
                            <Box
                              className="tag-box-item relative"
                              key={tag?.value}
                              style={{
                                backgroundColor: tag.selected ? "" : "#4A6D4B",
                              }}
                              onClick={() => handleSelectandDeselect(tag.value)}
                            >
                              <div>
                                <>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "12px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {tag.value}
                                  </span>

                                  <span className="w-[17px] h-[17]">
                                    {tag.selected ? (
                                      <img
                                        className=""
                                        src={NotSelect}
                                        alt=""
                                      />
                                    ) : (
                                      <img className="" src={Selected} alt="" />
                                    )}
                                  </span>
                                </>
                              </div>
                            </Box>
                          </>
                        );
                      })}
                </div>
              </>
            ) : null}

            <div className="tag-list mt-3 ">
              <p>Existing tags</p>
            </div>
            {props?.newTags?.filter(
              (fil) => fil?.type === "existingTag" || fil?.type === "ownerTags"
            )?.length > 0 && filteredExistingTags?.length > 0 ? (
              <div className="overflow-y-scroll max-h-[22rem]">
                <div className="flex flex-wrap ">
                  {props?.newTags?.length > 0 &&
                    props?.newTags &&
                    filteredExistingTags
                      ?.filter(
                        (fil) =>
                          fil?.type === "existingTag" ||
                          fil?.type === "ownerTags"
                      )
                      ?.map((tag) => {
                        return (
                          <>
                            <Box
                              key={tag?.value}
                              className={"px-4 tag-box-item relative"}
                              style={{
                                backgroundColor:
                                  tag.selected == false ? "#4A6D4B" : "",
                              }}
                              onClick={() => handleExistingTag(tag.value)}
                            >
                              <div>
                                <>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      fontSize: "12px",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {tag.value}
                                  </span>

                                  <span className="w-[17px] h-[17]">
                                    {tag.selected == false ? (
                                      <img className="" src={Selected} alt="" />
                                    ) : (
                                      <img
                                        className=""
                                        src={NotSelect}
                                        alt=""
                                      />
                                    )}
                                  </span>
                                </>
                              </div>
                            </Box>
                          </>
                        );
                      })}
                </div>
              </div>
            ) : (
              <div className="existing-tag mt-3">
                {
                  <p>
                    No existing tags found. <br />
                    To assign a tag, Kindly enter a new tag.
                  </p>
                }
              </div>
            )}
          </div>
          {icon == arrowdown ? (
            <div
              className="tag-bottom-expand"
              style={{
                display: `${
                  props?.newTags?.filter((fil) => !fil.selected).length > 0
                    ? ""
                    : "none"
                }`,
              }}
            >
              {props?.newTags?.map((tag) => {
                return (
                  <>
                    {!tag.selected && (
                      <>
                        <Box className=" px-4 tag-box-item relative" key={tag?.value}>
                          <div className=" flex items-center ">
                            <>
                              <span
                                style={{
                                 
                                  fontSize: "12px",
                                  paddingRight: "5px",
                                }}
                              >
                                {tag.value}
                              </span>

                              <span className="w-[17px] h-[17]">
                                <img
                                  className=""
                                  src={cancelBtn}
                                  alt=""
                                  onClick={() => handleExistingTag(tag.value)}
                                />
                              </span>
                            </>
                          </div>
                        </Box>
                      </>
                    )}
                  </>
                );
              })}
            </div>
          ) : null}
          <div
            className="tag-bottom"
            style={{
              borderRadius: icon == arrowdown ? "0 0 16px 16px" : "16px",
            }}
          >
            <div className="flex p-[18px] ">
              <div className=" bg-[#102C5D] text-[#FFFFFF] flex justify-center items-center rounded-[50%] text-[14px] w-[40px] h-[40px]">
                <p className=" text-[20px]">
                  {props?.newTags?.filter((fil) => !fil.selected)?.length}
                </p>
              </div>
              <span className="  items-center mt-[14px] ml-[10px]  text-[16px] font-normal leading-[14px] text-[#F9F9F9]">
                Tags assigned
              </span>

              <div className="show-btn">
                <div className="show_overbtn" onClick={handleClick}>
                  <div style={{ flex: "80%" }}>
                    {icon == arrowup ? "Show" : "Hide"}
                  </div>
                  <div style={{ flex: "40%" }}>
                    <img src={icon} alt="Status Icon" className="tagup_icon" />
                  </div>
                </div>
              </div>
              <div className="border-l-2 border-[#102C5D] p-[2px]"></div>
              <div className="main-btn">
                <div className="cancel_overbtn" onClick={handleClose}>
                  Cancel
                </div>
                <div
                  className="confirm_overbtn"
                  disabled={isDisabled}
                  style={{
                    backgroundColor: isDisabled ? "#707070" : "  #69B76F",
                    cursor: isDisabled ? "  default" : " pointer",
                  }}
                  onClick={() => !isDisabled && handleConfirmTag()}
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDetailsSelectTag;
