import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import accTransform from "../../../utils/accTransform";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";
import CardLogo from "../../_utils/logo/CardLogo";

const PayMethod = (props) => {
  return (
    <Box
      className="h-full drop-shadow-xl
        rounded-4xl px-6 py-4
        flex flex-col overflow-hidden"
      sx={{
        backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status)
      }}>
      <div className="h-[30%] flex flex-row justify-between">
        <div className="self-center text-gray-300 text-sm font-bold">
          Payment Method
        </div>
      </div>
      {
        props.module === "Internal" ?
          <div className="h-[70%] flex">
            <div className="self-center text-base
           text-white antialiased truncate ...">
              Internal Fund Transfer
            </div>
          </div>
          :
          <div className="h-[30%] flex">
            <div className="self-center text-base
           text-white antialiased truncate ...">
              {
                props.bank_acc_no !== "Instant Pay" && props.bank_acc_no !== "Net Banking" ? `•••• •••• ${(accTransform(props.bank_acc_no))}` : props.bank_acc_no
              }
            </div>
          </div>
      }
      {
        props.module !== "Internal" &&
        <div className="h-[40%] flex flex-row justify-between">
          <div className="self-center w-3/5 text-base
          text-white antialiased truncate ...">
            {props.name ? props.name : " "}
          </div>
          <div className="w-2/5
          -mr-8 -mb-4
          flex flex-col justify-end">
            <CardLogo bank={props?.bank_logo}
              divCssProps={
                'rounded-tl-4xl'
              }
            />
          </div>
        </div>
      }
    </Box>
  );
};
export default PayMethod;
