import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { Link } from "react-router-dom";
import AccountVendorViewAllCard from "../../../../components/Accounts/Vendor/ViewAllCard";
import { approvalStatus } from "../../../../services/approvalStatus";

const AccountVendorViewAllBody = ({ viewAllData }) => {
  const emptyAccountListComponent = () => {
    return (
      <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
        <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
          No accounts found <br />
          All vendor accounts will appear here.
        </div>
        <div className="w-2/6 p-4 flex flex-row justify-center">
          <img
            className="h-full w-full"
            src={NoDataImg} alt="NoDataImg" />
        </div>
      </div>
    );
  }

  const approvalColor = (key) => {
    if (key === "Y") {
      return "#4DDD37";
    } else if (key === "N") {
      return "#fb923c";
    } else if (key === "Blocked") {
      return "#FF5860";
    } else if (key === "Archived") {
      return "#B4B4B4";
    } else if (key === "P") {
      return "#FBC02D";
    }
  }
  const accountViewAllComponents = () => {
    if (Object.keys(viewAllData).length === 0) {
      return emptyAccountListComponent();
    } else {
      return (
        <div className="
          grid gap-4 lg:gap-6 4xl:gap-16
          grid-cols-1
          md:grid-cols-2
          xl:grid-cols-4
          4xl:grid-cols-5
          6xl:grid-cols-6
          justify-items-center">
          {
            viewAllData.map((account) => (
              <div className="w-full min-h-full">
                <Link
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    window.location.href = `/accounts/vendor/details/${account.vendor_id}`;
                  }}>
                  <AccountVendorViewAllCard
                    account_name={account.name}
                    sub_data={account.bank_acc_no}
                    sub_data_label="Account Number"
                    bank_name={account.bank_name}
                    approval_status={account.approval_status}
                  />
                </Link>
              </div>
            ))
          }
        </div>
      );
    }
  }

  return accountViewAllComponents();
}

export { AccountVendorViewAllBody };

