import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast_position } from "../../Settings/Authorization";
import { toast } from "react-toastify";
import axios from "axios";
import { env } from "../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneVerifyThisPAN = createAsyncThunk(
  "beneVerifyThisPAN",
  async (values) => {
    const { pan_number } = values ? values : { pan_number: null };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        pan: pan_number || values?.search_string,
      }),
    };

    return await fetch(`${baseUrl}/verification/pan/verify`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const benePANLookUp = createAsyncThunk(
  "benePANLookUp",
  async (values) => {
    const { pan_number } = values ? values : { pan_number: null };

    try {
      const response = await axios.get(
        `${baseUrl}/accounts/beneficiary/lookup`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            pan: pan_number,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const beneVerifyThisBank = createAsyncThunk(
  "beneVerifyThisBank",
  async (values, { rejectWithValue }) => {
    const { bank_account, ifsc_code, legal_name } = values;

    try {
      const response = await axios.post(
        `${baseUrl}/verification/bank_account/verify`,
        { bank_account, ifsc_code, legal_name },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.errors?.base[0] || "An error occurred"
      );
    }
  }
);

export const beneVerifyThisIFSC = createAsyncThunk(
  "beneVerifyThisIFSC",
  async (values) => {
    try {
      const response = await axios.get(
        `${baseUrl}/verification/ifsc/${values?.search_string}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            page_no: values?.page_no,
            page_size: values?.page_size,
          },
        }
      );

      if (response.data?.errors) {
        toast.error(response.data.errors.base[0], toast_position);
      }

      return response.data;
    } catch (error) {
      console.error("Error", error);
      throw error?.response?.data?.errors?.base[0];
    }
  }
);

export const beneBankLookUp = createAsyncThunk(
  "beneBankLookUp",
  async (values) => {
    const { bank_account_no, ifsc } = values
      ? values
      : { bank_account_no: null, ifsc: null };

    try {
      const response = await axios.get(
        `${baseUrl}/accounts/beneficiary/lookup`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
          params: {
            bank_account_no: bank_account_no,
            ifsc: ifsc,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const beneUnachivedAccount = createAsyncThunk(
  "beneUnachivedAccount",
  async (values) => {
    const { id } = values ? values : { id: null };
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/unarchive`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const beneVerifyThisGST = createAsyncThunk(
  "beneVerifyThisGST",
  async (values) => {
    const { gstin } = values ? values : { gstin: null };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        gstin: gstin || values?.search_string,
      }),
    };

    return await fetch(`${baseUrl}/verification/gstin/verify`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const beneVerifyThisCIN = createAsyncThunk(
  "beneVerifyThisCIN",
  async (values) => {
    try {
      const response = await axios.get(
        `${baseUrl}/verification/cin/` + values?.search_string,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      if (response.data?.errors) {
        toast.error(response.data.errors.base[0], toast_position);
      }

      return response.data;
    } catch (error) {
      toast.error(error, toast_position);
      console.error("Error", error);
      throw error;
    }
  }
);

export const beneVerifyThisDIN = createAsyncThunk(
  "beneVerifyThisDIN",
  async (values) => {
    try {
      const response = await axios.get(
        `${baseUrl}/verification/din/` + values?.search_string,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      if (response.data?.errors) {
        toast.error(response.data.errors.base[0], toast_position);
      }

      return response.data;
    } catch (error) {
      console.error("Error", error);
      throw error;
    }
  }
);

export const beneCategories = createAsyncThunk(
  "beneCategories",
  async (values) => {
    try {
      const response = await axios.get(`${baseUrl}/transaction_categories`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error", error);
      throw error;
    }
  }
);

export const beneCreateNewAccount = createAsyncThunk(
  "beneCreateNewAccount",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/accounts/beneficiary`,
        values,
        {
          headers: {
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error creating account:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const beneViewAllAcc = createAsyncThunk(
  "beneViewAllAcc",
  async (values) => {
    const {
      page_no,
      page_size,
      approval_status,
      categoryName,
      search_string,
      bank_account_no,
      ifsc,
      start_date,
      end_date,
      pan,
    } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status: approval_status,
          category: categoryName,
          search_string: search_string,
          start_date,
          end_date: start_date,
          end_date,
          bank_account_no: bank_account_no,
          ifsc: ifsc,
          pan: pan,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);

export const beneViewAllAprroved = createAsyncThunk(
  "beneViewAllAprroved",
  async (_, { getState }) => {
    const { approval_status } = getState().beneAllVerification;
    try {
      const response = await axios.get(`${baseUrl}/accounts/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          approval_status: approval_status,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const beneDashboard = createAsyncThunk(
  "beneDashboard",
  async ({ page, page_size }) => {
    try {
      const response = await axios.get(`${baseUrl}/accounts/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page,
          page_size: page_size,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);

export const beneRequiredApproval = createAsyncThunk(
  "beneRequiredApproval",
  async (values) => {
    const { page_no } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          approval_status: "pending",
          page_no: page_no,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const beneWaitingApproved = createAsyncThunk(
  "beneWaitingApproved",
  async (id) => {
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/approve`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const beneDeniedAccount = createAsyncThunk(
  "beneDeniedAccount",
  async ({ id, AccountIdData }) => {
    const data = {
      rejection_reason: AccountIdData,
    };

    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(data),
    };

    return await fetch(
      `${baseUrl}/accounts/beneficiary/${id}/reject`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.log("error", error);
      });
  }
);

export const beneDetailDenyAcc = createAsyncThunk(
  "beneDetailDenyAcc",
  async ({ id, denialReason }) => {
    const data = {
      rejection_reason: denialReason,
    };
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(
        `${baseUrl}/accounts/beneficiary/${id}/deny`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to deny account");
      }
      const responseData = await response.json();
      toast.success("Account denied successfully", toast_position);
      return responseData;
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to deny account", toast_position);
      throw error;
    }
  }
);

export const beneVerifyThisBankNames = createAsyncThunk(
  "beneVerifyThisBankNames",
  async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/verification/bank/bank_list/`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );

      if (response.data?.errors) {
        toast.error(response.data.errors.base[0], toast_position);
      }

      return response.data;
    } catch (error) {
      console.error("Error", error);
      throw error;
    }
  }
);

const beneAllVerification = createSlice({
  name: "beneAllVerification",
  initialState: {
    storedPage: 1,
    toggle: false,

    isPANError: false,
    isBankError: false,
    isIFSCError: false,
    isCINError: false,
    isDINError: false,
    isTransCateError: false,
    isCreateAccountError: false,
    isError: false,
    status: "idle",
    isGSTError: false,
    isPANLoading: false,
    isCINLoading: false,
    isDINLoading: false,
    isGSTLoading: false,
    isTransCateLoading: false,
    isCreateAccountLoading: false,
    beneVerifyPAN: null,
    verifyBank: null,
    bankMessage: "",
    verifyIFSC: null,
    beneVerifyGST: null,
    beneVerifyCIN: null,
    beneVerifyDIN: null,
    beneLookData: null,
    beneLookLoading: null,
    beneLookError: null,

    benePanLookData: null,
    benePanLookLoading: null,
    benePanLookError: null,

    beneunachivedData: null,
    beneunachivedLoading: null,
    beneunachivedError: null,
    beneTransCategories: {
      categories: [],
    },
    beneViewAllAccData: null,
    beneViewAllAccLoading: null,
    beneViewAllAccError: null,

    beneDashboardAccData: null,
    beneDashboardAccLoading: null,
    beneDashboardAccError: null,

    beneRequiredApprovalData: null,
    beneRequiredApprovalLoading: null,
    beneRequiredApprovalError: null,

    beneWaitingApprovalData: null,
    beneWaitingApprovalLoading: null,
    beneWaitingApprovalError: null,

    beneDeniedApprovalData: null,
    beneDeniedApprovalLoading: null,
    beneDeniedApprovalError: null,

    beneDetailDenyAccData: null,
    beneDetailDenyAccLoading: null,
    beneDetailDenyAccError: null,

    beneVerifyThisBankNamesData: [],
    beneVerifyThisBankNamesLoading: true,
    beneVerifyThisBankNamesError: null,
  },
  reducers: {
    resetVerification(state) {
      state.beneVerifyPAN = null;
      state.verifyBank = null;
    },
    resetBeneBankData(state) {
      state.beneLookData = null;
    },
    resetPANVerification(state) {
      state.beneVerifyPAN = null;
    },
    resetBankVerification(state) {
      state.verifyBank = null;
    },
    resetBankError(state) {
      state.isBankError = null;
    },
    resetpage: (state) => {
      state.storedPage = 1;
    },
    setStoredPage: (state, action) => {
      state.storedPage = action.payload;
    },
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
    resetToggle: (state) => {
      state.toggle = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(beneDeniedAccount.pending, (state, action) => {
      state.beneDeniedApprovalLoading = true;
    });
    builder.addCase(beneDeniedAccount.fulfilled, (state, action) => {
      state.beneDeniedApprovalLoading = false;
      state.beneDeniedApprovalData = action.payload;
    });
    builder.addCase(beneDeniedAccount.rejected, (state, action) => {
      state.beneDeniedApprovalError = true;
      state.beneDeniedApprovalData = null;
      state.beneDeniedApprovalLoading = false;
    });

    builder.addCase(beneDetailDenyAcc.pending, (state, action) => {
      state.beneDetailDenyAccLoading = true;
    });
    builder.addCase(beneDetailDenyAcc.fulfilled, (state, action) => {
      state.beneDetailDenyAccLoading = false;
      state.beneDetailDenyAccData = action.payload;
    });
    builder.addCase(beneDetailDenyAcc.rejected, (state, action) => {
      state.beneDetailDenyAccError = true;
      state.beneDetailDenyAccData = null;
      state.beneDetailDenyAccLoading = false;
    });

    builder.addCase(beneWaitingApproved.pending, (state, action) => {
      state.beneWaitingApprovalLoading = true;
    });
    builder.addCase(beneWaitingApproved.fulfilled, (state, action) => {
      state.beneWaitingApprovalLoading = false;
      state.beneWaitingApprovalData = action.payload;
    });
    builder.addCase(beneWaitingApproved.rejected, (state, action) => {
      state.beneWaitingApprovalError = true;
      state.beneWaitingApprovalData = null;
      state.beneWaitingApprovalLoading = false;
    });
    builder.addCase(beneRequiredApproval.pending, (state, action) => {
      state.beneRequiredApprovalLoading = true;
    });
    builder.addCase(beneRequiredApproval.fulfilled, (state, action) => {
      state.beneRequiredApprovalLoading = false;
      state.beneRequiredApprovalData = action.payload;
    });
    builder.addCase(beneRequiredApproval.rejected, (state, action) => {
      state.beneRequiredApprovalError = true;
      state.beneRequiredApprovalData = null;
    });

    builder.addCase(beneDashboard.pending, (state, action) => {
      state.beneDashboardAccLoading = true;
    });
    builder.addCase(beneDashboard.fulfilled, (state, action) => {
      state.beneDashboardAccLoading = false;
      state.beneDashboardAccData = action.payload;
    });
    builder.addCase(beneDashboard.rejected, (state, action) => {
      state.beneDashboardAccError = true;
      state.beneDashboardAccData = null;
    });
    builder.addCase(beneViewAllAcc.pending, (state, action) => {
      state.beneViewAllAccLoading = true;
    });
    builder.addCase(beneViewAllAcc.fulfilled, (state, action) => {
      state.beneViewAllAccLoading = false;
      state.beneViewAllAccData = action.payload;
    });
    builder.addCase(beneViewAllAcc.rejected, (state, action) => {
      state.beneViewAllAccError = true;
      state.beneViewAllAccData = null;
    });
    builder.addCase(beneUnachivedAccount.pending, (state, action) => {
      state.beneunachivedLoading = true;
    });
    builder.addCase(beneUnachivedAccount.fulfilled, (state, action) => {
      state.beneunachivedLoading = false;
      state.beneunachivedData = action.payload;
    });
    builder.addCase(beneUnachivedAccount.rejected, (state, action) => {
      state.beneunachivedError = true;
    });
    builder.addCase(beneBankLookUp.pending, (state, action) => {
      state.beneLookLoading = true;
      state.beneLookError = false;
    });
    builder.addCase(beneBankLookUp.fulfilled, (state, action) => {
      state.beneLookLoading = false;
      state.beneLookData = action.payload;
    });
    builder.addCase(beneBankLookUp.rejected, (state, action) => {
      state.beneLookError = true;
      state.beneLookData = null;
      state.beneLookLoading = false;
    });
    builder.addCase(benePANLookUp.pending, (state, action) => {
      state.benePanLookLoading = true;
    });
    builder.addCase(benePANLookUp.fulfilled, (state, action) => {
      state.benePanLookLoading = false;
      state.benePanLookData = action.payload;
    });
    builder.addCase(benePANLookUp.rejected, (state, action) => {
      state.benePanLookError = true;
      state.benePanLookData = null;
    });
    builder.addCase(beneVerifyThisPAN.pending, (state, action) => {
      state.verifyPANisLoading = true;
    });
    builder.addCase(beneVerifyThisPAN.fulfilled, (state, action) => {
      state.isPANLoading = false;
      state.beneVerifyPAN = action.payload;
    });
    builder.addCase(beneVerifyThisPAN.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(beneVerifyThisBank.pending, (state, action) => {
      state.verifyBankisLoading = true;
    });
    builder.addCase(beneVerifyThisBank.fulfilled, (state, action) => {
      state.verifyBankisLoading = false;
      state.isBankError = false;
      state.verifyBank = action.payload;
      state.bankMessage = "";
    });
    builder.addCase(beneVerifyThisBank.rejected, (state, action) => {
      state.isBankError = true;
      state.verifyBank = null;
      state.verifyBankisLoading = false;
      state.bankMessage = action.payload;
    });
    builder.addCase(beneVerifyThisCIN.pending, (state, action) => {
      state.isCINLoading = true;
    });
    builder.addCase(beneVerifyThisCIN.fulfilled, (state, action) => {
      state.isCINLoading = false;
      state.verifyCIN = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload?.errors.base[0], toast_position);
    });
    builder.addCase(beneVerifyThisCIN.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(beneVerifyThisDIN.pending, (state, action) => {
      state.isDINLoading = true;
    });
    builder.addCase(beneVerifyThisDIN.fulfilled, (state, action) => {
      state.isDINLoading = false;
      state.verifyDIN = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(beneVerifyThisDIN.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(beneVerifyThisGST.pending, (state, action) => {
      state.isGSTLoading = true;
      state.status = "loading";
    });
    builder.addCase(beneVerifyThisGST.fulfilled, (state, action) => {
      state.isGSTLoading = false;
      state.beneVerifyGST = action.payload;
      state.status = "fulfilled";
    });
    builder.addCase(beneVerifyThisGST.rejected, (state, action) => {
      state.isGSTError = true;
      state.status = "rejected";
    });
    builder.addCase(beneVerifyThisIFSC.pending, (state, action) => {
      state.verifyIFSCisLoading = true;
    });
    builder.addCase(beneVerifyThisIFSC.fulfilled, (state, action) => {
      state.verifyIFSCisLoading = false;
      state.verifyIFSC = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(beneVerifyThisIFSC.rejected, (state, action) => {
      state.isError = action;
    });
    builder.addCase(beneCategories.pending, (state, action) => {
      state.isTransCateLoading = true;
    });
    builder.addCase(beneCategories.fulfilled, (state, action) => {
      state.isTransCateLoading = false;
      state.beneTransCategories = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(beneCategories.rejected, (state, action) => {
      state.isTransCateError = true;
    });
    builder.addCase(beneCreateNewAccount.pending, (state, action) => {
      state.isCreateAccountLoading = true;
      state.isCreateAccountError = null;
    });
    builder.addCase(beneCreateNewAccount.fulfilled, (state, action) => {
      state.isCreateAccountLoading = false;
      state.createdAccount = action.payload;
    });
    builder.addCase(beneCreateNewAccount.rejected, (state, action) => {
      state.isCreateAccountLoading = false;
      state.isCreateAccountError = action.error.message;
    });
    builder.addCase(beneVerifyThisBankNames.pending, (state, action) => {
      state.beneVerifyThisBankNamesLoading = true;
    });
    builder.addCase(beneVerifyThisBankNames.fulfilled, (state, action) => {
      state.beneVerifyThisBankNamesLoading = false;
      state.beneVerifyThisBankNamesData = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(beneVerifyThisBankNames.rejected, (state, action) => {
      state.beneVerifyThisBankNamesError = true;
    });
  },
});

export const {
  resetVerification,
  resetPANVerification,
  resetBankVerification,
  resetBeneBankData,
  resetBankError,
  resetpage,
  setStoredPage,
  resetToggle,
  setToggle,
} = beneAllVerification.actions;

export default beneAllVerification.reducer;
