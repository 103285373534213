import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const CheckUserLogin = () => {
  let loggedIn = window.sessionStorage.getItem("loggedIn");

  return !loggedIn ? <Outlet /> : <Navigate to="/home" />;
};

export default CheckUserLogin;
