import React, { useEffect, useRef } from "react";
import clear_filter from "../../../../assets/ViewAllTransactions/Clear filter.png";
import arrowup from "../../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../../assets/OwnerAccount/arrowdown1.png";
import "./index.css";
import allChecker from "../../../../assets/Beneficiary Icons/whitecheckmark.png";

const AllStatusTypeButton = ({
  getAccStatus,
  selectedStatus,
  setPage,
  isStatusSelected,
  setIsStatusSelected,
  isPopoverOpen,
  setIsPopoverOpen,
  setShowSearchBar,
}) => {
  const popoverRefAcc = useRef(null);

  const handleClick = () => {
    setShowSearchBar(false);
    setIsPopoverOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const handleClearStatus = (event) => {
    if (event) event.stopPropagation();
    handleClose();
    getAccStatus(null, "status");
    getAccStatus(null, "approval");
    setIsStatusSelected(false);
    setPage(1);
  };

  const handleStatus = (status, state) => {
    handleClose();

    let statusToSend;

    if (status === "rejected") {
      statusToSend = "rejected";
    } else if (status === "awaiting verification") {
      statusToSend = "approved";
    } else {
      statusToSend = status;
    }
    getAccStatus(statusToSend, "status");

    setPage(1);
  };

  const getIcon = () => {
    let iconSrc = null;
    let iconClickHandler = null;

    if (isPopoverOpen) {
      iconSrc = arrowup;
      iconClickHandler = handleClick;
    } else {
      if (
        !isStatusSelected ||
        selectedStatus === null ||
        selectedStatus === "All"
      ) {
        iconSrc = arrowdown;
        iconClickHandler = handleClick;
      } else {
        iconSrc = clear_filter;
        iconClickHandler =
          selectedStatus !== "All" ? handleClearStatus : undefined;
      }
    }

    return { src: iconSrc, onClick: iconClickHandler };
  };

  const { src: icon, onClick: iconClickHandler } = getIcon();

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      case "all":
        return "#ffff";
      case "settled":
        return "#4ddd37";
      default:
        return "#ffff";
    }
  };

  const formatStatusText = (status) => {
    return `${status[0]?.toUpperCase()}${status?.slice(1)?.toLowerCase()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRefAcc.current &&
        !popoverRefAcc.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={popoverRefAcc}
      className="beneTxnViewAllPopover_acc"
      style={{
        opacity: 1,
        cursor: "pointer",
      }}
      onClick={() => {
        if (isPopoverOpen === true) {
          setIsPopoverOpen(false);
        }
      }}
    >
      <div
        className="benTxnViewall_inner_div_acc"
        style={{
          opacity: 1,
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <button
          id="bene_txn_popover_section_acc"
          style={{
            color: getStatusColor(selectedStatus),
          }}
        >
          {selectedStatus === "rejected"
            ? "Rejected"
            : selectedStatus === "approved"
            ? "Approved"
            : formatStatusText(selectedStatus || "All")}
          <p className="beneTxnViewAllchild2_acc">Approval Status</p>
        </button>
        <div
          className="bene_viewall_popover_icon_acc p-[4px]"
          onClick={(event) => {
            if (iconClickHandler) {
              event.stopPropagation();
              iconClickHandler();
            }
          }}
        >
          {icon && (
            <img
              src={icon}
              alt={
                selectedStatus === "All" ? "Arrow Icon" : "Clear Filter Icon"
              }
              className={`bene_txn_status_icon_acc ${
                selectedStatus === "All" ? "arrow-icon" : "cancel-icon"
              }`}
            />
          )}
        </div>
      </div>

      <div
        className={`bene-txn-viewall-popover_acc ${
          isPopoverOpen ? "open" : "closed"
        }`}
      >
        {["all", "approved", "pending", "rejected", "blocked", "archived"].map(
          (status) => (
            <div
              className={`popover_acc ${
                status === "awaiting verification"
                  ? "awaiting_verification_bene_txn_popover_btn_acc w-[212px] h-[33px] hover:bg-[#3A5686] rounded-[5px] flex items-center mt-[5px] ml-[2px]"
                  : `${status}_bene_txn_popover_btn_acc w-[206px] h-[30px] hover:bg-[#3A5686] rounded-[5px] flex items-center  justify-between mt-[5px] ml-[10px]`
              }`}
              key={status}
              style={{
                visibility: isPopoverOpen ? "visible" : "hidden",
                color: getStatusColor(status),
                backgroundColor:
                  selectedStatus === status && selectedStatus !== "all"
                    ? "#3A5686"
                    : "",
                width: "212px",
                padding: "0px 10px",
              }}
              onClick={() => handleStatus(status)}
            >
              {status === selectedStatus ||
              (status === "rejected" && selectedStatus === "rejected") ||
              (status === "awaiting verification" &&
                selectedStatus === "approved") ? (
                <>
                  {formatStatusText(status)}
                  <img
                    src={allChecker}
                    alt="Checkmark"
                    className="all-txn-status-icon_acc"
                  />
                </>
              ) : (
                formatStatusText(status)
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AllStatusTypeButton;
