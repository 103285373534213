import React, { useEffect } from "react";
import formatCurrency from "../../../utils/CurrencyFormater";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import trimSentence from "../../../utils/trimSentence";
import { approvalStatus, approvalStatusRGBA } from "../../../services/approvalStatus";
import { VendorTxnTextMapper } from "../../../services/VendorTxnTextMapper";
import formatDateViewAll from "../../../utils/formatDateViewAll";
import CardLogo from "../../_utils/logo/CardLogo";

const InstantViewAllDataCard = (props) => {
    const amountProp = formatCurrency(props.vendorTxnData.amount);
    const propStatus = approvalStatus(props.vendorTxnData.approval_status);
    const cardStatusRGBA = approvalStatusRGBA(props.vendorTxnData.approval_status);

    let payMethodLogo = props?.vendorTxnData.settlement_type === "HDFC NEFT/RTGS"
        ? bankLogoMapper(props?.vendorTxnData.owner_bank_name)
        : props?.vendorTxnData.settlement_type === "Instant Pay"
            ? "Instant Pay"
            : props?.vendorTxnData.settlement_type === "Registered Card"
                ? props?.vendorTxnData.card_type || "Default Card"
                : "Status - pending";

    return (
        <>
            <div className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-xl
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden">
                <div className="flex flex-row -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
                    <div className="font-semibold
            w-3/5 text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased"
                        style={{ color: propStatus }}>
                        {`₹ ${amountProp}`}
                    </div>
                    <div className="w-2/5 text-sm flex flex-row gap-1
            justify-end">
                        <div className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased group-hover:invisible group-hover:hidden">
                            {formatDateViewAll(props.vendorTxnData.posting_date, false)}
                        </div>
                        <div className="flex flex-row justify-start">
                            <div className="w-1.5 4xl:w-2"
                                style={{ backgroundColor: propStatus }}>
                            </div>
                            <div className="flex group-hover:px-4"
                                style={{ backgroundColor: cardStatusRGBA }}>
                                <span className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                                    style={{ color: propStatus }}>
                                    {VendorTxnTextMapper(props.vendorTxnData.approval_status) === "Approved" ? "Created" : VendorTxnTextMapper(props.vendorTxnData.approval_status)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="w-3/5
            flex text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased">
                        <span className="self-center text-white truncate capitalize">
                            {trimSentence(props.txn_initiator, 15)}
                        </span>
                    </div>
                    <div className="w-2/5
            -mr-4 -mb-4
            4xl:-mr-6 4xl:-mb-6
            5xl:-mr-8 5xl:-mb-8
            6xl:-mr-10 6xl:-mb-10
            flex flex-col justify-end">
                        <CardLogo bank={payMethodLogo} />
                    </div>
                </div>
            </div >
        </>
    );
};

export default InstantViewAllDataCard;