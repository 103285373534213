import { Box } from "@mui/material";
import "./index.css";
import formatCurrency from "../../utils/CurrencyFormater";
import { VendorTxnDetailChildBgMapper } from "../../services/VendorTxnDetailBgMapper";

const TxnInfo = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status),
      }}
      className="txninfo-container"
    >
      <Box className="heading">
        <Box className="heading-text">
          Transaction <br /> amount
        </Box>
        <Box className="value-text">
          {" "}
          ₹ {formatCurrency(props.data.amount ? props.data.amount : "0")}
        </Box>
      </Box>
      <Box
        className=""
        sx={{
          height: "1px",
          background:
            "repeating-linear-gradient(to left,transparent,transparent 15px,#BCBBBB 15px, #BCBBBB 30px)",
          width: "100%",
          margin: "5% 0",
        }}
      ></Box>
      <Box className="heading">
        <Box className="heading-text">
          Billing <br /> amount
        </Box>
        <Box className="value-text">
          {" "}
          ₹{" "}
          {formatCurrency(
            props.data?.swipable_amount ? props.data.swipable_amount : "0"
          )}
        </Box>
      </Box>
      <Box sx={{ color: "red", rotate: "90deg" }} className="heading">
        {" "}
        |{" "}
      </Box>
      <Box className="heading">
        <Box className="heading-text">
          Bank charges<br></br>(incl GST)
        </Box>
        <Box className="value-text">
          {" "}
          ₹{" "}
          {formatCurrency(
            props.data?.payment_charge ? props.data.payment_charge : "0"
          )}
        </Box>
      </Box>
      <Box sx={{ color: "red", rotate: "90deg" }} className="heading">
        {" "}
        |{" "}
      </Box>
      <Box className="heading">
        <Box className="heading-text">
          Bank fee <br></br>(incl GST){" "}
        </Box>
        <Box className="value-text">
          ₹{" "}
          {formatCurrency(
            props.data?.recipient_fee ? props.data.recipient_fee : "0"
          )}
        </Box>
      </Box>
      <Box className="heading rotate-90">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 9h16.5m-16.5 6.75h16.5"
          />
        </svg>
      </Box>
      <Box className="heading">
        <Box className="heading-text">
          Due <br /> amount
        </Box>
        <Box className="value-text">
          ₹{" "}
          {formatCurrency(props.data?.due_amount ? props.data.due_amount : "0")}
        </Box>
      </Box>
    </Box>
  );
};
export default TxnInfo;
