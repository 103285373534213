import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectAcc from "../../../../assets/CreateTransactionIcons/Select account.png";
import loadingLogo from "../../../../assets/Approve transactions/inputloder.gif";

import {
  verifyThisPAN,
  verifyThisBank,
  resetBankVerification,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import verifyLogo from "../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import trimSentence from "../../../../utils/trimSentence";
import Validation from "../../../../utils/validation";
import { toast } from "react-toastify";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import { all } from "axios";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { getFFLimits } from "../../../../redux/features/ffLimits/ffLimits";
import formatCurrency from "../../../../utils/CurrencyFormater";
const LinkUserFFAcc = (props) => {
  // validations
  const panStatus = useSelector((state) => state.verify.verifyPAN);
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const bankStatusLoading = useSelector(
    (state) => state.verify.verifyBankisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [gstinValidation, setGstinValidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const limitApiRes = useSelector((state) => state.ffLimits.limit);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;
  const [customLimit, setCustomLimit] = useState(
    props.registerNewVnerdorData.txn_limit === limitApiRes?.limits?.maximum
      ? false
      : true
  );

  useEffect(() => {
    if (props.registerNewVnerdorData.txn_limit === "") {
      setCustomLimit(false);
      props.setRegisterNewVnerdorData({
        ...props.registerNewVnerdorData,
        txn_limit: limitApiRes?.limits?.maximum,
      });
    }
  }, [limitApiRes]);

  const dispatch = useDispatch();
  const disabledToggle = Boolean(props.disabledToggle);

  const modifyValue = (type, value) => {
    if (
      type === "pan_no" ||
      type === "bank_acc_no" ||
      type === "ifsc_code" ||
      type === "gstin"
    ) {
      return value?.toUpperCase();
    }
    if (type === "email_id") {
      return value?.toLowerCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "pan_no") return Validation.allowedPAN(value);
    if (name === "bank_acc_no") return Validation.allowedBankAccNum(value);
    if (name === "ifsc_code") return Validation.allowedBankIfsc(value);
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    if (name === "gstin") return Validation.allowedGstin(value);
    if (name === "txn_limit") return Validation.allowedAmountLimit(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "pan_no") errors = Validation.validatePan(name, value, errors);
    if (name === "ifsc_code")
      errors = Validation.validateIfsc(name, value, errors);
    if (name === "bank_acc_no")
      errors = Validation.validateBankAcc(name, value, errors);
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    if (name === "txn_limit")
      errors = Validation.validateAmountLimit(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewVnerdorData, [name]: value };
      props.setRegisterNewVnerdorData(newObj);
    }
    if (
      props.registerNewVnerdorData.pan_no.length === 0 ||
      props.registerNewVnerdorData.pan_no.length < 10
    ) {
      setPanVerificationStatus(false);
    }
    if (props.registerNewVnerdorData.bank_acc_no.length === 0) {
      setBankVerificationStatus(false);
    }

    if (
      props.registerNewVnerdorData.ifsc_code.length === 0 ||
      props.registerNewVnerdorData.ifsc_code.length < 11
    ) {
      setBankVerificationStatus(false);
    }
    if (
      props.registerNewVnerdorData.contact_no.length === 0 ||
      props.registerNewVnerdorData.contact_no.length < 10
    ) {
      setContactNumberValidation(false);
    }
    if (props.registerNewVnerdorData.email_id.length === 0) {
      setEmailValidation(false);
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (!errorMessage && name === "ifsc_code" && bankValuesFilled())
      errorMessage = customErrors["bank_acc_no"];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (bankStatus?.errors) {
      let errors = { ...customErrors };
      errors["bank_acc_no"] = bankStatus.errors;
      setCustomErrors(errors);
      setBankVerificationStatus(false);
    }

    if (bankStatus?.status === true) {
      let errors = { ...customErrors };
      delete errors["bank_acc_no"];
      setCustomErrors(errors);
      setBankVerificationStatus(true);
    }
  }, [bankStatus]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no.length > 0 &&
      panStatus?.status === true
    ) {
      let errors = { ...customErrors };
      delete errors["pan_no"];
      setCustomErrors(errors);
      setPanVerificationStatus(true);
    }

    if (props.registerNewVnerdorData.pan_no.length > 0 && panStatus?.errors) {
      let errors = { ...customErrors };
      errors["pan_no"] = panStatus.errors;
      setCustomErrors(errors);
      setPanVerificationStatus(false);
    }
  }, [panStatus]);

  useEffect(() => {
    if (customErrors["pan_no"]) setPanVerificationStatus(false);
    if (customErrors["bank_acc_no"]) setBankVerificationStatus(false);
    if (customErrors["ifsc_code"]) setBankVerificationStatus(false);
    if (customErrors["contact_no"]) setContactNumberValidation(false);
    if (
      !customErrors["contact_no"] &&
      props.registerNewVnerdorData.contact_no.length === 10
    )
      setContactNumberValidation(true);
    if (customErrors["email_id"]) setEmailValidation(false);
    if (
      !customErrors["email_id"] &&
      props.registerNewVnerdorData.email_id.length > 0 &&
      emailRegex.test(props.registerNewVnerdorData.email_id)
    )
      setEmailValidation(true);
  }, [customErrors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bankValuesFilled = () => {
    return (
      props.registerNewVnerdorData.bank_acc_no.length >= 4 &&
      props.registerNewVnerdorData.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.registerNewVnerdorData.ifsc_code)
    );
  };

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no?.length === 10 &&
      !customErrors["pan_no"]
    ) {
      dispatch(
        verifyThisPAN({ pan_number: props.registerNewVnerdorData.pan_no })
      );
    }
  }, [props.registerNewVnerdorData.pan_no]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.bank_acc_no.length >= 4 &&
      props.registerNewVnerdorData.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.registerNewVnerdorData.ifsc_code)
    ) {
      dispatch(
        verifyThisBank({
          ifsc_code: props.registerNewVnerdorData.ifsc_code,
          bank_acc_no: props.registerNewVnerdorData.bank_acc_no,
        })
      );
    }
  }, [
    props.registerNewVnerdorData.ifsc_code,
    props.registerNewVnerdorData.bank_acc_no,
  ]);

  useEffect(() => {
    if (!disabledToggle) {
      if (props.registerNewVnerdorData.pan_no.length !== 10) {
        const newObj = {
          ...props.registerNewVnerdorData,
          name: "",
        };
        props.setRegisterNewVnerdorData(newObj);
      }
    }
  }, [props.registerNewVnerdorData.name]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no.length === 0 ||
      props.registerNewVnerdorData.bank_acc_no.length === 0 ||
      props.registerNewVnerdorData.txn_limit.length === 0 ||
      props.registerNewVnerdorData.ifsc_code.length === 0
    ) {
      props.enableNextButton(false);
      return;
    }
    if (
      panVerificationStatus &&
      (bankVerificationStatus || bankValuesFilled())
    ) {
      const newObj = {
        ...props.registerNewVnerdorData,
        name: panStatus?.pan_details.full_name,
        skip_account_verification:
          bankStatus?.verification_details &&
          bankStatus?.verification_details[0].account_exists === "Y"
            ? true
            : false,
      };
      props.setRegisterNewVnerdorData(newObj);
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [
    panVerificationStatus,
    bankVerificationStatus,
    contactNumberValidation,
    emailValidation,
    props.registerNewVnerdorData.pan_no,
    props.registerNewVnerdorData.ifsc_code,
    props.registerNewVnerdorData.bank_acc_no,
    props.registerNewVnerdorData.contact_no,
    props.registerNewVnerdorData.email_id,
    props.registerNewVnerdorData.txn_limit,
  ]);

  useEffect(() => {
    dispatch(getFFLimits());
  }, []);

  // this needs to be checked

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    return (
      <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
        <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
          {name === "contact_no" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 512"
            >
              <g id="_552489" data-name="552489" transform="translate(0 0)">
                <ellipse
                  id="Ellipse_59"
                  data-name="Ellipse 59"
                  cx="256"
                  cy="256"
                  rx="256"
                  ry="256"
                  transform="translate(0 0)"
                  fill="#fff"
                />
                <path
                  id="Path_626"
                  data-name="Path 626"
                  d="M348.054,299.359a23.9,23.9,0,0,0-26.948.071l-11.679,8.016a36.426,36.426,0,0,1-42.812-1.18,399.631,399.631,0,0,1-33.713-29.1,399.181,399.181,0,0,1-29.1-33.713,36.426,36.426,0,0,1-1.18-42.812l8.016-11.679a23.907,23.907,0,0,0,.07-26.948l-33.431-49.267a32.411,32.411,0,0,0-41.793-10.561l-.009,0a62.791,62.791,0,0,0-28.317,81.262,452.176,452.176,0,0,0,76.987,118.682l23.79,23.79A452.23,452.23,0,0,0,326.616,402.91a62.792,62.792,0,0,0,81.262-28.317l0-.009a32.411,32.411,0,0,0-10.561-41.793Z"
                  transform="translate(4.84 3.715)"
                  fill="#9f9f9f"
                />
              </g>
            </svg>
          )}
          {name === "name" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 512"
            >
              <path
                id="Path_2074"
                data-name="Path 2074"
                d="M257,224.429A109.714,109.714,0,1,0,147.286,114.714,109.256,109.256,0,0,0,257,224.429Zm0,73.143c-85.211,0-256,42.789-256,128V517H513V425.572C513,340.36,342.211,297.571,257,297.571Z"
                transform="translate(-1 -5)"
                fill="#fafafa"
              />
            </svg>
          )}
          {name === "email_id" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 392.778"
            >
              <g id="iconfinder_gmail_4975304" transform="translate(-0.001 0)">
                <g
                  id="Group_14"
                  data-name="Group 14"
                  transform="translate(33.38 0)"
                >
                  <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M484.973,122.808,452.288,453.324H59.712L33.379,129.205,256,254.723Z"
                    transform="translate(-33.379 -60.548)"
                    fill="#f2f2f2"
                  />
                  <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M473.886,60.983,256,267.1,38.114,60.983H473.886Z"
                    transform="translate(-33.379 -60.983)"
                    fill="#f2f2f2"
                  />
                </g>
                <path
                  id="Path_210"
                  data-name="Path 210"
                  d="M59.712,155.8V453.4H24.139A24.229,24.229,0,0,1,0,429.092V111.967l39,1.07Z"
                  transform="translate(0.001 -60.625)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_211"
                  data-name="Path 211"
                  d="M512,111.988V429.115a24.234,24.234,0,0,1-24.154,24.309H452.288V155.82l19.692-46.852Z"
                  transform="translate(0 -60.646)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_212"
                  data-name="Path 212"
                  d="M512,85.292v27.034l-59.712,43.832L256,300.232,59.712,156.158,0,112.326V85.292A24.23,24.23,0,0,1,24.139,60.983H38.114L256,220.909,473.886,60.983h13.962A24.232,24.232,0,0,1,512,85.292Z"
                  transform="translate(0.001 -60.983)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_213"
                  data-name="Path 213"
                  d="M59.712,155.8,0,146.476V111.967Z"
                  transform="translate(0.001 -60.625)"
                  fill="#9f9f9f"
                />
              </g>
            </svg>
          )}
        </div>
        <div
          style={{ cursor: name === "name" ? "pointer" : "default" }}
          onClick={() =>
            name === "name" &&
            props.activeStep === 2 &&
            props.handleOpen("linkUser")
          }
          className="w-2/3  py-2 text-white text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl"
        >
          <div
            className={`w-full flex flex-col
          antaliased 
          ${
            name === "name"
              ? "text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl font-semibold"
              : "text-lg"
          } ${name === "name" ? "text-custom-orange" : "text-white"}`}
          >
            <div className="w-full flex gap-5 self-start items-center">
              <div className="w-full truncate ... ">
                {value}
                {name === "name" && props.activeStep === 2 && (
                  <div className="text-orange inline-flex px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-10 5xl:h-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="text-white text-lg opacity-75">{label}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="bg-[#9777AC] flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl pb-8"
    >
      <div>
        <div
          className="bg-[#9777AC]
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        px-4 pt-4 md:px-6 md:pt-6 lg:px-8 lg:pt-8 xl:px-10 xl:pt-10 4xl:pt-24 4xl:px-24 pb-1"
        >
          <div
            className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
          >
            Link user
          </div>
          <div className="flex flex-row gap-2">
            <div className="self-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div
          className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
          gap-6"
        >
          {props.linkUser ? (
            <div className="flex flex-col gap-2">
              <div
                className="min-w-full flex flex-row
        "
              >
                <div
                  className={`min-w-full
          flex flex-col justify-center py-1
          rounded-l-3xl`}
                >
                  <div
                    className="min-w-full px-4 md:px-6 -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24 lg:px-8 xl:px-10 4xl:px-24"
                  >
                    {getInputElementDiv(
                      "name",
                      contactNumberValidation,
                      "Agent name",
                      props.linkUser.first_name +
                        " " +
                        props.linkUser.last_name,
                      10
                    )}
                    {getInputElementDiv(
                      "contact_no",
                      contactNumberValidation,
                      "Contact number",
                      "+91 " + props.linkUser.mob_no,
                      20,
                      true
                    )}
                    {getInputElementDiv(
                      "email_id",
                      emailValidation,
                      "Email-ID",
                      props.linkUser.email_id,
                      20
                    )}
                    {((props?.activeStep === 3 &&
                      props?.registerNewVnerdorData.gstin.length > 1) ||
                      props?.activeStep !== 3) && (
                      <TextField
                        className="w-full antialiased text-4xl"
                        inputProps={{
                          style: {
                            color: "white",
                            borderBottom: "1px solid white",
                          },
                          maxLength: 15,
                          readOnly: props?.activeStep === 3,
                        }}
                        InputLabelProps={{
                          style: {
                            color: "white",
                            fontFamily: "Open Sans",
                            fontWeight: 100,
                            fontSize: "1.0 rem",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              {!true &&
                                props?.registerNewVnerdorData.gstin.length >
                                  0 &&
                                (gstinValidation ? (
                                  <img
                                    src={verifyLogo}
                                    alt="verified logo"
                                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                                  />
                                ) : (
                                  <img
                                    src={skippedVerification}
                                    alt="verified logo"
                                    onClick={() => {
                                      showErrors("gstin");
                                    }}
                                    className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                                  />
                                ))}
                            </InputAdornment>
                          ),
                        }}
                        label={"GSTIN (Optional)"}
                        variant="standard"
                        name={"gstin"}
                        onChange={handleChange}
                        value={props?.registerNewVnerdorData.gstin}
                        autoComplete="off"
                      />
                    )}
                  </div>
                  <div className=" bg-[#A185B4] mt-10  rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl -mx-4 md:-mx-6 lg:-mx-8 xl:-mx-10 4xl:-mx-24 -mb-8 flex flex-col gap-5 justify-center p-6 bottom-0">
                    <div className="text-white font-semibold text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl">
                      User transaction limit
                    </div>
                    {((customLimit && props?.activeStep === 3) ||
                      props?.activeStep !== 3) && (
                      <div
                        onClick={() => {
                          setCustomLimit(true);
                        }}
                        style={{
                          borderColor:
                            customLimit && props?.activeStep !== 3
                              ? "#2CEC3B"
                              : "#B8A3C6",
                          cursor:
                            props?.activeStep === 2 ? "pointer" : "default",
                        }}
                        className="flex border-4 bg-[#B8A3C6] flex-row rounded-xl justify-between items-center h-2/5 p-4 w-full"
                      >
                        {props.activeStep !== 3 ? (
                          <TextField
                            className="w-full antialiased text-xs"
                            inputProps={{
                              style: {
                                color: "white",
                                borderBottom: "1px solid white",
                              },
                              maxLength: 10,
                              readOnly: props?.activeStep === 3,
                            }}
                            InputLabelProps={{
                              style: {
                                color: "white",
                                fontFamily: "Open Sans",
                                fontWeight: 100,
                                fontSize: "1.0 rem",
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            label="Custom 24 hour transaction limit"
                            variant="standard"
                            onChange={(e) => {
                              props.setRegisterNewVnerdorData({
                                ...props.registerNewVnerdorData,
                                txn_limit: e.target.value,
                              });
                            }}
                            value={
                              customLimit
                                ? props.registerNewVnerdorData.txn_limit
                                : ""
                            }
                            name="txn_limit"
                            autoComplete="off"
                          />
                        ) : (
                          <div
                            className={`flex flex-col -mx-4 ${
                              props.activeStep === 3 ? " p-4" : ""
                            }`}
                          >
                            <div className="text-white italic font-bold text-2xl">
                              ₹{" "}
                              {formatCurrency(
                                props.registerNewVnerdorData?.txn_limit
                              )}
                            </div>
                            <div className="text-white ">
                              {" "}
                              Use the custom 24 hour transaction limit{" "}
                            </div>
                          </div>
                        )}
                        {props.activeStep !== 3 && (
                          <div>
                            {customLimit ? (
                              <RadioButtonChecked sx={{ color: "#2CEC3B" }} />
                            ) : (
                              <RadioButtonUnchecked sx={{ color: "white" }} />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {((!customLimit && props?.activeStep === 3) ||
                      props?.activeStep !== 3) && (
                      <div
                        style={{
                          borderColor:
                            !customLimit && props?.activeStep !== 3
                              ? "#2CEC3B"
                              : "#B8A3C6",
                        }}
                        onClick={() => {
                          setCustomLimit(false);
                          props.setRegisterNewVnerdorData({
                            ...props.registerNewVnerdorData,
                            txn_limit: limitApiRes?.limits?.maximum,
                          });
                        }}
                        className="flex cursor-pointer border-4 flex-row rounded-xl bg-[#B8A3C6] justify-between items-center h-2/5 p-4 w-full"
                      >
                        <div
                          className={`flex flex-col ${
                            props.activeStep === 3 ? "p-4" : ""
                          }`}
                        >
                          <div className="text-white italic font-bold text-2xl">
                            ₹ {formatCurrency(limitApiRes?.limits?.maximum)}
                          </div>
                          <div className="text-white ">
                            {" "}
                            Use the default 24 hour transaction limit{" "}
                          </div>
                        </div>
                        {props.activeStep !== 3 && (
                          <div>
                            {!customLimit ? (
                              <RadioButtonChecked sx={{ color: "#2CEC3B" }} />
                            ) : (
                              <RadioButtonUnchecked sx={{ color: "white" }} />
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                props.handleOpen("linkUser");
              }}
              className="flex flex-col gap-5"
            >
              <div
                className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
              >
                Select user to link this account with
              </div>
              <div className="self-center cursor-pointer">
                <img
                  src={selectAcc}
                  className="w-8 h-8 3xl:w-12 3xl:h-12 5xl:w-16 5xl:h-16"
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkUserFFAcc;
