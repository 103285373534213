import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";

const legacyUrl = env.REACT_APP_LEGACY_URL;
export const getInstantPayBalance = createAsyncThunk(
  "getInstantPayBalance",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({}),
    };

    return await fetch(
      `${legacyUrl}/transactions/Instant_pay/view_balance`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

const instantPayBalance = createSlice({
  name: "instantPayBalance",
  initialState: {
    balance: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(getInstantPayBalance.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInstantPayBalance.fulfilled, (state, action) => {
      state.isLoading = false;
      state.balance = action.payload;
    });
    builder.addCase(getInstantPayBalance.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default instantPayBalance.reducer;












