export const VendorTxnTextMapper = (status) => {
  switch (status) {
    case "S":
      return "Settled";
    case "Processing":
      return "Processing";
    case "Stalled":
      return "Stalled";
    case "P":
      return "Pending";
    case "Y":
      return "Approved";
    case "Cancelled":
      return "Cancelled";
    case "Abandoned":
      return "Abandoned";
    case "Expired":
      return "Expired";
    case "N":
      return "Denied";
    case "D":
      return "Denied"; //for accounts
    case "Failed":
      return "Failed";
    case "Refund":
      return "Refund";
    case "Created":
      return "Created"
    case "Refunded":
      return "Refunded"
    default:
      return "Under Process";
  }
};

export const IPTextMapper = (status) => {
  switch (status) {
    case "S":
      return "Settled";
    case "Processing":
      return "Processing";
    case "Stalled":
      return "Stalled";
    case "P":
      return "Pending";
    case "Y":
      return "Created";
    case "Cancelled":
      return "Cancelled";
    case "Abandoned":
      return "Abandoned";
    case "Expired":
      return "Expired";
    case "N":
      return "Denied";
    case "D":
      return "Denied"; //for accounts
    case "Failed":
      return "Failed";
    case "Refund":
      return "Refund";
    case "Created":
      return "Created"
    default:
      return "Unknown"
    // return `Unknow Status: ${status}`;
  }
};
