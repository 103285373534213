import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
import formatCurrency from "../../../../utils/CurrencyFormater";
import formatDate from "../../../../utils/formatdate";
import trimSentence from "../../../../utils/trimSentence";
import { useDispatch, useSelector } from "react-redux";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";

const ApprovalStatusCard = (props) => {
  const id = props.data?.transaction.id;
  const txnDetail = props?.txnDetail;
  const dispatch = useDispatch();
  const services = useSelector((state) => state.refreshToken.services);
  const statusImage = () => {
    if (txnDetail?.transactions[0].approval_status === "P") {
      return "Pending yellow";
    } else if (txnDetail?.transactions[0].approval_status === "Y") {
      return "Approved green";
    } else if (txnDetail?.transactions[0].approval_status === "N") {
      return "Declined red";
    } else {
      return "Pending yellow";
    }
  };

  return (
    <div className="w-full  overflow-hidden smallHeight:h-[90vh] text-lg flex rounded-4xl shadow-customCard bg-[#3e5555]">
      <div className="w-1/2 text-center rounded-4xl text-white">
        {txnDetail?.transactions[0].approval_status === "P" && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white text-xl leading-loose tracking-normal">
            This transaction has been approved, but it requires another users
            approval before settlement <br />
            <Link
              className="text-center underline text-xl leading-loose tracking-normal text-[#ff7f33]"
              to={`/vendor/txn/${id}`}
            >
              View transaction {">"}{" "}
            </Link>
          </div>
        )}
        {txnDetail?.transactions[0].approval_status === "Y" && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white text-xl leading-loose tracking-normal">
            This transaction has been approved <br />
            {/* {services.includes("Settle_txn") && <Link className="text-center underline text-xl leading-loose tracking-normal text-[#ff7f33]" onClick={() => {
              dispatch(setHideBreadCrumb({ hide: true }));
              props.setSelectedTxnCarousel({ amt: txnDetail?.transactions[0].amount, selected: [txnDetail?.transactions[0].txn_id] });
              props.setShowSettlementComponent(true)
            }} >Settle now {">"} </Link>} */}
            <Link
              className="text-center underline text-xl leading-loose tracking-normal text-[#ff7f33]"
              to={`/vendor/txn/${id}`}
            >
              View transaction {">"}{" "}
            </Link>
          </div>
        )}
        {txnDetail?.transactions[0].approval_status === "N" && (
          <div className="p-5 h-full flex flex-col gap-5 justify-center items-center text-white text-xl leading-loose tracking-normal">
            This transaction has been declined. <br />
            <div
              style={{
                margin: "2% 0",
                height: "3px",
                width: "100%",
                background:
                  "repeating-linear-gradient( to left, transparent, transparent 15px, #657777 15px,  #657777 30px)",
              }}
            ></div>
            <div className="self-start px-4">
              <div
                className="text-left"
                style={{
                  color: "#FAFAFA",
                  font: "normal normal 600 18px/24px Open Sans",
                }}
              >
                {txnDetail?.transactions[0].reason}
              </div>
              <div
                className="text-left"
                style={{
                  color: "#C9C9C9",
                  font: "normal normal normal 14px/19px Open Sans",
                }}
              >
                Denied reason
              </div>
            </div>
            <Link
              className="text-center underline text-xl leading-loose tracking-normal text-[#ff7f33]"
              to={`/vendor/txn/${id}`}
            >
              View transaction {">"}
            </Link>
          </div>
        )}
        {txnDetail?.transactions[0].status === undefined && (
          <div className="p-5 h-full flex flex-col justify-center items-center text-white text-xl leading-loose tracking-normal">
            Approval status of this transaction cannot be discerned, check the
            transaction detail page after sometime
            {/* <Link className="text-center underline text-xl leading-loose tracking-normal text-[#ff7f33]" to={`/vendor/txn/${id}`} >View transaction {">"}</Link> */}
          </div>
        )}
      </div>
      <div className="w-1/2 xl:text-sm text-lg  text-center shadow-customCard rounded-4xl bg-[#4A6565]">
        {txnDetail?.transactions[0].approval_status === "P" && (
          <h1
            className="text-2xl font-semibold capitalize py-1 px-10 text-center text-white  w-full my-6
 truncate ..."
          >
            {txnDetail?.transactions[0].vendor_name}
          </h1>
        )}
        {txnDetail?.transactions[0].approval_status === "Y" && (
          <h1
            className="text-2xl font-semibold capitalize py-1 px-10 text-center text-white  w-full my-6
 truncate ..."
          >
            {txnDetail?.transactions[0].vendor_name}
          </h1>
        )}
        {txnDetail?.transactions[0].approval_status === "N" && (
          <h1
            className="text-2xl font-semibold capitalize py-1 px-10 text-center text-white  w-full my-6
 truncate ..."
          >
            {txnDetail?.transactions[0].vendor_name}
          </h1>
        )}
        {txnDetail?.transactions[0].approval_status === undefined && (
          <h1
            className="text-2xl font-semibold capitalize py-1 px-10 text-center text-white  w-full my-6
 truncate ..."
          >
            NA
          </h1>
        )}
        <div className="bg-[#def5ed] text-center py-3 text-lg">
          <div className="text-4xl font-semibold pb-3 text-[#69B76F]">
            {" "}
            ₹ {formatCurrency(txnDetail?.transactions[0].amount)}
          </div>
          {txnDetail?.transactions[0].approval_status === "P" && (
            <div className="text-[#707070]">
              {formatDate(txnDetail?.transactions[0].posting_date)}
            </div>
          )}
          {txnDetail?.transactions[0].approval_status === "Y" && (
            <div className="text-[#707070]">
              {formatDate(txnDetail?.transactions[0].posting_date)}
            </div>
          )}
          {txnDetail?.transactions[0].approval_status === "N" && (
            <div className="text-[#707070]">
              {formatDate(txnDetail?.transactions[0].posting_date)}
            </div>
          )}
          {txnDetail?.transactions[0].approval_status === undefined && (
            <div className="text-[#707070]">NA</div>
          )}
        </div>
        <div className="flex flex-col gap-9 p-20 w-full justify-center items-center">
          <img
            margin="auto"
            width="50%"
            src={require(`../../../../assets/Approve transactions/${statusImage()}.png`)}
            alt=""
            srcSet=""
          />
          <div className="text-2xl font-semibold text-white 5xl:pb-8">
            {/* {txnDetail?.transactions[0].approval_status === "P" && (
              <div className="text-[#707070]">{VendorTxnTextMapper(txnDetail?.transactions[0].approval_status==="P")}</div>
            )} */}
            {VendorTxnTextMapper(txnDetail?.transactions[0].approval_status)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalStatusCard;
