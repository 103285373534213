import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DashboardCreateDiv = (props) => {
  const navigate = useNavigate();

  return (
    <Box className="drop-shadow-xl
      rounded-2xl 3xl:rounded-3xl 4xl:rounded-large
      p-4 3xl:p-6 4xl:p-10
      flex flex-col
      gap-4 3xl:gap-6 4xl:gap-10"
      sx={{
        'background': "transparent linear-gradient(108deg, #3173E2 0%, #DF5C74 100%) 0% 0% no-repeat padding-box"
      }}>
      <div className="text-white
        text-base 3xl:text-lg 4xl:text-3xl
        text-center antialiased">{props.heading}</div>
      <div className="
          flex flex-row justify-center gap-4 3xl:gap-6 4xl:gap-8">
        <div
          className="p-2 4xl:p-4 text-center text-white w-1/2 border-2 border-gray-300
            rounded-2xl 4xl:rounded-3xl
            hover:border-gray-400 cursor-pointer
            text-lg 3xl:text-xl 4xl:text-4xl"
          onClick={() => {
            navigate(`${props.first_button_url}`);
          }}
        >
          {props.first_button_text}
        </div>
        <div
          className="p-2 4xl:p-4
            text-lg 3xl:text-xl 4xl:text-4xl
            text-center text-white w-1/2 border-2 border-gray-300
            rounded-2xl 4xl:rounded-3xl
          hover:border-gray-400 cursor-pointer"
          onClick={() => {
            navigate(props.second_button_url);
          }}>
          {props.second_button_text}
        </div>
      </div>
    </Box >
  );
};

export default DashboardCreateDiv;
