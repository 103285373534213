import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action
export const getInstantpayViewDetail = createAsyncThunk(
  "getInstantpayViewDetail",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: 0,
        page_size: 50,
        txn_id: values?.txn_id
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/Instant_pay/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getVendorViewDetail = createAsyncThunk(
  "getVendorViewDetail",
  async (values) => {
    const { txn_id } = values;
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: txn_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/vendor/view_details`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getFieldForceViewDetail = createAsyncThunk(
  "getFieldForceViewDetail",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: values?.txn_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/view_detail`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getInternalViewDetail = createAsyncThunk(
  "getInternalViewDetail",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: values?.txn_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/owner/internal_fund_transfer/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getClientViewDetail = createAsyncThunk(
  "getClientViewDetail",
  async (values) => {
    const { txn_id } = values;
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: txn_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/client/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getReimbursementViewDetail = createAsyncThunk(
  "getReimbursementViewDetail",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: "0",
        page_size: "50",
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/reimbursement/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const txnViewDetailSlice = createSlice({
  name: "txnViewDetail",
  initialState: {
    isLoading: false,
    instantpay: null,
    vendor: null,
    fieldForce: null,
    internal: null,
    client: null,
    reimbursement: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //Instantpay
    builder.addCase(getInstantpayViewDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInstantpayViewDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.instantpay = action.payload;
    });
    builder.addCase(getInstantpayViewDetail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Vendor
    builder.addCase(getVendorViewDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorViewDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(getVendorViewDetail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //FieldForce
    builder.addCase(getFieldForceViewDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFieldForceViewDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldForce = action.payload;
    });
    builder.addCase(getFieldForceViewDetail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Internal
    builder.addCase(getInternalViewDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInternalViewDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internal = action.payload;
    });
    builder.addCase(getInternalViewDetail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Client
    builder.addCase(getClientViewDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientViewDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(getClientViewDetail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Reimbursement
    builder.addCase(getReimbursementViewDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReimbursementViewDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbursement = action.payload;
    });
    builder.addCase(getReimbursementViewDetail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default txnViewDetailSlice.reducer;


