import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import getServices from "../../../services/getServices";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { env } from "../../../env";

function encryptPassword(password, key) {
  const encryptedPassword = CryptoJS.HmacSHA256(password, key).toString();
  return encryptedPassword;
}

const getIPAdress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");

    const ipAddress = await response.json();
    return ipAddress?.ip;
  } catch (error) {
    console.error(error);
  }
};


function getPlatform() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "web";
}

async function getDeviceInfo() {
  const userAgent = window.navigator.userAgent;
  let browserName = "Unknown";
  let osVersion = "Unknown";

  if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Apple Safari";
  } else if (
    userAgent.indexOf("MSIE") > -1 ||
    userAgent.indexOf("Trident/") > -1
  ) {
    browserName = "Microsoft Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
  }
  
if (/iPhone|iPad|iPod/.test(userAgent)) {
  osVersion = 'iOS';
} else if (/Mac OS X/.test(userAgent) && !/like Mac OS X/.test(userAgent)) {
  osVersion = 'macOS';
} else if (userAgent.indexOf('Windows NT') !== -1) {
  osVersion = 'Windows';
} else if (userAgent.indexOf('Linux') !== -1) {
  osVersion = 'Linux';
} else if (userAgent.indexOf('Android') !== -1) {
  osVersion = 'Android';
}



  const ipAddress = await getIPAdress();
  const platform = getPlatform();

  return {
    uuid: uuidv4(),
    platform: "web",
    browser_name: browserName,
    ip_address: ipAddress,
    os_version: osVersion,
  };
}

export const loginNewToken = createAsyncThunk(
  "loginNewToken",
  async (values, { rejectWithValue }) => {
    const { user_name, password } = values;
    const key = env.REACT_APP_ENCRYPTION_KEY;
    const baseUrl = env.REACT_APP_BASE_URL;
    const encryptedPassword = encryptPassword(password, key);

    const deviceInfo = await getDeviceInfo();

    const requestBody = {
      identifier: user_name,
      password: encryptedPassword,
      device_info: deviceInfo,
    };

    try {
      const response = await axios.post(`${baseUrl}/users/token`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (values, { rejectWithValue }) => {
    const { oldPassword, newPassword, confirmPassword } = values;
    const key = env.REACT_APP_ENCRYPTION_KEY;
    const baseUrl = env.REACT_APP_BASE_URL;
    const encryptedOldPassword = encryptPassword(oldPassword, key);
    const encryptedNewPass1Password = encryptPassword(newPassword, key);
    const encryptedNewPass2Password = encryptPassword(confirmPassword, key);

    const requestBody = {
      old_pwd: encryptedOldPassword,
      new_pwd1: encryptedNewPass1Password,
      new_pwd2: encryptedNewPass2Password,
    };

    try {
      const response = await axios.put(
        `${baseUrl}/users/change_password`,
        requestBody,
        {
          headers: {
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
            "Content-Type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkUsertoken = createAsyncThunk(
  "checkUsertoken",
  async (values, { rejectWithValue }) => {
    const baseUrl = env.REACT_APP_BASE_URL;

    try {
      const response = await axios.get(`${baseUrl}/holidays/1`, {
        headers: {
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
          "Content-Type": "application/json",
        },
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    data: null,
    services: null,
    new_services: null,
    q2p_token: null,
    isError: false,
    multipleEntity: false,
    entity: null,
    showReloginModal: false,
  },
  reducers: {
    setEntity: (state, action) => {
      let data = [action.payload];
      state.entity = data;
    },
    setMultipleEntity: (state) => {
      state.entity = null;
    },
    setShowReloginModal: (state) => {
      state.showReloginModal = true;
    },
    setShowReloginModalFalse: (state) => {
      state.showReloginModal = false;
    },
    resetFirstLoginData: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginNewToken.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginNewToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.showReloginModal = false;
      if (
        Array.isArray(action?.payload?.entity) &&
        action?.payload?.entity?.length > 1
      ) {
        state.multipleEntity = true;
      } else {
        state.entity = action.payload.entity;
      }
      localStorage.setItem("response", JSON.stringify(action.payload));

      localStorage.setItem("user_id", action.payload?.employees?.user_id);
      localStorage.setItem("first_name", action.payload?.employees?.first_name);
      sessionStorage.setItem("loggedIn", true);
      state.services = Object.keys(
        getServices(action.payload.services).services_list
      );
      state.data = action.payload;
      state.q2p_token = action.payload.q2p_token;
      state.new_services = Object.keys(
        getServices(action.payload.services).services_list
      );

      window.sessionStorage.setItem("q2p_token", action.payload.q2p_token);
      window.sessionStorage.setItem("tokenGenerated", "false");
    });
    builder.addCase(loginNewToken.rejected, (state, action) => {
      if (action?.payload?.errors?.user_unauthorized) {
        toast.error(
          action.payload?.errors?.user_unauthorized[0],
          toast_position
        );
      } else if (action?.payload?.errors?.base) {
        toast.error(action?.payload?.errors?.base[0], toast_position);
      }
      state.isError = true;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      if (action?.payload?.errors?.user_unauthorized) {
        toast.error(
          action.payload?.errors?.user_unauthorized[0],
          toast_position
        );
      } else if (action?.payload?.errors?.base) {
        toast.error(action?.payload?.errors?.base[0], toast_position);
      }
    });
    builder.addCase(checkUsertoken.rejected, (state, action) => {
      if (action?.payload?.errors?.user_unauthorized) {
        state.showReloginModal = true;
      }
    });
  },
});
export const {
  setEntity,
  setMultipleEntity,
  setShowReloginModal,
  resetFirstLoginData,
  setShowReloginModalFalse,
} = loginSlice.actions;
export default loginSlice.reducer;
