import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ApprovalDetailsCard from "../ApprovalDetailsCard";
import FFAccountsApprovalStatusCard from "../AccountsApprovalStatusCard";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function FFAccountApprovalCardsCarousel(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const txnDetail = useSelector((state) => state.approveAccount.fieldForce);
  const [activeStep, setActiveStep] = useState(0);
  const [showStatusCard, setShowStatusCard] = useState([]);
  const [approvalStatuses, setApprovalStatuses] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const maxSteps = props?.data.length;

  useEffect(() => {
    if (checkRes && txnDetail?.errors) {
      toast.error(Object.values(txnDetail.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    setApprovalStatuses({
      ...approvalStatuses,
      [txnDetail?.accounts[0].account_id]: txnDetail?.accounts[0],
    });
    setCheckRes(true);
  }, [txnDetail]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (
      activeStep + 1 === maxSteps &&
      maxSteps !== props.records &&
      Object.keys(approvalStatuses).length === 0
    )
      props.setPage(props.page + 1);
  }, [activeStep]);

  return (
    <div className="h-[680px] w-[820px] pt-36 rounded-4xl m-auto">
      <AutoPlaySwipeableViews
        disabled={true}
        autoplay={false}
        className="mt-10 w-full h-[650px] overflow-hidden"
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {props.data?.map((item, index) => (
          <div key={item.account_id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <div className="w-full p-2">
                {showStatusCard?.includes(index) ? (
                  <FFAccountsApprovalStatusCard
                    txnDetail={approvalStatuses[item.account_id]}
                    setSelectedTxnCarousel={props.setSelectedTxnCarousel}
                    showStatusCard={showStatusCard}
                    setShowSettlementComponent={
                      props.setShowSettlementComponent
                    }
                    data={item}
                  />
                ) : (
                  <ApprovalDetailsCard
                    setDeniedReason={props.setDeniedReason}
                    setDeniedReasonData={props.setDeniedReasonData}
                    data={item}
                    showStatusCard={showStatusCard}
                    setShowStatusCard={setShowStatusCard}
                    index={index}
                    vendor_id={item.vendor_id}
                    account_id={item.account_id}
                  />
                )}
              </div>
            ) : null}
          </div>
        ))}
        {approvalStatuses && (
          <div className="h-[580px] w-[820px] p-2">
            <div className="h-full overflow-hidden xl:text-sm 2xl:text-lg flex rounded-4xl shadow-customCard bg-[#3e5555]">
              <div className="w-1/2 text-center rounded-4xl text-white">
                <div className="p-5 h-full flex flex-col gap-20 justify-center items-center text-white font-semibold text-2xl 4xl:text-4xl leading-loose tracking-normal">
                  Approval list has been updated. <br /> Reload the page to view
                  the <br /> updated list.
                </div>
              </div>
              <div className="w-1/2 m-full bg-[#4A6565] flex justify-center flex-col text-center rounded-4xl text-white">
                <Box className="flex flex-col gap-9 p-8 w-full justify-center items-center">
                  <img
                    width="200px"
                    src={require(`../../../../assets/Approve transactions/Reload white.png`)}
                    alt=""
                    srcSet=""
                  />
                </Box>
                <h1>
                  <Link
                    className="xl:text-3xl text-4xl 4xl:text-7xl text-white "
                    style={{ color: "#FF9130", textDecoration: "underline" }}
                    onClick={() => navigate(0)}
                  >
                    {" "}
                    Reload page{" "}
                  </Link>
                </h1>
              </div>
            </div>
          </div>
        )}
      </AutoPlaySwipeableViews>
      {activeStep < maxSteps && (
        <Box className="flex my-4 justify-between">
          {activeStep > 0 ? (
            <Button
              sx={{ color: "white" }}
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <img
                width={"37px"}
                src={require("../../../../assets/Approve transactions/Previous.png")}
                alt=""
              />
            </Button>
          ) : (
            <Button disabled size="small"></Button>
          )}
          <Box className="text-white text-lg">
            {activeStep +
              1 +
              " of " +
              maxSteps +
              (maxSteps === props.records ? "" : "+")}
          </Box>
          {activeStep + 1 < maxSteps ||
            Object.keys(approvalStatuses).length > 0 ? (
            <Button
              sx={{ color: "white" }}
              size="small"
              onClick={handleNext}
              disabled={activeStep + 1 === props.records}
            >
              <img
                width={"37px"}
                src={require("../../../../assets/Approve transactions/Next.png")}
                alt=""
              />
            </Button>
          ) : (
            <Button disabled size="small"></Button>
          )}
        </Box>
      )}
    </div>
  );
}

export default FFAccountApprovalCardsCarousel;
