import React from "react";

const EntityCard = ({ name, desc, title, status_title, status }) => {
  return (
    <div
      className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-xl
        w-full
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden"
    >
      <div className="text-white w-full font-bold text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased truncate ...">
        {name}
      </div>
      <div>
        <div className="text-white text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased truncate ...">
          {desc}
        </div>
        <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
          {title}
        </div>
      </div>
      {status_title && (
        <div>
          <div className="text-white text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased truncate ...">
            {status}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            {status_title}
          </div>
        </div>
      )}
    </div>
  );
};

export default EntityCard;
