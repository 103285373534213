import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action
export const getFFLimits = createAsyncThunk("getFFLimits", async (values) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify(values),
  };

  return await fetch(
    `${legacyUrl}/accounts/field_force/view/limits`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
});

const ffLimitsSlice = createSlice({
  name: "ffLimits",
  initialState: {
    isLoading: false,
    limit: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getFFLimits.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFFLimits.fulfilled, (state, action) => {
      state.isLoading = false;
      state.limit = action.payload;
    });
    builder.addCase(getFFLimits.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default ffLimitsSlice.reducer;
