import React, { useEffect, useState } from "react";
import EditEntityLogo from "../../../../../assets/Settings/Entity Details/Edit details.png";
import { useSelector, useDispatch } from "react-redux";
import DetailsMenuButton from "../../../../../components/Settings/Management/DetailsMenuButton";
import DetailsCards from "../../../../../components/Settings/Management/DetailsCards";
import DetailsDataCard from "../../../../../components/Settings/Management/DetailsDataCard";
import EditFeatures from "../../../../../assets/Settings/Entity Details/Edit features.png";
import { getServices } from "../../../../../redux/features/Settings/Management/Services";
import ServiceDetailsBanner from "../../../../../components/Settings/Management/Services/ServiceDetailsBanner";

function ServicesDetails() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("Details");

  const menuOptions = ["Details", "Features", "Roles", "Users", "Entities"];

  useEffect(() => {
    dispatch(
      getServices({
        service_id: "29604557-534e-4ce8-9a69-a1c1f71f07ff",
      })
    );
  }, []);

  const servicesData = useSelector(
    (state) => state.services?.services?.service
  );
  const rolesData = [];
  const featuresData = [];

  //Replace this with API data
  const data = {
    "Service ID": {
      "": servicesData?.service_id ? servicesData.service_id : "Not Given",
    },
    Origin: {
      "Created by": servicesData?.created_by_name
        ? servicesData.created_by_name
        : "Not Given",
      "Created on": servicesData?.created_on
        ? servicesData.created_on
        : "Not Given",
    },
    "Service Privilege": {
      "": "Not given",
    },
  };

  return (
    <div className="bg-[#244277] w-screen h-screen pt-24 flex no-scrollbar">
      <div className="bg-[#244277] pt-10 w-2/3 shadow-xl pl-32 pr-10 no-scrollbar">
        <ServiceDetailsBanner data={servicesData} />
        <div className="3xl:text-base 2xl:text-sm 2xl:w-9/12 xl:text-xs w-full py-5">
          <DetailsMenuButton
            menuOptions={menuOptions}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        {selectedOption === "Details" && (
          <div className="flex gap-5">
            <div className="flex flex-col w-6/12 gap-5">
              <DetailsCards title={"Service ID"} data={data["Service ID"]} />
              <DetailsCards title={"Origin"} data={data["Origin"]} />
            </div>
            <div className="flex flex-col w-6/12 gap-5">
              <DetailsCards
                title={"Service Privilege"}
                data={data["Service Privilege"]}
              />
            </div>
          </div>
        )}
        {selectedOption === "Roles" && (
          <div className="flex flex-wrap gap-x-10 gap-y-5">
            {rolesData?.map((item) => (
              <DetailsDataCard>{item.role_name}</DetailsDataCard>
            ))}
          </div>
        )}
        {selectedOption === "Features" && (
          <div className="flex flex-col gap-5">
            <div className="text-white w-full flex justify-start items-center text-xl font-semibold">
              Accounts
            </div>
            <div className="flex flex-wrap gap-x-10 gap-y-5">
              {featuresData?.map((item) => (
                <DetailsDataCard>{item.feature_name}</DetailsDataCard>
              ))}
            </div>
          </div>
        )}
        {selectedOption === "Services" && (
          <div className="flex flex-col gap-5">
            <div className="text-white w-full flex justify-start items-center text-xl font-semibold">
              Accounts
            </div>
            <div className="flex flex-wrap gap-x-10 gap-y-5">
              {servicesData?.map((item) => (
                <DetailsDataCard>{item.service_name}</DetailsDataCard>
              ))}
            </div>
          </div>
        )}
        {selectedOption === "Entities" && (
          <div className="flex flex-col gap-5">
            <div className="text-white w-full flex justify-start items-center text-xl font-semibold">
              A
            </div>
            <div>
              <DetailsDataCard>Hello</DetailsDataCard>
            </div>
          </div>
        )}
      </div>
      <div className="bg-[#1D3A6D] pl-10 pt-10 w-1/3">
        <div className="mt-5">
          <div className="w-[60%] rounded-4xl grid grid-cols-1 divide-y divide-[#1D3A6D] p-3 bg-[#3A5686]">
            <div className="flex py-3 divide-x divide-[#1D3A6D]">
              <button className="flex flex-col items-center gap-2 w-1/2">
                <img src={EditEntityLogo} className="w-10 h-10" />
                <div className="text-white text-xs">Edit details</div>
              </button>
              <button className="flex flex-col items-center gap-2 w-1/2">
                <img src={EditFeatures} className="w-10 h-10" />
                <div className="text-white text-xs">Edit features</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesDetails;
