import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBufferState } from "../../../../../../redux/features/bufferState/bufferState";
import accTransform from "../../../../../../utils/accTransform";
import "./index.css";
import { bankLogoMapper } from "../../../../../../services/bankLogoMapper";

const ExistingVendorAcc = (props) => {
  const clientPrimaryAcc = useSelector((state) => state.bufferState.clientPrimaryAcc)
  const dispatch = useDispatch();
  const handleVendorSelection = () => {
    if (!(props.value.approval_status === "Y" || props.value.approval_status === "1"))
      return;
    dispatch(setBufferState({ clientAcc: props.value, clientPrimaryAcc: clientPrimaryAcc }));
    props.handleClose();
  }

  return (
    < div
      className={`flex flex-row bg-custom-lightGray  w-full h-fit justify-between
        ${(props.value.approval_status === "Y" || props.value.approval_status === "1") && "cursor-pointer"}
        ${!(props.value.approval_status === "Y" || props.value.approval_status === "1") && "opacity-40"}
        rounded-large 4xl:rounded-customXl 6xl:rounded-custom2Xl overflow-hidden drop-shadow-xl text-custom-offwhite
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40
        text-base 3xl:text-xl`}
      onClick={handleVendorSelection} >
      <div className="w-2/5 self-center truncate ...
       text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl
       px-6 py-2 3xl:px-8 3xl:py-4 5xl:px-10 5xl:py-10 capitalize font-bold">
        {props.value.name}
      </div>
      <div className="w-3/5 h-full flex flex-row justify-end gap-6 3xl:gap-8">
        {
          props.value.contact_no &&
          <div className="p-2 w-2/3 self-center text-left text-white opacity-75 antaliased text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl">
            +91 {props.value.contact_no}
          </div>
        }
      </div>
    </div >
  );
};

export default ExistingVendorAcc;

