import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

function VerficationSearch(props) {
    const dispatch = useDispatch()
    const inputValue = useRef("")
    const [toggleClear, setToggleClear] = useState(false)

    const handleChange = (e) => {
        if (inputValue.current.value !== "") {
            setToggleClear(true)
        } else {
            setToggleClear(false)
        }
    }

    const handleClear = () => {
        inputValue.current.value = ""
        setToggleClear(false)
    }

    return (
        <div className='flex justify-between items-center p-6 bg-[#314D7E] shadow-md rounded-b-4xl'>
            <div className='flex justify-start items-center bg-[#667CA3] p-2 px-4 rounded-4xl w-10/12 gap-5'>
                <img className="w-4 h-4 ml-4" src={require("../../../../assets/Approve transactions/search-white.png")} alt="" />
                <input ref={inputValue} onChange={handleChange} type='text' placeholder={props.placeholder} className='w-11/12 bg-[#667CA3] text-white placeholder-white border-none outline-none' />
                {toggleClear ? (<button onClick={handleClear} className='border-l border-white'>
                    <img className="w-4 h-4 ml-4" src={require("../../../../assets/Approve transactions/Close.png")} alt="close" />
                </button>) : null}
            </div>
            <button onClick={() => dispatch(props.dispatchFn({ search_string: inputValue.current.value }))} className='px-6 py-2 bg-white rounded-full rounded-xl text-sm font-semibold active:text-white active:bg-custom-card shadow-md truncate'>Fetch Details</button>
        </div>
    )
}

export default VerficationSearch