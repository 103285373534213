import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../env";

const legacyUrl = env.REACT_APP_LEGACY_URL;
export const balanceDetails = createAsyncThunk(
  "owner_balance",
  async (values) => {
    const body =
    {
      owner_id: values?.account_id,
    };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(body),
    };
    return await fetch(
      `${legacyUrl}/icici/balance/fetch`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const balanceDetailsSlice = createSlice({
  name: "owner_balance",
  initialState: {
    isLoading: false,
    records: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(balanceDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(balanceDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("ACCOUNT BALANCE FETCH", action.payload);
      state.records = action.payload;
    });
    builder.addCase(balanceDetails.rejected, (state, action) => {
      console.log("ERROR", action.payload);
      state.isError = true;
    });
  },
});

export default balanceDetailsSlice.reducer;
