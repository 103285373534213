import React, { useEffect, useState } from "react";
import EntityDetailsBanner from "../../../../../components/Settings/Management/Entities/Details/EntityDetailsBanner";
import EntityDetailsMenuButtons from "../../../../../components/Settings/Management/Entities/Details/EntityDetailsMenuButtons";
import RegisteredNumberDetails from "../../../../../components/Settings/Management/Entities/Details/RegisteredNumberDetails";
import EditEntityLogo from "../../../../../assets/Settings/Entity Details/Edit details.png";
import EditFeatures from "../../../../../components/Settings/Management/Entities/Details/EditFeatures";
import EditFee from "../../../../../components/Settings/Management/Entities/Details/EditFee";
import EntityAddress from "../../../../../components/Settings/Management/Entities/Details/EntityAddress";
import EditCCDCValues from "../../../../../components/Settings/Management/Entities/Details/EditCCDCValues";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getEntities } from "../../../../../redux/features/Settings/Management/entities/index";

function Details() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState("Details");

  useEffect(() => {
    dispatch(
      getEntities({
        page_no: 2,
        entity_id: id,
      })
    );
  }, []);

  const entityData = useSelector(
    (state) => state.entities?.entities?.entities[0]
  );
  const CCDCData = [
    {
      title: "HDFC",
      "Payment gateway enabled": "Yes",
      "HDFC password": "**********",
      MID: "1234567890",
      TID: "1234567890",
      "MDR lowest": "2.5",
      "Convenience fee lowest": "0.5",
      "MDR medium": "0.5",
      "Convenience fee medium": "0.5",
      "MDR highest": "3.5",
      "Convenience fee highest": "0.5",
    },
    {
      title: "EaseBuzz",
      "Payment gateway enabled": "Yes",
      MID: "1234567890",
      "MDR lowest": "2.5",
      "Convenience fee lowest": "0.5",
      "MDR medium": "0.5",
      "Convenience fee medium": "0.5",
      "MDR highest": "3.5",
      "Convenience fee highest": "0.5",
    },
    {
      title: "Cashfree",
      "Payment gateway enabled": "Yes",
      MID: "1234567890",
      "MDR lowest": "2.5",
      "Convenience fee lowest": "0.5",
      "MDR medium": "0.5",
      "Convenience fee medium": "0.5",
      "MDR highest": "3.5",
      "Convenience fee highest": "0.5",
    },
  ];

  const [fee, setFee] = useState({
    "Net banking": 18,
    UPI: 18,
    Wallet: 18,
  });

  return (
    <div
      className="bg-[#244277] min-h-screen max-h-full w-full
      flex flex-row vendor-txn"
    >
      <div className="h-full pb-4 mt-24 bg-[#244277] pt-10 w-[70%] pl-24 pr-16">
        <EntityDetailsBanner data={entityData} />
        <div className="text-sm w-full py-5">
          <EntityDetailsMenuButtons
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        {selectedOption === "Details" && (
          <div className="h-full">
            <div className="flex flex-row justify-between">
              <RegisteredNumberDetails
                type={"CIN"}
                num={entityData?.cin_number}
              />
              <RegisteredNumberDetails
                type={"PAN"}
                num={entityData?.entity_pan}
              />
            </div>
            <div className="py-5">
              <EntityAddress
                address={entityData?.entity_address}
                pincode={entityData?.entity_pincode}
              />
            </div>
          </div>
        )}
        {selectedOption === "Payable CC/DC" && (
          <div className="h-full">
            <EditCCDCValues title={"Payable"} data={CCDCData} />
          </div>
        )}
        {selectedOption === "Receivable CC/DC" && (
          <div className="h-full">
            <EditCCDCValues title={"Receivable"} data={CCDCData} />
          </div>
        )}
        {selectedOption === "Net banking" && (
          <EditFee title={"Net banking"} fee={fee} setFee={setFee} />
        )}
        {selectedOption === "UPI" && (
          <EditFee title={selectedOption} setFee={setFee} fee={fee} />
        )}
        {selectedOption === "Wallet" && (
          <EditFee title={selectedOption} setFee={setFee} fee={fee} />
        )}
      </div>
      <div
        className="bg-[#1D3A6D] mt-24 pt-10 h-full lg:fixed lg:right-0
          p-4
          md:pl-8 md:py-8
          lg:pl-10 lg:py-10
          xl:pl-12 xl:py-12
          4xl:pl-24 4xl:py-24
          w-[30%] overflow-hidden hide-scrollbar"
      >
        <div>
          <button className="text-white text-sm flex bg-[#506994] hover:bg-[#6b88ba] w-[80%] pl-4 py-2 rounded-3xl shadow-lg items-center">
            <img src={EditEntityLogo} className="w-5 h-5 mr-4" />
            Edit entity details
          </button>
        </div>
        <div className="mt-5">
          <EditFeatures />
        </div>
      </div>
    </div>
  );
}

export default Details;
