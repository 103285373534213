import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";

function EditCCDCValuesModal({
  title,
  selectedOption,
  editModalState,
  selectedOptionData,
}) {
  const [input, setInput] = useState(selectedOptionData);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setInput({
      ...input,
      ["Payment gateway enabled"]: isChecked ? "Yes" : "No",
    });
  };

  const handlechange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
  };
  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998]"></div>
      <div className="flex 4xl:text-3xl  justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  fixed z-[9999]">
        <div className="w-2/5 h-3/5 flex flex-col gap-8 bg-custom-deniedReasonPurple rounded-4xl">
          <div className="flex flex-col gap-4">
            <div
              className="text-white font-semibold
            text-2xl 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-4"
            >
              Edit {title.toLowerCase()} {selectedOption}
            </div>
          </div>
          <div className="h-full flex flex-col gap-5 justify-center overflow-y-scroll px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20">
            <div className="bg-[#8983A2] text-white flex justify-between items-center rounded-2xl">
              <div>Enabled</div>
              <label className="flex items-center cursor-pointer">
                <div
                  className={`w-8 p-1 h-4 bg-gray-300 rounded-4xl ${isChecked ? "bg-green-500" : ""
                    }`}
                >
                  <div
                    className={`w-2 h-2 bg-white rounded-4xl transform transition-transform ${isChecked ? "translate-x-4" : ""
                      }`}
                  >
                    <input
                      type="checkbox"
                      className="opacity-0 w-0 h-0"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
              </label>
            </div>
            <div className="flex flex-col gap-2">
              {Object.keys(selectedOptionData)
                .filter(
                  (value) =>
                    value !== "title" && value !== "Payment gateway enabled"
                )
                .map((option) => {
                  return (
                    <TextField
                      autoFocus
                      autoComplete="off"
                      onChange={handlechange}
                      inputProps={{
                        style: {
                          color: "white",
                          borderBottom: "1px solid grey",
                        },
                        type: "text",
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#DADADA",
                          font: "normal normal normal 14px/19px Open Sans",
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      label={option}
                      variant="standard"
                      value={input[option]}
                      name={option}
                    />
                  );
                })}
            </div>
          </div>
          <div
            className="px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-5"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5%",
              width: "100%",
            }}
          >
            <Button
              onClick={() => editModalState(false)}
              sx={{
                borderRadius: "20px",
                borderColor: "#fff",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
              className="4xl:text-3xl"
            >
              Cancel
            </Button>
            <Button
              className="4xl:text-3xl"
              variant="outlined"
              onClick={() => handleSubmit()}
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCCDCValuesModal;
