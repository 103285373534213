import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import ClientTxnDetailsBanner from "../../../components/TxnDetails/ClientDetails/TxnDetailsBannerCard";
import "./index.css";
import AdditionalDetailsCard from "../../../components/TxnDetails/AdditionalDetailsCard";
import BeneficiaryDetailsComponent from "../../../components/TxnDetails/BeneficiaryDetails";
import ClientTxnQuickAccessMenu from "../../../components/TxnDetails/ClientDetails/ClientTxnQuickAccessMenu";
import { useDispatch, useSelector } from "react-redux";
import { getClientViewDetail } from "../../../redux/features/txnDetails/txnDetails";
import {
  VendorTxnDetailBgMapperMain,
  VendorTxnDetailBgMapper,
  VendorTxnDetailDarkBgMapper,
  VendorTxnDetailChildBgMapper,
} from "../../../services/VendorTxnDetailBgMapper";
import TimeLine from "../../../components/TxnDetails/TimeLine";
import TxnDetailsToggleButtons from "../../../components/TxnDetails/TxnDetailsToggleButtons";
import { useParams } from "react-router-dom";
import PayMethod from "../../../components/TxnDetails/PayMethod";
import MoreActions from "../../../components/TxnDetails/MoreActions";
import { getClientRefunds } from "../../../redux/features/clientRefunds/clientRefunds";
import Refund from "../../../components/TxnDetails/Refund";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import AddStatus from "../../../components/TxnDetails/MoreActions/AddStatus";
import ConvertToInstantPay from "../../../components/TxnDetails/MoreActions/ConvertToInstantPay";
import SupportInfo from "../../../components/TxnDetails/SupportInfo";
import { approveVendorTxn } from "../../../redux/features/approveTxn/approveTxn";
import DeniedReason from "../../../components/ApprovalTxn/VendorApproval/DeniedReson/DeniedReason";
import { getVendorPendingTxn } from "../../../redux/features/pendingTxn/pendingTxn";
import VendorDeniedReasonComponent from "../../../components/TxnDetails/VendorDeniedReasonComponent";
import SettlePayment from "../../SettlementRequired/SettlePayment";
import { PaymentGatewayMapper } from "../../../services/paymentGatewayMapper";
import { toast } from "react-toastify";
import DeleteButton from "../../../components/TxnDetails/DeleteButton";
import TransactionRefund from "../../../components/TxnDetails/MoreActions/TransactionRefund";

const ClientTxnDetails = () => {
  const [toggleView, setToggleView] = useState("details");
  const dispatch = useDispatch();
  const txnDetail = useSelector((state) => state.txnDetails.client);
  const clientRefunds = useSelector((state) => state.clientRefunds.data);
  const actionableTxns = useSelector((state) => state.pendingTxn.client);
  const data = txnDetail ? txnDetail.transactions[0] : null;
  const { id } = useParams();
  const [showAddStatus, setShowAddStatus] = useState(false);
  const [showConvertToInstantPay, setShowConvertToInstanyPay] = useState(false);
  const [showTransactionRefund, setShowTransactionRefund] = useState(false);
  const [deniedReason, setDeniedReason] = useState(false);
  const actionableTxnsID = actionableTxns?.actionables
    ? actionableTxns?.actionables.map((txn) => txn?.transaction?.id)
    : [];
  const isActionable = actionableTxnsID?.includes(id);
  const [selectedTxnCarousel, setSelectedTxnCarousel] = useState({});
  const [showSettlementComponent, setShowSettlementComponent] = useState(false);
  const addStatusRes = useSelector((state) => state.addStatusState?.response);
  const [checkRes, setCheckRes] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    dispatch(getClientViewDetail({ txn_id: id }));
    dispatch(getClientRefunds({ txn_id: id }));
  }, [dispatch, addStatusRes, data?.approval_status, id]);

  const handleToggleView = (view) => {
    setToggleView(view);
  };

  useEffect(() => {
    if (checkRes && addStatusRes?.errors) {
      toast.error(Object.values(addStatusRes.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && addStatusRes?.status) {
      toast.success("Transaction status update successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setCheckRes(true);
  }, [addStatusRes]);

  return !showSettlementComponent ? (
    <Box
      sx={{
        background: `linear-gradient(to right, ${VendorTxnDetailBgMapperMain(data?.approval_status)[0]
          } 0%, ${VendorTxnDetailBgMapperMain(data?.approval_status)[0]
          } 66.66%, ${VendorTxnDetailBgMapperMain(data?.approval_status)[1]
          } 66.66%, ${VendorTxnDetailBgMapperMain(data?.approval_status)[1]
          } 100%)`,
      }}
    >
      <Box
        className="flex flex-col-reverse lg:flex-row vendor-txn-timeline relative"
        sx={{ maxWidth: "1440px", margin: "0px auto 0px auto" }}
      >
        {deniedReason && (
          <DeniedReason
            txnId={[data?.txn_id]}
            setDeniedReason={setDeniedReason}
            dispatchFn={approveVendorTxn}
            navigatePath={"/vendor/approval_status"}
          />
        )}
        {showAddStatus && (
          <AddStatus setState={setShowAddStatus} txn_id={data?.txn_id} />
        )}
        {showConvertToInstantPay && (
          <ConvertToInstantPay
            setState={setShowConvertToInstanyPay}
            txn_id={data?.txn_id}
          />
        )}
        {showTransactionRefund && (
          <TransactionRefund
            setState={setShowTransactionRefund}
            txn_id={data?.txn_id}
            amount={data?.amount}
          />
        )}

        {/** Left Container */}
        <Box
          className="flex flex-row justify-end pb-8 w-[100%] lg:max-w-[960px]"
          sx={{ bgcolor: VendorTxnDetailBgMapper(data?.approval_status) }}
        >
          <div
            className="mt-24
            py-4 w-full
            flex flex-col
            justify-items-center"
          >
            {/** Left Top Banner */}
            <div className="p-12 py-6">
              <div className="flex flex-col justify-between">
                <div className="">
                  <ClientTxnDetailsBanner
                    setSelectedTxnCarousel={setSelectedTxnCarousel}
                    setShowSettlementComponent={setShowSettlementComponent}
                    posting_date={data?.posting_date}
                    approval_status={data?.approval_status}
                    amount={data?.amount}
                    txn_id={id}
                    data={data}
                    setDeniedReason={setDeniedReason}
                    isActionable={isActionable}
                    client_name={data?.client_name}
                    client_contact_no={data?.client_contact_no}
                    client_email_id={data?.client_email_id}
                    utr_no={data?.utr_no}
                    client_id={data?.client_id}
                  />
                </div>
              </div>
            </div>
            <div
              className="w-full flex
              px-12 self-start"
            >
              <TxnDetailsToggleButtons
                handleToggleView={handleToggleView}
                toggleView={toggleView}
                clientTxn={
                  services?.includes("VCRefund") &&
                    (data?.last_updated_status === "Refund Attempt Failed" ||
                      data?.last_updated_status === "Refund Initiated" ||
                      data?.last_updated_status === "Refund Requested" ||
                      data?.last_updated_status === "Refund Pending" ||
                      data?.last_updated_status === "Refund Acknowledged" ||
                      data?.last_updated_status === "Refund Success" ||
                      data?.approval_status === "Refund")
                    ? true
                    : false
                }
              />
            </div>
            <Box className="quickAccessMenuMobile">
              <ClientTxnQuickAccessMenu
                bgColor={VendorTxnDetailBgMapper(data?.approval_status)}
                addStatus={setShowAddStatus}
                convertToInstantPay={setShowConvertToInstanyPay}
                showTransactionRefund={setShowTransactionRefund}
              />
            </Box>
            <div className="p-12 py-6 flex flex-col">
              {/** Left Body */}

              {toggleView === "refunds" ? (
                <Box className="p-4">
                  {clientRefunds?.refund_details.map((refund) => (
                    <Refund
                      refund_amount={refund.refund_amount}
                      refund_id={refund.refund_id}
                      status={refund.status}
                      reference_id={refund.reference_id}
                      refund_date={refund.requested_on}
                    />
                  ))}
                </Box>
              ) : toggleView === "timeline" ? (
                <div className="pb-8 3xl:pb-10 4xl:pb-12 5xl:pb-16 6xl:pb-20">
                  <TimeLine approval_status={data?.approval_status} />
                </div>
              ) : (
                <div className="flex flex-col">
                  <div className="mb-8">
                    <AdditionalDetailsCard
                      approval_status={data?.approval_status}
                      invoice_no={data?.invoice_no}
                      distribution_remark={data?.distribution_remark}
                    />
                  </div>

                  <div className="flex flex-row gap-4">
                    <div
                      className={`w-1/2
                        ${data?.settlement_type && data?.payment_gateway_used
                          ? "bg-white"
                          : null
                        }
                        rounded-t-large`}
                    >
                      {data?.approval_status === "N" && (
                        <VendorDeniedReasonComponent
                          denied_reason={data?.reason}
                          approval_status={data?.approval_status}
                        />
                      )}
                      {data?.settlement_type && (
                        <PayMethod
                          container={"clientTxnDetail"}
                          bank_acc_no={
                            data?.settlement_type === "HDFC NEFT/RTGS"
                              ? data?.owner_bank_acc_no
                              : data?.settlement_type === "Registered Card"
                                ? data?.card_ending_with
                                : data?.settlement_type === "Instant Pay"
                                  ? "Instant Pay"
                                  : data?.settlement_type === "Net Banking"
                                    ? "Net Banking"
                                    : ""
                          }
                          name={
                            data?.settlement_type === "HDFC NEFT/RTGS"
                              ? data?.owner_name
                              : data?.settlement_type === "Registered Card"
                                ? data?.card_holder_name
                                : ""
                          }
                          bank_logo={
                            data?.settlement_type === "HDFC NEFT/RTGS"
                              ? bankLogoMapper(data?.owner_bank_name)
                              : data?.settlement_type === "Instant Pay"
                                ? "Instant Pay"
                                : data?.settlement_type === "Registered Card"
                                  ? data?.card_type || "Default Card"
                                  : data?.settlement_type === "Net Banking"
                                    ? "Bank_default"
                                    : "Status - pending"
                          }
                          status={data?.status}
                          approval_status={data?.approval_status}
                          payment_gateway_used={data?.payment_gateway_used}
                        />
                      )}
                      {data?.settlement_type && data?.payment_gateway_used && (
                        <div
                          className="bg-white flex flex-row justify-between
                                rounded-b-4xl
                                py-2 px-8"
                        >
                          <div className="self-center flex flex-col">
                            <div
                              className="text-sm
                            text-black antialiased truncate ..."
                            >
                              {data?.payment_gateway_used}
                            </div>
                            <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                              Payment gateway
                            </div>
                          </div>
                          <div
                            className="w-2/5
                                    -mr-8
                                    flex flex-col justify-center"
                          >
                            <div
                              className="py-1
                              flex justify-center text-center drop-shadow-xl"
                            >
                              <img
                                className="w-[70%]"
                                src={require(`../../../assets/TxnDetails/${PaymentGatewayMapper(
                                  data?.payment_gateway_used
                                )}.png`)}
                                alt="BankLogo"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-1/2">
                      <BeneficiaryDetailsComponent
                        vendor_name={data?.owner_bank_name}
                        vendor_bank_acc_no={data?.owner_bank_acc_no}
                        owner_ifsc_code={data?.owner_ifsc_code}
                        approval_status={data?.approval_status}
                        vendor_account_status={data?.vendor_account_status}
                        bank_logo={bankLogoMapper(data?.owner_bank_name)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <Box className="quickAccessMenuMobile">
                <Box>More Actions</Box>
                <MoreActions />
              </Box>
            </div>
          </div>
        </Box>
        {/** Right Side */}
        <div
          className="hidden lg:flex h-fit lg:min-h-screen flex-col
             lg:max-w-[480px] overflow-hidden"
          style={{
            backgroundColor: VendorTxnDetailDarkBgMapper(data?.approval_status),
          }}
        >
          <div
            className="mt-24 lg:fixed
            max-w-[480px] py-4 overflow-x-hidden overflow-y-auto
            flex flex-col justify-items-center"
            style={{ width: "inherit", position: "relative" }}
          >
            <div className="p-12 py-6">
              <ClientTxnQuickAccessMenu
                addStatus={setShowAddStatus}
                convertToInstantPay={setShowConvertToInstanyPay}
                showTransactionRefund={setShowTransactionRefund}
                status={data?.approval_status}
                bgColor={VendorTxnDetailChildBgMapper(data?.approval_status)}
                vendor_id={data?.vendor_id}
                txnHistoryPath={`/client_transactions/view_all_transactions?client_id=${data?.client_id}`}
                txnStatementPath={`/client_transactions/view_all_transactions?settle_id=${data?.settle_id}`}
                txn_id={data?.txn_id}
                settle_id={data?.settle_id}
                secondary_bg={VendorTxnDetailBgMapper(data?.approval_status)}
                txnData={data}
              />
            </div>
            <div className="p-12 py-6">
              <SupportInfo
                settle_id={data?.settle_id}
                txn_id={data?.txn_id}
                utr_no={data?.utr_no}
                account_id={data?.account_id}
                bgColor={VendorTxnDetailChildBgMapper(data?.approval_status)}
                secondary_bg={VendorTxnDetailBgMapper(data?.approval_status)}
              />
            </div>
          </div>
          <div className="fixed right-16 lg:right-8 bottom-2 3xl:right-64 3xl:bottom-8 4xl:bottom-2/3 5xl:bottom-2/3 5xl:right-[32%] 6xl:right-[32%]">
            {services?.includes("Settle_txn") &&
              (data?.approval_status === "Y" ||
                data?.approval_status === "P" ||
                data?.approval_status === "Denied") && (
                <DeleteButton txn_id={data?.txn_id} />
              )}
          </div>
        </div>
      </Box>
    </Box>
  ) : (
    <SettlePayment getSelected={() => selectedTxnCarousel} />
  );
};

export default ClientTxnDetails;
