import { Button, TextField } from '@mui/material';
import { max } from 'lodash';
import React from 'react'
import { useDispatch } from 'react-redux';
import { ArchiveTransactionAuthorization } from '../../../../../../redux/features/Settings/Authorization';

function ArchivedDialogBox(props) {
    const dispatch = useDispatch();
    return (
        <>
            <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9999] "></div>
            <div
                className="flex 4xl:text-3xl  justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  fixed z-[9999]">
                <div
                    className="w-2/6 h-fit flex flex-col gap-8 bg-custom-deniedReasonPurple rounded-4xl
          px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-10">
                    <div className="flex flex-col gap-4">
                        <div className="text-[#FF7F33] font-semibold
            text-2xl 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl">Archive rule</div>
                        <div
                            className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-gray-300 antialiased truncate">
                            Are you sure you want to archive this rule?
                        </div>
                        <div className="text-base text-[#FF7F33] 3xl:text-lg 5xl:text-xl 6xl:text-2xl">
                            Note : It may take up to 24 hours for the changes to take effect.
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "5%",
                            width: "100%",
                        }}>
                        <Button
                            sx={{ borderRadius: "20px", borderColor: "#fff", color: "#fff", textTransform: "capitalize", textDecoration: "underline" }}
                            onClick={() => {
                                props.setState(false);
                            }}
                            className="4xl:text-3xl">
                            Cancel
                        </Button>
                        <Button
                            className="4xl:text-3xl"
                            variant="outlined"
                            sx={{
                                borderRadius: "20px",
                                backgroundColor: "#9F7EC0",
                                color: "#fff",
                                border: "none",
                                boxShadow: "0px 3px 6px #00000029",
                                textTransform: "capitalize",
                                pointerEvents: "auto",
                            }}
                            onClick={() => {
                                dispatch(ArchiveTransactionAuthorization({ id: props.id }))
                                props.setState(false);
                            }}>
                            Confirm
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArchivedDialogBox