import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../../Settings/Authorization";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneCreateAccount = createAsyncThunk(
  "createBeneficiaryAccount",
  async (values, { rejectWithValue }) => {
    const {
      bank_account,
      contact_no,
      email_id,
      gstin,
      ifsc_code,
      legalName,
      panNumber,
      categories,
    } = values;

    const requestBody = {
      beneficiary_accounts: {
        bank_account_no: bank_account,
        contact_no: contact_no,
        email: email_id,
        gstin: gstin,
        ifsc: ifsc_code,
        pan: panNumber,
        name: legalName,
      },
    };

    if (categories && categories.length > 0) {
      requestBody.beneficiary_accounts.category = categories;
    } else {
      requestBody.beneficiary_accounts.category = null;
    }

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(requestBody),
    };

    let ErrorArrayVal = false;

    try {
      const response = await fetch(
        `${baseUrl}/accounts/beneficiary`,
        requestOptions
      );
      const responseData = await response.json();
      if (response.ok) {
        return responseData;
      } else {
        let errorMessage = "";
        if (responseData?.errors?.user_unauthorized) {
          errorMessage = responseData?.errors?.user_unauthorized[0];
        } else {
          errorMessage =
            responseData?.errors?.category?.[0] ||
          responseData?.errors[0]?.gstin_detail?.base ||
            responseData?.errors?.bank_account_number ?
            JSON.stringify({
              type: "array",
              errorData: [responseData?.errors?.bank_account_number[0], responseData?.errors?.permanent_account_number[0]]
            }) :
              responseData?.errors?.base[0];

          ErrorArrayVal = responseData?.errors?.bank_account_number ? true : false;
          
        }
        throw new Error(errorMessage);
      }
    } catch (error) {
        if (ErrorArrayVal) {
          JSON.parse(error.message)?.errorData.forEach((fe, Index) => {
            toast.error(JSON.parse(error.message)?.errorData[Index], toast_position);
          })
        } else {
          toast.error(error.message, toast_position);
        }
      return rejectWithValue(error.message);
    }
  }
);

const handleErrorMsg = (errmsg) => {
  if (errmsg?.errors?.user_unauthorized) {
    return errmsg?.errors?.user_unauthorized[0];
  } else if (errmsg?.errors?.bank_account_number) {
    return errmsg?.errors?.bank_account_number[0];
  } else if (errmsg?.errors?.category) {
    return errmsg?.errors?.category[0];
  } else if (errmsg?.errors?.[0]?.gstin_detail?.base) {
    return errmsg?.errors[0]?.gstin_detail?.base[0];
  } else if (errmsg?.errors?.permanent_account_number) {
    return errmsg?.errors?.permanent_account_number[0];
  } else {
    return errmsg?.errors?.base[0];
  }
};

export const editBeneficiary = createAsyncThunk(
  "editBeneficiary",
  async (values, { rejectWithValue }) => {
    const { id, pan, contact_no, email, gstin, category, name } = values;

    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    try {
      const response = await axios.put(
        `${baseUrl}/accounts/beneficiary/${id}/edit_other_details`,
        {
          beneficiary_accounts: {
            pan,
            email,
            contact_no,
            gstin,
            category,
            name,
          },
        },
        requestOptions
      );

      return response.data;
    } catch (error) {
      let errorMessage;

      if (error.response) {
        errorMessage =
          error.response.data?.errors?.bank_account_number?.[0] ||
          error.response.data?.errors?.beneficiary_account?.[0] ||
          error.response.data?.errors?.permanent_account_number?.[0] ||
          error.response.data?.errors?.[0]?.gstin_detail?.base ||
          error.response.data?.errors?.base?.[0];
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage, toast_position);
      return rejectWithValue(errorMessage);
    }
  }
);

export const beneSuccessDetail = createAsyncThunk(
  "beneSuccessDetail",
  async (id, { rejectWithValue }) => {
    try {
      if (!id) {
        throw new Error("Invalid beneficiary id");
      }
      const response = await axios.get(
        `${baseUrl}/accounts/beneficiary/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const beneEditDetailBank = createAsyncThunk(
  "beneEditdetailsbank",
  async (values, {rejectWithValue}) => {
    const { id, bank_account, ifsc_code, name } = values;

    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        beneficiary_accounts: {
          name: name,
          bank_account_no: bank_account,
          ifsc: ifsc_code,
        },
      }),
    };

    // return await fetch(`${baseUrl}/accounts/beneficiary/${id}`, requestOptions)
    //   .then((response) => {
    //     return response.json();
    //   })
    //   .catch((error) => {
    //     return console.log("error", error);
    //   });

    try {
      const response = await fetch(`${baseUrl}/accounts/beneficiary/${id}`, requestOptions);

      const responseData = await response.json();

      let errorMessage;

      if (!responseData.status) {
        if (responseData.errors != "") {
        errorMessage =
          responseData?.errors?.bank_account_number?.[0] ||
          responseData?.errors?.beneficiary_account?.[0] ||
          responseData?.errors?.permanent_account_number?.[0] ||
          responseData?.errors?.[0]?.gstin_detail?.base ||
          responseData?.errors?.base?.[0];
        } else {
          errorMessage = `HTTP error! status: ${response.status}`;
        }

        toast.error(errorMessage, toast_position);
        return rejectWithValue(errorMessage);
      }
      else {
        return responseData;        
      }          
    } catch (error) {
        return console.log("error", error);
    }
  }
);

const beneCreateAccountSlice = createSlice({
  name: "createBeneAcc",
  initialState: {
    beneficiary: null,
    isError: false,
    beneSuccessData: null,
    isSuccessLoading: null,
    isSuccessError: null,
    isSuccessErrorData: null,
    beneEditDetailBankData: null,
    beneEditDetailBankLoading: null,
    beneEditDetailBankError: null,
    editBeneficiaryData: null,
    editBeneficiaryLoading: null,
    editBeneficiaryError: null,
    editContactBeneficiaryData: null,
    editContactBeneficiaryLoading: null,
    editContactBeneficiaryError: null,
  },
  reducers: {
    resetEditBeneficiary(state) {
      state.editBeneficiaryData = null;
    },
    resetEditBankBeneficiary(state) {
      state.beneEditDetailBankData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editBeneficiary.pending, (state) => {
      state.editBeneficiaryLoading = true;
    });
    builder.addCase(editBeneficiary.fulfilled, (state, action) => {
      state.editBeneficiaryLoading = false;
      state.editBeneficiaryData = action.payload;
    });
    builder.addCase(editBeneficiary.rejected, (state, action) => {
      state.editBeneficiaryError = true;
    });

    builder.addCase(beneCreateAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(beneCreateAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.beneficiary = action.payload;
      if (action.payload?.errors)
        toast.error(action.payload.errors.base[0], toast_position);
    });
    builder.addCase(beneCreateAccount.rejected, (state, action) => {
      state.isError = true;
    });
    builder.addCase(beneSuccessDetail.pending, (state) => {
      state.isSuccessLoading = true;
    });
    builder.addCase(beneSuccessDetail.fulfilled, (state, action) => {
      state.isSuccessErrorData = null;
      state.isSuccessLoading = false;
      state.beneSuccessData = action.payload;
    });
    builder.addCase(beneSuccessDetail.rejected, (state, action) => {
      state.isSuccessErrorData = action.payload;
      state.isSuccessError = true;
      state.beneSuccessData = null;
    });
    builder.addCase(beneEditDetailBank.pending, (state, action) => {
      state.beneEditDetailBankLoading = true;
    });
    builder.addCase(beneEditDetailBank.fulfilled, (state, action) => {
      state.beneEditDetailBankLoading = false;
      state.beneEditDetailBankData = action.payload;
    });
    builder.addCase(beneEditDetailBank.rejected, (state, action) => {
      state.beneEditDetailBankError = true;
      state.beneEditDetailBankLoading = false;
    });
  },
});

export const { resetEditBeneficiary, resetEditBankBeneficiary } =
  beneCreateAccountSlice.actions;

export default beneCreateAccountSlice.reducer;
