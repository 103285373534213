import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentMethodCard from "../../../components/CreateTxn/CreateVendorTxn/PaymentMethodCard";
import {
  cardSettle,
  getFFTxnPayMethods,
  getSettleType,
  instaPaySettle,
  settleFFTxns,
  wireSettle,
} from "../../../redux/features/settleTxn/settleTxn";
import formatCurrency from "../../../utils/CurrencyFormater";
import "./index.css";
import NextDate from "../../../components/NextDate";
import { useNavigate } from "react-router-dom";
import { setBufferState } from "../../../redux/features/bufferState/bufferState";
import { toast } from "react-toastify";
import InstantPaySettleTransactionStatus from "../../../containers/CreateTxn/InstantPayRefill/SettleTransactionStatus";

const SettlePaymentOptions = (props) => {
  const [showSettlementStatus, setShowSettlementStatus] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const settlementType = useSelector((state) => state.settleTxn.type);
  const cardResponse = useSelector((state) => state.settleTxn.settleCardRes);
  const NeftRtgsResponse = useSelector((state) => state.settleTxn.settleWireRes);
  const InstantPayResponse = useSelector((state) => state.settleTxn.settleInstantPayRes);
  const cardResLoading = useSelector((state) => state.settleTxn.isLoading)
  const NeftRtgsResLoading = useSelector((state) => state.settleTxn.isLoading);
  const InstantPayResLoading = useSelector((state) => state.settleTxn.isLoading);
  const [isActive, setIsActive] = useState(null);
  const [codeVerified, setCodeVerified] = useState(false);
  const [checkRes, setCheckRes] = useState(false);
  const settleTypeData = settlementType ? settlementType.settle_type : [];
  const FFSettleRes = useSelector((state) => state.settleTxn.FFSettleRes);

  const [cvv, setCvv] = useState(null);

  useEffect(() => {
    if (props?.container === "FF") dispatch(getFFTxnPayMethods());
    else dispatch(getSettleType());
  }, [dispatch]);

  useEffect(() => {
    setCodeVerified(false);
    setCvv(null)
  }, [isActive]);

  useEffect(() => {
    settleTypeData?.forEach((item) => {
      if (item.settlement_id === isActive && item.settlement_type === "NEFT/RTGS") {
        props.setTxnCompletionDate(1)
        return;
      } if (item.settlement_id === isActive) {
        props.setTxnCompletionDate(2)
        return;
      }
    })
  }, [isActive]);

  const handlePayButton = () => {
    dispatch(setBufferState({
      txnDetails: {
        amount: props.amount,
        txn_id: props.totalSelected[0],
        totalSelected: props.totalSelected.length,
        vendor_name: props.vendor_name,
        vendor_bank_acc_no: props.vendor_bank_acc_no,
        vendor_ifsc_code: props.vendor_ifsc_code,
        vendor_bank_name: props.vendor_bank_name,
        module: props.container === "FF" ? "FF" : "Vendor",
      }
    }));
    if (props.container === "FF") dispatch(settleFFTxns({
      txn_id: props.totalSelected,
      settlement_type: settleTypeData?.filter((item) => {
        return (item.settlement_id === isActive)
      })[0].settlement_method,
    }))
    else {
      if (isActive === "Instant Pay" && codeVerified) {
        dispatch(
          instaPaySettle({ txn_id: props.totalSelected })
        );
      }
      if (isActive !== "Instant Pay") {
        settleTypeData?.forEach((item) => {
          if (
            item.settlement_id === isActive &&
            item.settlement_type === "NEFT/RTGS"
          ) {
            dispatch(
              wireSettle({
                account_pin: item.settlement_title,
                source_owner_id: item.settlement_id,
                txn_id: props.totalSelected,
              })
            );
          }
          if (
            item.settlement_id === isActive &&
            item.settlement_type === "Registered Card"
          ) {
            dispatch(
              cardSettle({
                cvv: cvv,
                settlement_id: item.settlement_id,
                settlement_type: item.settlement_method,
                txn_id: props.totalSelected
              }
              )
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    if (checkRes && cardResponse?.errors) {
      toast.error((Object.values(cardResponse.errors)[0][0]), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && cardResponse?.status) navigate("/payments/card");
    setCheckRes(true);
  }, [cardResponse])

  useEffect(() => {
    if (checkRes && NeftRtgsResponse?.errors) {
      toast.error((Object.values(NeftRtgsResponse.errors)[0][0]), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    }
    if (checkRes && NeftRtgsResponse?.status) {
      props.module === "IPsettle" ? setShowSettlementStatus(true) : navigate("/transaction/status")
      setCheckRes(true);
    }
  }, [NeftRtgsResponse])

  useEffect(() => {
    if (checkRes && InstantPayResponse?.errors) {
      toast.error((Object.values(InstantPayResponse.errors)[0][0]), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && InstantPayResponse?.status) {
      props.module === "IPsettle" ? setShowSettlementStatus(true) : navigate("/transaction/status")
      setCheckRes(true);
    }
  }, [InstantPayResponse])

  useEffect(() => {
    if (checkRes && FFSettleRes?.errors) {
      toast.error((Object.values(FFSettleRes.errors)[0][0]), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && FFSettleRes?.status) {
      navigate("/transaction/status")
      setCheckRes(true);
    }
  }, [FFSettleRes])


  let RegisteredCardCount = 0;
  let NEFTRTGSCount = 0
  return !showSettlementStatus ? (
    <>
      <div>
        <h1 className="paymenthodTextSettle p-8 3xl:p-10 4xl:p-12 5xl:p-14 6xl:p-18 mt-24" >Payment method</h1></div>
      <Box
        className="paymentCardsContainer"
        sx={{
          height: "100%",
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <Box>
          {settleTypeData && props.module != "IPsettle" &&
            settleTypeData.map((item) => {
              if (item.settlement_type === "Instant Pay")
                return (
                  <>
                    <Box className="paymentCardHeading">
                      <Box>{item.settlement_type}</Box>
                      {props.amount > +item.current_balance ? (
                        <Box sx={{ color: "rgb(252, 77, 77)" }}>
                          Insufficient balance
                        </Box>
                      ) : (
                        <Box sx={{ color: "#4DE659" }}>Instant transfer</Box>
                      )}
                    </Box>
                    <PaymentMethodCard
                      key={item.id}
                      txn_amount={props.amount}
                      settlement_id={item.settlement_id}
                      settlement_title={item.settlement_title}
                      settlement_method={item.settlement_method}
                      settlement_type={item.settlement_type}
                      account_holder_name={item.account_holder_name}
                      is_primary={item.is_primary}
                      current_balance={item.current_balance}
                      isActive={isActive}
                      setCodeVerified={setCodeVerified}
                      setIsActive={setIsActive}
                    />
                    <br />
                  </>
                );
            })}
        </Box>
        <Box>
          {settleTypeData &&
            settleTypeData.map((item) => {
              if (item.settlement_type === "Registered Card") {
                RegisteredCardCount++;
                return (
                  <>
                    <Box className="paymentCardHeading">
                      <Box>{RegisteredCardCount === 1 ? "Preferred Card" : "All Cards"}</Box>
                      {props.amount > +item.current_balance ? (
                        <Box sx={{ color: "#FC4D4D" }}>
                          Insufficient balance
                        </Box>
                      ) : (
                        <Box style={{ color: "#FFCC00" }}>
                          <span>Completed by: </span><NextDate />
                        </Box>
                      )}
                    </Box>
                    <PaymentMethodCard
                      key={item.id}
                      setCvv={setCvv}
                      RegisteredCardCount={RegisteredCardCount}
                      txn_amount={props.amount}
                      settlement_id={item.settlement_id}
                      settlement_title={item.settlement_title}
                      settlement_method={item.settlement_method}
                      settlement_type={item.settlement_type}
                      account_holder_name={item.account_holder_name}
                      is_primary={item.is_primary}
                      current_balance={item.current_balance}
                      isActive={isActive}
                      setCodeVerified={setCodeVerified}
                      setIsActive={setIsActive}
                    />
                    <br />
                  </>
                );
              }
            })}
        </Box>
        <Box>
          {settleTypeData &&
            settleTypeData.map((item) => {
              if (item.settlement_type === "NEFT/RTGS") {
                NEFTRTGSCount++;
                return (
                  <>
                    {NEFTRTGSCount === 1 ? <Box className="paymentCardHeading">
                      <Box>{item.settlement_type}</Box>
                      {props.amount > +item.current_balance ? (
                        <Box sx={{ color: "#FC4D4D" }}>
                          Insufficient balance
                        </Box>
                      ) : (
                        <Box style={{ color: "#FFA000" }}>
                          <span>Completed by: </span>  <NextDate Tplus2 />
                        </Box>
                      )}
                    </Box> : null}
                    <PaymentMethodCard
                      key={item.id}
                      txn_amount={props.amount}
                      settlement_id={item.settlement_id}
                      settlement_title={item.settlement_title}
                      settlement_method={item.settlement_method}
                      settlement_type={item.settlement_type}
                      account_holder_name={item.account_holder_name}
                      is_primary={item.is_primary}
                      maintenance_account={item.maintenance_account}
                      current_balance={item.current_balance}
                      isActive={isActive}
                      setCodeVerified={setCodeVerified}
                      setIsActive={setIsActive}
                    />
                    <br />
                  </>
                );
              }
            })}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: (codeVerified || cvv?.length === 3) && !(cardResLoading || NeftRtgsResLoading || InstantPayResLoading) ? "#69B76F" : null,
          pointerEvents: (codeVerified || cvv?.length === 3) && !(cardResLoading || NeftRtgsResLoading || InstantPayResLoading) ? "auto" : "none",
          border: (codeVerified || cvv?.length === 3) && !(cardResLoading || NeftRtgsResLoading || InstantPayResLoading) ? "none" : "solid 1px rgba(246, 244, 244, 0.588)",
          opacity: "0.79px"
        }}
        className="paymentProceedBTSettle my-6"
        onClick={() => {
          handlePayButton();
        }}
      >
        <div className="flex justify-center items-center" >
          {!(cardResLoading || NeftRtgsResLoading || InstantPayResLoading) && <span>Pay ₹ {formatCurrency(props.amount)}</span>}
          {(cardResLoading || NeftRtgsResLoading || InstantPayResLoading) &&
            <span className="mx-10 w-[7%]" >
              {
                <img width="100%" src={require("../../../assets/Approve transactions/spinner.gif")} alt="" />
              }
            </span>}
        </div>
      </Box>
    </>
  ) : (
    <Box sx={{ marginTop: "-120px" }}>
      <InstantPaySettleTransactionStatus
        type="settlement"
        txn_id={props.totalSelected}
        txn_status={"under Processing!"}
        account={
          cardResponse?.account_details.bank_acc_no || NeftRtgsResponse?.account_details.bank_acc_no
        }
        amount={props?.amount}
        bank_name={
          cardResponse?.account_details.bank_name || NeftRtgsResponse?.account_details.bank_name
        }
      />
    </Box>
  );
}
export default SettlePaymentOptions
