const checkIfEnable = (mapped_obj, label, check_list) => {
  var checked_in_list = check_list || [];
  if (label in mapped_obj) {
    return checked_in_list.includes(mapped_obj[label]);
  } else {
    return false;
  }
};

export default checkIfEnable;
