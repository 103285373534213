export const bankLogoMapper = (type) => {
  if (type === "Any") return "Status - pending";
  else if (type === "Axis Bank" || type === "UTIB") return "axis";
  else if (type === "Bandhan Bank" || type === "BDBL") return "bandhan";
  else if (type === "Hdfc Bank" || type === "HDFC") return "hdfcbank";
  else if (type === "HDFC BANK") return "hdfcbank";
  else if (type === "Icici Bank Limited" || type === "ICIC") return "icici";
  else if (type === "Icici Bank") return "icici";
  else if (type === "Idbi" || type === "IBKL" || type === "IDBI") return "IDBI";
  else if (type === "Idbi Bank") return "IDBI";
  else if (type === "Punjab National Bank" || type === "PUNB") return "pnb";
  else if (type === "Punjab National Bank - Retail Banking") return "pnb";
  else if (type === "State Bank Of India" || type === "SBIN") return "sbi";
  else if (type === "STATE BANK OF INDIA") return "sbi";
  else if (type === "Yes Bank" || type === "YESB") return "yes-bank";
  else if (type === "Yes Bank Ltd") return "yes-bank";
  else if (type === "Emirates Bank") return "Emirates";
  else if (type === "EMIRATES NBD BANK") return "Emirates";
  else if (type === "Emirates Nbd Bank") return "Emirates";
  else if (type === "Bank Of America") return "BOA-1";
  else if (
    type === "Bank Of Baroda" ||
    type === "Bank of Baroda" ||
    type === "BARB"
  )
    return "bank-of-baroda";
  else if (type === "Bank Of Baroda - Corporate Banking")
    return "bank-of-baroda";
  else if (type === "Bank Of Baroda - Retail Banking") return "bank-of-baroda";
  else if (type === "Bank Of India" || type === "BKID") return "bank-of-india";
  else if (type === "Bank Of Maharashtra" || type === "MAHB")
    return "bank-of-maharashtra";
  else if (type === "Barclays Bank") return "barclays";
  else if (type === "Canara Bank" || type === "CNRB") return "canara-bank";
  else if (type === "Central Bank Of India" || type === "CBIN")
    return "central-bank-of-india";
  else if (type === "Citi Bank" || type === "CITI" || type === "CIUB")
    return "citi-bank";
  else if (type === "CITI Bank" || type === "CITI" || type === "CIUB")
    return "citi-bank";
  else if (type === "CSB-Bank" || type === "CSBK") return "csk-bank";
  else if (type === "Catholic Syrian Bank") return "csk-bank";
  else if (type === "CUB-logo") return "cub";
  else if (type === "City Union Bank") return "citi-bank";
  else if (type === "Dcb Bank" || type === "DCBL") return "DCB bank";
  else if (type === "Deutsche Bank" || type === "DEUT") return "deutsche";
  else if (type === "Emirates Nbd Bank") return "Emirates";
  else if (type === "Federal Bank" || type === "FDRL") return "FEDRAL";
  else if (type === "Hsbc Bank" || type === "HSBC") return "HSBC";
  else if (type === "Hongkong & Shanghai Banking Corporation") return "HSBC";
  else if (type === "Hsbc Bbank") return "HSBC";
  else if (type === "Hsbc Bank Oman S.A.O.G") return "HSBC";
  else if (type === "Idfc First Bank" || type === "IDFB") return "IDFC";
  else if (type === "Idfc Bank") return "IDFC";
  else if (type === "Idfc First Bank") return "IDFC";
  else if (type === "Indian Bank" || type === "IDIB") return "indian-bank";
  else if (type === "Indian Overseas Bank" || type === "IOBA")
    return "indian overseas bank";
  else if (type === "Indusind Bank" || type === "INDB") return "indusland";
  else if (type === "J.P. Morgan Chase Bank") return "jp-morgan-chase";
  else if (type === "Jp Morgan Chase Bank") return "jp-morgan-chase";
  else if (type === "Jp Morgan Chase Bank Na") return "jp-morgan-chase";
  else if (type === "Jammu And Kashmir Bank" || type === "JAKA")
    return "jk-bank";
  else if (type === "J&K Grameen Bank") return "jk-bank";
  else if (type === "J&K State Co-Operative Bank") return "jk-bank";
  else if (type === "Karnataka Bank" || type === "KARB") return "Karnataka";
  else if (type === "Karnataka Bank Ltd") return "Karnataka";
  else if (type === "Karur Vysya Bank" || type === "KVBL") return "kvb - 1";
  else if (type === "Kotak Mahindra Bank" || type === "KKBK") return "kotak";
  else if (type === "Kotak Mahindra Bank Limited") return "kotak";
  else if (type === "Kotak Bank") return "kotak";
  else if (type === "Punjab And Sind Bank" || type === "PSIB")
    return "punjab-sind-bank";
  else if (type === "Punjab & Sind Bank") return "punjab-sind-bank";
  else if (type === "Rbl Bank" || type === "RATN") return "rbl";
  else if (type === "RBL Bank" || type === "RATN") return "rbl";
  else if (type === "Rbl Bank Limited") return "rbl";
  else if (type === "South Indian Bank" || type === "SIBL")
    return "south-indian-bank";
  else if (type === "State Bank Of India") return "sbi";
  else if (type === "Uco Bank" || type === "UCBA") return "uco";
  else if (type === "Union Bank Of India" || type === "UBIN")
    return "union-bank";
  else if (type === "Thane Janata Sahakari Bank" || type === "TJSB")
    return "thane Janata sahakari bank";
  else if (type === "Syndicate Bank" || type === "SYNB")
    return "syndicate bank";
  else if (type === "Standard Chartered Bank" || type === "SCBL")
    return "Standard Charted";
  else if (type === "Saraswat Co-Operative Bank" || type === "SRCB")
    return "saraswat co-operative bank";
  else if (type === "Saraswat Bank") return "saraswat co-operative bank";
  else if (type === "Cosmos Co-Operative Bank" || type === "COSB")
    return "Cosmos co-operative bank";
  else if (type === "Bharat Co-Operative Bank" || type === "BCBM")
    return "bharat cooperative bank";
  else if (type === "Allahabad Bank" || type === "ALLA")
    return "Allahabad_Bank_Logo";
  else if (type === "Credit Suisse Ag" || type === "CRES")
    return "Credit Suisse Ag";
  else if (type === "Dena Bank" || type === "BKDN") return "Dena Bank";
  else if (
    type === "Kanara District Central Co-Operative Bank" ||
    type === "KSCB"
  )
    return "Kanara District Central Co-Operative Bank";
  else if (type === "National Co-Operative Bank" || type === "KKBK")
    return "National Co-Operative Bank";
  else if (type === "Reserve Bank of India" || type === "RBIS")
    return "Reserve Bank of India";
  else if (type === "Andhra Bank" || type === "ANDB") return "Andhra bank";
  else if (type === "Equitas Small Finance Bank" || type === "ESFB")
    return "Equitas small finance bank";
  else if (type === "Lakshmi Vilas Bank" || type === "LAVB")
    return "Lakshmi Vilas Bank";
  else if (type === "Tamilnad Mercantile Bank Ltd" || type === "TMBL")
    return "Tamilnad Mercantile Bank Ltd";
  else if (type === "Bank of India - Corporate" || type === "BKID")
    return "bank-of-india";
  else if (type === "State Bank of India - Corporate" || type === "SBIN")
    return "sbi";
  else if (type === "Union Bank of India - Corporate" || type === "UBIN")
    return "union-bank";
  else if (type === "Axis Bank Corporate") return "axis";
  else if (type === "ICICI Corporate Netbanking") return "icici";
  else if (type === "Yes Bank Corporate") return "yes-bank";
  else if (type === "Barclays Corporate- Corporate Banking - Corporate")
    return "barclays";
  else if (type === "Indian Overseas Bank Corporate")
    return "indian overseas bank";
  else if (type === "City Union Bank of Corporate") return "cub";
  else if (type === "HDFC Corporate") return "hdfcbank";
  else if (type === "Hdfc Corporate") return "hdfcbank";
  else if (type === "Bandhan Bank - Retail Net Banking") return "bandhan";
  else if (type === "HSBC Retail Netbanking") return "HSBC";
  else if (type === "Shamrao Vithal Bank Corporate" || type === "SVCB")
    return "svc bank";
  else if (type === "Axis Corporate Net Banking") return "axis";
  else if (type === "Andhra Bank Corporate") return "Andhra bank";
  else if (type === "Bandhan Bank Corporate") return "bandhan";
  else if (type === "Bank of Baroda - Corporate Banking")
    return "bank-of-baroda";
  else if (type === "Bank of Baroda - Retail Banking") return "bank-of-baroda";
  else if (type === "Bank of India") return "bank-of-india";
  else if (type === "Bank of Maharashtra") return "bank-of-maharashtra";
  else if (type === "Central Bank of India") return "central-bank-of-india";
  else if (type === "Cosmos Bank") return "Cosmos co-operative bank";
  else if (type === "DCB Bank") return "DCB bank";
  else if (type === "Federal Bank Scan ") return "FEDRAL";
  else if (type === "HDFC Bank") return "hdfcbank";
  else if (type === "ICICI Bank") return "icici";
  else if (type === "IDBI Bank") return "IDBI";
  else if (type === "IDFC First Bank") return "IDFC";
  else if (type === "IndusInd Bank") return "indusland";
  else if (type === "Laxmi Vilas Bank - Corporate Net Banking")
    return "Lakshmi Vilas Bank";
  else if (type === "Laxmi Vilas Bank - Retail Net Banking")
    return "Lakshmi Vilas Bank";
  else if (type === "Punjab National Bank - Corporate Banking") return "pnb";
  else if (type === "RBL Bank Limited") return "rbl";
  else if (type === "Shamrao Vithal Co-op Bank") return "svc bank";
  else if (type === "State Bank of India") return "sbi";
  else if (type === "TJSB Bank") return "KJSB";
  else if (type === "UCO Bank") return "uco";
  else if (type === "Union Bank of India") return "union-bank";
  else if (type === "AU Small Finance" || type === "AUBL")
    return "au small finance bank";
  else if (type === "Bank of Bahrain and Kuwait" || type === "BBKM")
    return "Bank of Bahrain and Kuwait";
  else if (type === "Bassien Catholic Co-Operative Bank" || type === "BACB")
    return "Bassien Catholic Co-Operative Bank";
  else if (type === "Bharatiya Mahila Bank" || type === "BMBL")
    return "Bharatiya Mahila Bank";
  else if (type === "Corporation Bank" || type === "CORP")
    return "Corporation Bank";
  else if (type === "DBS Bank" || type === "DBSS") return "DBS bank";
  else if (type === "Development Credit Bank") return "dcb";
  else if (type === "Development Credit Bank - Corporate") return "dcb";
  else if (type === "Dhanlakshmi Bank" || type === "DLXB")
    return "dhanlaxmi bank";
  else if (type === "Dhanlaxmi Bank Corporate") return "dhanlaxmi bank";
  else if (type === "Fincare Bank" || type === "FSFB") return "Fincare Bank";
  else if (
    type === "Gujarat State Co-operative Bank Limited" ||
    type === "GSCB"
  )
    return "gujarat state co-operative bank";
  else if (type === "Jammu  Bank") return "jk-bank";
  else if (type === "Janata Sahakari Bank Ltd Pune" || type === "JSBP")
    return "Janata Sahakari Bank Ltd Pune";
  else if (type === "KJSB Bank" || type === "KJSB") return "KJSB";
  else if (type === "Karnataka Gramin Bank" || type === "PKGB")
    return "Karnataka Gramin Bank";
  else if (type === "Mehsana urban Co-op Bank" || type === "MSNU")
    return "Mehsana urban Co-op Bank";
  else if (type === "NKGSB Co-op Bank" || type === "NKGS") return "nkgsb-bank";
  else if (type === "Oriental Bank of Commerce" || type === "ORBC")
    return "Oriental Bank of Commerce";
  else if (type === "Punjab  Bank") return "punjab-sind-bank";
  else if (type === "Punjab  Co-op Bank") return "Status - pending";
  else if (type === "Ratnakar Corporate Banking") return "rbl";
  else if (type === "Royal Bank Of Scotland") return "RoyalBank";
  else if (type === "Shivalik Bank" || type === "SMCB") return "Shivalik";
  else if (type === "State Bank of Bikaner and Jaipur" || type === "SBBJ")
    return "SBBJ";
  else if (type === "State Bank of Hyderabad") return "SBH";
  else if (type === "State Bank of Mysore") return "SBM";
  else if (type === "State Bank of Patiala") return "SBP";
  else if (type === "State Bank of Travancore") return "sbi";
  else if (type === "Tamil Nadu State Co-operative Bank" || type === "TNSC")
    return "TNSbank";
  else if (
    type === "The Kalupur Commercial Cooperative Bank Limited" ||
    type === "KCCB"
  )
    return "KalupurCommercial";
  else if (
    type === "The Surat People's Co - operative Bank Limited" ||
    type === "SPCB"
  )
    return "SuratPeople";
  else if (type === "United Bank of India" || type === "UTBI")
    return "UnitedBankofIndia";
  else if (type === "Utkarsh Small Finance Bank" || type === "UTKS")
    return "Utkarshinance";
  else if (type === "Vijaya Bank" || type === "VIJB") return "VijayaBank";
  else return "Bank_default";
};
