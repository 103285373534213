import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const accountViewAllDetails = createAsyncThunk(
  "accountViewAllDetails",
  async (values) => {
    const body =
    {
      page_no: values?.page_no || 0,
      page_size: 12,
      search_string: values?.search_string,
    };
    console.log("CARD STATUSES ", values);
    if (values?.account_id && values?.account_type == "owner")
      body.owner_id = values?.account_id;

    if (values?.account_id && values?.account_type == "vendor")
      body.vendor_id = values?.account_id;

    if (values?.account_id && values?.account_type == "client")
      body.client_id = values?.account_id;

    if (values?.approval_status && values?.account_type != "cards")
      body.approval_status = values?.approval_status;

    if (values?.account_type == "cards")
      body.status = values?.status;

    if (values?.account_id && values?.account_type == "field_force")
      body.account_id = values?.account_id;

    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(body),
    };
    console.log("ACCOUNT request options ", requestOptions, `${legacyUrl}/accounts/${values.account_type}/view`);
    if (values.account_type != "cards") {
      return await fetch(
        `${legacyUrl}/accounts/${values.account_type}/view`,
        requestOptions
      )
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    } else {
      return await fetch(
        `${legacyUrl}/cards/view`,
        requestOptions
      )
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    }
  }
);

const accountViewAllSlice = createSlice({
  name: "accountViewAll",
  initialState: {
    isLoading: false,
    records: {},
    total_count: 0,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(accountViewAllDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(accountViewAllDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("REDUX ACCOUNT VIEW ALL", action.payload, action.payload.accounts);
      let payload = action.payload;
      let data = payload.accounts || payload.clients || payload.cards;
      console.log("REDUX DATA", data);
      state.records = data;
      if (Object.keys(data).length > 0)
        state.total_count = action.payload.title.records;
    });
    builder.addCase(accountViewAllDetails.rejected, (state, action) => {
      console.log("ERROR", action.payload);
      state.isError = true;
    });
  },
});

export default accountViewAllSlice.reducer;
