export const VendorAccountStatusTextMapper = (status) => {
  switch (status) {
    case "Y":
      return "Approved";
    case "N":
      return "Denied";
    case "Blocked":
      return "Blocked";
    case "Archived":
      return "Archived";
    case "P":
      return "Pending";
    default:
      return "";
  }
};

export const AccountStatusTextMapper = (accountType, status) => {
  switch (accountType) {
    case "vendor":
      return VendorAccountStatusTextMapper(status);
    case "field_force":
      return VendorAccountStatusTextMapper(status);
    case "client":
      return ClientAccountStatusTextMapper(status);
    case "owner":
      return OwnerAccountStatusTextMapper(status);
    case "cards":
      return CardAccountStatusTextMapper(status);
    default:
      return "";
  }
};


export const CardAccountStatusTextMapper = (status) => {
  switch (status) {
    case "Active":
      return "Active";
    case "Blocked":
      return "Blocked";
    case "Inactive":
      return "Inactive";
    default:
      return "";
  }
};

export const ClientAccountStatusTextMapper = (status) => {
  switch (status) {
    case "Y":
      return "Approved";
    case "N":
      return "Denied";
    case "Blocked":
      return "Blocked";
    case "Archived":
      return "Archived";
    default:
      return "";
  }
};

export const OwnerAccountStatusTextMapper = (status) => {
  switch (status) {
    case "Y":
      return "Approved";
    case "N":
      return "Denied";
    case "Blocked":
      return "Blocked";
    case "Archived":
      return "Archived";
    case "P":
      return "Pending";
    default:
      return "";
  }
};

export const AccountTypeApprovalStatusMap = (accountType) => {
  switch (accountType) {
    case "vendor":
      return {
        Y: "Approved",
        P: "Pending",
        N: "Denied",
        Blocked: "Blocked",
        Archived: "Archived",
      };
    case "field_force":
      return {
        Y: "Approved",
        N: "Denied",
        Blocked: "Blocked",
        Archived: "Archived",
        P: "Pending",
      };
    case "client":
      return {
        Y: "Approved",
        N: "Denied",
        Blocked: "Blocked",
        Archived: "Archived",
      };
    case "owner":
      return {
        Y: "Approved",
        Blocked: "Blocked",
        Archived: "Archived",
        P: "Awating Activation",
        D: "Activation Denied",
        F: "Account Deactivated",
      };
    case "cards":
      return {
        Active: "Active",
        Blocked: "Blocked",
        Inactive: "Inactive",
      };
    default:
      return {};
  }
};