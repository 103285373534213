import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SettlePayment from "../../../SettlementRequired/SettlePayment";
import { getFieldForcePendingAccount } from "../../../../redux/features/accounts/Vendor/Approval/pendingAccount";
import AccountsDeniedReason from "../../../../components/Accounts/Vendor/AccountsDeniedReson/DeniedReason";
import FFAccountApprovalCardsCarousel from "../../../../components/Accounts/FieldForceAccount/AccountApprovalDetailsCarousel";
import MultiSelectFieldForceApproval from "../MultiSelectAccountApproval";

const FieldForceAccountApproval = () => {
  const dispatch = useDispatch();
  const [selectedTxnCarousel, setSelectedTxnCarousel] = useState({});
  const [showMultiple, setShowMultiple] = useState(false);
  const [deniedReason, setDeniedReason] = useState(false);
  const [deniedReasonData, setDeniedReasonData] = useState({});
  const [showSettlementComponent, setShowSettlementComponent] = useState(false);
  const [page, setPage] = useState(0);
  const fieldForcePendingApprovals = useSelector(
    (state) => state.pendingAccount.fieldForce
  );
  const [vendorData, setVendorData] = useState([]);

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    let j = 0;
    appendedData?.forEach((item) => {
      if (
        !deDupData?.find(
          (deDupItem) => deDupItem.account_id === item.account_id
        )
      ) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    appendedData.sort(
      (timestamp, nextTimestamp) =>
        Number(nextTimestamp.txn_id) - Number(timestamp.txn_id)
    );
    return appendedData;
  };

  useEffect(() => {
    setVendorData((prevVendorData) => {
      return dataAppend(
        prevVendorData,
        fieldForcePendingApprovals?.accounts || []
      );
    });
  }, [fieldForcePendingApprovals, vendorData]);

  useEffect(() => {
    if (showMultiple) {
      setPage(1);
      setVendorData([]);
    }
    dispatch(getFieldForcePendingAccount({ page_no: page }));
  }, [dispatch, showMultiple, page]);

  return !showSettlementComponent ? (
    <div className="min-h-screen h-full overflow-y-scroll bg-[#364949] vendor-txn hide-scrollbar">
      <Box className="selectTxnsHeadingBoxMobile">
        <Box sx={{ display: "flex" }}>
          <Button className="selectTxnSearch">
            <img
              className="selectTxnSearchImg"
              src={require("../../../../assets/Approve transactions/Close.png")}
              alt=""
              srcSet=""
            />
          </Button>
          &nbsp; &nbsp;
          <Box className="selectTxnsHeading">Field Force Account</Box>
        </Box>
      </Box>
      {deniedReason && (
        <AccountsDeniedReason
          setDeniedReason={setDeniedReason}
          account_id={deniedReasonData.account_id}
          dispatchFn={deniedReasonData.dispatchFn}
          setShowStatusCard={deniedReasonData.setShowStatusCard}
          isFFAccount={true}
        />
      )}
      <Box className="h-full bg-[#364949]">
        {showMultiple ? (
          <MultiSelectFieldForceApproval data={vendorData} />
        ) : (
          <FFAccountApprovalCardsCarousel
            records={fieldForcePendingApprovals?.title.records}
            page={page}
            setPage={setPage}
            setSelectedTxnCarousel={setSelectedTxnCarousel}
            setShowSettlementComponent={setShowSettlementComponent}
            setDeniedReason={setDeniedReason}
            setDeniedReasonData={setDeniedReasonData}
            data={vendorData}
          />
        )}
      </Box>
      <Button
        onClick={() => setShowMultiple(!showMultiple)}
        className="showMultipleBT"
      >
        <img
          className="selectTxnSearchImg"
          src={require("../../../../assets/Approve transactions/Task switcher.png")}
          alt=""
          srcSet=""
        />
      </Button>
    </div>
  ) : (
    <SettlePayment getSelected={() => selectedTxnCarousel} />
  );
};

export default FieldForceAccountApproval;
