import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBufferState } from "../../../../../../redux/features/bufferState/bufferState";
import accTransform from "../../../../../../utils/accTransform";
import "./index.css";
import { bankLogoMapper } from "../../../../../../services/bankLogoMapper";

const ExistingVendorAcc = (props) => {

  const DebitAcc = useSelector((state) => state.bufferState.internalDebit);
  const CreditAcc = useSelector((state) => state.bufferState.internalCredit);
  const clientAcc = useSelector((state) => state.bufferState.clientAcc)
  const dispatch = useDispatch();
  const handleVendorSelection = () => {
    if (!(props.value.approval_status === "Y" || props.value.approval_status === "1"))
      return;
    if (props.componentType === "Credit") dispatch(setBufferState({ internalCredit: props.value,internalDebit:DebitAcc }))
    if (props.componentType === "Debit") dispatch(setBufferState({ internalDebit: props.value, internalCredit: CreditAcc }));
    if (props.componentType === "Client" || props.componentType === "Owner" ) dispatch(setBufferState({ clientPrimaryAcc: props.value, clientAcc: clientAcc }));
    props.handleClose();
  }

  return (
    < div
      className={`flex flex-row bg-custom-lightGray  w-full h-fit justify-between
        ${(props.value.approval_status === "Y" || props.value.approval_status === "1") && "cursor-pointer"}
        ${!(props.value.approval_status === "Y" || props.value.approval_status === "1") && "opacity-40"}
        ${props.value.owner_id === DebitAcc?.owner_id && "border-2 border-custom-red"}
        ${props.value.owner_id === CreditAcc?.owner_id && "border-2 border-custom-green"}
        rounded-large 4xl:rounded-customXl 6xl:rounded-custom2Xl overflow-hidden drop-shadow-xl text-custom-offwhite
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40
        text-base 3xl:text-xl`}
      onClick={handleVendorSelection} >
      <div className="w-2/5 self-center truncate ...
       text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl
       px-6 py-2 3xl:px-8 3xl:py-4 5xl:px-10 5xl:py-10 capitalize font-bold">
        {accTransform(props.value.bank_acc_no)}
      </div>
      <div className="w-3/5 h-full flex flex-row justify-end gap-6 3xl:gap-8">
        {
          props.value.available_balance &&
          <div className="p-2 w-2/3 self-center text-right text-[#4DE659] antaliased text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl">
          ₹ {props.value.available_balance}
          </div>
        }
        <div className="h-full px-6 w-1/3 flex bg-white rounded-l-large 4xl:rounded-l-customXl 6xl:rounded-l-custom2Xl">
          <img
            className="self-center"
            src={(require("../../../../../../assets/Banks/" + bankLogoMapper(props.value.bank_name) + ".png"))}
            alt="banklogo"
          />
        </div>
      </div>
    </div >
  );
};

export default ExistingVendorAcc;

