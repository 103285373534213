import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import loactionImg from "../../../assets/Beneficiary Icons/location.png";

const defaultPosition = [19.076, 72.8777];

const ChangeView = ({ center }) => {
  const map = useMap();
  map.setView(center, map.getZoom());
  return null;
};

const customIcon = L.icon({
  iconUrl: loactionImg,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

const GeoMapLeaflet = (props) => {
  const [position, setPosition] = useState(defaultPosition);

  // useEffect(() => {
  //   const address = props.address;
  //   const geocodeAddress = async (address) => {
  //     const response = await fetch(
  //       `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
  //         address
  //       )}`
  //     );
  //     const data = await response.json();
  //     if (data.length > 0) {
  //       const { lat, lon } = data[0];
  //       setPosition([parseFloat(lat), parseFloat(lon)]);
  //     }
  //   };

  //   geocodeAddress(address);
  // }, []);

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{
        height:
          props.owner === "ownerDetails"
            ? "310px"
            : props.owner === "beneDetails"
            ? "200px"
            : props.owner === "gstDetails"
            ? "225px"
            : "100%",

        width:
          props.owner === "ownerDetails"
            ? "425px"
            : props.owner === "beneDetails"
            ? "332px"
            : props.owner === "gstDetails"
            ? "348px"
            : "100%",
      }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} icon={customIcon}>
        <Popup>The custom icon is here!</Popup>
      </Marker>
      <ChangeView center={position} />
    </MapContainer>
  );
};

export default GeoMapLeaflet;
