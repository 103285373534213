import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import CurrencyFormater from "../../../utils/CurrencyFormater";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SettleSingleTxnStatusCard = (props) => {
  const navigate = useNavigate();
  const txnDetails = props?.txnDetails;

  return (
    <>
      <Box className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <Box className="w-full">
          <Box className="settleAmountText">
            {" "}
            ₹ {CurrencyFormater(txnDetails?.amount)}
          </Box>
          <Box style={{ margin: "8%" }} className="dashedLine"></Box>
          {true ? (
            <Box className="detailsText-count">
              {txnDetails?.totalSelected} transactions
            </Box>
          ) : (
            <Box sx={{ padding: "50px 0" }}>
              Waiting for{" "}
              {txnDetails?.approval_status === "P"
                ? "approval"
                : txnDetails?.approval_status === "Y"
                ? "settlement"
                : null}
            </Box>
          )}
        </Box>
        <Box className="w-full cursor-pointer divide-x-2 divide-white h-[30%] xl:h-[25%] text-white rounded-4xl bg-[#7fb085] flex justify-evenly items-center gap-4">
          <Box
            onClick={() => {
              if (txnDetails?.module === "FF") navigate("/field_force_transactions")
              else
                navigate("/vendor_transactions")
            }}
            className="logoWithText"
            sx={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <img
              src={require("../../../assets/CreateTransactionIcons/Dashboard.png")}
              alt=""
              width="70%"
            />
            <Box
              sx={{ textAlign: "center", font: "normal normal 1rem Open Sans" }}
            >
              Go to <br /> dashboard
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SettleSingleTxnStatusCard;
