import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./index.css";

const SettleIdSearch = () => {
  return (
    <Box
      className="textFieldContainer"
      component="form"
      sx={{
        "& > :not(style)": { m: 1,width: "100%"},
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="filled-basic"
        label="Settle ID"
        variant="filled"
        color=""
        InputProps={{
          endAdornment: (
            <img
              height={"30px"}
              src={require("../../../../assets/ViewAllTransactions/search-transparent.png")}
              alt=""
            />
          ),
        }}
      />
    </Box>
  );
};

export default SettleIdSearch;
