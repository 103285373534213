import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast_position } from "../../Settings/Authorization";
import { toast } from "react-toastify";
import axios from "axios";
import { env } from "../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const beneTxnModalAccount = createAsyncThunk(
  "beneTxnModalAccount",
  async (values) => {
    const { page_no, page_size, approval_status, search_string } = values;
    try {
      const response = await axios.get(`${baseUrl}/accounts/beneficiary`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status: approval_status,
          search_string: search_string,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const beneTxnOwnerAccounts = createAsyncThunk(
  "beneTxnOwnerAccounts",
  async (values, { rejectWithValue }) => {
    const {
      page_no,
      approval_status,
      categoryName,
      page_size,
      search_string,
      bank_name,
    } = values || {};

    try {
      const response = await axios.get(`${baseUrl}/accounts/owner`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status: approval_status,
          category: categoryName,
          search_string: search_string,
          connected_banking: "Y",
          bank_name: bank_name,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error, toast_position);
      return rejectWithValue(error);
    }
  }
);

export const beneTxnOwnerAccountsNonActive = createAsyncThunk(
  "beneTxnOwnerAccountsNonActive",
  async (values, { rejectWithValue }) => {
    const {
      page_no,
      approval_status,
      categoryName,
      page_size,
      search_string,
      bank_name,
    } = values || {};

    try {
      const response = await axios.get(`${baseUrl}/accounts/owner`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_size,
          approval_status: approval_status,
          category: categoryName,
          search_string: search_string,
          connected_banking: "N", // Non-active accounts
          bank_name: bank_name,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.error, toast_position);
      return rejectWithValue(error);
    }
  }
);

export const beneCreateTxn = createAsyncThunk(
  "beneCreateTxn",
  async (values, { rejectWithValue }) => {
    const {
      amount,
      owner_account_id,
      beneficiary_account_id,
      category,
      additional_data,
      payment_method,
    } = values;

    const requestBody = {
      amount: amount,
      owner_account_id: owner_account_id,
      beneficiary_account_id: beneficiary_account_id,
      category: category,
      additional_data: {
        data: additional_data,
      },
      payment_method: payment_method,
    };

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${baseUrl}/transactions/beneficiary`,
        requestOptions
      );
      const responseData = await response.json();
      if (response.ok) {
        return responseData;
      } else {
        const errorMessage =
          responseData?.errors?.category?.[0] ||
          responseData?.errors?.additional_data?.[0] ||
          responseData?.errors?.base[0];
        throw new Error(errorMessage);
      }
    } catch (error) {
      toast.error(error.message, toast_position);
      return rejectWithValue(error.message);
    }
  }
);

export const beneTxnSuccessDetail = createAsyncThunk(
  "beneTxnSuccessDetail",
  async (id, { rejectWithValue }) => {
    try {
      if (!id) {
        throw new Error("Invalid beneficiary id");
      }
      const response = await axios.get(
        `${baseUrl}/transactions/beneficiary/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const beneTransactionAccounts = createSlice({
  name: "beneTransactionAccounts",
  initialState: {
    beneOwnerData: null,
    beneOwnerLoading: null,
    beneOwnerError: null,

    beneOwnerDataNonActive: null,
    beneOwnerLoadingNonActive: null,
    beneOwnerErrorNonActive: null,

    beneTxnAccountData: null,
    beneTxnAccountLoading: null,
    beneTxnAccountError: null,

    beneCreateTxnData: null,
    beneCreateTxnLoading: null,
    beneCreateTxnError: null,

    beneTxnSuccessData: null,
    beneTxnSuccessDataError: null,
    beneTxnSuccessLoading: null,
    beneTxnSuccessError: null,
  },
  reducers: {
    resetBeneOwnerData(state) {
      state.beneOwnerData = null;
    },
    resetBeneOwnerDataNonActive(state) {
      state.beneOwnerDataNonActive = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(beneTxnOwnerAccounts.pending, (state, action) => {
      state.beneOwnerLoading = true;
    });
    builder.addCase(beneTxnOwnerAccounts.fulfilled, (state, action) => {
      state.beneOwnerLoading = false;
      state.beneOwnerData = action.payload;
    });
    builder.addCase(beneTxnOwnerAccounts.rejected, (state, action) => {
      state.beneOwnerError = true;
      state.beneOwnerLoading = false;
    });

    builder.addCase(beneTxnOwnerAccountsNonActive.pending, (state, action) => {
      state.beneOwnerLoadingNonActive = true;
    });
    builder.addCase(
      beneTxnOwnerAccountsNonActive.fulfilled,
      (state, action) => {
        state.beneOwnerLoadingNonActive = false;
        state.beneOwnerDataNonActive = action.payload;
      }
    );
    builder.addCase(beneTxnOwnerAccountsNonActive.rejected, (state, action) => {
      state.beneOwnerErrorNonActive = true;
      state.beneOwnerLoadingNonActive = false;
    });

    builder.addCase(beneTxnModalAccount.pending, (state, action) => {
      state.beneTxnAccountLoading = true;
    });
    builder.addCase(beneTxnModalAccount.fulfilled, (state, action) => {
      state.beneTxnAccountLoading = false;
      state.beneTxnAccountData = action.payload;
    });
    builder.addCase(beneTxnModalAccount.rejected, (state, action) => {
      state.beneTxnAccountError = true;
      state.beneTxnAccountData = null;
    });

    builder.addCase(beneCreateTxn.pending, (state, action) => {
      state.beneCreateTxnLoading = true;
    });
    builder.addCase(beneCreateTxn.fulfilled, (state, action) => {
      state.beneCreateTxnLoading = false;
      state.beneCreateTxnData = action.payload;
    });
    builder.addCase(beneCreateTxn.rejected, (state, action) => {
      state.beneCreateTxnError = true;
      state.beneCreateTxnData = null;
    });
    builder.addCase(beneTxnSuccessDetail.pending, (state, action) => {
      state.beneTxnSuccessLoading = true;
    });
    builder.addCase(beneTxnSuccessDetail.fulfilled, (state, action) => {
      state.beneTxnSuccessLoading = false;
      state.beneTxnSuccessDataError = null;
      state.beneTxnSuccessData = action.payload;
    });
    builder.addCase(beneTxnSuccessDetail.rejected, (state, action) => {
      state.beneTxnSuccessDataError = action.payload;
      state.beneTxnSuccessError = true;
      state.beneTxnSuccessData = null;
    });
  },
});

export const {
  resetVerification,
  resetPANVerification,
  resetBankVerification,
  resetBeneBankData,
  resetBeneOwnerData,
  resetBeneOwnerDataNonActive,
} = beneTransactionAccounts.actions;

export default beneTransactionAccounts.reducer;
