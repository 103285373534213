import { Box } from "@mui/system";
import React from "react";
import "./index.css";
import arrowImg from "../../../assets/TransactionDashboardIcons/arrow_next.png";

const SideDrawerOptions = (props) => {
  return (
    <Box
      className="container-options cursor-pointer"
      sx={{ opacity: props.disable ? "0.2" : "1" }}
      onClick={props.fn}
    >
      <Box>
        <img
          src={require("../../../assets/TxnDetails/" + props.img_path)}
          width="30px"
          height="30px"
          alt="document"
        />{" "}
      </Box>
      <Box className="container-text">{props.text}</Box>
      <Box>
        <img
          src={arrowImg}
          alt="arrowImg"
          style={{ height: "30px", width: "30px" }}
        />
      </Box>
    </Box>
  );
};

export default SideDrawerOptions;
