import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import deleteTxn, {
    deleteAccount,
} from "../../../../redux/features/deleteTxn/deleteTxn";
import { useNavigate } from "react-router-dom";

const EditAccPopUp = (props) => {

    const dispatch = useDispatch();
    const [editOption, setEditOption] = useState(0);
    const navigate = useNavigate();
    return (
        <>
            {/* <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  absolute z-[9999]">
        <div
          className="w-100 h-fit flex flex-col gap-8  py-12 px-4 bg-custom-deniedReasonPurple rounded-4xl"
        > */}

            <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
            <div
                className="flex justify-center items-center m-auto w-2/5 top-1/2 left-1/2 fixed z-[9999]"
                style={{ transform: "translate(-50%, -50%)" }}
            >
                <div className="h-fit w-full flex flex-col gap-2 py-8 px-4 bg-custom-deniedReasonPurple rounded-4xl">
                    <div className="mx-8">
                        <h2 className="text-[#FF7F33] font-semibold text-2xl ">
                            Select the option you would like to edit:
                        </h2>
                    </div>


                    < div
                        onClick={() => setEditOption(1)}
                        className={`flex flex-row h-fit justify-between
        rounded-4xl overflow-hidden drop-shadow-xl  ${editOption === 1 ? 'bg-[#9F7EC0] text-white ' : 'bg-[#FFFFFF]'}
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40 ${0 ? 'pointer-events-none opacity-60 ' : 'cursor-pointer hover:opacity-80'}
        text-base 3xl:text-xl mx-8 pr-4`}
                    >
                        <div className="w-full h-full flex flex-row justify-end gap-6 3xl:gap-8">
                            <div className="w-4/5 m-auto font-semibold self-center antaliased text-xl">
                                IFSC code
                            </div>
                        </div>
                    </div >

                    < div onClick={() => setEditOption(2)}
                        className={`flex flex-row h-fit justify-between
        rounded-4xl overflow-hidden drop-shadow-xl ${editOption === 2 ? 'bg-[#9F7EC0] text-white ' : 'bg-[#FFFFFF] text-[#0A5BE2] '}
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40 ${0 ? 'pointer-events-none opacity-60 ' : 'cursor-pointer hover:opacity-80'}
        text-base 3xl:text-xl mx-8 pr-4`}
                    >
                        <div className="w-full h-full flex flex-row justify-end gap-6 3xl:gap-8">
                            <div className="w-4/5 m-auto font-semibold self-center antaliased text-xl">
                                Phone no, Email, GST.
                            </div>
                        </div>
                    </div >

                    < div
                        onClick={() => setEditOption(3)}
                        className={`flex flex-row h-fit justify-between
        rounded-4xl overflow-hidden drop-shadow-xl  ${editOption === 3 ? 'bg-[#9F7EC0] !text-white ' : 'bg-[#FFFFFF] text-[#0A5BE2]'}
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40 ${0 ? 'pointer-events-none opacity-60 ' : 'cursor-pointer hover:opacity-80'}
        text-base 3xl:text-xl mx-8 pr-4`}
                    >
                        <div className="w-full h-full flex flex-row justify-end gap-6 3xl:gap-8">
                            <div className="w-4/5 m-auto font-semibold self-center antaliased text-xl">
                                <span className={`${editOption === 3 ? 'text-white' : 'text-[#B015FF]'}`} >PAN,</span>  Phone no, Email GST.
                            </div>
                        </div>
                    </div >

                    < div
                        onClick={() => setEditOption(4)}
                        className={`flex flex-row h-fit justify-between
        rounded-4xl overflow-hidden drop-shadow-xl  ${editOption === 4 ? 'bg-[#9F7EC0] !text-white' : 'bg-[#FFFFFF] text-[#0A5BE2]'}
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40 ${0 ? 'pointer-events-none opacity-60 ' : 'cursor-pointer hover:opacity-80'}
        text-base 3xl:text-xl mx-8 pr-4`}
                    >
                        <div className="w-full h-full flex flex-row justify-end gap-6 3xl:gap-8">
                            <div className="w-4/5 m-auto font-semibold self-center antaliased text-xl">
                                <span className={`${editOption === 4 ? 'text-white' : "text-[#FF1515]"}`} >Bank account no, </span>
                                <span className={`${editOption === 4 ? 'text-white' : "text-[#B015FF]"}`} > PAN,</span>  Phone no, Email, GST.
                            </div>
                        </div>
                    </div >
                    {
                        editOption === 1 ?
                            <div className="grid grid-cols-1 mx-10 gap-x-10">
                                <p className="text-white opacity-70 mt-5">
                                    Please contact a Xpentra administrator to change the IFSC detail.
                                </p>
                            </div>
                            :
                            <div className="grid grid-cols-2 mx-8 gap-x-10">
                                <>
                                    <p className="text-white opacity-70 mt-5">
                                        {editOption >= 3 ? " 🟢 " : " ⚫ "}
                                        Requires approval</p>
                                    <p className="text-white opacity-70 mt-5">
                                        {editOption >= 4 ? " 🟢 " : " ⚫ "}
                                        Does not create a new account
                                    </p>
                                    <p className="text-white opacity-70 mt-5">
                                        {editOption >= 4 ? " 🟢 " : " ⚫ "}
                                        Does not archive the old account
                                    </p>
                                </>
                            </div>
                    }
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "5%",
                            margin: "0px 20px",
                            width: "95%",
                        }}
                        className="px-8 pt-4"
                    >
                        <Button
                            sx={{
                                borderRadius: "20px",
                                borderColor: "#fff",
                                color: "#fff",
                                textTransform: "capitalize",
                                textDecoration: "underline",
                            }}
                            onClick={() => props.setShowPopup(false)}
                            className=""
                        >
                            Cancel
                        </Button>
                        <Button
                            className=""
                            variant="outlined"
                            sx={{
                                borderRadius: "20px",
                                backgroundColor: "#9F7EC0",
                                color: "#fff",
                                border: "none",
                                boxShadow: "0px 3px 6px #00000029",
                                textTransform: "capitalize",
                                pointerEvents: editOption > 1 ? "auto" : "none",
                                opacity: editOption > 1 ? "1" : "0.5"
                            }}
                            onClick={() => {
                                navigate(`/accounts/${props.account_type}/create?editOption=${editOption}`)
                            }}
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditAccPopUp;
