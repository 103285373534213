import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CreateTxnMenuItemCard = ({
  icon,
  label,
  subtext,
  type,
  to,
  checkservice,
}) => {
  const services = useSelector((state) => state.refreshToken.services);

  return (
    <Box
      columns={{ xs: 9 }}
      sx={[
        services?.find(
          (service) => service === checkservice || service === "Admin"
        ) !== undefined && {
          display: "flex",
          borderRadius: "40px",
          backgroundColor: "#3A5686",
          "&:hover": {
            backgroundColor: "#5676ad",
            cursor: "pointer",
          },
          width: "507px",
          height: "109px",
          color: "#202020",
          opacity: "1",
          textAlign: "left",
          fontSize: "16px",
        },
        services?.find(
          (service) => service === checkservice || service === "Admin"
        ) === undefined && {
          display: "flex",
          borderRadius: "40px",
          backgroundColor: type === "account" ? "#9777AC" : "#3A5686",
          opacity: "0.5",
          width: "507px",
          height: "109px",
          color: "#202020",
          textAlign: "left",
          fontSize: "16px",
          pointerEvents: "none",
        },
      ]}
    >
      <Link
        className="w-full flex justify-start gap-5"
        style={{ textDecoration: "none" }}
        to={to}
      >
        <div
          style={{
            width: "111px",
            height: "109px",
            background: "#FAFAFA 0% 0% no-repeat padding-box",
            borderRadius: "40px",
            opacity: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            width={"60%"}
            src={require("../../assets/TransactionDashboardIcons/" + icon)}
            alt=""
          />
        </div>
        <div className="mt-5">
          <div className="w-full text-left text-xl 5xl:text-2xl font-semibold capitalize text-white">
            {label}
          </div>
          <div className="w-[90%] text-left text-xs 5xl:text-sm capitalize text-[#DADADA]">
            {subtext}
          </div>
        </div>
      </Link>
    </Box>
  );
};

export default CreateTxnMenuItemCard;
