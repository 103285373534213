import React, { useEffect } from "react";
import "./index.css";
import settingImg from "../../../assets/Settings/Dashboard/Setting_Girl.png";
import user from "../../../assets/Settings/Dashboard/user.png";
import arrowRight from "../../../assets/Settings/Dashboard/arrow_right.png";
import statistics from "../../../assets/Settings/Dashboard/statistics.png";
import din from "../../../assets/Settings/Dashboard/DIN.png";
import cin from "../../../assets/Settings/Dashboard/CIN.png";
import ifsc from "../../../assets/Settings/Dashboard/IFSC.png";
import pan from "../../../assets/Settings/Dashboard/PAN.png";
import test_matrix from "../../../assets/Settings/Dashboard/Payment matrix.png";
import gst from "../../../assets/Settings/Dashboard/gst.png";
import device from "../../../assets/Settings/Dashboard/device.png";
import notification from "../../../assets/Settings/Dashboard/notification.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { useDispatch } from "react-redux";
import {
  resetGetCinData,
  resetGetDinData,
  resetGetPanData,
} from "../../../redux/features/verifyPAN/verifyPAN";

const SettingsDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetGetCinData());
    dispatch(resetGetDinData());
    dispatch(resetGetPanData());
  }, []);
  const navigate = useNavigate();

  const handleNavigate = (type) => {
    if (type === "Users") {
      navigate("/settings/Users");
    } else if (type === "IFSC") {
      navigate("/settings/IFSC");
    } else if (type === "GST") {
      navigate("/settings/GST_Verification");
    }
  };

  return (
    <div className="setting_top_main_div">
      <div className="setting_main_1024_div">
        <div className="setting_enterpriseData">
          <h4>ENTERPRISE DATA</h4>
          <div className="inner_setting_enterpriseData">
            <Button
              className="inner_setting_enterpriseData_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => {
                toast.info("Coming Soon", toast_position);
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={statistics} />
                <h6>Statistics</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => handleNavigate("Users")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={user} />
                <h6>Users</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => {
                toast.info("Coming Soon", toast_position);
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={test_matrix} />
                <h6>Test Matrix</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => {
                navigate("/settings/Devices");
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={device} />
                <h6>Devices</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              onClick={() => {
                toast.info("Coming Soon", toast_position);
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={notification} />
                <h6>Notification Center</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
          </div>
        </div>
        <div className="setting_verification">
          <h4>VERIFICATION</h4>
          <div className="inner_setting_verification">
            <Button
              className="inner_setting_verification_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => navigate("/settings/CIN_Verification")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={cin} />
                <h6>CIN verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => navigate("/settings/DIN_Verification")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={din} />
                <h6>DIN verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => navigate("/settings/PAN_Verification")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={pan} />
                <h6>PAN verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => handleNavigate("GST")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={gst} />
                <h6>GST verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              onClick={() => handleNavigate("IFSC")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={ifsc} />
                <h6>IFSC verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
          </div>
        </div>
        <div className="setting_img">
          <img alt="setting" src={settingImg} />
        </div>
      </div>
    </div>
  );
};

export default SettingsDashboard;
