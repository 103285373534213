import React, { useState } from "react";
import "./index.css";
import formatCurrency from "../../../../utils/CurrencyFormater";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
import formatdate from "../../../../utils/formatdate";
import { approvalStatus } from "../../../../services/approvalStatus";
import ReceivedDetailsTxnDetails from "../receivedDetailsCard";
import { VendorTxnDetailChildBgMapper } from "../../../../services/VendorTxnDetailBgMapper";
import info from "../../../../assets/TxnDetails/info-black-border.png";
import PopOverContainer from "../../../PopOverContainer";
import { Popover } from "@mui/material";
import { Link } from "react-router-dom";

const TxnDetailsBanner = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleImageClick = (event) => {
    event.stopPropagation();
    handleClick(event);
  };
  const open = Boolean(anchorEl);
  return (
    <div
      className="h-full drop-shadow-2xl
      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl
      p-8 3xl:p-10 4xl:p-12 5xl:p-12 6xl:p-16 flex flex-row justify-between"
      style={{
        background: `white no-repeat padding-box`,
      }}>
      {// top banner txn details - left part
      }
      <div className="flex flex-col justify-between">
        <div className="flex">
          <div className="
          text-[#13B981] text-semibold
            text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl
            font-light capitalize antialiased truncate ...">
            ₹ {formatCurrency(props.amount)}
          </div>
          <div className="cursor-pointer" onClick={handleImageClick}>
            <img
              src={info}
              alt="info"
              className="w-3 h-3"
            />
          </div>
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                "border-radius": "40px",
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <PopOverContainer data={props.data} approval_status={props.approval_status} />
          </Popover>
        </div>
        <div className="
          text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl font-bold
          capitalize whitespace-nowrap flex items-center "
          style={{ color: approvalStatus(props.approval_status) }}>
          {VendorTxnTextMapper(props.approval_status) === "Approved" ? "Created" : VendorTxnTextMapper(props.approval_status)} {props.utr_no ?
            <div className="text-[#707070] text-sm flex">
              &nbsp;
              <div className="w-0.5 h-5 bg-gray-300">
              </div>
              &nbsp;
              <span className="text-[#707070]">UTR No&nbsp;:&nbsp; </span>
              <span className="text-[#000000] font-thin">{props.utr_no}</span>
            </div> :
            null}
        </div>
        <div className="
            text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl text-gray-600 antaliased">
          {props.posting_date == undefined
            ? {}
            : formatdate(props.posting_date)}
        </div>
      </div>
      <div className="absolute right-[-3%]  bottom-auto top-auto self-center">
        <Link
          style={{ textDecoration: "none" }}
          onClick={() => {
            window.location.href = `/accounts/client/details/${props?.client_id}`;
          }}>
          <ReceivedDetailsTxnDetails
            bgColor={VendorTxnDetailChildBgMapper(props.approval_status)}
            clientName={props.client_name}
            clientContactNumber={props.client_contact_no}
            clientEmail={props.client_email_id}
          />
        </Link>
      </div>
    </div>
  );
};

export default TxnDetailsBanner;
