import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import pci from "../../assets/Landing/pci.png";
import aboutimage from "../../assets/Landing/about.png";
import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";
import "../../assets/Landing/css/owl.carousel.css";

const AboutusPage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const workSectionRef = useRef(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [isMenuActive, setIsMenuActive] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        setIsMenuActive(true);
      } else {
        setIsMenuActive(false);
      }
    };

    if (window.innerWidth > 991 && isMenuActive) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landingbody">
      <div id="preloder">
        <div className="loader"></div>
      </div>
      <header
        className={`header-section ${isSticky ? "sticky slideInDown" : ""}`}
      >
        <div className="header-content">
          <div className="landingcontainer">
            <a
              onClick={() => navigate("/")}
              className="site-logo"
              style={{ cursor: "pointer" }}
            >
              <img className="landingimg" src={xpentraLogo} alt="xpentraLogo" />
            </a>
            <div className="responsive-bar" onClick={toggleMenu}>
              <i className="fa fa-bars"></i>
            </div>
            <a href="" className="user">
              <i className="fa fa-user"></i>
            </a>
            <button
              className="site-btn sb-gradients sbg-line"
              id="loginbtn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            {isMenuActive && (
              <nav className="main-menu">
                <ul className="menu-list">
                  <li onClick={() => navigate("/")}>
                    <a>Home</a>
                  </li>
                  <li onClick={() => navigate("/featruepage")}>
                    <a>Features</a>
                  </li>
                  <li onClick={() => navigate("/howitwork")}>
                    <a>How it works</a>
                  </li>
                  <li className="actived" onClick={() => navigate("/aboutus")}>
                    <a>About</a>
                  </li>
                  <li onClick={() => navigate("/contact")}>
                    <a>Contact</a>
                  </li>
                  <li onClick={() => navigate("/faq")}>
                    <a>FAQ</a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>

      <main>
        <section className="inner-section gradient gradient-primary-auxiliary">
          <div className="shapes-container">
            <div className="static-shape ghost-shape ghost-shape-1"></div>
            <div className="static-shape pattern-dots-1"></div>
            <div className="static-shape pattern-dots-2"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="landinginner-text">
                  <h2>About Us</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-section boxshadow spad">
          <div className="shapes-container">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
          </div>
          <div className="landingcontainer">
            <div className="vertical-center-inner">
              <div className="landingrow">
                <div
                  className="coll-lg-6"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <div className="cardview-text">
                    <div className="cardview-body">
                      Xpentra is a technology platform providing all-in-one
                      banking and payments experience to enterprise customers.
                      We provide next generation solution to overcome the
                      challenges of legacy corporate internet banking.
                      <br />
                      <br />
                      Our platform empowers customers to embark on digital
                      payments journey with highest level of security and
                      availability standards.
                      <br />
                      <br />
                      Xpentra was founded in 2016 with a vision to help
                      enterprises solve payment issues and focus on their core
                      business.
                    </div>
                  </div>
                </div>
                <div
                  className="coll-lg-6"
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <img className="landingimg" src={aboutimage} alt="" />
                </div>
              </div>
              <div className="landingrow">
                <div
                  className="coll-lg-12"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <h6>Our vision:</h6>
                  <p>
                    Xpentra aims to be a go-to platform for businesses seeking a
                    reliable, transparent, and hassle-free payment experience.
                  </p>
                  <br />
                </div>
                <div
                  className="coll-lg-12"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <h6>Our mission:</h6>
                  <p>
                    To empower businesses to easily and securely pay their
                    vendors, eliminating the need for error prone manual
                    processes and enabling them to focus on their core
                    operations.
                  </p>
                  <br />
                </div>
                <div
                  className="coll-lg-12"
                  data-aos="fade-right"
                  data-aos-delay="0"
                >
                  <h6>Our core values:</h6>
                  <p>
                    <strong>Trust:</strong> We believe that trust is essential
                    for any successful financial transaction, and we are
                    committed to building and maintaining trust with both our
                    clients and vendors.
                  </p>
                  <p>
                    <strong>Security:</strong> We prioritize the security and
                    protection of our clients' and vendors' data and payments,
                    implementing strict security measures and adhering to
                    industry best practices.
                  </p>
                  <p>
                    <strong>Customer-centricity:</strong> We prioritize the
                    needs and satisfaction of our clients and vendors, providing
                    exceptional customer support and services to ensure their
                    success and growth.
                  </p>
                  <p>
                    <strong>Accessibility:</strong> We strive to make our
                    platform accessible and user-friendly for businesses of all
                    sizes and backgrounds, enabling them to manage their vendor
                    payouts efficiently and effectively.
                  </p>
                  <p>
                    <strong>Reliability:</strong> We understand the importance
                    of timely and reliable vendor payouts and are committed to
                    delivering on our promises to our clients and vendors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>

      <footer className="footer-section footer">
        <div className="landingcontainer">
          <div className="landingrow spad">
            <div className="coll-md-5 footer-widget">
              <p>
                Payments should be Quick, Secure and Quintessentially yours.
              </p>
              <span>
                &copy; Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                rights reserved
              </span>
              <br />
              <br />
              <img
                src={pci}
                className="mb-4 landingimg"
                alt=""
                style={{ width: "100px", marginRight: "20px", float: "left" }}
              />
              <a
                id="fC4seLa5xl"
                href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                target="_blank"
              >
                <img
                  className="landingimg"
                  src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                  alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                  style={{ width: "50px", float: "left" }}
                />
              </a>
            </div>
            <div className="coll-md-4 footer-widget">
              <h5 className="widget-title">Quick Links</h5>
              <div className="landingrow">
                <div className="coll-md-5 footer-widget">
                  <ul>
                    <li className="actived" onClick={() => navigate("/")}>
                      <a>Home</a>
                    </li>
                    <li onClick={() => navigate("/featruepage")}>
                      <a>Features</a>
                    </li>
                    <li onClick={() => navigate("/howitwork")}>
                      <a>How it works</a>
                    </li>
                    <li onClick={() => navigate("/aboutus")}>
                      <a>About</a>
                    </li>
                    <li onClick={() => navigate("/contact")}>
                      <a>Contact</a>
                    </li>
                    <li onClick={() => navigate("/faq")}>
                      <a>FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="coll-md-7 footer-widget">
                  <ul>
                    <li onClick={() => navigate("/privacy")}>
                      <a>Privacy Policy</a>
                    </li>
                    <li onClick={() => navigate("/terms")}>
                      <a>Terms of Use</a>
                    </li>
                    <li onClick={() => navigate("/cancellation")}>
                      <a>Cancellation Policy</a>
                    </li>
                    <li onClick={() => navigate("/refund")}>
                      <a>Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coll-md-3 footer-widget">
              <h5 className="widget-title">Supported By</h5>
              <div className="visacard"></div>
              <div className="mastercard"></div>
              <div className="dinerscard"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutusPage;
