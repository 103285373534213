import { Button } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import "./index.css";

const CustomizedStepper = ({
  activeStep,
  handleBack,
  steps,
  componentType,
  handleNext,
  nextButtonEnabled,
  twoSteps,
  editOption,
  container,
}) => {
  return (
    <div className="w-full h-full flex flex-row">
      <div
        className="self-center
        flex flex-row justify-center
      w-1/6"
      >
        <div
          className="cursor-pointer
          text-white
          text-lg
          3xl:text-xl 4xl:text-2xl 5xl:text-3xl
          6xl:text-4xl underline"
          style={{
            visibility:
              activeStep === 0
                ? container === "clientAcc"
                  ? "visible"
                  : "hidden"
                : activeStep === 1 && twoSteps
                ? "hidden"
                : "visible",
          }}
          onClick={handleBack}
        >
          {activeStep === 0 ? "Cancel" : "Previous"}
        </div>
      </div>

      <div
        className="w-4/6
        flex flex-row justify-center gap-6"
      >
        {steps?.map((step, index) => {
          if (twoSteps && index === 0) return null;
          return (
            <div
              className={
                activeStep === index
                  ? "flex flex-col w-4/6"
                  : "flex flex-col w-1/6"
              }
            >
              <div className="h-1/2"></div>
              <div
                className={
                  activeStep === index
                    ? "bg-white h-2 rounded-lg"
                    : "bg-white opacity-40 h-2 rounded-lg"
                }
              ></div>
              <div
                className="h-1/4
                text-white text-base
                3xl:text-lg 4xl:text-xl 5xl:text-2xl
                6xl:text-3xl antialiased"
              >
                {activeStep === index ? step : null}
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="self-center
        flex flex-row justify-center
        w-1/6"
      >
        {activeStep <= steps?.length - 1 && (
          <Button
            onClick={handleNext}
            sx={{
              textTransform: "none",
              borderRadius: "2rem",
              padding: "0.5rem 1.5rem",
              background: nextButtonEnabled
                ? activeStep === steps?.length - 1
                  ? "#5FA564 0% 0% no-repeat padding-box !important"
                  : "#ffffff 0% 0% no-repeat padding-box !important"
                : "#666666 0% 0% no-repeat padding-box !important",
              color: nextButtonEnabled
                ? activeStep === steps?.length - 1
                  ? "white !important"
                  : "black !important"
                : "white !important",
            }}
            disabled={!nextButtonEnabled}
          >
            <span
              className={`
                text-lg cursor-pointer
                3xl:text-xl 4xl:text-2xl 5xl:text-3xl
                6xl:text-4xl antialiased`}
            >
              {activeStep === steps?.length - 1
                ? editOption
                  ? "Update"
                  : componentType === "Internal"
                  ? "Transfer"
                  : "Create"
                : "Next"}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default CustomizedStepper;
