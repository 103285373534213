function DetailsCards({ title, data }) {
    return (
        <div className="bg-[#3A5686] drop-shadow-xl p-5 rounded-4xl flex flex-col justify-between gap-5">
            <div className='text-[#C9C9C9] text-lg font-semibold'>{title}</div>
            <div className="flex flex-col gap-2">
                {Object.keys(data).map((key, index) =>
                    <div>
                        <div className='text-white text-lg 5xl:text-xl'>{data[key]}</div>
                        <div className='text-[#D3D3D3] text-sm 5xl:text-lg'>{key}</div>
                        {index !== (Object.keys(data).length - 1) && <div className="h-[1vh] border-gray-500 border-b"></div>}
                    </div>
                )}
            </div>
        </div>
    )
}

export default DetailsCards