import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../../../../../utils/validation";
import { InputAdornment, TextField } from "@mui/material";
import verifyLogo from "../../../../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import { toast } from "react-toastify";
import { verifyThisCIN } from "../../../../../../redux/features/verifyPAN/verifyPAN";
import loadingLogo from "../../../../../../assets/Approve transactions/inputloder.gif";
import trimSentence from "../../../../../../utils/trimSentence";
import styled, { keyframes } from "styled-components";
import SideBar from "../../SideBar";

const EntityCinDetails = (props) => {
  const panStatus = useSelector((state) => state.verify.verifyCIN);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyCINisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [showDirectors, setShowDirectors] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);

  const dispatch = useDispatch();

  const modifyValue = (type, value) => {
    if (type === "cin_number") {
      return value?.toUpperCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "cin_number") return Validation.allowedCIN(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "cin_number")
      errors = Validation.validateCIN(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewEntityData, [name]: value };
      props.setRegisterNewEntityData(newObj);
    }
    if (e.target.value.length > 0 && e.target.value.length < 21) {
      props.enableNextButton(false);
    }
    if (e.target.value.length >= 0 && e.target.value.length < 21) {
      setPanVerificationStatus(false);
      setShowDirectors(false);
    }
    if (
      e.target.value.length === 0 &&
      props.registerNewEntityData.entity_pan.length === 10
    )
      props.enableNextButton(true);
    if (
      props.registerNewEntityData.cin_number.length === 0 ||
      props.registerNewEntityData.cin_number.length < 21
    ) {
      setPanVerificationStatus(false);
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (
      props.registerNewEntityData.cin_number.length > 0 &&
      panStatus?.status === true
    ) {
      let errors = { ...customErrors };
      delete errors["cin_number"];
      setCustomErrors(errors);
      setPanVerificationStatus(true);
    }

    if (
      props.registerNewEntityData.cin_number.length > 0 &&
      panStatus?.errors
    ) {
      let errors = { ...customErrors };
      errors["cin_number"] = panStatus.errors;
      setCustomErrors(errors);
      setPanVerificationStatus(false);
    }
  }, [panStatus]);

  useEffect(() => {
    if (
      props.registerNewEntityData.cin_number?.length === 21 &&
      !customErrors["cin_number"]
    ) {
      dispatch(
        verifyThisCIN({ search_string: props.registerNewEntityData.cin_number })
      );
    }
  }, [props.registerNewEntityData.cin_number]);

  useEffect(() => {
    if (props.registerNewEntityData.cin_number.length === 0) return;
    if (
      props.registerNewEntityData.cin_number?.length === 21 &&
      panVerificationStatus
    )
      props.enableNextButton(true);
    else props.enableNextButton(false);
  }, [panVerificationStatus, props.registerNewEntityData.cin_number]);

  // this needs to be checked

  const getPanVerifiedDiv = (
    name,
    status,
    isLoading,
    label,
    value,
    maxLength
  ) => {
    return (
      <div
        className={`
        ${value?.length === 10 && !isLoading && status && "-mx-1"}
        min-w-full flex flex-row`}
      >
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: { maxLength },
          }}
          InputLabelProps={{
            style: {
              color: "#DADADA",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
                {value.length === 21 &&
                  !isLoading &&
                  (status ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : (
                    <img
                      src={skippedVerification}
                      alt="verified logo"
                      onClick={() => {
                        showErrors(name);
                      }}
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ))}
              </InputAdornment>
            ),
          }}
          label={label}
          variant="standard"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const panVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        <div
          className={`min-w-full
          flex justify-center py-1
          rounded-r-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {
              // Verified Vendor Pan Number Div
              getPanVerifiedDiv(
                "cin_number",
                panVerificationStatus,
                panStatusLoading,
                "Entity CIN number",
                props.registerNewEntityData.cin_number,
                10
              )
            }
          </div>
        </div>
      </div>
    );
  };

  const getSideDraweContent = () => {
    return (
      <div className="h-full flex flex-col gap-12 py-8 px-8 max-w-xl">
        <div className="flex flex-col gap-8">
          <div>
            <div
              className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
            >
              {panStatus?.cin_details?.company_name || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Company name
            </div>
          </div>
          <div>
            <div
              className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
            >
              {panStatus?.cin_details?.incorporation_date || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              ESTD. on
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-white px-8 opacity-70"></div>
        <div className="flex flex-col gap-8">
          <div>
            <div
              className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
            >
              {panStatus?.cin_details?.pan_no || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              PAN number
            </div>
          </div>
          <div>
            <div
              className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
            >
              {panStatus?.cin_details?.listed_status || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Corporate listing
            </div>
          </div>
          <div>
            <div
              className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
            >
              {panStatus?.cin_details?.company_status || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Company listing
            </div>
          </div>
        </div>
        <div className="h-[1px] bg-white px-8 opacity-70"></div>
        <div>
          <div>
            <div
              className="
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl"
            >
              {panStatus?.cin_details?.company_address || "Not Given"}
            </div>
            <div className="antaliased text-white text-xs opacity-70">
              Corporate address
            </div>
          </div>
        </div>
      </div>
    );
  };

  const slideOutDown = keyframes`
    from {
        height: 0px;
    }

    to {
        height: 200px;
    }
    `;

  const AnimatedDiv = styled.div`
    animation: ${slideOutDown} 0.5s ease-in-out;
  `;

  return (
    <div
      className="bg-[#3A5686]  flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl"
    >
      <div
        className="p-2 bg-custom-lightGreen  flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-4 2xl:gap-6 4xl:gap-8"
      >
        <div>
          <div
            className="-m-2 bg-[#29487E]
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-4 md:p-6 lg:p-8 xl:p-10 4xl:p-24"
          >
            <div
              className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
            >
              Entity CIN details (Optional)
            </div>
          </div>
          <div
            className="-m-2
         bg-custom-lightBlue rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col
          drop-shadow-xl
          gap-4 2xl:gap-6 4xl:gap-8"
          >
            <div
              className="bg-[#29487E] drop-shadow-xl h-full
          rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl
          flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-6"
            >
              {props.activeStep !== 2 ? (
                <div className="w-full">{panVerificationDiv()}</div>
              ) : (
                <div className="flex flex-col gap-8">
                  <div>
                    <div
                      className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
                    >
                      {props?.registerNewEntityData?.cin_number || "Not Given"}
                    </div>
                    <div className="antaliased text-white text-xs opacity-70">
                      Entity CIN number
                    </div>
                  </div>
                  {props?.registerNewEntityData?.cin_number && (
                    <>
                      <div className="h-[1px] bg-white px-8 opacity-70"></div>
                      <div>
                        <div
                          className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-xl 3xl:text-2xl 5xl:text-3xl truncate ..."
                        >
                          {props?.registerNewEntityData?.entity_name ||
                            "Not Given"}
                        </div>
                        <div className="antaliased text-white text-xs opacity-70">
                          Legal name
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {panVerificationStatus &&
          props.activeStep !== 2 &&
          props.registerNewEntityData?.cin_number?.length > 0 && (
            <div className="-mx-4">
              <div
                className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
          gap-6"
              >
                <div
                  className="bg-custom-lightGreen rounded-l-large 4xl:rounded-l-customXl 5xl:rounded-l-custom2Xl
            flex w-full p-2 flex-col justify-center items-start"
                >
                  <div className="flex mx-2 flex-row justify-end">
                    <div className="antaliased text-white text-xs ">
                      Company name
                    </div>
                  </div>
                  <div
                    onClick={() => setShowSideBar(true)}
                    className="w-full m-2 p-1 cursor-pointer rounded-md bg-[#4A7C40] flex items-center flex-row gap-2"
                  >
                    <div className="flex flex-row">
                      <img
                        className="self-center w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11 p-1"
                        src={verifyLogo}
                        alt="verified logo"
                      />
                    </div>
                    <div className="flex flex-row h-full w-[90%]">
                      <div
                        className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                      >
                        {trimSentence(
                          props.registerNewEntityData?.company_name
                            ? props.registerNewEntityData?.company_name.toLowerCase()
                            : panStatus?.cin_details?.company_name.toLowerCase(),
                          25
                        )}
                      </div>
                    </div>
                    <div className="flex flex-row w-8 h-8 2xl:w-9 2xl:h-9 3xl:w-10 3xl:h-10 5xl:w-11 5xl:h-11">
                      <img
                        className="self-center p-1"
                        src={require("../../../../../../assets/TransactionDashboardIcons/arrow_next.png")}
                        alt="verified logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      {props.activeStep !== 2 &&
        panStatus?.status &&
        panVerificationStatus &&
        panStatus?.cin_details?.directors?.length && (
          <div
            onClick={() => setShowDirectors(!showDirectors)}
            className="w-full m-2 py-6 px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 cursor-pointer flex justify-between items-center flex-row"
          >
            <div
              className="font-[100] text-white capitalize self-center
                    text-md 3xl:text-lg 5xl:text-xl truncate"
            >
              {panStatus?.cin_details?.directors?.length} Directors found
            </div>
            <div
              className="flex font-[100] capitalize self-center
                    text-md 3xl:text-lg 5xl:text-xl truncate text-[#FF7F33] flex-row trancate"
            >
              Show directors
            </div>
          </div>
        )}
      {showDirectors && panStatus?.cin_details?.directors?.length && (
        <AnimatedDiv className="py-4 overflow-auto overflow-y-scroll">
          {panStatus?.cin_details?.directors?.map((item) => (
            <>
              <div className="h-[1px] bg-white mx-4 md:mx-6 lg:mx-8 xl:mx-10 4xl:mx-24 opacity-70"></div>
              <div className="w-full py-2 px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 flex justify-between items-center flex-row">
                <div
                  className="text-white capitalize self-center
                    text-md 3xl:text-lg 5xl:text-xl truncate"
                >
                  {item.director_name || "Not Given"}
                </div>
                <div
                  className="flex capitalize self-center opacity-70
                    text-base 3xl:text-md 5xl:text-lg truncate text-white flex-row trancate"
                >
                  Director name
                </div>
              </div>
            </>
          ))}
        </AnimatedDiv>
      )}
      {
        <SideBar
          state={showSideBar}
          setState={setShowSideBar}
          title={
            <div className="flex items-center gap-6">
              <div className="text-2xl 3xl:text-3xl 5xl:4xl w-96 text-white">
                CIN Verification details
              </div>
            </div>
          }
        >
          {getSideDraweContent()}
        </SideBar>
      }
    </div>
  );
};

export default EntityCinDetails;
