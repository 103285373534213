import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel } from "@mui/material";
import "./index.css";
import MultiSelectVendorCard from "../../../../components/SettleTxn/MultiSelectVendorCard";
import { useDispatch, useSelector } from "react-redux";
import { approveVendorTxn } from "../../../../redux/features/approveTxn/approveTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import DeniedReason from "../../../../components/ApprovalTxn/VendorApproval/DeniedReson/DeniedReason";
import formatDate from "../../../../utils/formatdate";
import TransactionAmount from "../../../../components/TransactionAmount";
import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import SearchBar from "../../../../components/_utils/search/SearchBar";
import { getVendorPendingTxn } from "../../../../redux/features/pendingTxn/pendingTxn";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";

const SelectVendorTxns = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deniedReason, setDeniedReason] = useState(false);
  const [selectAllToggle, setSelectAllToggle] = useState(false);
  const [selectAllCheck, setSelectAllCheck] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const toggleShowSearchBar = () => setShowSearchBar(!showSearchBar);
  const VendorPendingApprovals = useSelector(
    (state) => state.pendingTxn.vendor
  );
  const loading = useSelector((state) => state.pendingTxn.isLoading);
  const [page, setPage] = useState(1);
  const [searchString, setSearchString] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState([]);
  const [selectedDatum, setSelectedDatum] = useState([]);
  const [selectedTxns, setSelectedTxns] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const vendorApprovalResponse = useSelector(
    (state) => state.approveTxn.vendor
  );

  useEffect(() => {
    if (checkRes && vendorApprovalResponse?.errors) {
      toast.error(Object.values(vendorApprovalResponse.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && vendorApprovalResponse?.status)
      navigate("/vendor/approval_status");
    setCheckRes(true);
  }, [vendorApprovalResponse]);

  const getSelected = () => {
    let selected = [];
    let amt = 0.0;
    for (let key in selectedTxns) {
      if (selectedTxns[key]) {
        selected.push(key);
        let item = data.find((item) => item.transaction.id === key);
        item = item
          ? item
          : selectedDatum.find((item) => item.transaction.id === key);
        amt += Number(item.transaction.amount);
      }
    }
    return { selected: selected, amt: amt };
  };

  const handleRadioBTClick = (e, value, checked, datum) => {
    let selected = [...selectedDatum];
    if (!checked) {
      selected = selected.filter((item) => item.transaction.id !== value);
    } else {
      selected.push(datum);
    }
    setSelectedDatum(selected);
    if (!e) {
      setSelectedTxns({ ...selectedTxns, [value]: checked });
    } else {
      let { value, checked } = e.target;
      setSelectedTxns({ ...selectedTxns, [value]: checked });
    }
  };

  const handleSelectAll = () => {
    setSelectAllCheck(!selectAllCheck);
    let selectedTxn = { ...selectedTxns };
    let selectedDatumArr = [...selectedDatum];
    if (selectAllCheck) {
      data.forEach((item) => {
        selectedTxn[item.transaction.id] = false;
        selectedDatumArr = selectedDatumArr.filter(
          (datum) => datum.transaction.id !== item.transaction.id
        );
      });
    } else {
      data.forEach((item) => {
        selectedTxn[item.transaction.id] = true;
        selectedDatumArr.push(item);
      });
    }
    setSelectedTxns(selectedTxn);
    setSelectedDatum(selectedDatumArr);
  };

  useEffect(() => {
    if (data?.length === getSelected().selected.length) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
  }, [data, selectedTxns, selectAllToggle]);

  const getData = () => {
    dispatch(
      getVendorPendingTxn({ search_string: searchString, page_no: page })
    );
  };

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    // deDup data
    let j = 0;
    appendedData?.forEach((item) => {
      if (
        !deDupData?.find(
          (deDupItem) => deDupItem.transaction.id === item.transaction.id
        )
      ) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    return appendedData;
  };

  useEffect(() => {
    if (
      VendorPendingApprovals === null ||
      VendorPendingApprovals === undefined
    ) {
      return;
    }
    if (!loading) {
      setData(dataAppend(data, VendorPendingApprovals?.actionables));
    }
  }, [VendorPendingApprovals]);

  let nextFetch = () => {
    setPage((page) => page + 1);
    if (!loading) {
      setData(dataAppend(data, VendorPendingApprovals?.actionables));
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  // useEffect(() => {
  //   setData([]);
  //   if (page !== 0) setPage(0);
  //   else getData();
  // }, [searchString]);

  const handleSearch = (search_string) => {
    if (search_string !== searchString) {
      setData([]);
      setSearchString(search_string);
    }
  };

  useEffect(() => {
    let checkMark = data?.length > 0 ? true : false;
    data?.forEach((item) => {
      if (!selectedTxns[item.transaction.id]) {
        checkMark = false;
      }
    });
    setSelectAllCheck(checkMark);
  }, [data, selectedTxns, selectAllCheck, selectedDatum]);

  return (
    <div
      id="infinityScrollContainer"
      className="flex h-screen overflow-y-auto selectTxnsLeft bg-[#3e5555]"
      style={{ scrollBehavior: "smooth" }}
    >
      {deniedReason && (
        <DeniedReason
          setDeniedReason={setDeniedReason}
          getSelected={getSelected}
          dispatchFn={approveVendorTxn}
        />
      )}
      <div className="w-4/6 pl-24 pt-32 pr-12 py-2 flex drop-shadow-xl justify-between bg-[#3e5555] items-center fixed bg-no-repeat bg-padding-box">
        <div
          className="w-[50%] h-full pl-8
            text-white self-center
              text-xl xl:text-2xl 3xl:text-3xl 5xl:text-5xl"
        >
          Select vendor transactions
        </div>
        <div className="w-[50%] flex flex-row justify-end gap-4">
          <div className="w-[60%]">
            {/* <SearchBar
              handleSearch={handleSearch}
              showSearchBar={showSearchBar}
              toggleShowSearchBar={toggleShowSearchBar}
              bgColor={"bg-custom-notSelectedCardApproval"}
              bgHover={"hover:bg-custom-selectedCard"}
            /> */}
          </div>
          <FormControlLabel
            value="start"
            control={
              <Checkbox
                icon={
                  <img
                    className="w-6 h-6 3xl:w-7 3xl:h-7 4xl:w-8 4xl:h-8 6xl:w-10 6xl:h-10"
                    src={require("../../../../assets/Approve transactions/not selected.png")}
                    alt=""
                  />
                }
                checkedIcon={
                  <img
                    className="w-6 h-6 3xl:w-7 3xl:h-7 4xl:w-8 4xl:h-8 6xl:w-10 6xl:h-10"
                    src={require("../../../../assets/Approve transactions/selected.png")}
                    alt=""
                  />
                }
                value={"option1"}
                name="selectAllBT"
                inputProps={{ "aria-label": "A" }}
                onClick={handleSelectAll}
                checked={selectAllCheck}
              />
            }
            label={
              <div className="text-white text-sm xl:text-lg 3xl:text-2xl 5xl:text-4xl antialiased">
                Select all
              </div>
            }
            sx={{
              textAlign: "center",
              letterSpacing: "0px",
              color: "#dddddd",
              textDecoration: "underline",
              ".MuiFormControlLabel-label": {
                font: "normal normal 600 20px Open Sans",
              },
            }}
            labelPlacement="start"
          />
        </div>
      </div>
      {data?.length !== 0 ? (
        <div className="w-4/6 mt-28 3xl:mt-32 5xl:mt-40 pl-24 pb-8">
          {data?.length > 0 ? (
            <InfiniteScroll
              className="grid
            grid-cols-2
            3xl:grid-cols-3
            4xl:grid-cols-4
            pl-8 pb-8 pr-12 mt-24 gap-x-4 gap-y-4"
              dataLength={data?.length || []}
              next={nextFetch}
              hasMore={data?.length < VendorPendingApprovals?.title?.records}
              loader={
                <div
                  style={{
                    width: "55vw",
                    overflow: "hidden",
                  }}
                  className="w-full m-auto justify-center items-center flex"
                >
                  <img
                    src={require("../../../../assets/Approve transactions/inputloder.gif")}
                    alt=""
                  />
                </div>
              }
              scrollableTarget="infinityScrollContainer"
              scrollThreshold={0.7}
            >
              {data &&
                data?.map((item, index) => {
                  return (
                    <MultiSelectVendorCard
                      parent={"approvalTxns"}
                      datum={item}
                      handleRadioBTClick={handleRadioBTClick}
                      account={item.account.bank_account_no}
                      amount={item.transaction.amount}
                      txn_id={item.transaction.id}
                      checked={selectAllToggle}
                      selectedTxns={selectedTxns}
                      vendor_name={item.vendor.name}
                      posting_date={formatDate(
                        item.transaction.initiation_date,
                        false
                      )}
                    />
                  );
                })}
            </InfiniteScroll>
          ) : (
            <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
              <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                No transactions found <br />
                Vendor transactions will appear here.
              </div>
              <div className="w-2/6 p-4 flex flex-row justify-center">
                <img
                  className="h-full w-full"
                  src={NoDataImg}
                  alt="NoDataImg"
                />
              </div>
            </div>
          )}
          {/* {loading && (
          <div className="w-full m-auto justify-center items-center flex">
            <img
              src={require("../../../../assets/Approve transactions/inputloder.gif")}
              alt=""
            />
          </div>
        )} */}
        </div>
      ) : (
        <div className="w-4/6 p-4 flex flex-row justify-center">
          <img
            className="w-1/4 h-60 m-auto"
            src={require("../../../../assets/Approve transactions/inputloder.gif")}
            alt=""
          />
        </div>
      )}
      <div
        className="w-2/6 bg-[#364949]"
        style={{ position: "fixed", right: "5px", pointerEvents: "none" }}
      >
        <div className="pt-28 3xl:pt-32 4xl:pt-36 flex justify-between flex-col relative h-screen">
          {getSelected().selected.length === 0 ? (
            <TransactionAmount showText={true} />
          ) : (
            <TransactionAmount
              txn_count={getSelected().selected.length}
              amount={formatCurrency(getSelected().amt)}
            />
          )}
          {!deniedReason && (
            <div
              className="approveDetailsBTs"
              style={{ pointerEvents: "all", paddingBottom: "5%" }}
            >
              <Button
                sx={{
                  backgroundColor:
                    getSelected().selected.length === 0
                      ? "#666666 !important"
                      : "auto",
                  color:
                    getSelected().selected.length === 0
                      ? "#ffffff !important"
                      : "auto",
                  textTransform: "none",
                  padding: "6px 8px",
                }}
                onClick={() => {
                  setDeniedReason(true);
                }}
                variant="contained"
                className="declineBt w-2/6 font-normal xl:px-0.5 2xl:px-1 4xl:px-3 xl:py-2 2xl:py-3 4xl:py-6 rounded-4xl flex items-center justify-center"
                disabled={getSelected().selected.length === 0 ? true : false}
              >
                <img
                  width={"14px"}
                  src={require("../../../../assets/Approve transactions/Decline white.png")}
                  alt=""
                />{" "}
                &nbsp;&nbsp; Decline
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    getSelected().selected.length === 0
                      ? "#666666 !important"
                      : "auto",
                  color:
                    getSelected().selected.length === 0
                      ? "#ffffff !important"
                      : "auto",
                  textTransform: "none",
                  padding: "6px 8px",
                }}
                variant="contained"
                className="approveBt w-2/6 font-normal xl:px-0.5 2xl:px-1 4xl:px-3  xl:py-2 2xl:py-3 4xl:py-6 rounded-4xl flex items-center justify-center "
                disabled={getSelected().selected.length === 0 ? true : false}
                onClick={() => {
                  dispatch(
                    approveVendorTxn({
                      txn_id: getSelected().selected,
                      status: "Y",
                    })
                  );
                }}
              >
                <img
                  width={"15px"}
                  src={require("../../../../assets/Approve transactions/Approve white.png")}
                  alt=""
                />{" "}
                &nbsp;&nbsp; Approve
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectVendorTxns;
