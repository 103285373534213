import React, { useEffect } from "react";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";

const TxnDetailsCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const txn_id = props?.createdClientTxn.txn_details.txn_id;

  useEffect(() => {
    return () => {
      dispatch(setBufferState({}));
    };
  }, []);

  return (
    <>
      <div className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <div className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6 items-center justify-center">
          {props?.container === "TxnDetailsCard" && (
            <div className="w-[30%] p-6 2xl:p-8 3xl:p-8 4xl:p-12 5xl:p-16 6xl:p-18 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                viewBox="0 0 441.661 656.463"
              >
                <g
                  id="Group_14"
                  data-name="Group 14"
                  transform="translate(-530.701 -183.378)"
                >
                  <g
                    id="Group_13"
                    data-name="Group 13"
                    transform="translate(530.701 183.378)"
                  >
                    <circle
                      id="Ellipse_21"
                      data-name="Ellipse 21"
                      cx="90.136"
                      cy="90.136"
                      r="90.136"
                      transform="translate(144.922 47.01)"
                      fill="#2f2e41"
                    />
                    <circle
                      id="Ellipse_22"
                      data-name="Ellipse 22"
                      cx="67.085"
                      cy="67.085"
                      r="67.085"
                      transform="translate(169.802 101.072)"
                      fill="#e7c2c2"
                    />
                    <path
                      id="Path_159"
                      data-name="Path 159"
                      d="M1031.1,635s-122.912-21.851-202.122,5.463l23.217,184.368-5.463,30.045,163.883-5.463L999.69,819.372Z"
                      transform="translate(-692.25 -369.484)"
                      fill="#e7c2c2"
                    />
                    <path
                      id="Path_160"
                      data-name="Path 160"
                      d="M938.475,835.637a826.368,826.368,0,0,1-84.672-5.365A23.2,23.2,0,0,1,833.15,806.66l1.994-86.221.232-.336c23.747-34.4,12.18-59.519,3.278-73.045a17.485,17.485,0,0,1-2.874-9.62h0c-1.365-1.912-1.671,1.106-.819-1.035a6.855,6.855,0,0,1,5.015-4.2l13.559-2.709A6.829,6.829,0,0,1,861.341,634c4.556,13.594,22.312,57.335,63,57.323,33.992,0,46.676-48.1,47.436-59.219a6.829,6.829,0,0,1,6.81-6.342h16.934a7.148,7.148,0,0,1,5.643,2.551,6.773,6.773,0,0,1,.666,7.514c-19.327,35.535,8.918,91.774,9.205,92.321l.18.355-7.97,82.742a23.328,23.328,0,0,1-17.956,20.423C972.97,834.561,956.317,835.637,938.475,835.637Z"
                      transform="translate(-685.038 -371.158)"
                      fill="#7466b7"
                    />
                    <path
                      id="Path_161"
                      data-name="Path 161"
                      d="M865.328,888.943c-18.355,0-35.158-2.021-48.509-6.8-15.869-5.678-25.833-14.862-29.619-27.314a36.054,36.054,0,0,1,4.386-31.883c23.476-35.153,104.942-45.068,115.873-46.24l5.79-48.255a23.3,23.3,0,0,1,22.69-20.447l137.2-2.141a23,23,0,0,1,23.217,19.057l7.8,42.883-89.966,43.634,13.6,38.089-.888.593C1000.572,867.671,924.85,888.941,865.328,888.943Z"
                      transform="translate(-767.872 -232.481)"
                      fill="#60392b"
                    />
                    <path
                      id="Path_162"
                      data-name="Path 162"
                      d="M980.585,727.412s125.643-16.388,117.449,49.165-103.793,111.987-248.556,0L865.867,743.8s81.941,21.851,114.718,5.463Z"
                      transform="translate(-656.757 -198.314)"
                      fill="#60392b"
                    />
                    <path
                      id="Path_163"
                      data-name="Path 163"
                      d="M926.01,754.406s0-32.777-27.314-30.045-76.479,5.463-76.479,5.463-16.388,10.925,5.463,16.388,87.4,32.777,87.4,32.777Z"
                      transform="translate(-714.17 -200.725)"
                      fill="#e7c2c2"
                    />
                    <path
                      id="Path_164"
                      data-name="Path 164"
                      d="M873.478,752.406s0-32.777,27.314-30.045,76.479,5.463,76.479,5.463,16.388,10.925-5.463,16.388-87.4,32.776-87.4,32.776Z"
                      transform="translate(-615.204 -204.188)"
                      fill="#e7c2c2"
                    />
                    <path
                      id="Path_165"
                      data-name="Path 165"
                      d="M997.325,907.239,956.569,765.953s-8.194-49.165-16.388-95.6-38.239-40.971-38.239-40.971l-5.463,2.731v73.747l5.463,68.284L956.474,924.1a28.68,28.68,0,1,0,40.85-16.872Z"
                      transform="translate(-575.38 -365.229)"
                      fill="#e7c2c2"
                    />
                    <path
                      id="Path_166"
                      data-name="Path 166"
                      d="M902.6,631.384s-30.045-5.463-38.239,40.971-16.388,95.6-16.388,95.6L807.216,909.239a28.679,28.679,0,1,0,40.851,16.872L902.6,776.158l5.463-68.285V640.222A9.882,9.882,0,0,0,902.6,631.384Z"
                      transform="translate(-756.311 -361.767)"
                      fill="#e7c2c2"
                    />
                    <path
                      id="Path_167"
                      data-name="Path 167"
                      d="M841.335,603.24a199.507,199.507,0,0,0,86.311,28.439l-9.1-10.9a66.866,66.866,0,0,0,20.652,4.1,22.619,22.619,0,0,0,18.437-8.629,21.032,21.032,0,0,0,1.409-19.434,39.843,39.843,0,0,0-12.534-15.675,74.63,74.63,0,0,0-69.461-12.414,44.6,44.6,0,0,0-20.758,13.307,25.227,25.227,0,0,0-5.089,23.383"
                      transform="translate(-670.856 -476.294)"
                      fill="#2f2e41"
                    />
                    <path
                      id="Path_168"
                      data-name="Path 168"
                      d="M1022.461,589.562a206.179,206.179,0,0,0-75.012-48.506c-18.134-6.717-37.871-10.868-56.827-7.052s-36.874,16.935-42.172,35.53c-4.324,15.208.142,31.586,6.853,45.9s15.673,27.587,21.1,42.446A96.877,96.877,0,0,1,778.92,787.8c18.625,2.5,35.795,11.251,54,15.91s39.7,4.343,53.224-8.7c14.315-13.8,14.6-36.237,13.908-56.108l-3.086-88.62c-.524-15.064-.972-30.613,4.46-44.675s18.344-26.374,33.419-26.246c11.425.1,21.534,7.017,30.7,13.843s18.847,14.108,30.239,15.121,24.372-7.4,23.52-18.8"
                      transform="translate(-778.92 -532.547)"
                      fill="#2f2e41"
                    />
                  </g>
                </g>
              </svg>
            </div>
          )}
          <div
            className={`flex text-4xl ${
              props?.container === "TxnDetailsCard" ? "" : "pt-12 p-4 mt-4 pb-4"
            } font-semibold text-[#27B882]`}
          >
            {" "}
            ₹ {CurrencyFormater(props?.createTxnData.amount)}
          </div>
          {props?.selectedAcc.name && (
            <div className="text-black text-2xl 4xl:text-3xl capitalize w-2/3 truncate ...">
              {props?.selectedAcc.name}
            </div>
          )}
          <div
            className="w-11/12 h-1 opacity-40"
            style={{
              background:
                "repeating-linear-gradient(to left,transparent,transparent 15px, #000000 15px, #000000 30px)",
            }}
          ></div>
          <div className="flex w-5/6 m-auto flex-col gap-2 3xl:gap-4 6xl:gap-6">
            <div className="flex justify-start items-center gap-4 3xl:gap-8 6xl:gap-12">
              <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  viewBox="0 0 475 475"
                >
                  <g id="WA" transform="translate(15 15)">
                    <g
                      id="Group_1361"
                      data-name="Group 1361"
                      transform="translate(0 0)"
                    >
                      <path
                        id="Path_1150"
                        data-name="Path 1150"
                        d="M11.946,441.054,107.3,414.83A224.69,224.69,0,0,0,226.5,449,222.5,222.5,0,1,0,4,226.5,224.691,224.691,0,0,0,38.17,345.7Z"
                        transform="translate(-4 -4)"
                        fill="#50bf7a"
                        stroke="#fff"
                        strokeMiterlimit="10"
                        strokeWidth="30"
                      />
                      <path
                        id="Path_1151"
                        data-name="Path 1151"
                        d="M239.806,176.445C224.708,208.23,196.9,212.2,196.9,212.2c-23.839,4.768-54.036-5.563-77.875-16.687-34.17-15.893-63.571-41.321-83.437-73.9C26.842,106.516,18.9,89.034,18.1,72.346c0,0-3.179-27.812,23.839-50.062A13.425,13.425,0,0,1,49.887,19.9h11.92a11.951,11.951,0,0,1,11.125,7.946l18.277,44.5a13.883,13.883,0,0,1-2.384,13.509L76.9,98.57c-3.973,3.973-3.973,9.536-1.589,14.3a26.049,26.049,0,0,0,4.768,6.357c14.3,19.071,33.375,33.375,54.83,42.911a54.783,54.783,0,0,0,7.946,3.179c5.562,1.589,10.33-.795,13.509-4.768l9.536-14.3a11.822,11.822,0,0,1,11.92-5.563l47.679,7.152c4.768.795,8.741,4.768,10.33,9.536l3.179,11.92C240.6,170.882,240.6,174.061,239.806,176.445Z"
                        transform="translate(93.149 106.448)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div
                className="text-left text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                antialiased truncate ..."
              >
                <div className="font-bold">
                  +91 {props?.selectedAcc.contact_no}
                </div>
                <div className="opacity-60">Phone</div>
              </div>
            </div>
            {props?.selectedAcc.email_id && (
              <>
                <div className="w-full h-0.5 bg-gray-200" />
                <div className="flex justify-start items-center gap-4 3xl:gap-8 6xl:gap-12">
                  <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      viewBox="0 0 512 392.778"
                    >
                      <g
                        id="iconfinder_gmail_4975304"
                        transform="translate(-0.001 0)"
                      >
                        <g
                          id="Group_14"
                          data-name="Group 14"
                          transform="translate(33.38 0)"
                        >
                          <path
                            id="Path_208"
                            data-name="Path 208"
                            d="M484.973,122.808,452.288,453.324H59.712L33.379,129.205,256,254.723Z"
                            transform="translate(-33.379 -60.548)"
                            fill="#f2f2f2"
                          />
                          <path
                            id="Path_209"
                            data-name="Path 209"
                            d="M473.886,60.983,256,267.1,38.114,60.983H473.886Z"
                            transform="translate(-33.379 -60.983)"
                            fill="#f2f2f2"
                          />
                        </g>
                        <path
                          id="Path_210"
                          data-name="Path 210"
                          d="M59.712,155.8V453.4H24.139A24.229,24.229,0,0,1,0,429.092V111.967l39,1.07Z"
                          transform="translate(0.001 -60.625)"
                          fill="#f14336"
                        />
                        <path
                          id="Path_211"
                          data-name="Path 211"
                          d="M512,111.988V429.115a24.234,24.234,0,0,1-24.154,24.309H452.288V155.82l19.692-46.852Z"
                          transform="translate(0 -60.646)"
                          fill="#f14336"
                        />
                        <path
                          id="Path_212"
                          data-name="Path 212"
                          d="M512,85.292v27.034l-59.712,43.832L256,300.232,59.712,156.158,0,112.326V85.292A24.23,24.23,0,0,1,24.139,60.983H38.114L256,220.909,473.886,60.983h13.962A24.232,24.232,0,0,1,512,85.292Z"
                          transform="translate(0.001 -60.983)"
                          fill="#f14336"
                        />
                        <path
                          id="Path_213"
                          data-name="Path 213"
                          d="M59.712,155.8,0,146.476V111.967Z"
                          transform="translate(0.001 -60.625)"
                          fill="#f14336"
                        />
                      </g>
                    </svg>
                  </div>
                  <div
                    className="text-left text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                antialiased truncate ..."
                  >
                    <div className="font-bold">
                      {props?.selectedAcc.email_id}
                    </div>
                    <div className="opacity-60">Email</div>
                  </div>
                </div>{" "}
              </>
            )}
          </div>
        </div>
        <div
          className={`w-full cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl ${
            props.container === "TxnDetailsCard"
              ? "bg-[#5C769F]"
              : "bg-[#7fb085]"
          } flex justify-evenly items-center gap-2`}
        >
          <div
            className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
            onClick={() => navigate(0)}
          >
            <div className="w-3/6 flex justify-center">
              <img
                width={"70%"}
                src={require("../../../../assets/CreateTransactionIcons/Create transaction.png")}
                alt=""
                srcSet=""
              />
            </div>
            <p>
              Create new <br /> transaction
            </p>
          </div>
          <div className="h-4/5 w-0.5 bg-white" />
          {props.createdClientTxn.txn_details.txn_id && (
            <>
              <div
                className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
                onClick={() => navigate(`/client/txn/${txn_id}`)}
              >
                <div className="w-3/6 flex justify-center">
                  <img
                    width={"70%"}
                    src={require("../../../../assets/CreateTransactionIcons/View details.png")}
                    alt=""
                    srcSet=""
                  />
                </div>
                <p>
                  View transaction <br /> details
                </p>
              </div>
              <div className="h-4/5 w-0.5 bg-white" />
            </>
          )}
          <div
            className="flex flex-col w-2/6 gap-4 items-center justify-center text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3x"
            onClick={() => navigate("/client_transactions")}
          >
            <div className="w-3/6 flex justify-center">
              <img
                width={"70%"}
                src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                alt=""
                srcSet=""
              />
            </div>
            <p>
              Go to <br /> dashboard
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TxnDetailsCard;
