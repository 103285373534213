import React from "react";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import "./index.css";

const FilterIconDesktop = ({ setDrawerState }) => {
  const toggleDrawer = () => {
    setDrawerState(true);
  };
  return (
    <div
      className="p-2 3xl:p-3 flex flex-row gap-4 bg-custom-card rounded-customXl hover:bg-[#F05A6A] drop-shadow-2xl cursor-pointer"
      onClick={toggleDrawer}>
      <Box className="w-4 h-4 3xl:w-6 3xl:h-6
        4xl:w-8 4xl:h-8 5xl:w-10 5xl:h-10">
        <img
          src={require("../../../../assets/ViewAllTransactions/Left arrow white.png")}
          alt=""
        />
      </Box>
      <Box className="w-4 h-4 3xl:w-6 3xl:h-6
        4xl:w-8 4xl:h-8 5xl:w-10 5xl:h-10">
        <img
          src={require("../../../../assets/ViewAllTransactions/filter.png")}
          alt=""
        />
      </Box>
    </div>
  );
};

export default FilterIconDesktop;
