import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import FilterDrawer from "../../../components/ViewAllTransactions/FilterDrawer";
import ViewAllCounts from "../../../components/ViewAllTransactions/ViewAllCounts";
import DatacardViewAllInternal from "../../../components/ViewAllTransactions/DatacardViewAllInternal";
import AllStatusTypePopover from "../../../components/ViewAllTransactions/AllStatusTypePopover";
import { useDispatch, useSelector } from "react-redux";
import { getInternalViewAll } from "../../../redux/features/txnViewAll/txnViewAll";
import { approvalStatus } from "../../../services/approvalStatus";
import NoDataImg from "../../../assets/TransactionDashboardIcons/Nothing.png";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import SearchBar from "../../../components/_utils/search/SearchBar";

const InternalViewAllT = () => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState();
  const viewAllData = useSelector((state) => state.txnViewAll.internal);
  const [searchParams] = useSearchParams();
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [totalRecords, setRecords] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const loading = useSelector((state) => state.txnViewAll.isLoading);
  const [txnStaus, setTxnStatus] = useState(
    searchParams.get("approval_status") || null
  );
  const toggleShowSearchBar = () => setShowSearchBar(!showSearchBar);
  const [data, setData] = useState([]);

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    // deDup data
    let j = 0;
    appendedData?.forEach((item) => {
      if (!deDupData?.find((deDupItem) => deDupItem.txn_id === item.txn_id)) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    return appendedData;
  };

  const handleSearch = (search_string) => {
    if (search_string !== searchString) {
      setData([]);
      setSearchString(search_string);
    }
  };

  useEffect(() => {
    setTxnStatus(searchParams.get("approval_status"));
  }, [searchParams]);

  const getData = () => {
    let source_owner_id = searchParams.get("source_owner_id");
    let destination_owner_id = searchParams.get("destination_owner_id");
    let settle_id = searchParams.get("settle_id");
    let approval_status = txnStaus;
    dispatch(
      getInternalViewAll({
        source_owner_id: source_owner_id,
        destination_owner_id: destination_owner_id,
        approval_status: approval_status,
        settle_id: settle_id,
        page_no: page,
        search_string: searchString,
      })
    );
  };

  const handleScroll = debounce(() => {
    const container = document.getElementById("infinityScrollContainer");
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight <=
      1
    ) {
      if (!loading && hasMore) {
        setPage(page + 1);
      }
    }
  }, 100);

  useEffect(() => {
    let container = document.getElementById("infinityScrollContainer");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (viewAllData === null) return;

    if (data?.length === 0 && viewAllData?.transactions?.length === 0) {
      setRecords(0);
      setTotalAmount(0.0);
    }

    if (viewAllData?.transactions.length === 0) {
      setHasMore(false);
      return;
    }
    setHasMore(true);
    setData(dataAppend(data, viewAllData?.transactions));
    if (viewAllData?.transactions?.length > 0) {
      setRecords(viewAllData?.title?.records);
      setTotalAmount(viewAllData?.title?.amount);
    }

    const container = document.getElementById("infinityScrollContainer");
    if (container.scrollHeight <= container.clientHeight) setPage(page + 1);
  }, [viewAllData]);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    setData([]);
    if (page !== 0) setPage(0);
    else {
      getData();
    }
  }, [searchString, txnStaus]);

  return (
    <div
      className="
      bg-custom-appBack
        flex flex-col h-screen"
    >
      <div
        className="bg-custom-appBackLight drop-shadow-2xl
        border-b-1 border-custom-card
         w-full flex flex-col justify-center h-1/3"
      >
        <div className="w-10/12 h-1/2 mt-24 self-center  flex flex-row justify-between">
          <div className="w-1/4 self-center">
            <ViewAllCounts txn={totalRecords} amt={totalAmount} />
          </div>
          <div className="invisible">
            <p>Filtered by :</p>
          </div>
          <div className="flex flex-col justify-center">
            <FilterDrawer />
          </div>
        </div>
      </div>

      <div
        id="infinityScrollContainer"
        className="py-4 3xl:py-6 5xl:py-8
          w-full  flex flex-col h-2/3 overflow-y-auto overflow-x-hidden viewAllvendor-txn"
      >
        <div className="self-center h-5/6 w-10/12 flex flex-col gap-4">
          <div className="h-[10%] flex flex-row justify-between">
            <AllStatusTypePopover
              txnType="internal"
              setTxnStatus={setTxnStatus}
              txnStatus={txnStaus}
              data={data}
            />
            <div className="w-[40%]">
              <SearchBar
                handleSearch={handleSearch}
                showSearchBar={showSearchBar}
                toggleShowSearchBar={toggleShowSearchBar}
                bgColor={"bg-custom-card"}
                bgHover={"hover:bg-custom-cardHover"}
              />
            </div>
          </div>

          <div className="h-[85%]">
            <div className="pb-6 3xl:pb-8 5xl:pb-10">
              {viewAllData?.transactions?.length > 0 || data?.length > 0 ? (
                <div
                  className="grid gap-6 4xl:gap-16 grid-cols-2
                  xl:grid-cols-4
                  4xl:grid-cols-5
                  6xl:grid-cols-6
                  justify-items-center"
                >
                  {data?.map((transaction) => (
                    <div className="min-w-full">
                      <Link
                        to={`/internal/txn/${transaction.txn_id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <DatacardViewAllInternal
                          amount={transaction.amount}
                          sourceAccount={transaction.source_bank_acc_no}
                          destinationAccount={
                            transaction.destination_bank_acc_no
                          }
                          status={approvalStatus(transaction.approval_status)}
                          sourceBankName={transaction.source_bank_name}
                          destinationBankName={
                            transaction.destination_bank_name
                          }
                          approvalStatus={transaction.approval_status}
                          postingDate={transaction.posting_date}
                        />
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
                  <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                    No transactions found <br />
                    Internal transactions will appear here.
                  </div>
                  <div className="w-2/6 p-4 flex flex-row justify-center">
                    <img
                      className="h-full w-full"
                      src={NoDataImg}
                      alt="NoDataImg"
                    />
                  </div>
                </div>
              )}
            </div>
            {loading && (
              <div className="w-full m-auto justify-center items-center flex">
                <img
                  src={require("../../../assets/Approve transactions/inputloder.gif")}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternalViewAllT;
