import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";

const legacyUrl = env.REACT_APP_LEGACY_URL;
const baseUrl = env.REACT_APP_BASE_URL;

export const updateAlias = createAsyncThunk(
  "updateAlias",
  async (values, { rejectWithValue }) => {
    const { id, alias_name } = values;
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        alias_name: alias_name,
      }),
    };

    try {
      const response = await fetch(`${baseUrl}/users/${id}`, requestOptions);
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData, "responseData");
        return responseData;
      } else {
        const errorMessage = responseData?.errors?.alias_name?.[0];
        throw new Error(errorMessage);
      }
    } catch (error) {
      toast.error(error.message, toast_position);
      return rejectWithValue(error.message);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "updateEmail",
  async (values, { rejectWithValue }) => {
    const { id, email } = values;
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        email: email,
      }),
    };

    try {
      const response = await fetch(`${baseUrl}/users/${id}`, requestOptions);
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData, "responseData");
        return responseData;
      } else {
        const errorMessage = responseData?.errors?.email?.[0];
        throw new Error(errorMessage);
      }
    } catch (error) {
      toast.error(error.message, toast_position);
      return rejectWithValue(error.message);
    }
  }
);

export const updateMobNo = createAsyncThunk(
  "updateMobNo",
  async (values, { rejectWithValue }) => {
    const { id, mob_no } = values;
    var requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        mob_no: mob_no,
      }),
    };

    try {
      const response = await fetch(`${baseUrl}/users/${id}`, requestOptions);
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData, "responseData");
        return responseData;
      } else {
        const errorMessage = responseData?.errors?.mobile?.[0];
        throw new Error(errorMessage);
      }
    } catch (error) {
      toast.error(error.message, toast_position);
      return rejectWithValue(error.message);
    }
  }
);

// export const userUpdate = createAsyncThunk(
//   "userUpdate",
//   async (values, { rejectWithValue }) => {
//     // debugger;
//     const { id, mob_no, email_id, alias_name } = values;
//     var requestOptions = {
//       method: "PUT",
//       redirect: "follow",
//       headers: {
//         "Content-Type": "application/json",
//         "X-Api-Key": sessionStorage.getItem("q2p_token"),
//       },
//       body: JSON.stringify({
//         email_id: email_id,
//         mob_no: mob_no,
//         alias_name: alias_name,
//       }),
//     };

//     await fetch(`${baseUrl}/users/${id}`, requestOptions)
//       .then((response) => {
//         console.log(response, "response-api");
//         return response.json();
//       })
//       .catch((error) => {
//         return console.log("error", error);
//       });
//   }
// );
const userSlice = createSlice({
  name: "user",
  initialState: {
    aliasIsLoading: false,
    aliasData: null,
    aliasError: null,
    emailIsLoading: false,
    emailData: null,
    emailError: null,
    mobNoIsLoading: false,
    mobNoData: null,
    mobNoError: null,
  },
  reducers: {
    resetAliasName(state) {
      state.aliasData = null;
      state.aliasError = null;
      state.aliasIsLoading = null;
    },
    resetMobNum(state) {
      state.mobNoData = null;
      state.mobNoError = null;
      state.mobNoIsLoading = null;
    },
    resetEmail(state) {
      state.emailData = null;
      state.emailError = null;
      state.emailIsLoading = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateAlias.pending, (state, action) => {
      state.aliasIsLoading = true;
    });
    builder.addCase(updateAlias.fulfilled, (state, action) => {
      state.aliasIsLoading = false;
      state.aliasData = action.payload;
      toast.success("Alias updated successfully", toast_position);
    });
    builder.addCase(updateAlias.rejected, (state, action) => {
      state.aliasIsLoading = false;
      state.aliasError = action.payload;
      toast.error(action?.payload?.errors?.base[0], toast_position);
    });
    builder.addCase(updateEmail.pending, (state, action) => {
      state.emailIsLoading = true;
    });
    builder.addCase(updateEmail.fulfilled, (state, action) => {
      state.emailIsLoading = false;
      state.emailData = action.payload;
      toast.success("Email updated successfully", toast_position);
    });
    builder.addCase(updateEmail.rejected, (state, action) => {
      state.emailIsLoading = false;
      state.emailError = action.payload;
      toast.error(action?.payload?.errors?.email[0], toast_position);
    });
    builder.addCase(updateMobNo.pending, (state, action) => {
      state.mobNoIsLoading = true;
    });
    builder.addCase(updateMobNo.fulfilled, (state, action) => {
      state.mobNoIsLoading = false;
      state.mobNoData = action.payload;
      toast.success("Mobile Number updated successfully", toast_position);
    });
    builder.addCase(updateMobNo.rejected, (state, action) => {
      state.mobNoIsLoading = false;
      state.mobNoError = action.payload;
      toast.error(action?.payload?.errors?.mobile[0], toast_position);
    });
  },
});

export const { resetEmail, resetMobNum, resetAliasName } = userSlice.actions;

export default userSlice.reducer;
