import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import deleteTxn, { deleteVendorTxn } from "../../../../redux/features/deleteTxn/deleteTxn";

const DeleteTxnPopUp = (props) => {
  const dispatch = useDispatch();

  return (
    <>
      {/* <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  absolute z-[9999]">
        <div
          className="w-100 h-fit flex flex-col gap-8  py-12 px-4 bg-custom-deniedReasonPurple rounded-4xl"
        > */}
          
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999]" style={{transform: "translate(-50%, -50%)"}}>
        <div
          className="h-fit w-fit flex flex-col gap-2 py-8 px-4 bg-custom-deniedReasonPurple rounded-4xl"
        >
          <div className="mx-8">
            <h2 className="text-[#FF7F33] font-semibold text-2xl ">Delete transaction</h2>
            <p
              className="text-white opacity-70 mt-5"
            >
              Are you sure, you want to delete this transaction?
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5%",
              margin: "0px 20px",
              width: "95%",
            }}
            className="px-8 pt-4"
          >
            <Button
              sx={{ borderRadius: "20px", borderColor: "#fff", color: "#fff", textTransform: "capitalize", textDecoration: "underline" }}
              onClick={() => props.setShowPopup(false)}
              className=""
            >
              Cancel
            </Button>
            <Button
              className=""
              variant="outlined"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
              onClick={() => {
                dispatch(deleteVendorTxn({ txn_id: [props?.txn_id] }));
                props.setShowPopup(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteTxnPopUp;
