import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import addbutton from "../../../assets/Accounts/Create/addbutton.png";
import arrowright from "../../../assets/Accounts/Create/arrowright.png";
import SendPayment from "../../../assets/Approve transactions/SendPaymentAdvice.png";
import SendTxnInitial from "../../../assets/Approve transactions/InitialEmail.png";
import bill from "../../../assets/Accounts/Create/bill.png";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import NoDataImg from "../../../assets/TransactionDashboardIcons/Nothing.png";
import vendorImage from "../../../assets/Approve transactions/Vendor.png";
import { useNavigate } from "react-router-dom";
import xpentraloader from "../../../assets/Beneficiary Icons/lodemore.gif";
import PrevIcon from "../../../assets/Beneficiary Icons/PreviousIcon.png";
import NextIcon from "../../../assets/Beneficiary Icons/NextIcon.png";
import { BeneTxnDashBoard } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { BeneTxnRequireApprovalPage } from "../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { checkUsertoken } from "../../../redux/features/login/login";
import FormatAmount from "../../../components/_utils/FormatAmount/FormatAmount";
import Datacard from "../../../components/Datacard";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";

const BeneTxnDashboard = () => {
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { BeneTxnDashBoardData, BeneTxnDashBoardLoading } = useSelector(
    (state) => state?.beneTxnViewAccData
  );
  const services = useSelector((state) => state.refreshToken.services);
  // const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    async function getData() {
      dispatch(checkUsertoken());
      await dispatch(BeneTxnDashBoard({ page: 1, page_size: 50 }));
    }
    getData();
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(checkUsertoken());
  //   dispatch(
  //     BeneTxnRequireApprovalPage({
  //       page_no: 1,
  //     })
  //   ).then((response) => {
  //     setTotalPages(response?.payload?.title?.total_pages);
  //   });
  // }, []);

  const handleDatacardClick = (approvalStatus) => {
    const routeMap = {
      approved: "/transaction/beneficiary_transaction/view_all",
      pending: "/transaction/beneficiary_transaction/view_all",
      require:
        "/transaction/beneficiary_transaction/transaction_single_approval",
      rejected: "/transaction/beneficiary_transaction/view_all",
      settled: "/transaction/beneficiary_transaction/view_all",
      cancelled: "/transaction/beneficiary_transaction/view_all",
      failed: "/transaction/beneficiary_transaction/view_all",
      abandoned: "/transaction/beneficiary_transaction/view_all",
      queued: "/transaction/beneficiary_transaction/view_all",
    };

    navigate(routeMap[approvalStatus], {
      state: { approvalStatus },
    });
  };

  const firstTenAccounts = BeneTxnDashBoardData?.transactions?.slice(0, 8);

  const beneTxnNext = () => {
    setShowSecondContent(!showSecondContent);
    setDisableNext(true);
    setDisablePrev(false);
  };

  const beneTxnPrev = () => {
    setShowSecondContent(!showSecondContent);
    setDisablePrev(true);
    setDisableNext(false);
  };

  const getColorClass = (approvalStatus) => {
    switch (approvalStatus) {
      case "pending":
        return "pendingColor";
      case "rejected":
        return "rejectedColor";
      case "approved":
        return "approvedColor";
      case "queued":
        return "queuedColor";
      case "settled":
        return "settledColor";
      case "abandoned":
        return "abandonedColor";
      case "cancelled":
        return "cancelledColor";
      case "denied":
        return "deniedColor";
      default:
        return "leftPrice";
    }
  };

  const accountTypesSummary =
    BeneTxnDashBoardData && BeneTxnDashBoardData.summary
      ? Object.entries(BeneTxnDashBoardData.summary)
      : [];

  const order = [
    "pending_transaction_count",
    "actionable_transaction_count",

    "awaiting_funds_distribution_transaction_count",

    "abandoned_distribution_transaction_count",
    "cancelled_transaction_count",
    "rejected_transaction_count",
    "denied_transaction_count",
    "blocked_transaction_count",
    "abandoned_transaction_count",
    "created_distribution_transaction_count",
    "distribution_complete_distribution_transaction_count",
  ];

  let firstFiveDataCards;
  let nextFiveDataCards;

  if (accountTypesSummary?.length > 7) {
    firstFiveDataCards = accountTypesSummary.slice(0, 7);
    nextFiveDataCards = accountTypesSummary.slice(7);
  } else {
    firstFiveDataCards = accountTypesSummary;
  }

  const getTransactionDataCardsDetails = (accountType, accountAccount) => {
    let accountTypeName;
    let status;
    let color;

    switch (accountType) {
      case "created_distribution_transaction_count":
        accountTypeName = "Awaiting verification";
        status = "approved";
        color = "#B196FA";
        break;
      case "actionable_transaction_count":
        accountTypeName = "Require approval";
        status = "require";
        color = "#31E5E5";
        break;
      case "pending_transaction_count":
        accountTypeName = "Waiting for approval";
        status = "pending";
        color = "#FBC02D";
        break;
      case "abandoned_distribution_transaction_count":
        accountTypeName = "Abandoned Distribution";
        status = "abandoned";
        color = "#B4B4B4";
        break;
      // case "cancelled_transaction_count":
      // case "rejected_transaction_count":
      // case "denied_transaction_count":
      // case "blocked_transaction_count":
      //   accountTypeName = "Failed Transactions";
      //   status = "failed";
      //   color = "#FF5860";
      //   break;

      case "rejected_transaction_count":
        accountTypeName = `Denied ${
          accountAccount > 1 ? "Transactions" : "Transaction"
        }`;
        status = "failed";
        color = "#FF5860";
        break;
      case "abandoned_transaction_count":
        accountTypeName = `Abandoned ${
          accountAccount > 1 ? "Transactions" : "Transaction"
        }`;
        status = "abandoned";
        color = "#B4B4B4";
        break;
      case "awaiting_funds_distribution_transaction_count":
        accountTypeName = `Queued  ${
          accountAccount > 1 ? "Transactions" : "Transaction"
        }`;
        status = "queued";
        color = "#FBC02D";
        break;
      case "distribution_complete_distribution_transaction_count":
        accountTypeName = `Settled  ${
          accountAccount > 1 ? "Transactions" : "Transaction"
        }`;
        status = "settled";
        color = "#4DDD37";
        break;
      case "rejected_distribution_transaction_count":
        accountTypeName = `Denied Distribution`;
        status = "failed";
        color = "#FF5860";
        break;

      default:
        accountTypeName = "Unknown";
        status = "unknown";
        color = "#FBC02D";
        break;
    }

    return { accountTypeName, status, color };
  };

  const showDataCardDetailsFirstDiv = (cards) => {
    return cards
      ?.filter(([accountType, accountCount]) => {
        // Filter out certain account types based on count and specific types
        const excludeTypes = [
          "approved_transaction_count",
          "cancelled_transaction_count",
          "blocked_transaction_count",
        ];

        if (excludeTypes.includes(accountType)) {
          return false;
        }

        if (
          accountCount <= 0 &&
          accountType !== "pending_transaction_count" &&
          accountType !== "actionable_transaction_count"
        ) {
          return false;
        }

        // Service-based filtering
        if (
          (services?.includes("ABeneficiaryTxn") &&
            services?.includes("CBeneficiaryTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }

        if (
          services?.includes("ABeneficiaryTxn") &&
          !services?.includes("CBeneficiaryTxn")
        ) {
          return accountType !== "pending_transaction_count";
        }

        if (
          services?.includes("CBeneficiaryTxn") &&
          !services?.includes("ABeneficiaryTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }

        // Ensure all account types are included if none of the above conditions apply
        return true;
      })
      ?.sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color } =
          getTransactionDataCardsDetails(accountType, accountCount);
        return (
          <Box
            key={accountType}
            sx={{
              opacity: "0.9",
              height: "111px",
            }}
          >
            <Datacard
              color={color}
              onClick={() => handleDatacardClick(status)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };
  const showDataCardDetailsSecondDiv = (cards) => {
    return cards
      ?.filter(([accountType, accountCount]) => {
        if (
          (accountCount <= 0 &&
            accountType !== "pending_transaction_count" &&
            accountType !== "actionable_transaction_count") ||
          accountType === "approved_transaction_count"
        ) {
          return false;
        }
        if (
          (services?.includes("ABeneficiaryTxn") &&
            services?.includes("CBeneficiaryTxn")) ||
          services?.includes("Admin")
        ) {
          return true;
        }
        if (
          services?.includes("ABeneficiaryTxn") &&
          !services?.includes("CBeneficiaryTxn")
        ) {
          return accountType !== "pending_transaction_count";
        }

        if (
          services?.includes("CBeneficiaryTxn") &&
          !services?.includes("ABeneficiaryTxn")
        ) {
          return accountType !== "actionable_transaction_count";
        }

        return accountType;
      })
      .sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]))
      ?.map(([accountType, accountCount]) => {
        const { accountTypeName, status, color } =
          getTransactionDataCardsDetails(accountType, accountCount);

        return (
          <Box
            key={accountType}
            sx={{
              opacity: "0.9",
              height: "111px",
            }}
          >
            <Datacard
              color={color}
              onClick={() => handleDatacardClick(status)}
              text={
                accountTypeName === "Unknown" ? accountType : accountTypeName
              }
              number={accountCount}
            />
          </Box>
        );
      });
  };

  return (
    <div
      style={{ marginTop: "60px", position: "absolute", top: "-10px" }}
      className="w-full"
    >
      <div className="BeneTxn_Dashboard">
        <div
          className="BeneTxn_NavMenu"
          style={{
            height: accountTypesSummary?.length > 7 ? "230px" : "200px",
          }}
        >
          {BeneTxnDashBoardData?.transactions?.length > 0 ? (
            <>
              <div className="BeneTxn_GridLayout ">
                <div
                  className={`transition-effect ${
                    !showSecondContent ? "showFirstContent" : "hideFirstContent"
                  }`}
                >
                  {showDataCardDetailsFirstDiv(firstFiveDataCards)}
                </div>

                <div
                  className={`transition-effect ${
                    showSecondContent
                      ? "showSecondContent"
                      : "hideSecondContent"
                  }`}
                >
                  {showDataCardDetailsSecondDiv(nextFiveDataCards)}
                </div>
              </div>

              <div
                className="BeneTxn_CaraouselBar"
                style={{ display: accountTypesSummary?.length <= 7 && "none" }}
              >
                <div className="BeneTxn_CarouselDotbar">
                  <div
                    className="BeneTxn_FirstCaraoDot"
                    style={{
                      width: showSecondContent ? "12px" : "46px",
                      opacity: showSecondContent ? "0.65" : "1",
                      transition: "width 0.3s ease, opacity 0.3s ease",
                    }}
                  ></div>
                  <div
                    className="BeneTxn_SecondCaraoDot"
                    style={{
                      width: showSecondContent ? "46px" : "12px",
                      opacity: showSecondContent ? "1" : "0.65",
                      transition: "width 0.3s ease, opacity 0.3s ease",
                    }}
                  ></div>
                </div>
                <div className="BeneTxn_NextAndPrevIcon">
                  <div
                    className="BeneTxn_CarouPrevIcon"
                    onClick={disablePrev ? null : beneTxnPrev}
                  >
                    <img
                      src={PrevIcon}
                      alt=""
                      style={{
                        opacity: showSecondContent ? "1" : "0.5",
                        transition: "opacity 0.3s ease", // Add transition property
                      }}
                    />
                  </div>
                  <div
                    className="BeneTxn_CarouNextIcon"
                    onClick={disableNext ? null : beneTxnNext}
                  >
                    <img
                      src={NextIcon}
                      alt=""
                      style={{
                        opacity: showSecondContent ? "0.5" : "1",
                        transition: "opacity 0.3s ease",
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="beneTxngridlayout">
              <Box
                sx={{
                  opacity: "0.9",
                  height: "111px",
                  cursor: "pointer",
                }}
              >
                <Datacard
                  color={
                    (services?.includes("ABeneficiaryTxn") &&
                      services?.includes("CBeneficiaryTxn")) ||
                    services?.includes("Admin") ||
                    (services?.includes("ABeneficiaryTxn") &&
                      !services?.includes("CBeneficiaryTxn"))
                      ? "#31E5E5"
                      : "#FBC02D"
                  }
                  onClick={() => handleDatacardClick("pending")}
                  text={
                    (services?.includes("ABeneficiaryTxn") &&
                      services?.includes("CBeneficiaryTxn")) ||
                    services?.includes("Admin") ||
                    (services?.includes("ABeneficiaryTxn") &&
                      !services?.includes("CBeneficiaryTxn"))
                      ? "Require Approval"
                      : "Waiting for Approval"
                  }
                  number={"0"}
                />
              </Box>
              <Box
                sx={{
                  opacity: "0.9",
                  height: "111px",
                  cursor: "pointer",
                }}
              >
                <Datacard
                  color={"#4DDD37"}
                  onClick={() => handleDatacardClick("approved")}
                  text={"Settled Transaction"}
                  number={"0"}
                />
              </Box>
            </div>
          )}
        </div>
      </div>

      <div className="BeneTxn_AllSection">
        <div className="BeneTxn_TxnSection">
          {BeneTxnDashBoardLoading ? (
            <div className="loader-container expentraloaderwithDashBoard">
              <img src={xpentraloader} alt="Loading..." />
            </div>
          ) : BeneTxnDashBoardData?.transactions?.length ? (
            <div className="BeneTxn_ContentPage">
              <div className="BeneTxn_RecentPage">
                <div className="BeneTxn_RecentText">Recent</div>
                <div
                  className="BeneTxn_ViewallPage hover:bg-custom-cardHover"
                  onClick={() =>
                    navigate("/transaction/beneficiary_transaction/view_all", {
                      state: { approvalStatus: "all" },
                    })
                  }
                >
                  <p>View All</p>
                  <img src={arrowright} alt="" />
                </div>
              </div>

              <div className="BeneTxn_BenGridLaySection">
                <div className="BeneTxn-GridContainer">
                  {firstTenAccounts?.map((account, index) => (
                    <div
                      className="BeneTxn_GridItemContent cursor-pointer hover:bg-custom-cardHover"
                      key={index}
                      onClick={() =>
                        navigate(
                          `/transaction/beneficiary_transaction/details/${account.txn_id}`
                          // {
                          //   state: totalPages,
                          // }
                        )
                      }
                    >
                      <div className="BeneTxn_ItemContent">
                        <div className="BeneTxn_TxtLeftSection">
                          <div className="BeneTxn_PriceSection">
                            <p
                              className={`leftPrice ${getColorClass(
                                account.lifecycle_status
                              )}`}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  fontSize: "17.2px",
                                  paddingRight: "5px",
                                }}
                              >
                                ₹
                              </span>

                              <span>
                                {<FormatAmount price={account.amount} />}
                              </span>
                            </p>
                          </div>
                          <div className="BeneTxn_CompanyName">
                            <p
                              className={`lelfP ${getColorClass(
                                account.approval_status
                              )}`}
                            >
                              {account.beneficiary_account_name}
                            </p>
                          </div>
                        </div>
                        <div className="BeneTxn_ImageRightSection">
                          <div className="BeneTxn_CategoriesSection">
                            <img src={vendorImage} alt="" />
                          </div>
                          <div className="BeneTxn_DividerBtwnCatnBank"></div>
                          <div className="BeneTxn_BankImagesSection">
                            <img
                              src={require("../../../assets/IfscLogo/" +
                                IfscCodeMapper(
                                  account?.beneficiary_account_ifsc_code
                                ))}
                              alt="bank"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="beneTxnDashNotFound">
              <img width={"100%"} src={NoDataImg} alt="NoDataImg" />

              <Typography sx={{ color: "#FFFFFF", textAlign: "center" }}>
                No transactions found <br />
                All beneficiary transactions will appear here.
              </Typography>
            </div>
          )}

          <div className="BeneTxn_AcountRightSec">
            <Box
              sx={{
                boxShadow: "5px 5px 11px #00000033",
                width: "308px",
                cursor: "default",
                marginLeft: "22px",
                marginTop: "30px",
                opacity: 1,
                height: "65px",
                borderRadius: "22px",
                justifyContent: "center",
                bgcolor: "#3A5686",
              }}
            >
              <div
                className={`createAc ${
                  services?.includes("CBeneficiaryTxn") ||
                  services?.includes("Admin")
                    ? "hover:bg-custom-cardHover"
                    : ""
                }`}
                style={{
                  opacity:
                    services?.includes("CBeneficiaryTxn") ||
                    services?.includes("Admin")
                      ? "1"
                      : "0.5",
                  cursor:
                    services?.includes("CBeneficiaryTxn") ||
                    services?.includes("Admin")
                      ? "cursor"
                      : "default",
                }}
                onClick={() => {
                  if (
                    services?.includes("CBeneficiaryTxn") ||
                    services?.includes("Admin")
                  ) {
                    navigate("/transaction/beneficiary_transaction/create");
                  }
                }}
              >
                {/*  navigate("/transaction/beneficiary_transaction/create") */}
                <img src={addbutton} alt="img add" />
                <p className="BeneTxn_CreateTxn">Create Transaction</p>
              </div>
            </Box>

            <Box
              sx={{
                boxShadow: "5px 5px 11px #00000033",
                width: "308px",
                marginLeft: "22px",
                marginTop: "30px",
                paddingBottom: "3%",
                height: "fit-content",
                opacity: 1,
                borderRadius: "22px",
                justifyContent: "center",
                bgcolor: "#3A5686",
              }}
            >
              <div className="BeneTxn_QuickActionSec">
                <div className="BeneTxn_LeftBenfContent">
                  <p>Quick actions</p>
                </div>

                <div className="BeneTxn_RightBenfContent">
                  <div className="BeneTxn_leftSidecontent">
                    <img src={bill} alt="" />
                    <p>Send MIS report</p>
                  </div>
                  <div className="BeneTxn_RightSideContent">
                    <img src={arrowright} alt="" />
                  </div>
                </div>

                <div className="BeneTxnRightDivider"></div>

                <div className="BeneTxn_RightBenfContent">
                  <div className="BeneTxn_leftSidecPaymentAdvice">
                    <img src={SendPayment} alt="" />
                    <p>Send payment advices</p>
                  </div>
                  <div className="BeneTxn_RightSideContent">
                    <img src={arrowright} alt="" />
                  </div>
                </div>

                <div className="BeneTxnRightDivider"></div>

                <div className="BeneTxn_RightBenfContent">
                  <div className="BeneTxn_LeftSideSendTxn">
                    <img src={SendTxnInitial} alt="" />
                    <p>Send transaction initiation emails</p>
                  </div>
                  <div className="BeneTxn_RightSideContent">
                    <img src={arrowright} alt="" />
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneTxnDashboard;
