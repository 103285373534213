import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import selectAcc from "../../../../assets/CreateTransactionIcons/Select account.png";
import dorpdownLogo from "../../../../assets/TxnDetails/asset-1.png";

const DebitAccount = (props) => {
  return (
    <div className="h-full bg-custom-card flex flex-col
      p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl
      overflow-hidden gap-4 3xl:gap-6 4xl:gap-8 5xl:gap-10">
      <div className="h-1/3 flex flex-row justify-between">
        <div className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl">
          {props.componentType} Account
        </div>
      </div>
      <div onClick={() => {
        props.handleOpen(props.componentType);
      }} className="flex flex-col gap-4">
        <div className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
        >
          Select Account
        </div>
        <div className="self-center cursor-pointer">
          <img src={selectAcc} alt="" className="w-8 h-8 3xl:w-10 3xl:h-10 5xl:w-12 5xl:h-12" />
        </div>
      </div>
    </div>
  );
};

export default DebitAccount;
