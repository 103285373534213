import React from "react";
import formatHolidayDate from "../../../utils/holidayDateFormatter";

function HolidayDateCardnew(props) {
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return (
    <div className="datecontent">
      <div className="datediv">
        {new Date(props.date).getDate()}
        <br />
        <span style={{ fontSize: "10px" }}>
          {weekdays[new Date(props.date).getDay()]}
        </span>
      </div>
      <div className="datedisc">
        {props.occasion}
        <br />
        <span style={{ fontSize: "10px", fontWeight: "normal" }}>
          {formatHolidayDate(props.date).diff}
        </span>
      </div>
    </div>
  );
}

export default HolidayDateCardnew;
