import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import accTransform from "../../../../utils/accTransform";
import "./index.css";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const ExistingVendorAcc = (props) => {
  const dispatch = useDispatch();
  const handleVendorSelection = () => {
    if (!(props.value.approval_status === "Y" || props.value.approval_status === "1"))
      return;
    dispatch(setBufferState({ vendor: props.value }));
    props.enableNextButton(true);
    props.handleClose();
  }

  return (
    < div
      className={`flex flex-row bg-custom-lightGray  w-full h-fit justify-between
        ${(props.value.approval_status === "Y" || props.value.approval_status === "1") && "cursor-pointer"}
        ${!(props.value.approval_status === "Y" || props.value.approval_status === "1") && "opacity-40"}
        ${props.value.vendor_id === props.selectedAcc?.vendor_id && "border-2 border-custom-green"}
        rounded-large 4xl:rounded-customXl 6xl:rounded-custom2Xl overflow-hidden drop-shadow-xl text-custom-offwhite
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40
        text-base 3xl:text-xl`}
      onClick={handleVendorSelection} >
      <div className="w-3/5 self-center truncate ...
       text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl
       px-6 py-2 3xl:px-8 3xl:py-4 5xl:px-10 5xl:py-10 capitalize font-bold">
        {props.value.name}
      </div>
      <div className="w-2/5 h-full flex flex-row justify-end gap-6 3xl:gap-8">
        <div className="p-2 w-1/2 self-center antaliased text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl">
          {accTransform(props.value.bank_acc_no)}
        </div>
        <div className="h-full px-6 w-1/2 flex bg-white rounded-l-large 4xl:rounded-l-customXl 6xl:rounded-l-custom2Xl">
          <img
            className="self-center"
            src={(require("../../../../assets/Banks/" + bankLogoMapper(props.value.bank_name) + ".png"))}
            alt="banklogo"
          />
        </div>
      </div>
    </div >
  );
};

export default ExistingVendorAcc;

