import React from "react";
import "./TransactionBankCardLogo.css";
import IfscCodeMapper from "../../../../../components/_utils/IfscLogoMapper";
import salary from "../../../../../assets/Beneficiary Icons/salaries.png";

const TransactionBankCardLogo = (props) => {
  return (
    <div
      className={`flex justify-center items-center group-hover:bg-blue-100 text-center bg-white `}
      style={{
        position: "relative",
        borderRadius:
          props.owner || props?.type === "account"
            ? "20px 0px 4px 20px"
            : "20px 0px",
        width: props?.type === "account" ? "80px" : "98px",
        height: props?.type === "account" ? "26px" : "30px",
        right: "15px",
      }}
    >
      <div className="view-all-txn-icon">
        <img src={salary} alt="txn-icon" className="view-all-txn-image" />
      </div>
      <div className="txn-split">&nbsp;</div>
      <div className="view-all-txn-div">
        <img
          src={require("../../../../../assets/IfscLogo/" +
            IfscCodeMapper(props.data?.beneficiary_account_ifsc_code))}
          alt="bank"
          className="view-all-txn-image-bank"
        />
      </div>
    </div>
  );
};

export default TransactionBankCardLogo;
