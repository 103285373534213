import React from "react";
import { Box, Button } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";
import formatCurrency from "../../../../utils/CurrencyFormater";
import NextDate from "../../../NextDate";
import trimSentence from "../../../../utils/trimSentence";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const TxnCreatedCard = (props) => {
  const navigate = useNavigate();
  return (
    <Box className="TxnCreatedContainer">
      <p className="txnCreatedText p-4">
        Transaction created successfully!
        <img
          width="27px"
          src={require("../../../../assets/Approve transactions/selected.png")}
          alt=""
          srcSet=""
        />
      </p>
      <Box className="txnStatusCard">
        <h2 className="amountTextTxnCreated">
          {" "}
          ₹ {formatCurrency(props.amount)}
        </h2>
        {props.txnCompletionDate && (
          <Box className="estimatedDate2">
            <b>
              {" "}
              {props.txnCompletionDate === 2 ? (
                <NextDate />
              ) : (
                <NextDate Tplus2={true} />
              )}{" "}
            </b>{" "}
            <br />
            <span>Estimated transaction completion on</span>
          </Box>
        )}
        <Box className="txnCreatedIntName">
          {trimSentence(props.selectedAccName, 20)}
        </Box>
        <Box className="dashedLine"></Box>
        <Box className="detailsText">
          <div className="w-full">
            <div className="w-full truncate ...">
              <b> {props.account}</b>
            </div>
            <p>Account number</p>
          </div>
          <Box className="simpleLine"></Box>
          <b>{props.ifsc}</b>
          <Box className="paraNImg">
            <p>IFSC code</p>
            <Box>
              <img
                width={"101px"}
                src={require("../../../../assets/Banks/" +
                  bankLogoMapper(props.bank_name) +
                  ".png")}
                alt=""
                srcSet=""
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="invoiceNumber">
        <b>
          {props.invoice_no ? trimSentence(props.invoice_no, 25) : "Not given"}
        </b>
        <p className="py-3">Invoice number</p>
        <br />
        <b>
          {props.distribution_remark
            ? trimSentence(props.distribution_remark, 25)
            : "Not given"}
        </b>
        <p className="py-3">Distribution remark</p>
      </Box>

      <Button className="createTxnBt" onClick={() => navigate(0)}>
        <img
          width={"30px"}
          src={require("../../../../assets/CreateTransactionIcons/Create transaction.png")}
          alt=""
          srcSet=""
        />
        &nbsp; Create new transaction
      </Button>
    </Box>
  );
};

export default TxnCreatedCard;
