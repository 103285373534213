import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../env";


const baseUrl = env.REACT_APP_BASE_URL;
// Action
export const getSpecificCIN = createAsyncThunk(
    "getSpecificCIN",
    async (values) => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                // "X-Api-Key": sessionStorage.getItem("q2p_token"),
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
        };

        return await fetch(
            `${baseUrl}/verification/cin/${values?.cin || 1}`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);

export const verifySpecificCIN = createAsyncThunk(
    "verifySpecificCIN",
    async (values) => {
        const data = {
            'cin': values?.cin
        }
        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                // "X-Api-Key": sessionStorage.getItem("q2p_token"),
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
            body: JSON.stringify(data)
        };

        return await fetch(
            `${baseUrl}/verification/cin/verify`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);


const CINSlice = createSlice({
    name: "cin",
    initialState: {
        isLoading: false,
        data: [],
        verified_data: [],
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getSpecificCIN.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getSpecificCIN.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(getSpecificCIN.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
        builder.addCase(verifySpecificCIN.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(verifySpecificCIN.fulfilled, (state, action) => {
            state.isLoading = false;
            state.verified_data = action.payload;
        });
        builder.addCase(verifySpecificCIN.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default CINSlice.reducer;