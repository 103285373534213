import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Link } from "react-router-dom";
import MenuItemCard from "../../MenuItemCard";
import checkIfEnable from "../../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../../services/ServiceIDByLabel";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useRef } from "react";
import "./index.css";

export default function MobileViewPopover(props) {
  const services_list = useSelector((state) => state.refreshToken.services);
  const elementRef = useRef(null);
  const setOpenMobilePopover = props.setOpenMobilePopover;

  const handleTogglePopover = () => {
    setOpenMobilePopover(false);
  };
  const [state, setState] = useState({
    bottom: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setOpenMobilePopover(open);
  };

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div ref={elementRef} className="popover-main-mobile">
              <Box
                sx={{
                  height: "700px",
                  width: "75%",
                  flexGrow: 1,
                  boxShadow: "none",
                  margin: "auto",
                }}
              >
                <Box>
                  {props.data.map((transaction, index) =>
                    transaction.title !== "Admin Transactions" ? (
                      <Box
                        sx={{ display: "flex", justifyContent: "center" }}
                        key={index}
                      >
                        <Box sx={{ paddingTop: "5%" }}>
                          <Typography
                            sx={{
                              /* UI Properties */
                              textAlign: "left",
                              fontWeight: "600",
                              letterSpacing: "0px",
                              color: "#042E77",
                              textTransform: "uppercase",
                              font: "normal normal 600 16px Open Sans, sans-serif",
                              opacity: 1,
                            }}
                          >
                            {transaction.title}
                          </Typography>
                          <Box
                            sx={{
                              padding: "2%",
                            }}
                          >
                            {transaction.menuItems.map((menuItem, index) => (
                              <Link
                                style={{
                                  textDecoration: "none",
                                  pointerEvents: checkIfEnable(
                                    ServiceIDByLabel,
                                    menuItem.label,
                                    services_list
                                  )
                                    ? "auto"
                                    : "none",
                                }}
                                onClick={() => handleTogglePopover()}
                                to={`/${menuItem.route}`}
                              >
                                {" "}
                                <MenuItemCard
                                  key={index}
                                  icon={menuItem.icon}
                                  label={menuItem.label}
                                />
                              </Link>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    ) : null
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{
                      padding: "2%",
                      textAlign: "center",
                      font: "normal normal normal 10px/12px Open Sans",
                      letterSpacing: "0px",
                      color: "#202020",
                      paddingRight: "5%",
                      position: "relative",
                    }}
                  >
                    *If disabled - the feature is not available for your ID.
                  </Typography>
                </Box>
                {props.data[props.data.length - 1].title ===
                  "Admin Transactions" && (
                  <Box
                    sx={{
                      paddingTop: "2%",
                      paddingLeft: "2%",
                      width: "fit-content",
                      margin: "auto",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        textAlign: "left",
                        font: "normal normal 600 16px Open Sans, sans-serif",
                        letterSpacing: "0px",
                        color: "#042E77",
                        textTransform: "uppercase",
                        opacity: 1,
                      }}
                    >
                      Admin Transactions
                    </Typography>
                    <Box>
                      {props.data[props.data.length - 1].menuItems.map(
                        (transaction, index) => {
                          return (
                            <Box key={index}>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  pointerEvents: checkIfEnable(
                                    ServiceIDByLabel,
                                    transaction.label,
                                    services_list
                                  )
                                    ? "auto"
                                    : "none",
                                }}
                                onClick={() => handleTogglePopover()}
                                to={`/${transaction.route}`}
                              >
                                <MenuItemCard
                                  key={index}
                                  icon={transaction.icon}
                                  label={transaction.label}
                                />
                              </Link>
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
