import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const PaymentGateway = () => {
  const initialCardResponse = useSelector((state) => state.settleTxn.settleCardRes);
  const addCardResponse = useSelector((state) => state.createAllAcc.createAccResponse);

  const [cardResponse, setCardResponse] = useState(initialCardResponse);

  useEffect(() => {
    if (!cardResponse) {
      setCardResponse(addCardResponse);
    }
  }, [addCardResponse]);

  useEffect(() => {
    if (cardResponse) {
      document.getElementById('seamless_auto_submit_form')?.submit();
    }
  }, [cardResponse]);

  return (
    cardResponse &&
    <div dangerouslySetInnerHTML={{ __html: cardResponse?.easebuzz_api_details?.form_to_load }}></div>
  );
}

export default PaymentGateway;
