import React from "react";

import {
  AccountVendorViewAllBody,
} from './Strategy/vendorStrategy';

import {
  AccountFieldForceViewAllBody,
} from './Strategy/fieldForceStrategy';

import {
  AccountOwnerViewAllBody,
} from './Strategy/ownerStrategy';

import {
  AccountClientViewAllBody,
} from './Strategy/clientStrategy';

import {
  AccountCardViewAllBody,
} from './Strategy/cardStrategy';

const StrategyContext = (data) => {
  let accountType = data.accountType;
  let viewAllData = data.viewAllData;

  let ComponentToRender;

  const strategyMap = {
    "vendor": () => {
      ComponentToRender = AccountVendorViewAllBody;
    },
    "client": () => {
      ComponentToRender = AccountClientViewAllBody;
    },
    "field_force": () => {
      ComponentToRender = AccountFieldForceViewAllBody;
    },
    "owner": () => {
      ComponentToRender = AccountOwnerViewAllBody;
    },
    "cards": () => {
      ComponentToRender = AccountCardViewAllBody;
    }
  }

  strategyMap[accountType]();
  return (
    <ComponentToRender
      viewAllData={viewAllData}
    />
  )
}

export default StrategyContext;
