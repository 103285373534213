import React from "react";
import "./index.css";
import formatCurrency from "../../utils/CurrencyFormater";

const TransactionAmount = (props) => {
  // i need to work on this -- ARJUN
  return (
    props.showText ?
      <div
        style={{
          'background': "transparent linear-gradient(108deg, #3173E2 0%, #DF5C74 100%) 0% 0% no-repeat padding-box"
        }}
        className="w-4/6
        p-4 3xl:p-6 4xl:p-10
        text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl
        rounded-2xl 3xl:rounded-3xl 4xl:rounded-large
        tracking-normal text-white bg-padding-box shadow-customCard
        text-center antialiased
        flex items-center justify-center mt-24
        flex-col mx-7
        drop-shadow-xl">
        Select to proceed
      </div>
      :
      <div className="
        w-4/6 mt-24 mx-7
        drop-shadow-xl
        rounded-2xl 3xl:rounded-3xl 4xl:rounded-large
        p-4 3xl:p-6 4xl:p-10
        flex flex-col
        gap-4 2xl:gap-5 4xl:gap-6 5xl:gap-7 6xl:gap-8"
        style={{
          'background': "transparent linear-gradient(108deg, #3173E2 0%, #DF5C74 100%) 0% 0% no-repeat padding-box"
        }}>
        <div className="
        text-white flex flex-row justify-between
        text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl
        text-center antialiased">
          <span>Transactions</span>
          <span>
            <span>Σ </span>
            {props.txn_count}
          </span>
        </div>
        <hr className="w-full" />
        <div className="
        text-white flex flex-row justify-between
          text-lg 3xl:text-xl 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl
          text-center antialiased">
          <span>Amount</span>
          <span>{`₹ ${(props.amount)}`}</span>
        </div>
      </div>

  );
};
export default TransactionAmount;
