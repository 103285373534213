import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import { Link, useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import pci from "../../assets/Landing/pci.png";
import "../../assets/Landing/css/landingPage.css";
import "../../assets/Landing/css/font-awesome.css";
import "../../assets/Landing/css/owl.carousel.css";

import Dashboard from "../../assets/Landing/1.png";
import Createtransaction from "../../assets/Landing/2.png";
import Selectapprovetransaction from "../../assets/Landing/3.png";
import Approved from "../../assets/Landing/4.png";
import Selectforsettlement from "../../assets/Landing/5.png";
import Settletransaction from "../../assets/Landing/6.png";
import TransactionSuccess from "../../assets/Landing/7.png";

const HowitworkPage = ({
  scrollSpeed = 1500,
  scrollText = <i className="fa fa-angle-up"></i>,
}) => {
  const navigate = useNavigate();
  const workSectionRef = useRef(null);
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true);

  const [isMenuActive, setIsMenuActive] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      once: true,
    });

    window.addEventListener("load", () => {
      setTimeout(() => setIsPreloaderVisible(false), 400);
    });

    const handleScroll = () => {
      if (window.scrollY > 48) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 120) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    const handleResize = () => {
      if (window.innerWidth > 991 && isMenuActive) {
        setIsMenuActive(true);
      } else {
        setIsMenuActive(false);
      }
    };

    if (window.innerWidth > 991 && isMenuActive) {
      setIsMenuActive(true);
    } else {
      setIsMenuActive(false);
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="landingbody">
      <div id="preloder">
        <div className="loader"></div>
      </div>
      <header
        className={`header-section ${isSticky ? "sticky slideInDown" : ""}`}
      >
        <div className="header-content">
          <div className="landingcontainer">
            <a
              onClick={() => navigate("/")}
              className="site-logo"
              style={{ cursor: "pointer" }}
            >
              <img className="landingimg" src={xpentraLogo} alt="xpentraLogo" />
            </a>
            <div className="responsive-bar" onClick={toggleMenu}>
              <i className="fa fa-bars"></i>
            </div>
            <a href="" className="user">
              <i className="fa fa-user"></i>
            </a>
            <button
              className="site-btn sb-gradients sbg-line"
              id="loginbtn"
              onClick={() => navigate("/login")}
            >
              Login
            </button>
            {isMenuActive && (
              <nav className="main-menu">
                <ul className="menu-list">
                  <li onClick={() => navigate("/")}>
                    <a>Home</a>
                  </li>
                  <li onClick={() => navigate("/featruepage")}>
                    <a>Features</a>
                  </li>
                  <li
                    className="actived"
                    onClick={() => navigate("/howitwork")}
                  >
                    <a>How it works</a>
                  </li>
                  <li onClick={() => navigate("/aboutus")}>
                    <a>About</a>
                  </li>
                  <li onClick={() => navigate("/contact")}>
                    <a>Contact</a>
                  </li>
                  <li onClick={() => navigate("/faq")}>
                    <a>FAQ</a>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </header>

      <main>
        <section className="inner-section gradient gradient-primary-auxiliary">
          <div className="shapes-container">
            <div className="static-shape ghost-shape ghost-shape-1"></div>
            <div className="static-shape pattern-dots-1"></div>
            <div className="static-shape pattern-dots-2"></div>
          </div>
          <div className="landingcontainer">
            <div className="landingrow">
              <div className="coll-md-12">
                <div className="landinginner-text">
                  <h2>How it works</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-section boxshadow spad">
          <div className="landingcontainer">
            <div className="vertical-center-inner">
              <div
                className="landingrow"
                data-aos="fade-down"
                data-aos-delay="400"
              >
                <div className="coll-12">
                  <div className="iphone">
                    <div className="screen shadow-box">
                      <Carousel
                        autoPlay
                        interval={2000} // Time between slides in milliseconds
                        infiniteLoop
                        stopOnHover
                        showThumbs={true} // Hide thumbnails if you don't need them
                        showStatus={false} // Hide status indicator
                        showIndicators={true} // Show indicators (dots)
                        showArrows={true} // Show navigation arrows
                      >
                        <div>
                          <img src={Dashboard} alt="" />
                          <p className="legend">Dashboard</p>
                        </div>
                        <div>
                          <img src={Createtransaction} alt="" />
                          <p className="legend">Create Transaction</p>
                        </div>
                        <div>
                          <img src={Selectapprovetransaction} alt="" />
                          <p className="legend">Select & Approve Transaction</p>
                        </div>
                        <div>
                          <img src={Approved} alt="" />
                          <p className="legend">Approved</p>
                        </div>
                        <div>
                          <img src={Selectforsettlement} alt="" />
                          <p className="legend">Select For Settlement</p>
                        </div>
                        <div>
                          <img src={Settletransaction} alt="" />
                          <p className="legend">Settle Transaction</p>
                        </div>
                        <div>
                          <img src={TransactionSuccess} alt="" />
                          <p className="legend">Transaction Success</p>
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {isVisible && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            {scrollText}
          </div>
        )}
      </main>

      <footer className="footer-section footer">
        <div className="landingcontainer">
          <div className="landingrow spad">
            <div className="coll-md-5 footer-widget">
              <p>
                Payments should be Quick, Secure and Quintessentially yours.
              </p>
              <span>
                &copy; Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                rights reserved
              </span>
              <br />
              <br />
              <img
                src={pci}
                className="mb-4 landingimg"
                alt=""
                style={{ width: "100px", marginRight: "20px", float: "left" }}
              />
              <a
                id="fC4seLa5xl"
                href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                target="_blank"
              >
                <img
                  className="landingimg"
                  src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                  alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                  style={{ width: "50px", float: "left" }}
                />
              </a>
            </div>
            <div className="coll-md-4 footer-widget">
              <h5 className="widget-title">Quick Links</h5>
              <div className="landingrow">
                <div className="coll-md-5 footer-widget">
                  <ul>
                    <li className="actived" onClick={() => navigate("/")}>
                      <a>Home</a>
                    </li>
                    <li onClick={() => navigate("/featruepage")}>
                      <a>Features</a>
                    </li>
                    <li onClick={() => navigate("/howitwork")}>
                      <a>How it works</a>
                    </li>
                    <li onClick={() => navigate("/aboutus")}>
                      <a>About</a>
                    </li>
                    <li onClick={() => navigate("/contact")}>
                      <a>Contact</a>
                    </li>
                    <li onClick={() => navigate("/faq")}>
                      <a>FAQ</a>
                    </li>
                  </ul>
                </div>
                <div className="coll-md-7 footer-widget">
                  <ul>
                    <li onClick={() => navigate("/privacy")}>
                      <a>Privacy Policy</a>
                    </li>
                    <li onClick={() => navigate("/terms")}>
                      <a>Terms of Use</a>
                    </li>
                    <li onClick={() => navigate("/cancellation")}>
                      <a>Cancellation Policy</a>
                    </li>
                    <li onClick={() => navigate("/refund")}>
                      <a>Refund Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="coll-md-3 footer-widget">
              <h5 className="widget-title">Supported By</h5>
              <div className="visacard"></div>
              <div className="mastercard"></div>
              <div className="dinerscard"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HowitworkPage;
