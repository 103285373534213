import React, { useState } from "react";

import arrowUp from "../../../../assets/Beneficiary Icons/arrowup.png";
import arrowDown from "../../../../assets/Beneficiary Icons/arrowdown.png";
import allChecker from "../../../../assets/Beneficiary Icons/whitecheckmark.png";
import "./index.css";
import { Button, Popover, Typography } from "@mui/material";

const AllStatusTypeButtonModal = ({
  data,
  setData,
  getAccStatus,
  lifecycleStatus,
  selectedStatus,
  setPage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [mainButtonStatus, setMainButtonStatus] = useState("all");
  const [icon, setIcon] = useState(arrowDown);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIcon(icon === arrowDown ? arrowUp : arrowDown);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIcon(arrowDown);
  };

  const handleStatus = (status) => {
    handleClose();

    let statusToSend;

    // if (status === "denied") {
    //   statusToSend = "rejected";
    // } else if (status === "awaiting verification") {
    //   statusToSend = "Awaiting Verification";
    // } else {
    //   statusToSend = status;
    // }
    let transactionStatus;
    if (status.split(" ").length > 1) {
      const word_array = status.split(" ");
      const capitalizedWordsArray = word_array.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
      transactionStatus = capitalizedWordsArray.join(" ");
    } else {
      transactionStatus = status[0].toUpperCase() + status.slice(1);
    }

    getAccStatus(transactionStatus);
    setPage(1);
  };

  const open = Boolean(anchorEl);
  const formatStatusText = (status) => {
    return `${status[0]?.toUpperCase()}${status?.slice(1)?.toLowerCase()}`;
  };

  return (
    <div className="transactionHistoryPopup hover:opacity-80 grid grid-cols-4 gap-4">
      <Button
        id="ownerTransaction_popover_section"
        className={`pop_overbutton hover:bg-custom-cardHover ${
          selectedStatus === "Awaiting Verification"
            ? "awaiting_verification"
            : selectedStatus === "rejected"
            ? "rejected"
            : `${selectedStatus.toLowerCase()}`
        }`}
        onClick={handleClick}
        endIcon={<img src={icon} alt="Status Icon" className="status_icon" />}
      >
        {selectedStatus === "rejected"
          ? "denied"
          : selectedStatus === "Awaiting Verification"
          ? "awaiting verification"
          : formatStatusText(selectedStatus || "All")}
      </Button>
      <Popover
        PaperProps={{
          style: { borderRadius: "20px" },
        }}
        // className="main-popover-section"
        sx={{
          marginTop: "10px",
          borderRadius: "20px !important",
          zIndex: "9999 !important",
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography className="ownerTransaction_popover_section">
          {[
            "all",
            "settled",
            "queued",

            "awaiting verification",
            "pending",
            "denied",

            "failed",
            "abandoned",
          ].map((status) => (
            <div
              className={`hover:opacity-80
                ${
                  status === "awaiting verification"
                    ? "awaiting_verification_ownerTransaction_popover_btn"
                    : `${status}_ownerTransaction_popover_btn`
                }`}
              key={status}
              onClick={() => handleStatus(status)}
            >
              {status === selectedStatus ||
              (status === "denied" && selectedStatus === "rejected") ||
              (status === "awaiting verification" &&
                selectedStatus === "Awaiting Verification") ? (
                <>
                  {formatStatusText(status)}
                  <img
                    src={allChecker}
                    alt="Checkmark"
                    className="all-ownerTransaction-status-icon"
                  />
                </>
              ) : (
                formatStatusText(status)
              )}
              {/* {status === "all" && status === selectedStatus && (
                    <img
                      src={allChecker}
                      alt="All Status"
                      className="all-status-icon"
                      style={{ marginLeft: "5px" }} // Optionally, add spacing if needed for the 'all' status icon
                    />
                  )} */}
            </div>
          ))}
        </Typography>
      </Popover>
    </div>
  );
};

export default AllStatusTypeButtonModal;
