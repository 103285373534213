import React from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import CardLogo from "../../../_utils/logo/CardLogo";
import { AccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";
import { accountDetailsBackground } from "../../../../services/approvalStatus";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AccountDetailsClientBannerComponent = (props) => {
  const navigate = useNavigate();
  const name = props.name;
  const mobile = props.mobile;
  const accountType = props.account_type;
  const approvalStatusText = AccountStatusTextMapper(accountType, props.approval_status);
  const bgColor = accountDetailsBackground(props?.approval_status)[0];
  const darkBgColor = accountDetailsBackground(props?.approval_status)[1];

  const createTxnButton = () => {
    if (approvalStatusText == "Approved") {
      return (
        <>
          <div className="self-center text-white flex flex-row gap-2">
            <div className="cursor-pointer hover:opacity-80 text-sm rounded-4xl px-3 py-2 bg-[#27B882]"
              onClick={() => window.location.href = `/create_transaction/client_transaction?client_id=${props?.account_id}`}>
              Create a transaction
            </div>
          </div >
        </>
      );
    } else {
      return (
        <></>
      );
    }
  }

  const bgClr = `bg-gradient-to-r from${bgColor} ${darkBgColor}`;

  const fromColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "from-[#0B0F0D]"
      case "Blocked":
        return "from-[#3D2525]";
      case "Archived":
        return "from-[#303030]";
      case "N":
        return "from-[#462E19]";
      default:
        return "from-[#303030]";
    }
  }
  const toColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "to-[#3F675A]"
      case "Blocked":
        return "to-[#A65757]";
      case "Archived":
        return "to-[#5A5A5A]";
      case "N":
        return "to-[#BF793C]";
      default:
        return "to-[#5A5A5A]";
    }
  }

  return (
    <div className="h-48 w-full drop-shadow-2xl rounded-4xl  flex flex-row overflow-hidden">
      <div className={`bg-gradient-to-r ${fromColorGradient()} ${toColorGradient()} w-1/2 flex flex-col justify-between p-6`}>
        <span className="w-[75%] text-white font-light text-2xl mr-6 capitalize antialiased truncate ...">
          {name || `${mobile ? '+91 ' + mobile : ''}`}
        </span>
        <span className="text-white text-base font-bold capitalize">
          {approvalStatusText}
        </span>
      </div>
      <div className="z-10 bg-white w-1/2 flex flex-col justify-center p-6">
        {createTxnButton()}
      </div>
      <div className="h-24 absolute inset-x-0 top-0 transform rotate-45 bg-white"></div>
      <div className="h-24 absolute inset-x-0 bottom-0 transform -rotate-45 bg-white"></div>
    </div >
  );
};

export default AccountDetailsClientBannerComponent;
