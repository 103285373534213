import React from "react";
import { Box } from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import formatCurrency from "../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { bankLogoMapper } from "../../services/bankLogoMapper";
import trimSentence from "../../utils/trimSentence";
import CardLogo from "../_utils/logo/CardLogo";

const RecentTransactions = (props) => {
  const navigate = useNavigate();
  const propAcc = trimSentence(props.account, 15);
  const amountProp = formatCurrency(props.amount);
  const propStatus = props.status;
  const transaction_id = props.transaction_id;
  return (
    <div className="w-full"
      onClick={() => { navigate(props.navigate_url) }}
      style={{ textDecoration: "none" }}>
      <div className="
        bg-custom-card rounded-customXl flex flex-col justify-center
        min-w-full
        py-4 4xl:py-6 5xl:py-8 6xl:py-10
        overflow-hidden
        hover:bg-custom-cardHover
        cursor-pointer drop-shadow-xl antialiased
        group">

        <div className="flex flex-row w-full gap-1">
          <div className="w-[70%] flex flex-row justify-between gap-5
            pl-4 4xl:pl-6 5xl:pl-8 6xl:pl-10  truncate ...  ">
            <div className="
            text-base 4xl:text-xl 6xl:text-2xl font-semibold flex-shrink flex-grow
            text-gray-200 capitalize truncate ...">
              {propAcc}
            </div>
            <div className="
            text-right text-base 4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-semibold flex-shrink flex-grow truncate ..."
              style={{ color: propStatus }}>
              {`₹ ${amountProp}`}
            </div>
          </div>
          <div className="
            w-[30%]
            -mr-6 -mb-4
            4xl:-mr-10 4xl:-mb-6
            5xl:-mr-12 5xl:-mb-8
            6xl:-mr-14 6xl:-mb-10
            flex flex-col justify-end">
            <CardLogo bank={props.img}
              divCssProps={
                'rounded-tl-3xl 5xl:rounded-tl-large drop-shadow-xl'
              }
            />
          </div>
        </div>
      </div >
    </div >
  );
};

export default RecentTransactions;

