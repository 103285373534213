import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import arrow_down_red from "../../../assets/TransactionDashboardIcons/arrow_down_red.png";
import cancel from "../../../assets/Settings/Dashboard/cancel.png";
import search_icon from "../../../assets/Approve transactions/search-white.png";
import AllStatusTypeButton from "../../Accounts/BeneficiaryAccount/AllStatusTypeButton";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../redux/features/Settings/Management/Users";
import { checkUsertoken } from "../../../redux/features/login/login";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import enter_ifsc from "../../../assets/Settings/Verification/enter_ifsc.png";
import notFound from "../../../assets/Settings/Dashboard/not_found.png";
import { useFetcher, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  beneVerifyThisBankNames,
  beneVerifyThisIFSC,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import { Button, Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";

const IfscSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBankFilter, setShowBankFilter] = useState(true);
  const [bankNames, setBankNames] = useState([]);
  const [filterBankName, setFilterBankName] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [selectIFSC, setSelectIFSC] = useState("");
  const [ifscDetails, setIfscDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(false);
  const [ifscLoading, setIfscLoading] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [prvBank, setPrvBank] = useState("");
  const [prvIfsc, setPrvIfsc] = useState("");
  const [bankLoading, setBankLoading] = useState(true);
  const inputRef = useRef("");
  const bankInputRef = useRef("");
  const filerCloser = useRef("");
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  const { verifyIFSCisLoading, verifyIFSC, isError } = useSelector(
    (state) => state.beneAllVerification
  );

  const {
    beneVerifyThisBankNamesLoading,
    beneVerifyThisBankNamesData,
    beneVerifyThisBankNamesError,
  } = useSelector((state) => state.beneAllVerification);

  const handleToggle = () => {
    setShowBankFilter(!showBankFilter);
  };

  const handleSelectedBank = (bankName) => {
    setSelectedBank(bankName);
    setShowBankFilter(!showBankFilter);
    setFilterBankName("");
  };

  const handleSelectIfsc = (e) => {
    const ifscValue = e.target.value.toUpperCase();
    const updateData = ifscValue.replace(/[^A-Z0-9]/g, "");
    if (updateData.length <= 11) {
      setSelectIFSC(updateData);
    }
    if (ifscValue.length == 0) {
      handleIfscClear();
    }
  };

  const handleIfscSearch = async () => {
    const ifscValidation = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (selectedBank != "" || ifscValidation.test(selectIFSC)) {
      setIfscLoading(true);
      let queryParams = {
        page_no: currentPage,
        page_size: pageSize,
        search_string:
          selectedBank != ""
            ? selectedBank
            : selectIFSC.length == 11
            ? selectIFSC
            : "",
      };
      dispatch(checkUsertoken());
      let response = await dispatch(beneVerifyThisIFSC(queryParams));

      if (
        response?.payload?.ifsc_details &&
        response?.payload?.title &&
        response?.payload?.total_count
      ) {
        let newData = response?.payload?.ifsc_details;
        setIfscDetails((prevData) => {
          return currentPage == 1 ||
            prvBank != selectedBank ||
            prvIfsc != selectIFSC
            ? newData
            : [...prevData, ...newData];
        });
        setTotalCount(response?.payload?.total_count);
        setIfscLoading(false);
        setPrvBank(selectedBank);
        setPrvIfsc(selectIFSC);
      } else if (response?.error) {
        if (response?.error?.message == "Rejected") {
          toast.error("Bank Details Not Found", toast_position);
        } else {
          toast.error(response?.error?.message, toast_position);
        }
        setTimeout(() => {
          setIfscDetails(false);
          setFilterBankName("");
          setIfscLoading("");
          setNoDataFound(true);
        }, 1000);
      }
    } else {
      if (selectedBank == "" && selectIFSC == "") {
        toast.error("Select Bank OR Enter IFSC Code", toast_position);
      } else if (!ifscValidation.test(selectIFSC)) {
        toast.error(
          "IFSC code format is incorrect OR invalid!",
          toast_position
        );
        handleIfscClear();
      }
    }
  };

  const handleIfscClear = () => {
    setIfscDetails(false);
    // setSelectedBank("");
    // setSelectIFSC("");
    setFilterBankName("");
    setIfscLoading("");
    setNoDataFound(false);
  };

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneVerifyThisBankNames());
  }, []);

  const handleIFSCDetails = (IFSC) => {
    const ifscValidation = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (ifscValidation.test(IFSC)) {
      let id = IFSC;
      navigate(`/settings/IFSC/IFSC_Details/${id}`);
    }
  };

  const handleOpenDropDown = (e) => {
    if (selectIFSC == "") {
      e.stopPropagation();
      setBankLoading(false);
      setFilterBankName("");
      setTimeout(() => {
        setBankLoading(true);
        if (selectIFSC == "") {
          handleToggle();
        }
      }, [500]);
    }
  };

  useEffect(() => {
    setBankNames(
      !beneVerifyThisBankNamesLoading
        ? beneVerifyThisBankNamesData?.bank_names
        : []
    );
  }, [beneVerifyThisBankNamesLoading, beneVerifyThisBankNamesData]);

  useEffect(() => {
    const getNextData = async () => {
      const ifscValidation = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (selectedBank != "" || ifscValidation.test(selectIFSC)) {
        let queryParams = {
          page_no: currentPage,
          page_size: pageSize,
          search_string:
            selectedBank != ""
              ? selectedBank
              : selectIFSC.length == 11
              ? selectIFSC
              : "",
        };
        dispatch(checkUsertoken());
        let response = await dispatch(beneVerifyThisIFSC(queryParams));

        if (
          response?.payload?.ifsc_details &&
          response?.payload?.title &&
          response?.payload?.total_count
        ) {
          let newData = response?.payload?.ifsc_details;
          setIfscDetails((prevData) => {
            return currentPage == 1 || ifscDetails == false
              ? newData
              : [...prevData, ...newData];
          });
          setTotalCount(response?.payload?.total_count);
        } else if (response?.error) {
          toast.error(response?.error?.message, toast_position);
          setTimeout(() => {
            setIfscDetails(false);
            setFilterBankName("");
            setIfscLoading("");
          }, 1000);
        }
      }
    };
    getNextData();
  }, [currentPage]);

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const handleOutSideClick = (e) => {
      if (filerCloser.current && !filerCloser.current.contains(e.target)) {
        setShowBankFilter(true);
      }
    };
    document.addEventListener("click", handleOutSideClick);
  }, [filerCloser]);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && (selectedBank != "" || selectIFSC != "")) {
      handleIfscSearch();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [selectIFSC, selectedBank]);

  return (
    <>
      <div
        id="beneScrollContainer "
        className={`bg-custom-appBack flex flex-col h-screen`}
        style={{ overflow: "hidden" }}
      >
        <div className="ifsc-first-div">
          <div className="ifsc-bar-container">
            {showBankFilter ? (
              <>
                <div
                  className={`ifsc-bank-dropdown hand-pointer ${
                    selectIFSC != "" ? "block-pointer" : ""
                  }`}
                  onClick={(e) => {
                    handleOpenDropDown(e);
                  }}
                >
                  <div
                    className={`ifsc-partition ifsc-partition-css ${
                      selectIFSC != "" ? "block-pointer" : ""
                    }`}
                  >
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={selectedBank}
                    >
                      <div
                        className={`ifsc-partition-one ifsc-partition-one-css ${
                          selectIFSC != "" ? "block-pointer" : ""
                        }`}
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedBank == ""
                          ? "Select bank to fetch IFSC"
                          : selectedBank}
                      </div>
                    </Tooltip>
                    <div
                      className={`ifsc-partition-two ${
                        selectIFSC != "" ? "block-pointer" : ""
                      }`}
                    >
                      {selectedBank.length === 0 ? (
                        <>
                          <img
                            className="ifsc-down-img"
                            src={bankLoading ? arrow_down_red : dataLoading}
                            alt=""
                            onClick={(e) => {
                              handleOpenDropDown(e);
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="ifsc-cancle-img"
                            alt="cancel"
                            src={cancel}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedBank("");
                              setIfscLoading("");
                              setIfscDetails(false);
                              setNoDataFound(false);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="ifsc-bank-dropdown-expand"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="ifsc-partition ifsc-partition-css hand-pointer"
                    onClick={(e) => {
                      handleToggle();
                      e.stopPropagation();
                      setFilterBankName("");
                    }}
                    ref={filerCloser}
                  >
                    <div
                      className="ifsc-partition-one ifsc-partition-one-css"
                      style={{
                        whiteSpace: " nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedBank == ""
                        ? "Select bank to fetch IFSC"
                        : selectedBank}
                    </div>
                    <div className="ifsc-partition-two hand-pointer">
                      {selectedBank.length === 0 ? (
                        <>
                          <img
                            className="ifsc-down-img ifsc-up-img"
                            src={arrow_down_red}
                            alt=""
                            onClick={() => {
                              handleToggle();
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="ifsc-cancle-img"
                            alt="cancel"
                            src={cancel}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedBank("");
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="splitline-dd"></div>
                  <div className="ifsc-partition bank-name-search-dd">
                    <div className="ifsc-partition-one label-left">
                      <input
                        ref={bankInputRef}
                        className="input-custom"
                        type="text"
                        value={filterBankName}
                        placeholder="Search"
                        onChange={(e) => setFilterBankName(e.target.value)}
                        autoFocus
                      />
                    </div>
                    <div
                      className="ifsc-partition-two"
                      onClick={() => bankInputRef.current.focus()}
                    >
                      {filterBankName.length === 0 ? (
                        <>
                          <img
                            className="ifsc-down-search"
                            src={search_icon}
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <img
                            className="ifsc-cancle-img cursor-pointer"
                            alt="cancel"
                            src={cancel}
                            onClick={(e) => {
                              e.stopPropagation();
                              setFilterBankName("");
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="bank-name-dd-options">
                    {bankNames == [] ? (
                      <></>
                    ) : (
                      <>
                        {bankNames.filter((fil) => {
                          return JSON.stringify(fil.toLowerCase()).includes(
                            filterBankName.toLowerCase()
                          );
                        }).length > 0 ? (
                          bankNames
                            .filter((fil) => {
                              return JSON.stringify(fil.toLowerCase()).includes(
                                filterBankName.toLowerCase()
                              );
                            })
                            .map((res) => {
                              return (
                                <>
                                  <Tooltip
                                    componentsProps={{
                                      popper: {
                                        sx: {
                                          [`& .${tooltipClasses.tooltip}`]: {
                                            backgroundColor: (theme) =>
                                              theme.palette.common.black,
                                          },
                                        },
                                      },
                                    }}
                                    title={res}
                                  >
                                    <div
                                      className="bank-name-search-dd bank-name-search-dd-color bank-name-search-dd-text hand-pointer"
                                      onClick={() => handleSelectedBank(res)}
                                    >
                                      {res}
                                    </div>
                                  </Tooltip>
                                </>
                              );
                            })
                        ) : (
                          <>
                            <p className=" text-white p-4 text-sm ">No bank found</p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="ifsc-bar-container-others">
            <div className="ifsc-option">OR</div>
            <div
              className={`ifsc-code ${
                selectedBank != "" ? "block-pointer" : ""
              }`}
            >
              <div
                className={`ifsc-partition ifsc-partition-css ${
                  selectedBank != "" ? "block-pointer" : ""
                }`}
              >
                <div className={`ifsc-partition-one ifsc-partition-one-css`}>
                  <input
                    className={`input-custom-ifsc ${
                      selectedBank != "" ? "block-pointer" : ""
                    }`}
                    type="text"
                    value={selectIFSC}
                    placeholder="Enter 11 digit IFSC code"
                    onChange={(e) => handleSelectIfsc(e)}
                    disabled={selectedBank != "" ? true : false}
                    ref={inputRef}
                  />
                </div>
                <div
                  className={`ifsc-partition-two   ${
                    selectedBank != "" ? "block-pointer" : ""
                  }`}
                  onClick={() => inputRef.current.focus()}
                >
                  {selectIFSC.length === 0 ? (
                    <>
                      <img
                        className="ifsc-down-search"
                        src={search_icon}
                        alt=""
                      />
                    </>
                  ) : (
                    <>
                      <img
                        className="ifsc-cancle-img cursor-pointer"
                        alt="cancel"
                        src={cancel}
                        onClick={() => {
                          setSelectIFSC("");
                          setIfscLoading("");
                          setIfscDetails(false);
                          setNoDataFound(false);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <Button
              className={`${
                selectedBank === "" && selectIFSC.length != 11
                  ? "ifsc-fetch-css-dis"
                  : "ifsc-fetch-css"
              }`}
              disabled={
                selectedBank === "" && selectIFSC.length != 11 ? true : false
              }
              onClick={() => handleIfscSearch()}
            >
              {"Fetch Details"}
            </Button>
          </div>
        </div>
        {ifscLoading == "" && !ifscDetails ? (
          <>
            <div className="flex flex-col items-center justify-center m-auto ifsc-enter-div">
              <img
                src={noDataFound ? notFound : enter_ifsc}
                alt="Enter Bank or Enter 11 digit IFSC"
                className="ifsc-no-enter-image"
              />
              {noDataFound ? (
                <>
                  <p className="ifsc-enter-label">
                    No data found. <br /> Please check the{" "}
                    {selectedBank != "" ? "Bank Details" : "IFSC code"} entered
                    above.
                  </p>
                </>
              ) : (
                <>
                  <p className="ifsc-enter-label">
                    Select bank or enter 11 digit IFSC code in the search bar,
                    to fetch the IFSC details.
                  </p>
                </>
              )}
            </div>
          </>
        ) : ifscLoading ? (
          <>
            <div className="loader-container m-auto justify-center items-center flex loader-height">
              <img src={dataLoading} alt="" />
            </div>
          </>
        ) : ifscDetails != false && !ifscLoading ? (
          <div id="infinityScrollContainerIfscView" className={"opacity-100"}>
            <div
              className={`scroll flex flex-col items-center justify-center w-full`}
            >
              <InfiniteScroll
                style={{ width: "inherit" }}
                dataLength={ifscDetails.length || []}
                next={fetchMoreData}
                hasMore={currentPage < Math.ceil(totalCount / pageSize)}
                loader={
                  <div className="loader-container m-auto justify-center items-center flex">
                    <img src={dataLoading} alt="" />
                  </div>
                }
                scrollableTarget="infinityScrollContainerIfscView"
                scrollThreshold={0.7}
              >
                <div
                  style={{
                    opacity: "1",
                  }}
                  className="ifscCard"
                >
                  {ifscDetails.map((ifscDtls) => {
                    return (
                      <div
                        className="ifsc-details-card"
                        onClick={() => handleIFSCDetails(ifscDtls.ifsc)}
                      >
                        <div className="ifsc-code-card">{ifscDtls.ifsc}</div>
                        <div className="ifsc-code-card-label">IFSC code</div>
                        <div
                          className="ifsc-code-card ext-padding"
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                  [`& .${tooltipClasses.arrow}`]: {
                                    color: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={ifscDtls.branch.toLowerCase()}
                            arrow
                            placement="bottom"
                            PopperProps={{
                              style: {
                                maxWidth: "none",
                              },
                            }}
                          >
                            {ifscDtls.branch.toLowerCase()}
                          </Tooltip>
                        </div>
                        <div className="ifsc-code-card-label">
                          Branch location
                        </div>
                        <div className="ifsc-logo-card-container">
                          <img
                            src={require("../../../assets/IfscLogo/" +
                              IfscCodeMapper(ifscDtls.ifsc))}
                            alt="bank"
                            className="ifsc-bank-image"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default IfscSettings;
