import React from 'react'

function CPSection({ children, title, subtext }) {
    return (
        <div className='w-4/6 flex justify-center items flex-col mx-auto'>
            <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-white py-10'>{title}</div>
            <div className='flex flex-row gap-10 flex-wrap'>
                {children ? children : <div className='text-white'>Not Provided</div>}
            </div>
            <div className='text-gray-300 text-lg py-5'>{subtext}</div>
        </div>
    )
}

export default CPSection