import React, { useState } from "react";
import { Box, Button, Popover } from "@mui/material";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { approveVendorTxn } from "../../../../redux/features/approveTxn/approveTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import formatdate from "../../../../utils/formatdate";
import { useNavigate } from "react-router-dom";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
import { VendorTxnDetailBannerBgMapper } from "../../../../services/VendorTxnDetailBgMapper";
import info from "../../../../assets/TxnDetails/info-black-border.png";
import PopOverContainer from "../../../PopOverContainer";
import { approvalStatus } from "../../../../services/approvalStatus";
import trimSentence from "../../../../utils/trimSentence";

const TxnDetailsBanner = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleImageClick = (event) => {
    event.stopPropagation();
    handleClick(event);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Box className="bg-white h-full flex justify-between rounded-4xl shadow-customCard ">
        <Box
          sx={{
            background: `transparent linear-gradient(90deg, ${VendorTxnDetailBannerBgMapper(props.approval_status)[1]} 0%, ${VendorTxnDetailBannerBgMapper(props.approval_status)[0]} 100%) 0% 0% no-repeat padding-box`
          }}
          className={`w-[45%] rounded-l-4xl flex flex-col justify-between shadow-customCard p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16`}
        >
          <Box sx={{ textAlign: "left" }} className="txnDetailsBannerLeftTop whitespace-nowrap w-4/5">
            {trimSentence(props.vendor_name, 15)}
          </Box>
          <Box sx={{ textAlign: "left", color: approvalStatus(props.approval_status) }} className="txnDetailsBannerRightTop flex items-center gap-4">
            {VendorTxnTextMapper(props.approval_status)}
            {props.utr_no ?
              <div className="text-white text-sm flex">
                &nbsp;
                <div className="w-0.5 h-5 bg-gray-300">
                </div>
                <span className="text-custom-offwhite">  &nbsp; UTR No &nbsp; : &nbsp; </span >
                <span className="text-white font-thin">{props.utr_no}</span>
              </div> :
              null}
          </Box>
          <Box className="txnDetailsBannerLeftBottom">
            <p>
              {props.posting_date == undefined
                ? {}
                : formatdate(props.posting_date)}
            </p>
          </Box>
        </Box>
        <Box
          className="text-right flex justify-end w-[65%] p-6 3xl:p-8 4xl:p-10 5xl:p-12 6xl:p-16"
        >
          <Box className="txnDetailsBannerRightMiddle h-full flex flex-col justify-between text-right">
            <div className="txnDetailsBannerRightMiddle" >
              <Box className="flex gap-4 self-start justify-end" >
                ₹ {formatCurrency(props.amount)}
                <div className="cursor-pointer" onClick={handleImageClick}>
                  <img
                    src={info}
                    alt="info"
                    className="w-3 h-3"
                  />
                </div>
                <Popover
                  sx={{
                    "& .MuiPopover-paper": {
                      "border-radius": "40px",
                    },
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <PopOverContainer data={props.data} approval_status={props.approval_status} />
                </Popover>
              </Box>
              <hr />
              {props.destination_account_txn_limit && <span >
                {trimSentence(props.vendor_name, 15) + "'s remaining limit"} &nbsp; <p> {formatCurrency(props.destination_account_txn_limit)}</p>
              </span>}
            </div>
            <Box className="txnDetailsBannerRightBottom justify-end w-full">
              <Box className="approveTxnDetailsBTs">
                {props.approval_status === "P" &&
                  services?.includes("FATransaction") ? (
                  <>
                    <Button
                      variant="contained"
                      className="declineBt"
                      onClick={() => {
                        props.setDeniedReason(true);
                      }}
                    >
                      <div className="p-2 text-sm 3xl:text-base 5xl:text-lg">Decline</div>
                    </Button>
                    <Button
                      onClick={() => {
                        dispatch(
                          approveVendorTxn({
                            txn_id: [props.txn_id],
                            status: "Y",
                          })
                        );
                        setTimeout(() => {
                          navigate("/vendor/approval_status");
                        }, 500);
                      }}
                      variant="contained"
                      className="approveBt"
                    >
                      <div className="p-2 text-sm 3xl:text-base 5xl:text-lg">Approve</div>
                    </Button>{" "}
                  </>
                ) : props.approval_status === "Y" &&
                  services?.includes("FSTransaction") ? (
                  <Button
                    onClick={() => {
                      props.setSelectedTxnCarousel({ amt: props.amount, selected: [props.txn_id] })
                      props.setShowSettlementComponent(true)
                    }}
                    variant="contained"
                    className="approveBt"
                  >
                    <div className="p-2 text-sm 3xl:text-base 5xl:text-lg">Pay Now</div>
                  </Button>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="txnDetailsBannerMobile">
        <Box>
          <Box sx={{ color: "#707070" }} className="txnDetailsBannerLeftBottom">
            <p>
              {props.posting_date == undefined
                ? {}
                : formatdate(props.posting_date)}
            </p>{" "}
          </Box>
          <Box className="txnDetailsBannerRightMiddle">
            {formatCurrency(props.amount)}
          </Box>
          <Box sx={{ color: "black" }} className="txnDetailsBannerLeftTop">
            {props.vendor_name}
          </Box>
          <Box className="txnDetailsBannerRightTop">
            {VendorTxnTextMapper(props.approval_status)}
          </Box>
          <Box className="txnDetailsBannerRightBottom">
            <Box className="approveDetailsBTs">
              {props.approval_status === "P" &&
                services?.includes("ASinglePay") ? (
                <>
                  <Button
                    variant="contained"
                    className="declineBt"
                    onClick={() => {
                      dispatch(
                        approveVendorTxn({
                          txn_id: [props.txn_id],
                          status: "N",
                        })
                      );
                      setTimeout(() => {
                        navigate("/transactionStatus");
                      }, 500);
                    }}
                  >
                    Decline
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        approveVendorTxn({
                          txn_id: [props.txn_id],
                          status: "Y",
                        })
                      );
                      setTimeout(() => {
                        navigate(0);
                      }, 500);
                    }}
                    variant="contained"
                    className="approveBt"
                  >
                    Approve
                  </Button>{" "}
                </>
              ) : props.approval_status === "Y" &&
                services?.includes("VSinglePay") ? (
                <Button
                  onClick={() => {
                    navigate(`/vendor/settle/${props.txn_id}`);
                  }}
                  variant="contained"
                  className="approveBt"
                >
                  Pay Now
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box className="txnDetailsBannerMobileBottom">
          <Box
            sx={{ background: "#DEF5ED 0% 0% no-repeat padding-box" }}
            className="txnDetailsBannerRightMiddle"
          >
            <span>
              <p>₹ 4,653.00</p> <br />{" "}
              {props.vendor_name + "'s remaining limit"}
            </span>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TxnDetailsBanner;
