import React, { useState } from "react";
import { Input, InputAdornment, Box, TextField } from "@mui/material";
import "./index.css";
import formatCurrency from "../../../../utils/CurrencyFormater";
import NextDate from "../../../NextDate";
import { toast } from "react-toastify";
import Validation from "../../../../utils/validation";
import SelectDebitAccount from "../../CreateInternaltxn/CreateInternalTxnForm/SelectDebitAccount";
import { useSelector } from "react-redux";
import CardLogo from "../../../_utils/logo/CardLogo";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import accTransform from "../../../../utils/accTransform";

const EnterDetailsClientTxn = (props) => {
  const allowedValue = (value) => {
    return Validation.allowedAmount(value);
  };

  const [open, setOpen] = useState(false);
  const clientPrimaryAcc = useSelector(
    (state) => state.bufferState.clientPrimaryAcc
  );

  const handleOpen = (type) => {
    setOpen(type);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "amount") {
      if (allowedValue(value) || value.length === 0) {
        const newObj = { ...props.createTxnData, [name]: value };
        props.setCreateTxnData(newObj);
      } else {
        e.target.value = props.createTxnData.amount;
      }
      let floatAmount = value?.length > 0 ? parseFloat(value).toFixed(2) : 0.0;
      if (floatAmount >= 1.0 && floatAmount <= 999999999.99) {
      } else {
        toast.error("Amount must be >= 10.00 AND <= 999999999.99", {
          position: "bottom-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } else {
      const newObj = { ...props.createTxnData, [name]: value };
      props.setCreateTxnData(newObj);
    }
  };

  return (
    <>
      <div
        className="h-fit bg-custom-card drop-shadow-2xl
      rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl
      overflow-hidden"
      >
        <div className="bg-[#29487E] rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl drop-shadow-2xl h-fit">
          <div
            className="flex flex-col bg-custom-green justify-center rounded-b-large
        p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12 drop-shadow-xl"
          >
            <div className="flex flex-row justify-center text-white text-lg 3xl:text-2xl 4xl:text-3xl 5xl:text-4xl">
              {props.filled ? (
                <div
                  className="flex flex-row justify-center text-white
            p-4 xl:p-8 4xl:p-10 5xl:p-12 6xl:p-14
            text-4xl 2xl:text-5xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl"
                >
                  ₹ {formatCurrency(props.createTxnData.amount)}
                </div>
              ) : (
                <Input
                  name="amount"
                  disableunderline
                  autoFocus
                  placeholder="Enter amount"
                  className="enterAmountInput p-4 xl:p-8 4xl:p-10 5xl:p-12 6xl:p-14"
                  id="standard-adornment-amount"
                  autoComplete="off"
                  readOnly={props.disabledToggle}
                  onChange={handleChange}
                  value={props.createTxnData.amount}
                  startAdornment={
                    <InputAdornment position="start">
                      <p
                        style={{
                          color: "white",
                          paddingRight: "10px",
                          textDecoration: "none",
                          underline: "none",
                        }}
                      >
                        ₹
                      </p>
                    </InputAdornment>
                  }
                />
              )}
            </div>
          </div>
          <Box
            className="
        relative
        px-4 md:px-6 lg:px-8 xl:px-10 2xl:px-12 3xl:px-14 4xl:px-16 5xl:px-18 flex flex-col gap-4
        py-2 lg:py-4 xl:py-6 2xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 "
          >
            <div
              className="antaliased text-white text-base 3xl:text-lg
          4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-thin"
            >
              Beneficiary account
            </div>
            <div>
              {props.filled === "true" ? (
                <>
                  <div className="py-2 text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                    <div
                      className="flex capitalize
          antaliased text-custom-orange font-semibold
          text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl truncate ..."
                    >
                      <div className="flex gap-5 self-start items-center">
                        <div>
                          {clientPrimaryAcc
                            ? accTransform(clientPrimaryAcc.bank_acc_no)
                            : "Primary account"}
                        </div>
                      </div>
                    </div>
                    {clientPrimaryAcc && (
                      <div
                        className="
                  right-0
                  bottom-0
                  absolute
                  flex flex-row w-full justify-end"
                      >
                        <CardLogo
                          bank={bankLogoMapper(clientPrimaryAcc?.bank_name)}
                          divCssProps={
                            "rounded-tl-4xl 3xl:rounded-tl-large 5xl:rounded-tl-customXl rounded-br-4xl 3xl:rounded-br-large 5xl:rounded-br-customXl w-1/3"
                          }
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div
                  onClick={() => handleOpen("Owner")}
                  className="cursor-pointer py-2 text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased"
                >
                  <div
                    className="flex capitalize
          antaliased text-custom-orange font-semibold
          text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl truncate ..."
                  >
                    <div className="flex gap-5 self-start items-center">
                      <div>
                        {clientPrimaryAcc
                          ? accTransform(clientPrimaryAcc.bank_acc_no)
                          : "Primary account"}
                      </div>
                      <div className="text-custom-orange">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-10 5xl:h-10"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  {clientPrimaryAcc && (
                    <div
                      className="
                  right-0
                  bottom-0
                  absolute
                  flex flex-row w-full justify-end"
                    >
                      <CardLogo
                        bank={bankLogoMapper(clientPrimaryAcc?.bank_name)}
                        divCssProps={
                          "rounded-tl-4xl 3xl:rounded-tl-large 5xl:rounded-tl-customXl rounded-br-4xl 3xl:rounded-br-large 5xl:rounded-br-customXl w-1/3"
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Box>
        </div>
        <Box
          className="
        px-4 md:px-6 lg:px-8 xl:px-10 2xl:px-12 3xl:px-14 4xl:px-16 5xl:px-18 flex flex-col gap-4
        py-2 lg:py-4 xl:py-6 2xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 "
        >
          <div
            className="antaliased text-white text-base 3xl:text-lg
          4xl:text-xl 5xl:text-2xl 6xl:text-3xl font-thin"
          >
            Additional details
          </div>
          <div>
            {props.filled === "true" ? (
              <>
                <div>
                  <div className="text-white text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased truncate ...">
                    {props.createTxnData.invoice_no === null
                      ? "Not given"
                      : props.createTxnData.invoice_no}
                  </div>
                  <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                    Invoice number
                  </div>
                </div>
                <br />
                <hr style={{ color: "white" }} />
                <br />
                <div sx={{ color: "white" }}>
                  <div
                    className="text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
           text-white antialiased truncate ..."
                  >
                    {props.createTxnData.distribution_remark === null
                      ? "Not given"
                      : props.createTxnData.distribution_remark}
                  </div>
                  <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                    Distribution remark
                  </div>
                </div>
              </>
            ) : (
              <div className="py-2 text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                <TextField
                  onKeyDown={(e) => {
                    const specialCharRegex =
                      /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
                    if (specialCharRegex.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  autoComplete="off"
                  inputProps={{
                    style: {
                      color: "white",
                      borderBottom: "1px solid grey",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#DADADA",
                      font: "normal normal normal 14px/19px Open Sans",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  label="Invoice number (optional)"
                  variant="standard"
                  className="enterAddDetails"
                  name="invoice_no"
                  onChange={handleChange}
                  value={props.createTxnData.invoice_no}
                />{" "}
                <br />
                <TextField
                  autoComplete="off"
                  sx={{
                    marginTop: "20px",
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                      borderBottom: "1px solid grey",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    style: {
                      color: "#DADADA",
                      font: "normal normal normal 14px/19px Open Sans",
                    },
                  }}
                  label="Distribution remark (optional)"
                  variant="standard"
                  className="enterAddDetails"
                  name="distribution_remark"
                  onChange={handleChange}
                  value={props.createTxnData.distribution_remark}
                />
              </div>
            )}
          </div>
        </Box>
      </div>
      {open ? (
        <div className="">
          <SelectDebitAccount componentType={open} handleClose={handleClose} />
        </div>
      ) : null}
    </>
  );
};

export default EnterDetailsClientTxn;
