import React, { useEffect, useState } from "react";
import "./index.css";
import ExistingVendorAcc from "./AccountCard";
import { getOwnerAccountsData } from "../../../../../redux/features/accounts/accounts";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import SearchBar from "../../../../_utils/search/SearchBar";
import NoDataImg from "../../../../../assets/TransactionDashboardIcons/Nothing.png";

export default function SelectDebitAccount(props) {
  const dispatch = useDispatch();
  const handleClose = () => {
    props.handleClose();
  };

  const DebitAcc = useSelector((state) => state.bufferState.internalDebit);
  const CreditAcc = useSelector((state) => state.bufferState.internalCredit);
  const [searchString, setSearchString] = useState();
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  // const loading = useSelector((state) => state.accounts.isLoading);
  const [page, setPage] = useState(0);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const toggleShowSearchBar = () => setShowSearchBar(!showSearchBar);
  const [dataPending, setDataPending] = useState(false);

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    return appendedData;
  };

  const getAndUpdateData = () => {
    if (dataPending) return;
    setDataPending(true);
    let params = null;
    if (props?.componentType === "Debit")
      params = {
        page_no: page,
        search_string: searchString,
        active_status: "Y",
        hdfc_umrn: "\u003cnot_null\u003e",
      };
    else params = { page_no: page, search_string: searchString };
    getOwnerAccountsData(params, (res) => {
      if (res?.accounts?.length > 0) {
        setData(dataAppend(data, res?.accounts));
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setDataPending(false);
    });
  };

  const handleScroll = debounce(() => {
    const container = document.getElementById("infinityScrollContainer");
    if (
      container.scrollHeight - container.scrollTop - container.clientHeight <=
      1
    ) {
      if (hasMore) setPage(page + 1);
    }
  }, 100);

  const handleSearch = (search_string) => {
    if (search_string !== searchString) {
      setData([]);
      setPage(0);
      setSearchString(search_string);
    }
  };

  useEffect(() => {
    getAndUpdateData();
  }, [page]);

  useEffect(() => {
    getAndUpdateData();
  }, [searchString]);

  useEffect(() => {
    let container = document.getElementById("infinityScrollContainer");
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <div>
      <div className="backdrop:bg-gray-50 grid grid-cols-1 place-items-center w-full h-full fixed top-0 left-0 z-[9998]">
        <div className="bg-custom-backdrop opacity-80 w-full h-full fixed top-0 left-0 z-[9999]"></div>
        <div
          className=" bg-custom-deniedReasonPurple h-3/5 w-1/2
          overflow-hidden  drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl absolute z-[9999]"
        >
          <div
            className="h-[15%] flex flex-row
           px-6 2xl:px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:p-18
           text-custom-offwhite fixed bg-custom-deniedReasonPurple w-full left-0 top-0  z-10"
          >
            <div
              className="w-1/2 text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl"
            >
              Select {props?.componentType} account:
            </div>
            <div className="w-1/2 self-center flex flex-row justify-end gap-4">
              <div className="w-4/5 flex justify-end">
                <SearchBar
                  handleSearch={handleSearch}
                  showSearchBar={showSearchBar}
                  toggleShowSearchBar={toggleShowSearchBar}
                  bgColor={"bg-custom-deniedReasonPurple"}
                  bgHover={"hover:bg-custom-lightGray"}
                  searchLogoDivColor={"none"}
                />
              </div>
              <div className="self-center cursor-pointer" onClick={handleClose}>
                <svg
                  className="p-1 w-8 h-8 3xl:w-9 3xl:h-9 4xl:w-10 4xl:h-10 6xl:w-12 6xl:h-12"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="h-[80%] fixed left-0 top-[15%] right-0 bottom-[5%] overflow-hidden z-9">
            <div
              id="infinityScrollContainer"
              className="w-full h-full overflow-y-auto vendor-txn "
            >
              <div
                className="px-6 2xl:px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:p-18 flex flex-col
                gap-6 4xl:gap-8 5xl:gap-10 6xl:gap-12"
              >
                {data?.length > 0 ? (
                  Object.entries(data).map(([key, value]) => {
                    if (
                      (DebitAcc?.owner_id === value.owner_id &&
                        props.componentType === "Credit") ||
                      (CreditAcc?.owner_id === value.owner_id &&
                        props.componentType === "Debit")
                    )
                      return;
                    else
                      return (
                        <ExistingVendorAcc
                          key={key}
                          componentType={props.componentType}
                          value={value}
                          handleClose={handleClose}
                          enableNextButton={props.enableNextButton}
                          selectedAcc={props.selectedAcc}
                        />
                      );
                  })
                ) : (
                  <> </>
                )}
              </div>
              {!hasMore && data?.length === 0 && (
                <div
                  className="w-full h-full
                        flex flex-col justify-center items-center gap-5 text-center overflow-hidden"
                >
                  <div className="w-[40%] p-4 flex flex-row justify-center">
                    <img
                      className="h-full w-full"
                      src={NoDataImg}
                      alt="NoDataImg"
                    />
                  </div>
                  <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                    No accounts found.
                  </div>
                </div>
              )}
              {dataPending && (
                <div className="w-full h-full m-auto justify-center items-center flex">
                  <img
                    src={require("../../../../../assets/Approve transactions/inputloder.gif")}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
