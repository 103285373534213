import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectAcc from "../../../assets/CreateTransactionIcons/Select account.png";
import loadingLogo from "../../../assets/Approve transactions/inputloder.gif";

import {
  verifyThisPAN,
  verifyThisBank,
  resetBankVerification,
} from "../../../redux/features/verifyPAN/verifyPAN";
import verifyLogo from "../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";

import "./index.css";
import trimSentence from "../../../utils/trimSentence";
import Validation from "../../../utils/validation";
import { toast } from "react-toastify";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import { all } from "axios";

const RegisterNewVedor = (props) => {
  // validations
  const panStatus = useSelector((state) => state.verify.verifyPAN);
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const bankStatusLoading = useSelector(
    (state) => state.verify.verifyBankisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [gstinValidation, setGstinValidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const dispatch = useDispatch();
  const disabledToggle = Boolean(props.disabledToggle);

  const modifyValue = (type, value) => {
    if (
      type === "pan_no" ||
      type === "bank_acc_no" ||
      type === "ifsc_code" ||
      type === "gstin"
    ) {
      return value?.toUpperCase();
    }
    if (type === "email_id") {
      return value?.toLowerCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "pan_no") return Validation.allowedPAN(value);
    if (name === "bank_acc_no") return Validation.allowedBankAccNum(value);
    if (name === "ifsc_code") return Validation.allowedBankIfsc(value);
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    if (name === "gstin") return Validation.allowedGstin(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "pan_no") errors = Validation.validatePan(name, value, errors);
    if (name === "ifsc_code")
      errors = Validation.validateIfsc(name, value, errors);
    if (name === "bank_acc_no")
      errors = Validation.validateBankAcc(name, value, errors);
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewVnerdorData, [name]: value };
      props.setRegisterNewVnerdorData(newObj);
    }
    if (
      props.registerNewVnerdorData.pan_no.length === 0 ||
      props.registerNewVnerdorData.pan_no.length < 10
    ) {
      setPanVerificationStatus(false);
    }
    if (props.registerNewVnerdorData.bank_acc_no.length === 0) {
      setBankVerificationStatus(false);
    }

    if (
      props.registerNewVnerdorData.ifsc_code.length === 0 ||
      props.registerNewVnerdorData.ifsc_code.length < 11
    ) {
      setBankVerificationStatus(false);
    }
    if (
      props.registerNewVnerdorData.contact_no.length === 0 ||
      props.registerNewVnerdorData.contact_no.length < 10
    ) {
      setContactNumberValidation(false);
    }
    if (props.registerNewVnerdorData.email_id.length === 0) {
      setEmailValidation(false);
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (!errorMessage && name === "ifsc_code" && bankValuesFilled())
      errorMessage = customErrors["bank_acc_no"];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (bankStatus?.errors) {
      let errors = { ...customErrors };
      errors["bank_acc_no"] = bankStatus.errors;
      setCustomErrors(errors);
      setBankVerificationStatus(false);
    }

    if (bankStatus?.status === true) {
      let errors = { ...customErrors };
      delete errors["bank_acc_no"];
      setCustomErrors(errors);
      setBankVerificationStatus(true);
    }
  }, [bankStatus]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no.length > 0 &&
      panStatus?.status === true
    ) {
      let errors = { ...customErrors };
      delete errors["pan_no"];
      setCustomErrors(errors);
      setPanVerificationStatus(true);
    }

    if (props.registerNewVnerdorData.pan_no.length > 0 && panStatus?.errors) {
      let errors = { ...customErrors };
      errors["pan_no"] = panStatus.errors;
      setCustomErrors(errors);
      setPanVerificationStatus(false);
    }
  }, [panStatus]);

  useEffect(() => {
    if (customErrors["pan_no"]) setPanVerificationStatus(false);
    if (customErrors["bank_acc_no"]) setBankVerificationStatus(false);
    if (customErrors["ifsc_code"]) setBankVerificationStatus(false);
    if (customErrors["contact_no"]) setContactNumberValidation(false);
    if (
      !customErrors["contact_no"] &&
      props.registerNewVnerdorData.contact_no.length === 10
    )
      setContactNumberValidation(true);
    if (customErrors["email_id"]) setEmailValidation(false);
    if (
      !customErrors["email_id"] &&
      props.registerNewVnerdorData.email_id.length > 0 &&
      emailRegex.test(props.registerNewVnerdorData.email_id)
    )
      setEmailValidation(true);
  }, [customErrors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bankValuesFilled = () => {
    return (
      props.registerNewVnerdorData.bank_acc_no.length >= 4 &&
      props.registerNewVnerdorData.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.registerNewVnerdorData.ifsc_code)
    );
  };

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no?.length === 10 &&
      !customErrors["pan_no"]
    ) {
      dispatch(
        verifyThisPAN({ pan_number: props.registerNewVnerdorData.pan_no })
      );
    }
  }, [props.registerNewVnerdorData.pan_no]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.bank_acc_no.length >= 4 &&
      props.registerNewVnerdorData.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.registerNewVnerdorData.ifsc_code)
    ) {
      dispatch(
        verifyThisBank({
          ifsc_code: props.registerNewVnerdorData.ifsc_code,
          bank_acc_no: props.registerNewVnerdorData.bank_acc_no,
        })
      );
    }
  }, [
    props.registerNewVnerdorData.ifsc_code,
    props.registerNewVnerdorData.bank_acc_no,
  ]);

  useEffect(() => {
    if (!disabledToggle) {
      if (props.registerNewVnerdorData.pan_no.length !== 10) {
        const newObj = {
          ...props.registerNewVnerdorData,
          name: "",
        };
        props.setRegisterNewVnerdorData(newObj);
      }
    }
  }, [props.registerNewVnerdorData.name]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no.length === 0 ||
      props.registerNewVnerdorData.bank_acc_no.length === 0 ||
      props.registerNewVnerdorData.ifsc_code.length === 0 ||
      props.registerNewVnerdorData.contact_no.length === 0 ||
      props.registerNewVnerdorData.email_id.length === 0
    ) {
      props.enableNextButton(false);
      return;
    }
    if (
      panVerificationStatus &&
      contactNumberValidation &&
      emailValidation &&
      (bankVerificationStatus || bankValuesFilled())
    ) {
      const newObj = {
        ...props.registerNewVnerdorData,
        name: panStatus?.pan_details.full_name,
        skip_account_verification:
          bankStatus?.verification_details &&
          bankStatus?.verification_details[0].account_exists === "Y"
            ? true
            : false,
      };
      props.setRegisterNewVnerdorData(newObj);
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [
    panVerificationStatus,
    bankVerificationStatus,
    contactNumberValidation,
    emailValidation,
    props.registerNewVnerdorData.pan_no,
    props.registerNewVnerdorData.ifsc_code,
    props.registerNewVnerdorData.bank_acc_no,
    props.registerNewVnerdorData.contact_no,
    props.registerNewVnerdorData.email_id,
  ]);

  // this needs to be checked

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    return (
      <div className="w-full flex flex-row self-center">
        <TextField
          className="w-full antialiased text-4xl"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: { maxLength },
            readOnly: props.activeStep === 2,
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {!skip &&
                  value.length > 0 &&
                  (status ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : (
                    <img
                      src={skippedVerification}
                      alt="verified logo"
                      onClick={() => {
                        showErrors(name);
                      }}
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ))}
              </InputAdornment>
            ),
          }}
          label={label}
          variant="standard"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getPanVerifiedDiv = (
    name,
    status,
    isLoading,
    label,
    value,
    maxLength
  ) => {
    return (
      <div
        className={`
        ${value.length === 10 && !isLoading && status && "-mx-1"}
        min-w-full flex flex-row`}
      >
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: { maxLength },
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
                {value.length === 10 &&
                  !isLoading &&
                  (status ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : (
                    <img
                      src={skippedVerification}
                      alt="verified logo"
                      onClick={() => {
                        showErrors(name);
                      }}
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ))}
              </InputAdornment>
            ),
          }}
          label={label}
          variant="standard"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getBankVerifiedElementDiv = (isLoading, bankDetails, ifscDetails) => {
    return (
      <div
        className={`min-w-full flex flex-col self-center gap-2
        ${bankValuesFilled() && "-mx-1"}`}
      >
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: bankDetails.maxLength,
            readOnly: props.activeStep === 2,
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: props.activeStep === 2,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
                {!isLoading &&
                bankVerificationStatus &&
                bankDetails.value?.length >= 4 ? (
                  <img
                    src={verifyLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
                {!isLoading &&
                bankDetails.value?.length > 0 &&
                customErrors["bank_acc_no"] ? (
                  <img
                    src={skippedVerification}
                    onClick={() => {
                      showErrors(bankDetails.name);
                    }}
                    alt="verified logo"
                    className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
              </InputAdornment>
            ),
          }}
          label={bankDetails.label}
          variant="standard"
          name={bankDetails.name}
          onChange={handleChange}
          value={bankDetails.value}
          readOnly={disabledToggle}
          autoComplete="off"
        />
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: ifscDetails.maxLength,
            readOnly: props.activeStep === 2,
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: props.activeStep === 2,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}

                {!isLoading &&
                bankVerificationStatus &&
                ifscDetails.value?.length === 11 ? (
                  <img
                    src={verifyLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
                {!isLoading &&
                ((ifscDetails.value?.length <= 11 &&
                  customErrors["ifsc_code"] &&
                  ifscDetails.value?.length > 0) ||
                  (bankValuesFilled() && customErrors["bank_acc_no"])) ? (
                  <img
                    src={skippedVerification}
                    onClick={() => {
                      showErrors(ifscDetails.name);
                    }}
                    alt="verified logo"
                    className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
              </InputAdornment>
            ),
          }}
          label={ifscDetails.label}
          variant="standard"
          name={ifscDetails.name}
          onChange={handleChange}
          value={ifscDetails.value}
          readOnly={disabledToggle}
          autoComplete="off"
        />
      </div>
    );
  };

  const panVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        {panVerificationStatus &&
          props.registerNewVnerdorData?.pan_no?.length > 0 && (
            <div
              className="bg-custom-lightGreen rounded-l-large 4xl:rounded-l-customXl 5xl:rounded-l-custom2Xl
            flex w-full p-2 flex-row justify-between"
              style={{
                marginLeft: "-80%",
              }}
            >
              <div className="w-[70%] flex flex-row gap-2">
                <div className="flex flex-row h-full w-[10%]">
                  <img
                    className="self-center p-1"
                    src={verifyLogo}
                    alt="verified logo"
                  />
                </div>
                <div className="flex flex-row h-full w-[90%]">
                  <div
                    className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                  >
                    {trimSentence(
                      props.registerNewVnerdorData.name
                        ? props.registerNewVnerdorData.name.toLowerCase()
                        : panStatus?.pan_details.full_name.toLowerCase(),
                      25
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[30%] flex flex-row justify-end">
                <div
                  className="self-center
                antaliased text-white text-xs "
                >
                  Legal name
                </div>
              </div>
            </div>
          )}
        <div
          className={`min-w-full
          ${
            panVerificationStatus &&
            props.registerNewVnerdorData?.pan_no?.length > 0 &&
            "border-4 border-custom-lightGreen"
          }
          flex justify-center py-1
          rounded-r-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {
              // Verified Vendor Pan Number Div
              getPanVerifiedDiv(
                "pan_no",
                panVerificationStatus,
                panStatusLoading,
                "Vendor's PAN number",
                props.registerNewVnerdorData.pan_no,
                10
              )
            }
          </div>
        </div>
      </div>
    );
  };

  const bankVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        {bankValuesFilled() &&
        bankVerificationStatus &&
        !customErrors["bank_acc_no"] &&
        bankStatus?.verification_details?.length > 0 ? (
          <div
            className="bg-custom-lightGreen rounded-l-4xl 4xl:rounded-l-large 5xl:rounded-l-customXl
            flex w-full p-2 flex-col justify-between overflow-hidden"
            style={{
              marginLeft: "-80%",
            }}
          >
            <div className="flex flex-row">
              <div className="w-[60%] flex flex-row gap-2">
                <div className="flex flex-row h-full w-[11.66%]">
                  <img
                    className="self-center p-1"
                    src={verifyLogo}
                    alt="verified logo"
                  />
                </div>
                <div className="flex flex-row h-full w-[90%]">
                  <div
                    className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                  >
                    {bankStatus?.verification_details[0].legal_name.toLowerCase()}
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-end w-[40%]">
                <div
                  className="self-center
                antaliased text-white text-xs "
                >
                  Account holders name
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div
                className="w-1/2 -m-2
                flex flex-col justify-end"
              >
                <div
                  className="w-2/3
                  drop-shadow-2xl
                  text-center p-2 flex flex-row justify-center
                  bg-white rounded-tr-large"
                >
                  <img
                    className="w-2/3"
                    src={require("../../../assets/Banks/" +
                      bankLogoMapper(props.registerNewVnerdorData.bank_name) +
                      ".png")}
                    alt="BankLogo"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {bankValuesFilled() && !bankVerificationStatus && bankStatus?.errors ? (
          <div
            className="
            w-full 3xl:-ml-96"
            style={{
              marginLeft: "-80%",
            }}
          >
            <div className="flex flex-col h-full">
              <div className="h-full bg-custom-red flex flex-row overflow-hidden rounded-l-4xl 4xl:rounded-l-large 5xl:rounded-l-customXl">
                <div className="p-2 flex flex-row gap-2">
                  <div className="flex flex-col h-full w-[7%]">
                    <img
                      className="p-1"
                      src={skippedVerification}
                      alt="verified logo"
                    />
                  </div>

                  <div
                    className="h-full flex flex-col justify-between p-1
                     text-white text-base"
                  >
                    <div className="text-md font-extrabold">
                      SKIPPING VERIFICATION
                    </div>
                    <div className="flex font-normal">
                      •{" "}
                      <p className="ml-4 font-bold">
                        Account could not be verified
                      </p>
                    </div>
                    <div className="flex font-normal">
                      •{" "}
                      <p className="ml-4 font-bold">
                        Crosscheck details and proceed with caution
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="-mb-48 flex flex-row justify-center w-full text-white px-2">
              <div className="bg-custom-dark-orange w-[85%] rounded-b-large p-4">
                <div className="capitalize px-2 text-base italic font-semibold">
                  BANK REMARK:
                </div>
                <div className="text-xs px-2 3xl:text-sm">
                  IMPS maybe currently disabled for this account or this account
                  may not exist.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className={`min-w-full
          ${
            bankValuesFilled() &&
            bankVerificationStatus &&
            "border-4 border-custom-lightGreen"
          }
          ${
            bankValuesFilled() &&
            !bankVerificationStatus &&
            bankStatus?.errors &&
            "border-4 border-custom-red"
          }
          flex justify-center py-1
          rounded-r-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {getBankVerifiedElementDiv(
              bankStatusLoading,
              {
                name: "bank_acc_no",
                status: bankVerificationStatus,
                label: "Bank account number",
                value: props.registerNewVnerdorData.bank_acc_no,
                maxLength: 18,
              },
              {
                name: "ifsc_code",
                status: bankVerificationStatus,
                label: "Bank IFSC code",
                value: props.registerNewVnerdorData.ifsc_code,
                maxLength: 11,
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="p-2 h-full bg-custom-blue flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-4 2xl:gap-6 4xl:gap-8"
    >
      <div>
        <div
          className="-m-2 bg-custom-card
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18"
        >
          <div
            className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
          >
            Vendor Details
          </div>
          <div className="flex flex-row gap-2">
            <div className="self-center">
              <img
                src={selectAcc}
                className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
              />
            </div>
            <div
              className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
              onClick={() => {
                props.handleOpen();
              }}
            >
              Select an existing vendor
            </div>
          </div>
        </div>
        <div
          className="-m-2
          h-full bg-custom-lightBlue rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col
          drop-shadow-xl
          gap-4 2xl:gap-6 4xl:gap-8"
        >
          <div
            className="bg-custom-card drop-shadow-xl h-full
          rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl
          flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4"
          >
            <div className="w-full">{panVerificationDiv()}</div>
          </div>
          <div
            className="w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-2"
          >
            {bankVerificationDiv()}
          </div>
        </div>
      </div>

      <div className="-mx-2 mt-2">
        <div
          className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-6"
        >
          {panVerificationStatus && panStatus?.pan_details?.full_name ? (
            <div className="w-full flex flex-col">
              <div className="text-white capitalize">
                {panStatus?.pan_details.full_name}
              </div>
              <div className="text-[#C9C9C9] text-xs antialiased">
                Vendor name - Using legal name
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="flex flex-col gap-2">
            {getInputElementDiv(
              "contact_no",
              contactNumberValidation,
              "Contact number",
              props.registerNewVnerdorData.contact_no,
              10
            )}
            {getInputElementDiv(
              "email_id",
              emailValidation,
              "Email-ID",
              props.registerNewVnerdorData.email_id,
              20
            )}
            {getInputElementDiv(
              "gstin",
              gstinValidation,
              "GSTIN (optional)",
              props.registerNewVnerdorData.gstin,
              20,
              true
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterNewVedor;
