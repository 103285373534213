import AccountVendorDetailBannerComponent from "../../../../components/Accounts/Vendor/AccountDetailBannerComponent";
import AccountDetailCard from "../../../../components/Accounts/Common/AccountDetailCard";
import { accountDetailsCardBg } from "../../../../services/approvalStatus";
import convertDate from "../../../../utils/convertDateUtil";

const AccountVendorDetailsBannerComponent = ({ accountData }) => {
  const bannerComponent = () => {
    return (
      <AccountVendorDetailBannerComponent
        bank_name={accountData?.bank_name}
        account_name={accountData?.name}
        account_id={accountData?.vendor_id}
        account_type={"vendor"}
        approval_status={accountData?.approval_status}
        account_number={accountData?.bank_acc_no}
      />
    );
  };

  return bannerComponent();
};

const AccountVendorDetailsBodyComponent = ({ accountData }) => {
  const accountBodyComponents = () => {
    let cardsNeeded = {
      "Contact Details": {
        "Contact number": `${
          accountData?.contact_no ? "+91 " + accountData?.contact_no : null
        }`,
        "Email ID": accountData?.email_id,
      },
      "Additional Details": {
        "PAN details": accountData?.pan_no,
        "GST details": accountData?.gst_no,
      },
      "Origin Timeline": {
        "Created by": [
          accountData?.created_by_name,
          convertDate(accountData?.created_on),
        ],
      },
      "Bank Details": { "IFSC code": accountData?.ifsc_code },
    };
    if (accountData?.approval_status === "N") {
      cardsNeeded["Origin Timeline"]["Denied by"] = [
        accountData?.approved_by_name,
        convertDate(accountData?.approved_on),
      ];
    } else {
      cardsNeeded["Origin Timeline"]["Approved by"] = [
        accountData?.approved_by_name,
        convertDate(accountData?.approved_on),
      ];
    }
    if (accountData?.denied_reason) {
      cardsNeeded["Origin Timeline"]["Denied reason"] =
        accountData?.denied_reason;
    }
    return (
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        {cardsNeeded &&
          Object.keys(cardsNeeded).map((key, index) => {
            return (
              <div className="w-full h-fit">
                <AccountDetailCard
                  heading={key}
                  data={cardsNeeded[key]}
                  bg_color={accountDetailsCardBg(accountData?.approval_status)}
                />
              </div>
            );
          })}
      </div>
    );
  };
  return accountBodyComponents();
};

export {
  AccountVendorDetailsBannerComponent,
  AccountVendorDetailsBodyComponent,
};
