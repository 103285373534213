import React from "react";
// import logo from "../../../../assets/BankLogos/DCB bank.png";
// import bank1 from "../../../../assets/Banks/Any.png";
import "./index.css";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const OwnerTransactionCardLogo = (props) => {
  return (
    <div
      className={`
      flex 
      justify-center group-hover:bg-blue-100 text-center
      bg-white `}
      style={{ position: "relative", borderRadius: "20px 0px" }}
    >
      <div className="owner_category_div">
        <img
          // className={scheme_logo ? "w-50%" : "w-full"}
          className="w-50"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
          src={`../../../../assets/Banks/Any.png`}
          alt="BankLogo"
        />
      </div>
      <div className="owner_payment_mode_div">
        {/* {getLogoPath("AmEx") && ( */}
        <img
          src={require("../../../../assets/Banks/" +
            bankLogoMapper(props.data?.beneficiary_account_bank_name) +
            ".png")}
          alt="bank"
          className="owner_paymentmode_image"
        />
      </div>
    </div>
  );
};

export default OwnerTransactionCardLogo;
