import { Button, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { initiateRefund } from "../../../../redux/features/initiateRefund/initiateRefund";
import selectedLogo from "../../../../assets/Approve transactions/selected.png";
import noSelectedLogo from "../../../../assets/Approve transactions/not selected.png";
import formatCurrency from "../../../../utils/CurrencyFormater";

const TransactionRefund = (props) => {
  const dispatch = useDispatch();
  const [completeRefundStatus, setCompleteRefundStatus] = useState(false);
  const amountRef = useRef();

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div className="flex 4xl:text-3xl  justify-center items-center left-0 top-0 bottom-0 right-0 m-auto  absolute z-[9999]">
        <div
          className="w-[28%] h-fit flex flex-col gap-8 bg-custom-deniedReasonPurple rounded-4xl
          px-8 3xl:px-10 4xl:px-12 5xl:px-16 6xl:px-20 py-10"
        >
          <div className="flex flex-col gap-4">
            <div
              className="text-[#FF7F33] 
            text-2xl 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl"
            >
              Transaction refund
            </div>
            <div
              className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-gray-300 antialiased truncate"
            >
              Enter amount
            </div>
            <TextField
              autoComplete="off"
              inputProps={{
                style: {
                  color: "white",
                  borderBottom: "1px solid grey",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#DADADA",
                  font: "normal normal normal 14px/19px Open Sans",
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              label="Amount"
              variant="standard"
              inputRef={amountRef}
              onChange={{}}
            />
            <div
              className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
           text-gray-400 my-5"
            >
              Or
            </div>
            <div className="flex justify-start gap-5">
              <div
                onClick={() => setCompleteRefundStatus(!completeRefundStatus)}
                className="w-7 h-7 cursor-pointer"
              >
                <img
                  src={completeRefundStatus ? selectedLogo : noSelectedLogo}
                  alt="selected"
                />
              </div>
              <div className="text-white font-semibold">
                Complete refund (₹{formatCurrency(props.amount)})
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5%",
              width: "100%",
            }}
          >
            <Button
              sx={{
                borderRadius: "20px",
                borderColor: "#fff",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
              onClick={() => {
                props.setState(false);
              }}
              className="4xl:text-3xl"
            >
              Cancel
            </Button>
            <Button
              className="4xl:text-3xl"
              variant="outlined"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
              onClick={() => {
                dispatch(
                  initiateRefund({
                    txn_id: props.txn_id,
                    amount: completeRefundStatus
                      ? props.amount
                      : amountRef.current.value,
                  })
                );
                props.setState(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionRefund;
