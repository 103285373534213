import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { toast_position } from "../../Authorization";
import { env } from "../../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;
export const getEntities = createAsyncThunk("getEntities", async (values) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
  };

  return await fetch(
    `${baseUrl}/entities?page_no=${values?.page_no || 1}&page_size=${
      values?.page_size || 15
    }&entity_id=${values?.entity_id || ""}&search_string=${
      values?.search_string || ""
    } `,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
});

export const createEntity = createAsyncThunk("createEntity", async (values) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify(values),
  };
  return await fetch(`${baseUrl}/entities`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
});

const entitiesSlice = createSlice({
  name: "entities",
  initialState: {
    isLoading: false,
    entities: null,
    grades: [],
    isError: false,
    createEntityRes: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getEntities.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getEntities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.entities = action.payload;
      try {
        state.grades = action.payload?.entities[0]?.grades;
      } catch {}
    });
    builder.addCase(getEntities.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(createEntity.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createEntity.fulfilled, (state, action) => {
      state.isLoading = false;
      state.createEntityRes = action.payload;
      if (action.payload?.status)
        toast.success("Entity created successfully", toast_position);
      if (action.payload?.errors)
        toast.error(action.payload?.errors[0], toast_position);
    });
    builder.addCase(createEntity.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export default entitiesSlice.reducer;
