import React from 'react'

const VerificationDetails = ({ template, data }) => {
    return (
        <div className='flex w-full flex-col gap-16'>
            <div className='w-full'>
                <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-right w-full
              text-white antialiased truncate ...'>{template["title-card"]?.label}</div>
                <div className='flex gap-12'>
                    <div className='flex items-center w-28 h-28 3xl:w-32 3xl:h-32 4xl:w-36 4xl:h-36 6xl:w-40 6xl:h-40' >
                        <img src={require('../../../../assets/Settings/Verification/' + template["title-card"]?.icon)} alt='' />
                    </div>
                    <div className='flex flex-col justify-center w-full gap-1'>
                        <div className='text-2xl font-bold 3xl:text-3xl 5xl:text-4xl 6xl:text-5xl
              text-white antialiased truncate ..."' >{data[template["title-card"]?.api_key] || "Not provided"}</div>
                        <div className='text-gray-300 text-lg antialiased'>{template["title-card"]["title-subtext"]}</div>
                    </div>
                </div>
            </div>
            <div className='flex w-full' >
                <div className='flex w-1/3 flex-col gap-8'>
                    {
                        template.w_icons.map((item, index) => {
                            return (
                                <div key={index} className='flex items-center gap-12'>
                                    <div className='flex items-center w-7 h-7 3xl:w-8 3xl:h-8 4xl:w-9 4xl:h-9 6xl:w-10 6xl:h-10' >
                                        <img src={require('../../../../assets/Settings/Verification/' + item.icon)} alt='' />
                                    </div>
                                    <div className='flex flex-col justify-center w-full gap-1'>
                                        <div className='text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl
                text-white antialiased truncate ..."' >{data[item?.api_key] || "Not provided"}</div>                                </div>
                                </div>
                            )
                        }
                        )
                    }
                </div>
                <div className='grid w-1/2 grid-rows-3 grid-cols-auto grid-flow-col-dense justify-between gap-x-5  gap-y-5' >
                    {
                        template.wo_icons.map((item, index) => {
                            return (
                                <div key={index} className='flex flex-col gap-1'>
                                    <div className={`text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl truncate
                                    ${item.title === "PAN Number" ? "underline cursor-pointer" : null}
                text-white antialiased truncate ...`}>{data[item?.api_key] || "Not provided"}
                                        {item.title === "PAN Number" && <div className="inline-block mx-8 w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8">
                                            <img width={"100^"} src={require('../../../../assets/TransactionDashboardIcons/arrow_next.png')} alt='' />
                                        </div>}
                                    </div>
                                    <div className='text-gray-300 text-lg antialiased' >{item.title}</div>
                                </div>
                            )
                        }
                        )

                    }
                </div>
            </div>
        </div>
    )
}

export default VerificationDetails