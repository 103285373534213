import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import ApprovalCardsCarousel from "../../../components/ApprovalTxn/FieldForceApproval/ApprovalDetailsCarousel";
import "./index.css";
import SelectVendorTxns from "./SelectTxnList";
import { useDispatch, useSelector } from "react-redux";
import { getFieldForcePendingTxn } from "../../../redux/features/pendingTxn/pendingTxn";
import DeniedReason from "../../../components/ApprovalTxn/VendorApproval/DeniedReson/DeniedReason";
import SettlePayment from "../../SettlementRequired/SettlePayment";

const FieldForceApproval = () => {
  const dispatch = useDispatch();
  const [selectedTxnCarousel, setSelectedTxnCarousel] = useState({});
  const [showMultiple, setShowMultiple] = useState(false);
  const [deniedReason, setDeniedReason] = useState(false);
  const [deniedReasonData, setDeniedReasonData] = useState({});
  const [showSettlementComponent, setShowSettlementComponent] = useState(false);
  const [page, setPage] = useState(0);
  const ffPendingApprovals = useSelector(
    (state) => state.pendingTxn.fieldForce
  );
  const [vendorData, setVendorData] = useState([]);

  const dataAppend = (data, dataToBeAdded) => {
    let appendedData = [];
    let deDupData = [];
    let i = 0;
    data?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    dataToBeAdded?.forEach((item) => {
      appendedData[i] = item;
      i++;
    });
    // deDup data
    let j = 0;
    appendedData?.forEach((item) => {
      if (!deDupData?.find((deDupItem) => deDupItem.txn_id === item.txn_id)) {
        deDupData[j] = item;
        j++;
      }
    });
    appendedData = deDupData;
    // sort data w.r.t txn_id
    appendedData.sort(
      (timestamp, nextTimestamp) =>
        Number(nextTimestamp.txn_id) - Number(timestamp.txn_id)
    );
    return appendedData;
  };

  useEffect(() => {
    setVendorData(
      dataAppend(vendorData, ffPendingApprovals?.transactions || [])
    );
  }, [ffPendingApprovals]);

  useEffect(() => {
    if (showMultiple) {
      setPage(1);
      setVendorData([]);
    }
    dispatch(getFieldForcePendingTxn({ page_no: page }));
  }, [showMultiple, page, showSettlementComponent]);

  return !showSettlementComponent ? (
    <>
      <Box className="selectTxnsHeadingBoxMobile">
        <Box sx={{ display: "flex" }}>
          <Button className="selectTxnSearch">
            <img
              className="selectTxnSearchImg"
              src={require("../../../assets/Approve transactions/Close.png")}
              alt=""
              srcSet=""
            />
          </Button>
          &nbsp; &nbsp;
          <Box className="selectTxnsHeading">Field force Transactions</Box>
        </Box>
      </Box>
      {deniedReason && (
        <DeniedReason
          setDeniedReason={setDeniedReason}
          txnId={deniedReasonData.txnId}
          dispatchFn={deniedReasonData.dispatchFn}
          setShowStatusCard={deniedReasonData.setShowStatusCard}
        />
      )}
      <Box className="vendorApproval">
        {showMultiple ? (
          <SelectVendorTxns data={vendorData} />
        ) : (
          <ApprovalCardsCarousel
            records={ffPendingApprovals?.title.records}
            page={page}
            setPage={setPage}
            setSelectedTxnCarousel={setSelectedTxnCarousel}
            setShowSettlementComponent={setShowSettlementComponent}
            setDeniedReason={setDeniedReason}
            setDeniedReasonData={setDeniedReasonData}
            data={vendorData}
          />
        )}
      </Box>
      <Button
        onClick={() => setShowMultiple(!showMultiple)}
        className="showMultipleBT"
      >
        <img
          className="selectTxnSearchImg"
          src={require("../../../assets/Approve transactions/Task switcher.png")}
          alt=""
          srcSet=""
        />
      </Button>
    </>
  ) : (
    <SettlePayment getSelected={() => selectedTxnCarousel} />
  );
};

export default FieldForceApproval;
