import { useState, useRef, useEffect } from 'react'
import AddRuleButton from './AddRuleButton'
import SetRange from './SetRange'
import SetStages from './SetStages'

function AddCustomRule({ rule, index, type_of_transaction, archive, setArchive,dontShowName }) {
    const [showAddRuleButton, setShowAddRuleButton] = useState(rule ? false : true)
    const [showSetRange, setShowSetRange] = useState(false)
    const minRef = useRef()
    const maxRef = useRef()

    const handlechange = () => {
        if (minRef.current?.value > 10000000000) minRef.current.value = minRef.current.value.slice(0, 10)
        if (maxRef.current?.value > 10000000000) maxRef.current.value = maxRef.current.value.slice(0, 10)
    }

    const handleCancel = () => {
        setShowAddRuleButton(true)
        minRef.current.value = ""
        maxRef.current.value = ""
    }

    return (
        <div>
            {showAddRuleButton ? <AddRuleButton showPopup={setShowSetRange} /> :
                <SetStages dontShowName={dontShowName} setShowAddRuleButton={setShowAddRuleButton} archive={archive} setArchive={setArchive} type_of_transaction={type_of_transaction} index={index} rule={rule} min={minRef.current?.value || rule.lower_amount} max={maxRef.current?.value || rule.higher_amount} handleCancel={handleCancel} />}
            {showSetRange && <SetRange setState={setShowSetRange} setShowAddRuleButton={setShowAddRuleButton} minRef={minRef} maxRef={maxRef} handlechange={handlechange} />}
        </div>
    )
}

export default AddCustomRule