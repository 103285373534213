import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const panVerify = createAsyncThunk(
    "panVerify",
    async (values) => {
        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
            body: JSON.stringify({
                account_id: "68855",
            }),
        };

        return await fetch(
            `${legacyUrl}/pan/view`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);

const panVerifySlice = createSlice({
    name: "panVerify",
    initialState: {
        isLoading: false,
        instantpay: null,
        vendor: null,
        fieldForce: null,
        internal: null,
        client: null,
        reimbursement: null,
        isError: false,
    },
    extraReducers: (builder) => {
        //Instantpay
        builder.addCase(panVerify.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(panVerify.fulfilled, (state, action) => {
            state.isLoading = false;
            state.panVerify = action.payload;
        });
        builder.addCase(panVerify.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isError = true;
        });
    },
});

export default panVerifySlice.reducer;
