import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { values } from "lodash";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action
export const getInstantpayViewAll = createAsyncThunk(
  "getInstantpayViewAll",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: values?.page_no || 0,
        page_size: 15,
        approval_status: values?.approval_status,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/Instant_pay/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getVendorViewAll = createAsyncThunk(
  "getVendorViewAll",
  async (values) => {
    const body =
    {
      vendor_id: values?.vendor_id,
      approval_status: values?.approval_status,
      page_no: values?.page_no || 0,
      page_size: 15,
      settle_id: values?.settle_id,
      search_string: values?.search_string
    };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(body),
    };

    return await fetch(
      `${legacyUrl}/transactions/vendor/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getFieldForceViewAll = createAsyncThunk(
  "getFieldForceViewAll",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: values?.page_no || "0",
        page_size: "15",
        approval_status: values?.approval_status,
        search_string: values?.search_string,
        account_id: values?.destination_account_id,
        settle_id: values?.settle_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/field_force/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getInternalViewAll = createAsyncThunk(
  "getInternalViewAll",
  async (values) => {
    const body =
    {
      vendor_id: values?.vendor_id,
      approval_status: values?.approval_status,
      page_no: values?.page_no || 0,
      settle_id: values?.settle_id,
      page_size: 15,
      destination_owner_id: values?.destination_owner_id || null,
      source_owner_id: values?.source_owner_id || null,
      search_string: values?.search_string
    };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(body),
    };

    return await fetch(
      `${legacyUrl}/transactions/owner/internal_fund_transfer/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getClientViewAll = createAsyncThunk(
  "getClientViewAll",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        approval_status: values?.approval_status,
        page_no: values?.page_no || 0,
        page_size: 15,
        settle_id: values?.settle_id,
        client_id: values?.client_id,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/client/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getClientViewAllRefunds = createAsyncThunk(
  "getClientViewAllRefunds",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        settlement_type: values?.settlement_type,
        page_no: values?.page_no || 0,
        page_size: 15,
        search_string: values?.search_string
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/client/refund/view`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getReimbursementViewAll = createAsyncThunk(
  "getReimbursementViewAll",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: "0",
        page_size: "50",
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/reimbursement/view_all`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const txnViewAllSlice = createSlice({
  name: "txnViewAll",
  initialState: {
    isLoading: false,
    instantpay: null,
    vendor: null,
    fieldForce: null,
    internal: null,
    client: null,
    reimbursement: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //Instantpay
    builder.addCase(getInstantpayViewAll.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInstantpayViewAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.instantpay = action.payload;
    });
    builder.addCase(getInstantpayViewAll.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Vendor
    builder.addCase(getVendorViewAll.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorViewAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(getVendorViewAll.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //FieldForce
    builder.addCase(getFieldForceViewAll.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFieldForceViewAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldForce = action.payload;
    });
    builder.addCase(getFieldForceViewAll.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Internal
    builder.addCase(getInternalViewAll.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInternalViewAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internal = action.payload;
    });
    builder.addCase(getInternalViewAll.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Client
    builder.addCase(getClientViewAll.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientViewAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(getClientViewAll.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });

    builder.addCase(getClientViewAllRefunds.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientViewAllRefunds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clientRefund = action.payload;
    });
    builder.addCase(getClientViewAllRefunds.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Reimbursement
    builder.addCase(getReimbursementViewAll.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReimbursementViewAll.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbursement = action.payload;
    });
    builder.addCase(getReimbursementViewAll.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default txnViewAllSlice.reducer;






