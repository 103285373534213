export const timelineLogoMapper = (txn) => {
    switch (txn.platform) {
        case "Web App": {
            return "webapp.png";
        }
        case "Web": {
            switch (txn.browser_name) {
                case "Google Chrome": {
                    return "chrome.png";
                }
                case "Firefox": {
                    return "firefox.png";
                }
                case "safari": {
                    return "safari.png";
                }
                case "Edge": {
                    return "edge.png";
                }
                default: {
                    return "browserdefault.png";
                }
            }
        }
        case "Android": {
            return "android.png";
        }
        case "iOS": {
            return "ios.png";
        }
        case "Server": {
            return "servers.png";
        }
        case "API": {
            return "api.png";
        }
        default: {
            return "android.png";
        }
    }
}
