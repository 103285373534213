import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const viewVendorDetails = createAsyncThunk(
  "viewVendorDetails",
  async (values) => {
    const { vendor_id } = values
      ? values
      : {
        vendor_id: null,
      };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: null,
        page_size: null,
        vendor_id: vendor_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/accounts/vendor/view`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

const viewAccountDetails = createSlice({
  name: "viewAccountDetails",
  initialState: {
    vendor: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(viewVendorDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(viewVendorDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(viewVendorDetails.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default viewAccountDetails.reducer;











