import React, { useEffect, useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import {
  getAllApprovalStatus,
  getApprovalList,
} from "../../../../services/getAllApprovalStatus";
import dropDownLogo from "../../../../assets/ViewAllTransactions/Dropdown white.png";
import { filterButtonColorMapper } from "../../../../services/colorMapper";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import {
  AccountStatusTextMapper,
  AccountTypeApprovalStatusMap,
} from "../../../../services/accountApprovalTextMapper";
import { filterButtonViewAll } from "../../../../services/approvalStatus";

function AccountStatusTypePopOver(props) {
  const accountStatusList = AccountTypeApprovalStatusMap(props.accountType);
  const status_list =
    props.accountType != "cards"
      ? [...new Set(props.data?.map((item) => item.approval_status))]
      : [...new Set(props.data?.map((item) => item.status))];

  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowDown, setArrowDown] = useState(false);
  const [selectedType, setSelectedType] = useState("All");
  const [selectedTypeColor, setSelectedTypeColor] = useState("#446AFF");
  const selectedStatus = useRef("All");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowDown(!arrowDown);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setArrowDown(!arrowDown);
  };

  useEffect(() => {
    let accountStatusVal = props.accountStatus
      ? AccountStatusTextMapper(props.accountType, props.accountStatus)
      : "All";
    let accountStatusColor = props.accountStatus
      ? filterButtonViewAll(props.accountStatus)
      : "#446AFF";
    setSelectedType(accountStatusVal);
    setSelectedTypeColor(accountStatusColor);
  }, [status_list]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="self-center">
      <Button
        className="flex flex-row gap-2 p-1 4xl:p-2"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{
          borderRadius: "2rem",
          backgroundColor: selectedTypeColor,
          textTransform: "Capitalize",
          "&:hover": {
            backgroundColor: selectedTypeColor,
            filter: "brightness(1.2)",
          },
        }}
      >
        <span className="text-base 3xl:text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
          {selectedType}
        </span>

        <span
          style={{
            transform: arrowDown ? "rotate(180deg)" : "rotate(0deg)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <img
            src={dropDownLogo}
            alt="Dropdown-white"
            border="0"
            style={{ width: "1.2em", height: "1.2em" }}
          />
        </span>
      </Button>

      <Popover
        className="w-full drop-shadow-xl"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            padding: "1em",
            borderRadius: "1.5rem",
            textTransform: "Capitalize",
            marginTop: "0.75em",
          },
        }}
      >
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          <Button
            
            className="flex flex-row gap-2 p-1 4xl:p-2"
            aria-describedby={id}
            variant="contained"
            onClick={() => {
              props.setAccountStatus(null);
              selectedStatus.current = "All";
              handleClose();
            }}
            sx={{
              borderRadius: "2rem",
              backgroundColor: "#446AFF",
              textTransform: "Capitalize",
              "&:hover": {
                backgroundColor: "#446AFF",
                filter: "brightness(1.2)",
              },
            }}
          >
            <span className="text-sm md:text-base 3xl:text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
              All
            </span>
            {selectedType === "All" && (
              <CheckIcon className={{ width: "1em", height: "1em" }} />
            )}
          </Button>

          {Object.keys(accountStatusList).map((status) => (
            <Button
              className="flex flex-row gap-1"
              aria-describedby={id}
              variant="contained"
              onClick={() => {
                props.setAccountStatus(status);
                selectedStatus.current = status;
                handleClose();
              }}
              sx={{
                borderRadius: "2rem",
                backgroundColor: filterButtonViewAll(status),
                textTransform: "Capitalize",
                "&:hover": {
                  backgroundColor: filterButtonViewAll(status),
                  filter: "brightness(1.2)",
                },
              }}
            >
              <span className="text-base 3xl:text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                {AccountStatusTextMapper(props.accountType, status)}
              </span>
              {selectedType ===
                AccountStatusTextMapper(props.accountType, status) && (
                <CheckIcon className={{ width: "1em", height: "1em" }} />
              )}
            </Button>
          ))}
        </div>
      </Popover>
    </div>
  );
}

export default AccountStatusTypePopOver;
