import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const createReimbusrementAcc = createAsyncThunk(
  "createReimbusrementAcc",
  async (values) => {
    const {
      bank_acc_no,
      contact_no,
      email_id,
      ifsc_code,
      pan_no,
      name,
      user_id
    } = values
        ? values
        : {
          bank_acc_no: null,
          contact_no: null,
          email_id: null,
          ifsc_code: null,
          pan_no: null,
          name: null,
          user_id: null,

        };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        bank_acc_no: bank_acc_no,
        contact_no: contact_no,
        email_id: email_id,
        ifsc_code: ifsc_code,
        pan_no: pan_no,
        name: name,
        user_id: user_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/accounts/reimbursement/create`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);


const accountsSlice = createSlice({
  name: "createReimbusrementAcc",
  initialState: {
    reimbusrement: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(createReimbusrementAcc.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createReimbusrementAcc.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbusrement = action.payload;
    });
    builder.addCase(createReimbusrementAcc.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default accountsSlice.reducer;




























