import { delFromObj } from "../utils/delFromObj";

export const vendorDashboardChecks = (obj, services) => {
  var data = delFromObj(obj, "status");
  if (services ? !services.includes("ASinglePay") : true) {
    delete data.requires_approval_txn_details;
  } else {
    data["Require approval"] = data.requires_approval_txn_details;
    delete data.requires_approval_txn_details;
  }
  if (
    (data.scheduled_txn_details !== "0") &&
    (data.requires_settlement_txn_details !== "0")
  ) {
    if (
      (data.scheduled_txn_details < data.awaiting_approval_txn_details) ||
      services?.includes("ASinglePay")
    ) {
      data["Waiting for approval"] = data.awaiting_approval_txn_details;
      delete data.awaiting_approval_txn_details;
    } else {
      delete data.awaiting_approval_txn_details;
    }
  } else {
    delete data.awaiting_approval_txn_details;
  }
  if (services ? !services.includes("VSinglePay") : true) {
    delete data.requires_settlement_txn_details;
  } else {
    data["Require settlement"] = data.requires_settlement_txn_details;
    delete data.requires_settlement_txn_details;
  }
  if (data.processing_txn_details) {
    data["Processing transactions"] = data.processing_txn_details;
    delete data.processing_txn_details;
  }
  if (data.settled_txn_details) {
    data["Settled transactions"] = data.settled_txn_details;
    delete data.settled_txn_details;
  }
  if (data.scheduled_txn_details?.records) {
    delete data.scheduled_txn_details;
  } else {
    data["Pending transactions"] = data.scheduled_txn_details;
    delete data.scheduled_txn_details;
  }
  if (data.abandoned_txn_details?.records === "0") {
    delete data.abandoned_txn_details;
  } else {
    data["Abandoned transactions"] = data.abandoned_txn_details;
    delete data.abandoned_txn_details;
  }
  if (data.cancelled_txn_details?.records === "0") {
    delete data.cancelled_txn_details;
  } else {
    data["Cancelled transactions"] = data.cancelled_txn_details;
    delete data.cancelled_txn_details;
  }
  if (data.gateway_failed_txn_details?.records === "0") {
    delete data.gateway_failed_txn_details;
  } else {
    data["Failed transactions"] = data.gateway_failed_txn_details;
    delete data.gateway_failed_txn_details;
  }
  return data;
};
