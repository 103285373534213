const accTransform = (acc, longerFormat = true) => { // i used true instead of false change by pmk bug 394
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }
  if (!acc) {
    return "N/A";
  } else {
    if (longerFormat)
      return `•••• •••• •••• ${acc.slice(-4)}`;
    return `•••• ${acc.slice(-4)}`;
  }
};

export default accTransform;
