import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";
import axios from "axios";

const legacyUrl = env.REACT_APP_LEGACY_URL;
const baseUrl = env.REACT_APP_BASE_URL;
// local data - hence not redux

export const getBeneAccountsData = (values, successFn, failureFn) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    data: {
      page_no: values?.page_no || 0,
      page_size: "15",
      search_string: values?.search_string || "",
    },
  };

  // Using axios without async/await
  axios
    .post(`${baseUrl}/accounts/beneficiary`, requestOptions.data, {
      headers: requestOptions.headers,
    })
    .then((response) => {
      response.data && successFn(response.data);
    })
    .catch((error) => failureFn(error));
};

export const getVendorsAccountsData = async (values, successFn, failureFn) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      page_no: values?.page_no || 0,
      page_size: "15",
      search_string: values?.search_string || "",
    }),
  };

  return await fetch(`${legacyUrl}/accounts/vendor/view`, requestOptions)
    .then((response) => {
      response.json().then((result) => {
        successFn(result);
      });
    })
    .catch((error) => failureFn(error));
};

export const getOwnerAccountsData = async (values, successFn, failureFn) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      page_no: values?.page_no || 0,
      page_size: "15",
      search_string: values?.search_string || "",
      active_status: values?.active_status || "",
      hdfc_umrn: values?.hdfc_umrn || "",
    }),
  };

  return await fetch(`${legacyUrl}/accounts/owner/view`, requestOptions)
    .then((response) => {
      response.json().then((result) => {
        successFn(result);
      });
    })
    .catch((error) => failureFn(error));
};

export const getClientAccountsData = async (values, successFn, failureFn) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      page_no: values?.page_no,
      page_size: values.page_size,
      search_string: values?.search_string || "",
    }),
  };

  return await fetch(`${legacyUrl}/accounts/client/view`, requestOptions)
    .then((response) => {
      response.json().then((result) => {
        successFn(result);
      });
    })
    .catch((error) => failureFn(error));
};

export const getFFAccountsData = async (values, successFn, failureFn) => {
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      page_no: values?.page_no || "0",
      page_size: values?.page_size || "15",
      search_string: values?.search_string || "",
      approval_status: values?.approval_status || "",
      user_id: values?.user_id || "",
    }),
  };

  return await fetch(`${legacyUrl}/accounts/field_force/view`, requestOptions)
    .then((response) => {
      response.json().then((result) => {
        successFn(result);
      });
    })
    .catch((error) => failureFn(error));
};

// Action
export const getInstantpayAccounts = createAsyncThunk(
  "getInstantpayAccounts",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/Instant_pay/Accounts`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getVendorAccounts = createAsyncThunk(
  "getVendorAccounts",
  async (values) => {
    console.log("REL VENDOR CURL VALUES", values);
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        page_no: values?.page_no || 0,
        page_size: "15",
        search_string: values?.search_string || "",
      }),
    };

    return await fetch(`${legacyUrl}/accounts/vendor/view`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getBeneAccounts = createAsyncThunk("getBeneAccounts", (values) => {
  console.log("REL BENE CURL VALUES", values);

  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    data: {
      page_no: values?.page_no || 0,
      page_size: "15",
      search_string: values?.search_string || "",
    },
  };

  // Using axios without async/await
  return axios
    .post(`${baseUrl}/accounts/beneficiary`, requestOptions.data, {
      headers: requestOptions.headers,
    })
    .then((response) => {
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.log("error", error);
      throw error;
    });
});

export const getFieldForceAccounts = createAsyncThunk(
  "getFieldForceAccounts",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        account_id: values?.account_id || "",
      }),
    };

    return await fetch(`${legacyUrl}/accounts/field_force/view`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getInternalAccounts = createAsyncThunk(
  "getInternalAccounts",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/transactions/owner/internal_fund_transfer/Accounts`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getClientAccounts = createAsyncThunk(
  "getClientAccounts",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(`${legacyUrl}/accounts/client/view`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);
export const getReimbursementAccounts = createAsyncThunk(
  "getReimbursementAccounts",
  async () => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };

    return await fetch(
      `${legacyUrl}/accounts/reimbursement/view`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const getOwnerAccounts = createAsyncThunk(
  "getOwnerAccounts",
  async (values = {}) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(values),
    };

    return await fetch(`${legacyUrl}/accounts/owner/view`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const accountsSlice = createSlice({
  name: "accounts",
  initialState: {
    isLoading: false,
    instantpay: null,
    beneficiary: null,
    vendor: null,
    fieldForce: null,
    internal: null,
    client: null,
    reimbursement: null,
    owner: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //Beneficiary
    builder.addCase(getBeneAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBeneAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.beneficiary = action.payload;
    });
    builder.addCase(getBeneAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Instantpay
    builder.addCase(getInstantpayAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInstantpayAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.instantpay = action.payload;
    });
    builder.addCase(getInstantpayAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Vendor
    builder.addCase(getVendorAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getVendorAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("REL VENDOR RESPONSE", action.payload);
      state.vendor = action.payload;
    });
    builder.addCase(getVendorAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //FieldForce
    builder.addCase(getFieldForceAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getFieldForceAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldForce = action.payload;
    });
    builder.addCase(getFieldForceAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Internal
    builder.addCase(getInternalAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getInternalAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.internal = action.payload;
    });
    builder.addCase(getInternalAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Client
    builder.addCase(getClientAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getClientAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(getClientAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Reimbursement
    builder.addCase(getReimbursementAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getReimbursementAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.reimbursement = action.payload;
    });
    builder.addCase(getReimbursementAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //Owner
    builder.addCase(getOwnerAccounts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getOwnerAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.owner = action.payload;
    });
    builder.addCase(getOwnerAccounts.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default accountsSlice.reducer;
