import { Box } from "@mui/system";
import React, { useState } from "react";
import "./index.css";
import SideDrawer from "../SideDrawer";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MoreActions from "../MoreActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getVendorViewDetail } from "../../../redux/features/txnDetails/txnDetails";
import { getVendorViewAll } from "../../../redux/features/txnViewAll/txnViewAll";

const QuickAccessMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showOtherIcons, setShowOtherIcons] = useState(true);
  const [toggleDocSec, settoggleDocSec] = useState(false);
  const handleShowIcons = () => {
    setShowOtherIcons(!showOtherIcons);
  };

  const toggleDocSection = () => {
    settoggleDocSec((toggleDocSec) => !toggleDocSec);
  };

  return (
    <Box
      className="h-full drop-shadow-xl flex flex-col
      rounded-4xl
      overflow-hidden"
      sx={{
        backgroundColor: props.bgColor,
      }}
    >
      <Box className="p-2 w-full h-full flex flex-row justify-evenly divide-x divide-[#1d1d1d55]">
        <Box
          className="cursor-pointer text-center h-full w-1/3 flex flex-col justify-center gap-1 hoverEffect p-2"
          onClick={() => {
            toggleDocSection();
          }}
          // sx={{transition: "linear height 2s"}}
        >
          <div
            className="flex justify-center h-4/5 
          `${ toggleDocSec ? 'h-auto' : 'h-0' }`"
            style={{ transition: "height 2s" }}
          >
            <Box className="self-center w-[30%]">
              <img
                src={require("../../../assets/TxnDetails/document-icon.png")}
                alt="document"
              />
            </Box>
          </div>
          <div className="flex justify-center h-1/5">
            <Box
              className=" h-8/12 self-center text-white
                text-xs antialiased truncate..."
            >
              Documents
            </Box>
          </div>
        </Box>
        {showOtherIcons && (
          <hr
            style={{
              height: "100%",
              borderColor: props.secondary_bg,
            }}
          />
        )}
        {showOtherIcons && (
          <Box
            className="cursor-pointer text-center h-full w-1/3 flex flex-col justify-center gap-1 hoverEffect p-2"
            onClick={() => {
              navigate(props?.txnHistoryPath);
            }}
          >
            <div className="flex justify-center h-4/5">
              <Box className="self-center w-[30%]">
                <img
                  src={require("../../../assets/TxnDetails/transaction-history.png")}
                  alt="document"
                />
              </Box>
            </div>
            <div className="flex justify-center h-1/5">
              <Box
                className=" h-8/12 self-center text-white
                text-xs antialiased truncate..."
              >
                Transaction history
              </Box>
            </div>
          </Box>
        )}
        {showOtherIcons && (
          <hr
            style={{
              height: "100%",
              borderColor: props.secondary_bg,
            }}
          />
        )}
        {showOtherIcons && (
          <Box
            className={`
            ${props.settle_id && "cursor-pointer"}
            ${!props.settle_id && "opacity-40"}
            text-center h-full w-1/3 flex flex-col justify-center gap-1 hoverEffect p-2`}
            onClick={() => {
              if (props.settle_id) navigate(props?.txnStatementPath);
              else return;
            }}
          >
            <div className="flex justify-center h-4/5">
              <Box className="self-center w-[30%]">
                <img
                  src={require("../../../assets/TxnDetails/transaction-entry.png")}
                  alt="transaction-entry"
                />
              </Box>
            </div>
            <div className="flex justify-center h-1/5">
              <Box
                className=" h-8/12 self-center text-white
                text-xs antialiased truncate..."
              >
                Entry in statement
              </Box>
            </div>
          </Box>
        )}
      </Box>
      {toggleDocSec && (
        <div className="p-4 relative z-10">
          <SideDrawer id={props.txn_id} />
        </div>
      )}

      <div className="py-1 -mr-2 -ml-2 ">
        <hr className="w-full border-[#1d1d1d55]" />
        <hr className="w-full border-[#1d1d1d55]" />
      </div>
      <div className="w-full flex justify-center">
        <MoreActions
          addStatus={props.addStatus}
          convertToInstantPay={props.convertToInstantPay}
          status={props.status}
        />
      </div>
    </Box>
  );
};

export default QuickAccessMenu;

// <Box className="QuickAccessMenu-Top-Menu">
//   <Accordion
//     disableGutters={true}
//     disableSpacing={true}
//     sx={{
//       background: props.bgColor,
//       margin: "0px",
//       padding: "0px",
//       border: "none",
//       boxShadow: "none",
//       width: "100%",
//     }}
//   >
//     <AccordionSummary
//       sx={{
//         padding: "0px",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         width: "100%",
//       }}
//     >

//     </AccordionSummary>
//     <AccordionDetails
//       sx={{
//         backgroundColor: props.bgColor,
//         width: "100%"
//       }}
//     >
//       <SideDrawer id={props.txn_id} />
//     </AccordionDetails>
//   </Accordion>
// </Box>
