import React from 'react';
import DinVerification from '../../../containers/Settings/Verification/DIN_verification/index.jsx';
import CinVerification from '../../../containers/Settings/Verification/CIN_Verification/index.jsx';
import AuthMatrix from '../../../containers/Settings/Configuration/AuthorizationMatrix/index.jsx';
import Entities from '../../../containers/Settings/Management/Entities/index.jsx';
import CreateEntities from '../../../containers/Settings/Management/Entities/CreateEntities/index.jsx';
import Details from '../../../containers/Settings/Management/Entities/Details/index.jsx';
import PanVerification from '../../../containers/Settings/Verification/PAN_Verification/index.jsx';
import GstVerification from '../../../containers/Settings/Verification/GST_Verification/index.jsx';
import FeatureDetails from '../../../containers/Settings/Management/Features/Details/index.jsx';
import RoleDetails from '../../../containers/Settings/Management/Roles/Details/index.jsx';
import ServiceDetails from '../../../containers/Settings/Management/Services/Details/index.jsx';
import UserDetails from '../../../containers/Settings/Management/Users/Details/index.jsx';
import Users from '../../../containers/Settings/Management/Users/index.jsx';
import Features from '../../../containers/Settings/Management/Features/index.jsx';
import Roles from '../../../containers/Settings/Management/Roles/index.jsx';
import Services from '../../../containers/Settings/Management/Services/index.jsx';

const settingsDashboardMapper = [
    {
        title: 'ADMINITRATION',
        options: [
            {
                title: 'Adminstration',
                path: '/settings/adminstration',
                img: "administration",
                serviceID: '',
            },
            {
                title: 'Email quotas',
                path: '/settings/adminstration/email-quotas',
                img: "email-quotas",
                serviceID: '',
            },
            {
                title: 'API Usage',
                path: '/settings/adminstration/api-usage',
                img: "API",
                serviceID: '',
            },
        ],
    },
    {
        title: 'SERVICES',
        options: [
            {
                title: 'Control Panel',
                path: '/settings/services/control-panel',
                img: "control-panel",
                serviceID: '',
            },
            {
                title: 'Analytics',
                path: '/settings/services/analytics',
                img: "analytics",
                serviceID: '',
            },
            {
                title: 'Express settings',
                path: '/settings/services/express-settings',
                img: "express settings",
                serviceID: '',
            },
        ],
    },
    {
        title: 'COMMUNICATION',
        options: [
            {
                title: 'Outgoing SMS',
                path: '/settings/communication/outgoing-sms',
                img: "outgoing-sms",
                serviceID: '',
            },
            {
                title: 'Outgoing Email',
                path: '/settings/communication/outgoing-email',
                img: "outgoing-mails",
                serviceID: '',
            },
        ],
    },
    {
        title: 'DATA',
        options: [
            {
                title: 'Statistics',
                path: '/settings/data/statistics',
                img: "statistics",
                serviceID: '',
            },
        ],
    },
    {
        title: 'MANAGMENT',
        options: [
            {
                title: 'Users',
                path: '/settings/management/users',
                img: "users",
                serviceID: 'VEmp',
                container: <Users />
            },
            {
                title: 'Entities',
                path: '/settings/management/entities',
                img: "entities",
                serviceID: 'Admin',
                container: <Entities />,
            },
            {
                path: '/settings/management/entities/create',
                container: <CreateEntities />,
            },
            {
                path: '/settings/management/entities/:id',
                container: <Details />,
            },
            {
                path: '/settings/management/users/:id',
                container: <UserDetails />,
            },
            {
                path: '/settings/management/services/:id',
                container: <ServiceDetails />,
            },
            {
                path: '/settings/management/roles/:id',
                container: <RoleDetails />,
            },
            {
                path: '/settings/management/features/:id',
                container: <FeatureDetails />,
            },
            {
                title: 'Grades',
                path: '/settings/management/grades',
                img: "grades",
                serviceID: 'Sup_VService',
            },
        ],
    },
    {
        title: 'NA',
        options: [{
            title: 'Services',
            path: '/settings/management/services',
            img: "services",
            serviceID: 'Sup_VService',
            container: <Services />
        },
        {
            title: 'Features',
            path: '/settings/management/features',
            img: "image",
            serviceID: 'SERVICE_VIEW_FEATURE',
            container: <Features />
        },
        {
            title: 'Roles',
            path: '/settings/management/roles',
            img: "roles",
            serviceID: 'Sup_VRole',
            container: <Roles />
        },
        ],
    },
    {
        title: 'CONFIGURATION',
        options: [
            {
                title: 'Auth Matrix',
                path: '/settings/configuration/auth-matrix',
                img: "approval-heirarchy",
                container: <AuthMatrix />,
                serviceID: 'VAuth_matrix',
            },
            {
                title: 'Transaction Matrix',
                path: '/settings/configuration/transaction-matrix',
                img: "test-matrix",
                serviceID: '',
            },
            {
                title: 'Payment Matrix',
                path: '/settings/configuration/payment-matrix',
                img: "Payment matrix",
                serviceID: '',
            },
        ],
    },
    {
        title: 'VERIFICATION',
        options: [
            {
                title: 'CIN verification',
                path: '/settings/verification/cin-verification',
                container: <CinVerification />,
                img: "CIN",
                serviceID: 'Verify_Cin_No',
            },
            {
                title: 'DIN verification',
                path: '/settings/verification/din-verification',
                container: <DinVerification />,
                img: "DIN",
                serviceID: 'VDIN',
            },
            {
                title: 'PAN',
                path: '/settings/verification/pan',
                container: <PanVerification />,
                img: "PAN",
                serviceID: 'VPAN',
            }
        ],
    },
    {
        title: 'NA',
        options: [
            {
                title: 'GST',
                path: '/settings/verification/gst',
                container: <GstVerification />,
                img: "PAN",
                serviceID: 'VGSTIN',
            },
            {
                title: 'Test transactions',
                path: '/settings/verification/test-transactions',
                img: "Test transactions",
                serviceID: '',
            },
            {
                title: 'IFSC',
                path: '/settings/verification/ifsc',
                img: "IFSC",
                serviceID: 'VIFSC',
            },
        ]
    }
];

export default settingsDashboardMapper