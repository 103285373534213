import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import groupIcon from "../../../src/assets/MyProfile.png";
import ProfileIcon from "../../../src/assets/ProfileIcon.png";
import ChangePassword from "../../../src/assets/ChangePassword.png";
import Role from "../../../src/assets/Settings/Entity Details/Edit roles.png";
import Services from "../../../src/assets/Settings/Entity Details/Edit services.png";
import Editprofile from "../../../src/assets/EditProfile.png";
import Logout from "../../../src/assets/LogoutAllDevices.png";
import Authorizeddevices from "../../../src/assets/Authorizeddevices.png";
import EditPopUp from "../../components/MyProfile/EditPop-up";
import LogoutModel from "../../components/MyProfile/LogoutModel";
import { Tooltip, tooltipClasses } from "@mui/material";
import {
  resetEmail,
  resetMobNum,
  resetAliasName,
} from "../../redux/features/users";
import { setHideBreadCrumb } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { checkUsertoken } from "../../redux/features/login/login";

import { useDispatch, useSelector } from "react-redux";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyProfile = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLogoutModelOpen, setIsLogoutModelOpen] = useState(false)
  const [updatedData, setUpdatedData] = useState({});
  const refreshData = useSelector((state) => state.refreshToken.data);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      // dispatch(checkUsertoken());
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  const updateRefreshData = (newData) => {
    setUpdatedData(newData);
  };

  const handleEdit = () => {
    setIsEditModalOpen(true);
  };

  const handleLogout = () => {
    setIsLogoutModelOpen(true)
  }

  const closeLogoutModel = () => {
    // dispatch(checkUsertoken());
    setIsLogoutModelOpen(false)
  }

  const closeModal = () => {
    dispatch(checkUsertoken());
    dispatch(resetAliasName());
    dispatch(resetEmail());
    dispatch(resetMobNum());
    setIsEditModalOpen(false);
  };
  const handleToastClick = () => {
    toast.info("New Feature Coming Soon", toast_position);
  };

  return (
    <>
      <div className="main-container-profile">
        {isLogoutModelOpen && (
          <LogoutModel
            onClose={() => closeLogoutModel()}
          />
        )}
        {isEditModalOpen && (
          <EditPopUp
            onClose={closeModal}
            refreshData={refreshData}
            updateRefreshData={updateRefreshData}
          />
        )}
        <div className="upperDiv">
          <div className="right-side">
            <div className="profile-icon flex">
              <img src={ProfileIcon} />
              <div className="Profile-title w-full">
                <span className="font-semibold text-lg color: #FFFFFF">{`${refreshData?.employees?.first_name} ${refreshData?.employees?.last_name}`}</span>
                <p className="horizontal-Line">Full name</p>
                <div className="upperContent">
                  <div>
                    <span>{refreshData?.employees?.user_id}</span>
                    <p>User ID</p>
                  </div>
                  <div>
                    <span>+91 {refreshData?.employees?.mob_no}</span>
                    <p>Contact number</p>
                  </div>
                </div>
                <div className="middleContent">
                  <div className="firstHalf overflow-hidden whitespace-nowrap overflow-ellipsis">
                    <Tooltip

                      sx={{ zIndex: "2147483647 !important" }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title={refreshData?.employees?.alias_name
                        ? refreshData?.employees?.alias_name
                        : "Not Given"}
                    >
                      <span>
                        {refreshData?.employees?.alias_name
                          ? refreshData?.employees?.alias_name
                          : "Not Given"}
                      </span>
                    </Tooltip>
                  </div>
                  <div>

                    <span >{refreshData?.employees?.grade}</span>
                  </div>
                </div>
                <div className="secondHalf">
                  <p>Alias</p>
                  <p>Grade</p>
                </div>
                <div className="lastContent" >
                  <div>
                    <Tooltip

                      sx={{ zIndex: "2147483647 !important" }}
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                            zIndex: "2147483647 !important",
                          },
                        },
                      }}
                      title={refreshData?.employees?.email}
                    >
                      <p style={{
                        color: "white"
                      }} id="email-id-firstLetter">
                        {refreshData?.employees?.email}
                      </p>
                    </Tooltip>
                    <p>Email ID</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="left-side">
            <img src={groupIcon} />
          </div>
        </div>
        <div className="bottomDiv">
          <div className="verifield">
            <div className="ver-detail">
              <h1>Verified Details</h1>
              <div className="detail-css">
                <span>{refreshData?.entity?.entity_pan}</span>
                <p>PAN number</p>
              </div>
              <div className="legalName">
                <span className="truncate">
                  {refreshData?.entity?.entity_name}
                </span>
                <p>Legal name</p>
              </div>
              <div className="GstNum">
                <span>Not Given</span>
                <p>GST number</p>
              </div>
            </div>
          </div>
          <div className="verifield ">
            <div className="ver-detail">
              <h1>Company Details</h1>
              <div className="detail-css">
                <span>Not Given</span>
                <p>Company name</p>
              </div>

              <div className="legalName">
                <span>Not Given</span>
                <p>CIN number</p>
              </div>
              <div className="GstNum">
                <span>Not Given</span>
                <p>DIN number</p>
              </div>
            </div>
          </div>
          <div className="verifield ">
            <div className="profileAction">
              <h1>Profile Actions</h1>
              <div className="UpperSection flex">
                <div
                  className="changePassword hover:bg-custom-cardHover"
                  onClick={handleToastClick}
                >
                  <img src={ChangePassword} />
                  <p>
                    Change <br /> password
                  </p>
                </div>
                <div
                  className="Logout hover:bg-custom-cardHover"
                  onClick={handleLogout}
                >
                  <img src={Logout} />
                  <p>
                    Logout
                  </p>
                </div>
                <div
                  className="EditProfile hover:bg-custom-cardHover"
                  onClick={handleEdit}
                >
                  <img src={Editprofile} />
                  <p>
                    Edit <br /> profile
                  </p>
                </div>
              </div>
              <div className="LowerSection flex">
                <div
                  className="changePassword hover:bg-custom-cardHover"
                  onClick={handleToastClick}
                >
                  <img src={Role} />
                  <p>Role</p>
                </div>
                <div
                  className="Logout hover:bg-custom-cardHover"
                  onClick={handleToastClick}
                >
                  <img src={Services} />
                  <p>Services</p>
                </div>
                <div
                  className="EditProfile hover:bg-custom-cardHover"
                  onClick={handleToastClick}
                >
                  <img src={Authorizeddevices} />
                  <p>
                    Authorized <br />
                    devices
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default MyProfile;
