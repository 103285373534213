import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "@mui/material";
import { approveVendorTxn } from "../../../../redux/features/approveTxn/approveTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import { VendorTxnTextMapper } from "../../../../services/VendorTxnTextMapper";
//import VendorTxnTextMapper from "../../../../services/VendorTxnTextMapper";
import formatdate from "../../../../utils/formatdate";
import info from "../../../../assets/TxnDetails/info-black-border.png";
import PopOverContainer from "../../../PopOverContainer";
import { VendorTxnDetailBannerBgMapper } from "../../../../services/VendorTxnDetailBgMapper";
import { approvalStatus } from "../../../../services/approvalStatus";
import trimSentence from "../../../../utils/trimSentence";

const TxnDetailsBanner = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleImageClick = (event) => {
    event.stopPropagation();
    handleClick(event);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className="h-full drop-shadow-2xl
      rounded-4xl
      p-6 flex flex-row justify-between"
      style={{
        background: `transparent linear-gradient(118deg, ${VendorTxnDetailBannerBgMapper(props.approval_status)[0]
          } 5%, ${VendorTxnDetailBannerBgMapper(props.approval_status)[1]
          } 60%, #ffffff 61%) 0% 0% no-repeat padding-box`,
      }}
    >
      {
        // top banner txn details - left part
      }
      <div className="flex flex-col justify-between">
        <div
          className="h-4/5
          text-white text-semibold
            text-3xl
            font-light capitalize antialiased truncate ..."
        >
          {trimSentence(props.vendor_name, 25)}
        </div>
        <div
          className="h-1/5 pb-5 pt-6
            text-sm text-gray-300 antaliased"
        >
          {props.posting_date == undefined
            ? {}
            : formatdate(props.posting_date)}
        </div>
      </div>
      {
        // top banner txn details - right part
      }
      <div className="flex w-2/6 flex-col justify-between">
        <div
          className="self-center text-center
          text-base font-bold
          capitalize flex justify-center items-center flex-wrap"
          style={{ color: approvalStatus(props.approval_status) }}
        >
          {VendorTxnTextMapper(props.approval_status)}
          {props.utr_no ? (
            <div className="text-[#707070] text-sm flex">
              &nbsp;
              <div className="w-0.5 h-5 bg-gray-300"></div>
              &nbsp;
              <span className="text-[#707070]">UTR No&nbsp;:&nbsp; </span>
              <span className="text-[#000000] font-thin">{props.utr_no}</span>
            </div>
          ) : null}
        </div>
        <div className="flex self-center flex-row mt-4 space-x-1 ...">
          <div
            className="text-green-500
            text-semibold
            text-3xl truncate ..."
          >
            ₹ {formatCurrency(props.amount)}
          </div>
          <div className="cursor-pointer" onClick={handleImageClick}>
            <img
              src={info}
              alt="info"
              className="w-3 h-3 hoverInfo cursor-pointer"
            />
          </div>
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                "border-radius": "40px",
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <PopOverContainer
              data={props.data}
              approval_status={props.approval_status}
            />
          </Popover>
        </div>
        <Box className="txnDetailsBannerRightBottom self-center mt-6">
          {
            <Box className="approveTxnDetailsBTs">
              {props.isActionable &&
                props.approval_status === "P" &&
                services?.includes("ASinglePay") ? (
                <>
                  <Button
                    className="declineBt"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(55, 63, 75)",
                      },
                    }}
                    onClick={() => {
                      props.setDeniedReason(true);
                    }}
                  >
                    <div className="p-4 text-sm">Decline</div>
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(
                        approveVendorTxn({
                          txn_id: [props.txn_id],
                          status: "Y",
                        })
                      );
                      setTimeout(() => {
                        navigate("/vendor/approval_status");
                      }, 500);
                    }}
                    className="approveBt"
                    sx={{
                      color: "#fff",
                      "&:hover": {
                        color: "rgb(55, 63, 75)",
                      },
                    }}
                  >
                    <div className="p-4 text-sm">Approve</div>
                  </Button>{" "}
                </>
              ) : props.approval_status === "Y" &&
                services?.includes("Settle_txn") ? (
                <Button
                  onClick={() => {
                    props.setSelectedTxnCarousel({
                      amt: props.amount,
                      selected: [props.txn_id],
                    });
                    props.setShowSettlementComponent(true);
                  }}
                  className="approveBt"
                  sx={{
                    color: "#fff",
                    "&:hover": {
                      color: "rgb(55, 63, 75)",
                    },
                  }}
                >
                  <div className="p-4 text-sm">Pay Now</div>
                </Button>
              ) : null}
            </Box>
          }
        </Box>
      </div>
    </div>
  );
};

export default TxnDetailsBanner;
