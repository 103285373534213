import React, { useEffect, useState } from "react";
import DeleteTxnPopUp from "../DeletePopUp";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditAccPopUp from "../EditPopup";

const EditButton = (props) => {
  const account_type = props?.account_type;
  const account_id = props?.account_id;

  const [showChip, setShowChip] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const deleteResponse = useSelector((state) => state.deleteTxn.deleteResponse);
  const [checkRes, setCheckRes] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (checkRes && deleteResponse?.errors) {
      toast.error(Object.values(deleteResponse.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && deleteResponse?.status) {
      toast.success("Account deleted successfully", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      // type to come here
      window.location.href = `/accounts/${account_type}/dashboard`;
    }
    setCheckRes(true);
  }, [deleteResponse]);

  return (
    <>
      <div
        onClick={() => setShowPopup(true)}
        onMouseEnter={() => setShowChip(true)}
        onMouseLeave={() => setShowChip(false)}
        className={`shadow-customCard relative
          flex items-center justify-center text-black bg-white cursor-pointer
          ${showChip ? "rounded-l-4xl" : "rounded-4xl"}
          p-2`}
      >
        <div className="w-4 h-4">
          <img src="../../../../assets/TxnDetails/Edit_button.png" alt="" />
        </div>
        {showChip && (
          <div className="text-xs font-bold absolute left-6 p-2 rounded-r-4xl text-gray-800 bg-white transition-all px-2">
            Edit
          </div>
        )}
      </div>
      {showPopup && (
        <EditAccPopUp account_type={account_type} account_id={account_id} setShowPopup={setShowPopup} />
      )}
    </>
  );
};

export default EditButton;
