export const VendorTxnDetailBgMapperMain = (status) => {
  switch (status) {
    case "S":
      return ["#445952", "#364943"];
      //'linear-gradient(to right, #445952 0%, #445952 66.66%, #364943 66.66%, #364943 100%)';
    case "Processing":
      return ["#57503B", "#494436"];
    case "Stalled":
      return ["#57503B", "#494436"];
    case "P":
      return ["#375151", "#364949"];
    case "Y":
      return ["#433D58", "#3A3649"];
    case "Cancelled":
      return ["#513939", "#493636"];
    case "Abandoned":
      return ["#313131", "#2A2A2A"];
    case "Expired":
      return ["#314D7E", "#314d7e"];
    case "N":
      return ["#513939", "#493636"];
    case "D":
      return ["#513939", "#493636"];
      //for accounts
    case "F":
      return ["#314D7E", "#314d7e"];
    case "Refund":
      return ["#533753", "#493649"];
      //"#DC76DC";
    default:
      return ["#513939", "#493636"];
  }
};

export const VendorTxnDetailBgMapper = (status) => {
  switch (status) {
    case "S":
      return "#445952";
    case "Processing":
      return "#57503B";
    case "Stalled":
      return "#57503B";
    case "P":
      return "#375151";
    case "Y":
      return "#433D58";
    case "Cancelled":
      return "#513939";
    case "Abandoned":
      return "#313131";
    case "Expired":
      return "#314D7E";
    case "N":
      return "#513939";
    case "D":
      return "#513939"; //for accounts
    case "F":
      return "#314D7E";
    case "Refund":
      return "#533753";
    default:
      return "#513939";
  }
};
export const VendorTxnDetailDarkBgMapper = (status) => {
  switch (status) {
    case "S":
      return "#364943";
      //"rgba(68, 89, 82, 0.9)"
    case "Processing":
      return "#494436";
      //"rgba(73, 68, 54, 0.9)";
    case "Stalled":
      return "#494436";
      //"rgba(251, 192, 45, 0.9)";
    case "P":
      return "#364949";
    //"rgba(54, 73, 73, 0.9)";
    case "Y":
      return "#3A3649";
      //"rgba(67, 61, 88, 0.9)";
    case "Cancelled":
      return "#493636";
      //"rgba(81, 57, 57, 0.9)";
    case "Abandoned":
      return "#2A2A2A";
      //"rgba(49, 49, 49, 0.9)";
    case "Expired":
      return "rgba(81, 57, 57, 0.9)";
    case "N":
      return "#493636";
      //"rgba(81, 57, 57, 0.9)";
    case "D":
      return "#493636";
      //"rgba(81, 57, 57, 0.9)"; //for accounts
    case "F":
      return "#314d7e";
      //"rgba(49, 77, 126, 0.9)";
    case "Refund":
      return "rgba(73, 54, 73, 1)";
    default:
      return "#493636";
      //"rgba(236, 236, 236, 0.9)";
  }
};
export const VendorTxnDetailChildBgMapper = (status) => {
  switch (status) {
    case "S":
      return "#4D655D";
    case "Processing":
      return "#675F46";
    case "Stalled":
      return "#675F46";
    case "P":
      return "#426161";
    case "Y":
      return "#534C6C";
    case "Cancelled":
      return "#674444";
    case "Abandoned":
      return "#404040";
    case "Expired":
      return "#674444";
    case "N":
      return "#674444";
    case "D":
      return "#513939"; //for accounts
    case "F":
      return "#314D7E";
    case "Refund":
      return "#6B476B";
    default:
      return "#674444";
  }
};

export const VendorTxnDetailBannerBgMapper = (status) => {
  switch (status) {
    case "S":
      return ["#3F675A", "#0B0F0D"];
    case "Processing":
      return ["#A39951", "#393729", "#FFFFFF"];
    case "Stalled":
      return ["#A39951", "#393729", "#FFFFFF"];
    case "P":
      return ["#5F9B9B", "#2F4D4D"];
    case "Y":
      return ["#594E7A", "#271D46"];
    case "Cancelled":
      return ["#A65757", "#3D2525"];
    case "Abandoned":
      return ["#585858", "#343434"];
    case "Expired":
      return "#314D7E";
    case "N":
      return ["#A65757", "#3D2525"];
    case "D":
      return "#513939"; //for accounts
    case "F":
      return "#314D7E";
    case "Refund":
      return "#6B476B";
    default:
      return ["#A65757", "#3D2525", "#FFFFFF"];
  }
};

export const InternalTxnDetailBannerBgMapper = (status) => {
  switch (status) {
    case "S":
      return ["#3F675A", "#0B0F0D"];;
    case "Processing":
      return ["#A39951", "#393729"];
    case "Stalled":
      return "#FBC02D";
    case "P":
      return ["#5F9B9B", "#2F4D4D"];
    case "Y":
      return ["#594E7A", "#271D46"];
    case "Cancelled":
      return ["#A65757", "#3D2525"];
    case "Abandoned":
      return ["#404040", "#131313"];
    case "Expired":
      return "#314D7E";
    case "N":
      return ["#A65757", "#3D2525"];
    case "D":
      return "#513939"; //for accounts
    case "Failed":
      return ["#A65757", "#3D2525"];
    case "Refund":
      return "#DC76DC";
    default:
      return "#ECECEC";
  }
};
