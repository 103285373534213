import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;
// Action
export const getFeatures = createAsyncThunk(
    "getFeatures",
    async (values) => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
            headers: {
                "Content-Type": "application/json",
                "X-Api-Key": sessionStorage.getItem("q2p_token"),
            },
        };

        return await fetch(
            `${baseUrl}/user_features${values?.feature_id ? "/" + values?.feature_id : ""}?page_no=${values?.page_no || 1}&page_size=${values?.page_size || 15}&search_string=${values?.search_string || ""}`,
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.log("error", error));
    }
);



const featuresSlice = createSlice({
    name: "features",
    initialState: {
        isLoading: false,
        features: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(getFeatures.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getFeatures.fulfilled, (state, action) => {
            state.isLoading = false;
            state.features = action.payload;
        });
        builder.addCase(getFeatures.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
        });
    }
});

export default featuresSlice.reducer;