import React, { useEffect, useState } from "react";
import successImage from "../../../../assets/NewImg.png";
import WhiteAwaitingVerification from "../../../../assets/Approve transactions/white_AwaitingVerification.png";
import WhitedashBoard from "../../../../assets/Approve transactions/WhiteDashboard.png";
import PendingApprover from "../../../../assets/Approve transactions/Pending yellow.png";
import blueAwaitingVerification from "../../../../assets/Approve transactions/Awaiting_Verification.png";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import Queued from "../../../../assets/Approve transactions/Queued.png";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { Link } from "react-router-dom";
import pending_yellow from "../../../../assets/Beneficiary Icons/pending_yellow.png";

const BeneficiaryMultiApprovalSuccess = () => {
  const [successPageData, setsuccessPageData] = useState([]);
  const [successPageID, setsuccessPageID] = useState([]);
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [approvedTransactions, setApprovedTransactions] = useState([]);
  const [activeTab, setActiveTab] = useState("approved");

  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      const transactions =
        storedSelectedApproval?.selectedSendTransactions || [];

      console.log("succesfull transaction", transactions);

      const pending = transactions.filter(
        (transaction) => transaction.lifecycle_status === "pending"
      );
      const approved = transactions.filter(
        (transaction) => transaction.lifecycle_status === "approved"
      );
      setsuccessPageData(storedSelectedApproval?.selectedSendTransactions);
      setPendingTransactions(pending);
      setApprovedTransactions(approved);
      setsuccessPageID(storedSelectedApproval.selectedTransactions);
    } else {
      setsuccessPageData([]);
      setsuccessPageID([]);
    }

    
  }, []);

  const TransactionList = ({ transactions, type }) => (
    <div
      className="h-[345px] w-[472px]  flex justify-center  rounded-tl-[27px] rounded-tr-[27px]"
      style={{
        overflowY: transactions.length > 5 ? "scroll" : "hidden",
      }}
    >
      <div className="w-[411px] h-[422px]    mt-[10px] ">
       
        {transactions?.map((account, i) => {
          return (
            <>
              <div className="flex   mt-[10px]   justify-between">
                <div className="flex justify-center items-center gap-[10px]">
                  <img
                    className="w-[30px] h-[30px]"
                    src={
                      type === "Approved"
                        ? Queued
                        : pending_yellow
                    }
                    alt=""
                  />
                  <div>
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={account?.beneficiary_account_name}
                    >
                      <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                        {account.beneficiary_account_name}
                      </p>
                    </Tooltip>
                    <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                      Beneficiary name
                    </span>
                  </div>
                </div>
                <div>
                  <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                    ₹ {<FormatAmount price={account?.amount} />}
                  </p>
                  {/* w-[45px] */}
                  <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                    Amount
                  </span>
                </div>
              </div>
              {i < transactions.length - 1 && (
                <div
                  style={{
                    marginTop: "10px",
                    border: "0.5px solid #4A664D",
                    height: "0px",
                    width: "411px",
                    opacity: "100",
                  }}
                ></div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-[#457549]">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 pt-48">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#457549]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={successImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">
                    Transactions have been approved. 
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 pt-[132px] pb-2">
            <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF]  rounded-[30px] items-center justify-between">
              {pendingTransactions.length > 0 &&
                approvedTransactions.length === 0 && (
                  <>
                    <div className="h-[85px] w-[472px] bg-[#55985C] rounded-tl-[27px] rounded-tr-[27px]">
                      <div className="flex justify-center mt-[8px] gap-[10px]">
                        <img
                          className="w-[30px] h-[30px]"
                          src={pending_yellow}
                          alt=""
                        />
                        <p className="w-[181px] whitespace-nowrap text-left font-semibold text-[16px] leading-[22px] tracking-[0px] text-[#F9F9F9] opacity-100">
                          {" "}
                        Awaiting approval - {pendingTransactions.length}
                       
                        </p>
                      </div>
                      <div className="flex justify-center mt-[4px]">
                        <p className="w-[284px]  text-center font-normal text-[12px] leading-[17px] tracking-[0px] text-[#DADADA] opacity-100 ">
                          It is approved and requires another user's approval
                          before settlement.
                        
                        
                        </p>
                      </div>
                    </div>

                    <div
                      className="h-[345px] w-[472px]  flex  justify-center  rounded-tl-[27px] rounded-tr-[27px]"
                      style={{
                        overflowY:
                          pendingTransactions.length > 5 ? "scroll" : "hidden",
                      }}
                    >
                      <div className="w-[411px] h-[422px]    mt-[10px] ">
                        {pendingTransactions?.map((account, i) => {
                          return (
                            <>
                              <div className="flex   mt-[10px]   justify-between">
                                <div className="flex justify-center items-center gap-[10px]">
                                  <img
                                    className="w-[30px] h-[30px]"
                                    src={pending_yellow}
                                    alt=""
                                  />
                                  <div>
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account?.beneficiary_account_name}
                                    >
                                      <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                                        {account.beneficiary_account_name}
                                      </p>
                                    </Tooltip>
                                    <span className="flex items-start   w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                      Beneficiary name
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                                    ₹ {<FormatAmount price={account?.amount} />}
                                  </p>

                                  <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                    Amount
                                  </span>
                                </div>
                              </div>
                              {i < pendingTransactions.length - 1 && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    border: "0.5px solid #4A664D",
                                    height: "0px",
                                    width: "411px",
                                    opacity: "100",
                                  }}
                                ></div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

              {approvedTransactions.length > 0 &&
                pendingTransactions.length === 0 && (
                  <>
                    <div className="h-[77px] w-[472px]  bg-[#55985C] rounded-tl-[27px] rounded-tr-[27px]">
                      <div className="flex justify-center mt-[14px] gap-[10px]">
                        
                        <p className="w-[181px] whitespace-nowrap text-left font-semibold text-[16px] leading-[22px] tracking-[0px] text-[#F9F9F9] opacity-100">
                          {" "}
                       
                         Distribution created - {approvedTransactions.length}
                        </p>
                      </div>
                      <div className="flex justify-center mt-[8px]">
                        <p className="w-[284px]  text-center font-normal whitespace-nowrap mr-8 text-[12px] leading-[17px] tracking-[0px] text-[#DADADA] opacity-100">
                        
                        The transaction has been approved and is set for distribution.
                        </p>
                      </div>
                    </div>

                    <div
                      className="h-[345px] w-[472px]  flex  justify-center  rounded-tl-[27px] rounded-tr-[27px]"
                      style={{
                        overflowY:
                          successPageData.length > 5 ? "scroll" : "hidden",

                        paddingBottom: "10px",
                      }}
                    >
                      
                      <div
                        className="w-[411px]     mt-[10px] "
                        style={{
                          height: "fit-content",
                          paddingBottom: "5px",
                        }}
                      >
                        {successPageData?.map((account, i) => {
                          return (
                            <>
                              <div className="flex   mt-[10px]   justify-between">
                                <div className="flex justify-center items-center gap-[10px]">
                                  <img
                                    className="w-[30px] h-[30px]"
                                    src={Queued}
                                    alt=""
                                  />
                                  <div>
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account?.beneficiary_account_name}
                                    >
                                      <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                                        {account.beneficiary_account_name}
                                      </p>
                                    </Tooltip>
                                    <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                      Beneficiary name
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <p className="min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#69B76F] opacity-100">
                                    ₹ {<FormatAmount price={account?.amount} />}
                                  </p>

                                  <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                    Amount
                                  </span>
                                </div>
                              </div>
                              {i < successPageData.length - 1 && (
                                <div
                                  style={{
                                    marginTop: "10px",
                                    border: "0.5px solid #4A664D",
                                    height: "0px",
                                    width: "411px",
                                    opacity: "100",
                                  }}
                                ></div>
                              )}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

              {approvedTransactions.length > 0 &&
                pendingTransactions.length > 0 && (
                  <>
                    <div className="h-[93px] w-[472px] bg-[#4C8452] rounded-tl-[27px] rounded-tr-[27px]">
                      <div className=" flex justify-between">
                        <button
                          onClick={() => setActiveTab("approved")}
                          className={
                            activeTab === "approved"
                              ? "activeone"
                              : "tabbutton1"
                          }
                        >
                          <h5 className="w-[136px] h-[17px]   whitespace-nowrap text-left  font-semibold text-[13px] leading-[17px] text-[#F9F9F9] opacity-100">
                             Distribution created  -{" "}
                            {approvedTransactions.length}
                          </h5>
                          <p className="w-[204px] h-[34px] text-center font-normal text-[12px] leading-[14px] text-[#DADADA] opacity-100">
                            The transaction has been approved and is set for distribution.
                          </p>
                        </button>
                        <button
                          onClick={() => setActiveTab("pending")}
                          className={
                            activeTab === "pending" ? "activetwo" : "tabbutton2"
                          }
                        >
                          <h5 className="h-[17px] whitespace-nowrap  font-semibold text-[13px] leading-[17px] text-[#F9F9F9] opacity-100">
                            Awaiting approval - {pendingTransactions.length}
                          </h5>
                          <p className="w-[204px] h-[34px] text-center text-[12px] leading-[14px] text-[#DADADA] opacity-100 font-normal tracking-normal">
                            It is approved and requires another user's approval
                            before distribution.
                          </p>
                        </button>
                      </div>
                    </div>

                    {activeTab === "approved" ? (
                      <TransactionList
                        transactions={approvedTransactions}
                        type={"Approved"}
                      />
                    ) : (
                      <TransactionList
                        transactions={pendingTransactions}
                        type={"Pending"}
                      />
                    )}
                  </>
                )}

              <div className="flex justify-center items-center h-[136px] w-[472px] bg-[#55985C] rounded-bl-[27px] rounded-br-[27px]">
                <Link to={"/beneficiary_transactions"}>
                  <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                    <img
                      className="w-[40px]  h-[40px] "
                      src={WhitedashBoard}
                      alt=""
                    />
                    <p className="w-[62px] h-[32px] text-center font-semibold text-[12px] leading-[15px] tracking-[0px] text-[#FFFFFF] opacity-100">
                      Go to dashboard
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryMultiApprovalSuccess;
