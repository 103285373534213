import NoDataImg from "../../../../assets/TransactionDashboardIcons/Nothing.png";
import { Link } from "react-router-dom";
import AccountCardViewAllCard from "../../../../components/Accounts/Cards/ViewAll";
import accTransform from "../../../../utils/accTransform";

const AccountCardViewAllBody = ({ viewAllData }) => {
  const emptyAccountListComponent = () => {
    return (
      <div className="flex flex-col justify-center items-center gap-5 text-center overflow-hidden h-full">
        <div className=" text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
          No cards found <br />
          All cards will appear here.
        </div>
        <div className="w-2/6 p-4 flex flex-row justify-center">
          <img
            className="h-full w-full"
            src={NoDataImg} alt="NoDataImg" />
        </div>
      </div>
    );
  }

  const cardTypePngPath = (key) => {
    if (key === "Mastercard")
      return "MC"
    else if (key === "Amex")
      return 'AmEx';
    else if (key === "Amex")
      return 'Diners';
    else if (key === "Diners")
      return 'AmEx';
    else if (key === "Rupay")
      return 'RuPay';
    else if (key === "Jcb")
      return 'JCB';
    return key;
  }

  const validFromTimestampToDate = (timestamp) => {
    const date = new Date(parseInt(timestamp) * 1000);
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  const accountViewAllComponents = () => {
    if (Object.keys(viewAllData).length === 0) {
      return emptyAccountListComponent();
    } else {
      return (
        <div className="
          grid gap-4 lg:gap-6 4xl:gap-16
          grid-cols-1
          md:grid-cols-2
          xl:grid-cols-4
          4xl:grid-cols-5
          6xl:grid-cols-6
          justify-items-center">
          {
            viewAllData.map((account) => (
              <div className="w-full min-h-full">
                <Link
                  to={`/account/vendor/details/${account.vendor_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <AccountCardViewAllCard
                    name={account.name_on_card}
                    card_number={`${accTransform(account.ending_with, true)}`}
                    scheme_logo={cardTypePngPath(account.card_type)}
                    valid_from={validFromTimestampToDate(account.activate_date)}
                    valid_to={`${account.expiry_date_month < 10 ? "0" : ""}${account.expiry_date_month}/20${account.expiry_date_year}`}
                    status={account.status}
                  />
                </Link>
              </div>
            ))
          }
        </div>
      );
    }
  }

  return accountViewAllComponents();
}

export { AccountCardViewAllBody };

