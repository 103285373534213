import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../../Authorization";

const baseUrl = env.REACT_APP_BASE_URL;
// Action
export const getUsers = createAsyncThunk("getUsers", async (values) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      // "X-Api-Key": sessionStorage.getItem("q2p_token"),
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
  };

  return await fetch(
    `${baseUrl}/users${values?.user_id ? "/" + values?.user_id : ""}?page_no=${
      values?.page_no || 1
    }&page_size=${values?.page_size || 15}&search_string=${
      values?.search_string || ""
    }`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => console.log("error", error));
});

export const getSpecificUser = createAsyncThunk(
  "getSpecificUser",
  async (id) => {
    try {
      const response = await fetch(`${baseUrl}/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        }
      } );

      return response.json();
    } catch (error) {
      toast.error(error, toast_position);
      console.error("Error", error);
      throw error;
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    users: null,
    isError: false,

    specificUserData: null,
    specificUserLoading: null,
    specificUserError: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
      builder.addCase(getSpecificUser.pending, (state, action) => {
      state.specificUserLoading = true;
      });
        builder.addCase(getSpecificUser.fulfilled, (state, action) => {
      state.specificUserLoading = false;
      state.specificUserData = action.payload;
        });
        builder.addCase(getSpecificUser.rejected, (state, action) => {
      state.specificUserError = true;
      state.specificUserData = null;
    });
  },
});

export default usersSlice.reducer;
