import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectAcc from "../../../assets/CreateTransactionIcons/Select account.png";
import loadingLogo from "../../../assets/Approve transactions/inputloder.gif";

import {
  verifyThisPAN,
  verifyThisBank,
} from "../../../redux/features/verifyPAN/verifyPAN";
import verifyLogo from "../../../assets/CreateTransactionIcons/Verified.png";
import skippedVerification from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";

import "./index.css";
import trimSentence from "../../../utils/trimSentence";
import Validation from "../../../utils/validation";
import { toast } from "react-toastify";
import { bankLogoMapper } from "../../../services/bankLogoMapper";
import { all } from "axios";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  RadioButtonUncheckedOutlined,
  RadioButtonUncheckedRounded,
  RadioButtonUncheckedSharp,
  RadioButtonUncheckedTwoTone,
} from "@mui/icons-material";
import { getFFLimits } from "../../../redux/features/ffLimits/ffLimits";
import formatCurrency from "../../../utils/CurrencyFormater";

const RegisterNewVedor = (props) => {
  const panStatus = useSelector((state) => state.verify.verifyPAN);
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const panStatusLoading = useSelector(
    (state) => state.verify.verifyPANisLoading
  );
  const bankStatusLoading = useSelector(
    (state) => state.verify.verifyBankisLoading
  );
  const [panVerificationStatus, setPanVerificationStatus] = useState(false);
  const [bankVerificationStatus, setBankVerificationStatus] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [gstinValidation, setGstinValidation] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const limitApiRes = useSelector((state) => state.ffLimits.limit);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;
  const [customLimit, setCustomLimit] = useState(
    props.registerNewVnerdorData.txn_limit === limitApiRes?.limits?.maximum
      ? false
      : true
  );

  const dispatch = useDispatch();
  const disabledToggle = Boolean(props.disabledToggle);

  useEffect(() => {
    if (props.registerNewVnerdorData.txn_limit === undefined) {
      setCustomLimit(false);
      props.setRegisterNewVnerdorData({
        ...props.registerNewVnerdorData,
        txn_limit: limitApiRes?.limits?.maximum,
      });
    }
  }, [limitApiRes]);

  const modifyValue = (type, value) => {
    if (
      type === "pan_no" ||
      type === "bank_acc_no" ||
      type === "ifsc_code" ||
      type === "gstin"
    ) {
      return value?.toUpperCase();
    }
    if (type === "email_id") {
      return value?.toLowerCase();
    }
    return value;
  };

  const allowedValue = (name, value) => {
    if (name === "pan_no") return Validation.allowedPAN(value);
    if (name === "bank_acc_no") return Validation.allowedBankAccNum(value);
    if (name === "ifsc_code") return Validation.allowedBankIfsc(value);
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    if (name === "gstin") return Validation.allowedGstin(value);
    if (name === "txn_limit") return Validation.allowedAmountLimit(value);
    return true;
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "pan_no") errors = Validation.validatePan(name, value, errors);
    if (name === "ifsc_code")
      errors = Validation.validateIfsc(name, value, errors);
    if (name === "bank_acc_no")
      errors = Validation.validateBankAcc(name, value, errors);
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    if (name === "txn_limit")
      errors = Validation.validateAmountLimit(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = modifyValue(name, value);
    if (allowedValue(name, value)) {
      validateAndRecordError(name, value);
      const newObj = { ...props.registerNewVnerdorData, [name]: value };
      props.setRegisterNewVnerdorData(newObj);
    }
    if (
      props.registerNewVnerdorData.pan_no.length === 0 ||
      props.registerNewVnerdorData.pan_no.length < 10
    ) {
      setPanVerificationStatus(false);
    }
    if (props.registerNewVnerdorData.bank_acc_no.length === 0) {
      setBankVerificationStatus(false);
    }

    if (
      props.registerNewVnerdorData.ifsc_code.length === 0 ||
      props.registerNewVnerdorData.ifsc_code.length < 11
    ) {
      setBankVerificationStatus(false);
    }
    if (
      props.registerNewVnerdorData.contact_no.length === 0 ||
      props.registerNewVnerdorData.contact_no.length < 10
    ) {
      setContactNumberValidation(false);
    }
    if (props.registerNewVnerdorData.email_id.length === 0) {
      setEmailValidation(false);
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (!errorMessage && name === "ifsc_code" && bankValuesFilled())
      errorMessage = customErrors["bank_acc_no"];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }

    Object.entries(errorMessage).forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };

  useEffect(() => {
    if (bankStatus?.errors) {
      let errors = { ...customErrors };
      errors["bank_acc_no"] = bankStatus.errors;
      setCustomErrors(errors);
      setBankVerificationStatus(false);
    }

    if (bankStatus?.status === true) {
      let errors = { ...customErrors };
      delete errors["bank_acc_no"];
      setCustomErrors(errors);
      setBankVerificationStatus(true);
    }
  }, [bankStatus]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no.length > 0 &&
      panStatus?.status === true
    ) {
      let errors = { ...customErrors };
      delete errors["pan_no"];
      setCustomErrors(errors);
      setPanVerificationStatus(true);
    }

    if (props.registerNewVnerdorData.pan_no.length > 0 && panStatus?.errors) {
      let errors = { ...customErrors };
      errors["pan_no"] = panStatus.errors;
      setCustomErrors(errors);
      setPanVerificationStatus(false);
    }
  }, [panStatus]);

  useEffect(() => {
    if (customErrors["pan_no"]) setPanVerificationStatus(false);
    if (customErrors["bank_acc_no"]) setBankVerificationStatus(false);
    if (customErrors["ifsc_code"]) setBankVerificationStatus(false);
    if (customErrors["contact_no"]) setContactNumberValidation(false);
    if (
      !customErrors["contact_no"] &&
      props.registerNewVnerdorData.contact_no.length === 10
    )
      setContactNumberValidation(true);
    if (customErrors["email_id"]) setEmailValidation(false);
    if (
      !customErrors["email_id"] &&
      props.registerNewVnerdorData.email_id.length > 0 &&
      emailRegex.test(props.registerNewVnerdorData.email_id)
    )
      setEmailValidation(true);
  }, [customErrors]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bankValuesFilled = () => {
    return (
      props.registerNewVnerdorData.bank_acc_no.length >= 4 &&
      props.registerNewVnerdorData.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.registerNewVnerdorData.ifsc_code)
    );
  };

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no?.length === 10 &&
      !customErrors["pan_no"]
    ) {
      dispatch(
        verifyThisPAN({ pan_number: props.registerNewVnerdorData.pan_no })
      );
    }
  }, [props.registerNewVnerdorData.pan_no]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.bank_acc_no.length >= 4 &&
      props.registerNewVnerdorData.ifsc_code.length === 11 &&
      /^[A-Z]{4}0[A-Z0-9]{6}$/.test(props.registerNewVnerdorData.ifsc_code)
    ) {
      dispatch(
        verifyThisBank({
          ifsc_code: props.registerNewVnerdorData.ifsc_code,
          bank_acc_no: props.registerNewVnerdorData.bank_acc_no,
        })
      );
    }
  }, [
    props.registerNewVnerdorData.ifsc_code,
    props.registerNewVnerdorData.bank_acc_no,
  ]);

  useEffect(() => {
    if (!disabledToggle) {
      if (props.registerNewVnerdorData.pan_no.length !== 10) {
        const newObj = {
          ...props.registerNewVnerdorData,
          name: "",
        };
        props.setRegisterNewVnerdorData(newObj);
      }
    }
  }, [props.registerNewVnerdorData.name]);

  useEffect(() => {
    if (
      props.registerNewVnerdorData.pan_no.length === 0 ||
      props.registerNewVnerdorData.bank_acc_no.length === 0 ||
      props.registerNewVnerdorData.ifsc_code.length === 0
    ) {
      props.enableNextButton(false);
      return;
    }
    if (
      panVerificationStatus &&
      (bankVerificationStatus || bankValuesFilled()) &&
      props.linkUser
    ) {
      const newObj = {
        ...props.registerNewVnerdorData,
        name: panStatus?.pan_details.full_name,
        skip_account_verification:
          bankStatus?.verification_details &&
          bankStatus?.verification_details[0].account_exists === "Y"
            ? true
            : false,
      };
      props.setRegisterNewVnerdorData(newObj);
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [
    panVerificationStatus,
    bankVerificationStatus,
    contactNumberValidation,
    emailValidation,
    props.registerNewVnerdorData.pan_no,
    props.registerNewVnerdorData.ifsc_code,
    props.registerNewVnerdorData.bank_acc_no,
    props.registerNewVnerdorData.contact_no,
    props.registerNewVnerdorData.email_id,
  ]);

  useEffect(() => {
    dispatch(getFFLimits());
  }, []);

  // this needs to be checked

  const getInputElementDiv = (name, status, label, value, maxLength, skip) => {
    return (
      <div className="w-full flex flex-row gap-2 xl:gap-4 2xl:gap-6 3xl:gap-8 4xl:gap-10 5xl:gap-12 items-center self-center">
        <div className="w-4 xl:w-6 3xl:w-8 5xl:w-10">
          {name === "contact_no" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 512"
            >
              <g id="_552489" data-name="552489" transform="translate(0 0)">
                <ellipse
                  id="Ellipse_59"
                  data-name="Ellipse 59"
                  cx="256"
                  cy="256"
                  rx="256"
                  ry="256"
                  transform="translate(0 0)"
                  fill="#fff"
                />
                <path
                  id="Path_626"
                  data-name="Path 626"
                  d="M348.054,299.359a23.9,23.9,0,0,0-26.948.071l-11.679,8.016a36.426,36.426,0,0,1-42.812-1.18,399.631,399.631,0,0,1-33.713-29.1,399.181,399.181,0,0,1-29.1-33.713,36.426,36.426,0,0,1-1.18-42.812l8.016-11.679a23.907,23.907,0,0,0,.07-26.948l-33.431-49.267a32.411,32.411,0,0,0-41.793-10.561l-.009,0a62.791,62.791,0,0,0-28.317,81.262,452.176,452.176,0,0,0,76.987,118.682l23.79,23.79A452.23,452.23,0,0,0,326.616,402.91a62.792,62.792,0,0,0,81.262-28.317l0-.009a32.411,32.411,0,0,0-10.561-41.793Z"
                  transform="translate(4.84 3.715)"
                  fill="#9f9f9f"
                />
              </g>
            </svg>
          )}
          {name === "name" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 512"
            >
              <path
                id="Path_2074"
                data-name="Path 2074"
                d="M257,224.429A109.714,109.714,0,1,0,147.286,114.714,109.256,109.256,0,0,0,257,224.429Zm0,73.143c-85.211,0-256,42.789-256,128V517H513V425.572C513,340.36,342.211,297.571,257,297.571Z"
                transform="translate(-1 -5)"
                fill="#fafafa"
              />
            </svg>
          )}
          {name === "email_id" && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 512 392.778"
            >
              <g id="iconfinder_gmail_4975304" transform="translate(-0.001 0)">
                <g
                  id="Group_14"
                  data-name="Group 14"
                  transform="translate(33.38 0)"
                >
                  <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M484.973,122.808,452.288,453.324H59.712L33.379,129.205,256,254.723Z"
                    transform="translate(-33.379 -60.548)"
                    fill="#f2f2f2"
                  />
                  <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M473.886,60.983,256,267.1,38.114,60.983H473.886Z"
                    transform="translate(-33.379 -60.983)"
                    fill="#f2f2f2"
                  />
                </g>
                <path
                  id="Path_210"
                  data-name="Path 210"
                  d="M59.712,155.8V453.4H24.139A24.229,24.229,0,0,1,0,429.092V111.967l39,1.07Z"
                  transform="translate(0.001 -60.625)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_211"
                  data-name="Path 211"
                  d="M512,111.988V429.115a24.234,24.234,0,0,1-24.154,24.309H452.288V155.82l19.692-46.852Z"
                  transform="translate(0 -60.646)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_212"
                  data-name="Path 212"
                  d="M512,85.292v27.034l-59.712,43.832L256,300.232,59.712,156.158,0,112.326V85.292A24.23,24.23,0,0,1,24.139,60.983H38.114L256,220.909,473.886,60.983h13.962A24.232,24.232,0,0,1,512,85.292Z"
                  transform="translate(0.001 -60.983)"
                  fill="#9f9f9f"
                />
                <path
                  id="Path_213"
                  data-name="Path 213"
                  d="M59.712,155.8,0,146.476V111.967Z"
                  transform="translate(0.001 -60.625)"
                  fill="#9f9f9f"
                />
              </g>
            </svg>
          )}
        </div>
        <div
          onClick={() => props.handleOpen("linkUser")}
          className="w-2/3 cursor-pointer py-2 text-white text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl"
        >
          <div
            className={`w-full flex flex-col
          antaliased 
          ${
            name === "name"
              ? "text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl font-semibold"
              : "text-lg"
          } ${name === "name" ? "text-custom-orange" : "text-white"}`}
          >
            <div className="w-full flex gap-5 self-start items-center">
              <div className="w-full truncate ... ">
                {value}
                {name === "name" && (
                  <div className="text-orange inline-flex px-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-10 5xl:h-10"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="text-white text-lg opacity-75">{label}</div>
          </div>
        </div>
      </div>
    );
  };

  const getPanVerifiedDiv = (
    name,
    status,
    isLoading,
    label,
    value,
    maxLength
  ) => {
    return (
      <div
        className={`
        ${value.length === 10 && !isLoading && status && "-mx-1"}
        min-w-full flex flex-row`}
      >
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: { maxLength },
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
                {value.length === 10 &&
                  !isLoading &&
                  (status ? (
                    <img
                      src={verifyLogo}
                      alt="verified logo"
                      className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ) : (
                    <img
                      src={skippedVerification}
                      alt="verified logo"
                      onClick={() => {
                        showErrors(name);
                      }}
                      className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    />
                  ))}
              </InputAdornment>
            ),
          }}
          label={label}
          variant="standard"
          name={name}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getBankVerifiedElementDiv = (isLoading, bankDetails, ifscDetails) => {
    return (
      <div
        className={`min-w-full flex flex-col self-center gap-2
        ${bankValuesFilled() && "-mx-1"}`}
      >
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: bankDetails.maxLength,
            readOnly: props.activeStep === 2,
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: props.activeStep === 2,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}
                {!isLoading &&
                bankVerificationStatus &&
                bankDetails.value?.length >= 4 ? (
                  <img
                    src={verifyLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
                {!isLoading &&
                bankDetails.value?.length > 0 &&
                customErrors["bank_acc_no"] ? (
                  <img
                    src={skippedVerification}
                    onClick={() => {
                      showErrors(bankDetails.name);
                    }}
                    alt="verified logo"
                    className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
              </InputAdornment>
            ),
          }}
          label={bankDetails.label}
          variant="standard"
          name={bankDetails.name}
          onChange={handleChange}
          value={bankDetails.value}
          readOnly={disabledToggle}
          autoComplete="off"
        />
        <TextField
          className="w-full antialiased text-xs"
          inputProps={{
            style: {
              color: "white",
              borderBottom: "1px solid grey",
            },
            maxLength: ifscDetails.maxLength,
            readOnly: props.activeStep === 2,
          }}
          InputLabelProps={{
            style: {
              color: "#C9C9C9",
              fontFamily: "Open Sans",
              fontWeight: 100,
              fontSize: "1.0 rem",
            },
          }}
          InputProps={{
            disableUnderline: true,
            readOnly: props.activeStep === 2,
            endAdornment: (
              <InputAdornment position="end">
                {isLoading && (
                  <img
                    src={loadingLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                )}

                {!isLoading &&
                bankVerificationStatus &&
                ifscDetails.value?.length === 11 ? (
                  <img
                    src={verifyLogo}
                    alt="verified logo"
                    className="w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
                {!isLoading &&
                ((ifscDetails.value?.length <= 11 &&
                  customErrors["ifsc_code"] &&
                  ifscDetails.value?.length > 0) ||
                  (bankValuesFilled() && customErrors["bank_acc_no"])) ? (
                  <img
                    src={skippedVerification}
                    onClick={() => {
                      showErrors(ifscDetails.name);
                    }}
                    alt="verified logo"
                    className="cursor-pointer w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                  />
                ) : null}
              </InputAdornment>
            ),
          }}
          label={ifscDetails.label}
          variant="standard"
          name={ifscDetails.name}
          onChange={handleChange}
          value={ifscDetails.value}
          readOnly={disabledToggle}
          autoComplete="off"
        />
      </div>
    );
  };

  const panVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        <div
          className={`min-w-full
          ${
            panVerificationStatus &&
            props.registerNewVnerdorData?.pan_no?.length > 0 &&
            "border-4 border-custom-lightGreen"
          }
          flex justify-center py-1
          rounded-l-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {
              // Verified Vendor Pan Number Div
              getPanVerifiedDiv(
                "pan_no",
                panVerificationStatus,
                panStatusLoading,
                "Agent's PAN number",
                props.registerNewVnerdorData.pan_no,
                10
              )
            }
          </div>
        </div>
        {panVerificationStatus &&
          props.registerNewVnerdorData?.pan_no?.length > 0 && (
            <div
              className="bg-custom-lightGreen rounded-r-large 4xl:rounded-r-customXl 5xl:rounded-r-custom2Xl
            flex w-full p-2 flex-row justify-between gap-2"
              style={{
                marginRight: "-80%",
              }}
            >
              <div className="w-[63%] flex flex-row">
                <div className="flex flex-row h-full w-[90%]">
                  <div
                    className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                  >
                    {trimSentence(
                      props.registerNewVnerdorData.name
                        ? props.registerNewVnerdorData.name.toLowerCase()
                        : panStatus?.pan_details.full_name.toLowerCase(),
                      25
                    )}
                  </div>
                </div>
              </div>
              <div className="w-[30%] flex flex-row justify-end">
                <div
                  className="self-center
                antaliased text-white text-xs "
                >
                  Legal name
                </div>
              </div>
              <div className="flex flex-row h-full w-[7%]">
                <img
                  className="self-center p-1"
                  src={verifyLogo}
                  alt="verified logo"
                />
              </div>
            </div>
          )}
      </div>
    );
  };

  const bankVerificationDiv = () => {
    return (
      <div
        className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
      >
        <div
          className={`min-w-full
          ${
            bankValuesFilled() &&
            bankVerificationStatus &&
            "border-4 border-custom-lightGreen"
          }
          ${
            bankValuesFilled() &&
            !bankVerificationStatus &&
            bankStatus?.errors &&
            "border-4 border-custom-red"
          }
          flex justify-center py-1
          rounded-l-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
        >
          <div className="min-w-full">
            {getBankVerifiedElementDiv(
              bankStatusLoading,
              {
                name: "bank_acc_no",
                status: bankVerificationStatus,
                label: "Bank account number",
                value: props.registerNewVnerdorData.bank_acc_no,
                maxLength: 15,
              },
              {
                name: "ifsc_code",
                status: bankVerificationStatus,
                label: "Bank IFSC code",
                value: props.registerNewVnerdorData.ifsc_code,
                maxLength: 11,
              }
            )}
          </div>
        </div>
        {bankValuesFilled() &&
        bankVerificationStatus &&
        !customErrors["bank_acc_no"] &&
        bankStatus?.verification_details?.length > 0 ? (
          <div
            className="bg-custom-lightGreen rounded-r-4xl 4xl:rounded-r-large 5xl:rounded-r-customXl
            flex w-full p-2 flex-col justify-between overflow-hidden"
            style={{
              marginRight: "-80%",
            }}
          >
            <div className="flex flex-row gap-2">
              <div className="w-[63%] flex flex-row">
                <div className="flex flex-row h-full w-[90%]">
                  <div
                    className="w-full
                    antaliased font-[100] text-white capitalize self-center
                    text-sm 3xl:text-base 5xl:text-lg truncate ..."
                  >
                    {bankStatus?.verification_details[0].legal_name.toLowerCase()}
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-end w-[30%]">
                <div
                  className="self-center
                antaliased text-white text-xs "
                >
                  Account holders name
                </div>
              </div>
              <div className="flex flex-row h-full w-[7%]">
                <img
                  className="self-center p-1"
                  src={verifyLogo}
                  alt="verified logo"
                />
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <div
                className="w-1/2 -m-2
                flex justify-end"
              >
                <div
                  className="w-2/3
                  drop-shadow-2xl
                  text-center p-2 flex flex-row justify-center
                  bg-white rounded-tl-large"
                >
                  <img
                    className="w-2/3"
                    src={require("../../../assets/Banks/" +
                      bankLogoMapper(props.registerNewVnerdorData.bank_name) +
                      ".png")}
                    alt="BankLogo"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {bankValuesFilled() && !bankVerificationStatus && bankStatus?.errors ? (
          <div
            className="
            w-full 3xl:-mr-96"
            style={{
              marginRight: "-80%",
            }}
          >
            <div className="flex flex-col h-full">
              <div className="h-full bg-custom-red flex flex-row overflow-hidden rounded-r-4xl 4xl:rounded-r-large 5xl:rounded-r-customXl">
                <div className="p-2 flex flex-row gap-2">
                  <div className="flex flex-col h-full w-[7%]">
                    <img
                      className="p-1"
                      src={skippedVerification}
                      alt="verified logo"
                    />
                  </div>

                  <div
                    className="h-full flex flex-col justify-between p-1
                     text-white text-base"
                  >
                    <div className="text-md font-extrabold">
                      SKIPPING VERIFICATION
                    </div>
                    <div className="flex font-normal">
                      •{" "}
                      <p className="ml-4 font-bold">
                        Account could not be verified
                      </p>
                    </div>
                    <div className="flex font-normal">
                      •{" "}
                      <p className="ml-4 font-bold">
                        Crosscheck details and proceed with caution
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="-mb-48 flex flex-row justify-center w-full text-white px-2">
              <div className="bg-custom-dark-orange w-[85%] rounded-b-large p-4">
                <div className="capitalize px-2 text-base italic font-semibold">
                  BANK REMARK:
                </div>
                <div className="text-xs px-2 3xl:text-sm">
                  IMPS maybe currently disabled for this account or this account
                  may not exist.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  return (
    <div
      className="p-2 h-full bg-custom-blue flex flex-col
      drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl gap-4 2xl:gap-6 4xl:gap-8"
    >
      <div>
        <div
          className="-m-2 bg-custom-card
        rounded-t-large 4xl:rounded-t-customXl 5xl:rounded-t-custom2Xl
        flex flex-row justify-between
        p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18"
        >
          <div
            className="text-white
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
          >
            Agent details
          </div>
          <div className="flex flex-row gap-2">
            <div className="self-center">
              <img
                src={selectAcc}
                className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
              />
            </div>
            <div
              className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
              onClick={() => {
                props.handleOpen();
              }}
            >
              Select field force agent
            </div>
          </div>
        </div>
        <div
          className="-m-2
          h-full bg-custom-lightBlue rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl flex flex-col
          drop-shadow-xl
          gap-4 2xl:gap-6 4xl:gap-8"
        >
          <div
            className="bg-custom-card drop-shadow-xl h-full
          rounded-b-large 4xl:rounded-b-customXl 5xl:rounded-b-custom2Xl
          flex flex-col px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4"
          >
            <div className="w-full">{panVerificationDiv()}</div>
          </div>
          <div
            className="w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-2"
          >
            {bankVerificationDiv()}
          </div>
        </div>
      </div>

      <div className="-mx-2 mt-2">
        <div
          className=" w-full flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24 pb-4
          gap-6"
        >
          {panVerificationStatus && panStatus?.pan_details?.full_name ? (
            <div className="w-full flex flex-col">
              <div className="text-white capitalize">
                {panStatus?.pan_details.full_name}
              </div>
              <div className="text-[#C9C9C9] text-xs antialiased">
                Agent name - Using legal name
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {props.linkUser ? (
            <div className="flex flex-col gap-2">
              <div
                className="min-w-full flex flex-row
        -ml-4 md:-ml-6 lg:-ml-8 xl:-ml-10 4xl:-ml-24
        -mr-4 md:-mr-6 lg:-mr-8 xl:-mr-10 4xl:-mr-24"
              >
                <div
                  className={`min-w-full
                border-4 border-[#AFBACF]
          flex justify-center py-1
          rounded-l-3xl px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24`}
                >
                  <div className="min-w-full py-10">
                    {getInputElementDiv(
                      "name",
                      contactNumberValidation,
                      "Agent name",
                      props.linkUser.first_name +
                        " " +
                        props.linkUser.last_name,
                      10
                    )}
                    {getInputElementDiv(
                      "contact_no",
                      gstinValidation,
                      "Contact number",
                      "+91 " + props.linkUser.mob_no,
                      20,
                      true
                    )}
                    {getInputElementDiv(
                      "email_id",
                      emailValidation,
                      "Email-ID",
                      props.linkUser.email_id,
                      20
                    )}
                  </div>
                </div>
                {
                  <div
                    className="bg-[#AFBACF] rounded-r-large 4xl:rounded-r-customXl 5xl:rounded-r-custom2Xl
            flex w-full p-2 flex-row justify-center gap-2"
                    style={{
                      marginRight: "-80%",
                    }}
                  >
                    <div className="w-full flex flex-col gap-5 justify-center py-6">
                      <div className="text-white font-semibold text-2xl">
                        User transaction limit
                      </div>
                      {((customLimit && props?.activeStep === 2) ||
                        props?.activeStep !== 2) && (
                        <div
                          onClick={() => {
                            setCustomLimit(true);
                          }}
                          style={{
                            borderColor:
                              customLimit && props?.activeStep !== 2
                                ? "#4CB537"
                                : "#AFBACF",
                          }}
                          className="flex cursor-pointer border-4 flex-row rounded-xl bg-[#3A5686] justify-between items-center h-2/5 p-4 w-full"
                        >
                          {props.activeStep !== 2 ? (
                            <TextField
                              className="w-full antialiased text-xs"
                              inputProps={{
                                style: {
                                  color: "white",
                                  borderBottom: "1px solid white",
                                },
                                maxLength: 10,
                                readOnly: props?.activeStep === 2,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: "white",
                                  fontFamily: "Open Sans",
                                  fontWeight: 100,
                                  fontSize: "1.0 rem",
                                },
                              }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              label="Custom 24 hour transaction limit"
                              variant="standard"
                              onChange={(e) => {
                                props.setRegisterNewVnerdorData({
                                  ...props.registerNewVnerdorData,
                                  txn_limit: e.target.value,
                                });
                              }}
                              value={
                                customLimit
                                  ? props.registerNewVnerdorData.txn_limit
                                  : ""
                              }
                              name="txn_limit"
                              autoComplete="off"
                            />
                          ) : (
                            <div className="flex flex-col">
                              <div className="text-white italic font-bold text-2xl">
                                ₹{" "}
                                {formatCurrency(
                                  props.registerNewVnerdorData?.txn_limit
                                )}
                              </div>
                              <div className="text-white ">
                                {" "}
                                Use the custom 24 hour transaction limit{" "}
                              </div>
                            </div>
                          )}
                          {props.activeStep !== 2 && (
                            <div>
                              {customLimit ? (
                                <RadioButtonChecked sx={{ color: "#4CB537" }} />
                              ) : (
                                <RadioButtonUnchecked
                                  sx={{ color: "#4CB537" }}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                      <div
                        style={{
                          borderColor:
                            !customLimit && props?.activeStep !== 2
                              ? "#4CB537"
                              : "#AFBACF",
                        }}
                        onClick={() => {
                          setCustomLimit(false);
                          props.setRegisterNewVnerdorData({
                            ...props.registerNewVnerdorData,
                            txn_limit: limitApiRes?.limits?.maximum,
                          });
                        }}
                        className="flex cursor-pointer border-4 flex-row rounded-xl bg-[#3A5686] justify-between items-center h-2/5 p-4 w-full"
                      >
                        <div className="flex flex-col">
                          <div className="text-white italic font-bold text-2xl">
                            ₹ {formatCurrency(limitApiRes?.limits?.maximum)}
                          </div>
                          <div className="text-white ">
                            {" "}
                            Use the default 24 hour transaction limit{" "}
                          </div>
                        </div>
                        {props.activeStep !== 2 && (
                          <div>
                            {!customLimit ? (
                              <RadioButtonChecked sx={{ color: "#4CB537" }} />
                            ) : (
                              <RadioButtonUnchecked sx={{ color: "#4CB537" }} />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                props.handleOpen("linkUser");
              }}
              className="flex flex-col gap-5"
            >
              <div
                className="self-center
          antaliased underline cursor-pointer
          text-custom-orange
          text-base xl:text-lg 3xl:text-xl 5xl:text-3xl"
              >
                Select user to link this account with
              </div>
              <div className="self-center cursor-pointer">
                <img
                  src={selectAcc}
                  className="w-8 h-8 3xl:w-12 3xl:h-12 5xl:w-16 5xl:h-16"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterNewVedor;
