import React, { useEffect } from "react";
import formatCurrency from "../../../../utils/CurrencyFormater";
import accTransform from "../../../../utils/accTransform";
import timedifference from "../../../../utils/timedifference";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import CardLogo from "../../../_utils/logo/CardLogo";

const FilledCardComponent = (props) => {
  return (
    <div className="flex justify-between h-full w-full bg-custom-card overflow-hidden p-6 2xl:p-8 3xl:p-8 4xl:p-12 5xl:p-16 6xl:p-18 shadow-customCard rounded-4xl">
      <div className="flex flex-col justify-between w-full gap-8">
        <div
          className="text-white w-full
          self-center font-semibold
          text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl"
        >
          {props.componentType} account
        </div>
        <div className="flex w-full justify-between">
          <div
            onClick={() => props.onClickFn()}
            style={{
              cursor: props?.activeStep === 2 ? "default" : "pointer",
            }}
            className="flex capitalize
          antaliased text-custom-orange font-semibold
          text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl truncate ..."
          >
            <div className="flex gap-5 self-start items-center">
              <div>{accTransform(props?.data.bank_acc_no)}</div>
              {props.editable && (
                <div className="text-custom-orange">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-10 5xl:h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
          {props?.data.available_balance ? (
            <div className="flex flex-col">
              <div
                className="text-right text-custom-lightGreen capitalize
          antaliased font-semibold
          text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl truncate ..."
              >
                ₹ {formatCurrency(props.data.available_balance)}
              </div>
              <div className="italic text-white text-right opacity-80 h-1/3 font-semibold text-base xl:text-lg 3xl:text-xl 5xl:text-3xl">
                {timedifference(props.data.last_fetched_balance)}
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <div
                className="text-right text-white capitalize
          antaliased
          text-lg xl:text-2xl 3xl:text-3xl 5xl:text-5xl truncate ..."
              >
                Balance not available
              </div>
              <div className="italic text-white text-right opacity-80 h-1/3 font-semibold text-base xl:text-lg 3xl:text-xl 5xl:text-3xl">
                &nbsp;
              </div>
            </div>
          )}
        </div>
        <div
          className="w-1/3
            -ml-6
            -mb-6
            2xl:-ml-8
            2xl:-mb-8
            4xl:-ml-12
            4xl:-mb-12
            5xl:-ml-16
            5xl:-mb-16
            6xl:-ml-18
            6xl:-mb-18
            flex flex-col justify-end"
        >
          <CardLogo
            bank={bankLogoMapper(props?.data.bank_name)}
            divCssProps={
              "rounded-tr-4xl 3xl:rounded-tr-large 5xl:rounded-tr-customXl"
            }
          />
        </div>
      </div>
    </div>
  );
};
export default FilledCardComponent;
