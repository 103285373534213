import { Box } from "@mui/material";
import React, { useState } from "react";
import TxnStatusCard from "../../../../components/CreateTxn/CreateInternaltxn/InternalTxnStatusCard";
import "./index.css";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import { useDispatch } from "react-redux";

const TransactionStatus = (props) => {
  const dispatch = useDispatch();
  return (
    <Box
      className="flex absolute xl:fixed top-0 left-0 right-0 bottom-0 -mt-[10] flex-col xl:flex-row gap-12
      min-w-screen min-h-screen justify-center pt-24 xl:h-screen items-center bg-[#6BA673]"
    >
      <Box className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 p-6 flex flex-col items-center justify-center gap-24 text-center text-white text-2xl">
        <img
          src={require("../../../../assets/Approve transactions/Success screen.png")}
          width={"60%"}
          alt=""
          srcSet=""
        />
        <h3>Transaction under processing!</h3>
      </Box>
      <Box className="w-4/5 flex justify-center h-[750px] xl:w-2/5 xl:h-[90%] p-6 text-center">
        <TxnStatusCard createdInternalTxn={props.createdInternalTxn} />
      </Box>
    </Box>
  );
};

export default TransactionStatus;
