import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";


const baseUrl = env.REACT_APP_BASE_URL;

export const getServices = createAsyncThunk("getServices", async (values) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
  };

  return await fetch(
    `${baseUrl}/platform_services${
      values?.service_id ? "/" + values?.service_id : ""
    }?page_no=${values?.page_no || 1}&page_size=${
      values?.page_size || 15
    }&search_string=${values?.search_string || ""}`,
    requestOptions
  )
    .then((response) => response.json())

    .catch((error) => console.log("error", error));
});

// export const getAllDevices = createAsyncThunk(
//   "getAllDevices",
//   async (values) => {
//     const { page_no, page_size } = values;
//     try {
//       const response = await axios.get(`${baseUrl}/devices`, {
//         headers: {
//           "Content-Type": "application/json",
//           "X-Api-Key": sessionStorage.getItem("q2p_token"),
//         },
//         params: {
//           page_no: page_no,
//           page_size: page_size,
//         },
//       });
//       return response.data;
//     } catch (error) {
//       toast.error(error, toast_position);
//       console.error("Error", error);
//       throw error;
//     }
//   }
// );

// export const getAPIToken = createAsyncThunk("getAPIToken", async (id) => {
//   try {
//     const response = await fetch(
//       `${baseUrl}/devices/${id}/generate_api_token`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "X-Api-Key": sessionStorage.getItem("q2p_token"),
//         },
//       }
//     );
//     return response.json();
//   } catch (error) {
//     toast.error(error, toast_position);
//     console.error("Error", error);
//     throw error;
//   }
// });

// export const deleteDevice = createAsyncThunk("deleteDevice", async (id) => {
//   try {
//     const response = await fetch(`${baseUrl}/devices/${id}`, {
//        method: 'DELETE',
//       headers: {
//         "X-Api-Key": sessionStorage.getItem("q2p_token"),
//         "Content-Type": "application/json",
//       },
//     });
//     return response.json();
//   } catch (error) {
//     toast.error(error, toast_position);
//     console.error("Error", error);
//     throw error;
//   }
// });

const servicesSlice = createSlice({
  name: "services",
  initialState: {
    isLoading: false,
    services: null,
    isError: false,

//     allDevicesData: null,
//     allDevicesDataLoading: null,
//     allDevicesDataError: null,

//     deleteDeviceStatus: null,
//     deleteDeviceLoading: null,
//     deleteDeviceError: null,
// deviceData:null,
//     apiToken: null,
//     apiTokenLoading: null,
//     apiTokenError: null,
  },
  // reducers: {
  //   setDeviceData: (state, action) => {
  //     state.deviceData = action.payload
  //   }
  // },
  extraReducers: (builder) => {
    builder.addCase(getServices.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.isLoading = false;
      state.services = action.payload;
      console.log("services", action.payload);
    });
    builder.addCase(getServices.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    // builder.addCase(getAllDevices.pending, (state, action) => {
    //   state.allDevicesDataLoading = true;
    // });
    // builder.addCase(getAllDevices.fulfilled, (state, action) => {
    //   state.allDevicesDataLoading = false;
    //   state.allDevicesData = action.payload;
    // });
    // builder.addCase(getAllDevices.rejected, (state, action) => {
    //   state.allDevicesDataError = true;
    //   state.allDevicesData = null;
    // });

    // builder.addCase(deleteDevice.pending, (state, action) => {
    //   state.deleteDeviceLoading = true;
    // });
    // builder.addCase(deleteDevice.fulfilled, (state, action) => {
    //   state.deleteDeviceLoading = false;
    //   state.deleteDeviceStatus = action.payload;
    // });
    // builder.addCase(deleteDevice.rejected, (state, action) => {
    //   state.deleteDeviceError = true;
    //   state.deleteDeviceStatus = null;
    // });

    // builder.addCase(getAPIToken.pending, (state, action) => {
    //   state.apiTokenLoading = true;
    // });
    // builder.addCase(getAPIToken.fulfilled, (state, action) => {
    //   state.apiTokenLoading = false;
    //   state.apiToken = action.payload;
    // });
    // builder.addCase(getAPIToken.rejected, (state, action) => {
    //   state.apiTokenError = true;
    //   state.apiToken = null;
    // });
  },
});

// export const  {setDeviceData} = servicesSlice.actions
export default servicesSlice.reducer;
