import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "./index.css";

export default function CommonSearchInput(props) {
  return (
    <Autocomplete
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      options={props.items.map((option) => option.title)}
      ListboxProps={{
        className: "autoCompleteListBox",
      }}
      renderInput={(params) => (
        <TextField
          id="filled-basic"
          variant="filled"
          {...params}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            type: "search",
          }}
        />
      )}
    />
  );
}