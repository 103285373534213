import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getHolidays } from "../../redux/features/date/date";
import { useDispatch } from "react-redux";

const NextDate = (props) => {
  const dispatch = useDispatch();
  let weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    dispatch(getHolidays());
  }, []);

  const holiday_list = useSelector((state) => state.date.holiday_list);

  const holidays = holiday_list ? holiday_list.holidays : [];

  const holidaysArray = [];
  holidays.map((object) => {
    holidaysArray.push(object.date);
  });
  const options = { month: "long", day: "numeric", year: "numeric" };

  const today = new Date();
  const nextDay = new Date();

  if (today.getHours() < 11) nextDay.setDate(today.getDate());
  // if txn is made before 11am, next date is today
  else nextDay.setDate(today.getDate() + 1);

  while (
    holidaysArray.includes(nextDay.toLocaleDateString().replace(/\//g, "-"))
  ) {
    nextDay.setDate(nextDay.getDate() + 1);
  }

  if (props.Tplus2) {
    nextDay.setDate(nextDay.getDate() + 1);
    while (
      holidaysArray.includes(nextDay.toLocaleDateString().replace(/\//g, "-"))
    ) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
  }
  let formattedNextDay = `${weekdays[nextDay.getDay()]}, ${nextDay.getDate()} ${months[nextDay.getMonth()]} ${nextDay.getFullYear()}`;
  return formattedNextDay;
};

export default NextDate;
