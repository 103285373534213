import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import formatCurrency from "../../../utils/CurrencyFormater";

const ViewAllCounts = (props) => {
  return (
    <div className="
      w-full
      drop-shadow-xl
      rounded-2xl 3xl:rounded-3xl 4xl:rounded-large
      p-4 3xl:p-6 4xl:p-10
      flex flex-col
      gap-2 3xl:gap-6 4xl:gap-10"
      style={{
        'background': "transparent linear-gradient(108deg, #3173E2 0%, #DF5C74 100%) 0% 0% no-repeat padding-box"
      }}>
      <div className="
        text-white flex flex-row justify-between
        text-sm 2xl:text-base 3xl:text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl
        text-center antialiased">
        <span>Transactions</span>
        <span>
          <span>Σ </span>
          {props.txn}
        </span>
      </div>
      <hr className="w-full" />
      <div className="w-full
        text-white flex flex-row justify-between
        text-sm 2xl:text-base 3xl:text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl
        text-center antialiased">
        <span>Amount</span>
        <span>{`₹ ${formatCurrency(props.amt)}`}</span>
      </div>
    </div >
  );
};

export default ViewAllCounts;


