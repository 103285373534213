import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DeniedReason = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reason, setReason] = useState("");
  const handlechange = (e) => {
    setReason(e.target.value);
  };

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[9998] "></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div className="w-100 h-fit flex flex-col gap-6  py-12 px-4 bg-custom-deniedReasonPurple rounded-4xl">
          <div className="mx-8">
            <h2 className="text-[#FF7F33] pb-4 font-semibold text-2xl ">
              Denied reason
            </h2>
            <p className="text-white opacity-70 ">
              Please state the reason for declining the transactions
            </p>
          </div>
          <div className="mx-8">
            <TextField
              autoComplete="off"
              inputProps={{
                style: {
                  color: "white",
                  borderBottom: "1px solid grey",
                },
              }}
              InputProps={{
                disableUnderline: true,
              }}
              InputLabelProps={{
                style: {
                  color: "#DADADA",
                  font: "normal normal 600 18px/24px Open Sans",
                  fontDecoration: "bold",
                  "@media (min-width: 2560px)": {
                    fontSize: "80px",
                  },
                },
              }}
              sx={{
                width: "100%",
                borderRadius: "10px",
                border: "none",
                backgroundColor: "#4E4A5E",
              }}
              id="standard-basic"
              label="Reason"
              onChange={handlechange}
              variant="standard"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "5%",
              margin: "0px 20px",
              width: "95%",
            }}
            className="px-8"
          >
            <Button
              sx={{
                borderRadius: "20px",
                borderColor: "#fff",
                color: "#fff",
                textTransform: "capitalize",
                textDecoration: "underline",
              }}
              onClick={() => props.setDeniedReason(false)}
              className=""
            >
              Cancel
            </Button>
            <Button
              disabled={reason.length === 0}
              className=""
              variant="outlined"
              sx={{
                borderRadius: "20px",
                backgroundColor: "#9F7EC0",
                color: "#fff",
                border: "none",
                boxShadow: "0px 3px 6px #00000029",
                textTransform: "capitalize",
                pointerEvents: "auto",
              }}
              onClick={() => {
                let enteredReason = reason?.trim();
                if (!(enteredReason.length > 5)) {
                  toast.error("Enter more than 5 valid characters", {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                } else {
                  let txn_id = props.txnId
                    ? props.txnId
                    : props.getSelected().selected;
                  props.setDeniedReason(false);
                  props.isAccount
                    ? props.isFFAccount
                      ? dispatch(
                        props.dispatchFn({
                          account_id: txn_id,
                          status: "N",
                          denied_reason: enteredReason,
                        })
                      )
                      : dispatch(
                        props.dispatchFn({
                          vendor_id: txn_id,
                          status: "N",
                          denied_reason: enteredReason,
                        })
                      )
                    : dispatch(
                      props.dispatchFn({
                        txn_id: txn_id,
                        status: "N",
                        denied_reason: enteredReason,
                      })
                    );
                  if (props.setShowStatusCard) props.setShowStatusCard();
                  if (props.navigatePath) navigate(props.navigatePath);
                }
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeniedReason;
