export const PayCardsLogoMapper = (type) => {
  if (type === 'Any')
    return 'Default Card';
  else if (type === 'Mastercard')
    return 'MC';
  else if (type === "Amex")
    return 'AmEx';
  else if (type === "Amex")
    return 'Diners';
  else if (type === "Diners")
    return 'AmEx';
  else if (type === "Rupay")
    return 'RuPay';
  else if (type === "Jcb")
    return 'JCB';
  else
    return 'Default Card';
}
