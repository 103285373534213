import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import logo from "../../../assets/TransactionDashboardIcons/InstantPay card icon.png";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";
import trimSentence from "../../../utils/trimSentence";

const VendorDeniedReasonComponent = (props) => {
  return (
    <div className="h-full">
      <div
        style={{
          backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status),
        }}
        className="h-full text-white drop-shadow-2xl flex flex-col
        rounded-4xl
        px-8 py-4
        overflow-hidden"
      >
        <div className="h-[30%] flex flex-row justify-between">
          <div className="self-center w-1/2 text-gray-300 text-sm font-bold">
            Denied Reason
          </div>
          <div className="self-center">
            <img
              className="w-4 h-4"
              src={require("../../../assets/TxnDetails/denied.png")}
            />
          </div>
        </div>
        <div className="h-1/3">
          <div
            className="text-base
           text-white antialiased truncate ..."
          >
            {props.denied_reason ? props.denied_reason : "Not Given"}
          </div>
        </div>
        <div className="h-1/3"></div>
      </div>
      <div className="h-1/5"></div>
    </div>
  );
};
export default VendorDeniedReasonComponent;

// <Box sx={{
//         display: "flex",
//         alignItems: "flex-start",
//         justifyContent: "space-between",
//       }}>
//         <Box className="additionalDetailsCardLeftTop" sx={{ width: "50%" }}>Denied Reason</Box>
//         <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
//           <img width="10%" src={require("../../../assets/TxnDetails/denied.png")} alt="" srcSet="" />
//         </Box>
//       </Box>
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "flex-start",
//           justifyContent: "flex-start",
//           paddingTop: "20px",
//           font: "normal normal normal 20px/27px Open Sans",
//           color: "#fff",
//           width: "100%",
//           overflow: "hidden",
//         }}>{props.denied_reason ? trimSentence(props.denied_reason, 40) : "Not Given"}</Box>
