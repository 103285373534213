import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import skippedVerification from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import { bankLogoMapper } from "../../../services/bankLogoMapper";

const RegisterdFilledForm = (props) => {
  const panStatus = useSelector((state) => state.verify.verifyPAN);
  const bankStatus = useSelector((state) => state.verify.verifyBank);
  const accStatus = useSelector((state) => state.createAcc.vendor);

  useEffect(() => {
    if (accStatus?.status === true) {
      props.enableNextButton(true);
    } else {
      props.enableNextButton(false);
    }
  }, [accStatus]);

  return (
    <div className="h-full bg-custom-blue
      flex flex-col shadow-xl rounded-large overflow-hidden">
      <div className="bg-custom-lightBlue shadow-xl rounded-b-large">
        <div className="bg-custom-card shadow-xl rounded-large flex flex-col
        px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
        pt-2 md:pt-4 xl:pt-6 4xl:pt-20
        pb-1 lg:pb-2 xl:pb-4 4xl:pb-14
        overflow-hidden">
          <div className="flex flex-col gap-4">
            <div className="text-white
            font-semibold
            text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl">
              Vendor details
            </div>
            <div className="flex flex-col gap-2 divide-y divide-gray-300">
              <div className="py-2 flex flex-col">
                <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
                  {props.registerNewVnerdorData?.pan_no}
                </div>
                <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                  Vendor's PAN number
                </div>
              </div>
              <div className="py-2 flex flex-row justify-between">
                <div className="text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl
              text-white antialiased truncate ...">
                  {panStatus?.pan_details.full_name}
                </div>
                <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                  Legal Name
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col
          px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
          py-1 lg:py-2 xl:py-4 4xl:py-14
          overflow-hidden">
          <div className="flex flex-col">
            <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
              {props.registerNewVnerdorData?.bank_acc_no}
            </div>
            <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
              Bank account number
            </div>
          </div>
          <div className="flex flex-col gap-2 divide-y divide-gray-300">
            <div className="py-4 flex flex-row justify-between">
              <div className="w-4/5">
                <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
                  {props.registerNewVnerdorData?.ifsc_code}
                </div>
                <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                  Bank IFSC code
                </div>
              </div>
              <div className="w-1/5 flex flex-col justify-center">
                <div className="
                  group-hover:bg-blue-100
                  text-center
                  bg-white rounded-custom2Xl">
                  <img
                    className="p-2"
                    src={require("../../../assets/Banks/" +
                      bankLogoMapper(props.registerNewVnerdorData.bank_name) +
                      ".png")}
                    alt="BankLogo"
                  />
                </div>
              </div>
            </div>
            {bankStatus.verification_details &&
              bankStatus.verification_details[0].account_exists === "Y" ? (
              <div className="py-2 flex flex-row justify-between">
                <div className="text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl
              text-white antialiased truncate ...">
                  {bankStatus?.verification_details[0].legal_name}
                </div>
                <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                  Account holders name
                </div>
              </div>) : (
              <div className="py-2 flex flex-row justify-between">
                <div className="text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl
                text-white antialiased truncate ...">
                  Verification skipped
                </div>
                <div className="flex flex-row justify-end h-full w-1/6">
                  <img
                    className=" w-4 h-4 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8"
                    src={skippedVerification}
                    alt="verified logo"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4
        px-4 md:px-6 lg:px-8 xl:px-10 4xl:px-24
        py-1 lg:py-2 xl:py-4 4xl:py-14
        overflow-hidden">
        <div className="flex flex-col">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
            {panStatus?.pan_details.full_name}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            Vendor name - Using legal name
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
            {props.registerNewVnerdorData?.contact_no}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            Contact number
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
            {props.registerNewVnerdorData?.email_id}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            Email-ID
          </div>
        </div>

        <div className="flex flex-col">
          <div className="text-base 3xl:text-lg 5xl:text-xl 6xl:text-2xl
              text-white antialiased truncate ...">
            {props.registerNewVnerdorData?.gstin
              ? props.registerNewVnerdorData?.gstin
              : "Not Given"}
          </div>
          <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
            GSTIN (optional)
          </div>
        </div>
      </div>
    </div >
  );
};

export default RegisterdFilledForm;

{/*
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <h4
          style={{
            color: "white",
            font: "normal normal 600 1.25em/1em Open Sans",
            textTransform: "capitalize",
          }}
        >
          {panStatus?.pan_details.full_name}
        </h4>
        <p
          style={{
            font: "normal normal normal 0.75em/1em Open Sans",
            marginTop: "-8%",
            color: "#C9C9C9",
          }}
        >
          Vendor name - Using legal name
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <h4
          style={{
            color: "white",
            font: "normal normal normal 1.25em/1em Open Sans",
          }}
        >
          {props.registerNewVnerdorData?.contact_no}
        </h4>
        <p
          style={{
            font: "normal normal normal 0.75em/1em Open Sans",
            marginTop: "-22%",
            color: "#C9C9C9",
          }}
        >
          Contact number
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <h4
          style={{
            color: "white",
            font: "normal normal normal 1.25em/1em Open Sans",
          }}
        >
          {props.registerNewVnerdorData?.email_id}
        </h4>
        <p
          style={{
            font: "normal normal normal 0.75em/1em Open Sans",
            marginTop: "-10%",
            color: "#C9C9C9",
          }}
        >
          Email-ID
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <h4
          style={{
            color: "white",
            font: "normal normal normal 1.25em/1em Open Sans",
          }}
        >
          {props.registerNewVnerdorData?.gstin
            ? props.registerNewVnerdorData?.gstin
            : "Not Given"}
        </h4>
        <p
          style={{
            font: "normal normal normal 0.75em/1em Open Sans",
            marginTop: "-20%",
            color: "#C9C9C9",
          }}
        >
          GSTIN (optional)
        </p>
      </Box>
    </Box>
  </Box>
</Box> */}
