import React from "react";
import { Box } from "@mui/material";
import FilterDrawer from "../../../components/ViewAllTransactions/FilterDrawer";
import ViewAllCounts from "../../../components/ViewAllTransactions/ViewAllCounts";
import DatacardViewAll from "../../../components/ViewAllTransactions/DatacardViewAll";
import AllStatusTypePopover from "../../../components/ViewAllTransactions/AllStatusTypePopover";

const ChargebackViewAllT = () => {
  return (
    <Box sx={{ marginTop: "80px" }}>
      <Box className="headContainer">
        <Box>
          <ViewAllCounts txn={345254} amt={34523} />
        </Box>
        <Box className="filterByLabel">
          <p>Filter by:</p>
        </Box>
        <Box className="filterIconContainer">
          <FilterDrawer />
        </Box>
      </Box>
      <Box className="bodyContainer">
        <Box className="ViewAllStatusTypePopover">
          <AllStatusTypePopover />
        </Box>
        <Box className="viewAllRecentCards">
          <DatacardViewAll />
        </Box>
      </Box>
    </Box>
  );
};

export default ChargebackViewAllT;
