import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import ApprovalDetailsCard from "../ApprovalDetailsCard";
import ApprovalStatusCard from "../ApprovalStatusCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./index.css";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function ApprovalCardsCarousel(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const txnDetail = useSelector((state) => state.approveTxn.vendor);
  const [activeStep, setActiveStep] = useState(0);
  const [showStatusCard, setShowStatusCard] = useState([]);
  const [approvalStatuses, setApprovalStatuses] = useState({});
  const [checkRes, setCheckRes] = useState(false);
  const maxSteps = props?.data.length;

  useEffect(() => {
    if (checkRes && txnDetail?.errors) {
      toast.error(Object.values(txnDetail.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && txnDetail?.status)
      setApprovalStatuses({
        ...approvalStatuses,
        [txnDetail?.transactions[0].txn_id]: txnDetail,
      });
    setCheckRes(true);
  }, [txnDetail]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (
      activeStep + 1 === maxSteps &&
      maxSteps !== props.records &&
      Object.keys(approvalStatuses).length === 0
    )
      props.setPage(props.page + 1);
  }, [activeStep]);

  return (
    <>
      <div className="vendorTxnCarouselHeader w-full flex drop-shadow-xl justify-between bg-[#3e5555] overflow-hidden items-center bg-no-repeat bg-padding-box">
        {activeStep < maxSteps && (
          <Box className="flex justify-between place-items-center w-4/6 scale-70 5.6xl:scale-60 vendorTxnCarousel">
            {activeStep > 0 ? (
              <Button
                sx={{ color: "white" }}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <img
                  width={"33px"}
                  src={require("../../../../assets/Approve transactions/Previous.png")}
                  alt=""
                />
              </Button>
            ) : (
              <Button disabled size="small"></Button>
            )}
            <Box className="text-white text-lg 5.6xl:text-xl pt-0">
              {activeStep +
                1 +
                " of " +
                maxSteps +
                (maxSteps === props.records ? "" : "+")}
            </Box>
            {activeStep + 1 < maxSteps ||
              Object.keys(approvalStatuses).length > 0 ? (
              <Button
                sx={{ color: "white" }}
                size="small"
                onClick={handleNext}
                disabled={activeStep + 1 === props.records}
              >
                <img
                  width={"33px"}
                  src={require("../../../../assets/Approve transactions/Next.png")}
                  alt=""
                />
              </Button>
            ) : (
              <Button disabled size="small"></Button>
            )}
          </Box>
        )}
      </div>
      <div className="vendorTxnApprovalReq">
        <Box className="scale-70 5.6xl:scale-85 w-4/6 overflow-hidden rounded-4xl mx-auto 5.6xl:mt-16">
          <AutoPlaySwipeableViews
            sx={{
              height: "auto",
            }}
            disabled={true}
            autoplay={false}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {props.data?.map((item, index) => (
              <div key={item.txn_id}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <div className="w-full p-2">
                    {showStatusCard?.includes(index) ? (
                      <ApprovalStatusCard
                        txnDetail={approvalStatuses[item.transaction.id]}
                        setSelectedTxnCarousel={props.setSelectedTxnCarousel}
                        showStatusCard={showStatusCard}
                        setShowSettlementComponent={
                          props.setShowSettlementComponent
                        }
                        data={item}
                      />
                    ) : (
                      <ApprovalDetailsCard
                        setDeniedReason={props.setDeniedReason}
                        setDeniedReasonData={props.setDeniedReasonData}
                        data={item}
                        showStatusCard={showStatusCard}
                        setShowStatusCard={setShowStatusCard}
                        index={index}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            ))}
            {approvalStatuses && (
              <div className="reloadButton">
                <div className="w-full  overflow-hidden h-[630px] mt-3 text-lg flex rounded-4xl shadow-customCard bg-[#3e5555]">
                  <div className="w-1/2 text-center rounded-4xl text-white">
                    <div className="p-5 h-full flex flex-col gap-20 justify-center items-center text-white text-xl leading-loose tracking-normal">
                      Approval list has been updated. <br /> Reload the page to
                      view the <br /> updated list.
                    </div>
                  </div>
                  <div className="w-1/2 m-full bg-[#4A6565] flex justify-center flex-col text-center rounded-4xl text-white">
                    <Box className="flex flex-col gap-9 p-8 w-full justify-center items-center">
                      <img
                        width="200px"
                        src={require(`../../../../assets/Approve transactions/inputloder.gif`)}
                        alt=""
                        srcSet=""
                      />
                    </Box>
                    <h1>
                      <Link
                        className="text-3xl text-white"
                        style={{
                          color: "#FF9130",
                          textDecoration: "underline",
                        }}
                        onClick={() => navigate(0)}
                      >
                        {" "}
                        Reload page{" "}
                      </Link>
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </AutoPlaySwipeableViews>
        </Box>
      </div>
    </>
  );
}

export default ApprovalCardsCarousel;
