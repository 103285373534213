import arrowIcon from '../../../../assets/TransactionDashboardIcons/arrow_next.png'
function DetailsDataCard({ children }) {
    return (
        <div className="bg-[#3A5686] w-5/12 h-[6rem] drop-shadow-xl py-5 px-8 rounded-4xl flex items-center">
            <div className='w-2/3 line-clamp-2 text-white text-lg'>
                {children}
            </div>
            <div className='w-1/3 flex justify-end'>
                <img
                    src={arrowIcon}
                    className='w-5 h-5' />
            </div>
        </div>
    )
}

export default DetailsDataCard