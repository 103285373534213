const colorCode = {
  "Processing transactions": "#FBC02D",
  "Successful transactions": "#4DDD37",
  "Settled transactions": "#4DDD37",
  "Cancelled transactions": "#FF5860",
  "Abandoned transactions": "#B4B4B4",
  "Require approval": "#31E5E5",
  "Pending transactions": "#31E5E5",
  "Created transactions": "#B196FA",
  "Require settlement": "#B196FA",
  "Failed transactions": "#FF5860",
  "Waiting for approval": "#FBC02D",
};

export default colorCode;

