import { Archive } from "@mui/icons-material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { env } from "../../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const toast_position = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const toast_position_for_refresh_load = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const getAllTransactionAuthorization = createAsyncThunk(
  "getAllTransactionAuthorization",
  async (values) => {
    var requestOptions = {
      method: "get",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };
    return await fetch(
      `${baseUrl}/transaction_auth_matrix?page_no=${
        values?.page_no || 1
      }&page_size=${values?.page_size || 100}&type_of_transaction=${
        values?.type_of_transaction || ""
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const createTransactionAuthorization = createAsyncThunk(
  "createTransactionAuthorization",
  async (values) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(values?.inputStages),
    };
    return await fetch(`${baseUrl}/transaction_auth_matrix`, requestOptions)
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

export const ArchiveTransactionAuthorization = createAsyncThunk(
  "ArchiveTransactionAuthorization",
  async (values) => {
    var requestOptions = {
      method: "delete",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };
    return await fetch(
      `${baseUrl}/transaction_auth_matrix/${values?.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const AuthMatrix = createSlice({
  name: "AuthMatrix",
  initialState: {
    allTransactionAuth: null,
    archiveRuleRes: null,
    createTransactionAuth: null,
    isLoading: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTransactionAuthorization.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAllTransactionAuthorization.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.allTransactionAuth = action.payload;
      }
    );
    builder.addCase(
      getAllTransactionAuthorization.rejected,
      (state, action) => {
        state.isError = true;
      }
    );
    builder.addCase(
      ArchiveTransactionAuthorization.pending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      ArchiveTransactionAuthorization.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.archiveRuleRes = action.payload;
        if (action.payload?.errors)
          toast.error(Object.values(action.payload.errors)[0], toast_position);
        if (action.payload?.status)
          toast.success(
            "Transaction Authorization Archived Successfully",
            toast_position
          );
      }
    );
    builder.addCase(
      ArchiveTransactionAuthorization.rejected,
      (state, action) => {
        state.isError = true;
      }
    );
    builder.addCase(createTransactionAuthorization.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      createTransactionAuthorization.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.createTransactionAuth = action.payload;
        if (action.payload?.error)
          toast.error(action.payload.error, toast_position);
        if (action.payload?.errors)
          toast.error(Object.values(action.payload.errors)[0], toast_position);
        if (action.payload?.status === true)
          toast.success(
            "Transaction Authorization Created Successfully",
            toast_position
          );
      }
    );
    builder.addCase(
      createTransactionAuthorization.rejected,
      (state, action) => {
        state.isError = true;
      }
    );
  },
});

export default AuthMatrix.reducer;
