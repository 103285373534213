import { delFromObj } from "../utils/delFromObj";

export const internalDashboardChecks = (obj) => {
  var data = delFromObj(obj, "status");

  if (data.abandoned_txn_details.records === "0") {
    delete data.abandoned_txn_details;
  } else {
    data["Abandoned transactions"] = data.abandoned_txn_details;
    delete data.abandoned_txn_details;
  }
  if (data.settled_txn_details) {
    data["Successful transactions"] = data.settled_txn_details;
    delete data.settled_txn_details;
  }
  if (data.processing_txn_details) {
    data["Processing transactions"] = data.processing_txn_details;
    delete data.processing_txn_details;
  }
  return data;
};

