export const txnPathMapper = (txn_path) => {
  return {
    "Processing transactions": `/${txn_path}/view_all_transactions`,
    "Settled transactions": `/${txn_path}/view_all_transactions`,
    "Successful transactions": `/${txn_path}/view_all_transactions`,
    "Cancelled transactions": `/${txn_path}/view_all_transactions`,
    "Abandoned transactions": `/${txn_path}/view_all_transactions`,
    "Created transactions": `/${txn_path}/view_all_transactions`,
    "Require approval": `/approval_required/${txn_path}`,
    "Pending transactions": "#",
    "Require settlement": `/settlement_required/${txn_path}`,
    "Failed transactions": `/${txn_path}/view_all_transactions`,
    "Waiting for approval": `/${txn_path}/view_all_transactions`,
  }
};
export const InstantPayMapper = {
  "Processing transactions": "#",
  "Successful transactions": "/instantPay/settle_txn",
  "Cancelled transactions": "#",
  "Abandoned transactions": "#",
  "Require approval": "/approval_required/vendor_transaction",
  "Pending transactions": "#",
  "Created transactions": "#",
  "Require settlement": "/settlement_required/vendor_transaction",
  "Failed transactions": "#",
  "Waiting for approval": "#",
}

export const ReimbursementMapper = {
  "Processing transactions": "#",
  "Successful transactions": "#",
  "Cancelled transactions": "#",
  "Abandoned transactions": "#",
  "Require approval": "/approval_required/reimbursement_transaction",
  "Pending transactions": "#",
  "Created transactions": "#",
  "Require settlement": "/settlement_required/reimbursement_transaction",
  "Failed transactions": "#",
  "Waiting for approval": "#",
}

export const FFMapper = {
  "Processing transactions": "#",
  "Successful transactions": "#",
  "Cancelled transactions": "#",
  "Abandoned transactions": "#",
  "Require approval": "/approval_required/fieldforce_transaction",
  "Pending transactions": "#",
  "Created transactions": "#",
  "Require settlement": "/settlement_required/fieldforce_transaction",
  "Failed transactions": "#",
  "Waiting for approval": "#",
}



