import React, { useEffect } from "react";
import SettleTxnStatusCard from "../../../components/SettleTxn/SettleTxnStatusCard";
import SettleSingleTxnStatus from "../../../components/SettleTxn/SettleSingleTxnStatus";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { setHideBreadCrumb } from "../../../redux/features/hideBreadCrumb/hideBreadCrumb";

const SettlementTransactionStatus = () => {
  const txnDetails = useSelector((state) => state.bufferState.txnDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  return (
    <div className="flex flex-col bg-[#6BA673] xl:flex-row min-w-screen min-h-screen w-screen justify-center pt-24 xl:h-screen items-center">
      <div className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 flex flex-col items-center justify-center gap-6 text-center text-white text-2xl">
        <img
          src={require("../../../assets/Approve transactions/Success screen.png")}
          width="70%"
          alt=""
          srcSet=""
        />
        <h2>
          {txnDetails?.module === "FF" ? "Field force " : "Vendor "}
          {txnDetails?.totalSelected === 1
            ? "transaction"
            : "transactions"}{" "}
          under<br></br> processing!
        </h2>
      </div>
      <div className="w-4/5 flex justify-start h-[750px] xl:w-2/5 xl:h-[90%] text-center">
        {txnDetails?.totalSelected === 1 ? (
          <SettleTxnStatusCard txnDetails={txnDetails} />
        ) : (
          <SettleSingleTxnStatus txnDetails={txnDetails} />
        )}
      </div>
    </div>
  );
};

export default SettlementTransactionStatus;
