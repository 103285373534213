import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CreateTxnMenuItemCard from "../../components/CreateTxnMenuItemCard";
import InstanPayAvailBal from "../../components/InstanPayAvailBal";
import { getInstantPayBalance } from "../../redux/features/instantPayBalance/instantPayBalance";

const CreateAccount = () => {
  const dispatch = useDispatch();
  const instantAccountSummary = useSelector(
    (state) => state.instantPayBalance.balance
  );

  const instantPayAvailBal = instantAccountSummary
    ? instantAccountSummary.balance_details &&
      instantAccountSummary.balance_details.length
      ? instantAccountSummary.balance_details[0]
      : null
    : null;


  useEffect(() => {
    dispatch(getInstantPayBalance());
  }, [dispatch]);
  return (
    <div className="bg-[#744991] pt-1 pb-10 -mb-28">
      <div className="mt-48 mx-32 flex justify-between">
        <div className="create-txn-headings">
          <div className="create-txn-container-title">Select</div>
          <div className="create-txn-container-subtitle">
            the account type you want to create
          </div>
        </div>
      </div>
      <div className="mx-32 mt-10 flex justify-start flex-col">
        <div className="mt-12 text-left font-semibold text-xl tracking-normal text-white uppercase opacity-100">
          DEBIT ACCOUNTS
          <div className="mt-12 flex flex-row justify-start gap-16">
            <CreateTxnMenuItemCard
              type="account"
              icon="Vendor.png"
              label="Vendor"
              subtext="Register your vendors and start using Xpentra"
              to="/accounts/vendor/create"
            />
          </div>
        </div>
        <div className="mt-12 text-left font-semibold text-xl tracking-normal text-white uppercase opacity-100">
          EMPLOYEE ACCOUNTS
          <div className="mt-12 flex flex-row justify-start gap-16">
            <CreateTxnMenuItemCard
              type="account"
              icon="Field force.png"
              label="Field Force"
              to="/accounts/field_force/create"
              subtext="Create a field force account to link it with a user"
            />
            <CreateTxnMenuItemCard
              type="account"
              icon="Reimbursement.png"
              label="Reimbursement"
              to="/accounts/reimbursement/create"
              subtext="Create an account you would like to reimburse to"
            />
          </div>
        </div>
        <div className="mt-12 text-left font-semibold text-xl tracking-normal text-white uppercase opacity-100">
          CREDIT ACCOUNTS
          <div className="mt-12 flex flex-row justify-start gap-16">
            <CreateTxnMenuItemCard
              type="account"
              icon="Client.png"
              label="Client"
              to="/accounts/client/create"
              subtext="Create account you would like to receive from"
            />
          </div>
        </div>
        <div className="mt-12 text-left font-semibold text-xl tracking-normal text-white uppercase opacity-100">
          CORPORATE ACCOUNTS
          <div className="mt-12 flex flex-row justify-start gap-16">
            <CreateTxnMenuItemCard
              type="account"
              icon="Client.png"
              label="Owner"
              to="/accounts/owner/create"
              subtext="Create account you would like to pay from"
            />
          </div>
        </div>
      </div>
      <div className=" fixed bottom-16 right-10 text-xs text-white">
        *If disabled - the feature is not available for your ID.
      </div>
    </div>
  );
};

export default CreateAccount;
