import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import accTransform from "../../../../utils/accTransform";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const TxnStatusCardFF = (props) => {
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);

  return (
    <>
      <Box className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <Box className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6">
          <div className="flex flex-col gap-2 font-bold p-4 mt-4 pb-4">
            <div className="text-2xl 4xl:text-4xl font-semibold text-[#27B882] w-full">
              ₹ {CurrencyFormater(props.amount)}
            </div>
            <div className="text-black text-xl 4xl:text-3xl capitalize truncate ...">
              {props.name}
            </div>
          </div>
          <Box className="dashedLine"></Box>
          {props.type === "settlement" ? (
            <Box className="detailsText w-full">
              <div
                className="text-base 3xl:text-xl font-bold 5xl:text-2xl 6xl:text-3xl
                antialiased truncate ..."
              >
                {accTransform(props.account)}
              </div>
              <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                Account number
              </div>
              <Box sx={{ background: "#A0A0A0" }} className="simpleLine"></Box>
              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <div
                    className="text-base 3xl:text-xl font-bold 5xl:text-2xl 6xl:text-3xl
                    antialiased truncate ..."
                  >
                    {props.ifsc}
                  </div>
                  <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                    IFSC code
                  </div>
                </div>
                <div className="flex flex-row justify-end w-2/6">
                  <img
                    className="w-[70%] self-center"
                    src={require("../../../../assets/Banks/" +
                      bankLogoMapper(props?.bank_name) +
                      ".png")}
                    alt=""
                  />
                </div>
              </div>
            </Box>
          ) : props.approval_status === "Processing" ? (
            <div className="flex w-4/5 self-center flex-row justify-between">
              <div className="flex flex-col">
                <div
                  className="text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                    antialiased truncate ..."
                >
                  InstantPay
                </div>
                <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                  Payment method
                </div>
              </div>
              <div className="flex flex-row justify-center w-2/6">
                <img
                  className="w-[80%] self-center"
                  src={require("../../../../assets/Banks/" +
                    "InstantPay" +
                    ".png")}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <Box
              sx={{ padding: "24px 0" }}
              className="font-semibold text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl"
            >
              Waiting for{" "}
              {props.approval_status === "P"
                ? "approval"
                : props.approval_status === "Y"
                ? "settlement"
                : null}
            </Box>
          )}
        </Box>
        <Box className="w-full divide-x-2 cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl bg-[#7fb085] flex justify-evenly items-center gap-0">
          <Box
            className="flex flex-col w-2/6 h-3/4 gap-2 items-center justify-center"
            onClick={() => navigate(0)}
          >
            <Box className="w-3/6 flex justify-center">
              <img
                width={"50%"}
                src={require("../../../../assets/CreateTransactionIcons/Create transaction.png")}
                alt=""
                srcSet=""
              />
            </Box>
            <p>
              Create new <br /> transaction
            </p>
          </Box>
          {props.txn_id && (
            <Box
              className="flex flex-col w-2/6 h-3/4 gap-2 items-center justify-center border-gray-300"
              onClick={() => navigate(`/ff/txn/${props.txn_id}`)}
            >
              <Box className="w-3/6 flex justify-center">
                <img
                  width={"50%"}
                  src={require("../../../../assets/CreateTransactionIcons/View details.png")}
                  alt=""
                  srcSet=""
                />
              </Box>
              <p>
                View transaction <br /> details
              </p>
            </Box>
          )}
          <Box
            className="flex flex-col w-2/6 h-3/4 gap-2 items-center justify-center border-gray-300"
            onClick={() => navigate("/field_force_transactions")}
          >
            <Box className="w-3/6 flex justify-center">
              <img
                width={"50%"}
                src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                alt=""
                srcSet=""
              />
            </Box>
            <p>
              Go to <br /> dashboard
            </p>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TxnStatusCardFF;
