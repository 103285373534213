import React, { useRef } from "react";
import "./index.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { beneCategories } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import filter from "../../../../assets/ViewAllTransactions/filter.png";
import clear_filter from "../../../../assets/ViewAllTransactions/Clear filter.png";
import arrow_down_red from "../../../../assets/TransactionDashboardIcons/arrow_down_red.png";
import cancel from "../../../../assets/TransactionDashboardIcons/cancel.png";
import search from "../../../../assets/ViewAllTransactions/search-white.png";
import DatePickerComponent from "../../../../components/_utils/Calendar";

import closeIcon from "../../../../assets/Approve transactions/Close-icon.png"; // Assuming you have a close icon
import arrowforward from "../../../../assets/Beneficiary Icons/arrowForward.png";
import Validation from "../../../../utils/validation";

import { Button, Tooltip, tooltipClasses } from "@mui/material";
const BeneAccFilterSidebar = ({
  isOpen,
  onClose,
  categoryName,
  sendCategory,
  sendDateRange,
  setPage,
  setIsSidebarOpen,
  initCategory,
  setStartDateFilter,
  setEndDateFilter,
  setInitCategory,
  setAccountNumber,
  accountNumber,
  sendAccountNumber,
  setPanNumber,
  panNumber,
  sendPanNumber,
  isSidebarOpen,
  searchPanText,
  setSearchPanText,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  toggleSidebar,
  isIfsc,
  setIsIfsc,
  searchIfscText,
  setSearchIfscText,
  searchAccountText,
  setSearchAccountText,
  showDatePicker,
  setShowDatePicker,
  setCurrentPage,
}) => {
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [categorySearch, setCategorySearch] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [label, setLabel] = useState("Select category");

  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const searchInputRef = useRef(null);
  const categorydropdownRef = useRef(null);
  const yearDropdownRef = useRef(null);
  const dateRef = useRef(null);
  const categories = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );

  useEffect(() => {}, [isOpen]);
  const handleScroll = (scrollAmount) => {
    window.scrollBy({
      top: scrollAmount,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 87) {
        setScrollAmount(-30);
      } else if (e.keyCode === 83) {
        setScrollAmount(30);
      }
    };

    const handleKeyUp = () => {
      setScrollAmount(0);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    const scrollInterval = setInterval(() => {
      if (scrollAmount !== 0) {
        handleScroll(scrollAmount);
      }
    }, 10);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      clearInterval(scrollInterval);
    };
  }, [scrollAmount]);
  const handleAccountSearch = (text) => {
    setSearchAccountText(text);
    // setAccountNumber(text);
  };
  const handlePanSearch = (text) => {
    setSearchPanText(text);
    // setAccountNumber(text);
  };
  const handleIfscSearch = (text) => {
    setSearchIfscText(text);
  };
  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    onClose(open);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        categorydropdownRef.current &&
        !categorydropdownRef.current.contains(event.target)
      ) {
        setdropdownOpen(false);
      }
      if (dateRef.current && !dateRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categorydropdownRef, dateRef]);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleClick = (name) => {
    setInitCategory(name);
    setPage(1);
    setdropdownOpen(false);
  };
  const handleCategorySearchChange = (event) => {
    setCategorySearch(event.target.value);
  };
  const clearFilter = () => {
    setInitCategory("");
    sendCategory("");
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    setStartDateFilter("");
    setEndDateFilter("");
    // setIsSidebarOpen(false);
    setAccountNumber("");
    setSearchAccountText("");
    setSearchPanText("");
    setPanNumber("");
    setSearchIfscText("");
    setIsIfsc("");
  };
  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };
  const allowedValue = (name, value) => {
    if (name === "accountnumber") return Validation.allowedBankAccNum(value);
    if (name === "ifscnumber") return Validation.allowedBankIfsc(value);
    if (name === "pannumber") return Validation.allowedPAN(value);
    return true;
  };

  const handleInput = (e) => {
    let { name, value } = e.target;

    let val = value.replaceAll(" ", "");
    if (allowedValue(name, val)) {
      if (name === "accountnumber") {
        setSearchAccountText(val.toUpperCase());
      } else if (name === "ifscnumber") {
        setSearchIfscText(val.toUpperCase());
      } else if (name === "pannumber") {
        setSearchPanText(val.toUpperCase());
      }
      setActiveSection(name);
      setdropdownOpen(false);
      setShowDatePicker(false);
    }
  };

  useEffect(() => {
    if (searchAccountText.length === 0) {
      setSearchIfscText("");
      setAccountNumber("");
      setIsIfsc("");
      setSearchIfscText("");
    }
  }, [searchAccountText, setSearchIfscText, setAccountNumber, setIsIfsc]);

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
  };
  const filterCategory = () => {
    if (handleFilterEnable()) {
      sendCategory(initCategory);
      setPage(1);
      if (startDate && endDate) {
        setStartDateFilter(convertDate(startDate));
        setEndDateFilter(convertDate(endDate));
      }
      setAccountNumber(searchAccountText);
      setPanNumber(searchPanText);
      setIsIfsc(searchIfscText);
      setShowDatePicker(false);
      setIsSidebarOpen(false);
    }
  };
  const handleFilterEnable = () => {
    if (searchPanText.length > 0 && searchPanText.length !== 10) {
      return false;
    }
    if (searchAccountText.length > 0 && searchAccountText.length < 4) {
      return false;
    }
    if (searchAccountText.length > 0 && searchIfscText.length !== 11) {
      return false;
    }
    if (searchIfscText.length > 0 && searchAccountText.length < 4) {
      return false;
    }
    if (searchAccountText.length === 0 && searchIfscText.length > 0) {
      return false;
    }
    if (
      searchAccountText.length === 0 &&
      searchIfscText.length === 0 &&
      searchPanText.length === 0 &&
      !startDate &&
      !endDate &&
      initCategory.length === 0
    ) {
      return false;
    }
    return true;
  };
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(categorySearch.toLowerCase())
  );
  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };
  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const isNoCategorySelected =
    initCategory === "" &&
    startDate === null &&
    endDate === null &&
    searchAccountText === "" &&
    searchIfscText === "" &&
    searchPanText === "";

  return (
    <div className={`beneAccviewallsidebar ${isOpen ? "open" : "closed"}`}>
      <div
        className="beneAccviewallFilterDiv pl-5"
        style={{
          visibility: isOpen ? "visible" : "hidden",
          transition: `opacity 0.2s ease-in ${isOpen ? "0s" : "0.2s"}`,
          opacity: isOpen ? "1" : "0",
        }}
      >
        <div className="beneAccFilterSection1">
          <span className="text-[#F05A6A] font-normal">Filter </span>

          <div
            className="relative w-[200px] h-[35px] mt-[-15px]"
            onClick={() => {
              setActiveSection("pannumber");
              setdropdownOpen(false);
              setShowDatePicker(false);
            }}
          >
            <span
              style={{
                color: "#C9C9C9",
                display: "inline-block",
                marginTop: "19px",
                // width: "113px",
                height: "17px",
                fontSize: "12px",
              }}
            >
              PAN number
            </span>
            <input
              ref={searchInputRef}
              className="w-[200px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[3px]"
              type="text"
              placeholder="Enter PAN number"
              value={searchPanText}
              name="pannumber"
              onChange={handleInput}
              onFocus={() => setActiveSection("pannumber")}
            />
          </div>

          <p
            style={{
              color: "#C9C9C9",
              display: "inline-block",
              marginTop: "68px",
              height: "17px",
              fontSize: "12px",
              width: "200px",
            }}
          >
            Beneficiary Category
          </p>

          <div
            className="bene_acc_category_select mt-[137px]"
            ref={categorydropdownRef}
            onClick={() => {
              setdropdownOpen(!dropdownOpen);
              setIsDropdownOpen(false);
              setShowDatePicker(false); // Close date picker if it's open
            }}
            style={{
              height: dropdownOpen ? "198px" : "35px",
              outline: dropdownOpen ? "1px solid #E25869" : "",
              transition: dropdownOpen ? "none" : "",
              position: "relative",
            }}
          >
            <div className="bene_txn_category_select_select">
              <div
                className="txn_category_select_scroll_line"
                style={{ display: dropdownOpen ? "block" : "none" }}
              ></div>

              <span
                style={{
                  fontSize: "12px",
                  paddingLeft: dropdownOpen ? "9px" : "15px",
                  color: initCategory ? "#f9f9f9" : "#dadada",
                }}
              >
                {initCategory || "Select a category"}
              </span>
              <img
                src={arrow_down_red}
                alt="down"
                style={{
                  transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </div>

            {dropdownOpen && (
              <div className="txn_category_select_scroll">
                <div className="bene_input_div_element">
                  <img
                    src={search}
                    alt="search"
                    className="search_owner_element"
                    onClick={(e) => {
                      e.stopPropagation();
                      searchRef.current.focus();
                    }}
                  />
                  <input
                    ref={searchRef}
                    type="text"
                    value={categorySearch}
                    onChange={handleCategorySearchChange}
                    placeholder="Search"
                    onClick={(e) => e.stopPropagation()}
                    className="placeholder-[#dadada] text-xs"
                  />
                  <img
                    src={cancel}
                    alt="cancel"
                    className="search_owner_element"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCategorySearch("");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </div>

                {categorySearch === "" ? (
                  <div className="bene_txn_sort_by_category_scroll">
                    {categories.map((category) => (
                      <div key={category.id} className="category-wrapper">
                        <Tooltip
                          key={category.id}
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={category.name}
                        >
                          <button
                            className="bene_acc_category_select_scroll_com overflow-hidden whitespace-nowrap overflow-ellipsis"
                            onClick={() => {
                              handleClick(category.name);
                            }}
                          >
                            <span className="w-[157px]  overflow-hidden whitespace-nowrap overflow-ellipsis">
                              {category.name}
                            </span>
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="bene_Acc_filter_by_category_scroll">
                    {filteredCategories.map((category) => (
                      <div key={category.id} className="category-wrapper">
                        <Tooltip
                          key={category.id}
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={category.name}
                        >
                          <button
                            className="bene_txn_category_select_scroll_com"
                            onClick={() => {
                              handleClick(category.name);
                            }}
                          >
                            {category.name}
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div className="lower-div flex place-items-stretch">
          <div className="bene-acc-lower-div2 px-7 mb-5 flex-grow flex flex-row h-[413px]"> */}
        {/* <div className="flex flex-row justify-between mt-8 h-10 px-5 py-2.5 rounded-xl bg-[#3A5686]"> */}

        <div className="beneAccFilterSection2 pl-[30px] mt-[24px]">
          <div
            className="relative w-[200px]"
            onClick={() => {
              setActiveSection("accountnumber");
              setdropdownOpen(false);
              setShowDatePicker(false);
            }}
          >
            <span
              style={{
                color: "#C9C9C9",
                width: "35px",
                height: "19px",
                font: "normal normal 12px/19px Open Sans",
              }}
            >
              Account number
            </span>
            <input
              ref={searchInputRef}
              className="w-[200px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px]"
              type="text"
              placeholder="Enter account number"
              value={searchAccountText}
              name="accountnumber"
              onChange={handleInput}
              onFocus={() => setActiveSection("accountnumber")}
            />
            {/* {searchAccountText && (
                <img
                  src={closeIcon}
                  alt="Clear"
                  className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    setSearchAccountText("");
                    searchInputRef.current.focus();
                  }}
                />
              )}{" "}
              {!searchAccountText && (
                <img
                  src={search}
                  alt="search"
                  className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )} */}
          </div>

          <div
            className="relative w-[200px] mt-[20px]"
            onClick={() => {
              setActiveSection("ifscnumber");
              setdropdownOpen(false);
              setShowDatePicker(false);
            }}
          >
            <span
              style={{
                color: "#C9C9C9",
                width: "35px",
                height: "19px",
                font: "normal normal 12px/19px Open Sans",
              }}
            >
              IFSC code
            </span>
            <input
              ref={searchInputRef}
              className="w-[200px] h-[35px] px-4 py-2.5 rounded-xl bg-[#667CA3] text-[#F9F9F9] placeholder-[#DADADA] text-xs mt-[5px] mb-[20px]"
              type="text"
              placeholder="Enter IFSC code"
              value={searchIfscText}
              name="ifscnumber"
              onChange={handleInput}
              onFocus={() => setActiveSection("ifscnumber")}
            />
            {/* {searchAccountText && (
                <img
                  src={closeIcon}
                  alt="Clear"
                  className="bene-acc-viewall-search-close-image absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    setSearchAccountText("");
                    searchInputRef.current.focus();
                  }}
                />
              )}{" "}
              {!searchAccountText && (
                <img
                  src={search}
                  alt="search"
                  className="bene-acc-viewall-search-inner-image absolute right-[14px] w-4 h-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                />
              )} */}
          </div>
          <span
            style={{
              color: "#B4B4B4",
              width: "208px",
              height: "34px",
              font: "normal normal 12px/19px Open Sans",
              paddingRight: "8px",
            }}
          >
            Note: Account number and IFSC code must be filled to filter by bank
            details
          </span>
        </div>

        <div className="beneAccFilterSection3 pl-[30px] mt-[30px]">
          <span
            style={{
              color: "#C9C9C9",
              width: "62px",
              height: "17px",
              font: "normal normal 12px/19px Open Sans",
              marginBottom: "5px",
            }}
          >
            Date range
          </span>
          <div
            className="bene_acc_Calendar_main_div relative flex flex-col items-center cursor-pointer"
            // style={{ opacity: dropdownOpen ? 0 : 1 }}
            ref={dateRef}
            style={{
              width: "305px",
              height: showDatePicker ? "360px" : "35px",
              border: showDatePicker ? "1px solid #ff5860" : "none",
              // borderRadius: showDatePicker ? "10px" : "auto",
            }}
          >
            <div
              className="flex flex-row items-center justify-between rounded-[10px] bg-[#314D7E] cursor-pointer"
              style={{
                width: "305px",
                height: showDatePicker ? "" : "35px",
                border: showDatePicker ? "none" : "1px solid #ff5860",
                // borderRadius: showDatePicker ? "10px" : "auto",
              }}
              onClick={(e) => {
                setShowDatePicker((prevState) => !prevState);
                setdropdownOpen(false);
                setActiveSection("date");
                e.stopPropagation();
              }}
              // style={{ opacity: dropdownOpen ? 0 : 1 }}
            >
              <div
                className="beneAcc_date_heading_div cursor-pointer"
                style={{
                  borderTopLeftRadius: showDatePicker ? "7px" : "none",
                  borderLeft: showDatePicker ? "1px solid #ff5860" : "none",
                }}
                // onClick={() => setShowDatePicker((prev) => !prev)}
              >
                <div className="beneAcc_date_heading_left">
                  {" "}
                  {startDate ? formatDate(startDate) : "Select from date"}
                </div>
              </div>
              <img
                src={arrowforward}
                className="bene_acc_viewall_calendar_icon"
              />
              <div
                className="beneAcc_date_select_div cursor-pointer"
                style={{
                  borderTopRightRadius: showDatePicker ? "7px" : "none",
                  borderRight: showDatePicker ? "1px solid #ff5860" : "none",
                }}
                // onClick={() => setShowDatePicker((prev) => !prev)}
              >
                <div className="beneAcc_date_heading_right">
                  {endDate ? formatDate(endDate) : "Select to date"}
                </div>
              </div>
            </div>
            {showDatePicker && (
              <DatePickerComponent
                startDate={startDate}
                endDate={endDate}
                handleDateChange={handleDateChange}
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                showDatePicker={showDatePicker}
                yearDropdownRef={yearDropdownRef}
                dateRef={dateRef}
                setShowDatePicker={setShowDatePicker}
              />
            )}
          </div>
        </div>
        <div className="beneAccFilterbuttonContainer">
          <div
            className={`acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center ${
              isNoCategorySelected === true ? "" : "hover:bg-custom-cardHover"
            } w-[87px] h-[25px]`}
            onClick={() => {
              if (
                initCategory ||
                startDate ||
                endDate ||
                searchAccountText ||
                searchIfscText ||
                searchPanText
              ) {
                clearFilter();
              }
            }}
            style={{
              cursor:
                initCategory !== "" ||
                startDate !== null ||
                endDate !== null ||
                searchAccountText !== "" ||
                searchIfscText !== "" ||
                searchPanText !== ""
                  ? "pointer"
                  : "default",
              // opacity: dropdownOpen ? 0 : 1,
            }}
          >
            Clear
          </div>
          <div
            className={`acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px]  ${
              isNoCategorySelected === true ? "" : "hover:bg-custom-cardHover"
            }`}
            onClick={() => {
              if (
                initCategory ||
                (startDate && endDate) ||
                (searchAccountText && searchIfscText) ||
                searchPanText
              ) {
                filterCategory();
              }
            }}
            style={{
              backgroundColor: handleFilterEnable() ? "#F05A6A" : "#727272",
              cursor: handleFilterEnable() ? "pointer" : "default",
            }}
          >
            Apply
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneAccFilterSidebar;
{
  /* {searchPanText && (
              <img
                src={closeIcon}
                alt="Clear"
                className="bene-acc-viewall-search-close-image absolute right-[17px] top-[40px] transform -translate-y-1/2 cursor-pointer"
                onClick={() => {
                  setSearchPanText("");
                  searchInputRef.current.focus();
                }}
              />
            )}{" "}
            {!searchPanText && (
              <img
                src={search}
                alt="search"
                className="bene-acc-viewall-search-inner-image absolute right-[16px] w-4 h-4 top-[20px] transform -translate-y-1/2 cursor-pointer"
              />
            )} */
}
