import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";

const legacyUrl = env.REACT_APP_LEGACY_URL;
export const dashboardDetails = createAsyncThunk(
  "dashboard",
  async (values) => {
    console.log("ACCOUNT VALUES ", values);
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
    };
    console.log(
      "ACCOUNT DASH request options ",
      requestOptions,
      `${legacyUrl}/accounts/${values.account_type}/dashboard`
    );
    if (values.account_type !== "cards") {
      return await fetch(
        `${legacyUrl}/accounts/${values.account_type}/dashboard`,
        requestOptions
      )
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    } else {
      return await fetch(`${legacyUrl}/cards/dashboard`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    records: {},
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(dashboardDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(dashboardDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log("ACCOUNT DASH", action.payload);
      state.records = action.payload;
    });
    builder.addCase(dashboardDetails.rejected, (state, action) => {
      console.log("ERROR", action.payload);
      state.isError = true;
    });
  },
});

export default dashboardSlice.reducer;
