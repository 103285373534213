import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setHideBreadCrumb,
  setCurrentMenu,
} from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { checkUsertoken } from "../../redux/features/login/login";
import { CSSTransition } from "react-transition-group";
import entityIcon from "../../assets/newdashboardicon/entity_das.png";
import dateIcon from "../../assets/newdashboardicon/date_icon.png";
import arrowright from "../../assets/Accounts/Create/arrowright.png";
import arrowleft from "../../assets/Beneficiary Icons/arrowleft.png";
import ProfileIcon from "../../../src/assets/ProfileIcon.png";
import Transactionicon from "../../assets/newdashboardicon/transactionicon.png";
import Accounticon from "../../assets/newdashboardicon/accounticon.png";
import payicon from "../../assets/newdashboardicon/transaction_pay_blue_circle.png";
import receiveicon from "../../assets/newdashboardicon/transaction_recieve_blue_circle.png";
import approvalicon from "../../assets/newdashboardicon/transaction_approve_blue_circle.png";
import createicon from "../../assets/newdashboardicon/create_account_blue_circle.png";
import beneficiaryicon from "../../assets/newdashboardicon/beneficiary_transaction_blue_circle.png";
import internalicon from "../../assets/newdashboardicon/internal_transaction_blue_circle.png";
import HolidaysSidebarnew from "../../components/HomeDashboard/HolidaysSidebarnew";
import { getHolidays } from "../../redux/features/date/date";
import { beneTxnViewAllAcc } from "../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { beneViewAllAcc } from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import formatTotalBalance from "../../utils/formatTotalBalance";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../assets/Beneficiary Icons/inputloder.gif";
import ConnectedBankOwnerCard from "../../components/Accounts/OwnerAccounts/ConnectedBankOwnerCard";
import {
  ownerListViewAllAcc,
  resetpage,
  resetToggle,
  setshowsuccestoast,
} from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { toast, ToastContainer } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { Tooltip, tooltipClasses } from "@mui/material";
import RefreshBalanceToast from "../../components/_utils/RefreshBalanceToast";

const Maindashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contents = ["Entity Details", "Upcoming Bank Holidays"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const refreshData = useSelector((state) => state.refreshToken.data);
  const loading = useSelector((state) => state.homeDashboard.loading);
  const services = useSelector((state) => state.refreshToken.services);
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [hover, setHover] = useState(false);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);
  const [sidebarState, setSidebarState] = useState(false);
  const [dashboardOwnersData, setDashboardOwnersData] = useState([]);
  const [connectedBankingTotal, setConnectedBankingTotal] = useState("");
  const [ownerAccounts, setOwnerAccounts] = useState([]);
  const [ownerDataLoading, setOwnerDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBalanceSummaryOpen, setIsBalanceSummaryOpen] = useState(true);
  const [connectedBankingOwnerAccounts, setConnectedBankingOwnerAccounts] =
    useState([]);
  const { ownerAccBalanceLoading, showSuccessToast, ownerAccBalanceError } =
    useSelector((state) => state.ownerAllVerification);
  const [dataLoading, setDataLoading] = useState(false);

  const { beneTxnViewAllAccData } = useSelector(
    (state) => state.beneTxnViewAccData
  );

  const { beneViewAllAccData } = useSelector(
    (state) => state.beneAllVerification
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDataLoading(true);
        let queryParams = {
          page_no: 1,
          page_size: 50,
          approval_status: ["pending", "approved"],
        };
        dispatch(checkUsertoken());
        await dispatch(beneViewAllAcc(queryParams));

        queryParams = {
          page_no: 1,
          page_item_count: 50,
        };
        await dispatch(beneTxnViewAllAcc(queryParams));
        setDataLoading(false);
      } catch (error) {
        console.error(error);
        setDataLoading(false);
      }
    };

    fetchData();
    dispatch(checkUsertoken());
    dispatch(getHolidays());
  }, []);

  const getMoreOwners = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    const getConnectedBankingData = async () => {
      setOwnerDataLoading(true);
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          ownerListViewAllAcc({
            page_no: currentPage,
            page_size: 50,
            connected_banking: "Y",
          })
        );
        const newData = response?.payload?.owner_accounts || [];
        setConnectedBankingOwnerAccounts((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setConnectedBankingTotal(response?.payload?.total_balance);
        setOwnerAccounts(response?.payload?.owner_accounts);
        setDashboardOwnersData(response?.payload);
      } catch (error) {
        console.error(error);
      } finally {
        setOwnerDataLoading(false);
      }
    };
    getConnectedBankingData();
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!ownerAccBalanceLoading) {
      setTimeout(() => {
        toast.dismiss();
        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  const txnApprovalReq = useSelector(
    (state) => state.homeDashboard.txnApprovalReq
  );

  const beneTxnNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, contents.length - 1)
    );
    setShowSecondContent(!showSecondContent);
  };

  const beneTxnPrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowSecondContent(!showSecondContent);
  };

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };
  return (
    <div>
      <div className="bg-custom-appBack flex flex-col h-screen">
        <div className="dashboard-container-div">
          <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_welcome">
                <img
                  src={ProfileIcon}
                  alt=""
                  style={{ height: "50px", float: "left", margin: "10px" }}
                />
                <div className="dashboard_welcometext">
                  {refreshData?.employees ? (
                    <div style={{ margin: "10px" }}>
                      <span
                        className="font-semibold capitalize"
                        style={{ fontSize: "18px" }}
                      >
                        {`${refreshData.employees.first_name} ${refreshData.employees.last_name}`}
                      </span>
                      <br />
                      Welcome back
                    </div>
                  ) : null}
                </div>

                <br className="clearfix" />
              </div>
              <div className="dashboard_tranx">
                <div className="dashboard_tranxmain">
                  <div id="webviewd" style={{ width: "100%" }}>
                    <div className="dashboardcontentleft">
                      <img
                        src={Transactionicon}
                        alt=""
                        style={{
                          width: "20px",
                          float: "left",
                          margin: "0px 10px 0px 5px",
                        }}
                      />
                      Transactions
                    </div>
                    <div
                      className="dashboardcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                      onClick={() =>
                        navigate(
                          "/transaction/beneficiary_transaction/view_all",
                          {
                            state: { approvalStatus: "all" },
                          }
                        )
                      }
                    >
                      View All
                      <img
                        src={arrowright}
                        alt=""
                        style={{
                          width: "12px",
                          float: "right",
                          marginTop: "3px",
                        }}
                      />
                    </div>
                  </div>
                  <div id="mobileviewd" style={{ width: "100%" }}>
                    {isFirstDivVisible && (
                      <div className="dashboardcontentleft">
                        <img
                          src={Transactionicon}
                          alt=""
                          style={{
                            width: "20px",
                            float: "left",
                            margin: "0px 10px 0px 5px",
                          }}
                        />
                        Transactions
                      </div>
                    )}
                    {!isFirstDivVisible && (
                      <div
                        className="dashboardcontentleft"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsFirstDivVisible(true);
                        }}
                      >
                        <img
                          src={arrowleft}
                          alt=""
                          style={{
                            width: "20px",
                            float: "left",
                            margin: "0px 10px 0px 5px",
                          }}
                        />
                        Pay
                      </div>
                    )}
                    <div
                      className="dashboardcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                      onClick={() =>
                        navigate(
                          "/transaction/beneficiary_transaction/view_all",
                          {
                            state: { approvalStatus: "all" },
                          }
                        )
                      }
                    >
                      View All
                      <img
                        src={arrowright}
                        alt=""
                        style={{
                          width: "12px",
                          float: "right",
                          marginTop: "3px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div id="webviewd">
                  <div className="dashboard_tranxcontentmain">
                    <div className="tranxcontentmainfirst">
                      <div style={{ padding: "33px 20px" }}>
                        {beneTxnViewAllAccData && (
                          <div className="textcontent">
                            {beneTxnViewAllAccData?.total_count &&
                              beneTxnViewAllAccData?.total_count?.toLocaleString()}
                          </div>
                        )}
                        <div
                          className="textheader"
                          style={{ marginBottom: "20px" }}
                        >
                          {beneTxnViewAllAccData?.total_count === 0 ||
                          beneTxnViewAllAccData?.total_count === 1
                            ? "Beneficiary transaction"
                            : "Beneficiary transactions"}
                        </div>
                        <div className="textcontent">N/A</div>
                        <div className="textheader">Internal transactions</div>
                      </div>
                    </div>
                    <div style={{ display: "table-cell", width: "75%" }}>
                      <div className="table-container">
                        <CSSTransition
                          in={isFirstDivVisible}
                          timeout={300}
                          classNames="slide-right-left"
                          unmountOnExit
                        >
                          <div className="table-row">
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    //navigate("/create_transactions");
                                    //dispatch(setCurrentMenu("Transactions"));
                                    setIsFirstDivVisible(false);
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Pay"
                                      ? services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={payicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">pay</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 20px" }}
                                  >
                                    Create an outgoing transaction
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "RCTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Receive"
                                      ? services?.find(
                                          (service) =>
                                            service === "RCTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "RCTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "RCTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={receiveicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Receive</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 14px" }}
                                  >
                                    Create an incoming transaction
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "ABeneficiaryTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/transaction/beneficiary_transaction/transaction_single_approval"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    // setShowPopup(true);
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                          (service) =>
                                            service === "ABeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "ABeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                          ?.pending_transaction_count ===
                                          undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "ABeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={approvalicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Approve</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve created transactions
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                        <CSSTransition
                          in={!isFirstDivVisible}
                          timeout={300}
                          classNames="slide-right-left"
                          unmountOnExit
                        >
                          <div className="table-row">
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond"
                                style={{
                                  position: "relative",
                                  cursor: "default",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    backgroundColor: "#314D7E",
                                    fontSize: "12px",
                                    padding: "10px",
                                    top: "0px",
                                    cursor: "pointer",
                                    borderBottomRightRadius: "20px",
                                  }}
                                  onClick={() => {
                                    setIsFirstDivVisible(true);
                                  }}
                                >
                                  Pay
                                  <img
                                    src={arrowleft}
                                    alt=""
                                    style={{
                                      width: "12px",
                                      float: "left",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                                <div className="borderright">
                                  <div
                                    className="textheader"
                                    style={{ padding: "47px 20px" }}
                                  >
                                    Select the transaction type you want to
                                    create
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/transaction/beneficiary_transaction/create"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Receive"
                                      ? services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={beneficiaryicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Beneficiary</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Select category and pay a beneficiary
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => showToast()}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={internalicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Internal</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Transfer funds from one owner account to
                                    another
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="mobileviewd">
                  <div className="dashboard_tranxcontentmainm">
                    <div className="tranxcontentmainfirstm">
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {beneTxnViewAllAccData && (
                          <div className="textcontent">
                            {beneTxnViewAllAccData?.total_count &&
                              beneTxnViewAllAccData?.total_count?.toLocaleString()}
                          </div>
                        )}
                        <div className="textheader">
                          {beneTxnViewAllAccData?.total_count
                            ? beneTxnViewAllAccData?.total_count === 0 ||
                              beneTxnViewAllAccData?.total_count === 1
                              ? "Beneficiary transaction"
                              : "Beneficiary transactions"
                            : "Beneficiary transaction"}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          textAlign: "right",
                        }}
                      >
                        <div className="textcontent">N/A</div>
                        <div className="textheader">Internal transactions</div>
                      </div>
                      <br className="clearfix" />
                    </div>
                    <div className="table-containerm">
                      <CSSTransition
                        in={isFirstDivVisible}
                        timeout={300}
                        classNames="slide-right-left"
                        unmountOnExit
                      >
                        <div className="table-rowm">
                          {!loading && (
                            <div
                              className="tranxcontentmainsecondm"
                              onClick={() => {
                                if (
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) !== undefined
                                ) {
                                  setIsFirstDivVisible(false);
                                }
                              }}
                              style={{
                                transition: "all 0.6s ease-in-out",
                                transform:
                                  hover === "Pay"
                                    ? services?.find(
                                        (service) =>
                                          service === "CBeneficiaryTxn" ||
                                          service === "Admin"
                                      ) !== undefined
                                      ? "translateY(-50px)"
                                      : "translateY(0px)"
                                    : "translateY(0px)",
                                opacity:
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) === undefined
                                    ? "0.5"
                                    : "1",
                                cursor:
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) === undefined
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <div style={{ width: "15%", float: "left" }}>
                                <div>
                                  <img
                                    src={payicon}
                                    alt=""
                                    style={{
                                      width: "60px",
                                      margin: "5px auto 5px auto",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "85%",
                                  float: "left",
                                  padding: "5px 10px",
                                }}
                              >
                                <div className="textcontent">pay</div>
                                <div className="textheader">
                                  Create an outgoing transaction
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div>
                          )}
                          {!loading && (
                            <div
                              className="tranxcontentmainthirdm"
                              onClick={() => {
                                if (
                                  services?.find(
                                    (service) =>
                                      service === "RCTxn" || service === "Admin"
                                  ) !== undefined
                                ) {
                                }
                              }}
                              style={{
                                transition: "all 0.6s ease-in-out",
                                transform:
                                  hover === "Approval required"
                                    ? services?.find(
                                        (service) =>
                                          service === "RCTxn" ||
                                          service === "Admin"
                                      ) !== undefined
                                      ? "translateY(-50px)"
                                      : "translateY(0px)"
                                    : "translateY(0px)",
                                opacity:
                                  services?.find(
                                    (service) =>
                                      service === "RCTxn" || service === "Admin"
                                  ) === undefined
                                    ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                      txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0
                                      ? "0.5"
                                      : "0.5"
                                    : "1",
                                cursor:
                                  services?.find(
                                    (service) =>
                                      service === "RCTxn" || service === "Admin"
                                  ) === undefined
                                    ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                      undefined
                                      ? "not-allowed"
                                      : "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <div style={{ width: "15%", float: "left" }}>
                                <div>
                                  <img
                                    src={receiveicon}
                                    alt=""
                                    style={{
                                      width: "60px",
                                      margin: "5px auto 5px auto",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "85%",
                                  float: "left",
                                  padding: "5px 10px",
                                }}
                              >
                                <div className="textcontent">Receive</div>
                                <div className="textheader">
                                  Create an incoming transaction
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div>
                          )}
                          {!loading && (
                            <div
                              className="tranxcontentmainforthm"
                              onClick={() => {
                                if (
                                  services?.find(
                                    (service) =>
                                      service === "ABeneficiaryTxn" ||
                                      service === "Admin"
                                  ) !== undefined
                                ) {
                                  navigate(
                                    "/transaction/beneficiary_transaction/transaction_single_approval"
                                  );
                                  dispatch(setCurrentMenu("Transactions"));
                                  // setShowPopup(true);
                                }
                              }}
                              style={{
                                transition: "all 0.6s ease-in-out",
                                transform:
                                  hover === "Approval required"
                                    ? services?.find(
                                        (service) =>
                                          service === "ABeneficiaryTxn" ||
                                          service === "Admin"
                                      ) !== undefined
                                      ? "translateY(-50px)"
                                      : "translateY(0px)"
                                    : "translateY(0px)",
                                opacity:
                                  services?.find(
                                    (service) =>
                                      service === "ABeneficiaryTxn" ||
                                      service === "Admin"
                                  ) === undefined
                                    ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                      txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0
                                      ? "0.5"
                                      : "0.5"
                                    : "1",
                                cursor:
                                  services?.find(
                                    (service) =>
                                      service === "ABeneficiaryTxn" ||
                                      service === "Admin"
                                  ) === undefined
                                    ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                      undefined
                                      ? "not-allowed"
                                      : "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <div style={{ width: "15%", float: "left" }}>
                                <div>
                                  <img
                                    src={approvalicon}
                                    alt=""
                                    style={{
                                      width: "60px",
                                      margin: "5px auto 5px auto",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "85%",
                                  float: "left",
                                  padding: "5px 10px",
                                }}
                              >
                                <div className="textcontent">Approve</div>
                                <div className="textheader">
                                  Approve created transactions
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div>
                          )}
                        </div>
                      </CSSTransition>
                      <CSSTransition
                        in={!isFirstDivVisible}
                        timeout={300}
                        classNames="slide-right-left"
                        unmountOnExit
                      >
                        <div className="table-rowm">
                          {!loading && (
                            <div
                              className="tranxcontentmainthirdm"
                              onClick={() => {
                                if (
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) !== undefined
                                ) {
                                  navigate(
                                    "/transaction/beneficiary_transaction/create"
                                  );
                                  dispatch(setCurrentMenu("Transactions"));
                                }
                              }}
                              style={{
                                transition: "all 0.6s ease-in-out",
                                transform:
                                  hover === "Approval required"
                                    ? services?.find(
                                        (service) =>
                                          service === "CBeneficiaryTxn" ||
                                          service === "Admin"
                                      ) !== undefined
                                      ? "translateY(-50px)"
                                      : "translateY(0px)"
                                    : "translateY(0px)",
                                opacity:
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) === undefined
                                    ? txnApprovalReq?.summary
                                        ?.pending_transaction_count ===
                                        undefined ||
                                      txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0
                                      ? "0.5"
                                      : "0.5"
                                    : "1",
                                cursor:
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) === undefined
                                    ? txnApprovalReq?.summary
                                        ?.pending_transaction_count === 0 ||
                                      undefined
                                      ? "not-allowed"
                                      : "not-allowed"
                                    : "pointer",
                              }}
                            >
                              <div style={{ width: "15%", float: "left" }}>
                                <div>
                                  <img
                                    src={beneficiaryicon}
                                    alt=""
                                    style={{
                                      width: "60px",
                                      margin: "5px auto 5px auto",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "85%",
                                  float: "left",
                                  padding: "5px 10px",
                                }}
                              >
                                <div className="textcontent">Beneficiary</div>
                                <div className="textheader">
                                  Select category and pay a beneficiary
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div>
                          )}
                          {!loading && (
                            <div
                              className="tranxcontentmainforthm"
                              onClick={() => showToast()}
                            >
                              <div style={{ width: "15%", float: "left" }}>
                                <div>
                                  <img
                                    src={internalicon}
                                    alt=""
                                    style={{
                                      width: "60px",
                                      margin: "5px auto 5px auto",
                                    }}
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "85%",
                                  float: "left",
                                  padding: "5px 10px",
                                }}
                              >
                                <div className="textcontent">Internal</div>
                                <div className="textheader">
                                  Transfer funds from one owner account to
                                  another
                                </div>
                              </div>
                              <br className="clearfix" />
                              <ToastContainer />
                            </div>
                          )}
                        </div>
                      </CSSTransition>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard_tranx">
                <div className="dashboard_tranxmain">
                  <div className="dashboardcontentleft">
                    <img
                      src={Accounticon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "left",
                        margin: "0px 10px 0px 5px",
                      }}
                    />
                    Beneficiary Accounts
                  </div>
                  <div
                    className="dashboardcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                    onClick={() =>
                      navigate("/accounts/beneficiary_account/view_all", {
                        state: { approvalStatus: "all" },
                      })
                    }
                  >
                    View All
                    <img
                      src={arrowright}
                      alt=""
                      style={{
                        width: "12px",
                        float: "right",
                        marginTop: "3px",
                      }}
                    />
                  </div>
                </div>
                <div id="webviewd">
                  <div className="dashboard_tranxcontentmain">
                    <div className="tranxcontentmainfirst">
                      <div style={{ padding: "33px 20px" }}>
                        {beneViewAllAccData && (
                          <div className="textcontent">
                            <span>
                              {beneViewAllAccData?.summary
                                ?.pending_accounts_count || 0}
                            </span>
                          </div>
                        )}
                        <div
                          className="textheader"
                          style={{ marginBottom: "20px" }}
                        >
                          {beneViewAllAccData?.summary?.pending_accounts_count
                            ? beneViewAllAccData?.summary
                                ?.pending_accounts_count === 0 ||
                              beneViewAllAccData?.summary
                                ?.pending_accounts_count === 1
                              ? "Pending account"
                              : "Pending accounts"
                            : "Pending account"}
                        </div>
                        {beneViewAllAccData && (
                          <div className="textcontent">
                            <span>
                              {beneViewAllAccData?.summary
                                ?.approved_accounts_count || 0}
                            </span>
                          </div>
                        )}
                        <div className="textheader">
                          {beneViewAllAccData?.summary?.approved_accounts_count
                            ? beneViewAllAccData?.summary
                                ?.approved_accounts_count === 0 ||
                              beneViewAllAccData?.summary
                                ?.approved_accounts_count === 1
                              ? "Approved account"
                              : "Approved accounts"
                            : "Approved account"}
                        </div>
                      </div>
                    </div>
                    {!loading && (
                      <div
                        className="tranxcontentmainsecond"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate("/accounts/beneficiary_account/create");
                            dispatch(setCurrentMenu("Accounts"));
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          transform:
                            hover === "Pay"
                              ? services?.find(
                                  (service) =>
                                    service === "BUAccount" ||
                                    service === "Admin"
                                ) !== undefined
                                ? "translateY(-50px)"
                                : "translateY(0px)"
                              : "translateY(0px)",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "0.5"
                              : "1",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div className="borderright">
                          <div>
                            <img
                              src={createicon}
                              alt=""
                              style={{
                                width: "60px",
                                margin: "0px auto 10px auto",
                              }}
                            />
                          </div>
                          <div className="textcontent">Create</div>
                          <div
                            className="textheader"
                            style={{ padding: "0px 20px" }}
                          >
                            Create a beneficiary account
                          </div>
                        </div>
                      </div>
                    )}
                    {!loading && (
                      <div
                        className="tranxcontentmainthird"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate(
                              "/accounts/beneficiary_account/approval_required"
                            );
                            dispatch(setCurrentMenu("Accounts"));
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          transform:
                            hover === "Approval required"
                              ? services?.find(
                                  (service) =>
                                    service === "BAAccount" ||
                                    service === "Admin"
                                ) !== undefined
                                ? "translateY(-50px)"
                                : "translateY(0px)"
                              : "translateY(0px)",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) === undefined
                              ? txnApprovalReq?.summary
                                  ?.pending_transaction_count === undefined ||
                                txnApprovalReq?.summary
                                  ?.pending_transaction_count === 0
                                ? "0.5"
                                : "0.5"
                              : "1",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) === undefined
                              ? txnApprovalReq?.summary
                                  ?.pending_transaction_count === 0 || undefined
                                ? "not-allowed"
                                : "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div className="borderright">
                          <div>
                            <img
                              src={approvalicon}
                              alt=""
                              style={{
                                width: "60px",
                                margin: "5px auto 5px auto",
                              }}
                            />
                          </div>
                          <div className="textcontent">Approve</div>
                          <div
                            className="textheader"
                            style={{ padding: "0px 20px" }}
                          >
                            Approve created accounts
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="tranxcontentmainforth"
                      style={{ cursor: "default", opacity: "0.5" }}
                    ></div>
                  </div>
                </div>
                <div id="mobileviewd">
                  <div className="dashboard_tranxcontentmainm">
                    <div className="tranxcontentmainfirstm">
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          textAlign: "left",
                        }}
                      >
                        {beneViewAllAccData && (
                          <div className="textcontent">
                            {beneViewAllAccData?.summary
                              ?.pending_accounts_count !== undefined &&
                            beneViewAllAccData?.summary
                              ?.pending_accounts_count !== null
                              ? beneViewAllAccData.summary
                                  .pending_accounts_count
                              : 0}
                          </div>
                        )}
                        <div className="textheader">
                          {beneViewAllAccData?.summary?.pending_accounts_count
                            ? beneViewAllAccData?.summary
                                ?.pending_accounts_count === 0 ||
                              beneViewAllAccData?.summary
                                ?.pending_accounts_count === 1
                              ? "Pending account"
                              : "Pending accounts"
                            : "Pending account"}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          float: "left",
                          textAlign: "right",
                        }}
                      >
                        {beneViewAllAccData && (
                          <div className="textcontent">
                            <span>
                              {beneViewAllAccData?.summary
                                ?.approved_accounts_count || 0}
                            </span>
                          </div>
                        )}
                        <div className="textheader">
                          {beneViewAllAccData?.summary?.approved_accounts_count
                            ? beneViewAllAccData?.summary
                                ?.approved_accounts_count === 0 ||
                              beneViewAllAccData?.summary
                                ?.approved_accounts_count === 1
                              ? "Approved account"
                              : "Approved accounts"
                            : "Approved account"}
                        </div>
                      </div>
                      <br className="clearfix" />
                    </div>
                    {!loading && (
                      <div
                        className="tranxcontentmainsecondm"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate("/accounts/beneficiary_account/create");
                            dispatch(setCurrentMenu("Accounts"));
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          transform:
                            hover === "Pay"
                              ? services?.find(
                                  (service) =>
                                    service === "BUAccount" ||
                                    service === "Admin"
                                ) !== undefined
                                ? "translateY(-50px)"
                                : "translateY(0px)"
                              : "translateY(0px)",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "0.5"
                              : "1",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div style={{ width: "15%", float: "left" }}>
                          <div>
                            <img
                              src={createicon}
                              alt=""
                              style={{
                                width: "60px",
                                margin: "5px auto 5px auto",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "85%",
                            float: "left",
                            padding: "5px 10px",
                          }}
                        >
                          <div className="textcontent">Create</div>
                          <div className="textheader">
                            Create a beneficiary account
                          </div>
                        </div>
                        <br className="clearfix" />
                      </div>
                    )}
                    {!loading && (
                      <div
                        className="tranxcontentmainthirdm"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate(
                              "/accounts/beneficiary_account/approval_required"
                            );
                            dispatch(setCurrentMenu("Accounts"));
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          transform:
                            hover === "Approval required"
                              ? services?.find(
                                  (service) =>
                                    service === "BAAccount" ||
                                    service === "Admin"
                                ) !== undefined
                                ? "translateY(-50px)"
                                : "translateY(0px)"
                              : "translateY(0px)",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) === undefined
                              ? txnApprovalReq?.summary
                                  ?.pending_transaction_count === undefined ||
                                txnApprovalReq?.summary
                                  ?.pending_transaction_count === 0
                                ? "0.5"
                                : "0.5"
                              : "1",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) === undefined
                              ? txnApprovalReq?.summary
                                  ?.pending_transaction_count === 0 || undefined
                                ? "not-allowed"
                                : "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div style={{ width: "15%", float: "left" }}>
                          <div>
                            <img
                              src={approvalicon}
                              alt=""
                              style={{
                                width: "60px",
                                margin: "5px auto 5px auto",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            width: "85%",
                            float: "left",
                            padding: "5px 10px",
                          }}
                        >
                          <div className="textcontent">Approve</div>
                          <div className="textheader">
                            Approve created accounts
                          </div>
                        </div>
                        <br className="clearfix" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_summery">
                <div className="dashboard_hedder">
                  <div className="Caraouseltab">
                    <div
                      className="CarouselDottabl"
                      onClick={beneTxnPrev}
                      disabled={currentIndex === 0}
                    >
                      <div style={{ width: "max-content", margin: "0px auto" }}>
                        <img
                          src={entityIcon}
                          alt=""
                          style={{
                            float: "left",
                            margin: "0px 5px",
                            width: "15px",
                          }}
                        />
                        {contents[0]}
                      </div>
                    </div>
                    <div
                      className="CarouselDottabr"
                      onClick={beneTxnNext}
                      disabled={currentIndex === 1}
                    >
                      <div style={{ width: "max-content", margin: "0px auto" }}>
                        <img
                          src={dateIcon}
                          alt=""
                          style={{
                            float: "left",
                            margin: "0px 5px",
                            width: "15px",
                          }}
                        />
                        {contents[1]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerscroll">
                  <div
                    className={`${
                      !showSecondContent
                        ? "showFirstContentd"
                        : "hideFirstContentd"
                    }`}
                  >
                    {currentIndex === 0 && (
                      <>
                        <div className="dashboard_contenthedder">
                          <div className="textcontent">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={refreshData?.entity?.entity_name}
                              placement="bottom-start"
                            >
                              <div className="textcontent">
                                {refreshData?.entity ? (
                                  <span>
                                    {refreshData.entity.entity_name.toLowerCase()}
                                  </span>
                                ) : null}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="textheader">Entity name</div>
                          <div
                            style={{
                              padding: "15px 0px 0px 0px",
                              fontSize: "14px",
                              color: "#4DDD37",
                            }}
                          >
                            ₹
                            {connectedBankingTotal !== undefined
                              ? formatTotalBalance(connectedBankingTotal).amount
                              : "00.00"}
                            {connectedBankingTotal !== undefined &&
                              formatTotalBalance(connectedBankingTotal)
                                .prefix == "crores"}
                            <span
                              style={{ fontSize: "12px", color: "#DADADA" }}
                            >
                              &nbsp;in&nbsp;
                            </span>
                            {ownerAccounts?.length}&nbsp;
                            <span
                              style={{ fontSize: "12px", color: "#DADADA" }}
                            >
                              {ownerAccounts?.length === 0 ||
                              ownerAccounts?.length === 1
                                ? "account"
                                : "accounts"}
                            </span>
                          </div>
                          <div
                            style={{
                              padding: "0px 0px 15px 0px",
                              fontSize: "12px",
                              color: "#DADADA",
                            }}
                          >
                            Total balance&nbsp;
                            {formatTotalBalance(connectedBankingTotal).prefix ==
                            "crores"
                              ? "in crores"
                              : ""}
                          </div>
                        </div>
                        <div className="Caraouselcontent">
                          <div className="Caraouselcontentleft">
                            Balances Summary
                          </div>
                          <div
                            className="Caraouselcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                            onClick={async () => {
                              dispatch(resetpage());
                              dispatch(resetToggle());
                              navigate("/accounts/owner/view_all");
                            }}
                          >
                            View All
                            <img
                              src={arrowright}
                              alt=""
                              style={{
                                width: "12px",
                                float: "right",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="Caraouselcontentmain">
                          {ownerDataLoading ? (
                            <div className=" flex justify-center items-center">
                              <img
                                src={Loader}
                                alt="Loading"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          ) : (
                            <>
                              <InfiniteScroll
                                style={{ width: "inherit" }}
                                dataLength={ownerAccounts?.length || 0}
                                next={getMoreOwners}
                                hasMore={
                                  currentPage <
                                  Math.ceil(
                                    dashboardOwnersData?.total_count / 50
                                  )
                                }
                                loader={
                                  <div className="loader-container m-auto justify-center items-center flex">
                                    <img src={Loader} alt="" />
                                  </div>
                                }
                                scrollableTarget="infinityScrollOwnersView"
                                scrollThreshold={0.8}
                              >
                                {connectedBankingOwnerAccounts?.map((owner) => (
                                  <ConnectedBankOwnerCard
                                    key={owner?.id}
                                    owner={owner}
                                    ownerAccounts={ownerAccounts}
                                    setOwnerAccounts={setOwnerAccounts}
                                    ownerAccBalanceLoading={
                                      ownerAccBalanceLoading
                                    }
                                    ownerAccBalanceError={ownerAccBalanceError}
                                    updateTotalBalance={
                                      setConnectedBankingTotal
                                    }
                                    total_balance={connectedBankingTotal}
                                    isBalanceSummaryOpen={isBalanceSummaryOpen}
                                    connectedBankingOwnerAccounts={
                                      connectedBankingOwnerAccounts
                                    }
                                    setConnectedBankingOwnerAccounts={
                                      setConnectedBankingOwnerAccounts
                                    }
                                  />
                                ))}
                              </InfiniteScroll>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`${
                      showSecondContent
                        ? "showSecondContentd"
                        : "hideSecondContentd"
                    }`}
                  >
                    {currentIndex === 1 && (
                      <>
                        <div className="dashboard_contenthedder"></div>
                        <div
                          className="Caraouseldatemain"
                          style={{ padding: "0px" }}
                        >
                          <HolidaysSidebarnew
                            state={sidebarState}
                            setState={setSidebarState}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maindashboard;
