import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

import { checkUsertoken } from "../../../redux/features/login/login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { deleteDevice } from "../../../redux/features/devices/devices";

const DeleteDevicePopup = ({ close, deviceId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteDevice = async (id) => {
    dispatch(checkUsertoken());
    try {
      const response = await dispatch(deleteDevice(id));

      if (response.payload.status === true) {
        navigate("/settings/Devices", {
          state: {
            isDeviceDeleted: true,
          },
        });
      } else {
        toast.error(response.payload.errors.base[0], toast_position);
      }
    } catch (err) {
      console.error(err, "err");
      toast.error("Failed To delete!", toast_position);
    } finally {
      close(false);
    }
  };

  return (
    <>
      <div
        className="fixed w-screen top-0 left-0 h-screen z-[9998] "
        style={{
          backgroundColor: "#000000",
          opacity: "0.8",
        }}
      ></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-70% flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl"
          style={{ width: "400px", maxWidth: "600px" }}
        >
          <div className="mx-4">
            <h6 className="text-custom-orange pb-1 md:font-bold">
              Delete Device
            </h6>
            <div className="flex items-center pb-2"></div>
            <div className="mb-3">
              <span
                className="text-white text-sm"
                style={{ wordWrap: "break-word" }}
              >
                Are you sure, you want to delete this device?
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "6%",
                margin: "0px 50px",
                width: "100%",
              }}
              className="px-4 pt-4 m-4"
            >
              <Button
                onClick={() => {
                  close(false);
                }}
                sx={{
                  borderColor: "none",
                  color: "#fff",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleDeleteDevice(deviceId)}
                sx={{
                  borderRadius: "20px",
                  color: "#fff !important",
                  boxShadow: "0px 3px 6px #00000033",
                  textTransform: "capitalize",
                  backgroundColor: "#69B76F",
                  border: "1px solid transparent",
                  "&:hover": {
                    backgroundColor: "#69B76F",
                  },
                  marginRight: "30px",
                  width: "110px",
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteDevicePopup;
