import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
export const initiateEmail = createAsyncThunk(
  "initiateEmail",
  async (values) => {
    const { txn_id } = values;
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        txn_id: txn_id,
      }),
    };

    return await fetch(
      `${legacyUrl}/transactions/send_settle_confirmation`,
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log("error", error));
  }
);

const initiateEmailState = createSlice({
  name: "initiateEmailState",
  initialState: {
    isLoading: null,
    response: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(initiateEmail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(initiateEmail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
    });
    builder.addCase(initiateEmail.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export default initiateEmailState.reducer;