import React, { useState } from "react";
import EditFeeModal from "../EditFeeModal";

function EditFee({ title, fee, setFee }) {
  const [editModal, editModalState] = useState(false);
  return (
    <div className="bg-[#506994] w-full h-48 flex flex-col justify-center gap-9 rounded-4xl shadow-lg p-10">
      {editModal && (
        <EditFeeModal
          title={title}
          setFee={setFee}
          fee={fee}
          editModalState={editModalState}
        />
      )}
      <div className="flex justify-between items-center w-full">
        <div>
          <div className="text-[#C9C9C9] text-lg font-semibold">{title}</div>
        </div>
        <div
          className="w-5 h-5 cursor-pointer"
          onClick={() => editModalState(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        </div>
      </div>
      <div>
        <div className="text-lg text-white">{fee[title]}</div>
        <div className="text-xs text-[#C9C9C9]">
          {title === "Wallet" ? "Mobile wallet" : title} fee
        </div>
      </div>
    </div>
  );
}

export default EditFee;
