import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import logo from "../../../assets/TransactionDashboardIcons/InstantPay card icon.png";
import { VendorTxnDetailChildBgMapper } from "../../../services/VendorTxnDetailBgMapper";
import trimSentence from "../../../utils/trimSentence";
import { VendorTxnTextMapper } from "../../../services/VendorTxnTextMapper";
import CardLogo from "../../_utils/logo/CardLogo";

const VendorDetailComponent = (props) => {
  const getApprovalIcon = () => {
    let status = VendorTxnTextMapper(props?.vendor_account_status);
    if (status === "Pending") {
      return "pending";
    }
    if (status === "Approved") {
      return "approved";
    }
    if (status === "Denied") {
      return "denied";
    }
    else {
      return "pending";
    }
  }
  return (
    <Box
      sx={{ backgroundColor: VendorTxnDetailChildBgMapper(props.approval_status), '&:hover': { background: '#fafafa33' }, cursor: "pointer" }}
      className="text-white drop-shadow-xl flex flex-col
        rounded-4xl
        px-6 py-6
        overflow-hidden">
      <div className="h-[30%] flex flex-row justify-between">
        <div className="self-center w-3/5 text-gray-300 text-sm 3xl:text-base 5xl:text-lg 6xl:text-2xl font-bold">
          {props.container === "FF" ? "Agent Details" : "Vendor Details"}
        </div>
        <div className="self-center flex flex-row justify-between">
          <div className="self-center">
            <img alt="" className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
              src={require(`../../../assets/TxnDetails/${getApprovalIcon()}.png`)} />
          </div>
          <div className="self-center text-sm pr-8 pl-2
           text-white antialiased truncate ...">
            {VendorTxnTextMapper(props.vendor_account_status)}
          </div>
          <div className="self-center">
            <img alt="" className="w-5 h-5 3xl:w-6 3xl:h-6 4xl:h-8 4xl:w-8"
              src={require("../../../assets/TransactionDashboardIcons/arrow_next.png")} />
          </div>
        </div>
      </div>
      <div className="flex pt-4">
        <div className="self-center text-base
           text-white truncate ...">
          {props.vendor_name}
        </div>
      </div>
      <div className="flex flex-row justify-between pt-4">
        <div className="self-center w-3/5">
          <div className="text-base
           text-white truncate ...">{props.vendor_bank_acc_no}</div>
          <div className="text-gray-300 text-xs antialiased  pb-0">
            Account number
          </div>
        </div>
        <div className="w-2/5
            -mr-8 -mb-6
            flex flex-col justify-end">
          <CardLogo bank={props?.bank_logo}
            divCssProps={
              'rounded-tl-4xl'
            }
          />
        </div>
      </div>
    </Box>
  );
};
export default VendorDetailComponent;
