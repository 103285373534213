import React, { useEffect } from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import CardLogo from "../../../_utils/logo/CardLogo";
import { AccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";
import { filterButtonViewAll } from "../../../../services/approvalStatus";
import { useNavigate } from "react-router-dom";
import accTransform from "../../../../utils/accTransform";
import { useSelector } from "react-redux";
import fetchBalance, {
  balanceDetails,
} from "../../../../redux/features/accounts/Owner/fetchBalance";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch } from "react-redux";
import formatCurrency from "../../../../utils/CurrencyFormater";
import timeDifference from "../../../../utils/accountTimeFormat";

const AccountOwnerDetailBannerComponent = (props) => {
  const dispatch = useDispatch();
  const accountName = props.account_name;
  const accountType = props.account_type;
  const bankLogo = bankLogoMapper(props.bank_name);
  const approvalStatusText = AccountStatusTextMapper(
    accountType,
    props.approval_status
  );
  const textColor = filterButtonViewAll(props?.approval_status);
  const balance =
    useSelector((state) => state.ownerBalance.records?.available_balance) ||
    props?.balance;

  const lastFetchedAt =
    useSelector((state) => state.ownerBalance.records?.last_fetched_at) ||
    props?.last_fetched_at;

  const accountNumber = accTransform(props.account_number, true);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(
      balanceDetails({
        account_id: props?.account_id,
      })
    );
  }, []);

  const fromColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "from-[#0B0F0D]";
      case "Blocked":
        return "from-[#3D2525]";
      case "Archived":
        return "from-[#303030]";
      case "N":
        return "from-[#462E19]";
      case "P":
        return "from-[#304F4F]";
      default:
        return "from-[#303030]";
    }
  };
  const toColorGradient = () => {
    switch (props?.approval_status) {
      case "Y":
        return "to-[#3F675A]";
      case "Blocked":
        return "to-[#A65757]";
      case "Archived":
        return "to-[#5A5A5A]";
      case "N":
        return "to-[#BF793C]";
      case "P":
        return "to-[#5D9898]";
      default:
        return "to-[#5A5A5A]";
    }
  };

  const vendorPaymentDiv = () => {
    let vendorDiv, accountLinkedDiv;
    if (props?.accountLinkedDiv) {
      accountLinkedDiv = (
        <span className="flex flex-row gap-2">
          <span className="self-center text-[#69B76F]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span className="self-center text-sm font-thin text-gray-500">
            Account linked
          </span>
        </span>
      );
    } else {
      accountLinkedDiv = (
        <span className="flex flex-row gap-2">
          <span className="self-center text-[#FF5860]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span className="self-center text-sm font-thin text-gray-500">
            Account linked
          </span>
        </span>
      );
    }
    if (props?.vendor_payment) {
      vendorDiv = (
        <span className="flex flex-row gap-2">
          <span className="self-center text-[#69B76F]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span className="self-center text-sm font-thin text-gray-500">
            Vendor payments
          </span>
        </span>
      );
    } else {
      vendorDiv = (
        <span className="flex flex-row gap-2">
          <span className="self-center text-[#FF5860]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span className="self-center text-sm font-thin text-gray-500">
            Vendor payments
          </span>
        </span>
      );
    }
    return (
      <span className="flex flex-col gap-1">
        {vendorDiv}
        {accountLinkedDiv}
      </span>
    );
  };

  const topCardRight = () => {
    if (balance) {
      return (
        <span className="pr-4 flex flex-col justify-between">
          <span className="tracking-wider self-end italic font-extrabold text-2xl text-[#35AA3D] truncate ...">
            {"₹ " + formatCurrency(balance)}
          </span>
          <span className="self-end text-gray-500 text-sm font-normal truncate ...">
            {lastFetchedAt
              ? "Balance - " +
                timeDifference(new Date(), new Date(lastFetchedAt * 1000))
              : null}
          </span>
        </span>
      );
    } else {
      return (
        <span className="self-center text-gray-700 truncate ... text-xl">
          Balance not available
        </span>
      );
    }
  };

  const bottomRightCard = () => {
    return (
      <div className="flex flex-col w-full h-full justify-center">
        <span className="text-white font-semibold self-center text-base">
          Wire transfer activated
        </span>
      </div>
    );
  };

  const fetchAccountBalance = () => {
    dispatch(checkUsertoken());
    dispatch(
      balanceDetails({
        account_id: props?.account_id,
      })
    );
  };

  return (
    <div className="h-48 w-full drop-shadow-2xl rounded-4xl  flex flex-row overflow-hidden">
      <div
        className={`z-20 bg-white w-[50%] flex flex-col justify-between p-6`}
      >
        <span className="text-gray-700 font-medium text-2xl capitalize antialiased tracking-wider truncate ...">
          {accountNumber}
        </span>
        <span className="-mt-2 w-24 rounded-4xl ring-1 ring-gray-300">
          <CardLogo bank={bankLogo} divCssProps={"rounded-4xl"} />
        </span>
        {vendorPaymentDiv()}
      </div>
      <div className={`bg-white h-full w-[50%] flex flex-col`}>
        <div className="w-full h-1/2 py-4 px-6">
          <div className="h-full self-center flex flex-row gap-1 divide-x divide-gray-300">
            <div className="w-[70%] flex flex-col h-full justify-center truncate ...">
              {topCardRight()}
            </div>
            <div
              className="cursor-pointer h-full group w-[30%] self-center flex flex-row justify-evenly"
              onClick={() => {
                fetchAccountBalance();
              }}
            >
              <span className="text-gray-700 self-center group-hover:text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              <span className="hidden sm:block text-gray-700  group-hover:text-gray-500 self-center text-sm font-semibold truncate ...">
                Refresh
              </span>
            </div>
          </div>
        </div>
        <div
          className={`drop-shadow-xl px-6 py-4 w-full h-1/2 rounded-br-4xl rounded-tl-4xl bg-gradient-to-r ${fromColorGradient()} ${toColorGradient()}`}
        >
          {bottomRightCard()}
        </div>
      </div>
    </div>
  );
};

export default AccountOwnerDetailBannerComponent;
