import React, { useState, useEffect } from 'react'
import SideBar from '../../SideBar'
import ArrowIcon from '../../../../../../assets/TransactionDashboardIcons/arrow_next.png'
import { useDispatch, useSelector } from 'react-redux'
import { verifySpecificCIN } from '../../../../../../redux/features/Settings/CIN'
import { viewGSTINDetails } from '../../../../../../redux/features/Settings/Utilies'

function RegisteredNumberDetails({ type, num }) {
    const dispatch = useDispatch()
    const CINData = useSelector((state) => state.cin.verified_data)
    const PANData = useSelector((state) => state.utilities.data)

    const [showSideBar, setShowSideBar] = useState(false)
    const [sideBarData, setSidebarData] = useState([])

    useEffect(() => {
        if (num) {
            if (type === "CIN") {

                dispatch(verifySpecificCIN({ cin: num }))
            } else {
                dispatch(viewGSTINDetails({ pan: num }))
                setSidebarData(PANData?.gst_details)
            }
        }
    }, [])

    return (
        <div className='bg-[#506994] w-[48%] h-56 rounded-4xl shadow-lg'>
            <div className='bg-[#3A5686] w-full h-44 rounded-4xl shadow-lg p-5'>
                <div className='h-4/5 flex flex-col justify-between'>
                    <div className='text-[#C9C9C9]'>{type === "CIN" ? "CIN & DIN Details" : "PAN & GST Details"}</div>
                    <div>
                        <div className='text-white'>{num ? num : "Not Given"}</div>
                        <div className='text-[#C9C9C9] text-xs'>{type === "CIN" ? "CIN no." : "Pan no."}</div>
                    </div>
                </div>
            </div>
            <div className='px-5 mt-3 flex justify-between items-center'>
                <div className='text-white'>{!sideBarData ? "0" : sideBarData?.length} {type === "CIN" ? "Directors found" : "Associated GSTs found"}</div>
                <div onClick={() => setShowSideBar(true)} className={`text-[#FF7F33] text-xs hover:underline ${(sideBarData?.length <= 0) || !sideBarData ? "pointer-events-none" : "hover:cursor-pointer"}`}>{type === "CIN" ? "Show directors" : "Show all"}</div>
            </div>
            <SideBar
                state={showSideBar}
                setState={setShowSideBar}
                title={
                    <div className='flex items-center gap-6'>
                        <div className='text-2xl 3xl:text-3xl 5xl:4xl w-96 text-white'>
                            {type === "CIN" ? "Directors found" : "Associated GSTs"}
                        </div>
                    </div>
                }>
                <div className='flex flex-col px-10'>
                    <div className='py-5 flex justify-center items-center text-[#D3D3D3] border-dashed border-b border-gray-600'>Total {sideBarData?.length} Directors</div>
                    {type === "CIN" ? sideBarData?.map((item, index) =>
                        <div>
                            <div className='flex justify-between items-center w-full py-5 px-5'>
                                <div className='flex items-center gap-4'>
                                    <div className='flex flex-col'>
                                        <div className='text-white '>{item.director_name}</div>
                                        <div className='text-[#C9C9C9] text-xs'>Director name</div>
                                    </div>
                                </div>
                                <div className='hover:cursor-pointer'>
                                    <img src={ArrowIcon} className='w-4 h-4' />
                                </div>
                            </div>
                            <hr className='border-gray-600' />
                        </div>
                    ) :
                        sideBarData?.map((item, index) =>
                            <div>
                                <div className='flex justify-between items-center w-full py-5 px-5'>
                                    <div className='flex items-center gap-4'>
                                        <div className='flex flex-col'>
                                            <div className='text-white '>{item.gstin_no}</div>
                                            <div className='text-[#C9C9C9] text-xs'>GST number</div>
                                        </div>
                                    </div>
                                    <div className='hover:cursor-pointer'>
                                        <img src={ArrowIcon} className='w-4 h-4' />
                                    </div>
                                </div>
                                <hr className='border-gray-600' />
                            </div>
                        )}
                </div>
            </SideBar>
        </div>
    )
}

export default RegisteredNumberDetails