import colorCode from "./colorCodeMapper";

const trimSentence = (sentence, limit) => {
  limit = limit ? limit : 10;
  if (sentence?.length > limit) {
    return sentence.slice(0, limit) + '...';
  } else {
    return sentence;
  }
}
export default trimSentence;