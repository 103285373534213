import React, { useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import ArrowDown from "../../assets/OwnerAccount/arrowdown5.png";
const SelectAccountBalance = ({
  filterPayload,
  setFilterPayload,
  selectSortAmt,
  setSelectSortAmt,
  owner,
}) => {
  const accountBalanceRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accountBalanceRef.current &&
        !accountBalanceRef.current.contains(event.target)
      ) {
        setSelectSortAmt(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div
      className="owner_sort_by_acc_balance "
      style={{
        position: owner ? "" : "relative",
        left: owner ? "" : "70px",
      }}
    >
      {owner ? (
        <>
          <p style={{ color: "#C9C9C9", height: "22px" }}>Account balance</p>
        </>
      ) : (
        <p style={{ color: "#C9C9C9", height: "22px" }}>
          Sort by account balance
        </p>
      )}
      <Button
        ref={accountBalanceRef}
        className="bank_high_to_low_select"
        onClick={() => {
          setSelectSortAmt(!selectSortAmt);
        }}
        disabled={true}
        style={{
          height: selectSortAmt ? "110px" : owner ? "37px" : "39px",
          width: owner ? "216px" : "189px",
          outline: selectSortAmt ? "1px solid #E25869" : "",
          opacity: "0.4",
        }}
      >
        <div className="bank_high_to_low_select_select">
          <div
            className="bank_high_to_low_select_scroll_line"
            style={{
              display: selectSortAmt ? "block" : "none",
              width: owner ? "160px" : "171px",
            }}
          ></div>

          <span
            style={{
              fontSize: selectSortAmt ? "12px" : "14px",
              paddingLeft: selectSortAmt ? "9px" : "15px",
            }}
          >
            {filterPayload.sortAccountNo.length > 0
              ? filterPayload.sortAccountNo
              : "Select order"}
          </span>
          <img
            src={ArrowDown}
            alt="down"
            style={{
              height: "12px",
              transform: selectSortAmt ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
        <div className="bank_high_to_low_select_scroll">
          <Button
            className="bank_high_to_low_select_scroll_com hover:bg-[#506994]"
            style={{
              width: owner ? "205px" : "189px ",
              backgroundColor:
                filterPayload.sortAccountNo === "High to low" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (filterPayload.sortAccountNo === "High to low") {
                setFilterPayload({
                  ...filterPayload,
                  sortAccountNo: "",
                });
              } else {
                setFilterPayload({
                  ...filterPayload,
                  sortAccountNo: "High to low",
                });
              }
              setSelectSortAmt(false);
            }}
          >
            High to low
          </Button>
          <Button
            className="bank_high_to_low_select_scroll_com hover:bg-[#506994]"
            style={{
              width: owner ? "205px" : "189px",
              backgroundColor:
                filterPayload.sortAccountNo === "Low to high" ? "#3A5686" : "",
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (filterPayload.sortAccountNo === "Low to high") {
                setFilterPayload({
                  ...filterPayload,
                  sortAccountNo: "",
                });
              } else {
                setFilterPayload({
                  ...filterPayload,
                  sortAccountNo: "Low to high",
                });
              }
              setSelectSortAmt(false);
            }}
          >
            Low to high
          </Button>
        </div>
      </Button>
    </div>
  );
};

export default SelectAccountBalance;
