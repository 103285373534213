import React from "react";
import { useState, useRef } from "react";
import { Box, Checkbox } from "@mui/material";
import "./index.css";
import formatCurrency from "../../../utils/CurrencyFormater";
import trimSentence from "../../../utils/trimSentence";

const MultiSelectVendorCard = (props) => {
  const accTransform = (acc) => {
    function isNumeric(value) {
      return /^-?\d+$/.test(value);
    }
    if (isNumeric(acc)) {
      return `⋅⋅⋅⋅ ${acc.slice(-4)}`;
    } else {
      return acc;
    }
  };
  const amountProp = formatCurrency(props.amount);
  const propStatus = props.status;
  const handleRadioBTClick = props.handleRadioBTClick;
  const elementRef = useRef(null);
  const datum = props.datum;
  const [checkedState, setCheckedState] = useState(props.selectedTxns[props.txn_id]);

  const handleCardClick = () => {
    let value = props.txn_id;
    let checked = !props.selectedTxns[props.txn_id] ? true : false;
    handleRadioBTClick(null, value, checked, datum);
  };

  return (
    <>
      <div
        className={
          `py-1 px-2 2xl:py-2 2xl:px-4 4xl:py-4 4xl:px-6 flex flex-col
          gap-4
          flex-grow rounded-3xl 4xl:rounded-4xl shadow-customCard cursor-pointer
          ${(props.parent === "approvalTxns") ?
            (props.selectedTxns[props.txn_id] ? 'bg-custom-selectedCard' : 'bg-custom-notSelectedCardApproval') :
            (props.selectedTxns[props.txn_id] ? 'bg-custom-selectedCard' : 'bg-custom-notSelectedCardSettlement')}`
        }
        onClick={handleCardClick}
        key={props.txn_id}>
        <div className="flex semi-bold justify-between items-center my-1 mx-2">
          <div className="font-semibold
             text-[#4DDD37]
            text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased" style={{ color: propStatus }}>
            ₹ {amountProp}
          </div>
          <div className="text-gray-300 visible self-center
              text-xs 3xl:text-sm 4xl:text-base 5xl:text-lg
              antialiased">
            {props.posting_date}
          </div>
        </div>
        <div className="flex justify-between items-center my-1 mx-2">
          <div className="w-3/5
            flex text-base 3xl:text-lg 4xl:text-xl 5xl:text-2xl 6xl:text-3xl antialiased">
            <span className="self-center text-white truncate capitalize">
              {trimSentence(props.vendor_name, 18)}
            </span>
          </div>
          <div>
            {props.selectedTxns[props.txn_id] ? (
              <img
                className="w-6 h-6"
                src={require("../../../assets/Approve transactions/selected.png")}
                alt=""
              />
            ) : (
              <img
                className="w-6 h-6"
                src={require("../../../assets/Approve transactions/not selected.png")}
                alt=""
              />
            )}
          </div>
        </div>
      </div >
    </>
  );
};

export default MultiSelectVendorCard;
