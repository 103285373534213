import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import TxnStatusCard from "../../../../components/ApprovalTxn/VendorApproval/TxnStatusCard";
import "./index.css";

const SettlementStatus = () => {
  const vendorApprovalResponse = useSelector(
    (state) => state.approveTxn.vendor
  );
  const transactions = vendorApprovalResponse?.transactions[0];
  const amount = transactions?.amount;
  const status = transactions?.approval_status;

  const txns = vendorApprovalResponse
    ? vendorApprovalResponse.transactions
    : [];

  if (txns.length > 1) {
    var total_amt = txns.reduce(function (previousValue, currentValue) {
      return {
        value: Number(previousValue.amount) + Number(currentValue.amount),
      };
    });
  }

  return (
    <Box
      className="txnStatusContainer"
      sx={{ backgroundColor: status === "Y" ? "#6ba673" : "#BC5555" }}
    >
      <Box className="leftbodyVApp">
        {status === "Y" ? (
          <img
            src={require("../../../../assets/Approve transactions/Success screen.png")}
            width={"100%"}
            alt=""
            srcSet=""
          />
        ) : (
          <img
            src={require("../../../../assets/Approve transactions/Failed screen.png")}
            width={"100%"}
            alt=""
            srcSet=""
          />
        )}
        {status === "Y" ? (
          <h3>Transaction under processing!</h3>
        ) : (
          <h3>Vendor transaction has been declined</h3>
        )}
      </Box>
      <Box className="rightbody1">
        {!(txns.length > 1) ? (
          <TxnStatusCard
            name={transactions?.vendor_name}
            status={status}
            amount={amount}
            txn={transactions?.txn_id}
          />
        ) : (
          <TxnStatusCard
            name={`${txns.length} Transactions`}
            status={status}
            amount={total_amt.value}
            txn_len={txns.length}
          />
        )}
      </Box>
    </Box>
  );
};

export default SettlementStatus;
