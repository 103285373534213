import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { setBufferState } from "../../../../redux/features/bufferState/bufferState";
import accTransform from "../../../../utils/accTransform";
import "./index.css";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";

const ExistingUser = (props) => {
  const dispatch = useDispatch();
  const handleVendorSelection = () => {
    if (!(props.value.approval_status === "Y" || props.value.approval_status === "1"))
      return;
    dispatch(setBufferState({ linkUser: props.value }));
    props.enableNextButton(true);
    props.handleClose();
  }

  return (
    < div
      className={`flex flex-row bg-custom-lightGray  w-full h-fit
        ${(props.value.approval_status === "Y" || props.value.approval_status === "1") && "cursor-pointer"}
        ${!(props.value.approval_status === "Y" || props.value.approval_status === "1") && "opacity-40"}
        ${props.value.user_id === props.linkUser?.user_id && "border-2 border-custom-green"}
        rounded-large 4xl:rounded-customXl 6xl:rounded-custom2Xl overflow-hidden drop-shadow-xl text-custom-offwhite
        h-20 3xl:h-24 4xl:h-28 5xl:h-34 6xl:h-40
        text-base 3xl:text-xl`}
      onClick={handleVendorSelection} >
      <div className="w-24 h-full flex flex-row justify-start gap-2 3xl:gap-5 p-5">
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 512 512">
          <defs>
            <linearGradient id="linear-gradient" y1="-38.449" x2="1" y2="-38.449" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#0279ff" />
              <stop offset="1" stop-color="#ff5860" />
            </linearGradient>
            <clipPath id="clip-profile_1">
              <rect width="512" height="512" />
            </clipPath>
          </defs>
          <g id="profile_1" data-name="profile – 1" clip-path="url(#clip-profile_1)">
            <g id="_1177568" data-name="1177568" transform="translate(10 20)">
              <path id="Path_674" data-name="Path 674" d="M492,246c0,135.864-110.136,246-246,246S0,381.864,0,246,110.136,0,246,0,492,110.136,492,246Zm0,0" fill="url(#linear-gradient)" />
              <path id="Path_675" data-name="Path 675" d="M305.441,241.124a93.162,93.162,0,1,0-111.859,0C133.081,263.834,89.9,322.271,89.9,390.61H118.73c0-72.115,58.67-130.782,130.786-130.782S380.3,318.495,380.3,390.61h28.828c0-68.339-43.178-126.776-103.683-149.486ZM185.178,166.663A64.334,64.334,0,1,1,249.512,231,64.4,64.4,0,0,1,185.178,166.663Zm0,0" transform="translate(-3.512 -2.871)" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
      <div
        className="flex w-4/5 flex-col justify-center items-start px-3 py-2 3xl:px-4 3xl:py-4 5xl:px-5 5xl:py-10"
      >
        <div className="truncate ...
       text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl
        capitalize font-bold">
          {props.value.first_name + " " + props.value.last_name}
        </div>
        <div className="truncate ...
       text-base xl:text-xl 3xl:text-2xl 5xl:text-5xl
         capitalize font-semibold opacity-60">
          {props.value.user_id}
        </div>
      </div>
    </div >
  );
};

export default ExistingUser;

