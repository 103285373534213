import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import StatusIMG from "../../../../assets/TxnDetails/Group 1303.png";
import arrowImg from "../../../../assets/TransactionDashboardIcons/arrow_next.png";

const QuickAccessMenu = (props) => {
  const navigate = useNavigate();
  const bgColor = props.bg_color;

  return (
    <Box className="h-full drop-shadow-xl flex flex-col
      rounded-4xl
      overflow-hidden"
      sx={{
        backgroundColor: bgColor,
      }}>

      <Box className="p-2 w-full h-full flex flex-row justify-evenly divide-x divide-[#1d1d1d55]">
        {
          props?.disableRecepients == true ? (<></>) : (
            <Box className="opacity-40 text-center h-full w-1/2 flex flex-col justify-center gap-1 hoverEffect p-2">
              <div className="flex justify-center h-4/5">
                <Box className="self-center w-10 xl:w-12">
                  <img
                    src={require("../../../../assets/TxnDetails/Group 1284.png")}
                    alt="document"
                  />
                </Box>
              </div>
              <div className="flex justify-center h-1/5">
                <Box className=" h-8/12 self-center text-white
                text-xs antialiased truncate...">
                  Additional recepients
                </Box>
              </div>
            </Box>
          )
        }
        <Box className="cursor-pointer text-center h-full w-1/2 flex flex-col justify-center gap-1 hoverEffect p-2"
          onClick={() => {
            window.location.href = props?.tx_history_url;
          }}>
          <div className="flex justify-center h-4/5">
            <Box className="self-center w-10 xl:w-12">
              <img
                src={require("../../../../assets/TxnDetails/transaction-history.png")}
                alt="document"
              />
            </Box>
          </div>
          <div className="flex justify-center h-1/5">
            <Box className=" h-8/12 self-center text-white
                text-xs antialiased truncate...">
              Transaction history
            </Box>
          </div>
        </Box>
      </Box>

      <div className="py-1 -mr-2 -ml-2 ">
        <hr className="w-full border-[#1d1d1d55]" />
        <hr className="w-full border-[#1d1d1d55]" />
      </div>
      <div className="w-full flex justify-center">
        <Box className="px-4 py-2 w-full gap-0">
          <div className="cursor-pointer hoverEffect px-0">
            <div className="py-0 flex cursor-pointer items-center"
              onClick={() => navigate('/roles')}>
              <div className="p-2 flex flex-row gap-2 items-center w-full h-full rounded-3xl">
                <div className="w-2/12">
                  <img
                    src={StatusIMG}
                    className="w-6 h-6 xl:w-8 xl:h-8"
                  />
                </div>
                <div className="w-8/12 flex justify-start text-base text-white items-center">
                  Roles
                </div>
              </div>
              <div className="w-2/12 flex justify-center">
                <img
                  src={arrowImg}
                  alt="arrowImg"
                  className="w-6 h-6"
                />
              </div>
            </div>
          </div>
        </Box>
      </div>
    </Box>
  );
};

export default QuickAccessMenu;
