import React, { useEffect, useState } from "react";
import CustomizedStepper from "../../../../../components/CreateTxn/CustomizedStepper";
import { useDispatch, useSelector } from "react-redux";
import EntityPanDetails from "../../../../../components/Settings/Management/Entities/CreateEntity/EntityPanDetails";
import EntityCinDetails from "../../../../../components/Settings/Management/Entities/CreateEntity/EntityCinDetails";
import EntityAddress from "../../../../../components/Settings/Management/Entities/CreateEntity/EntityAddress";
import { createEntity } from "../../../../../redux/features/Settings/Management/entities/index";
import { useNavigate } from "react-router-dom";

const CreateEntity = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const createdEntityRes = useSelector(
    (state) => state.entities.createEntityRes
  );
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const navigate = useNavigate();
  const [registerNewEntityData, setRegisterNewEntityData] = useState({
    entity_name: "",
    entity_pan: "",
    entity_address: "",
    entity_pincode: "",
    entity_activate_date: "",
    cin_number: "",
    acquired_by: "",
  });

  const steps = [
    "Enter PAN details and CIN details",
    "Enter entity details",
    "Confirm and create entity",
  ];

  const handleNext = () => {
    if (activeStep === 2) {
      dispatch(createEntity(registerNewEntityData));
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    enableNextButton(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (createdEntityRes?.status) {
      navigate(
        "/settings/management/entities/" + createdEntityRes?.entities?.entity_id
      );
    }
  }, [createdEntityRes]);

  return (
    <div className="mt-24 bg-custom-appBack flex flex-row justify-center min-h-screen">
      <div className="self-center h-screen overflow-y-auto overflow-x-hidden w-full hide-scrollbar xl:w-10/12">
        <div className="h-fit flex flex-col gap-2 3xl:gap-4 5xl:gap-6 py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20">
          <div className="h-[15%] flex flex-row justify-center">
            <CustomizedStepper
              nextButtonEnabled={nextButtonEnabled}
              activeStep={activeStep}
              steps={steps}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </div>
          <div className="h-4/5 flex flex-row justify-center pb-24">
            <div className="w-10/12 h-full">
              {activeStep === 0 && (
                <div className="flex justify-between gap-8 p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EntityPanDetails
                      title="Entity PAN details"
                      bgColor="bg-[#29487E]"
                      registerNewEntityData={registerNewEntityData}
                      setRegisterNewEntityData={setRegisterNewEntityData}
                      enableNextButton={enableNextButton}
                    />
                  </div>
                  <div className="w-1/2 h-fit">
                    <EntityCinDetails
                      registerNewEntityData={registerNewEntityData}
                      setRegisterNewEntityData={setRegisterNewEntityData}
                      enableNextButton={enableNextButton}
                    />
                  </div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="flex flex-row justify-end p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EntityAddress
                      activeStep={activeStep}
                      registerNewEntityData={registerNewEntityData}
                      setRegisterNewEntityData={setRegisterNewEntityData}
                      enableNextButton={enableNextButton}
                    />
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <>
                  <div className="flex flex-row justify-between p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-[49%] flex flex-col justify-between gap-8 h-fit">
                      <EntityPanDetails
                        registerNewEntityData={registerNewEntityData}
                        setRegisterNewEntityData={setRegisterNewEntityData}
                        enableNextButton={enableNextButton}
                        bgColor="bg-[#29487E]"
                        title="Entity PAN details"
                        activeStep={activeStep}
                      />
                      {
                        <EntityCinDetails
                          registerNewEntityData={registerNewEntityData}
                          setRegisterNewEntityData={setRegisterNewEntityData}
                          enableNextButton={enableNextButton}
                          activeStep={activeStep}
                        />
                      }
                    </div>
                    <div className="w-[49%]">
                      <EntityAddress
                        registerNewEntityData={registerNewEntityData}
                        setRegisterNewEntityData={setRegisterNewEntityData}
                        enableNextButton={enableNextButton}
                        activeStep={activeStep}
                      />
                    </div>
                  </div>
                  <div className="bg-[#29487E] flex flex-col m-4 3xl:m-6 4xl:m-8 5xl:m-10 6xl:m-12 drop-shadow-2xl rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl">
                    <div>
                      <div className="bg-[#29487E] rounded-large 4xl:rounded-customXl 5xl:rounded-custom2Xl flex flex-col justify-start gap-4 p-6 2xl:p-8 3xl:p-10 4xl:p-12 5xl:p-16 6xl:p-18">
                        <div className="text-white text-sm xl:text-base 3xl:text-lg 5xl:text-xl">
                          The followings settings can be edited only after
                          creation of the <b>Entity</b>:
                        </div>
                        <div className="flex flex-row justify-between gap-2">
                          {[
                            "Grades",
                            "Authorization matrix",
                            "Transaction matrix",
                            "Gateway matrix",
                          ].map((item, index) => {
                            return (
                              <div className="flex flex-row gap-2 items-center justify-between xl:gap-4 3xl:gap-6 5xl:gap-8">
                                <span> ⚪ </span>
                                <span className="text-white self-center font-semibold italic font-sans text-base xl:text-xl 3xl:text-2xl 5xl:text-4xl">
                                  {item}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEntity;
