import React, { useEffect, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { beneTxnModalAccount } from "../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import dataLoading from "../../../assets/Beneficiary Icons/inputloder.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRef } from "react";
import Cancel from "../../../assets/CreateTransactionIcons/cancelrecent.png";
import Search from "../../../assets/CreateTransactionIcons/search.png";
import CircleCancel from "../../../assets/CreateTransactionIcons/cancel.png";
import { checkUsertoken } from "../../../redux/features/login/login";
import { useLocation } from "react-router-dom";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import NoAccountFound from "../../../assets/Beneficiary Icons/noaccfound.png";

const BeneTxnViewModal = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const approvalStatus = location.state ? location.state.approvalStatus : "";
  const inputRefAccount = useRef(null);
  const [loading, setLoading] = useState(true);
  const [accBeneData, setAccBeneData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [beneAccount, setBeneAccount] = useState(false);
  const [viewAllAccData, setViewAllAccData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatus, setCurrentStatus] = useState(approvalStatus);

  const { beneTxnAccountData } = useSelector(
    (state) => state.beneTransactionAccounts
  );
  useEffect(() => {
    const fetchAllAccountData = async () => {
      try {
        if (currentPage === 1) {
          setLoading(true);
        }

        let queryParams = {
          page_no: currentPage,
          page_size: 50,
          approval_status: [
            "approved",
            "pending",
            "denied",
            "blocked",
            "archived",
          ],
          search_string: searchText.length > 0 ? searchText : undefined,
        };
        dispatch(checkUsertoken());
        const response = await dispatch(beneTxnModalAccount(queryParams));
        const newData = response?.payload?.beneficiary_accounts || [];

        setViewAllAccData(response?.payload);
        setAccBeneData((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    fetchAllAccountData();
  }, [dispatch, currentPage, searchText]);

  useEffect(() => {
    setCurrentStatus(approvalStatus);
  }, []);

  const mainRef = useRef(null);

  const handleSearchUserChange = (e) => {
    setSearchText(e.target.value);
  };

  const clearText = () => {
    setBeneAccount(false);
    setSearchText("");
  };

  const handleClose = (e) => {
    // props.setIsSidebarOpen(true);
    e.stopPropagation();
    props.onClosed();
  };

  const handleButtonClick = (dataItem) => {
    props.onAccountSelect(
      dataItem.name.trim(),
      dataItem.bank_acc_no.trim(),
      dataItem.ifsc_code.trim(),
      dataItem.id,
      dataItem.bank_name,
      dataItem.category,
      dataItem.pan_no
    );
  };
  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const scrollToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };
  return (
    <>
      <div
        className="flex justify-center items-center fixed top-[50%] left-1/2"
        style={{ zIndex: "21486764", transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-5 "
          style={{ width: "50vw", maxWidth: "600px" }}
        >
          <div className="flex justify-between gap-5">
            {!beneAccount && (
              <h6 className="text-[#DADADA] font-semibold text-lg text-center pr-6 pl-9 pt-5 pb-5">
                Select Beneficiary Account:
              </h6>
            )}
            <div className="flex items-center mr-4 gap-2 pr-6 pl-9 pt-5 pb-5">
              {!beneAccount ? (
                <button
                  className="smallInputIcon"
                  onClick={() => {
                    setBeneAccount(true);
                  }}
                >
                  <img src={Search} alt="search" />
                </button>
              ) : (
                <div className="input_searchDiv">
                  <img
                    src={Search}
                    alt="search"
                    className="search_owner_acc_bar"
                    onClick={() => inputRefAccount.current.focus()}
                  />
                  <input
                    ref={inputRefAccount}
                    autoFocus
                    type="text"
                    value={searchText}
                    onChange={handleSearchUserChange}
                    placeholder="Email ID, A/C ID, A/C name, Phone no."
                  />
                  <img
                    src={CircleCancel}
                    alt="search"
                    className="search_owner_acc_bar"
                    onClick={clearText}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
              <button className="owner_popup_cancel" onClick={handleClose}>
                <img src={Cancel} alt="cancel" />
              </button>
            </div>
          </div>
          <div
            id="infinityScrollContainer"
            className="w-full overflow-y-auto h-[545px] pr-7 pl-9 custom-scroll-container relative"
          >
            {loading ? (
              <div className="absolute inset-0 flex justify-center items-center">
                <img
                  src={dataLoading}
                  alt="Loading"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
            ) : (
              <>
                {accBeneData && accBeneData.length > 0 ? (
                  <div ref={mainRef} className="achieved_main">
                    <div className="achieved_scroll">
                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                        <InfiniteScroll
                          style={{ width: "inherit" }}
                          dataLength={accBeneData?.length}
                          next={fetchMoreData}
                          hasMore={
                            currentPage <
                            Math.ceil(beneTxnAccountData.total_count / 50)
                          }
                          loader={
                            searchText && accBeneData.length === 0 ? null : (
                              <div className="absolute inset-0 flex justify-center items-center">
                                <img
                                  src={dataLoading}
                                  alt="Loading"
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </div>
                            )
                          }
                          scrollableTarget="infinityScrollContainer"
                          scrollThreshold={0.8}
                        >
                          {accBeneData?.map((val, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                handleButtonClick(val);
                              }}
                              className="group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl hover:bg-[#506994] bg-[#29487E] overflow-hidden cursor-pointer"
                              style={{
                                marginBottom: "1rem",
                              }}
                            >
                              <div className="flex flex-row -mr-4">
                                <div
                                  className="w-4/5 antialiased truncate"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textTransform: "capitalize",
                                    color: getStatusColor(val.approval_status),
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {val.name.toLowerCase()}
                                  <div className="text-[#D5D5D5] self-center text-xs antialiased font-normal">
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      Beneficiary
                                    </span>
                                    &nbsp;
                                    <span
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      name
                                    </span>
                                  </div>
                                </div>
                                <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                  <button className="group">
                                    <div className="text-[#FAFAFA] visible self-center text-base antialiased pr-[14px] group-hover:invisible group-hover:hidden font-normal">
                                      {val.ifsc_code}
                                      {val.ifsc_code && (
                                        <div className="text-[#D5D5D5] text-xs antialiased mr-[-40px] font-normal">
                                          IFSC Code
                                        </div>
                                      )}
                                    </div>
                                  </button>
                                  <div className="flex flex-row justify-start">
                                    <div
                                      className="w-2 4xl:w-2"
                                      style={{
                                        backgroundColor: getStatusColor(
                                          val.approval_status
                                        ),
                                        height: "20px",
                                      }}
                                    ></div>
                                    <div
                                      className={`flex group-hover:px-4 h-[20px] w-0 mt-1 self-center group-hover:visible group-hover:block group-hover:w-full text-xs antialiased font-semibold assignedTxn ${
                                        val.approval_status === "pending"
                                          ? "assignedTxn-pending-box"
                                          : val.approval_status === "approved"
                                          ? "assignedTxn-approved-box"
                                          : val.approval_status === "rejected"
                                          ? "assignedTxn-rejected-box"
                                          : val.approval_status === "blocked"
                                          ? "assignedTxn-blocked-box"
                                          : val.approval_status === "archived"
                                          ? "assignedTxn-archived-box"
                                          : ""
                                      }`}
                                      style={{
                                        marginTop: "-20px",
                                        paddingTop: "2px",
                                      }}
                                    >
                                      {val.approval_status === "rejected"
                                        ? "denied"
                                        : val.approval_status}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-row justify-between">
                                <div className="w-4/5 antialiased text-[#FAFAFA] text-base self-center font-normal">
                                  {formatAccountNumber(val.bank_acc_no)}
                                  <div className="text-[#D5D5D5] self-center text-xs antialiased font-normal">
                                    Account number
                                  </div>
                                </div>
                              </div>
                              <div className="select-acc-viewall-logo flex justify-center self-center h-6 cursor-pointer text-xs antialiased font-semibold">
                                <img
                                  src={require("../../../assets/IfscLogo/" +
                                    IfscCodeMapper(val?.ifsc_code))}
                                  alt="bank"
                                  className="w-[80px] h-[20px] flex items-center justify-center self-center"
                                />
                              </div>
                            </div>
                          ))}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                    <div className="flex justify-center items-center">
                      <img
                        className="w-[255px] h-[260px]"
                        src={NoAccountFound}
                        alt="No Data"
                      />
                    </div>
                    <div className="text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                      <span>No beneficiary accounts found</span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneTxnViewModal;
