import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import trimSentence from "../../../../utils/trimSentence";
import { useSelector } from "react-redux";

const TxnStatusCardFF = (props) => {
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  return (
    <>
      <div className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        <div className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6">
          <div className="flex flex-col gap-6 p-4 mt-4 pb-4">
            <div className="text-2xl 4xl:text-4xl font-semibold text-[#27B882] w-full">
              ₹ {CurrencyFormater(props.amount)}
            </div>
          </div>
          <div
            className="mt-6 text-black font-semibold text-xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl
            capitalize antialiased truncate ..."
          >
            {props.name}
          </div>
          <Box className="dashedLine"></Box>
          <div className="flex w-4/5 self-center flex-row justify-between">
            <div className="flex flex-col">
              <div
                className="text-lg 3xl:text-xl 5xl:text-2xl 6xl:text-3xl
                    antialiased truncate ..."
              >
                InstantPay
              </div>
              <div className="text-gray-500 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                Payment method
              </div>
            </div>
            <div className="flex flex-row justify-center w-2/6">
              <img
                className="w-[80%] self-center"
                src={require("../../../../assets/Banks/" +
                  "InstantPay" +
                  ".png")}
                alt=""
              />
            </div>
          </div>
        </div>
        {props.status === "Y" ||
        props.status === "P" ||
        props.status === "Processing" ? (
          <div
            className="w-full cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl flex justify-evenly items-center"
            style={{
              backgroundColor:
                props.status === "Y" ||
                props.status === "P" ||
                props.status === "Processing"
                  ? "#7FB085"
                  : "#9F5353",
            }}
          >
            {services.includes("Settle_txn") && (
              <>
                <div
                  className={`flex flex-col w-1/3 gap-4 items-center justify-center`}
                  onClick={() =>
                    navigate(`/settlement_required/ff_transactions`)
                  }
                >
                  <div className="w-2/6 flex justify-center">
                    <img
                      width={"70%"}
                      src={require("../../../../assets/CreateTransactionIcons/Settle.png")}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p>
                    Settle <br /> transaction
                  </p>
                </div>
                <div className="h-4/5 w-0.5 bg-gray-300" />
              </>
            )}
            {!(props.txn_len > 1) && (
              <>
                <div
                  className={`flex flex-col w-1/3 gap-2 items-center justify-center`}
                  onClick={() => navigate(`/ff/txn/${props.txn}`)}
                >
                  <div className="w-2/6 flex justify-center">
                    <img
                      width="70%"
                      src={require("../../../../assets/CreateTransactionIcons/View details.png")}
                      alt=""
                      srcSet=""
                      className=""
                    />
                  </div>
                  <p>
                    View transaction <br /> details
                  </p>
                </div>
                <div className="h-4/5 w-0.5 bg-gray-300" />
              </>
            )}
            <div
              className={`flex flex-col w-1/3 gap-2 items-center justify-center`}
              onClick={() => navigate(`/field_force_transactions`)}
            >
              <div className="w-2/6 flex justify-center">
                <img
                  width={"70%"}
                  src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                  alt=""
                  srcSet=""
                  className=""
                />
              </div>
              <p>
                Go to <br /> dashboard
              </p>
            </div>
          </div>
        ) : (
          <div
            className="w-full h-[30%] cursor-pointer xl:h-[25%] text-white rounded-4xl bg-[#7fb085] flex justify-evenly items-center"
            style={{
              backgroundColor:
                props.status === "Y" || props.status === "P"
                  ? "#7FB085"
                  : "#9F5353",
            }}
          >
            {!(props.txn_len > 1) && (
              <>
                <div
                  className={`flex flex-col ${
                    props.txn_len > 1 ? "w-1/2" : "w-1/3"
                  } gap-2 items-center justify-center`}
                  onClick={() => navigate(`/ff/txn/${props.txn}`)}
                >
                  <div className="w-2/6 flex justify-center">
                    <img
                      width="70%"
                      src={require("../../../../assets/CreateTransactionIcons/View details.png")}
                      alt=""
                      srcSet=""
                    />
                  </div>
                  <p>
                    View transaction <br /> details
                  </p>
                </div>
                <div className="h-4/5 w-0.5 bg-gray-300" />
              </>
            )}
            {/* <div className="h-4/5 w-0.5 bg-gray-300" /> */}
            <div
              className={`flex flex-col w-2/6 gap-2 items-center justify-center`}
              onClick={() => navigate(`/field_force_transactions`)}
            >
              <div className="w-2/6 flex justify-center">
                <img
                  width="70%"
                  src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                  alt=""
                  srcSet=""
                />
              </div>
              <p>
                Go to <br /> dashboard
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TxnStatusCardFF;
