import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast_position } from "../Settings/Authorization";
import { toast } from "react-toastify";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const createClientAccount = createAsyncThunk(
  "createClientAccount",
  async (values) => {
    const {
      name,
      contact_no,
      email_id,
      pan_no,
      gstin,
    } = values ? values : { name: null, contact_no: null, email_id: null, pan_no: null, gstin: null };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        name: name,
        contact_no: contact_no,
        email_id: email_id,
        pan_no: pan_no,
        gstin: gstin,
      }),
    };

    return await fetch(
      `${legacyUrl}/accounts/client/create`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

export const createAcc = createAsyncThunk(
  "createAcc",
  async ({ type_of_txn, payload, editOption }) => {
    var endPoint = editOption && !(editOption === "4") ? `${legacyUrl}/accounts/${type_of_txn}/edit` :
      `${legacyUrl}/accounts/${type_of_txn}/create`;
    var requestOptions = {
      method: editOption ? "POST" : "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(payload),
    };

    return await fetch(
      endPoint,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);

export const addCard = createAsyncThunk(
  "addCard",
  async ({ type_of_txn, payload }) => {
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify(payload),
    };

    return await fetch(
      `${legacyUrl}/cards/upsert`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
);



const accountsSlice = createSlice({
  name: "createAcc",
  initialState: {
    client: null,
    createAccResponse: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(createClientAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createClientAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.client = action.payload;
    });
    builder.addCase(createClientAccount.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //createAcc
    builder.addCase(createAcc.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(createAcc.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.error) toast.error(action.payload.error, toast_position);
      if (action.payload?.errors) toast.error((Object.values(action.payload.errors)[0][0]), toast_position);
      state.createAccResponse = action.payload;

    }
    );
    builder.addCase(createAcc.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
    //addCard
    builder.addCase(addCard.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(addCard.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.error) toast.error(action.payload.error, toast_position);
      if (action.payload?.errors) toast.error((Object.values(action.payload.errors)[0][0]), toast_position);
      state.createAccResponse = action.payload;

    }
    );
    builder.addCase(addCard.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
  },
});

export default accountsSlice.reducer;
























