export const filterButtonColorMapper = (status) => {
  switch (status) {
    case "S":
      return "#27B882";
    case "Processing":
      return "#FBC02D";
    case "Stalled":
      return "#FBC02D";
    case "P":
      return "#31E5E5";
    case "Y":
      return "#7C4DFF";
    case "Cancelled":
      return "#FF5860";
    case "Abandoned":
      return "#727272";
    case "Expired":
      return "#FF5860";
    case "N":
      return "#FF5860";
    case "Failed":
      return "#FF5860";
    case "Refund":
      return "#A921A9";
    case "Created":
      return "#7C4DFF"
    default:
      return "#ECECEC";
  }
}