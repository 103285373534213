import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import CurrencyFormater from "../../../../utils/CurrencyFormater";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import trimSentence from "../../../../utils/trimSentence";

const AccountsStatusCard = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex w-5/6 h-full flex-col bg-white rounded-4xl items-center justify-between">
        {props.total_accounts > 1 ? (
          <div className="w-full flex flex-col gap-2 3xl:gap-4 6xl:gap-6">
            <div className="flex flex-col gap-6 p-4 mt-4 pb-4">
              <div className="text-2xl 4xl:text-4xl font-semibold text-black w-full">
                {props?.total_accounts} accounts
              </div>
            </div>
            <Box className="dashedLine"></Box>
          </div>
        ) : (
          <div>
            <h1 className="xl:text-xl 2xl:text-3xl 4xl:text-7xl capitalize text-center text-black  w-full my-6">
              {trimSentence(props?.name, 20)}
            </h1>
            <div
              style={{
                height: "3px",
                padding: 0,
                margin: "10px 22px",
                opacity: "0.3",
                background:
                  "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
              }}
              className="dashedLine"
            ></div>
            <div className="xl:p-4 2xl:p-8 text-left">
              <div className="simpleBorderBox truncate ...">
                <b style={{ color: "black" }}>{props?.bank_acc_no} </b>
                <br />
                <span style={{ color: "black" }}> Account number </span>
              </div>
              <div className="dashedBorderBox">
                <div className="paraNImg">
                  <p className="w-1/2 truncate ...">
                    <b className="text-black">{props?.ifsc_code} </b>
                    <br />
                    <span style={{ color: "black" }}> IFSC code </span>
                  </p>
                  <img
                    width={"20%"}
                    src={require("../../../../assets/Banks/" +
                      bankLogoMapper(props?.bank_name) +
                      ".png")}
                    alt=""
                    srcSet=""
                  />
                </div>
                <div
                  style={{
                    height: "3px",
                    padding: 0,
                    margin: "10px 0",
                    opacity: "0.2",
                    marginBottom: "27.21px",
                    background:
                      "repeating-linear-gradient(to left,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)",
                  }}
                  className="dashedLine"
                ></div>
              </div>
            </div>
          </div>
        )}

        {props?.status === "Y" || props?.status === "P" ? (
          <div
            className="w-full cursor-pointer h-[30%] xl:h-[25%] text-white rounded-4xl flex justify-evenly items-center"
            style={{
              backgroundColor:
                props?.status === "Y" || props?.status === "P"
                  ? "#7FB085"
                  : "#9F5353",
            }}
          >
            <div
              className={`flex flex-col w-1/3 gap-2 items-center justify-center`}
              onClick={() => navigate(`/vendor_transactions`)}
            >
              <div className="w-2/6 flex justify-center">
                <img
                  width={"70%"}
                  src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                  alt=""
                  srcSet=""
                  className=""
                />
              </div>
              <p>
                Go to <br /> dashboard
              </p>
            </div>
          </div>
        ) : (
          <div
            className="w-full h-[30%] cursor-pointer xl:h-[25%] text-white rounded-4xl bg-[#7fb085] flex justify-evenly items-center"
            style={{
              backgroundColor:
                props?.status === "Y" || props?.status === "P"
                  ? "#7FB085"
                  : "#9F5353",
            }}
          >
            {/* <div className="h-4/5 w-0.5 bg-gray-300" /> */}
            <div
              className={`flex flex-col w-2/6 gap-2 items-center justify-center`}
              onClick={() => navigate(`/vendor_transactions`)}
            >
              <div className="w-2/6 flex justify-center">
                <img
                  width="70%"
                  src={require("../../../../assets/CreateTransactionIcons/Dashboard.png")}
                  alt=""
                  srcSet=""
                />
              </div>
              <p>
                Go to <br /> dashboard
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountsStatusCard;
