import React from "react";
import { Box } from "@mui/material";
import "./index.css";

const dividerElement = (props) => {
  return (
    <span style={{
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}><hr style={{ width: "100%", borderColor: props.secondary_bg }} /></span>
  );
}

const SupportInfo = (props) => {
  return (
    <div
      className="drop-shadow-xl flex flex-col
      gap-4
      rounded-4xl
      px-8 py-4"
      style={{
        backgroundColor: props.bgColor,
      }}>
      <div className="flex h-1/3">
        <div className="self-center text-gray-300 text-sm font-bold">
          Support Info
        </div>
      </div>

      <div className={`flex flex-col gap-2`}>
        {props.txn_id &&
          <div className="py-1 w-1/2">
            <div className="text-sm
           text-white antialiased truncate ..." >
              {props.txn_id}
            </div>
            <div className="text-gray-300 text-xs antialiased">
              Transaction ID </div>
          </div>
        }
        {
          props.settle_id &&
          (<>
            {dividerElement(props)
            }            < div className="py-1 w-1/2" >
              <div className="text-sm
           text-white antialiased truncate ....">
                {props.settle_id}
              </div>
              <div className="text-gray-300 text-xs antialiased" >
                Settle ID
              </div>
            </div>
          </>)
        }
        {
          props.utr_no &&
          (<>
            {dividerElement(props)}
            <div className="py-1" >
              <div className="text-sm 3xl:text-base 5xl:text-lg 6xl:text-xl
           text-white antialiased truncate ..." >
                {props.utr_no}
              </div>
              <div className="text-gray-300 text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased">
                UTR number
              </div>
            </div>
          </>)
        }
        {
          props.account_id &&
          (<>
            {dividerElement(props)}
            <div className="py-1" >
              <div className="text-sm
           text-white antialiased truncate ..." >
                {props.account_id}
              </div>
              <div className="text-gray-300 text-xs antialiased">
                Account ID
              </div>
            </div>
          </>)
        }
      </div>
    </div>
  )
}

export default SupportInfo;
