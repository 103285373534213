import React from 'react'

const AssociatedGSTs = () => {
    return (
        <div className='flex w-4/5 px-1/5 flex-col items-center gap-20'>
            <div className='text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ...'>
                Associated GSTs
            </div>
            <div className='grid w-full grid-cols-3 justify-between' >
                {
                    [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                        <div className='flex w-3/5 items-center justify-between cursor-pointer py-5 hover:opacity-70' >
                            <div className='flex flex-col gap-1'>
                                <div className={`text-1xl 3xl:text-2xl 5xl:text-3xl 6xl:text-4xl text-left w-full
              text-white antialiased truncate ..`}>Value
                                </div>
                                <div className='text-gray-300 text-lg antialiased' >GST Number</div>
                            </div>
                            <div className="inline-block mx-8 w-4 h-4 2xl:w-5 2xl:h-5 3xl:w-6 3xl:h-6 5xl:w-8 5xl:h-8">
                                <img width={"100^"} src={require('../../../../assets/TransactionDashboardIcons/arrow_next.png')} alt='' />
                            </div>
                        </div>
                    )
                    )
                }
            </div>
        </div>
    )
}

export default AssociatedGSTs