import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TxnSettlePaymentLeftCard from "../../../components/SettleTxn/TxnSettlePaymentLeftCard";
import {
  getFieldForceViewDetail,
  getVendorViewDetail,
} from "../../../redux/features/txnDetails/txnDetails";
import { setHideBreadCrumb } from "../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import TxnCreatedCard from "../../../components/CreateTxn/CreateInstantPayTxn/TxnCreatedCard";

import "./index.css";
import SettlePaymentOptions from "../../../components/SettleTxn/SettlePaymentOptions";

const SettlePayment = (props) => {
  const dispatch = useDispatch();
  const [txnCompletionDate, setTxnCompletionDate] = useState(0);
  const txnDetail = useSelector((state) => state.txnDetails.vendor);
  const txnDetailFF = useSelector((state) => state.txnDetails.fieldForce);
  const data =
    props.container === "FF"
      ? txnDetailFF?.transactions[0]
      : txnDetail?.transactions[0];

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    if (props.getSelected().selected.length === 1) {
      if (props.container === "FF")
        dispatch(
          getFieldForceViewDetail({ txn_id: props.getSelected().selected[0] })
        );
      else
        dispatch(
          getVendorViewDetail({ txn_id: props.getSelected().selected[0] })
        );
    }
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, [dispatch]);

  return (
    <Box className="w-screen text-white flex flex-col xl:flex-row xl:fixed">
      <Box className="xl:w-1/2 bg-[#1d3a6d] px-28">
        <Box className="mt-24 p-8 3xl:p-10 4xl:p-12 5xl:p-14 6xl:p-18">
          <p className="text-5xl py-4 font-normal text-white m-0 text-left">
            Select
          </p>
          <span className="text-xl font-normal text-white text-left w-full block">
            the payment method you want to use
          </span>
        </Box>
        <Box className="w-4/5 m-auto h-1/2 flex justify-center items-center">
          {props.module != "IPsettle" ? (
            <TxnSettlePaymentLeftCard
              totalSelected={props.getSelected().selected.length}
              vendor_name={data?.vendor_name || data?.destination_owner_name}
              amount={props.getSelected().amt}
              vendor_bank_acc_no={
                data?.vendor_bank_acc_no || data?.destination_bank_acc_no
              }
              vendor_ifsc_code={
                data?.vendor_ifsc_code || data?.destination_ifsc
              }
              vendor_bank_name={
                data?.vendor_bank_name || data?.destination_bank_name
              }
              txnCompletionDate={txnCompletionDate}
            />
          ) : (
            <TxnCreatedCard
              amount={props.getSelected().amt}
              txnCompletionDate={txnCompletionDate}
              totalSelected={props.getSelected().selected.length}
            />
          )}
        </Box>
        <Box id="TxnCreatedCardMobile">
          <p className="txnCreatedText">Transaction created successfully! ✅</p>
          <h2 style={{ color: "white" }}>Bhaskar Divakar Choudary</h2>
        </Box>
      </Box>
      <Box
        className="xl:w-1/2 bg-[#3a5686] flex flex-col"
        sx={{ height: "100vh", overflow: "hidden", padding: "0% 5% 2% 5%" }}
      >
        <SettlePaymentOptions
          setTxnCompletionDate={setTxnCompletionDate}
          amount={props.getSelected().amt}
          totalSelected={props.getSelected().selected}
          vendor_name={data?.vendor_name || data?.destination_owner_name}
          vendor_bank_acc_no={
            data?.vendor_bank_acc_no || data?.destination_bank_acc_no
          }
          vendor_ifsc_code={data?.vendor_ifsc_code || data?.destination_ifsc}
          vendor_bank_name={
            data?.vendor_bank_name || data?.destination_bank_name
          }
          txnCompletionDate={txnCompletionDate}
          module={props.module}
          container={props.container}
        />
      </Box>
    </Box>
  );
};

export default SettlePayment;
