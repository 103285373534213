import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentMethodCard from "../../../../components/CreateTxn/CreateVendorTxn/PaymentMethodCard";
import TxnCreatedCard from "../../../../components/CreateTxn/CreateVendorTxn/TxnCreatedCard";
import {
  cardSettle,
  getSettleType,
  instaPaySettle,
  wireSettle,
} from "../../../../redux/features/settleTxn/settleTxn";
import formatCurrency from "../../../../utils/CurrencyFormater";
import "./index.css";
import TransactionStatus from "../TransactionStatus";
import NextDate from "../../../../components/NextDate";
import { useNavigate } from "react-router-dom";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { toast } from "react-toastify";

const SelectPayment = (props) => {
  const [showSettlementStatus, setShowSettlementStatus] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createdVendorTxn = useSelector((state) => state.createTxn.vendor);
  const settlementType = useSelector((state) => state.settleTxn.type);
  const cardResponse = useSelector((state) => state.settleTxn.settleCardRes);
  const NeftRtgsResponse = useSelector(
    (state) => state.settleTxn.settleWireRes
  );
  const InstantPayResponse = useSelector(
    (state) => state.settleTxn.settleInstantPayRes
  );
  const cardResLoading = useSelector((state) => state.settleTxn.isLoading);
  const NeftRtgsResLoading = useSelector((state) => state.settleTxn.isLoading);
  const InstantPayResLoading = useSelector(
    (state) => state.settleTxn.isLoading
  );
  const [isActive, setIsActive] = useState(null);
  const [codeVerified, setCodeVerified] = useState(false);
  const settleTypeData = settlementType ? settlementType.settle_type : [];
  const [txnCompletionDate, setTxnCompletionDate] = useState(0);
  const { createTxnData, selectedAcc, registerNewVnerdorData } = props;
  const [checkRes, setCheckRes] = useState(false);

  const [cvv, setCvv] = useState(null);

  useEffect(() => {
    dispatch(getSettleType());
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  useEffect(() => {
    setCodeVerified(false);
    setCvv(null);
  }, [isActive]);

  useEffect(() => {
    settleTypeData?.forEach((item) => {
      if (
        item.settlement_id === isActive &&
        item.settlement_type === "NEFT/RTGS"
      ) {
        setTxnCompletionDate(1);
        return;
      }
      if (item.settlement_id === isActive) {
        setTxnCompletionDate(2);
        return;
      }
    });
  }, [isActive]);

  const handlePayButton = () => {
    if (isActive === "Instant Pay" && codeVerified && createdVendorTxn?.status)
      dispatch(
        instaPaySettle({ txn_id: [createdVendorTxn?.transactions[0].txn_id] })
      );
    if (isActive !== "Instant Pay") {
      settleTypeData?.forEach((item) => {
        if (
          item.settlement_id === isActive &&
          item.settlement_type === "NEFT/RTGS"
        ) {
          dispatch(
            wireSettle({
              account_pin: item.settlement_title,
              source_owner_id: item.settlement_id,
              txn_id: [createdVendorTxn?.transactions[0].txn_id],
            })
          );
        }
        if (
          item.settlement_id === isActive &&
          item.settlement_type === "Registered Card"
        ) {
          dispatch(
            cardSettle({
              cvv: cvv,
              settlement_id: item.settlement_id,
              settlement_type: item.settlement_method,
              txn_id: [createdVendorTxn?.transactions[0].txn_id],
            })
          );
        }
      });
    }
  };

  useEffect(() => {
    if (checkRes && cardResponse?.errors) {
      toast.error(Object.values(cardResponse.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && cardResponse?.status) navigate("/payments/card");
    setCheckRes(true);
  }, [cardResponse]);

  useEffect(() => {
    if (checkRes && NeftRtgsResponse?.errors) {
      toast.error(Object.values(NeftRtgsResponse.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && NeftRtgsResponse?.status) setShowSettlementStatus(true);
    setCheckRes(true);
  }, [NeftRtgsResponse]);

  useEffect(() => {
    if (checkRes && InstantPayResponse?.errors) {
      toast.error(Object.values(InstantPayResponse.errors)[0][0], {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (checkRes && InstantPayResponse?.status) setShowSettlementStatus(true);
    setCheckRes(true);
  }, [InstantPayResponse]);

  let RegisteredCardCount = 0;
  let NEFTRTGSCount = 0;
  return !showSettlementStatus ? (
    <Box className="w-screen text-white flex flex-col gap-24 xl:gap-0 xl:flex-row">
      <Box className="xl:w-1/2 bg-[#1d3a6d] scale-75 xl:-translate-y-24 3xl:transform-none 3xl:scale-100 px-28">
        <Box className="mt-12 xl:mt-24 selectPayHeading">
          <p>Select</p>
          <span>the payment method you want to use</span>
        </Box>
        <Box id="selectPayHeadingMobile" className="selectTxnsHeadingBoxMobile">
          <Box sx={{ display: "flex" }}>
            <Button className="selectTxnSearch">
              <img
                className="selectTxnSearchImg"
                src={require("../../../../assets/Approve transactions/Close.png")}
                alt=""
                srcSet=""
              />
            </Button>
            &nbsp; &nbsp;
            <Box className="selectTxnsHeading">Vendor Transactions</Box>
          </Box>
        </Box>
        <Box id="TxnCreatedCardDesktop">
          <TxnCreatedCard
            selectedAccName={props.selectedAcc.name}
            amount={props.amount}
            account={props.account}
            ifsc={props.ifsc}
            bank_name={props.selectedAcc.bank_name}
            distribution_remark={props.distribution_remark}
            invoice_no={props.invoice_no}
            txnCompletionDate={txnCompletionDate}
          />
        </Box>
        <Box id="TxnCreatedCardMobile">
          <p className="txnCreatedText">Transaction created successfully! ✅</p>
          <h2 style={{ color: "white" }}>{props.txn_initiator_name}</h2>
        </Box>
      </Box>
      <Box
        className="xl:w-1/2 bg-[#3a5686] flex flex-col"
        sx={{ height: "100vh", overflow: "hidden", padding: "0% 5% 2% 5%" }}
      >
        <div className="paymenthodTextSettle p-8 3xl:p-10 4xl:p-12 5xl:p-14 6xl:p-18  mt-12 xl:mt-24">
          Payment method
        </div>
        <Box
          className="paymentCardsContainer"
          sx={{
            height: "65%",
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Box>
            {settleTypeData &&
              settleTypeData.map((item) => {
                if (item.settlement_type === "Instant Pay")
                  return (
                    <>
                      <Box className="paymentCardHeading">
                        <Box>{item.settlement_type}</Box>
                        {props.amount > +item.current_balance ? (
                          <Box sx={{ color: "rgb(252, 77, 77)" }}>
                            Insufficient balance
                          </Box>
                        ) : (
                          <Box sx={{ color: "#4DE659" }}>Instant Pay</Box>
                        )}
                      </Box>
                      <PaymentMethodCard
                        key={item.id}
                        txn_amount={props.amount}
                        settlement_id={item.settlement_id}
                        settlement_title={item.settlement_title}
                        settlement_method={item.settlement_method}
                        settlement_type={item.settlement_type}
                        account_holder_name={item.account_holder_name}
                        is_primary={item.is_primary}
                        current_balance={item.current_balance}
                        isActive={isActive}
                        setCodeVerified={setCodeVerified}
                        setIsActive={setIsActive}
                      />
                      <br />
                    </>
                  );
              })}
          </Box>
          <Box>
            {settleTypeData &&
              settleTypeData.map((item) => {
                if (item.settlement_type === "Registered Card") {
                  RegisteredCardCount++;
                  return (
                    <>
                      <Box className="paymentCardHeading">
                        <Box>
                          {RegisteredCardCount === 1
                            ? "Preferred Card"
                            : "All Cards"}
                        </Box>
                        {props.amount > +item.current_balance ? (
                          <Box sx={{ color: "#FC4D4D" }}>
                            Insufficient balance
                          </Box>
                        ) : (
                          <Box style={{ color: "#FFCC00" }}>
                            <span>Completed by: </span> <NextDate />
                          </Box>
                        )}
                      </Box>
                      <PaymentMethodCard
                        key={item.id}
                        setCvv={setCvv}
                        RegisteredCardCount={RegisteredCardCount}
                        txn_amount={props.amount}
                        settlement_id={item.settlement_id}
                        settlement_title={item.settlement_title}
                        settlement_method={item.settlement_method}
                        settlement_type={item.settlement_type}
                        account_holder_name={item.account_holder_name}
                        is_primary={item.is_primary}
                        current_balance={item.current_balance}
                        isActive={isActive}
                        setCodeVerified={setCodeVerified}
                        setIsActive={setIsActive}
                      />
                      <br />
                    </>
                  );
                }
              })}
          </Box>
          <Box>
            {settleTypeData &&
              settleTypeData.map((item) => {
                if (item.settlement_type === "NEFT/RTGS") {
                  NEFTRTGSCount++;
                  return (
                    <>
                      {NEFTRTGSCount === 1 ? (
                        <Box className="paymentCardHeading">
                          <Box>{item.settlement_type}</Box>
                          {props.amount > +item.current_balance ? (
                            <Box sx={{ color: "#FC4D4D" }}>
                              Insufficient balance
                            </Box>
                          ) : (
                            <Box style={{ color: "#FFA000" }}>
                              <span>Completed by: </span> <NextDate Tplus2 />
                            </Box>
                          )}
                        </Box>
                      ) : null}
                      <PaymentMethodCard
                        key={item.id}
                        txn_amount={props.amount}
                        settlement_id={item.settlement_id}
                        settlement_title={item.settlement_title}
                        settlement_method={item.settlement_method}
                        settlement_type={item.settlement_type}
                        account_holder_name={item.account_holder_name}
                        is_primary={item.is_primary}
                        current_balance={item.current_balance}
                        isActive={isActive}
                        maintenance_account={item.maintenance_account}
                        setCodeVerified={setCodeVerified}
                        setIsActive={setIsActive}
                      />
                      <br />
                    </>
                  );
                }
              })}
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor:
              (codeVerified || cvv?.length === 3) &&
              !(cardResLoading || NeftRtgsResLoading || InstantPayResLoading)
                ? "#69B76F"
                : null,
            pointerEvents:
              (codeVerified || cvv?.length === 3) &&
              !(cardResLoading || NeftRtgsResLoading || InstantPayResLoading)
                ? "auto"
                : "none",
            border:
              (codeVerified || cvv?.length === 3) &&
              !(cardResLoading || NeftRtgsResLoading || InstantPayResLoading)
                ? "none"
                : "solid rgba(246, 244, 244, 0.588)",
            opacity: "0.79px",
          }}
          className="paymentProceedBTSettle my-6"
          onClick={() => {
            handlePayButton();
          }}
        >
          <div className="flex justify-center items-center">
            {!(
              cardResLoading ||
              NeftRtgsResLoading ||
              InstantPayResLoading
            ) && <span>Pay ₹ {formatCurrency(props.amount)}</span>}
            {(cardResLoading || NeftRtgsResLoading || InstantPayResLoading) && (
              <span className="mx-10 w-[7%]">
                {
                  <img
                    width="100%"
                    src={require("../../../../assets/Approve transactions/spinner.gif")}
                    alt=""
                  />
                }
              </span>
            )}
          </div>
        </Box>
      </Box>
    </Box>
  ) : (
    <Box sx={{ marginTop: "-120px" }}>
      <TransactionStatus
        type="settlement"
        txn_id={createdVendorTxn?.transactions[0].txn_id}
        txn_status={"under Processing!"}
        account={
          selectedAcc && selectedAcc.bank_acc_no
            ? selectedAcc.bank_acc_no
            : registerNewVnerdorData.bank_acc_no
        }
        amount={createTxnData.amount}
        vendor_name={
          selectedAcc && selectedAcc.name
            ? selectedAcc.name
            : registerNewVnerdorData.name
        }
        bank_name={
          selectedAcc && selectedAcc.bank_name
            ? selectedAcc.bank_name
            : registerNewVnerdorData.bank_name
        }
        ifsc={
          selectedAcc && selectedAcc.ifsc_code
            ? selectedAcc.ifsc_code
            : registerNewVnerdorData.ifsc_code
        }
        distribution_remark={createTxnData.distribution_remark}
        invoice_no={createTxnData.invoice_no}
        vendor_id={createTxnData.vendor_id}
      />
    </Box>
  );
};

export default SelectPayment;
