import React, { useState } from "react";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import "./index.css";

const AllStatusTypeButton = ({ handleFilter }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleStatusClick = (status) => {
    handleFilter(status);
    handleClose();
  };

  const open = Boolean(anchorEl);
  return (
    <div className="grid grid-cols-4 gap-4 ">
      <Button className="pop_overbtn" onClick={handleClick}>
        All
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="view-all-pop-over">
          <Typography>
            <div className="view-all-select-buttons">
              <Button onClick={() => handleStatusClick("denied")}>All</Button>
            </div>
            <div className="hm">
              <Button className="text-white" onClick={() => handleStatusClick("denied")}>Denied</Button>
            </div>
             <div>
              <Button onClick={() => handleStatusClick("pending")}>Pending</Button>
            </div>
            <div>
              <Button onClick={() => handleStatusClick("blocked")}>Blocked</Button></div>
            <div>
              <Button onClick={() => handleStatusClick("archived")}>Archived</Button></div>
            <div>
          <Button  onClick={() => handleStatusClick("approved")}>Approved</Button></div>
          </Typography>
          </div>
      </Popover>

    </div>
  );
};
export default AllStatusTypeButton;
