import React, { useEffect, useState } from "react";
import ErrorPage from "../../../../assets/Approve transactions/ErrorPage.png";
import DeclinedRed from "../../../../assets/Approve transactions/DeclinedTxn_red.png";
import WhitedashBoard from "../../../../assets/Approve transactions/WhiteDashboard.png";
import "./index.css";
import { Tooltip, tooltipClasses } from "@mui/material";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";
import { Link } from "react-router-dom";

const BeneficiaryMultiApprovalErrorPage = () => {
  const [ErrorPageData, setErrorPageData] = useState([]);
  const [activeTab, setActiveTab] = useState(true);

  const findCommonNames = (arr1, arr2) => {
    let type = arr2.some((item) => typeof item === "string");
    if (type) {
      return arr2;
    } else {
      const arr2Map = new Map(arr2?.map((item) => [item?.id, item?.errors]));

      return arr1
        .filter((item) => arr2Map?.has(item.txn_id))
        .map((item) => ({
          ...item,
          errors: arr2Map?.get(item.txn_id),
        }));
    }
  };

  const filterErrorTxn = findCommonNames(
    ErrorPageData,
    JSON.parse(sessionStorage.getItem("selectedDataError"))
  );

  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setErrorPageData(storedSelectedApproval?.selectedSendTransactions);
    } else {
      setErrorPageData([]);
    }
  }, []);

  return (
    <div className="min-h-screen bg-[#754545]">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex-1 pt-40">
            <div className="flex min-w-screen mx-5 justify-center items-center bg-[#754545]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  className=""
                  src={ErrorPage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-[1px] flex flex-col justify-center items-center">
                  <p className="w-[159px] h-[76px] text-left font-sans font-bold text-[56px] leading-[76px] tracking-normal text-[#FAFAFA] opacity-100">
                    Oops,
                  </p>
                  <span className="h-[43px] text-left font-sans font-semibold text-[28px] leading-[43px] tracking-normal text-[#FAFAFA] opacity-100">
                    Something went wrong
                  </span>
                  <span className="w-[454px] h-[27px] text-center  mt-[5px] font-sans text-[20px] leading-[27px] tracking-normal text-white opacity-100">
                    An error occurred while taking action on the <br />{" "}
                    following transactions.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 pt-[145px] pb-2">
            <div className="flex flex-col h-[554px] w-[472px] mx-5 bg-[#FFFFFF]  rounded-[30px] items-center justify-between">
              <div className="h-[77px] w-[472px] flex justify-center items-center bg-[#985555] rounded-tl-[27px] rounded-tr-[27px]">
                <div
                  className="txn_error_swap_tab_left"
                  style={{
                    backgroundColor: activeTab === true ? "#985555" : "#864c4c",
                  }}
                  onClick={() => {
                    setActiveTab(true);
                  }}
                >
                  <h4>Error details</h4>
                </div>
                <div
                  className="txn_error_swap_tab_right"
                  style={{
                    backgroundColor:
                      activeTab === false ? "#985555" : "#864c4c",
                  }}
                  onClick={() => {
                    setActiveTab(false);
                  }}
                >
                  <h4>Transaction details</h4>
                </div>
              </div>
              {activeTab === false ? (
                <div
                  className="h-[335px] w-[472px] flex  justify-center"
                  style={{
                    overflowY: ErrorPageData.length > 5 ? "scroll" : "hidden",
                    marginTop: "6px",
                    paddingBottom: "20px",
                  }}
                >
                  <div
                    className="w-[411px]   mt-[10px]"
                    style={{ height: "fit-content" }}
                  >
                    {ErrorPageData?.map((account, i) => {
                      return (
                        <>
                          <div className="flex  mt-[10px]  justify-between">
                            <div className="flex justify-center items-center gap-[10px]">
                              <img
                                className="w-[30px] h-[30px]"
                                src={DeclinedRed}
                                alt=""
                              />
                              <div>
                                <Tooltip
                                  componentsProps={{
                                    popper: {
                                      sx: {
                                        [`& .${tooltipClasses.tooltip}`]: {
                                          backgroundColor: (theme) =>
                                            theme.palette.common.black,
                                        },
                                      },
                                    },
                                  }}
                                  title={account?.beneficiary_account_name}
                                >
                                  <p className=" whitespace-nowrap overflow-ellipsis overflow-hidden first-line:capitalize lowercase w-[167px] h-[22px] text-left font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-black">
                                    {account.beneficiary_account_name}
                                  </p>
                                </Tooltip>

                                <span className="flex items-start  w-[110px] h-[17px] text-left font-sans text-[12px] leading-[17px] tracking-normal text-[#828282] opacity-100">
                                  Beneficiary name
                                </span>
                              </div>
                            </div>
                            <div>
                              <p className=" min-w-0 whitespace-nowrap h-[22px] text-right font-sans font-semibold text-[16px] leading-[22px] tracking-normal text-[#FF5860] opacity-100">
                                ₹ {<FormatAmount price={account?.amount} />}
                              </p>

                              <span className="flex  justify-end items-end   font-sans text-[12px] leading-[17px]  text-[#828282] opacity-100">
                                Amount
                              </span>
                            </div>
                          </div>
                          {i < ErrorPageData.length - 1 && (
                            <div
                              style={{
                                marginTop: "10px",
                                border: "1px solid #DADADA",
                                height: "0px",
                                width: "411px",
                                opacity: "100",
                              }}
                            ></div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div
                  className="h-[335px] w-[472px] "
                  style={{
                    overflowY: "auto",
                    marginTop: "6px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {filterErrorTxn?.map((val, i) => {
                    return (
                      <>
                        {val?.errors ? (
                          <div className="inner_error_msg" key={i}>
                            <div className="inner_error_msg_name">
                              <h4 className="lowercase first-line:capitalize">
                                {val?.beneficiary_account_name}
                              </h4>
                              <h6>Beneficiary name</h6>
                            </div>
                            {val?.errors[0]?.reason ? (
                              <>
                                {val?.errors[0]?.reason?.map((ele, i) => {
                                  return (
                                    <div
                                      className="inner_error_msg_title"
                                      key={i}
                                      style={{
                                        borderBottom:
                                          val?.errors[0]?.reason?.length - 1 ===
                                          i
                                            ? ""
                                            : "1px solid #dadada",
                                      }}
                                    >
                                      <h4>{ele}</h4>
                                      <p>Error message</p>
                                    </div>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {val?.errors?.map((ele, i) => {
                                  return (
                                    <div
                                      className="inner_error_msg_title"
                                      key={i}
                                      style={{
                                        borderBottom:
                                          val?.errors?.length - 1 === i
                                            ? ""
                                            : "1px solid #dadada",
                                      }}
                                    >
                                      <h4>{ele}</h4>
                                      <p>Error message</p>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="inner_error_msg_title">
                            <h4>No transactions found for transaction</h4>
                            <p>Error message</p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
              <div className="flex justify-center items-center h-[136px] w-[472px] bg-[#985555] rounded-bl-[27px] rounded-br-[27px]">
                <Link to={"/beneficiary_transactions"}>
                  <div className="w-[62px] flex justify-center items-center flex-col gap-[4px]  h-[76px]">
                    <img
                      className="w-[40px]  h-[40px] "
                      src={WhitedashBoard}
                      alt=""
                    />
                    <p className="w-[62px] h-[32px] text-center font-semibold text-[12px] leading-[15px] tracking-[0px] text-[#FFFFFF] opacity-100">
                      Go to dashboard
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryMultiApprovalErrorPage;
