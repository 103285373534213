import React from "react";

const LineWithImage = ({ imageUrl }) => {

  const dashedLine = "repeating-linear-gradient(to right,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)";

  return (
    <div className="flex py-1 xl:py-2 2xl:py-4 4xl:py-8 w-full items-center justify-center">
      <div style={{ background: dashedLine }} className="h-0.5 flex-grow"/>
      <div className="px-1 xl:px-1 2xl:px-2 4xl:px-8" >
        <img className="2xl:w-8 4xl:w-16 xl:w-6 w-4 xl:bg-[repeating-linear-gradient(to right,transparent,transparent 10px,#CBCBCB 10px, #CBCBCB 20px)] bg-[repeating-linear-gradient(to right,transparent,transparent 15px,#CBCBCB 15px, #CBCBCB 30px)]" src={imageUrl} alt="" />
      </div>
      <div className="xl:text-lg 2xl:text-xl 4xl:text-4xl  xl:px-1 2xl:px-2 opacity-70" >Verified Details</div>
      <div style={{background:dashedLine}}  className="h-0.5 flex-grow"/>
    </div>
  );
};

export default LineWithImage;
