import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setHideBreadCrumb } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";
import AccountsStatusCard from "../../../../components/Accounts/Vendor/AccountsStatusCard";

const AccountApprovalStatus = () => {
  const dispatch = useDispatch();
  const vendorApprovalResponse = useSelector(
    (state) => state.approveAccount.vendor
  );
  const accounts = vendorApprovalResponse?.accounts;
  const status = accounts[0]?.approval_status;
  const txns = vendorApprovalResponse ? vendorApprovalResponse.accounts : [];

  if (txns.length > 1) {
    var total_amt = txns.reduce(function (previousValue, currentValue) {
      return {
        value: Number(previousValue.amount) + Number(currentValue.amount),
      };
    });
  }

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  return (
    <div
      className="flex flex-col xl:flex-row gap-0
      min-w-screen min-h-screen w-screen justify-center pt-24 xl:h-screen items-center"
      style={{
        backgroundColor:
          status === "Y" || status === "P" ? "#6ba673" : "#BC5555",
      }}
    >
      <div className="w-4/5 xl:w-2/5 h-[550px] xl:h-4/5 p-0 flex flex-col items-center justify-center gap-6 text-center text-white text-2xl">
        {status === "Y" || status === "P" ? (
          <img
            src={require("../../../../assets/Approve transactions/Success screen.png")}
            width={"70%"}
            alt=""
            srcSet=""
          />
        ) : (
          <img
            src={require("../../../../assets/Approve transactions/Failed screen.png")}
            width={"70%"}
            alt=""
            srcSet=""
          />
        )}
        {status === "Y" || status === "P" ? (
          <div>
            Vendor {txns.length > 1 ? "accounts have" : "accounts has"} <br />
            been approved!
          </div>
        ) : (
          <div>
            Vendor {txns.length > 1 ? "accounts have" : "accounts has"} <br />
            been declined!
          </div>
        )}
      </div>
      <div className="w-4/5 flex justify-start h-[750px] xl:w-2/5 xl:h-[90%] p-0 text-center">
        {!(txns.length > 1) ? (
          <AccountsStatusCard
            status={status}
            total_accounts={txns?.length}
            name={accounts[0].name}
            ifsc_code={accounts[0].ifsc_code}
            bank_acc_no={accounts[0].bank_acc_no}
            bank_name={accounts[0].bank_name}
          />
        ) : (
          <AccountsStatusCard
            txn_len={txns.length}
            total_accounts={txns?.length}
            name={accounts[0].name}
            ifsc_code={accounts[0].ifsc_code}
            bank_acc_no={accounts[0].bank_acc_no}
            bank_name={accounts[0].bank_name}
            status={status}
          />
        )}
      </div>
    </div>
  );
};

export default AccountApprovalStatus;
