import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../env";


const legacyUrl = env.REACT_APP_LEGACY_URL;
// Action

export const createVendorAccount = createAsyncThunk(
  "createVendorAccount",
  async (values) => {
    const {
      bank_acc_no,
      contact_no,
      email_id,
      gstin,
      ifsc_code,
      pan_no,
      skip_account_verification,
      name,
    } = values ? values : { bank_acc_no: null, contact_no: null, email_id: null, gstin: null, ifsc_code: null, pan_no: null, skip_account_verification: null, name: null };
    var requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": sessionStorage.getItem("q2p_token"),
      },
      body: JSON.stringify({
        bank_acc_no: bank_acc_no,
        contact_no: contact_no,
        email_id: email_id,
        gstin: gstin,
        ifsc_code: ifsc_code,
        pan_no: pan_no,
        skip_account_verification: skip_account_verification,
        name: name,
      }),
    };

    return await fetch(
      `${legacyUrl}/accounts/vendor/create`,
      requestOptions
    )
      .then((response) => {
        return response.json()
      })
      .catch((error) => {
        console.log("error", error)
      });
  }
);

export const createFFAcc = createAsyncThunk("createFFAcc", async (values) => {

  const {
    bank_acc_no,
    ifsc_code,
    contact_no,
    email_id,
    user_id,
    name,
    pan_no,
    txn_limit,
  } = values
      ? values
      : {
        bank_acc_no: null,
        contact_no: null,
        email_id: null,
        gstin: null,
        ifsc_code: null,
        pan_no: null,
        user_id: null,
        name: null,
        txn_limit: null,
      };
  var requestOptions = {
    method: "POST",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
    body: JSON.stringify({
      bank_acc_no: bank_acc_no,
      contact_no: contact_no,
      email_id: email_id,
      ifsc_code: ifsc_code,
      pan_no: pan_no,
      user_id: user_id,
      name: name,
      txn_limit: txn_limit,
    }),
  };

  return await fetch(
    `${legacyUrl}/accounts/field_force/create`,
    requestOptions
  )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log("error", error);
    });
});


const accountsSlice = createSlice({
  name: "createAcc",
  initialState: {
    vendor: null,
    fieldforce: null,
    isError: false,
  },
  extraReducers: (builder) => {
    //vendor
    builder.addCase(createVendorAccount.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createVendorAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.vendor = action.payload;
    });
    builder.addCase(createVendorAccount.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
    //fieldforce
    builder.addCase(createFFAcc.pending, (state, action) => {
      state.isLoading = true;
    }
    );
    builder.addCase(createFFAcc.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fieldforce = action.payload;
    }
    );
    builder.addCase(createFFAcc.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    }
    );
  },
});

export default accountsSlice.reducer;























