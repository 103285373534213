import React from "react";
import HolidayDateCard from "../HolidayDateCard";
import { useSelector } from "react-redux";
import LeftArrow from "../../../assets/ViewAllTransactions/Left arrow white.png";
import YellowExclamation from "../../../assets/HomeDashboard/Yellow exclamation.png";
import getTodaysDate from "../../../utils/getTodaysDate";

const currentEpoch = String(Math.floor(new Date().getTime() / 1000.0));

function ThreeUpcomingHolidays({ setSidebarState }) {
  const holiday_list = useSelector((state) => state.date?.holiday_list);
  const latestholiday = holiday_list?.holidays
    ?.map((a) => a)
    .sort((a, b) => a.id - b.id);
  const firstHoliday = holiday_list?.holidays?.[0]?.date;
  const todaysDate = getTodaysDate();
  const tomorrowsDate = getTodaysDate(1);

  return (
    <div
      onClick={() => setSidebarState(true)}
      className="cursor-pointer top-0 text-sm flex flex-col items-end gap-3 py-2"
    >
      {firstHoliday === todaysDate ? (
        <div className="flex gap-3 justify-center items-center pr-5">
          <div className="w-4 h-8 flex items-center">
            <img src={YellowExclamation} alt="" />
          </div>
          <div className="text-white font-semibold">
            Today is a Bank Holiday
          </div>
        </div>
      ) : tomorrowsDate === firstHoliday ? (
        <div className="flex gap-3 justify-center items-center pr-5">
          <div className="w-4 h-8 flex items-center">
            <img src={YellowExclamation} alt="" />
          </div>
          <div className="text-white font-semibold">
            Tomorrow is a Bank Holiday
          </div>
          <div className="w-4 h-8 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
              />
            </svg>
          </div>
        </div>
      ) : holiday_list?.working_hour?.to >= currentEpoch &&
        holiday_list?.working_hour?.from <= currentEpoch ? (
        <div className="flex gap-3 justify-center items-center pr-5">
          <div className="text-white font-semibold">Upcoming Bank Holidays</div>
          <div className="w-4 h-8 flex items-center">
            <img src={LeftArrow} alt="" />
          </div>
        </div>
      ) : (
        <div className="flex gap-3 justify-center items-center pr-4">
          <div className="w-4 h-8 flex items-center">
            <img src={YellowExclamation} alt="" />
          </div>
          <div className="text-white font-semibold">Upcoming Bank Holidays</div>
          <div className="w-4 h-8 flex items-center">
            <img src={LeftArrow} alt="" />
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 justify-items-center divide-x divide-gray-500">
        {latestholiday?.slice(0, 3).map((holiday, index) => (
          <HolidayDateCard
            date={holiday.date}
            occasion={holiday.occasion}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default ThreeUpcomingHolidays;
