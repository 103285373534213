//verificationMapper

export const verificationDetailsMapper =
{
    "CIN Verification": {
        "title-card": {
            "title-subtext": "Company Name",
            "label": "Corporate Profile",
            "icon": "Group 1435.png",
            "api_key": "company_name"
        },
        "w_icons": [
            {
                "title": "Birthdate",
                "icon": "Path 1411.png",
                "api_key": ""
            },
            {
                "title": "Type",
                "icon": "Path 1412.png",
                "api_key": ""
            },
            {
                "title": "Email",
                "icon": "Path 1413.png",
                "api_key": "email"
            },
            {
                "title": "phone",
                "icon": "Path 1414.png",
                "api_key": "phone_number"
            },
        ],
        "wo_icons": [
            {
                "title": "CIN Number",
                "api_key": "cin_number"
            },
            {
                "title": "Authorized Capital",
                "api_key": "authorized_capital"
            },
            {
                "title": "Paid Up Capital",
                "api_key": "paid_up_capital"
            },
            {
                "title": "Company Status",
                "api_key": "company_status"
            },
            {
                "title": "Listed Status",
                "api_key": "listed_status"
            },
        ]
    },
    "DIN Verification": {
        "title-card": {
            "title-subtext": "Full Name",
            "label": "Personal Profile",
            "icon": "1177568.png",
            "api_key": "full_name",
        },
        "w_icons": [
            {
                "title": "Birthdate",
                "icon": "Path 1411.png",
                "api_key": "dob"
            },
            {
                "title": "Email",
                "icon": "Path 1413.png",
                "api_key": "email"
            },
            {
                "title": "Nationality",
                "icon": "Path 2079.png",
                "api_key": "nationality"
            },
        ],
        "wo_icons": [
            {
                "title": "Father's Name",
                "api_key": "father_name"
            },
            {
                "title": "DIN Number",
                "api_key": "din_number"
            },
            {
                "title": "PAN Number",
                "api_key": ""
            },
        ]
    },
    "PAN Verification": {
        "title-card": {
            "title-subtext": "Full Name",
            "label": "Personal Profile",
            "icon": "1177568.png",
            "api_key": "full_name"
        },
        "w_icons": [
            {
                "title": "Birthdate",
                "icon": "Path 1411.png",
                "api_key": ""
            },
            {
                "title": "Father's Name",
                "icon": "Path 1415.png",
                "api_key": ""
            },
        ],
        "wo_icons": [
        ]
    },
    "GST Verification": {
        "title-card": {
            "title-subtext": "Company Name",
            "label": "Corporate Profile",
            "api_key": "business_name",
            "icon": "Group 1435.png",
        },
        "w_icons": [
            {
                "title": "Birthdate",
                "icon": "Path 1411.png",
                "api_key": "date_of_registration"
            },
            {
                "title": "Type",
                "icon": "Path 1412.png",
                "api_key": "taxpayer_type"
            },
        ],
        "wo_icons": [
            {
                "title": "PAN Number",
                "api_key": "pan_no"
            },
            {
                "title": "Company Status",
                "api_key": "status"
            },
        ]
    },
}