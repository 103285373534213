import React from "react";

function EntityAddress({ address, pincode }) {
  return (
    <div className="bg-[#506994] w-full h-80 rounded-4xl shadow-lg flex flex-col p-5 gap-5">
      <div className="text-[#C9C9C9]">Address</div>
      <div className="flex w-full h-full">
        <div className="flex justify-start gap-10 w-1/2">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#fff"
              className="w-7 h-7"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
          </div>
          {address || pincode ? (
            <div className="text-white pr-10">
              {address}
              {", "}
              {pincode}
            </div>
          ) : (
            <div className="text-white pr-10">Not Given</div>
          )}
        </div>
        {(address || pincode) && (
          <div className="w-1/2 pb-5">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4436.499425565234!2d73.89161941213118!3d18.539273999581372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c104223d4011%3A0xc331c31a620d8f90!2s421%2C%20Koregaon%20Rd%2C%20Suyojana%20Society%2C%20Kavadewadi%2C%20Koregaon%20Park%2C%20Pune%2C%20Maharashtra%20411001!5e0!3m2!1sen!2sin!4v1690535911965!5m2!1sen!2sin"
              className="w-full h-full rounded-xl"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default EntityAddress;
