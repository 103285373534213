import { Box } from "@mui/system";
import React, { useState } from "react";
import SideDrawer from "../../SideDrawer";
import ClientTxnDetailsMoreActions from "../ClientTxnDetailsMoreActions";
import { useNavigate } from "react-router-dom";
import { black } from "tailwindcss/colors";

const ClientTxnQuickAccessMenu = (props) => {
  const navigate = useNavigate();
  const [showOtherIcons, setShowOtherIcons] = useState(true);
  const [toggleDocSec, settoggleDocSec] = useState(false);

  const toggleDocSection = () => {
    settoggleDocSec(!toggleDocSec);
  }

  return (
    <Box className="h-full drop-shadow-2xl flex flex-col
      rounded-4xl 3xl:rounded-large 5xl:rounded-customXl
      overflow-hidden"
      sx={{
        backgroundColor: props.bgColor,
      }}>

      <Box className="p-2 3xl:p-4 5xl:p-6 w-full h-full flex flex-row justify-evenly divide-[#1d1d1d55]">
        {showOtherIcons &&
          <Box className="cursor-pointer text-center h-full w-1/3 flex flex-col justify-center gap-1"
            onClick={() => {
              navigate(props?.txnHistoryPath)
            }}>
            <div className="flex justify-center h-4/5">
              <Box className="self-center w-[30%]">
                <img
                  src={require("../../../../assets/TxnDetails/transaction-history.png")}
                  alt="document"
                />
              </Box>
            </div>
            <div className="flex justify-center h-1/5">
              <Box className="px-2 h-8/12 self-center text-white
                    text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased truncate...">
                Transaction history
              </Box>
            </div>
          </Box>}

        {
          <hr style={{
            height: "100%",
            width: "1px",
            opacity: "20%",
            backgroundColor: "black"
          }} />
        }
        {showOtherIcons &&
          <Box className={`
                ${props.settle_id && "cursor-pointer"}
                ${!props.settle_id && "opacity-40"}
                text-center h-full w-1/3 flex flex-col justify-center gap-1`}
            onClick={() => {
              if (props.settle_id)
                navigate(props?.txnStatementPath)
              else
                return;
            }
            }>
            <div className="flex justify-center h-4/5">
              <Box className="self-center w-[30%]">
                <img
                  src={require("../../../../assets/TxnDetails/transaction-entry.png")}
                  alt="transaction-entry"
                />
              </Box>
            </div>
            <div className="flex justify-center h-1/5">
              <Box className="px-2 h-8/12 self-center text-white
                    text-xs 3xl:text-sm 5xl:text-base 6xl:text-xl antialiased truncate...">
                Entry in statement
              </Box>
            </div>
          </Box>}
      </Box>
      {toggleDocSec &&
        <div className="p-4 relative z-10">
          <SideDrawer id={props.txn_id} />
        </div>
      }

      <div className="py-1 -mr-2 -ml-2 ">
        <hr className="w-full border-[#1d1d1d55]" />
      </div>
      <div className="w-full flex justify-center">
        <ClientTxnDetailsMoreActions txnData={props.txnData} addStatus={props.addStatus} showTransactionRefund={props.showTransactionRefund} convertToInstantPay={props.convertToInstantPay} status={props.status} />
      </div>

    </Box>
  );
};

export default ClientTxnQuickAccessMenu;