import React from "react";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import { accountApprovalStatusColor, accountApprovalStatusColorRGBA } from "../../../../services/approvalStatus";
import CardLogo from "../../../_utils/logo/CardLogo";
import { CardAccountStatusTextMapper } from "../../../../services/accountApprovalTextMapper";

const AccountCardViewAllCard = (data) => {
  const name = data.name;
  const cardNumber = data.card_number;
  const validFrom = data.valid_from;
  const validTo = data.valid_to;
  const status = data.status;
  const scheme_logo = data.scheme_logo;
  const approvalColor = accountApprovalStatusColorRGBA(data.status);
  const color = accountApprovalStatusColor(data.status);
  const approvalStatusText = CardAccountStatusTextMapper(data.status);

  return (
    <>
      <div className="group p-4 4xl:p-6 5xl:p-8 6xl:p-10
        flex flex-col
        group
        gap-2 4xl:gap-4 6xl:gap-6
        drop-shadow-xl
        rounded-3xl 4xl:rounded-large
        hover:bg-custom-cardHover
        bg-custom-card overflow-hidden">
        <div className="flex flex-row justify-between -mr-4 4xl:-mr-6 5xl:-mr-8 6xl:-mr-10">
          <div className="shrink-0 h-full w-3/5 truncate ... font-semibold
            text-base 3xl:text-xl 4xl:text-2xl 5xl:text-3xl antialiased capitalize"
            style={{ color: color }}>
            {cardNumber}
          </div>
          <div className="min-h-full text-sm flex flex-row gap-1 justify-end">
            <div className="flex flex-row justify-start">
              <div className="h-full w-1.5 4xl:w-2"
                style={{ backgroundColor: color }}>
              </div>
              <div className="flex group-hover:px-4"
                style={{ backgroundColor: approvalColor }}>
                <span className="
                  w-0 hidden self-center
                  group-hover:visible group-hover:block
                  group-hover:w-full
                  text-sm 4xl:text-base 6xl:text-lg
                  antialiased font-semibold"
                  style={{ color: color }}>
                  {approvalStatusText}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="shrink-0 h-full w-3/5 truncate ... font-medium
            text-sm 3xl:text-lg 4xl:text-xl 5xl:text-2xl antialiased capitalize text-white">
          {name}
        </div>
        <div className="flex flex-row justify-between">
          <div className="w-3/5
            flex flex-row justify-between font-medium text-xs 3xl:text-base 4xl:text-lg 5xl:text-xl antialiased">
            <div className="flex flex-col justify-between">
              <span className="text-[#FAFAFA] truncate ... uppercase">
                {validFrom}
              </span>
              <span className="text-gray-300 text-xs font-semibold antialiased">
                Valid from
              </span>
            </div>
            <div className="flex flex-col justify-between">
              <span className="text-[#FAFAFA] truncate ... uppercase">
                {validTo}
              </span>
              <span className="text-gray-300 text-xs font-semibold antialiased ">Valid till </span>
            </div>
          </div>
          <div className="w-2/5
            -mr-4 -mb-4
            4xl:-mr-6 4xl:-mb-6
            5xl:-mr-8 5xl:-mb-8
            6xl:-mr-10 6xl:-mb-10
            flex flex-col justify-end">
            <CardLogo scheme_logo={scheme_logo} bank={scheme_logo} />
          </div>
        </div>
      </div >
    </>
  );
};

export default AccountCardViewAllCard;



