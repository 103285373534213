import React, { useEffect, useState } from "react";
import CustomizedStepper from "../../../../components/CreateTxn/CustomizedStepper";
import { useDispatch, useSelector } from "react-redux";
import EntityPanDetails from "../../../../components/Settings/Management/Entities/CreateEntity/EntityPanDetails";
import { useSearchParams } from "react-router-dom";
import EnterBankDetails from "../../../../components/Accounts/Common/EnterBankDetails";
import EnterContactDetails from "../../../../components/Accounts/Vendor/EnterContactDetails";
import { createAcc } from "../../../../redux/features/createClientAcc/createClientAcc";
import AccCreateStatus from "../../CreateResponse";
import { bankLogoMapper } from "../../../../services/bankLogoMapper";
import ArchiveAccountData from "../../../../components/Accounts/Vendor/ArchiveAccountDetails";
import { deleteAccount } from "../../../../redux/features/deleteTxn/deleteTxn";

const CreateVendorAccount = () => {
  const dispatch = useDispatch();
  const [showStatus, setShowStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const createdAcc = useSelector(
    (state) => state.createAllAcc.createAccResponse
  );
  const [nextButtonEnabled, enableNextButton] = useState(false);
  const editPayload = useSelector((state) => state.accountViewAll.records[0]);
  const [searchParams] = useSearchParams();
  const editOption = searchParams?.get("editOption");
  const [payload, setPayload] = useState({
    entity_pan: "",
    entity_name: "",
    ifsc_code: "",
    bank_acc_no: "",
    contact_no: "",
    email_id: "",
    name: "",
    gstin: "",
  });

  useEffect(() => {
    if (editOption) {
      setPayload({
        vendor_id: editPayload?.vendor_id,
        entity_pan: editPayload.pan_no,
        entity_id: editPayload?.entity_id,
        entity_name: editPayload.name,
        ifsc_code: editPayload.ifsc_code,
        bank_acc_no: editPayload.bank_acc_no,
        contact_no: editPayload.contact_no,
        email_id: editPayload.email_id,
        name: editPayload.name,
        gstin: editPayload?.gstin,
      });
    }
  }, [editOption, setPayload]);

  const steps = [
    "Enter PAN details",
    "Enter bank details",
    "Enter contact details",
    "Confirm and create account",
  ];

  const handleNext = () => {
    if (activeStep === 3) {
      dispatch(
        createAcc({
          type_of_txn: "vendor",
          payload: payload,
          editOption: editOption,
        })
      );
      if (editOption === "4")
        dispatch(deleteAccount({ account_id: editPayload?.vendor_id }));
      return;
    }
    if (activeStep === 1) {
      setPayload({
        ...payload,
        name: payload.entity_name,
        pan_no: payload.entity_pan,
      });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    enableNextButton(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (createdAcc?.status && activeStep === 3) setShowStatus(true);
  }, [createdAcc]);

  return showStatus ? (
    <AccCreateStatus
      title={
        editOption
          ? editOption === "4"
            ? "Old account archived and new vendor account created successfully!"
            : "Vendor account updated successfully!"
          : "Vendor account created successfully!"
      }
      data={{
        title: createdAcc.accounts[0]?.name,
        body: [
          {
            value: createdAcc.accounts[0]?.bank_acc_no,
            subtitle: "Account Number",
          },
          {
            value: createdAcc.accounts[0]?.ifsc_code,
            subtitle: "IFSC Code",
            img:
              "/assets/Banks/" +
              bankLogoMapper(createdAcc.accounts[0]?.bank_name) +
              ".png",
          },
          {
            value: createdAcc.accounts[0]?.contact_no,
            subtitle: "Phone",
            img: "/assets/CreateTransactionIcons/whatsapp.png",
          },
          {
            value: createdAcc.accounts[0]?.email_id,
            subtitle: "Email ID",
            img: "/assets/CreateTransactionIcons/gmail.png",
          },
        ],
        links: [
          {
            title: "Create new account",
            link: 0,
            icon: "/assets/CreateTransactionIcons/Create new account.png",
          },
          {
            title: "Create transaction",
            link: "/create_transaction/vendor_transaction",
            icon: "/assets/CreateTransactionIcons/Create transaction.png",
          },
          {
            title: "View account details",
            link:
              "/accounts/vendor/details/" + createdAcc.accounts[0]?.vendor_id,
            icon: "/assets/CreateTransactionIcons/View details.png",
          },
          {
            title: "Go to dashboard",
            link: -1,
            icon: "/assets/CreateTransactionIcons/Dashboard.png",
          },
        ],
      }}
    />
  ) : (
    <div className="bg-[#744991] flex flex-row justify-center overflow-y-auto overflow-x-hidden vendor-txn hide-scrollbar min-h-screen">
      <div className="pt-24 self-center h-screen w-10/12">
        <div className="h-fit flex flex-col gap-2 3xl:gap-4 5xl:gap-6 py-6 xl:py-8 3xl:py-10 4xl:py-14 5xl:py-16 6xl:py-20">
          <div className="h-[15%] flex flex-row justify-center">
            <CustomizedStepper
              nextButtonEnabled={nextButtonEnabled}
              activeStep={activeStep}
              steps={steps}
              editOption={editOption}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </div>
          <div className="h-4/5 flex flex-row justify-center pb-24">
            <div className="w-10/12 h-full">
              {activeStep === 0 && (
                <div className="flex justify-between gap-8 p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EntityPanDetails
                      title="Vendor PAN details"
                      bgColor="bg-[#9777AC]"
                      readOnly={editOption === "2" ? true : false}
                      registerNewEntityData={payload}
                      setRegisterNewEntityData={setPayload}
                      enableNextButton={enableNextButton}
                    />
                  </div>
                  <div className="w-1/2 h-fit"></div>
                </div>
              )}
              {activeStep === 1 && (
                <div className="flex flex-row justify-start p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                  <div className="w-1/2 h-fit">
                    <EnterBankDetails
                      payload={payload}
                      setPayload={setPayload}
                      enableNextButton={enableNextButton}
                      activeStep={activeStep}
                      readOnly={
                        editOption === "2" || editOption === "3" ? true : false
                      }
                      title="Vendor bank details"
                    />
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <>
                  <div className="flex flex-row justify-end p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-1/2 h-fit">
                      <EnterContactDetails
                        title="Vendor contact details"
                        payload={payload}
                        setPayload={setPayload}
                        enableNextButton={enableNextButton}
                        activeStep={activeStep}
                        editable={true}
                      />
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div className="flex flex-row justify-between p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                    <div className="w-[49%] flex flex-col justify-between gap-8 h-fit">
                      <EntityPanDetails
                        title="Vendor PAN details"
                        bgColor="bg-[#9777AC]"
                        registerNewEntityData={payload}
                        setRegisterNewEntityData={setPayload}
                        enableNextButton={enableNextButton}
                        activeStep={activeStep - 1}
                      />
                      <EnterBankDetails
                        payload={payload}
                        setPayload={setPayload}
                        enableNextButton={enableNextButton}
                        activeStep={activeStep}
                        title="Vendor bank details"
                      />
                    </div>
                    <div className="w-[49%]">
                      <EnterContactDetails
                        title="Vendor contact details"
                        payload={payload}
                        setPayload={setPayload}
                        enableNextButton={enableNextButton}
                        activeStep={activeStep}
                        editable={false}
                      />
                    </div>
                  </div>
                  {editOption === "4" && (
                    <div className="flex flex-row justify-center p-4 3xl:p-6 4xl:p-8 5xl:p-10 6xl:p-12">
                      <ArchiveAccountData data={editPayload} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateVendorAccount;
